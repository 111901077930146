import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { map , tap} from 'rxjs/operators';
import { AppConstants }  from '../config/index.constants'
import { IStateData, IStateDataShort } from '../types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StateService implements OnInit, OnDestroy {
  subscription = new Subscription();
  states: IStateDataShort[];
  countries = [];
  private apiUrl = AppConstants.api;
  constructor(
    private http: HttpClient) {}

  /**
 * @deprecated The 'getStates' function is being deprecated in favor of 'getStatesData'.
 *             Please use 'getStatesData' instead, which returns an Observable that provides
 *             better handling of asynchronous data and state management.
 * @returns {Promise<any>} A Promise that resolves to an array of states.
 */
  async getStates(): Promise<any> {
    return await firstValueFrom(this.http.get<String[]>(this.apiUrl + 'state'));
  }

  ngOnInit(): void {
  }


/**
 * Retrieves states as an Observable.  Filters out 'District of Columbia' (stateKey = 9),  'Puerto Rico' (stateKey = 40), and 'Wyoming' (stateKey = 52)
 * @returns {Observable<any>} An Observable that emits an array of states.
 */
  getStatesData(): Observable<IStateDataShort []> {
    if (!this.states) {
      const obs = this.http.get<IStateData []>(this.apiUrl + 'state').pipe(
        map((states: any[]) => states.map((state: any) => ({ name: state.name, stateKey: state.stateKey }))
        .filter((state: any) => state.stateKey !== '9' && state.stateKey !== '40' && state.stateKey !== '52')),
        tap((states) => this.states = states)
      );
        return obs;
    } else {
      return of(this.states)
    }
  }

  getCountries(): Observable<any> {
    return this.http.get<String[]>(this.apiUrl + 'state/countries');
  }

  // Get details for a state
  getStateDetails(state) {
    return this.http.get(this.apiUrl + 'state/' + state);
  }

  // Get details for a state
  getStateRegionalAdmin(): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'state/regionalAdmin');
  }

  //Get regions for a state
  getStateRegions() {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'state/regionalUserAdmin');
  }

  getUserDistricts() {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'state/districts');
  }

  // Get admins for a district
  getDistrictAdmins(stateKey, district) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/admin?stateKey=' + stateKey + '&district=' + district);
  }

  // Get list of 
  getStateDistricts2(stateKey) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'state/' + stateKey + '/districts');
  }
  
  updateDistrict(districts) {
    return this.http.put(AppConstants.api + 'state/district', districts, {observe: 'response', responseType: 'blob' as 'text'});
  }

  updateRegion(region) {
    return this.http.put(AppConstants.api + 'state/region', region, {observe: 'response', responseType: 'blob' as 'text'});
  }

  createRegion(region) {
    return this.http.post(AppConstants.api + 'state/region', region, {observe: 'response', responseType: 'blob' as 'text'});
  }

  setDataAdmin(stateName, flagState) {
    return this.http.put(AppConstants.api + 'state/' + stateName + '/dataadmin/' 
      + flagState, null, {observe: 'response', responseType: 'blob' as 'text'});
  }

  setUserAdmin(stateName, flagState) {
    return this.http.put<ArrayBuffer[]>(AppConstants.api + 'state/' + stateName + '/useradmin/' + flagState, null);
  }

  setResidence(stateName, flagState) {
    return this.http.put<ArrayBuffer[]>(AppConstants.api + 'state/' + stateName + '/residence/' + flagState, null);
  }

  /*
  // Get districts for a state
  getStateDistricts(state) {
    return this._$http({
      url: this._AppConstants.api + 'state/' + state,
      method: 'GET'
    }).then(
      // On success...
      (res) => {
        this.districts = [];
        this.regioncount = 0;
        for (var r in res.data.regionMap) {
          this.districts = this.districts.concat(res.data.regionMap[r].districts);
        }
        return this.districts;
      }
    );
  }*/

  

  // Get schools for a district
  getDistrictSchools(stateKey, districtKey) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'state/' + stateKey + '/' + districtKey + '/schools');
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
