import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  constructor(
    private cookieService: CookieService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
    
   }

  acceptWarn() {
    // Removing all the cookies upon Privacy acceptance to make sure previous session values are cleard.
    this.cookieService.removeAll();
    
    this.cookieService.putObject('privacyAccept', {
      accept: true
    });
    this._router.navigate(['login']);
  }
}