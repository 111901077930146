<div class="studentAchievement">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Student Achievement</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Student Achievement</a></span>
    <span>></span>
    <span *ngIf="chart=='gradHse'">
      <p style="vertical-align: middle !important;">Migratory Graduation/HSE</p>
    </span>
    <span *ngIf="chart=='grade11Alg'">
      <p style="vertical-align: middle !important;">Migratory Children Enrolling in Grade 11 with Credit for Algebra 1</p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 flex="90" class="header-padding">Student Achievement</h1>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='gradHse'">
    <h1 style="width:90%" class="header-padding">Migratory Graduation/HSE</h1>
    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState && (isDesktop || isTablet)"
      [exportlink]="graduationHseExportCall" 
      [exportfilter]="studentAchievementFilterParams.selectedState"
      [showcsv]="true" 
      [showpdf]="false">
    </export-report>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='grade11Alg'">
    <h1 style="width:90%" class="header-padding">Migratory Children Enrolling in Grade 11 with Credit for Algebra 1</h1>
    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState  && (isDesktop || isTablet)"
      [exportlink]="grade11AlgebraExportCall" 
      [exportfilter]="studentAchievementExportObject"
      [showcsv]="true" 
      [showpdf]="false">
    </export-report>
  </div>

  <hr layout-padding class="thickHr">

  <div layout-align="center center" *ngIf="!showDrill" class="bodyPadding">
    <div class="layout-column" flex="90">
      <div class="report-summary layout-row">
        <p *ngIf="authRoleClasses.dataAdmins">
          The Student Achievement Dashboard provides insight into how migratory children in your State are
          performing in important academic areas.
          <a tabindex="0" *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
        <p *ngIf="authRoleClasses.dataDashboards">
          The Student Achievement Dashboard provides insight into how migratory children are performing in
          important academic areas nationally.
          <a tabindex="0" *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
      </div>

      <filter-button *ngIf="isTablet || isDesktop" (activateFilter)="filterActivate()" class="chart-row" flex=auto></filter-button>
      <div *ngIf="dataHasLoaded" [class]="{'chart-row' : authRoleClasses.dataAdmins}">
        <div id="chart-section" class="chart-section vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">Migratory Graduation/HSE</h2>
            <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState && (isDesktop || isTablet)"
              [exportlink]="graduationHseExportCall" 
              [exportfilter]="studentAchievementFilterParams.selectedState"
              [showcsv]="true" 
              [showpdf]="false">
            </export-report>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs layout-row" flex=90>
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text">
                  Performance Periods: {{dateObj.gradHSEPPText}}
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState && (isDesktop || isTablet)"
              (keypress)="showDrilldown('gradHse')" (click)="showDrilldown('gradHse')">View Details
            </a>
          </div>
          <div *ngIf="!hideGraduationHse && gradHseDataHasLoaded">
            <msix-line-chart 
              id="gradHse"
              [id]="gradHseId" 
              [chart]="gradHseChart"  
              [data]="gradHseData">
            </msix-line-chart>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hideGraduationHse">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>

        <div id="chart-section" class="chart-section vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">Migratory Children Enrolling in Grade 11 with Credit for Algebra 1</h2>
            <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState && (isDesktop || isTablet)"
              [exportlink]="grade11AlgebraExportCall" 
              [exportfilter]="studentAchievementExportObject"
              [showcsv]="true" 
              [showpdf]="false">
            </export-report>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs layout-row" flex=90>
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text" *ngIf="studentAchievementFilterParams.periodType == 'PREVIOUS'">
                  Last Performance Period ({{dateObj.recent}})
                </p>
                <p class="perf-period-text" *ngIf="studentAchievementFilterParams.periodType == 'CURRENT'">
                  Current Performance Period ({{dateObj.current}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && studentAchievementFilterParams.rptGrping == 'State' && studentAchievementFilterParams.selectedState && (isDesktop || isTablet)"
              (keypress)="showDrilldown('grade11Alg')" (click)="showDrilldown('grade11Alg')">View Details
            </a>
          </div>
          <div *ngIf="!hideGrade11Alg1 && grade11AlgDataHasLoaded">
            <msix-line-chart 
              id="grade11Alg"
              [id]="grade11AlgId" 
              [chart]="grade11AlgChart"  
              [data]="grade11AlgData">
            </msix-line-chart>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hideGrade11Alg1">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!--Drilldown Page-->
  <div class="layout-align-center-center layout-row bodyPadding" *ngIf="showDrill">
    <div class="layout-column" flex="90">
      <div class="report-summary" class="layout-row">
        <p *ngIf="chart=='gradHse'">
          The table below lists the migratory children that were enrolled in grade 12 for each Performance Period displayed in the chart and whether they graduated, received their HSE, or neither.
          <a tabindex="0" *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
        <p *ngIf="chart=='grade11Alg'">
          This table displays all children enrolled in Grade 11 in the selected performance period and whether those children received Algebra 1 or equivalent credit in Grades 7 to 10.
          <a tabindex="0" *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary layout-row">
        <span class="showing layout-row">Showing:</span>

        <!-- Showing text for Migratory Graduation/HSE -->
        <div *ngIf="chart=='gradHse'">
          <span class="perf-period layout-row">
            <p class="perf-period-text">
              Performance Periods: {{dateObj.gradHSEPPText}}
            </p>
          </span>
        </div>

        <!-- Showing text for Migratory Children Enrolling in Grade 11 with Credit for Algebra 1 -->
        <div *ngIf="chart=='grade11Alg'">
          <span class="perf-period layout-row">
            <p class="perf-period-text" *ngIf="studentAchievementFilterParams.periodType == 'PREVIOUS'">
              Last Performance Period ({{dateObj.recent}})
            </p>
            <p class="perf-period-text" *ngIf="studentAchievementFilterParams.periodType == 'CURRENT'">
              Current Performance Period ({{dateObj.current}})
            </p>
          </span>
        </div>
      </div>

      <no-results *ngIf="blankRes"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>
</div>