<div class="mepPopulation">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">MEP Population</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">MEP Population</a></span>
    <span>></span>
    <span>
      <p *ngIf="chart=='mepAgeForGrade'" style="vertical-align: middle !important;">
        MEP Age-for-Grade Breakdown
      </p>
      <p *ngIf="chart=='pfsPopulation'" style="vertical-align: middle !important;">
        PFS, EL, and IEP Populations
      </p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 flex="90" class="header-padding">MEP Population</h1>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='mepAgeForGrade'">
    <h1 style="width:90%" class="header-padding">MEP Age-for-Grade Breakdown</h1>
    <div class="right-align">
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState && (isDesktop || isTablet)"
        [exportlink]="mepStairExportCall" 
        [exportfilter]="mepPopulationExportObject"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='pfsPopulation'">
    <h1 style="width:90%" class="header-padding">PFS, EL, and IEP Populations</h1>
    <div class="right-align">
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState  && (isDesktop || isTablet)"
        [exportlink]="pfsPopulationExportCall" 
        [exportfilter]="mepPopulationExportObject"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <hr layout-padding class="thickHr">

  <div id="dashboardDiv" layout-align="center center" *ngIf="!showDrill" class="bodyPadding">
    <div class="layout-column" flex="90">
      <div class="report-summary layout-row">
        <p *ngIf="authRoleClasses.dataAdmins">
          The Migrant Education Program (MEP) Dashboard provides insight into the academic progress of migratory
          children in your State and the services they receive.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
        <p *ngIf="authRoleClasses.dataDashboards">
          The Migrant Education Program (MEP) Dashboard provides insight into the academic progress of migratory
          children nationally and the services they receive.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
      </div>

      <!--Desktop, tablet, and mobile view-->
      <filter-button *ngIf="isTablet || isDesktop" (activateFilter)="filterActivate()" class="chart-row" flex=auto></filter-button>
      <div *ngIf="dataHasLoaded" ng-class="{'chart-row' : authRoleClasses.dataAdmins || isMobile}">
        <div id="chart-section" class="vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">MEP Age-for-Grade Breakdown</h2>
            <div class="right-align">
              <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState && (isDesktop || isTablet)"
                [exportlink]="mepStairExportCall" 
                [exportfilter]="mepPopulationExportObject"
                [showcsv]="true" 
                [showpdf]="false">
              </export-report>
            </div>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs layout-row flex-90">
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'PREVIOUS'">
                  Last Performance Period ({{dateObj.recent}})
                </p>
                <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'CURRENT'">
                  Current Performance Period ({{dateObj.current}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState && (isDesktop || isTablet)"
              (keypress)="showDrilldown('mepAgeForGrade')" (click)="showDrilldown('mepAgeForGrade')">View Details
            </a>
          </div>
          <div *ngIf="!hideMepStair && mepStairDataHasLoaded">
            <msix-table
              id="mepStair"
              [id]="mepStairId" 
              [chart]="mepStairChart"  
              [data]="mepStairData">
            </msix-table>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hideMepStair">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>
        <div id="chart-section" class="chart-row vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">PFS, EL, and IEP Populations</h2>
            <div class="right-align">
              <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState  && (isDesktop || isTablet)"
                [exportlink]="pfsPopulationExportCall" 
                [exportfilter]="mepPopulationExportObject"
                [showcsv]="true" 
                [showpdf]="false">
              </export-report>
            </div>
          </div>
          <div class="layout-row">
            <div class=" drill-down-rs layout-row flex-90">
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'PREVIOUS'">
                  Last Performance Period ({{dateObj.recent}})
                </p>
                <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'CURRENT'">
                  Current Performance Period ({{dateObj.current}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && mepPopulationFilterParams.rptGrping == 'State' && mepPopulationFilterParams.selectedState  && (isDesktop || isTablet)"
              (keypress)="showDrilldown('pfsPopulation')" (click)="showDrilldown('pfsPopulation')">View Details
            </a>
          </div>
          <div *ngIf="!hidePfsPopulation && pfsPopulationDataHasLoaded">
            <msix-table
              id="pfsPopulation"
              [id]="pfsPopulationId" 
              [chart]="pfsPopulationChart"  
              [data]="pfsPopulationData">
            </msix-table>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hidePfsPopulation">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Drilldown Page-->
  <div class="layout-align-center-center bodyPadding" *ngIf="showDrill">
    <div class="layout-column" flex="90">
      <div class="report-summary layout-row">
        <p *ngIf="chart=='mepAgeForGrade'">
          The table below displays each migratory child with open enrollments in all States in the current performance period.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
        <p *ngIf="chart=='pfsPopulation'">
          The table below displays each migratory child that enrolled in your State in the selected Performance Period with PFS, EL, or IEP indicators of “Yes.”
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary drill-down-rs layout-row">
        <span class="showing layout-row">Showing:</span>
        <span class="perf-period layout-row">
          <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'PREVIOUS'">
            Last Performance Period ({{dateObj.recent}})
          </p>
          <p class="perf-period-text" *ngIf="mepPopulationFilterParams.periodType == 'CURRENT'">
            Current Performance Period ({{dateObj.current}})
          </p>
        </span>
      </div>
      <no-results *ngIf="blankRes"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>
</div>