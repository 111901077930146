<div class="filter-dialog">
  <div aria-label="Confirm Deactivation" class="confirmDeactivateDialog">
    <!-- TODO - Need to figure out appropriate ngIf condition to replace ng-cloak-->
    <!--form ng-cloak-->
    <form>
      <mat-toolbar>
        <div class="md-toolbar-tools layout-row">
          <h2 style="padding-top: 20px">MFA Reset Confirmation</h2>
          <span flex></span>
          <button style="border: none; margin-left: 333px;" class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">
          <div layout="row" class="center">
            <p style="padding: 10px">Please confirm that you wish to reset the existing MFA configuration for the 
              following user:</p>
          </div>
          <div layout="row" class="center usernameDeactivate">
            <h2>{{firstName}}&nbsp;{{lastName}}</h2>
            <h3>Username:&nbsp;{{username}}</h3>
            <h3>Email:&nbsp;{{email}}</h3>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions layout="row" style="padding: 20px">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span flex></span>
        <button style="margin-left: 400px;" (click)="resetMFA()" class="md-button confirmButton">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
