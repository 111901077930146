<div class="container">
  <div class="content">
    <div class="topLine">
      There were no results returned.
    </div>
    <div class="bottomLine">
      Please modify the filter critera and try running the query again.
    </div>
  </div>
</div>
