<form [formGroup]="userDetailsForm" (ngSubmit)="onSubmit()">
  <div class="custom-mat-input-component custom-input-container top-input-padding"
    [ngClass]="{'error': isrequired && userDetailsForm.controls[controlName].touched && checkError()}">
    <mat-form-field style="display: flex; width: 100%" [floatLabel]="isrequired && userDetailsForm.controls[controlName].touched && 
      checkError() ? 'always' : 'auto'" [ngClass]="{'mff-has-value': textValue}">
      <mat-label class="custom-label" [ngClass]="{'label-error' : errorlength > 0, 'box-selected': inputField.focused || textValue}" 
        for="inputField">{{customlabel}}</mat-label>
      <input matInput #inputField="matInput" type="{{textType}}" [(ngModel)]="textValue" formControlName={{controlName}}
        [ngClass]="{'mat-input-has-value': textValue, 'custom-input-disabled': isdisabled==true, 'custom-input': isdisabled==false, 
        'input-error' : (errorlength > 0 || nonNumError || (isrequired && userDetailsForm.controls[controlName].touched && checkError()))}" 
        (input)="onValueChanged($event)" maxlength="{{maxcharacter}}" [required]="isrequired" (keydown)="_keyDown($event)">
    </mat-form-field>
    <mat-error class="error-message" *ngIf="userDetailsForm.controls[controlName].touched && checkError()">{{errormessage}}</mat-error>
  </div>
</form>