import  { AbstractControl, ValidatorFn} from '@angular/forms';

export function ListNameExistsValidator(exisitingNames: string[]): ValidatorFn {
    return (ctrl: AbstractControl) => {
        if (!ctrl || !exisitingNames)  {
            return null;
        }
        if (exisitingNames.some(n=>n?.toLowerCase()?.trim() === ctrl.value?.toLowerCase()?.trim())) {
            return {duplicateListName: true}
        }
        return null;
    }
}