<div class="resetPasswordDialog">
  <div aria-label="Confirm Deactivation" class="confirmDeactivateDialog">
    <!-- TODO - Need to figure out appropriate ngIf condition to replace ng-cloak-->
    <!--form ng-cloak-->
    <form>
      <mat-toolbar>
        <div class="align-right layout-row md-toolbar-tools" style="flex: 100%;">
          <h2 style="padding-top: 20px">Password Reset Confirmation</h2>
          <span style="flex: auto;"></span>
          <button style="border: none;" class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">
          <div style="display: flex; flex-direction: row;" class="center">
            <p style="padding: 20px">Please confirm that you wish to reset the password for the following user:</p>
          </div>
          <div style="display: flex; flex-direction: column;" class="center usernameDeactivate">
            <h2>{{data.firstName}}&nbsp;{{data.lastName}}</h2>
            <h3>Username:&nbsp;{{data.username}}</h3>
            <h3>Email:&nbsp;{{data.email}}</h3>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions style="display: flex; flex-direction: row; padding: 20px;">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span style="flex:auto"></span>
        <button (click)="resetPasswordApiCall()" class="md-button confirmButton">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>  
