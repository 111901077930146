import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SavedListService } from 'src/app/services/savedlist.service';
import { AuthService } from '../../services/auth.service'
import { SearchService } from '../../services/search.service'
import { ToastService } from './../../services/toast.service'
import * as moment from 'moment';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/config/index.constants';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { StateService } from 'src/app/services/state.service';
import { HTMLExportService } from 'src/app/services/html-export.service';
import { cloneDeep } from 'lodash';
import { SortByPipe } from 'src/app/pipes/order-by-pipe';
import { AddEditStudentToListDialogComponent, IAddEditRemoveStudentFromListDialogData, IStudentListsUpdatePayload } from '../add-edit-student-to-list-dialog/add-edit-student-to-list-dialog.component';
import { tap } from 'rxjs';



@Component({
  selector: 'student-details-dialog',
  templateUrl: 'student-details-dialog.html',
  styleUrls: ['student-details-filter.scss']
})
export class StudentDetailsDialog {
  $log: any;
  savedListsNames = [];
  isSaved = false;
  newListIsChecked: boolean;
  listNameExists: boolean;
  checkedListsTracker: any;
  
  constructor(
    public dialogRef: MatDialogRef<StudentDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Savedlist: SavedListService,
    private toast: ToastService,
    public responsiveUI: ResponsiveUIService
  ) {
    //On open, get savedListsNames
    this.getSavedListsNames();

    this.checkedListsTracker = cloneDeep(data.checkedListTracker);
    this.isSaved = data.isSaved;
    this.checkedListsTracker.newListName = '';

  }
    // creates a new list and adds the student to that list
  checkNewList() {
    if (!this.newListIsChecked) {
      this.checkedListsTracker.newListName = '';
    }
  }

  // sets the ng-checked to true or false based on if savedListKey exists in the checkedLists
  existsInCheckedLists(saveListKey) {
    let index = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  checkCreateListName() {
    for (let list of this.savedListsNames) {
      if (this.checkedListsTracker.newListName && list.saveListName.toUpperCase() === this.checkedListsTracker.newListName.trim().toUpperCase()) {
        this.listNameExists = true;
        return true;
      }
    }
    this.listNameExists = false;
    return false;
  }

  // adds and removes a saved student from the selected saved lists
  setCheckedLists(saveListKey) {
    let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
    let uncheckedIndex = this.checkedListsTracker.uncheckedLists.indexOf(saveListKey);
    let newCheckedIndex = this.checkedListsTracker.newCheckedLists.indexOf(saveListKey);
    if (checkedIndex === -1) {
      this.checkedListsTracker.checkedLists.push(saveListKey);
      this.checkedListsTracker.newCheckedLists.push(saveListKey);
      if (uncheckedIndex > -1) {
        this.checkedListsTracker.uncheckedLists.splice(uncheckedIndex, 1);
      }
    } else {
      this.checkedListsTracker.checkedLists.splice(checkedIndex, 1);
      if (newCheckedIndex > -1) {
        this.checkedListsTracker.newCheckedLists.splice(newCheckedIndex, 1);
      }
      this.checkedListsTracker.uncheckedLists.push(saveListKey);
    }
    console.log("Setting!!");
  }

  
  //Retrieves a list of the names of a user's saved lists
  getSavedListsNames() {
    let apiCall = this._Savedlist.getSaveListsNames();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log(data);
        this.savedListsNames = data;
        console.log("Saved Lists Names: ", this.savedListsNames);
        subscribed.unsubscribe();
      },
      error: err => {
        console.log(err);
        this.toast.showToast(err, 0);
      }
    });
  }

  //checks the checkbox to create a new list if the user enters a new list name
  selectCreateListCheckbox() {
    if (this.checkedListsTracker.newListName.length > 0) {
      this.newListIsChecked = true;
    } else {
      this.newListIsChecked = false;
    }
  }
  
  //Add student to all of the saved lists
  selectAll() {
    for (let list of this.savedListsNames) {
      let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(list.saveListKey);
      if (checkedIndex === -1) {
        this.checkedListsTracker.checkedLists.push(list.saveListKey);
        this.checkedListsTracker.newCheckedLists.push(list.saveListKey);
      }
    }
    this.checkedListsTracker.uncheckedLists = [];
  }

  //Remove student from all lists
  selectNone() {
    for (let key of this.checkedListsTracker.checkedLists) {
      this.checkedListsTracker.uncheckedLists.push(key);
    }
    this.checkedListsTracker.checkedLists = [];
    this.checkedListsTracker.newCheckedLists = [];
    this.newListIsChecked = false;
  }

  onSubmit(): void {
    this.dialogRef.close(this.checkedListsTracker);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'historical-raw-view',
  templateUrl: './_historicalRawView.html',
  styleUrls: ['./student-details.component.scss']
})
export class HistoricalRawViewComponent{
  @Input() rawHistoricalDataElements!: any;
  @Input() showHistorical!: any;
  rawIndex = -1;
  rawAddIDIndex = -1;
  rawQualMovesIndex = -1;
  rawEnrIndex = -1;
  rawEnrItemsIndex = -1;
  rawEnrItemDataAdminIndex = -1;
  IdHistory = -1;
  IdItem = -1;
  courseIndex = -1;
  courseItemIndex = -1;
  assessmentIndex = -1;
  assessmentItemIndex = -1;
  rawDataAdminsNested = -1;
  rawAssessmentsNested = -1;
  rawCoursesNested = -1;
  rawIDNested = -1;

  constructor(private sortBy: SortByPipe){
  }

  showRawFn(index) {
    if (this.rawIndex === index) {
      this.rawIndex = -1;
    } else {
      this.rawIndex = index;
    }
    this.sortAssessments(index);
  }

  //Sorts each list of assessments under the state of this index
  //this.rawHistoricalDataElements[index] = state
  sortAssessments(index) {
    for (let enrollmentIndex in this.rawHistoricalDataElements[index].enrollments) {
      this.rawHistoricalDataElements[index].enrollments[enrollmentIndex].assessments.sort((a,b) => 
        b.asmtAdminDate.substring(2) - a.asmtAdminDate.substring(2)
      );
    }
  }

  showRawAddID(index) {
    if (this.rawAddIDIndex === index) {
      this.rawAddIDIndex = -1;
    } else {
      this.rawAddIDIndex = index;
    }
  }

  showRawQualMoves(index) {
    if (this.rawQualMovesIndex === index) {
      this.rawQualMovesIndex = -1;
    } else {
      this.rawQualMovesIndex = index;
    }
  }

  showRawEnrFn(index) {
    if (this.rawEnrIndex === index) {
      this.rawEnrIndex = -1;
    } else {
      this.rawEnrIndex = index;
    }
  }

  showRawEnrItems(index) {
    if (this.rawEnrItemsIndex === index) {
      this.rawEnrItemsIndex = -1;
    } else {
      this.rawEnrItemsIndex = index;
    }
  }

  showRawEnrItemDataAdmin(index) {
    if (this.rawEnrItemDataAdminIndex === index) {
      this.rawEnrItemDataAdminIndex = -1;
    } else {
      this.rawEnrItemDataAdminIndex = index;
    }
  }

  showIdHistory(index) {
    if (this.IdHistory === index) {
      this.IdHistory = -1;
    } else {
      this.IdHistory = index;
    }
  }

  showIdItem(index) {
    if (this.IdItem === index) {
      this.IdItem = -1;
    } else {
      this.IdItem = index;
    }
  }

  showCourseHistory(index) {
    if (this.courseIndex === index) {
      this.courseIndex = -1;
    } else {
      this.courseIndex = index;
    }
  }

  showCourseItemHistory(index) {
    if (this.courseItemIndex === index) {
      this.courseItemIndex = -1;
    } else {
      this.courseItemIndex = index;
    }
  }

  showAssessmentHistory(index) {
    if (this.assessmentIndex === index) {
      this.assessmentIndex = -1;
    } else {
      this.assessmentIndex = index;
    }
  }

  showAssessmentItemHistory(index) {
    if (this.assessmentItemIndex === index) {
      this.assessmentItemIndex = -1;
    } else {
      this.assessmentItemIndex = index;
    }
  }

  showDataAdminsNested(index) {
    if (this.rawDataAdminsNested === index) {
      this.rawDataAdminsNested = -1;
    } else {
      this.rawDataAdminsNested = index;
    }
  }
  showAssessmentsNested(index) {
    if (this.rawAssessmentsNested === index) {
      this.rawAssessmentsNested = -1;
    } else {
      this.rawAssessmentsNested = index;
      this.assessmentItemIndex = -1;
    }
  }
  showCoursesNested(index) {
    if (this.rawCoursesNested === index) {
      this.rawCoursesNested = -1;
    } else {
      this.rawCoursesNested = index;
      this.courseItemIndex = -1;
    }
  }

  showIDHistoryNested(index) {
    if (this.rawIDNested === index) {
      this.rawIDNested = -1;
    } else {
      this.rawIDNested = index;
      this.courseItemIndex = -1;
    }
  }

} //TODO remove once confirmed refactor is working -- using app-student-details-historical-raw-view [mdev]

@Component({
  selector: 'cor-dialog',
  templateUrl: 'corDialog.html',
  styleUrls: ['student-details.component.scss']
})
export class CORDialog {
  filterParameters: any;
  CORForm = this.formBuilder.group({
    stateIndex: null,
    districtIndex: null,
    schoolIndex: null,
    moveNotice: null,
    multiMoveNotice: null,
    comments: null
  });
  indicator;
  textValue: string = '';
  submitDisabled: boolean = true;
  selectedDistrict: any;
  selectedSchool: any;
  corObject: any;
  selectedState: any;
  stateKey: any;
  states: any;
  districts: any;
  toast: any;
  errors: any;
  schoolSelected: boolean;
  constructor(
    public dialogRef: MatDialogRef<CORDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public responsiveUI: ResponsiveUIService,
    private stateService: StateService,
  ) {
    this.filterParameters = cloneDeep(this.data.params);
    this.filterParameters.moveNotice = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.filterParameters.corObject.isLeaving = this.filterParameters.moveNotice;
    this.filterParameters.corObject.comments = this.textValue;
    this.dialogRef.close(this.filterParameters);
  }

  getDistricts() {
    this.submitDisabled = false;
    this.filterParameters.selectedSchool.schoolIndex = null;
    this.filterParameters.corObject.district = null;
    this.filterParameters.corObject.school = null;

    if (!this.filterParameters.selectedState.stateIndex) {
      this.filterParameters.selectedState.stateIndex = 0;
    }

    this.stateKey = this.filterParameters.states[this.filterParameters.selectedState.stateIndex].stateKey;
    this.filterParameters.corObject.state = this.filterParameters.states[this.filterParameters.selectedState.stateIndex];
    if (this.stateKey) {
      let apiCall = this.stateService.getStateDistricts2(this.stateKey);
        // Callback for success
      let subscribed = apiCall.subscribe({
        next: data => {
          this.filterParameters.districts = data;
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: err => {
          console.log(err);
          this.toast.showToast(err.developerMessage, 0);
          this.errors = err.data.errors;
          subscribed.unsubscribe();
        }
      });
    } else {
    }
  }

  getSchoolsList() {
    this.filterParameters.selectedSchool.schoolIndex = null;
    this.filterParameters.corObject.district = this.filterParameters.districts[this.filterParameters.selectedDistrict.districtIndex];
    this.filterParameters.corObject.school = null;


    if (this.filterParameters.corObject.district) {
      let apiCall = this.stateService.getDistrictSchools(this.stateKey, this.filterParameters.corObject.district.key);
        // Callback for success
        let subscribed = apiCall.subscribe({
          next: data => {
          console.log(data);
          this.filterParameters.schools = data;
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: err => {
          console.log(err);
          this.toast.showToast(err.developerMessage, 0);
          this.errors = err.data.errors;
          subscribed.unsubscribe();
        }
      });
    } else {
    }
  }

  enableSubmit() {
    this.schoolSelected = true;
    //set the move notification object
    this.filterParameters.corObject.school = this.filterParameters.schools[this.filterParameters.selectedSchool.schoolIndex];
  }
}

@Component({
  selector: 'student-details-dialog',
  templateUrl: 'filterExportReport.html',
  styleUrls: ['student-details.component.scss']
})
export class CustomExportFilter {
  element: any;
  gradeLevel: any;
  filterParameters: any;
  constructor(
    public dialogRef: MatDialogRef<CustomExportFilter>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.filterParameters = cloneDeep(this.data.params);
  }

  closeDialog(): void {
    this.resetExportFilter();
    this.dialogRef.close();
  }

  onSubmit() {
    this.setGradeLevels();
    this.dialogRef.close(this.filterParameters);
  }

  selectAllExportFilter(list) {
    if (list === 'elements') {
      for (let e in this.filterParameters.reportElements) {
        // element.checked = true;
        this.filterParameters.reportElements[e].checked = true;
      }
    } else if (list === 'gradeLevel') {
      for (let g in this.filterParameters.gradeLevels) {
        this.filterParameters.gradeLevels[g].checked = true;
      }
      this.setGradeLevels();
    }
  }

  // Set the comma separated list of grade level keys that will be sent to API call based on the Grade Level filter selection
  setGradeLevels() {
    let tempGradeLevelArray = [];
    for (var x = 0; x < this.filterParameters.gradeLevels.length; x++) {
      let gradeLevel = this.filterParameters.gradeLevels[x];
      if (gradeLevel.checked) {
        if (gradeLevel.value === '-100') {
          this.filterParameters.nonAssociatedWithEnrollment = true;
        } else {
          tempGradeLevelArray.push(gradeLevel.value);
        }
      } else {
        if (gradeLevel.value === '-100') {
          this.filterParameters.nonAssociatedWithEnrollment = false;
        }
      }
    }
    //gradeLevelString is sent to the API. gradeLevels is used by the filter
    this.filterParameters.gradeLevelsString = tempGradeLevelArray.join();
  }

  selectNoneExportFilter(list) {
    if (list === 'elements') {
      for (let element of this.filterParameters.reportElements) {
        element.checked = false;
      }
    } else if (list === 'gradeLevel') {
      for (let gradeLevel of this.filterParameters.gradeLevels) {
        gradeLevel.checked = false;
      }
      this.filterParameters.gradeLevelsString = [];
    }
  }

  //updates the 'checked' property for the updated Grade Level checkbox on the filter popup. 
  checkedGradeLevels(v) {
    var gradeLevel = this.filterParameters.gradeLevels.find(({ value }) => value === v);
    gradeLevel.checked = !gradeLevel.checked;
  }

  checkedElements(v) {
    var element = this.filterParameters.reportElements.find(({ value }) => value === v);
    element.checked = !element.checked;
  }

  resetExportFilter() {
    this.filterParameters.criteria = 'default';
    this.filterParameters.language = 'English';
    this.filterParameters.displayDateError = false;
    this.resetGradeLevels();
    this.filterParameters.enrollmentDate = {};
    //Select all report elements except for  'Additional Guidance'
    this.selectAllExportFilter('elements');
    this.filterParameters.reportElements[2].checked = false;
    this.filterParameters.reportElements[3].checked = false;
  }

  //resets grade levels to select all grade levels except for 'Assessments Not Associated with Enrollment/Grade Level'
  resetGradeLevels(){
    this.selectAllExportFilter('gradeLevel');
    for (let gradeLevel of this.filterParameters.gradeLevels) {
      if (gradeLevel.value == '-100') {
        gradeLevel.checked = false;
      }
    }
  }

  // format string date into UNIX and set filter parameter
  formatDate() {
    let startEnrollmentDate = '';
    let endEnrollmentDate = '';
    if (this.filterParameters.enrollmentDate.startYYYY && this.filterParameters.enrollmentDate.startMM) {
      if (this.filterParameters.enrollmentDate.startDD){
        startEnrollmentDate = this.filterParameters.enrollmentDate.startYYYY + '-' + this.filterParameters.enrollmentDate.startMM + '-' + this.filterParameters.enrollmentDate.startDD;
        this.filterParameters.exportStartEnrollmentDate = this.filterParameters.enrollmentDate.startMM + '/' + this.filterParameters.enrollmentDate.startDD + '/'+ this.filterParameters.enrollmentDate.startYYYY;
      } else {
        startEnrollmentDate = this.filterParameters.enrollmentDate.startYYYY + '-' + this.filterParameters.enrollmentDate.startMM;
        this.filterParameters.exportStartEnrollmentDate = this.filterParameters.enrollmentDate.startMM + '/' + this.filterParameters.enrollmentDate.startYYYY;
      }
      
    }

    if (this.filterParameters.enrollmentDate.endYYYY && this.filterParameters.enrollmentDate.endMM) {
      if (this.filterParameters.enrollmentDate.endDD){
        endEnrollmentDate = this.filterParameters.enrollmentDate.endYYYY + '-' + this.filterParameters.enrollmentDate.endMM + '-' + this.filterParameters.enrollmentDate.endDD;
        this.filterParameters.exportEndEnrollmentDate = this.filterParameters.enrollmentDate.endMM + '/' + this.filterParameters.enrollmentDate.endDD + '/' + this.filterParameters.enrollmentDate.endYYYY;
      } else {
        endEnrollmentDate = this.filterParameters.enrollmentDate.endYYYY + '-' + this.filterParameters.enrollmentDate.endMM;
        this.filterParameters.exportEndEnrollmentDate = this.filterParameters.enrollmentDate.endMM + '/' + this.filterParameters.enrollmentDate.endYYYY;
      }
      
    }
    
    if (startEnrollmentDate && endEnrollmentDate) {
      if (this.filterParameters.enrollmentDate.startDD && this.filterParameters.enrollmentDate.endDD && moment(endEnrollmentDate).isBefore(startEnrollmentDate)){
        this.filterParameters.displayDateError = true;
      } 
      else if (moment(endEnrollmentDate).isBefore(startEnrollmentDate, 'month')){
        this.filterParameters.displayDateError = true;
      } 
      else {
        this.filterParameters.displayDateError = false;
      }
    }
    
  }

  //focuses on the next input of the Enrollment date bassed of the parameter passed in
  skipToNextDateInput(currentInput, nextElementId) {
    if (currentInput.length > 1) {
      // this.document.getElementById(nextElementId).focus();
      let myDiv = <HTMLElement>document.getElementById(nextElementId);
      myDiv.focus();
      this.formatDate();
    }
  }
}

@Component({
  selector: 'student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component-temp.scss']
})
export class StudentDetailsComponent implements OnInit {
  privAct = this.formBuilder.group({
    comments: '',
    stateStudentKey: null,
    privActKey: true
  })
  showFilter = false;
  authRoleClasses: any = { 
    worklist: null,
    superUser: null,
    privacyEdit: null
  };
  msixId: any;
  view: string;
  loadComplete: boolean;
  inMergeWorklist: any;
  inSplitWorklist: any;
  inPendingMergeWorklist: any;
  inPendingSplitWorklist: any;
  $log = true;
  errors = true;
  _Toast = true;
  comment = true;
  course = true;
  graduationHSEDate = true;
  graduationHSEIndicator = true;
  algebra1orEquivalentIndicator = true;
  assess = true;
  enr = true;
  enrollment = true;
  qualMoves = {
    qualArrivalDate: "",
    qadFromState: "",
    qadFromCity: "",
    qadToCity: ""
  };
  moveToCity = true;
  moveFromCity = true;
  student = {
    birthLocation: "",
    maleParentName: "",
    femaleParentName: "",
    altIds: [{altId: ""}],
    privacyActComments: 0,
    stateStudentId: "",
    statePostalCode: "",
    gender: ""
  };
  id = true;
  studentAge: any;
  isSaved: boolean;
  submittingStates: any;
  reportType = 'consolidated';
  exportType = {
    pdf: '/pdf',
    word: '/rtf'
  };
  getAPIPath = 'student/msix/';
  studentName = "";
  indicator = true;
  $mdMedia = true;
  showConsolidated = true;
  showHistorical = false;
  showRaw = false;
  
  showQualInfo: any;
  showAcademicInfo: any;
  showOverview: any;

  enrollments = 0;
  enrFlag = {};
  assessments = 0;
  courses = [];
  sectionTitle: string;
  rawHistoricalDataElements: any;
  stateId: any;
  state: any;
  $mdDialog: any;
  filterParameters: any;
  customFullscreen: any;
  canSplit: any;
  states = [];
  savelistObject: { msixID: string; savedListKeys: any; unsavedListKeys: any; newListName: any; };
  checkedLists = [];
  newCheckedLists = [];
  uncheckedLists = [];
  newListName: any;
  indexArray = [];
  assessIndex: any;
  courseIndex: any;
  rawIndex: any;
  rawAddIDIndex: any;
  rawDemographicsIndex: any;
  rawQualMovesIndex: any;
  rawEnrIndex: any;
  rawEnrItemsIndex: any;
  rawEnrItemDataAdminIndex: any;
  IdHistory: any;
  IdItem: any;
  courseItemIndex: any;
  assessmentIndex: any;
  assessmentItemIndex: any;
  rawDataAdminsNested: any;
  rawAssessmentsNested: any;
  rawCoursesNested: any;
  rawIDNested: any;
  submitDisabled: boolean;
  corObject: { comments: string; isLeaving: boolean; district: any; school: {}; state: any; };
  selectedState: { stateIndex: any; };
  selectedDistrict: { districtIndex: any; };
  selectedSchool: { schoolIndex: any; };
  districts: any[];
  schools: any[];
  corType: any;
  mainPage: string;
  stateKey: any;
  printView: boolean;
  savedListsNames = [];
  newListIsChecked: boolean;
  reportElements = [
    {
      value: 1,
      name: "Course History",
      checked: true
    },
    {
      value: 2,
      name: "Assessments",
      checked: true
    },
    {
      value: 3,
      name: "Enrollment Comments",
      checked: false
    },
    {
      value: 4,
      name: "Additional Guidance",
      checked: false
    },
  ];

  // Value-name pair in this list is tied to the reference table in the database
  // 'checked' property indicates if the checkbox is checked or not. This is the proper way of taking advandage of two-way binding.
  gradeLevels = [
    {
      value: '2,17,18,19,20,21,22,23',
      name: "P Values",
      checked: true
    },
    {
      value: 3,
      name: "Kindergarten",
      checked: true
    },
    {
      value: 5,
      name: "Grade 1",
      checked: true
    },
    {
      value: 6,
      name: "Grade 2",
      checked: true
    },
    {
      value: 7,
      name: "Grade 3",
      checked: true
    },
    {
      value: 8,
      name: "Grade 4",
      checked: true
    },
    {
      value: 9,
      name: "Grade 5",
      checked: true
    },
    {
      value: 10,
      name: "Grade 6",
      checked: true
    },
    {
      value: 11,
      name: "Grade 7",
      checked: true
    },
    {
      value: 12,
      name: "Grade 8",
      checked: true
    },
    {
      value: 13,
      name: "Grade 9",
      checked: true
    },
    {
      value: 14,
      name: "Grade 10",
      checked: true
    },
    {
      value: 15,
      name: "Grade 11",
      checked: true
    },
    {
      value: 16,
      name: "Grade 12",
      checked: true
    },
    {
      value: 1,
      name: "Ungraded",
      checked: true
    },
    {
      value: 4,
      name: "Out-of-School",
      checked: true
    },
    {
      value: '0,-1',
      name: "Invalid/Not Submitted",
      checked: true
    },
    {
      // Assigned random number as this is not an actual key and value in database grade level reference table
      value: '-100',
      name: "Not Associated with Enrollment/Grade Level",
      checked: false
    }
  ];
  activeExportReportFilter: any;
  activeGradeLevelArray: any;
  activeEnrollmentDate: any;
  displayDateError: boolean;
  currentPeriod: string;
  dateObj: any;
  startYear: any;
  endYear: any;
  recentPeriod: string;
  _ReportsService: any;
  exportStartEnrollmentDate: string;
  exportEndEnrollmentDate: string;
  activeWithdrawalDate: any;
  response: any;
  enrollmentDate: any = { 
    startMM: '',
    startDD: '',
    startYYYY: '', 
    endMM: '',
    endDD: '',
    endYYYY: '' 
  };

  exportCallAsPDF: string;
  
  textValue: string = '';
  exportCallAsWord: string;
  CORFilterParameters: any = {};

  constructor(
    private _AuthService: AuthService,
    private _Worklist: WorklistService,
    private _Search: SearchService,
    private _SavedList: SavedListService,
    private stateService: StateService,
    public dialog: MatDialog,
    private toast: ToastService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public responsiveUI: ResponsiveUIService,
    public htmlExportService: HTMLExportService
  ){
    this.activatedRoute.queryParams.subscribe(params => {
      this.msixId = params['msixId'];
      if(params['studentName']) {
        this.studentName = params['studentName'];
      }
      this.view = params['historical'] ? 'historical' : null;
      this.stateId = params['stateId'] ? params['stateId'] : '';
      this.state = params['state'] ? params['state'] : '';
    });
  }

  ngOnInit() {
    this.showQualInfo = true;
    this.showAcademicInfo = true;
    this.showOverview = true;
    this.resetExportFilter();
    this.setExportCall();
    //this.getStudentDetails();
    this.checkRoleClasses();
    this.getStateList();
    // For new students from Near match, Validate merge worklists 
    // msix id will be missing and so this API call should not be made.
    if (this.msixId) {
      this.getSaveListsforMsixId(this.msixId);
    }
    // this.getDateInfo();

  }

  setExportCall() {
    this.exportCallAsWord = AppConstants.api + this.getAPIPath + this.msixId + 
      this.exportType.word + "?reportType=" + this.reportType;
    this.exportCallAsPDF = AppConstants.api + this.getAPIPath + this.msixId + 
      this.exportType.pdf + '?reportType=' + this.reportType;
  }

  openDialog() {
    this.showFilter = true;

    const dialogRef = this.dialog.open(AddEditStudentToListDialogComponent, {
      height: 'fit-content',
      width: '650px',
      hasBackdrop: true,
      disableClose: false,
      data: {
        currentlySavedLists: this.checkedLists,
        isSaved: this.isSaved
      } as IAddEditRemoveStudentFromListDialogData,
    });

    dialogRef.afterClosed().subscribe((result: Omit<IStudentListsUpdatePayload, 'msixID'>) => {
      this.showFilter = false;
      if (!result) {
        return;
      }
      this.updateStudentToSavedLists({...result, msixID: this.msixId});
    });
  }

  checkRoleClasses() {
    this._AuthService.isRoleClassAuthorized(['initiateMerge', 'privacyEdit', 
    'worklist', 'sendInfo', 'superUser']).then(data => {
      console.log("Role classes data: ", data);
        this.authRoleClasses = data;
        if (this.msixId) {
          if (this.view === 'historical') { //for showing historical view coming from child count student details
            this.showHistoricalView();
          }
          this.getStudentDetails(); //get student details after getting role classes
        } else {
          this.showHistoricalView();
        }
    });
  }
  
  // Show and retrieve data for raw view
  showRawView() {
    console.log("Getting raw data...");
    if (this.msixId) {
      this._Search.getStudentRawDetails(this.msixId).subscribe({
        next: data => {
          console.log("Raw Data: ", data);
          let userActivity = {
            msixID: this.msixId,
            pageName: 'APP_STU_VW_RAW',
            activityNotes: '',
            sessionID: ''
          };
          for (let student of data.ssinlist) {
            for (let enr of student.enrollments) {
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\n\\r/g, '<br/>');
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\n/g, '<br/>');
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\r/g, '<br/>');
            }
          }
          this._Search.logUserActivity(userActivity);
          this.handleRawView(data);
        },
        error: err => {
          console.log("Student Detail errors:", err);
        }});
    } else {
      this._Search.getStudentRawDetailsAlt(this.stateId, this.state).subscribe({
        next: data => {
          this.handleRawView(data);
        },
        error: err => {
          console.log("Raw View Error:", err);
        }});
    }
  }
  
  handleRawView(res) {
    console.log("Raw View Res:", res);
    this.sectionTitle = "Raw Student View";
    this.reportType = 'raw';
    this.setExportCall();
    this.showConsolidated = false;
    this.showHistorical = false;
    this.showRaw = true;
    this.rawHistoricalDataElements = res.ssinlist;
    if (!this.studentName) {
      this.studentName = res.ssinlist[0].demographics[0].firstName + ' ' + res.ssinlist[0].demographics[0].lastName1 + ' ' + res.ssinlist[0].demographics[0].lastName2;
      this.student.gender = res.ssinlist[0].demographics[0].gender;
      this.student.stateStudentId = this.stateId;
      this.student.statePostalCode = res.ssinlist[0].demographics[0].statePostalCode;
    }
  }
  
  // Show consolidated view
  showConsolidatedView() {
    this.showRaw = false;
    this.showHistorical = false;
    this.showConsolidated = true;
    this.reportType = 'consolidated';
    this.setExportCall();
    let userActivity = {
      msixID: this.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this._Search.logUserActivity(userActivity);
  }

  // Show and retrieve data for historical view
  showHistoricalView() {
    
    console.log("Getting historical view data....");
    if (this.msixId) {
      this._Search.getStudentHistoricalDetails(this.msixId).subscribe({
        next: data => {
          console.log("Historical view data: ", data);
          let userActivity = {
            msixID: this.msixId,
            pageName: 'APP_STU_VW_HIST',
            activityNotes: '',
            sessionID: ''
          };
          for (let student of data.ssinlist) {
            for (let enr of student.enrollments) {
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\n\\r/g, '<br/>');
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\n/g, '<br/>');
              enr.enrollmentComment = enr.enrollmentComment.replace(/\\r/g, '<br/>');
            }
          }
          this._Search.logUserActivity(userActivity);
          this.handleHistoricalView(data);
        },
        error: error => {
          console.log("Historical View Error:", error);
          this.toast.showToast(error.developerMessage, 0);
        }});
    } else {
      this._Search.getStudentHistoricalDetailsAlt(this.stateId, this.state).subscribe({
        next: data => {
          this.handleHistoricalView(data);
        },
        error: error => {
          console.log(error);
          this.errors = error.data.errors;
          this.toast.showToast(error.developerMessage, 0);
    }});
    }
  }

  handleHistoricalView(res) {
    this.sectionTitle = "Historical Student View";
    this.reportType = 'historical';
    this.setExportCall();
    this.showConsolidated = false;
    this.showRaw = false;
    this.showHistorical = true;
    this.rawHistoricalDataElements = res.ssinlist;
    this.replaceQualMoveStateNameWithAbbr(this.rawHistoricalDataElements);
    if (!this.studentName) {
      this.studentName = res.ssinlist[0].demographics[0].firstName + ' ' + res.ssinlist[0].demographics[0].lastName1 + ' ' + res.ssinlist[0].demographics[0].lastName2;
      this.student.stateStudentId = this.stateId;
      this.student.statePostalCode = res.ssinlist[0].demographics[0].statePostalCode;
    }
  }

  replaceQualMoveStateNameWithAbbr(states) {
    var outerThis = this;
    states.forEach(function (state) {
      state.qualifyingMoves.forEach(function (qualMove) {
        var stateAbbr = outerThis.abbreviateStates([qualMove.qadFromState]);
        qualMove.qadFromState = stateAbbr;
      });
    });
  }

  getStudentDetails() {
    this.loadComplete = false;
    //Make API Call to get all data for page
    this._Search.getStudentDetails(this.msixId).subscribe({
      next: data => {
        console.log("Data: ", data);
        this.loadComplete = true;
        for (var enr of data.enrollments) {
          enr.enrollmentComment = enr.enrollmentComment.replace(/\\n\\r/g, '<br/>');
          enr.enrollmentComment = enr.enrollmentComment.replace(/\\n/g, '<br/>');
          enr.enrollmentComment = enr.enrollmentComment.replace(/\\r/g, '<br/>');
        }
        //Save data, set city names, and set age.
        this.storeDataHelper(data);
        this.getCityNamesHelper();
        this.calculateAge(data.birthDate);
        //check if student is already in worklist
        if (this.authRoleClasses.worklist) {
          this.inMergeWorklist = data.flaggedForMerge;
          this.inSplitWorklist = data.flaggedForSplit;
          this.inPendingMergeWorklist = data.pendingMergeValidation;
          this.inPendingSplitWorklist = data.pendingSplitValidation;
        }
      },
      error: error => {
        console.log('Error');
        this.loadComplete = true;
        // console.log(error);
        // this.errors = error.data.errors;
        // this.toast.showToast(error.data.developerMessage, 0);
        // rolesSubscribed.unsubscribe();
      }
    });
  }

  storeDataHelper(res) {
    this.isSaved = res.saved;
    this.student = res;
    this.qualMoves = res.qualMoves[0];
    if (this.qualMoves) {
      var stateAbbrev = this.abbreviateStates([this.qualMoves.qadFromState]);
      this.qualMoves.qadFromState = stateAbbrev;
    }
    this.enrollments = res.enrollments;
    console.log("Enr length: ", this.enrollments);
    this.studentName = res.studentName;
    this.assessments = res.assessments;
    this.canSplit = res.canSplit;
    this.courses = res.courses;
    this.graduationHSEIndicator = res.graduationHSEIndicator;
    this.graduationHSEDate = res.graduationHSEDate;
    this.algebra1orEquivalentIndicator = res.algebra1orEquivalentIndicator;
    this.submittingStates = this.abbreviateStates(res.submittingStates);
  }

  abbreviateStates(statesList) {
    let states = [
      ['Arizona', 'AZ'],
      ['Alabama', 'AL'],
      ['Alaska', 'AK'],
      ['Arkansas', 'AR'],
      ['California', 'CA'],
      ['Colorado', 'CO'],
      ['Connecticut', 'CT'],
      ['Delaware', 'DE'],
      ['Florida', 'FL'],
      ['Georgia', 'GA'],
      ['Hawaii', 'HI'],
      ['Idaho', 'ID'],
      ['Illinois', 'IL'],
      ['Indiana', 'IN'],
      ['Iowa', 'IA'],
      ['Kansas', 'KS'],
      ['Kentucky', 'KY'],
      ['Louisiana', 'LA'],
      ['Maine', 'ME'],
      ['Maryland', 'MD'],
      ['Massachusetts', 'MA'],
      ['Michigan', 'MI'],
      ['Minnesota', 'MN'],
      ['Mississippi', 'MS'],
      ['Missouri', 'MO'],
      ['Montana', 'MT'],
      ['Nebraska', 'NE'],
      ['Nevada', 'NV'],
      ['New Hampshire', 'NH'],
      ['New Jersey', 'NJ'],
      ['New Mexico', 'NM'],
      ['New York', 'NY'],
      ['North Carolina', 'NC'],
      ['North Dakota', 'ND'],
      ['Ohio', 'OH'],
      ['Oklahoma', 'OK'],
      ['Oregon', 'OR'],
      ['Pennsylvania', 'PA'],
      ['Rhode Island', 'RI'],
      ['South Carolina', 'SC'],
      ['South Dakota', 'SD'],
      ['Tennessee', 'TN'],
      ['Texas', 'TX'],
      ['Utah', 'UT'],
      ['Vermont', 'VT'],
      ['Virginia', 'VA'],
      ['Washington', 'WA'],
      ['West Virginia', 'WV'],
      ['Wisconsin', 'WI'],
      ['Wyoming', 'WY']
    ];

    let submittingStates = '';
    for (let state of statesList) {
      for (let i = 0; i < states.length; i++) {
        if (states[i][0] === state) {
          if (submittingStates === '') {
            submittingStates = states[i][1];
          } else {
            submittingStates = submittingStates + ', ' + states[i][1];
          }
        }
      }
    }
    return submittingStates;
  }

  getStateAbbrFromName(stateName) {
    var stateAbbrev = "";
    this.states.forEach(function (state) {
      if (stateName.toUpperCase() === state.name.toUpperCase()) {
        stateAbbrev = state.stateAbbrev;
      }
    });
    return stateAbbrev;
  }
  
  getCityNamesHelper() {
    if (this.qualMoves && this.qualMoves.qadFromCity) {
      this.moveFromCity = this.capitalizeFirstLetter(this.qualMoves.qadFromCity.toLowerCase());
    }
    if (this.qualMoves && this.qualMoves.qadToCity) {
      this.moveToCity = this.capitalizeFirstLetter(this.qualMoves.qadToCity.toLowerCase());
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  calculateAge(birthday) { //(code taken from stackoverflow)
    var bday = new Date(birthday);
    var ageDifMs = Date.now() - bday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    this.studentAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  //Adds a student record to the selected lists
  //Removes a student record from the unselected lists
  updateStudentToSavedLists(payload: IStudentListsUpdatePayload) {
   this._SavedList.updateStudentToSavedLists(payload).pipe(
    tap(() => {
      this.getStudentDetails();
      this.getSaveListsforMsixId(this.msixId)})
   ).subscribe();
  }

  flagForMerge() {
    if (!this.inMergeWorklist && !this.inPendingMergeWorklist) {
      let apiCall = this._Worklist.flagStudentforMerge(this.msixId);
      let subscribed = apiCall.subscribe({
        next: data => {
          this.inMergeWorklist = true;
          this.toast.showToast('Student has been added to merge worklist');
          console.log(data);
          subscribed.unsubscribe();
        },
        error: err => {
          if(err.developerMessage==='Student successfully flagged for merge.') {
            this.toast.showToast('Student has been added to merge worklist');
          } else {
            this.toast.showToast(err.developerMessage, 0);
          }
          console.log(err);
          subscribed.unsubscribe();
        }
      });
    }
  }

  flagForSplit() {
    let apiCall = this._Worklist.flagStudentforSplit(this.msixId);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.inSplitWorklist = true;
        this.toast.showToast('Student has been added to split worklist');
        console.log(data);
        subscribed.unsubscribe();
      },
      error: err => {
        this.toast.showToast(err.developerMessage, 0);
        console.log(err);
        subscribed.unsubscribe();
      }
    });
  }

  openExportMenu($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  }

  showEnrDetailsFn(index) {
    //Not in array: add, and set enrFlag to open
    if (this.indexArray.indexOf(index) === -1) {
      this.indexArray.push(index)
      this.enrFlag[index] = index;
    } else {
      var idx = this.indexArray.indexOf(index)
      this.indexArray.splice(idx, 1)
      this.enrFlag[index] = -1;
    }
  }

  showAssessDetailsFn(index) {
    if (this.assessIndex === index) {
      this.assessIndex = -1;
    } else {
      this.assessIndex = index;
    }
  }

  showCourseDetailsFn(index) {
    if (this.courseIndex === index) {
      this.courseIndex = -1;
    } else {
      this.courseIndex = index;
    }
  }

  showRawDemographics(index) {
    if (this.rawDemographicsIndex === index) {
      this.rawDemographicsIndex = -1;
    } else {
      this.rawDemographicsIndex = index;
    }
  }



  openAssociatedEnrollment(item, type) {
    /* Need to implement functionality once back end changes are made.
    Loop through enrollments and find the one that this item is associated with
    and open that enrollment up */
   }


   //COR Model
   //Dialog
  changeOfResidencyModal(ev, corType) {
    this.submitDisabled = true;
    this.corObject = {
      comments: '',
      isLeaving: true,
      district: null,
      school: {},
      state: null
    };
    this.selectedState = {
      stateIndex: ''
    };
    this.selectedDistrict = {
      districtIndex: ''
    };
    this.selectedSchool = {
      schoolIndex: ''
    };
    this.districts = [];
    this.schools = [];
    this.corType = corType;
    this.mainPage = "studentDetails";
    this.corDialogActivate();
  }

  corDialogActivate() {
    this.showFilter = true;
    this.CORFilterParameters.studentName = this.studentName;
    this.CORFilterParameters.msixId = this.msixId;
    this.CORFilterParameters.student = this.student;
    this.CORFilterParameters.states = this.states;
    this.CORFilterParameters.districts = this.districts;
    this.CORFilterParameters.schools = this.schools;
    this.CORFilterParameters.corType = this.corType;
    this.CORFilterParameters.mainPage = this.mainPage;
    this.CORFilterParameters.corObject = this.corObject;
    this.CORFilterParameters.selectedSchool = this.selectedSchool;
    this.CORFilterParameters.selectedState = this.selectedState;
    this.CORFilterParameters.selectedDistrict = this.selectedDistrict;
    const dialogRef = this.dialog.open(CORDialog, {
      height: 'auto',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.CORFilterParameters },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("result: ", result);
      //Prevent re-submission if dialog is just closed
      if(result){
        this.CORFilterParameters = result;
        this.CORFilterParameters.studentName = result.studentName;
        this.msixId = result.msixId;
        this.student = result.student;
        this.states = result.states;
        this.districts = result.districts;
        this.schools = result.schools;
        this.corType = result.corType;
        this.reportElements = result.reportElements;
        this.corObject = result.corObject;
        this.selectedSchool = result.selectedSchool;
        this.selectedState = result.selectedState;
        this.selectedDistrict = result.selectedDistrict;
        this.submitFilterCOR();
      }
      this.showFilter = false;
    });
  }

  // may change once comps are finalized
  async getStateList() {
      this.states = await this.stateService.getStates();
    }

  enableSubmit() {
    //set the move notification object
    this.corObject.school = this.schools[this.selectedSchool.schoolIndex];
  }

  submitFilterCOR() {
    if (this.corType == 'moveNotice') {
      this.submitMoveNotice();
    } else if (this.corType == 'requestData') {
      this.submitDataRequest();
    }
  }

  submitMoveNotice() {
    let apiCall = this._Worklist.initiateMoveNotification(this.msixId, this.corObject);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        this.indicator = false;
        console.log(data);
        this._Worklist.getWorklistCount();
        this.toast.showToast('Your move notification has been sent');
        subscribed.unsubscribe();
      },
      // Callback for failure
      error: err => {
        this.indicator = false;
        console.log(err);
        this.errors = err.data.errors;
        this.toast.showToast(err.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  submitDataRequest() {
    var msixId = this.msixId;
    var dataRequestObject = this.corObject;

    let apiCall = this._Worklist.initiateDataRequest(msixId, dataRequestObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.indicator = false;
        console.log(data);
        this._Worklist.getWorklistCount();
        this.toast.showToast('Your data request has been sent');
        subscribed.unsubscribe();
      },
      // Callback for failure
      error: err => {
        console.log(err);
        this.errors = err.data.errors;
        this.toast.showToast(err.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  updatePrivacyActComment(comment) {
    this.privAct.controls['comments'].setValue(comment);  
  }

  submitPrivacyActComment() {
    if (this.privAct && this.privAct.value.comments && this.privAct.value.stateStudentKey) {
      if (this.privAct.value.privActKey) {
        let apiCall = this._Search.updatePrivacyActComments(this.privAct);
        let subscribed = apiCall.subscribe({
          next: data => {
            this.privAct = null;
            console.log(data);
            this.getStudentDetails();
            this.toast.showToast('The privacy act comment was successfully updated.');
            subscribed.unsubscribe();
          },
          error: err => {
            console.log(err);
            this.toast.showToast(err, 0);
            subscribed.unsubscribe();
          }
        });
      } else {
        let apiCall = this._Search.updatePrivacyActComments(this.privAct);
        let subscribed = apiCall.subscribe({
          next: data => {
            this.privAct = null;
            console.log(data);
            this.getStudentDetails();
            this.toast.showToast('The privacy act comment was successfully submitted.');
            subscribed.unsubscribe();
          },
          error: err => {
          console.log(err);
          this.toast.showToast(err.developerMessage, 0);
          subscribed.unsubscribe();
          }
        });
      }
    }
  }

  exportPrint() {
    var localScope = this;
    this.printView = true;
    this.htmlExportService.hideToolbar();
    setTimeout(function () {
      this.print();
    }, 100);
    setTimeout(function () {
      localScope.printView = false;
    }, 101);
    this.htmlExportService.showToolbar();

    //Log the download of exporting of a CSR in HTML format
    let userActivity = {
      msixID: this.msixId,
      pageName: 'APP_STU_HTML_DWNLD_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this._Search.logUserActivity(userActivity);
  }

  exportFile(fileType) {
    if (fileType === 'PDF') {
      //Log the download of exporting of a CSR in PDF format
      let userActivity = {
        msixID: this.msixId,
        pageName: 'APP_STU_PDF_DWNLD_CONS',
        activityNotes: '',
        sessionID: ''
      }
      this._Search.logUserActivity(userActivity);
    } 
    else if (fileType === 'WORD') {
      //Log the download of exporting of a CSR in WORD format
      let userActivity = {
        msixID: this.msixId,
        pageName: 'APP_STU_WORD_DWNLD_CONS',
        activityNotes: '',
        sessionID: ''
      }
      this._Search.logUserActivity(userActivity);
    }
    else {
      let apiCall = this._Search.exportStudentFileToState(this.msixId);
      // let apiCall = this._Search.exportStudentFile(this.msixId)
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log(data);
          this.toast.showToast(data);
          subscribed.unsubscribe();
        },
        error: err => {
          console.log(err);
          this.errors = err.errors;
          if (err.error.text === "The file containing the student's record has been uploaded to your state's account for further processing.") {
            this.toast.showToast(err.error.text);
          } else {
            this.toast.showToast(err.developerMessage, 0);
          }
          subscribed.unsubscribe();
        }
      });

      //Log the download of exporting of a CSR in STATE format
      let userActivity = {
        msixID: this.msixId,
        pageName: 'APP_STU_STATE_DWNLD_CONS',
        activityNotes: '',
        sessionID: ''
      }
      this._Search.logUserActivity(userActivity);
    }
  }

  getSaveListsforMsixId(msixId) {
    let apiCall = this._SavedList.getSaveListsForMsixId(msixId);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log(data);
        this.checkedLists = data;
      },
      error: error => {
        console.log(error);
        this.toast.showToast(error.developerMessage, 0);
      }
    });
  }

  //Functionality reduced to only remove the student from saved lists while in mobile view
  removeStudentInMobile() {
    for (let list of this.checkedLists) {
      let unCheckedIndex = this.uncheckedLists.indexOf(list);
      if (unCheckedIndex === -1) {
        this.uncheckedLists.push(list);
      }
    }

    this.savelistObject = {
      msixID: this.msixId,
      savedListKeys: [],
      unsavedListKeys: this.uncheckedLists,
      newListName: this.newListName
    }

    let apiCall = this._SavedList.updateStudentToSavedLists(this.savelistObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log(data);
        this.getStudentDetails();
        
        this.checkedLists = [];
        this.uncheckedLists = [];
        this.newCheckedLists = [];
        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        this.toast.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //focuses on the next input of the Enrollment date bassed of the parameter passed in
  skipToNextDateInput(currentInput, nextElementId) {
    if (currentInput.length > 1) {
      document.getElementById(nextElementId).focus();
      this.formatDate();
    }
  }

  //Checks all options in the list parameter provided
  selectAllExportFilter(list) {
    if (list === 'elements') {
      for (let element of this.reportElements) {
        element.checked = true;
      }
    } else if (list === 'gradeLevel') {
      for (let gradeLevel of this.gradeLevels) {
        gradeLevel.checked = true;
      }
      this.setGradeLevels();
    }
  }

  //Unchecks all options in the list parameter provided
  selectNoneExportFilter(list) {
    if (list === 'elements') {
      for (let element of this.reportElements) {
        element.checked = false;
      }
    } else if (list === 'gradeLevel') {
      for (let gradeLevel of this.gradeLevels) {
        gradeLevel.checked = false;
      }
      this.filterParameters.gradeLevels = [];
    }
  }

  //Opens the Record Report export filter
  activateExportFilter() {
    this.activeExportReportFilter = this.filterParameters;
    this.activeGradeLevelArray = this.gradeLevels;

    this.filterParameters.enrollmentDate = cloneDeep(this.enrollmentDate);
    this.filterParameters.gradeLevels = cloneDeep(this.gradeLevels);
    this.filterParameters.reportElements = cloneDeep(this.reportElements);
    this.filterParameters.displayDateError = cloneDeep(this.displayDateError);

    this.showFilter = true;
    const dialogRef = this.dialog.open(CustomExportFilter, {
      height: 'auto',
      width: '61%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("result: ", result);
      //Prevent re-submission if dialog is just closed
      if(result){
        this.filterParameters = result;
        this.reportElements = result.reportElements;
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // Close the filter modal
  closeFilterDialog() {
    this.resetExportFilter();
    this.$mdDialog.cancel();
  }

  //resets the filter for the record report export
  resetExportFilter() {
    this.displayDateError = false;
    this.filterParameters = {
      criteria: 'default',
      language: 'English'
    };
    this.resetGradeLevels();
    this.enrollmentDate = {};
    //Select all report elements except for  'Additional Guidance'
    this.selectAllExportFilter('elements');
    this.reportElements[2].checked = false;
    this.reportElements[3].checked = false;
  }

  //resets grade levels to select all grade levels except for 'Assessments Not Associated with Enrollment/Grade Level'
  resetGradeLevels(){
    this.selectAllExportFilter('gradeLevel');
    for (let gradeLevel of this.gradeLevels) {
      if (gradeLevel.value == '-100') {
        gradeLevel.checked = false;
      }
    }
  }

  //resets the 'Select Filter Criteria' radio button options when switching 
  setExportCriteria(){
    if (this.filterParameters.criteria =='default') {
      this.resetGradeLevels();
      this.enrollmentDate = {};
      this.displayDateError = false;
    }
    else if (this.filterParameters.criteria =='enrollmentDate'){
      this.resetGradeLevels();
    }
    else if (this.filterParameters.criteria =='gradeLevel'){
      this.enrollmentDate = {};
      this.displayDateError = false;
    }
  }

  //updates the 'checked' property for the updated report elements checkbox on the filter popup. 
  checkedElements(v) {
    var element = this.reportElements.find(({ value }) => value === v);
    element.checked = !element.checked;
  }

  //formats how the performance periods will be displayed on the filter
  updateDisplayDate() {
    if (this.filterParameters.periodType === 'Current') {
      this.currentPeriod = `Current performance period (${this.dateObj.current})`;
      this.startYear = this.dateObj.currentStartYear;
      this.endYear = this.dateObj.currentEndYear;
    } else {
      this.recentPeriod = `Last performance period (${this.dateObj.recent})`;
      this.startYear = this.dateObj.recentStartYear;
      this.endYear = this.dateObj.recentEndYear;
    }
  }

  //retrieves dates for the current and most recently closed performance period
  getDateInfo() {
    this._ReportsService.getCurrentReportingPeriod().then(
      (res) => {
        this.dateObj.current = res.data.formattedPeriod;
        this.dateObj.currentStartYear = res.data.startYear;
        this.dateObj.currentEndYear = res.data.endYear;
        this._ReportsService.getRecentClosedReportingPeriod().then(
          (res) => {
            this.dateObj.recent = res.data.formattedPeriod;
            this.dateObj.recentStartYear = res.data.startYear;
            this.dateObj.recentEndYear = res.data.endYear;
            this.updateDisplayDate();
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Set the comma separated list of grade level keys that will be sent to API call based on the Grade Level filter selection
  setGradeLevels() {
    let tempGradeLevelArray = [];
    for (var x = 0; x < this.gradeLevels.length; x++) {
      let gradeLevel = this.gradeLevels[x];
      if (gradeLevel.checked) {
        if (gradeLevel.value === '-100') {
          this.filterParameters.nonAssociatedWithEnrollment = true;
        } else {
          tempGradeLevelArray.push(gradeLevel.value);
        }
      } else {
        if (gradeLevel.value === '-100') {
          this.filterParameters.nonAssociatedWithEnrollment = false;
        }
      }
    }

    this.filterParameters.gradeLevels = tempGradeLevelArray.join();
  }

  // format string date into UNIX and set filter parameter
  formatDate() {
    let startEnrollmentDate = '';
    let endEnrollmentDate = '';
    if (this.enrollmentDate.startYYYY && this.enrollmentDate.startMM) {
      if (this.enrollmentDate.startDD){
        startEnrollmentDate = this.enrollmentDate.startYYYY + '-' + this.enrollmentDate.startMM + '-' + this.enrollmentDate.startDD;
        this.exportStartEnrollmentDate = this.enrollmentDate.startMM + '/' + this.enrollmentDate.startDD + '/'+ this.enrollmentDate.startYYYY;
      } else {
        startEnrollmentDate = this.enrollmentDate.startYYYY + '-' + this.enrollmentDate.startMM;
        this.exportStartEnrollmentDate = this.enrollmentDate.startMM + '/' + this.enrollmentDate.startYYYY;
      }
      
    }

    if (this.enrollmentDate.endYYYY && this.enrollmentDate.endMM) {
      if (this.enrollmentDate.endDD){
        endEnrollmentDate = this.enrollmentDate.endYYYY + '-' + this.enrollmentDate.endMM + '-' + this.enrollmentDate.endDD;
        this.exportEndEnrollmentDate = this.enrollmentDate.endMM + '/' + this.enrollmentDate.endDD + '/' + this.enrollmentDate.endYYYY;
      } else {
        endEnrollmentDate = this.enrollmentDate.endYYYY + '-' + this.enrollmentDate.endMM;
        this.exportEndEnrollmentDate = this.enrollmentDate.endMM + '/' + this.enrollmentDate.endYYYY;
      }
      
    }
    
    if (startEnrollmentDate && endEnrollmentDate) {
      if (this.enrollmentDate.startDD && this.enrollmentDate.endDD && moment(endEnrollmentDate).isBefore(startEnrollmentDate)){
        this.displayDateError = true;
      } 
      else if (moment(endEnrollmentDate).isBefore(startEnrollmentDate, 'month')){
        this.displayDateError = true;
      } 
      else {
        this.displayDateError = false;
      }
    }
    
  }

  //submit the filter for the record report export
  submitFilter() {
    this.activeExportReportFilter = this.filterParameters;
    this.activeEnrollmentDate = this.enrollmentDate;
    this.activeWithdrawalDate = this.withdrawalDate;
    let exportObj = {
      courseHistory: this.reportElements[0].checked,
      assessments: this.reportElements[1].checked,
      enrollmentComments: this.reportElements[2].checked,
      guidance: this.reportElements[3].checked,
      language: this.filterParameters.language,
      enrollmentDateStart: '',
      enrollmentDateEnd: '',
      gradeLevels: ''
    }
    console.log("this.filterParameters.criteria: ", this.filterParameters.criteria);
    if (this.filterParameters.criteria == "enrollmentDate") {
      exportObj.enrollmentDateStart = this.filterParameters.exportStartEnrollmentDate;
      exportObj.enrollmentDateEnd = this.filterParameters.exportEndEnrollmentDate;
      console.log("exportObj: ", exportObj);
    }
    else if (this.filterParameters.criteria == "gradeLevel") {
      this.setGradeLevels();
      exportObj.gradeLevels = this.filterParameters.gradeLevelsString;
    }
    let apiCall = this._Search.recordReportExport(this.msixId, exportObj);
    let subscribed = apiCall.subscribe({
      next: data => {
        //Log the download of exporting of a CSR in Custom PDF format
        let userActivity = {
          msixID: this.msixId,
          pageName: 'APP_STU_PDF_DWNLD_CONS',
          activityNotes: '',
          sessionID: ''
        }
        this._Search.logUserActivity(userActivity);
        
        this.response = data;
        this.pdfExportHandler(this.response, this.filterParameters);

        // this.closeFilterDialog();
        console.log(data);
        
        subscribed.unsubscribe();
      },
      error: err => {
        console.log(err);
        subscribed.unsubscribe();
      }
    });
  }
  withdrawalDate(withdrawalDate: any): any {
    throw new Error('Method not implemented.');
  }

  //Take code from export report
  pdfExportHandler(data, scope) {
    console.log("In method:", data);
    var header = data.headers?.get('content-disposition');
    var date = moment().format('MM/DD/YYYY');
    var fileName = "report" + date + ".pdf";
    if (data.headers) {
      try {
        fileName = header?.split('attachment; filename=').pop();
      } catch (err) { console.log("Export error"); }
    }

    var file = new Blob([data.body], {
      type: 'application/pdf'});
    var fileURL = window.URL.createObjectURL(file);
    var a = document.createElement('a');
    
    //Download blob
    if (window.navigator && navigator.msSaveBlob) {
      console.log("Downloading blob from navigator.msSaveBlob");
      navigator.msSaveBlob(file, fileName);
    } else {
      console.log("Downloading blob regularly");
      a.href = fileURL;
      a.target = '_blank';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    }
  }
}
