<div *ngIf="splitWorklist.length == 0 && validateSplitItems.length == 0" class="layout-margin-worklist-module layout-padding-worklist-module">
  <h2>No Split items found.</h2>
</div>

<!-- START Split Worklist Table Desktop -->

<div *ngIf="responsiveUI.isDesktop && splitWorklist.length > 0" 
    class="layout-margin-worklist-module layout-padding-worklist-module worklist-page">
  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Flagged for Split</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders">
      <p class="padding-6px worklist-description-text">
        Initiate a split by selecting a single record that should be split from the master record and clicking "Process
        Split" at the bottom of the list. You can only split one record from the master record at a time. Remove a
        student record from your list by expanding the record and clicking "Remove from List.” Split flags are
        automatically removed after 14 days from the created date.
      </p>
    </div>

    <div class="worklist-header-titles layout-flex-row flex">
      <div class="flex-95">
        <div class="flex-container flex-90">
          <div class="block2 flex-20">
            <a (click)="sortColumns('column1')">
              Student(s)
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column2')">
              State
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column3')">
              Sex
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column4')">
              DOB
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column5')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column6')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column7')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-flex-row table2Row" *ngFor="let student of splitWorklist" style="flex: 100%">
      <div class="flex-95 background-white">
        <div class="layout-flex-row">
          <div style="flex: 90%" (click)="showSplitDetailsFn(student.demographics.msixId)" class="layout-flex-row section1">
            <span style="flex: 20%" class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p flex>
                {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p flex >{{student.demographics.submittingStateAbbv}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p flex>{{student.demographics.gender}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p flex>{{student.demographics.birthDate}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p flex>{{student.demographics.msixId}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column6'}">
              <p flex>{{student.demographics.studentStateID}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p class="flex layout-flex-column">
                <span class="days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                <span class="days-in-list">{{daysRemaining(student.dateCreated)}} remaining</span>
              </p>
            </span>
          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div>
        </div>

        <div class="flex layout-flex-row section2 worklist-accordion-section2 background-grey1 left-right-padding-20"
          *ngIf="studentSplitIndex === student.demographics.msixId">
          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 1:</b></p>
              <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Email Address:</b></p>
              <p>{{student.demographics.parent1EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Phone Number:</b></p>
              <p>{{student.demographics.parent1PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 2:</b></p>
              <p>{{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Email Address:</b></p>
              <p>{{student.demographics.parent2EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Phone Number:</b></p>
              <p>{{student.demographics.parent2PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 top-bottom-margin-8 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>State ID:</b> {{student.demographics.studentStateID}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Multi-birth:</b></p>
              <p>{{student.demographics.multipleBirthFlag}}</p>
            </div>
          </div>
        </div>

        <md-divider></md-divider>

        <div class="flex layout-flex-row section2 worklist-accordion-section2 background-grey1 left-right-padding-20"
          *ngIf="studentSplitIndex === student.demographics.msixId">
          <div class="flex radio-input float-right">
            <mat-radio-group>
              <div class="flex top-bottom-margin-15 worklist-split-children-bottom-border" *ngFor="let studentChild of student.children">
                <div style="flex: 100%" class="background-white height-45 worklist-split-children-bottom-border-green flex-container splitRadioGroup layout-flex-row">
                  <div class="flex-95 worklist-validate-accordion">
                      <mat-radio-button [value]="studentChild" [disabled]="!(currentUser.userStateName == studentChild.demographics.submittingState)"
                        (click)="!(currentUser.userStateName == studentChild.demographics.submittingState) || selectChildforSplit(studentChild)"
                        style="flex: 100%" class="no-bottom-margin" [ngClass]="studentChild === nominatedStudentChild ? 'md-checked' : ''">
                        <div class="flex layout-flex-row flex-inline">
                          <a style="width: 200px" (click)="viewDetails(student)">{{studentChild.demographics.lastName1}}
                            <span class="flex removeUnderline" *ngIf="studentChild.demographics.lastName2 !=''"> 
                              {{studentChild.demographics.lastName2}}</span>, {{studentChild.demographics.firstName}}
                              <span class="removeUnderline" *ngIf="studentChild.demographics.midName != ''"> 
                                {{studentChild.demographics.midName}}</span></a>
                          <p style="width: 155px;">{{studentChild.demographics.submittingStateAbbv}}</p>
                          <p style="width: 155px;">{{studentChild.demographics.gender}}</p>
                          <p style="width: 155px;">{{studentChild.demographics.birthDate}}</p>
                        </div>
                      </mat-radio-button>
                  </div>
                  <div (click)="showSplitChildFn(student.demographics.msixId)" class="flex-5 expandColumn-white flex-container">
                    <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(splitChildIndex === student.demographics.msixId)"></i>
                    <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="splitChildIndex === student.demographics.msixId"></i>
                  </div>
                </div>

                <div class="flex layout-row worklist-history-accordion background-white" *ngIf="splitChildIndex === student.demographics.msixId">
                  <div class="col1 flex-25 layout-column">
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 1:</b></p>
                      <p>{{studentChild.demographics.maleParentFirstName}}
                        {{studentChild.demographics.maleParentLastName}}
                      </p>
                    </div>
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 1 Email Address:</b></p>
                      <p>{{student.demographics.parent1EmailAddress}}
                      </p>
                    </div>
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 1 Phone Number:</b></p>
                      <p>{{student.demographics.parent1PhoneNumber}}
                      </p>
                    </div>
                  </div>
                  <div class="col1 flex-25 layout-column">
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 2:</b></p>
                      <p>
                        {{studentChild.demographics.femaleParentFirstName}}
                        {{studentChild.demographics.femaleParentLastName}}
                      </p>
                    </div>
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 2 Email Address:</b></p>
                      <p>{{student.demographics.parent2EmailAddress}}
                      </p>
                    </div>
                    <div class="top-bottom-margin-8">
                      <p><b>Parent 2 Phone Number:</b></p>
                      <p>{{student.demographics.parent2PhoneNumber}}
                      </p>
                    </div>
                  </div>

                  <div class="col1 top-bottom-margin-8 flex-25 layout-column">
                    <div class="top-bottom-margin-8">
                      <p>State ID: {{studentChild.demographics.studentStateID}}
                      </p>
                    </div>
                    <div class="top-bottom-margin-8">
                      <p>Multi-birth:</p>
                      <p>{{studentChild.demographics.multipleBirthFlag}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-radio-group>
          </div>
        </div>

        <div class="flex layout-flex-row section2 worklist-accordion-section2 background-grey1 left-right-padding-20 layout-align-end-start" 
          *ngIf="studentSplitIndex === student.demographics.msixId">
          <div class="layout-align-center-center">
            <a class="flex" (click)="removeFromSplitList(student)">
              <span>Remove from List</span>
            </a>
            <button class="flex" (click)="confirmNominatedSplit($event, student.demographics.msixId)" 
              [disabled]="!nominateSplitObjectOrphan"
              class="md-button confirmButton button-margin">
              Process Split
            </button>
          </div>
        </div>

      </div>

      <div style="flex: 5%" (click)="showSplitDetailsFn(student.demographics.msixId)" class="flex-5 expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(studentSplitIndex === student.demographics.msixId)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="studentSplitIndex === student.demographics.msixId"></i>
      </div>
    </div>

  </div>
</div>

<!-- END Split Worklist Table Desktop -->


<!--MOBILE AND TABLET START-->

<div class="flex worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && splitWorklist.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet split-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders top-bottom-margin-5">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow no-padding">
              <p class="worklist-section-header">Split Records</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To complete a split request, access your worklist on desktop.</p>
        </div>
        <div hide-gt-md>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of splitWorklist">
            <div class="layout-flex-row">
              <div style="flex: 65%" class="flex-65 layout-flex-column" (click)="viewDetails(student)">
                <a class="uppercase">{{student.demographics.lastName1}}<span *ngIf="student.demographics.lastName2 !=''"
                    class="removeUnderline"> {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}<span class="removeUnderline" *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                    {{student.demographics.submittingStateAbbv}}</p>
                </div>
              </div>
              <div style="flex: 35%" class="flex-35 layout-flex-row" layout-align="end center">
                <button class="mobile-worklist-remove-button remove-button" (click)="removeFromSplitList(student)">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MOBILE AND TABLET END-->
