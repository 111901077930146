<!--START Nominate Near Match Desktop-->

<div *ngIf="responsiveUI.isDesktop && nominateMatchItems.length > 0" class="worklist-page layout-margin-worklist-module layout-padding-worklist-module validate-records">

  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Near Matches</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders">
      <p class="worklist-description-text">
        Expand each row to view details of new records that may belong to a student already in MSIX. Merge the matches
        to consolidate into one record under the same MSIX ID. Reject the merge to assign a new unique MSIX ID to the
        new student.
      </p>
    </div>

    <div class="worklist-header-titles layout-flex-row" flex>
      <div style="flex: 95%">
        <div class="flex-container flex-90 layout-row">
          <div class="block2 flex-20">
            <a (click)="sortColumns('column1')">
              Student(s)
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column2')">
              Worklist ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column3')">
              State
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column4')">
              Sex
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column5')">
              DOB
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column6')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column7')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column8')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-flex-row table2Row" *ngFor="let student of nominateMatchItems" style="flex: 100%">
      <div style="flex: 95%" class="background-white">
        <div class="layout-row">
          <div class="layout-row section1 flex-90" (click)="nominateMatchDetailsFn(student.key); updateReadFlag(student)">
            <span class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.key}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.submittingStateAbbv}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.gender}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.birthDate}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column6'}" *ngIf="student.demographics.msixId == ''">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">N/A</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column6'}" *ngIf="student.demographics.msixId != ''">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.msixId}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.demographics.studentStateID}}</p>
            </span>
            <span class="flex column" [ngClass]="{highlight: currentSortColumn === 'column8'}">
              <p class="flex layout-flex-column" [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                <span class="days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                <span class="days-in-list">{{daysOnList(student.dateCreated)}} days in list</span>
              </p>
            </span>

          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div>
        </div>


        <!--new record created-->

        <div class="validate-records-open background-white" *ngIf="nominateMatchIndex === student.key">
          <div class="parentRecord" style="border: 1px solid #D3D3D3; background-color: #FAFAFA;">
            <div class="layout-flex-row flex-container section1">
              <div style="flex: 90%" class="layout-flex-row" (click)="nominateMatchDetailsFn(student.key); updateReadFlag(student)"
                class="flex-container">
                <span class="flex-20 column">
                  <a flex (click)="viewDetails(student)">
                    {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                    {{student.demographics.firstName}}
                    <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                  </a>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.key}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.demographics.submittingStateAbbv}}</p>
                </span>
                <span class="flex-10 column">
                  <p flex>{{student.demographics.gender}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.demographics.birthDate}}</p>
                </span>
                <span class="flex column" *ngIf="student.demographics.msixId == ''">
                  <p flex>N/A</p>
                </span>
                <span class="flex column" *ngIf="student.demographics.msixId != ''">
                  <p flex>{{student.demographics.msixId}}</p>
                </span>
                <span class="flex column" *ngIf="student.demographics.studentStateID != ''">
                  <p flex>{{student.demographics.studentStateID}}</p>
                </span>
                <span class="flex column">
                  <p flex>
                    <span class="days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                    <span class="days-in-list">{{daysOnList(student.dateCreated)}} days in list</span>
                  </p>
                </span>
              </div>
              <div class="flex-10 flex-container">
                <!-- empty padding space -->
              </div>
            </div>
            <div class="layout-row flex section2 worklist-accordion-section2">
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1:</b></p>
                  <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Email Address:</b></p>
                  <p>{{student.demographics.parent1EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Phone Number:</b></p>
                  <p>{{student.demographics.parent1PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2:</b></p>
                  <p>
                    {{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Email Address:</b></p>
                  <p>{{student.demographics.parent2EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Phone Number:</b></p>
                  <p>{{student.demographics.parent2PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>State ID:</b> {{student.demographics.studentStateID}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Multi-birth:</b></p>
                  <p>{{student.demographics.multipleBirthFlag}}</p>
                </div>
              </div>
              <div class="flex-25 col1">
                <a class="top-bottom-margin-8" (click)="viewDetails(student)">
                  <span class="no-underline">Full Record</span>
                </a>
              </div>
            </div>
          </div>

          <!--near match found in system record(s)-->
          <div class="near-match-records ie-flex-level-1">
            <div class="custom-label select-label top-margin-10 ie-flex-level-2 layout-flex-row">

              <div style="flex: 100%" class="ie-flex-level-1 layout-flex-column">
                <div class="layout-flex-column" *ngFor="let studentChild of student.children" class="studentRecord ie-flex-level-2">

                  <div class="layout-flex-row section1 flex-section-1">

                    <div style="flex: 90%" class="layout-flex-row">
                      <span class="flex-5 column">
                        <mat-checkbox md-no-ink class="flex no-margins" (click)="selectNearMatch(studentChild)"></mat-checkbox>
                      </span>
                      <span class="flex-20 column">
                        <a flex (click)="viewDetails(studentChild)">
                          {{studentChild.demographics.lastName1}}<span class="removeUnderline" *ngIf="studentChild.demographics.lastName2 !=''"> {{studentChild.demographics.lastName2}}</span>,
                          {{studentChild.demographics.firstName}}
                          <span *ngIf="studentChild.demographics.midName != ''"> {{studentChild.demographics.midName}}</span>
                        </a>
                      </span>
                      <span class="flex-5 column"></span>
                      <span class="flex-10 column">
                        <p flex>{{studentChild.demographics.submittingStateAbbv}}</p>
                      </span>
                      <span class="flex-10 column">
                        <p flex>{{studentChild.demographics.gender}}</p>
                      </span>
                      <span class="flex column">
                        <p flex>{{studentChild.demographics.birthDate}}</p>
                      </span>
                      <span class="flex column">
                        <p flex>{{studentChild.demographics.msixId}}</p>
                      </span>
                      <span class="flex column">
                        <p flex>{{studentChild.demographics.studentStateID}}</p>
                      </span>
                      <span class="flex column">
                        <p flex>
                          <span class="days-in-list" flex>{{convertDateCreated(studentChild.dateCreated)}}</span>
                          <span class="days-in-list" flex>{{daysOnList(studentChild.dateCreated)}} days in list</span>
                        </p>
                      </span>
                    </div>
                    <div class="flex-10 flex-container">
                      <!-- empty padding space -->
                    </div>
                  </div>

                  <div class="flex section2 worklist-validate-accordion flex-container flex-section-2">
                    <div class="layout-row flex">
                      <div class="col1 flex-25 layout-column">
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 1:</b></p>
                          <p>{{studentChild.demographics.maleParentFirstName}}
                            {{studentChild.demographics.maleParentLastName}}
                          </p>
                        </div>
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 1 Email Address:</b></p>
                          <p>{{student.demographics.parent1EmailAddress}}
                          </p>
                        </div>
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 1 Phone Number:</b></p>
                          <p>{{student.demographics.parent1PhoneNumber}}
                          </p>
                        </div>
                      </div>
                      <div class="col1 flex-25 layout-column">
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 2:</b></p>
                          <p>
                            {{studentChild.demographics.femaleParentFirstName}}
                            {{studentChild.demographics.femaleParentLastName}}
                          </p>
                        </div>
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 2 Email Address:</b></p>
                          <p>{{student.demographics.parent2EmailAddress}}
                          </p>
                        </div>
                        <div class="top-bottom-margin-8">
                          <p><b>Parent 2 Phone Number:</b></p>
                          <p>{{student.demographics.parent2PhoneNumber}}
                          </p>
                        </div>
                      </div>
                      <div class="col1 top-bottom-margin-8 flex-25 layout-column">
                        <div class="top-bottom-margin-8">
                          <p><b>State ID:</b> {{studentChild.demographics.studentStateID}}
                          </p>
                        </div>
                        <div class="top-bottom-margin-8">
                          <p><b>Multi-birth:</b></p>
                          <p>{{studentChild.demographics.multipleBirthFlag}}</p>
                        </div>
                      </div>
                      <div class="flex-25 col1" *ngIf="studentChild.demographics.msixId">
                        <a class="top-bottom-margin-8" (click)="viewDetails(studentChild)">
                          <span class="no-underline">Full Record</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!--comments-->
          <div class="layout-flex-row top-margin-10  validateMergeComments" *ngIf="nominateMatchIndex === student.key"
            flex>
            <div class="layout-flex-column" style="flex: 100%">
              <div>
                <div class="custom-input-container">
                  <mat-form-field class="md-block md-input-container-margins background-white"
                    style="border: 2px solid gray; width: 99%">
                    <mat-label class="custom-label">Comments</mat-label>
                    <textarea matInput class="custom-input comment-text" [(ngModel)]="nominateMatchObject.comments" maxlength="250" md-maxlength="250"
                      rows="2" md-select-on-focus></textarea>
                  </mat-form-field>
                </div>
                <p class="small worklist-commentbox-instructions" flex>
                  To protect the student's privacy, do not include any sensitive information such as Social Security
                  Numbers or other Personally
                  Identifiable Information in Comments fields. Comments entered will be shared unedited and in their
                  entirety
                  with other MSIX Users.
                </p>
              </div>
            </div>
          </div>
          <div class="layout-flex-row right-align" *ngIf="nominateMatchIndex === student.key" style="flex: 100%">
            <div class="layout-flex-column right-align">
              <a *ngIf="!showResolve" (click)="goToReassign(student)" class="top-bottom-margin-8 flexCenter"
                style="margin: auto;">
                <span>Reassign</span>
              </a>
            </div>
            <div class="layout-flex-column button-margin right-align">
              <button flex (click)="nominateOrRejectMatchClick(student, 'reject')" class="worklist-reject-button md-button"
                [disabled]="selectedNearMatchItems.length === 0">
                Reject Near Match
              </button>
            </div>
            <div class="layout-flex-column button-margin right-align">
              <button flex (click)="nominateOrRejectMatchClick(student, 'validate')" class="md-button" [disabled]="selectedNearMatchItems.length !== 1">
                Process Near Match
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="flex-5" (click)="nominateMatchDetailsFn(student.key); updateReadFlag(student)"
        class="expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(nominateMatchIndex === student.key)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="nominateMatchIndex === student.key"></i>
      </div>

    </div>

  </div>
</div>

<!--END Validate Record Merge Desktop-->

<!--MOBILE AND TABLET START-->

<div class="flex worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && nominateMatchItems.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet merge-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div class="tableRow headerRow no-padding layout-flex-row">
              <p class="worklist-section-header">Near Matches</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To complete a merge request, access your worklist on desktop.</p>
        </div>
        <div>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of nominateMatchItems">
            <div class="layout-flex-row">
              <div class="flex layout-flex-column" (click)="viewDetails(student)">
                <a class="uppercase">
                  {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}
                  <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.birthDate}} |&nbsp;{{student.demographics.gender}} |
                    {{student.demographics.submittingStateAbbv}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MOBILE AND TABLET END-->
