//Angular Imports
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared.module';

import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';


// GLOBAL options for Angular Material
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

//Projects Components
import { AppComponent } from './app.component'

// Pipes
import { SortByPipe } from './pipes/order-by-pipe'

//// MSIX Views ////
// Pre-Login pages
import { PrivacyComponent } from './views/privacy/privacy.component'
import { ErrorPageComponent } from './views/error-page/error-page.component'
import { LoginComponent, RulesOfBehaviorComponent, CheckEmailMsgComponent } from './views/login/login.component'
import { AnswerChallengeQuestionsComponent } from './views/challengeQuestions/answerChallengeQuestions.component'
import { SetChallengeQuestionsComponent } from './views/challengeQuestions/setChallengeQuestions.component'
import { PasswordUpdateComponent } from './views/passwordUpdate/passwordUpdate.component'
import { RequestAccountComponent } from './views/request-account/request-account.component'
// Request and Help
import { ResourcesComponent } from './views/resources/resources.component'
import { HelpComponent } from './views/help/help.component'
import { PasswordResetComponent } from './views/password-reset/passwordReset.component'
import { AdminSearchResultsComponent } from './views/adminSearchResults/adminSearchResults.component'
// Training
import { TrainingComponent } from './views/training/training.component'
// Home page
import { HomeComponent } from './views/home/home.component'
// Search page
import { SearchResultsComponent } from './views/search-results/search-results.component'
// State/Regions
import { DataAdminComponent } from './views/dataAdmin/dataAdmin.component';
import { AddEditRegionDialogComponent } from './views/add-edit-region-dialog/add-edit-region-dialog.component';
// Student Details
import { HistoricalRawViewComponent, StudentDetailsComponent, StudentDetailsDialog, CustomExportFilter, CORDialog } from './views/student-details/student-details.component';
// Reports - Main
import { ReportsComponent } from './views/reports/reportsMain/reports.component';
//Reports - MSIX Usage
import { MsixUsageComponent, FilterMsixUsage } from './views/reports/msixUsage/msixUsage/msixUsage.component';
import { StudentRecordsExchangeComponent } from './views/reports/msixUsage/studentRecordsExchange/studentRecordsExchange.component';
import { StudentRecordsExchangeFilterDialogComponent } from './views/reports/msixUsage/student-records-exchange-filter-dialog/student-records-exchange-filter-dialog.component';
// User Details
import { UserDetailsComponent, NewUser, ResetPassword, ResetMFA, ConfirmCancel, ConfirmDeactivate } from './views/userDetails/userDetails.component';
// User Admin
import { UserAdminComponent } from './views/userAdmin/userAdmin.component';
// Worklist
import { WorklistComponent, ConfirmMergeDialog, ConfirmSplitDialog, ResolveModalDialog, WorklistDataRequestComponent, 
  MoveNoticeComponent, ValidateMergeComponent, ValidateSplitComponent, NominateNearMatchComponent, ProcessMergeComponent, ProcessSplitComponent } from './views/worklist/worklist.component';
import { WorklistHistorySearchComponent } from './views/work-history-search/work-history-search.component';
import { WorklistHistoryRecordComponent } from './views/worklistHistoryRecord/worklistHistoryRecord.component';
import { WorklistHistoryComponent } from './views/work-history/work-history.component';
import { WorklistHistoryResultsComponent } from './views/work-history-results/work-history-results.component';
import { WorklistReassignComponent, WorklistReassignPageComponent } from './views/worklistReassign/worklistReassign.component';
// Select Job Titles
import { SelectJobTitlesComponent } from './views/selectJobTitles/selectJobTitles.component';
// Reports - Dashboard Reports
import { AccountManagementComponent, FilterAccountManagement, LearnMoreAccountManagement, LearnMoreDDAccountManagement} from './views/reports/dashboards/accountManagement/accountManagement.component';
import { DataManagementComponent, FilterDataManagement, LearnMoreDataManagement, LearnMoreDDDataManagement} from './views/reports/dashboards/dataManagement/dataManagement.component';
import { RecordsExchangeComponent, FilterRecordsExchange, LearnMoreRecordsExchange, LearnMoreDDRecordsExchange} from './views/reports/dashboards/recordsExchange/recordsExchange.component';
import { ChildMobilityComponent, FilterChildMobility, LearnMoreChildMobility, LearnMoreDDChildMobility} from './views/reports/dashboards/childMobility/childMobility.component';
import { MepPopulationComponent, FilterMepPopulation, LearnMoreMepPopulation, LearnMoreDDMepPopulation} from './views/reports/dashboards/mepPopulation/mepPopulation.component';
import { StudentAchievementComponent, FilterStudentAchievement, LearnMoreStudentAchievement, LearnMoreDDStudentAchievement} from './views/reports/dashboards/studentAchievement/studentAchievement.component';
// Reports - CCR
import { ReconPotentialDuplicatesComponent, ReconPotentialDuplicatesLearnMore } from './views/reports/reconciliation/category-1-and-category-2-child-count/potentialDuplicates/potentialDuplicates.component';
// Reports - Data Quality reports
import { FileSubmissionComponent, FilterFileSubmission, FileSubmissionLearnMore,  FileSubmissionDDLearnMore, FileSubmissionMdeDDLearnMore} from './views/reports/dataQuality/fileSubmission/fileSubmission.component';
import { DataValidityComponent, FilterDataValidity, DataValidityLearnMore,  DataValidityDDLearnMore} from './views/reports/dataQuality/dataValidity/dataValidity.component';
import { DataLogicComponent, FilterDataLogic, DataLogicLearnMore,  DataLogicDDLearnMore} from './views/reports/dataQuality/dataLogic/dataLogic.component';
import { DataCompletenessComponent, FilterDataCompleteness, DataCompletenessLearnMore,  DataCompletenessDDLearnMore} from './views/reports/dataQuality/dataCompleteness/dataCompleteness.component';
import { PotentialDuplicatesComponent, FilterPotentialDuplicates, PotentialDuplicatesLearnMore,  PotentialDuplicatesDDLearnMore} from './views/reports/dataQuality/potentialDuplicates/potentialDuplicates.component';
// Student Mobility Reports
import { GeneralMoveFromComponent, FilterGeneralMoveFrom, GeneralMoveFromLearnMore,  GeneralMoveFromDDLearnMore} from './views/reports/studentMobility/generalMoveFrom/generalMoveFrom.component';
import { GeneralMoveToComponent, FilterGeneralMoveTo, GeneralMoveToLearnMore, GeneralMoveToDDLearnMore} from './views/reports/studentMobility/generalMoveTo/generalMoveTo.component';

// Reports - User Account Reports
import { AccountListComponent, FilterAccountList, AccountListLearnMore,  AccountListDDLearnMore} from './views/reports/userAccount/accountList/accountList.component';
import { AccountMetricsComponent, FilterAccountMetrics, AccountMetricsLearnMore,  AccountMetricsDDLearnMore} from './views/reports/userAccount/accountMetrics/accountMetrics.component';
//D3 Charts
import { MsixLineChartComponent } from './components/msixLineChart/msixLineChart.component'
import { MsixMapComponent } from './components/msixMap/msixMap.component'
import { MsixTableComponent } from './components/msixTable/msixTable.component'
import { MsixBarChartComponent } from './components/msixBarChart/msixBarChart.component'
import { MsixStackedBarComponent } from './components/msixStackedBar/msixStackedBar.component'

//My Saved Lists
import { SavedListComponent, CreateNewList } from './views/savedList/savedList.component'
import {MultiMuveNoticeDialogComponent } from './views/multi-move-notice-dialog/multi-move-notice-dialog.component'
import { ShareMyListDialogComponent } from './views/share-my-list-dialog/share-my-list-dialog.component';
import { AcceptEditDeleteSharedListDialogComponent } from './views/accept-edit-delete-shared-list-dialog/accept-edit-delete-shared-list-dialog.component'
// My Account
//import { MyAccountComponent } from './components/my-account/my-account.component'
import { Myaccount2Component, ConfirmCancelMyAccountUpdate } from './views/myaccount2/myaccount2.component';

//Project Services
import { HttpInterceptorService } from './services/httpinterceptor.service'
// import { InformationDialogComponent } from './components/information-dialog/information-dialog.component'
import { NgxPrintModule } from 'ngx-print';
import { AppRoutingModule } from './app-routing.module';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { StudentDetailsModule } from './views/student-details/student-details.module';
import { UserSearch } from './components/user-search/user-search.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component'




@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule,
    NgxPrintModule,
    AppRoutingModule,
    StudentDetailsModule
    ],
  declarations: [
    // Main App component
    AppComponent,
    // Pipes
    SortByPipe,
    // MSIX views
    // Pre-Login
    PrivacyComponent,
    ErrorPageComponent,
    LoginComponent,
    RulesOfBehaviorComponent,
    CheckEmailMsgComponent,
    AnswerChallengeQuestionsComponent,
    SetChallengeQuestionsComponent,
    PasswordUpdateComponent,
    RequestAccountComponent,
    // Resources and Help
    ResourcesComponent,
    HelpComponent,
    PasswordResetComponent,
    AdminSearchResultsComponent,
    // Training
    TrainingComponent,
    // Home page
    HomeComponent,
    UserSearch,
    // Search page
    SearchResultsComponent,
    // State/Regions
    DataAdminComponent,
    AddEditRegionDialogComponent,
    // Student details
    StudentDetailsComponent,
    HistoricalRawViewComponent,
    StudentDetailsDialog,
    CustomExportFilter,
    CORDialog,
    MultiMuveNoticeDialogComponent,
    // Reports - Main page
    ReportsComponent,
    //Reports - Dashboards section
    AccountManagementComponent,
    FilterAccountManagement,
    LearnMoreAccountManagement,
    LearnMoreDDAccountManagement,
    DataManagementComponent,
    FilterDataManagement,
    LearnMoreDataManagement,
    LearnMoreDDDataManagement,
    RecordsExchangeComponent, 
    FilterRecordsExchange, 
    LearnMoreRecordsExchange, 
    LearnMoreDDRecordsExchange,
    ChildMobilityComponent, 
    FilterChildMobility, 
    LearnMoreChildMobility, 
    LearnMoreDDChildMobility,
    MepPopulationComponent, 
    FilterMepPopulation, 
    LearnMoreMepPopulation, 
    LearnMoreDDMepPopulation,
    StudentAchievementComponent, 
    FilterStudentAchievement, 
    LearnMoreStudentAchievement, 
    LearnMoreDDStudentAchievement,
    // Reports - Data Quality section
    FileSubmissionComponent, 
    FilterFileSubmission, 
    FileSubmissionLearnMore,  
    FileSubmissionDDLearnMore,
    FileSubmissionMdeDDLearnMore,
    DataValidityComponent, 
    FilterDataValidity, 
    DataValidityLearnMore,  
    DataValidityDDLearnMore,
    DataLogicComponent, 
    FilterDataLogic, 
    DataLogicLearnMore,  
    DataLogicDDLearnMore,
    DataCompletenessComponent, 
    FilterDataCompleteness, 
    DataCompletenessLearnMore,  
    DataCompletenessDDLearnMore,
    PotentialDuplicatesComponent, 
    FilterPotentialDuplicates, 
    PotentialDuplicatesLearnMore,  
    PotentialDuplicatesDDLearnMore,
    //Reports - MSIX Usage
    MsixUsageComponent,
    FilterMsixUsage,
    StudentRecordsExchangeComponent,
    StudentRecordsExchangeFilterDialogComponent,
    // Reports - Student Mobility section
    GeneralMoveFromComponent, 
    FilterGeneralMoveFrom,
    GeneralMoveFromLearnMore,  
    GeneralMoveFromDDLearnMore,
    GeneralMoveToComponent, 
    FilterGeneralMoveTo, 
    GeneralMoveToLearnMore, 
    GeneralMoveToDDLearnMore,
    // Reports - User Account section
    AccountListComponent,
    FilterAccountList,
    AccountListLearnMore, 
    AccountListDDLearnMore,
    AccountMetricsComponent, 
    FilterAccountMetrics, 
    AccountMetricsLearnMore,  
    AccountMetricsDDLearnMore,
    // User Details
    UserDetailsComponent,
    // User Admin
    UserAdminComponent,
    NewUser,
    ResetPassword,
    ResetMFA,
    ConfirmCancel,
    ConfirmDeactivate,
    // Worklist
    WorklistComponent,
    ConfirmMergeDialog,
    ConfirmSplitDialog,
    ResolveModalDialog,
    WorklistDataRequestComponent,
    ProcessMergeComponent,
    ProcessSplitComponent,
    NominateNearMatchComponent,
    MoveNoticeComponent,
    ValidateMergeComponent,
    ValidateSplitComponent,
    WorklistHistoryComponent,
    WorklistHistorySearchComponent,
    WorklistHistoryResultsComponent,
    WorklistHistoryRecordComponent,
    WorklistReassignComponent,
    WorklistReassignPageComponent,
    // My Account
    //MyAccountComponent,
    Myaccount2Component,
    ConfirmCancelMyAccountUpdate,
    // Select job titles
    SelectJobTitlesComponent,
    ReconPotentialDuplicatesComponent,
    ReconPotentialDuplicatesLearnMore,
    // D3 charts components
    MsixLineChartComponent,
    MsixMapComponent,
    MsixTableComponent,
    MsixBarChartComponent,
    MsixStackedBarComponent,
    //My Saved Lists
    SavedListComponent, 
    ShareMyListDialogComponent,
    CreateNewList, 
    AcceptEditDeleteSharedListDialogComponent,
    ForgotPasswordComponent,
  ],
  entryComponents: [
    ResetPassword
  ], 
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    { 
      provide: MAT_RIPPLE_GLOBAL_OPTIONS, 
      useValue: {
        disabled: true
      }
    }
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { 
}