<div class="login-page background-awesome" layout="row" layout-align="center start">
  <div class="md-content rob" layout-padding="" flex-xs="90" flex-sm="50" flex-md="50" flex-lg="40" flex-xl="40">

    <h1>Rules of Behavior</h1>
    <div class="rules">
      <h3 class="noPadding">Responsibilities</h3>
      <p>
        The Migrant Student Information Exchange (MSIX) is a Department of Education (ED) information system and is to
        be used for official use only. Users must read, understand, and comply with these Rules of Behavior. Failure to
        comply with the MSIX Rules
        of Behavior may result in revocation of your MSIX account privileges, job action, or criminal prosecution.
      </p>
      <p>
        MSIX users must complete a basic security awareness training course prior to being granted access to the system.
        The security topics addressed in this document provide the required security awareness content, so it is
        important that you read through
        this entire text. Users must also complete annual security awareness refresher training. MSIX will prompt you to
        reread the Rules of Behavior annually (or more often due to changes in the system or regulations) to meet this
        requirement.
      </p>
      <p>
        MSIX users are responsible for notifying their MSIX User Administrator when they no longer require access to
        MSIX. This may occur when a user gets new responsibilities that do not include a need to access MSIX or when the
        user gets another job or
        position.
      </p>

      <h3>Monitoring</h3>
      <p>
        This is a Department of Education computer system. System usage may be monitored, recorded, and subject to audit
        by authorized personnel. THERE IS NO RIGHT OF PRIVACY IN THIS SYSTEM. Unauthorized use of this system is
        prohibited and subject to
        criminal and civil penalties.
      </p>
      <p>
        System personnel may provide to law enforcement officials any potential evidence of crime found on Department of
        Education computer systems. USE OF THIS SYSTEM BY ANY USER, AUTHORIZED OR UNAUTHORIZED, CONSTITUTES CONSENT TO
        THIS MONITORING,
        RECORDING, and AUDIT.
      </p>

      <h3>MSIX Security Controls</h3>
      <p>
        MSIX security controls have been implemented to protect the information processed and stored within the system.
        MSIX users are an integral part in ensuring the MSIX security controls provide the intended level of protection.
        It is important to
        understand these security controls, especially those with which you directly interface. The sections below
        provide detail on some of those controls and the expectations for MSIX users.
      </p>
      <p>
        MSIX security controls are designed to:</p>
      <ul>
        <li>Ensure only authorized users have access to the system;</li>
        <li>Ensure users are uniquely identified when using the system;</li>
        <li>Tie actions taken within the system to a specific user;</li>
        <li>Ensure users only have access to perform the actions required by their position;</li>
        <li>Ensure MSIX information is not inappropriately released; and</li>
        <li>Ensure MSIX is available to users when needed.</li>
      </ul>

      <p class="noPadding">
        Examples of security controls deployed within MSIX include:
      </p>
      <ul>
        <li>
          <u stye="text-decoration: underline;">Automated Session Timeout</u>
          – Users are automatically logged out of MSIX after thirty minutes of inactivity. This helps ensure
          unauthorized users do not gain access to the system.
        </li>
        <li>
          <u stye="text-decoration: underline;">Role-Based Access Control</u>
          – User IDs are assigned a specific role within MSIX. This role corresponds to the user's job function and
          restricts access to certain MSIX capabilities.
        </li>
        <li>
          <u stye="text-decoration: underline;">Audit Logging</u>
          – Actions taken within MSIX are captured in log files to help identify unauthorized access and enforce
          accountability within the system.
        </li>
        <li>
          <u stye="text-decoration: underline;">Incident Response</u>
          – If a user suspects their user ID has been subject to unauthorized use, contact the MSIX help desk
          immediately.
        </li>
        <li>
          <u stye="text-decoration: underline;">Communication Protection</u>
          – Traffic between a user's web browser and the MSIX servers is encrypted to protect it during transmission.
        </li>
      </ul>
      <p class="noPadding">
        The sections below describe several other security controls in place within MSIX. It is important that you
        understand and comply with these controls to ensure the MSIX security is maintained.
      </p>

      <h3>User Credentials</h3>
      <p>
        User credentials are the mechanism by which MSIX identifies and verifies users. These are your user ID and
        password. User IDs uniquely identify each MSIX user and allow the MSIX System Administrators to attribute
        actions taken within the system to a
        specific user. This tracking is important in enforcing accountability within the system. Passwords are used by
        MSIX to verify a user’s identity. It is important for you to comply with the following rules governing user
        credentials:
      </p>
      <ul>
        <li>Protect your logon credentials at all times.</li>
        <li>Never share your user ID and/or password with anyone else. You are responsible for all actions taken with
          your user credentials.</li>
        <li>Your passwords must:</li>
        <ul>
          <li>Be changed upon initial login to MSIX;</li>
          <li>Contain at least eight (8) characters;</li>
          <li>Contain a mix of letters (upper and lower case), numbers, and special characters (#, @, etc.);</li>
          <li>Be changed at least every ninety (90) days; and</li>
          <li>Not reuse your previous six (6) passwords.</li>
        </ul>
        <li>Do not write your password down or keep it in an area where it can be easily discovered.</li>
        <li>Avoid using the “remember password” feature.</li>
        <li>User accounts are disabled after three (3) consecutive invalid attempts are made to supply a password.</li>
        <li>Reinstatement of a disabled user account can only be completed by a Help Desk technician or a system
          administrator.</li>
      </ul>

      <h3>Privacy of MSIX Information</h3>
      <p>
        MSIX users are subject to the Privacy Act of 1974 which established a code of fair information practices that
        govern the collection, maintenance, use, and dissemination of information about individuals. The Privacy Act
        prohibits the disclosure of
        records unless the disclosure has consent of the individual or is pursuant to one of twelve statutory
        exceptions. The Privacy Act also provides parents the right to gain access to their student’s record (Student
        Consolidated Record Report) and amend
        it. For additional guidance on disclosing student records, please see your State Education Agency (SEA).
      </p>

      <h3>Protection of MSIX Information</h3>
      <p>
        You are required to protect MSIX information in any form. This includes information contained on printed
        reports, data downloaded onto computers and computer media (e.g. diskettes, tapes, compact discs, thumb drives,
        etc.), or any other format. In
        order to ensure protection of MSIX information, you should observe the following rules:
      </p>
      <ul>
        <li>Log out of MSIX if you are going to be away from your computer for longer than fifteen minutes.</li>
        <li>Log out of MSIX or lock your computer before you leave it unattended by using the Ctrl + Alt + Delete key
          sequence when leaving your seat.</li>
        <li>Media (including reports) containing MSIX information should be removed from your desktops during
          non-business hours.</li>
        <li>Do not leave paper media with MSIX information in public areas such as printers, copiers, fax machines,
          conference rooms, etc.</li>
        <li>Store media containing MSIX information in a locked container (e.g. desk drawer) during non-business hours.
        </li>
        <li>Store digital information in an encrypted format where technically possible.</li>
        <li>Media containing MSIX information should be properly cleansed or destroyed.</li>
        <ul>
          <li>Shred paper media and compact discs prior to disposal.</li>
          <li>Diskettes and other magnetic media should be cleansed using appropriate software or a magnetic field with
            sufficient strength so as to make the information unreadable.</li>
          <ul>
            <li>Note that simply deleting files from magnetic media does not remove the information from the media.</li>
            <li>Media containing encrypted information can be excluded from the cleansing process, although it is
              recommended.</li>
          </ul>
        </ul>
        <li>If the access which you have been granted within MSIX is more than required to fulfill your job duties, it
          should be reported to your MSIX User Administrator.</li>
        <li>Do not disclose MSIX information to any individual without a "need-to-know" the information in the course of
          their business.</li>
      </ul>

      <h3>Other Security Considerations</h3>

      <p>
        This section describes some additional security items of which you should be aware.
      </p>
      <ul>
        <li>
          <u stye="text-decoration: underline;">Incident Response</u>
          – If you suspect or detect a security violation in MSIX, contact the MSIX Help Desk immediately. For example,
          if you suspect someone may have used your user ID to log in to MSIX, you should contact the MSIX Help Desk.
          Other warning signs that MSIX
          may have been compromised include, but are not limited to: inappropriate images or text on the web pages, data
          formats that are not what is expected, missing data, or MSIX is not available. While these may not be
          attributed to a compromise, it is
          better to have it checked out and be sure than to take no action.
        </li>
        <li>
          <u stye="text-decoration: underline;">Shoulder Surfing</u>
          – Shoulder surfing is using direct observation techniques, such as looking over someone's shoulder, to get
          information. An example of shoulder surfing is when a person looks over someone else's shoulder while they are
          entering a password for a system
          to covertly acquire that password. To protect against this type of attack, slouch over your keyboard slightly
          when keying in your password to block the view of a possible onlooker.
        </li>
        <li>
          <u stye="text-decoration: underline;">Social Engineering</u>
          – Social engineering is a collection of techniques used to manipulate people into performing actions or
          divulging confidential information. For example, a hacker calls a user at random and pretends to be a help
          desk technician. Under the guise of
          purportedly fixing a problem, the hacker requests the user’s login credentials. If provided, the user has
          unwittingly provided system access to an unauthorized person.
          <br />
          To defeat social engineering simply question anything that doesn't make sense to you. For example, a help desk
          technician should never ask a user for their login credentials to resolve a problem. If you receive a call
          from someone and you are not
          sure who they are, ask for a callback number. Hang up the phone and call back to the number provided. Hackers
          will typically provide a bogus number. Ask questions. If the answers you receive do not make sense, end the
          call and report the incident to
          your local security organization.
        </li>
        <li>
          <u stye="text-decoration: underline;">Faxing</u>
          – When faxing MSIX information, call the recipient of the fax and let them know it is coming. Ask them to go
          to the fax machine so they can pull it off right away so any sensitive information is not left lying around
          the office.
        </li>
        <li>
          <u stye="text-decoration: underline;">Virus Scanning</u>
          – Scan documents or files downloaded to your computer from the Internet for viruses and other malicious code.
          Virus scanning software should also be used on email attachments.
        </li>
      </ul>
    </div>

    <div layout-gt-md="row" class="robButtons center">
      <a (click)="acceptRob()"><button class="md-button center">Continue</button></a>
      <a (click)="logout()"><span>Cancel / Logout</span></a>
    </div>

  </div>
</div>