<div layout="row" class="reportsFilter learnMoreModal">
  <div aria-label="Account List Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="flex-90">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button flex-10" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div layout="column" flex>
        <p>Use this report to monitor and manage user accounts in MSIX. Review the report regularly to ensure only users
          with a demonstrated need have access to MSIX with the appropriate role(s).
        </p>
        <div class="add-margin-top">
          <p>Use the <b>Filter</b> function to set a custom date range for account activity, view users by user status,
            change the drill down level to State or District, and access other viewing options. </p>
        </div>
        <div class="add-margin-top">
          <p>Click on a row to view additional details for each user.
          </p>
        </div>
        <div class="add-margin-top">
          <p>Use the <b>Export</b> function to download a copy of the report in CSV or PDF format. The exported report contains
            additional information, including job title, Rules of Behavior acceptance status, and the regions and districts 
            associated with each account.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>