import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'password-reset',
  templateUrl: './passwordReset.component.html',
  styleUrls: ['./passwordReset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  selectedState: {
    name: string
    stateKey: number
  };
  selectedDistrict: {};
  header: string;
  private errors: any;
  districts: Array<District>;
  states = [];
  stateSelected = false;
  districtSelected = false;
  
  constructor(private route: ActivatedRoute,
    private _router: Router,
    private _Toast: ToastService,
    private _User: UserService,
    private _State: StateService,
    public responsiveUI: ResponsiveUIService) {
    
    this.header = this.route.snapshot.queryParams.header;
  }
    
  ngOnInit(): void {
    this.getStateList();
    if (!this.header){
      this.header = 'Resetting Your Password';
    }
  }

  async getStateList() {
    this.states = await this._State.getStates();
  }

  goToAdminSearchResults() {

    let apiCall = this._State.getStateDetails(this.selectedState.name);
    let subscribed = apiCall.subscribe({
      next: dt => {
        let data:any = dt;

        this._router.navigate(['adminSearchResults', {
          stateKey: data.stateKey,
          district: this.getSelectedDistrictKey(this.selectedDistrict),
          header: this.header
        }]);

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.data.developerMessage, 0);
        this.errors = error.data.errors;

        subscribed.unsubscribe();
      }
    });
  }

  getSelectedDistrictKey(districtName){
    for (let i = 0; i < this.districts.length; i++) { 
      // if (this.districts[i].name === districtName){
      //   return this.districts[i].key
      // }
    }
  }

  // This function helps determine if Submit button should be disabled. Submit is disable when:
  //   1) State has not been selected
  //   2) If state has been selected, but district has not been selected
  //   3) If state has been selected, but there are no districts
  determineDisableStatus() {
    return !this.selectedState || (this.districts && this.districts.length > 0 && !this.selectedDistrict);
  }

  selectDistrictFromList() {
    this.districtSelected = true;
  }
  getDistrictList() {
    this.stateSelected = true;
    this.districtSelected = false;
    this.selectedDistrict = null;
    if (this.selectedState) {
      let apiCall = this._State.getStateDistricts2(this.selectedState.stateKey);
      let subscribed = apiCall.subscribe({
        next: data => {
          let listOfDistricts = [];
          listOfDistricts = data;
          console.log(data);
          this.districts = listOfDistricts;

          subscribed.unsubscribe();
        }, 
        error: error => {
          console.error(error);
          this._Toast.showToast(error.error.developerMessage, 0);
          this.errors = error.error.developerMessage;

          subscribed.unsubscribe();
        }
      })
    }
  }
}

interface District {
  key: number,
  name: string;
}