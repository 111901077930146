import { Injectable, OnInit } from '@angular/core';
import { Observable, of, firstValueFrom, switchMap, forkJoin, lastValueFrom } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { AppConstants }  from '../config/index.constants';
import { StateService } from '../services/state.service';
import { ReportsService } from '../services/reports.service';
import { User, UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

import { Router } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class ReportInfoService implements OnInit {
  private apiUrl = AppConstants.api;
  router: string;
  monthNames : string[];
  returnObj: any;
  constructor(
    private http: HttpClient,
    private stateService: StateService,
    private reportsService: ReportsService,
    private userService: UserService,
    private authService: AuthService,
    private _router: Router) {

    this.router = _router.url;
    this.monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    this.returnObj = {
      stateData: [],
      currentPeriod: '',
      recentPeriod: '',
      userState: '',
      userStateName: '',
      authRoleClasses: {},
      isAuthorized: false
    }

    console.log('reportinfo.service constructor called');
    console.log('reportinfo.service - before calling getStateDataWithPPData()');
    this.getStateDataWithPPData();
    console.log('reportinfo.service - after calling getStateDataWithPPData()');

    // Get current user data and setup on the return object here.
    this.userService.getCurrentUser().then( data => {
      let userData:any = data;
      this.returnObj.userState = userData.userState;
      this.returnObj.userStateName = userData.userStateName;
      this.returnObj.isAuthenticated = true;
    });
  }

  ngOnInit() {
    console.log('reportinfo.service ngOnInit called');
  }

  // This function is called from Home page as it needs to call multiple APIS for the user related information.
  // this.returbObj values will be ready to use.
  private async getStateDataWithPPData() {

    console.log('*****reportInfo.service.getStateDataWithPPData().*****');

    this.returnObj.stateData = await this.stateService.getStates();
    //this.returnObj.currentPeriod = await this.reportsService.getCurrentReportingPeriod();
    //this.returnObj.recentPeriod = await this.reportsService.getRecentlyClosedReportingPeriod();
    let currentPeriodApiCall = await this.reportsService.getCurrentReportingPeriod();
    let currentSubscribed = currentPeriodApiCall.subscribe({
      next: data => {
        console.log("reportInfo.service.getStateDataWithPPData() - current period", data);
        this.returnObj.currentPeriod = data.formattedPeriod;
        currentSubscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - reportInfo.service.getStateDataWithPPData() - current period", error);
        currentSubscribed.unsubscribe();
      }
    });
    let recentPeriodApiCall = await this.reportsService.getRecentlyClosedReportingPeriod();
    let recentSubscribed = recentPeriodApiCall.subscribe({
      next: data => {
        console.log("reportInfo.service.getStateDataWithPPData() - recent period", data);
        this.returnObj.recentPeriod = data.formattedPeriod;
        recentSubscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - reportInfo.service.getStateDataWithPPData() - recent period", error);
        recentSubscribed.unsubscribe();
      }
    });

    const dateObj = {
      current: this.returnObj.currentPeriod.formattedPeriod,
      currentStartYear: this.returnObj.currentPeriod.startYear,
      currentEndYear: this.returnObj.currentPeriod.endYear,
      recent: this.returnObj.currentPeriod.formattedPeriod,
      recentStartYear: this.returnObj.currentPeriod.startYear,
      recentEndYear: this.returnObj.currentPeriod.endYear
    }

    console.log('*****reportInfo.service.getStateDataWithPPData() - Setting localstorage - ppDateObj *****');
    localStorage.setItem('ppDateObj', JSON.stringify(dateObj));

    /* let forkJoinSubscribed = await forkJoin(
      [
        this.stateService.getStates(),
        this.reportsService.getCurrentReportingPeriod(),
        this.reportsService.getRecentlyClosedReportingPeriod()
      ]
    ).subscribe({
      next: data => {
        console.log('forkJoin data states: ', data[0]);
        console.log('forkJoin data current RP: ', data[1]);
        console.log('forkJoin data recently closed RP: ', data[2]);
        this.returnObj.stateData = data[0];
        this.returnObj.currentPeriod = data[1];
        this.returnObj.recentPeriod = data[2];

        const dateObj = {
          current: this.returnObj.currentPeriod.formattedPeriod,
          currentStartYear: this.returnObj.currentPeriod.startYear,
          currentEndYear: this.returnObj.currentPeriod.endYear,
          recent: this.returnObj.currentPeriod.formattedPeriod,
          recentStartYear: this.returnObj.currentPeriod.startYear,
          recentEndYear: this.returnObj.currentPeriod.endYear
        }

        console.log('*****reportInfo.service.getStateDataWithPPData() - Setting localstorage - ppDateObj *****');
        localStorage.setItem('ppDateObj', JSON.stringify(dateObj));

        forkJoinSubscribed.unsubscribe();
      },
      error: error => {
        console.error('Error occurred in reportInfo.service.ngOnInit() when calling forkJoin for multiple APIs');
        forkJoinSubscribed.unsubscribe();
      }
    }); */
  }

  getReportDateObject() {
    return JSON.parse(localStorage.getItem('ppDateObj'));
  }

  async getReportInfo(rolesArray): Promise<any> {
    // if the returnObj does not contain populated data, pull it again
    /*if (!this.returnObj.stateData || !this.returnObj.currentPeriod || !this.returnObj.recentPeriod){
      this.getStateDataWithPPData();
    }*/

    // Get the current user's list of authorized roles
    await this.authService.isRoleClassAuthorized(rolesArray).then(data => {
      this.returnObj.authRoleClasses = data;
    });

    return this.returnObj;
  }

  getLast12Months(){
    var toDate = new Date();
    var fromDate = new Date();
    fromDate.setFullYear(toDate.getFullYear()-1);
    // setMonth() makes sure to account for subtracting from earlier months
    toDate.setMonth(toDate.getMonth()-1);
    var last12Months = this.monthNames[fromDate.getMonth()] + ' ' + fromDate.getFullYear() + ' - ' + this.monthNames[toDate.getMonth()] + ' ' + toDate.getFullYear();
    return last12Months; 
  }
}
