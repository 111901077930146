<p class="correspondence">Correspondence</p>
<div>
    <div >
        <div class="message">
            <p><b>{{student.creator.firstName}} {{student.creator.lastName}}</b></p>
            <p><b>{{student.creator.dateCreated}}</b></p>
            <p>{{student.creator.comments}}</p>
        </div>
    </div>

    <div *ngIf="student.corMessages.length > 0">
        <div *ngFor="let message of student.corMessages" class="correspondence-details">
            <div class="message">
                <p><b>{{message.msgSubmittingUserFN}} {{message.msgSubmittingUserLN}}</b></p>
                <p><b>{{message.msgDate}}</b></p>
                <p>{{message.message}}</p>
            </div>
            
        </div>
    </div>
    <mat-form-field class="correspondence-message text-area" appearance="outline">
        <mat-label>Message</mat-label>
        <textarea rows="3" matInput [(ngModel)]="corMessage.message" maxlength="250"></textarea>
    </mat-form-field>
    <div class="md-char-counter">{{corMessage.message.length}} / 250</div>
    <div class="action-button">
        <p>
            Use this form to submit questions regarding the student and their records. This will ensure the
            correspondence is logged
            in the MSIX system and will show above.
        </p>
        <button (click)="sendMessage(student)" class="md-button" [disabled]="corMessage.message?.trim().length === 0">
            Send
        </button>
    </div>
</div>