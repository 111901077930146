<div class="worklist-page">
    <div aria-label="Resolve / Dismiss Notice" class="confirmMergeDialog">
        <form>
            <div class="md-toolbar-tools toolbar">
                <h2 *ngIf="resolveType === 'data'">Resolve</h2>
                <h2 *ngIf="resolveType === 'move'">Dismiss</h2>
                <span flex></span>
                <button class="md-icon-button right-align" (click)="closeDialog()" aria-label="close dialog">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>

            <mat-dialog-content class="resolve-dismiss-dialog">
                <mat-form-field appearance="outline" class="text-area">
                    <mat-label >Comments</mat-label>
                    <textarea matInput 
                        [(ngModel)]="corUpdateDR.message" maxlength="250" rows="2" md-select-on-focus
                        *ngIf="resolveType === 'data'" name="comments"></textarea>
                    <textarea matInput 
                        [(ngModel)]="corUpdateMN.message" maxlength="250" rows="2" md-select-on-focus
                        *ngIf="resolveType === 'move'" name="comments"></textarea>
                    <div class="md-errors-spacer" *ngIf="resolveType === 'data'">
                        <div class="md-char-counter">{{corUpdateDR.message.length}} / 250</div>
                    </div>
                </mat-form-field>
                <div class="char-count" *ngIf="resolveType === 'move'">{{corUpdateMN.message.length}} / 250</div>
                <div class="worklist-commentbox-instructions">
                    <p>
                    To protect the student's privacy, do not include any sensitive information such as
                    Social Security Numbers or other Personally
                    Identifiable Information in Comments fields. Comments entered will be shared unedited
                    and
                    in their entirety with other MSIX Users.
                    </p>    
                </div>
            </mat-dialog-content>

            <hr class="thinHr">
            <mat-dialog-actions class="action-buttons">
                <a (click)="closeDialog()"><span>Cancel</span></a>
                <button (click)="callResolveRequest()"
                    *ngIf="resolveType === 'data'" type="submit" class="md-button">Submit</button>
                <button (click)="callResolveNotice()" 
                    *ngIf="resolveType === 'move'" type="submit" class="md-button">Submit</button>
            </mat-dialog-actions>

        </form>
    </div>
</div>