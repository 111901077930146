import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { roleClasses } from '../config/index.constants';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;
  //private acUserRoles = AppConstants.roleClasses;
  private timeoutFlag: boolean;
  private currUser: any;
  private isRoleAuthorized: {};
  private authRoles: {};
  constructor(
    private userService: UserService,
    private cookieService: CookieService) {
    
    this.timeoutFlag = false;
    this.currUser = {
      betaUser: false,
      roles: []
    };
  }

  getAuthRoles() {
    return this.authRoles;
  }

  setAuthRoles(authorizedRoles) {
    this.authRoles = authorizedRoles;
  }

  setTimeoutFlag(status: boolean) {
    this.timeoutFlag = status;
  }

  getTimeoutFlag() {
    return this.timeoutFlag;
  }

  isAuthenticated() {
    if (this.cookieService.getObject('user')) {
      return true;
    }
    return false;
  }
  
  isAuthorized(authorizedRoles) {
    if (!Array.isArray(authorizedRoles)) {
      authorizedRoles = [authorizedRoles];
    }
    if (!this.isAuthenticated()) {
      return false;
    }
    var roleAuthorized = false;
    
    let o = JSON.parse(this.cookieService.get('user'));
    let roles: string[] = JSON.parse(o.roles);

    for (var role in roles) {
      if (authorizedRoles.indexOf(roles[role]) !== -1) {
        return true;
      }
    }
    return roleAuthorized;
  }

  async isRoleClassAuthorized(roleClassArray): Promise<any> {

    // Get current user data.
    await this.userService.getCurrentUser().then( data => {
      this.currUser = data;
    });

    console.log("Authservice isRoleClassAuthorized(): ", this.currUser);

    this.isRoleAuthorized = await this.isRoleClassAuthorizedHelper(roleClassArray);
    return this.isRoleAuthorized;
  }

  private isRoleClassAuthorizedHelper(roleClassArray) {
    var isAuthorizedForClasses = {};
    for (var roleClass in roleClassArray) {
      var authorizedRoles = roleClasses[roleClassArray[roleClass]];
      if (authorizedRoles) {
        for (var role in this.currUser.roles) {
          if (authorizedRoles.indexOf(this.currUser.roles[role]) !== -1) {
            isAuthorizedForClasses[roleClassArray[roleClass]] = true;
            break;
          }
        }
      }
    }
    return isAuthorizedForClasses;
  }

  isBetaUser(){
    return this.currUser.betaUser;
  }

  init() {}
}