<div class="layout-row reportsFilter learnMoreModal">
  <div aria-label="File Submission Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content" style="height: 325px; overflow-y: scroll">
      <div class="layout-column" flex>
        <p>Use this report to monitor the frequency, quantity, and quality of data submitted to MSIX.</p>
        <div class="add-margin-top">
          <p>Monitor the frequency and quantity of data submitted to MSIX. Clicking the File Name will open the File Submission Details page for that specific submission. This details page allows MSIX users to view specific file details including: Begin Date, End Date, Student Count, Record Count, New Student Count, Near Match Student Count, Existing Student Count, and Total Rejected Count.</p>
        </div>
        <div class="add-margin-top">
          <p>Manage data quality. Clicking the File Name will open the File Submission Details page for that specific submission. This details page allows MSIX users to view any File Errors that may have occurred as part of the submission.</p>
        </div>
        <div class="add-margin-top">
          <p>The count in the ‘Records’ column reflects the total number of non-Header records contained in the file.</p>
        </div>
        <div class="add-margin-top">
          <p>The count in the ‘Records Rejected: Full’ column reflects the number of invalid or missing MDEs that were within the submitted file.</p>
        </div>
        <div class="add-margin-top">
          <p>Click on a row to view more submission details and processing information about a file.</p>
        </div>
        <div class="add-margin-top">
          <p>Use the <b>Export</b> function to download a copy of the report in CSV or PDF format. The report export also includes the Beginning Effective Date and Ending Effective Date.</p>
        </div>
        <div class="add-margin-top">
          <p>Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.</p>
        </div>
      </div>
    </div>
  </div>
</div>