<div id="print-section" [ngClass]="{'student-details': responsiveUI.isDesktop || responsiveUI.isTablet, 'consolidatedPage': showConsolidated}">
  <div *ngIf="showFilter" class="md-backdrop"></div>

  <div>

    <div class="mainDiv" [ngClass]="{'layout-margin': !responsiveUI.isDesktop}">

      <!-- Removing breadcrumbs as per Dan but leaving commented in case we revert back -->
      <!-- <p class="small camelcase" >Search&nbsp;<i class="fa fa-angle-right" aria-hidden="true"></i>&nbsp;{{student.studentName.toLowerCase()}}</p> -->

      <!--Tabs dependent on current view-->
      <div class="hideOnPrint" *ngIf="responsiveUI.isDesktop" layout="row" layout-align="end start" >
        <div (click)="showHistoricalView()" *ngIf="showConsolidated || showRaw" class="historicalTab">
          <p>Historical View</p>
        </div>
        <div (click)="showConsolidatedView()" *ngIf="showHistorical && msixId" class="historicalTab">
          <p>Consolidated View</p>
        </div>
        <div (click)="showRawView()" *ngIf="showConsolidated || showHistorical" class="rawTab">
          <p>Raw View</p>
        </div>
        <div (click)="showConsolidatedView()" *ngIf="showRaw && msixId" class="rawTab">
          <p>Consolidated View</p>
        </div>
      </div>

      <div class="mainHeader">
        <div *ngIf="!responsiveUI.isMobile"class="uppercase block2">Student Record</div>
        <div class="flexSpaceBetween">
          <h2 class="camelcase">{{studentName.toLowerCase()}}</h2>
          <div class="mainHeaderRight" *ngIf="!responsiveUI.isMobile || !printView">
            <div class="layout-row" (click)="corDialogActivate('requestData')"
              *ngIf="!authRoleClasses.superUser && authRoleClasses.sendInfo && msixId">
              <i class="flex-15-historical fa fa-list" aria-hidden="true"></i>
              <p class="flex-85 small rightPadding">Data Request</p>
            </div>
            <div class="layout-row" (click)="flagForMerge()"
              [disabled]="inMergeWorklist || inPendingMergeWorklist"
              *ngIf="!authRoleClasses.superUser && authRoleClasses.initiateMerge && msixId && responsiveUI.isDesktop">
              <i class="flex-10-historical fa fa-compress iconColor"
                [ngClass]="{'disabledColor': inMergeWorklist || inPendingMergeWorklist}"
                aria-hidden="true"></i>
              <p class="flex-90 small rightPadding" *ngIf="!inMergeWorklist && !inPendingMergeWorklist">Flag for Merge
              </p>
              <p class="flex-90 small rightPadding disabledColor" *ngIf="inMergeWorklist">Flagged for Merge</p>
              <p class="flex-90 small rightPadding disabledColor" *ngIf="inPendingMergeWorklist">Pending Merge
                Validation</p>
            </div>
            <div class="layout-row" (click)="(inPendingSplitWorklist || inSplitWorklist) || flagForSplit()"
              *ngIf="!authRoleClasses.superUser && authRoleClasses.initiateMerge && canSplit == true && msixId && responsiveUI.isDesktop"
              [disabled]="inSplitWorklist || inPendingSplitWorklist">
              <i class="flex-10-historical fa fa-expand iconColor"
                [ngClass]="{'disabledColor':inSplitWorklist || inPendingSplitWorklist}"
                aria-hidden="true"></i>
              <p class="flex-90 small rightPadding" *ngIf="!inSplitWorklist && !inPendingSplitWorklist">Flag for Split
              </p>
              <p class="flex-90 small rightPadding disabledColor" *ngIf="inSplitWorklist">Flagged for Split</p>
              <p class="flex-90 small rightPadding disabledColor" *ngIf="inPendingSplitWorklist">Pending Split
                Validation</p>
            </div>
            <div class="layout-row" (click)="corDialogActivate('moveNotice')"
              *ngIf="!authRoleClasses.superUser && msixId && responsiveUI.isDesktop">
              <i class="flex-15-historical fa fa-arrow-right" aria-hidden="true"></i>
              <p class="flex-85 small rightPadding">Move Notice</p>
            </div>
            <div *ngIf="msixId">
              <div></div>
            </div>
            <div class="hideOnPrint" md-position-mode="target-left bottom" *ngIf="msixId && responsiveUI.isDesktop">
              <div class="exportButton layout-row" mat-button [matMenuTriggerFor]="menu">
                <p class="flex-90 small">Export</p>
                <i class="flex-10-historical fa fa-caret-down color-green" aria-hidden="true"></i>
              </div>
              <div class="no-padding-bottom layout-row exportMenu">
                <mat-menu class="layout-row no-padding-bottom custom-export" #menu="matMenu">
                  <!--note: we can remove target but making it open in new page for easier testing-->
                  <button mat-menu-item 
                    printTitle="MSIX" 
                    printSectionId="print-section" 
                    ngxPrint 
                    [useExistingCss]="true"
                    styleSheetFile="assets/student-details.component-temp.scss">
                    <div class="layout-row">
                      <i class="fa fa-print color-green no-padding top-bottom-margin-4 center-text"
                        aria-hidden="true"></i>
                      <p class="small no-padding no-top-bottom-margin left-padding-10">HTML</p>
                    </div>
                  </button>
                  <a mat-menu-item [href]="exportCallAsPDF"
                    *ngIf="showHistorical || showRaw" target="_blank" rel="noopener noreferrer"
                    (click)="exportFile('PDF')">
                    <div class="layout-row">
                      <i class="fa fa-file-pdf-o color-green no-padding top-bottom-margin-4 center-text"
                        aria-hidden="true"></i>
                      <p class="small no-padding no-top-bottom-margin left-padding-10">PDF</p>
                    </div>
                  </a>
                  <a mat-menu-item *ngIf="showConsolidated" (click)="activateExportFilter()">
                    <div class="layout-row">
                      <i class="fa fa-file-pdf-o color-green no-padding top-bottom-margin-4 center-text"
                        aria-hidden="true"></i>
                      <p class="small no-padding no-top-bottom-margin left-padding-10">Custom PDF</p>
                    </div>
                  </a>
                  <a mat-menu-item [href]="exportCallAsWord"
                    target="_blank" rel="noopener noreferrer" (click)="exportFile('WORD')">
                    <div class="layout-row">
                      <i class="fa fa-file-word-o color-green no-padding top-bottom-margin-4 center-text"
                        aria-hidden="true"></i>
                      <p class="small no-padding no-top-bottom-margin left-padding-10">Word</p>
                    </div>
                  </a>
                  <a mat-menu-item (click)="exportFile('STATE')">
                    <div class="layout-row">
                      <i class="fa fa-file-word-o color-green no-padding top-bottom-margin-4 center-text"
                        aria-hidden="true"></i>
                      <p class="small no-padding no-top-bottom-margin left-padding-10">State</p>
                    </div>
                  </a>
                </mat-menu>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="flexSpaceBetween goBack" *ngIf="$mdMedia('xs')">-->
      <!--<a (click)="goToSearchResults()">-->
      <!--<i class="fa fa-chevron-left" aria-hidden="true"></i>&nbsp;<span>Search Results</span>-->
      <!--</a>-->
      <!--</div>-->

    </div>
  </div>

  <hr class="thinHr">

  <!-- Student IDs Desktop Begin -->
  <div class="layout-row desktopStudentIds flexSpaceBetween" *ngIf="responsiveUI.isDesktop">
    <div class="flex-baseline">
      <div class="block2 uppercase">Msix Id:</div>
      <p>{{msixId}}</p>
    </div>
    <div class="flex-baseline">
      <div class="block2 uppercase">Submitting States:</div>
      <p>{{submittingStates}}</p>
    </div>
    <div class="flex-inline hideOnPrint" *ngIf="!(printView || !msixId)">
      <button style="border: none;" class="md-button saveToList" type="button" (click)="openDialog()">
        {{isSaved ? 'EDIT/REMOVE FROM LIST' : 'ADD TO LIST'}}
      </button>
    </div>
  </div>
  <!-- Student IDs Desktop End -->

  <div>
    <!-- Student IDs Mobile/Tablet Begin -->
    <div class="studentIds" *ngIf="!responsiveUI.isDesktop">
      <div *ngIf="responsiveUI.isMobile">
        <div class="flex-inline" *ngIf="!(isSaved || printView || !msixId)">
          <button style="border: none;" class="md-button saveToList" type="button" (click)="openDialog()">
            ADD TO LIST
          </button>
        </div>
        <div class="flex-inline" *ngIf="!(!isSaved || printView || !msixId)">
          <button style="border: none;" class="md-button div saveToList" type="button" (click)="removeStudentInMobile()">
            REMOVE FROM LIST
          </button>
        </div>
      </div>

      <div class="layout-row">
        <div class="flex-50">
          <div class="block2 uppercase">Msix Id:</div>
          <p>{{msixId}}</p>
        </div>
        <div class="flex-50">
          <div class="block2 uppercase">Submitting States:</div>
          <p>{{submittingStates}}</p>
        </div>
        <!--Tablet -->
        <div *ngIf="responsiveUI.isTablet">
          <div class="flex-inline" *ngIf="!(printView || !msixId)">
            <button style="border: none; white-space: nowrap;" class="md-button saveToList" type="button" (click)="openDialog()">
              {{isSaved ? 'EDIT/REMOVE FROM LIST' : 'ADD TO LIST'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Student IDs Mobile/Tablet End -->

    <!-- Student Overview mobile/tablet view begin-->
    <div *ngIf="!responsiveUI.isDesktop">
      <div class="table tableHeader" (click)="showOverview = !showOverview">
        <div layout="row" class="layout-row tableRow headerRow flexSpaceBetween">
          <p>Student Overview</p>
          <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showOverview"></i>
          <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showOverview"></i>
        </div>
      </div>
      <div class="table tableBody" *ngIf="showOverview">
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Birth Date</div>
          <p *ngIf="loadComplete && student.birthDate" class="flex-historical">{{student.birthDate}} (Age: {{studentAge}})</p>
          <p *ngIf="!student.birthDate || student.birthDate === '' " class="flex-historical">--</p>

        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Birth Date Verification</div>
          <p *ngIf="student.birthDateVerify" class="flex-historical">{{student.birthDateVerify}}</p>
          <p *ngIf="!student.birthDateVerify || student.birthDateVerify === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Multiple Birth</div>
          <p *ngIf="loadComplete" class="flex-historical">{{(student.multipleBirths==" " || !student.multipleBirths ||
            student.multipleBirths=="" || student.multipleBirths=="NO") ? 'No' : student.multipleBirths}}</p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 1</div>
          <div class="flex-10"></div>
          <p *ngIf="student.maleParentName" flex class="camelcase">
            {{student.maleParentName.toLowerCase()}}</p>
          <p *ngIf="!student.maleParentName || student.maleParentName === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 1 Email Address</div>
          <div class="flex-10"></div>
          <p *ngIf="student.parent1EmailAddress" flex class="camelcase">
            {{student.parent1EmailAddress.toLowerCase()}}</p>
          <p *ngIf="!student.parent1EmailAddress || student.parent1EmailAddress === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 1 Phone Number</div>
          <div class="flex-10"></div>
          <p *ngIf="student.parent1PhoneNumber" flex class="camelcase">
            {{student.parent1PhoneNumber.toLowerCase()}}</p>
          <p *ngIf="!student.parent1PhoneNumber || student.parent1PhoneNumber === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 2</div>
          <div class="flex-10"></div>
          <p *ngIf="student.femaleParentName" flex class="camelcase">
            {{student.femaleParentName.toLowerCase()}}</p>
          <p *ngIf="!student.femaleParentName || student.femaleParentName === '' " class="flex-historical">--
          </p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 2 Email Address</div>
          <div class="flex-10"></div>
          <p *ngIf="student.parent2EmailAddress" flex class="camelcase">
            {{student.parent2EmailAddress.toLowerCase()}}</p>
          <p *ngIf="!student.parent2EmailAddress || student.parent2EmailAddress === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Parent 2 Phone Number</div>
          <div class="flex-10"></div>
          <p *ngIf="student.parent2PhoneNumber" flex class="camelcase">
            {{student.parent2PhoneNumber.toLowerCase()}}</p>
          <p *ngIf="!student.parent2PhoneNumber || student.parent2PhoneNumber === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow altIds">
          <div flex="35" class="block2 uppercase mobile-item-padding">State or Migrant ID</div>
          <div class="flex-10"></div>
          <div layout="column">
            <div class="align-right">
              <p class="key-value" flex *ngIf="student.altIds[0].altId">
                {{student.altIds[0].altId}}  ({{student.altIds[0].altIdState}})
              </p>
              <p class="key-value" *ngIf="!student.altIds[0].altId || student.altIds[0].altId === ''" flex>--</p>
            </div>
          </div>
        </div>
        <div layout="row" class="tableRow">
          <div flex="35" class="block2 uppercase mobile-item-padding">Sex</div>
          <div class="flex-10"></div>
          <p *ngIf="student.gender" flex class="camelcase">{{student.gender}}</p>
          <p *ngIf="!student.gender || student.gender === '' " class="flex-historical">--</p>
        </div>
      </div>
    </div>
    <!-- Student Overview mobile/tablet view end-->

    <!-- Qualifying Information mobile/tablet view begin-->
    <div *ngIf="!responsiveUI.isDesktop">
      <div class="table tableHeader" (click)="showQualInfo = !showQualInfo">
        <div layout="row" class="tableRow headerRow flexSpaceBetween">
          <p>Qualifying Move Information</p>
          <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showQualInfo"></i>
          <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showQualInfo"></i>
        </div>
      </div>
      <div class="table tableBody" *ngIf="showQualInfo">
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Qualifying Arrival Date</div>
          <p *ngIf="qualMoves.qualArrivalDate" class="flex-historical">{{qualMoves.qualArrivalDate}}</p>
          <p *ngIf="!qualMoves.qualArrivalDate || qualMoves.qualArrivalDate === '' " class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Eligibility Expiration Date</div>
          <p *ngIf="qualMoves.eligibilityExpirationDate && qualMoves.eligibilityExpirationDate != ' '" class="flex-historical">
            {{qualMoves.eligibilityExpirationDate}}
          </p>
          <p *ngIf="!qualMoves.eligibilityExpirationDate || qualMoves.eligibilityExpirationDate === '' || qualMoves.eligibilityExpirationDate === ' '"
            class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Qualifying Move From</div>
          <p *ngIf="loadComplete && (moveFromCity && qualMoves.qadFromState)" class="flex-historical">{{moveFromCity}},
            {{qualMoves.qadFromState}}
          </p>
          <p *ngIf="loadComplete && !qualMoves.qadFromState && qualMoves.qadFromCountry && moveFromCity" class="flex-historical">
            {{moveFromCity}}, {{qualMoves.qadFromCountry}}</p>
          <p *ngIf="!moveFromCity || moveFromCity === ''" class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Qualifying Move To</div>
          <p *ngIf="loadComplete && (moveToCity && qualMoves.qadToState)" class="flex-historical">{{moveToCity}}, {{qualMoves.qadToState}}
          </p>
          <p *ngIf="!moveToCity || moveToCity === ''" class="flex-historical">--</p>
        </div>
      </div>
    </div>
    <!-- Qualifying Information mobile/tablet view end-->

    <!-- Academic Information mobile/tablet view begin-->
    <div *ngIf="!responsiveUI.isDesktop">
      <div class="table tableHeader" (click)="showAcademicInfo = !showAcademicInfo">
        <div layout="row" class="tableRow headerRow flexSpaceBetween">
          <p>Academic Information</p>
          <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showAcademicInfo"></i>
          <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showAcademicInfo"></i>
        </div>
      </div>
      <div class="table tableBody" *ngIf="showAcademicInfo">
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Graduation/HSE Indicator</div>
          <p *ngIf="graduationHSEIndicator && graduationHSEIndicator !== '' && graduationHSEIndicator !== ' '" class="flex-historical">
            {{graduationHSEIndicator}}</p>
          <p *ngIf="!graduationHSEIndicator || graduationHSEIndicator === '' || graduationHSEIndicator === ' ' " class="flex-historical">--
          </p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Graduation/HSE Date</div>
          <p *ngIf="graduationHSEDate && graduationHSEDate !== '' && graduationHSEDate !== ' '" class="flex-historical">
            {{graduationHSEDate}}</p>
          <p *ngIf="!graduationHSEDate || graduationHSEDate === '' || graduationHSEDate === ' '" class="flex-historical">--</p>
        </div>
        <div layout="row" class="tableRow">
          <div class="flex-45 block2 uppercase mobile-item-padding">Algebra I or Equivalent Indicator</div>
          <p *ngIf="algebra1orEquivalentIndicator && algebra1orEquivalentIndicator !== '' && algebra1orEquivalentIndicator !== ' '"
            class="flex-historical">{{algebra1orEquivalentIndicator}}</p>
          <p *ngIf="!algebra1orEquivalentIndicator || algebra1orEquivalentIndicator === '' || algebra1orEquivalentIndicator === ' '"
            class="flex-historical">--</p>
        </div>
      </div>
    </div>
    <!-- Academic Information mobile/tablet view end-->

    <!--Raw/Historical View (Desktop only)-->
    <app-student-details-historical-raw-view *ngIf="showHistorical || showRaw" 
    [rawHistoricalDataElements]="rawHistoricalDataElements"
    [showHistorical]="showHistorical">
    </app-student-details-historical-raw-view>
    <!--Raw/Historical View End-->

    <!-- Overview, Qual Info, & Academics End -->
    <div class="layout-row flexSpaceBetweenTop removeBorders" *ngIf="!(showHistorical || showRaw) && responsiveUI.isDesktop">
      <!-- Student Overview Table Begin -->
      <div style="width: 50%" flex-gt-md="50">
        <div class="table tableHeader table-background-white" (click)="showOverview = !showOverview">
          <div layout="row" class="tableRow headerRow flexSpaceBetween">
            <h2>Student Overview</h2>
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!showOverview"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="showOverview"></i>
          </div>
        </div>
        <div *ngIf="showOverview" class="table tableBody table-background-white col-container">
          <div class="layout-row">
            <div class="key-text">
              Birth Date
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" *ngIf="student.birthDate" flex>{{student.birthDate}} (Age: {{studentAge}})</p>
              <p class="key-value" *ngIf="!student.birthDate || student.birthDate === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Birth Date Verification
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="student.birthDateVerify">{{student.birthDateVerify}}</p>
              <p class="key-value" *ngIf="!student.birthDateVerify || student.birthDateVerify === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Multiple Birth
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex>{{(student.multipleBirths==" " ||
                !student.multipleBirths ||
                student.multipleBirths=="" || student.multipleBirths=="NO") ? 'No' : student.multipleBirths}}</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 1
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value camelcase" flex *ngIf="student.maleParentName">
                {{student.maleParentName.toLowerCase()}}</p>
              <p class="key-value"
                *ngIf="!student.maleParentName || student.maleParentName === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 1 Email Address
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="student.parent1EmailAddress">{{student.parent1EmailAddress.toLowerCase()}}</p>
              <p class="key-value" *ngIf="!student.parent1EmailAddress || student.parent1EmailAddress === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 1 Phone Number
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value camelcase" flex *ngIf="student.parent1PhoneNumber">{{student.parent1PhoneNumber.toLowerCase()}}</p>
              <p class="key-value" *ngIf="!student.parent1PhoneNumber || student.parent1PhoneNumber === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 2
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value camelcase" flex *ngIf="student.femaleParentName">
                {{student.femaleParentName.toLowerCase()}}</p>
              <p class="key-value"
                *ngIf="!student.femaleParentName || student.femaleParentName === ''" flex>--
              </p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 2 Email Address
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="student.parent2EmailAddress">{{student.parent2EmailAddress.toLowerCase()}}</p>
              <p class="key-value" *ngIf="!student.parent2EmailAddress || student.parent2EmailAddress === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Parent 2 Phone Number
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value camelcase" flex *ngIf="student.parent2PhoneNumber">{{student.parent2PhoneNumber.toLowerCase()}}</p>
              <p class="key-value" *ngIf="!student.parent2PhoneNumber || student.parent2PhoneNumber === ''" flex>--</p>
            </div>
          </div>

          <div class="altIds layout-row">
            <div class="key-text">
              State or Migrant ID
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="student.altIds[0].altId">
                {{student.altIds[0].altId}}  ({{student.altIds[0].altIdState}})
              </p>
              <p class="key-value" *ngIf="!student.altIds[0].altId || student.altIds[0].altId === ''" flex>--</p>
            </div>
          </div>
          <div class="layout-row">
            <div class="align-right" style="margin-top: -10px;">
              <!-- <span *ngFor="id in student.altIds | limitTo: (1 - student.altIds.length)"> -->
              <span *ngFor="let id of student.altIds | slice:1:(student.altIds.length)">
                <p class="key-value" style="line-height: 20px;" flex>{{id.altId}} ({{id.altIdState}})</p>
                <p class="key-value" *ngIf="!student.birthDate || student.birthDate === ''" flex>--</p>
              </span>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Sex
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value camelcase" flex *ngIf="student.gender">{{student.gender}}</p>
              <p class="key-value" *ngIf="!student.gender|| student.gender === ''" flex>--</p>
            </div>
          </div>

        </div>
      </div>
      <!-- Student Overview Table End -->

      <!-- Qualifying Info Table Begin -->
      <div *ngIf="responsiveUI.isDesktop" style="width: 50%" flex-gt-md="50" class="qualInfoTable">
        <div class="table tableHeader table-background-white" (click)="showQualInfo = !showQualInfo">
          <div layout="row" class="tableRow headerRow flexSpaceBetween">
            <h2>Qualifying Move Information</h2>
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!showQualInfo"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="showQualInfo"></i>
          </div>
        </div>
        <div *ngIf="showQualInfo" class="table tableBody table-background-white col-container">
          <div class="layout-row">
            <div class="key-text">
              Qualifying Arrival Date
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="qualMoves.qualArrivalDate">{{qualMoves.qualArrivalDate}}</p>
              <p class="key-value" *ngIf="!qualMoves.qualArrivalDate || qualMoves.qualArrivalDate === '' " flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Eligibility Expiration Date
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="qualMoves.eligibilityExpirationDate">
                {{qualMoves.eligibilityExpirationDate}}</p>
              <p class="key-value" style="padding-top: 0px"
                *ngIf="!qualMoves.eligibilityExpirationDate || qualMoves.eligibilityExpirationDate === '' || qualMoves.eligibilityExpirationDate === ' '"
                flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Qualifying Move From
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="moveFromCity && qualMoves.qadFromState">{{moveFromCity}},
                {{qualMoves.qadFromState}}</p>
              <p class="key-value" flex *ngIf="!qualMoves.qadFromState && qualMoves.qadFromCountry && moveFromCity">
                {{moveFromCity}}, {{qualMoves.qadFromCountry}}</p>
              <p class="key-value" *ngIf="!moveFromCity || moveFromCity === ''" flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Qualifying Move To
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex *ngIf="moveToCity && qualMoves.qadToState">{{moveToCity}},
                {{qualMoves.qadToState}}</p>
              <p class="key-value" *ngIf="!moveToCity || moveToCity === ''" flex>--</p>
            </div>
          </div>
        </div>
        <!-- Academic Information Table Begin -->
        <div class="table tableHeader table-background-white" (click)="showAcademicInfo = !showAcademicInfo">
          <div layout="row" class="tableRow headerRow flexSpaceBetween">
            <h2>Academic Information</h2>
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!showAcademicInfo"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="showAcademicInfo"></i>
          </div>
        </div>
        <div *ngIf="showAcademicInfo" class="table tableBody table-background-white col-container">
          <div class="layout-row">
            <div class="key-text">
              Graduation/HSE Indicator
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex
                *ngIf="graduationHSEIndicator && graduationHSEIndicator !== '' && graduationHSEIndicator !== ' '">
                {{graduationHSEIndicator}}</p>
              <p class="key-value" *ngIf="!graduationHSEIndicator || graduationHSEIndicator === '' " flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Graduation/HSE Date
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex
                *ngIf="graduationHSEDate && graduationHSEDate !== '' && graduationHSEDate !== ' '">{{graduationHSEDate}}
              </p>
              <p class="key-value" *ngIf="!graduationHSEDate || graduationHSEDate === '' || graduationHSEDate === ' '"
                flex>--</p>
            </div>
          </div>

          <div class="layout-row">
            <div class="key-text">
              Algebra I or Equivalent Indicator
            </div>
            <div class="dash-divider"></div>
            <div class="align-right">
              <p class="key-value" flex
                *ngIf="algebra1orEquivalentIndicator && algebra1orEquivalentIndicator !== '' && algebra1orEquivalentIndicator !== ' '">
                {{algebra1orEquivalentIndicator}}</p>
              <p class="key-value"
                *ngIf="!algebra1orEquivalentIndicator || algebra1orEquivalentIndicator === '' || algebra1orEquivalentIndicator === ' '"
                flex>--</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Qualifying Info Table End -->

    <!-- Enrollments Mobile/Tablet Table Begin -->
    <div *ngIf="!responsiveUI.isDesktop">
      <div class="table tableHeader" (click)="showEnrollments = !showEnrollments">
        <div layout="row" class="tableRow headerRow flexSpaceBetween">
          <p>Enrollments</p>
          <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showEnrollments"></i>
          <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showEnrollments"></i>
        </div>
      </div>
      <div class="table tableBody" *ngIf="showEnrollments">
        <div class="tableRow enrollmentTableRow" *ngFor="let enrollment of enrollments">
          <p class="camelcase">{{enrollment.schoolProjectName}}</p>
          <div>
            <p class="small">{{enrollment.state}}&nbsp;|&nbsp;{{enrollment.enrollmentDate}}&nbsp;|&nbsp;Grade:
              {{enrollment.gradeLevelCode}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Enrollments Mobile/Tablet Table End -->

    <!-- Enrollments Desktop Table Begin -->
    <div class="table-background-white">
      <div *ngIf="!(showHistorical || showRaw) && responsiveUI.isDesktop" class="desktopEnrollments removeBorders">
        <div class="table tableHeader">
          <div layout="row" class="tableRow headerRow">
            <h2>Enrollments</h2>
          </div>
        </div>
      </div>

      <div class="headerTitles layout-row" *ngIf="responsiveUI.isDesktop && !(showHistorical || showRaw)">
        <div class="flex-container flex-95" *ngIf="(enrollments.length > 0)">
          <div class="flex-10 block2">State</div>
          <div class="flex-15 block2">School or Project</div>
          <div class="flex-15 block2">Enrollment Date</div>
          <div class="flex-15 block2">Withdrawal Date</div>
          <div class="flex block2">Grade</div>
          <div class="flex block2">Med Alert</div>
          <div class="flex block2">Immun</div>
          <div class="flex block2">EL</div>
          <div class="flex block2">PFS</div>
          <div class="flex block2">IEP</div>
        </div>
        <div flex *ngIf="(enrollments.length > 0)"></div>
        <div *ngIf="(enrollments.length === 0)">
          <div class="block2">No Enrollment Data is Available</div>
        </div>
      </div>

      <div *ngIf="!(showHistorical || showRaw) && responsiveUI.isDesktop">
        <div class="newtable2Row printBorder" *ngFor="let enr of enrollments; let $index=index" (click)="showEnrDetailsFn($index)"
          [ngClass]="{'greyBottomBorderThin': enrFlag[$index] === $index}">
          <div flex class="layout-row greyBottomBorderThin printBorder">
            <div class="left-padding-8 flex-95">
              <div class="flex-container section1">
                <p class="flex-10">{{enr.enrlmntStateCode}}</p>
                <p class="flex-15">{{enr.schoolProjectName}}</p>
                <p class="flex-15">{{enr.enrollmentDate}}</p>
                <p *ngIf="enr.withdrawalDate" class="flex-15">{{enr.withdrawalDate}}</p>
                <i class="flex-15 fa fa-minus" aria-hidden="true"
                  *ngIf="(!enr.withdrawalDate || enr.withdrawalDate == '')"></i>
                <!-- <i class="fa fa-minus removeIconMargin" aria-hidden="true"
                   class="flex-15"></i> -->
                <p class="flex">{{enr.gradeLevelCode}}</p>
                <i class="fa fa-minus removeIconMargin flex" aria-hidden="true" *ngIf="!enr.medAlert" flex></i>
                <p *ngIf="enr.medAlert" class="flex">{{enr.medAlert}}</p>
                <p *ngIf="enr.immunizationFlag" class="flex">{{enr.immunizationFlag}}</p>
                <i class="fa fa-minus removeIconMargin flex" aria-hidden="true"
                  *ngIf="(!enr.immunizationFlag || enr.immunizationFlag == '')" flex></i>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="enr.lepIndicator==='Yes'" flex>Yes</p>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="!enr.lepIndicator || enr.lepIndicator==='No'"
                  flex>No</p>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="enr.pfsFlag==='Yes'" flex>Yes</p>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="!enr.pfsFlag || enr.pfsFlag==='No'" flex>No
                </p>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="enr.iepIndicator==='Yes'" flex>Yes</p>
                <p class="removeIconMargin flex" aria-hidden="true" *ngIf="!enr.iepIndicator || enr.iepIndicator==='No'"
                  flex>No</p>
              </div>
            </div>
            <div class="expandColumn-green flex-container flex" flex>
              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(enrFlag[$index] === $index)"></i>
              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="enrFlag[$index] === $index"></i>
            </div>
          </div>
          <div style="display:none" class="layout-row section2 showPrint" [ngClass]="{'show': enrFlag[$index] === $index}">
            <div flex class="col-container flex-50" layout="column">
              <div class="layout-row">
                <p class="enrItemText">Enrollment Type:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.enrollmentType
                  || enr.enrollmentType==='') ? '--' : enr.enrollmentType}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">MEP Project Type:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.mepPrjctTypeCode
                  || enr.mepPrjctTypeCode==='') ? '--' : enr.mepPrjctTypeCode}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Continuation of Services Reason:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.continuingServiceReasonCode
                  || enr.continuingServiceReasonCode==='') ? '--' : enr.continuingServiceReasonCode}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Residency Date:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.residencyDate
                  || enr.residencyDate==='') ? '--' : enr.residencyDate}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Residency Verification Date:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.residencyVerificationDate
                  || enr.residencyVerificationDate==='') ? '--' : enr.residencyVerificationDate}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">District of Residence:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.residencyDistrictId
                  || enr.residencyDistrictId==='') ? '--' : enr.residencyDistrictId}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Algebra 1 or Equivalent Indicator:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.algebra1orEquivalentIndicator
                  || enr.algebra1orEquivalentIndicator==='') ? '--' :
                  enr.algebra1orEquivalentIndicator}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Graduation/HSE Date:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.graduationHSEDate
                  || enr.graduationHSEDate==='') ? '--' : enr.graduationHSEDate}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Graduation/HSE Indicator:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.graduationHSEIndicator
                  || enr.graduationHSEIndicator==='') ? '--' : enr.graduationHSEIndicator}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Designated Graduation School:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.designatedGradSchool
                  || enr.designatedGradSchool==='') ? '--' : enr.designatedGradSchool}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Enrollment Comment:</p>
                <!-- The top margin needs to match the text size of the enrollment comment (above). Current size for both is 13px-->
                <div class="dash-divider" style="background-position: top; margin:13px 5px 0px 5px;"></div>
                <p class="align-right enrItemDataText">{{(!enr.enrollmentComment
                  || enr.enrollmentComment==='') ? '--' : enr.enrollmentComment}}</p>
              </div>
            </div>
            <div flex class="col-container flex-50" layout="column">
              <div class="layout-row">
                <p class="enrItemText">School District ID:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.schoolDistrictId
                  || enr.schoolDistrictId==='') ? '--' : enr.schoolDistrictId}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">School District Name:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.schoolDistrict
                  || enr.schoolDistrict==='') ? '--' : enr.schoolDistrict}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">School NCES ID:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.schoolNCESID ||
                  enr.schoolNCESID==='') ? '--' : enr.schoolNCESID}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">School Telephone Number:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.schoolTelephone
                  || enr.schoolTelephone==='') ? '--' : enr.schoolTelephone}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Facility Name:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.schoolFacilityName
                  || enr.schoolFacilityName==='') ? '--' : enr.schoolFacilityName}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Facility Address:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.facilityLongAddress
                  || enr.facilityLongAddress==='') ? '--' : enr.facilityLongAddress}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Facility Location:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.facilityLocation
                  || enr.facilityLocation==='') ? '--' : enr.facilityLocation}}</p>
              </div>
              <div class="layout-row">
                <p class="enrItemText">Out of State Transcript Indicator:</p>
                <div class="dash-divider"></div>
                <p class="align-right enrItemDataText">{{(!enr.outofStateTranscript
                  || enr.outofStateTranscript==='') ? '--' : enr.outofStateTranscript}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Enrollments Desktop Table End -->

    <!-- Assessments Desktop Table Begin -->
    <div class="table-background-white">
      <div class="desktopAssessments removeBorders" *ngIf="responsiveUI.isDesktop && !(showHistorical || showRaw)">
        <div class="table tableHeader">
          <div layout="row" class="tableRow headerRow">
            <h2>Assessments</h2>
          </div>
        </div>
      </div>

      <!-- Asessments Headers -->
      <div class="headerTitles layout-row" *ngIf="responsiveUI.isDesktop && !(showHistorical || showRaw)">
        <div class="flex-95 layout-row block2" *ngIf="(assessments.length > 0)">
          <div class="flex-10">State</div>
          <div class="flex-15">Assessment Title</div>
          <div class="flex-15">Date</div>
          <div class="flex">Content</div>
          <div class="flex">Type</div>
          <div class="flex">Interpretation</div>
        </div>
        <div class="flex" *ngIf="(assessments.length > 0)"></div>
        <div *ngIf="(assessments.length === 0)">
          <div class="block2">No Assessment Data is Available</div>
        </div>
      </div>

      <div *ngIf="responsiveUI.isDesktop && !(showHistorical || showRaw)">
        <div class="greyBottomBorderThin layout-column newtable2Row" *ngFor="let assess of assessments; let $index=index"
          (click)="showAssessDetailsFn($index)" [ngClass]="{'greyBottomBorderThin': assessIndex === $index}">
          <div class="flex layout-row">
            <div class="greyBottomBorderThin left-padding-8 flex-95">
              <div class="flex-container section1">
                <p class="flex-10">{{assess.state}}</p>
                <p class="flex-15">{{assess.asmtTitle}}</p>
                <p class="flex-15">{{assess.asmtAdminDate}}</p>
                <p class="flex">{{assess.asmtContent}}</p>
                <i class="fa fa-minus flex" aria-hidden="true" *ngIf="!assess.asmtType" flex></i>
                <p class="flex" *ngIf="assess.asmtType">{{assess.asmtType}}</p>
                <i class="flex fa fa-minus" aria-hidden="true" *ngIf="!assess.asmtInterpretation"></i>
                <p *ngIf="assess.asmtInterpretation" class="flex">{{assess.asmtInterpretation}}</p>
              </div>
            </div>
            <div class="greyBottomBorderThin expandColumn-green flex-container flex">
              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(assessIndex === $index)"></i>
              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="assessIndex === $index"></i>
            </div>
          </div>

          <div class="flex layout-row worklist-history-accordion newtable2Row table-row-margin font-13"
            *ngIf="assessIndex === $index">
            <div class="flex-25 column layout-column">
              <p class="bold">Reporting Method:</p>
              <p flex>{{(!assess.asmtRptMethod || assess.asmtRptMethod==='') ? '--' :
                assess.asmtRptMethod}}</p>
            </div>

            <div class="flex-25 column layout-column">
              <p class="bold">Score Results:</p>
              <p flex>{{(!assess.scoreResults || assess.scoreResults==='') ? '--' :
                assess.scoreResults}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Assessments Desktop Table End -->

    <!-- Course History Desktop Table Begin -->
    <div class="table-background-white">
      <div *ngIf="!(showHistorical || showRaw) && responsiveUI.isDesktop" class="desktopCourses removeBorders">
        <div class="table tableHeader">
          <div layout="row" class="tableRow headerRow">
            <h2>Course History</h2>
          </div>
        </div>
      </div>

      <div class="headerTitles" *ngIf="responsiveUI.isDesktop && !(showHistorical || showRaw)">
        <div class="layout-row" style="max-width: 95%" *ngIf="(courses.length > 0)">
          <!-- <div class="flex-10">State</div> -->
          <div class="block2 flex-15-course-history">Course Title</div>
          <div class="block2 flex-15-course-history">Academic Year</div>
          <div class="block2 flex-course-history">Subject Area</div>
          <div class="block2 flex-10-course-history">Final Grade</div>
          <div class="block2 flex-course-history" flex>Credits Granted</div>
          <div class="block2 flex-course-history" flex>Grade-To-Date</div>
          <div class="block2 flex-10-course-history">Clock Hours</div>
        </div>
        <div flex *ngIf="(courses.length > 0)"></div>
        <div *ngIf="(courses.length === 0)">
          <div class="block2">No Course History Data is Available</div>
        </div>
      </div>

      <div *ngIf=" !(showHistorical || showRaw)">
        <div class="newtable2Row" *ngFor="let course of courses; let $index=index" (click)="showCourseDetailsFn($index)"
          [ngClass]="{'greyBottomBorderThin': courseIndex === $index}">
          <div style="flex-direction: row; box-sizing: border-box; display: flex;" class="greyBottomBorderThin">
            <div class="left-padding-8 flex-95">
              <div style="justify-content:left" class="flex-container section1">
                <i class="flex-15-course-history fa fa-minus" aria-hidden="true"
                  *ngIf="!course.courseTitle || course.courseTitle===''"></i>
                <p class="flex-15-course-history" *ngIf="course.courseTitle">{{course.courseTitle}}</p>

                <p class="flex-15-course-history" *ngIf="!course.beginAcademicYear && !course.endAcademicYear">
                  <i class="flex-15-course-history fa fa-minus" aria-hidden="true"></i>-
                  <i class="flex-15-course-history fa fa-minus" aria-hidden="true"></i>
                </p>
                <p class="flex-15-course-history" *ngIf="course.beginAcademicYear && !course.endAcademicYear">
                  {{course.beginAcademicYear}}-<i class="flex-15-course-history fa fa-minus" aria-hidden="true"></i>
                </p>
                <p class="flex-15-course-history" *ngIf="!course.beginAcademicYear && course.endAcademicYear">
                  <i class="flex-15-course-history fa fa-minus" aria-hidden="true"></i>-{{course.endAcademicYear}}
                </p>
                <p class="flex-15-course-history" *ngIf="course.beginAcademicYear && course.endAcademicYear">
                  {{course.beginAcademicYear}}-{{course.endAcademicYear}}</p>

                <i class="flex-course-history fa fa-minus" aria-hidden="true"
                  *ngIf="!course.subjectArea || course.subjectArea===''"></i>
                <p class="flex-course-history" *ngIf="course.subjectArea">{{course.subjectArea}}</p>

                <i class="flex-10 fa fa-minus" aria-hidden="true"
                  *ngIf="!course.finalGrade || course.finalGrade===''"></i>
                <p class="flex-10-course-history" *ngIf="course.finalGrade">{{course.finalGrade}}</p>

                <i class="flex-course-history fa fa-minus" aria-hidden="true"
                  *ngIf="!course.creditsGranted || course.creditsGranted===''"></i>
                <p class="flex-course-history" *ngIf="course.creditsGranted">{{course.creditsGranted}}</p>

                <i class="flex-course-history fa fa-minus" aria-hidden="true"
                  *ngIf="!course.gradeToDate || course.gradeToDate===''"></i>
                <p class="flex-10-course-history" *ngIf="course.gradeToDate">{{course.gradeToDate}}</p>

                <i class="flex-10 fa fa-minus" aria-hidden="true"
                  *ngIf="!course.clockHours || course.clockHours===''"></i>
                <p class="flex-10-course-history" *ngIf="course.clockHours">{{course.clockHours}}</p>
              </div>
            </div>
            <div class="expandColumn-green flex-container" flex>
              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(courseIndex === $index)"></i>
              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="courseIndex === $index"></i>
            </div>
          </div>
          <div class="layout-row worklist-history-accordion newtable2Row table-row-margin font-13"
            *ngIf="courseIndex === $index">
            <div flex layout="column" class="column flex">
              <p class="bold">State:</p>
              <p flex>{{(!course.state || course.state==='') ? '--' :
                course.state}}</p>
            </div>

            <div flex layout="column" class="column flex">
              <p class="bold">Course Section:</p>
              <p flex>{{(!course.courseSection || course.courseSection==='') ? '--' :
                course.courseSection}}</p>
            </div>

            <div flex layout="column" class="column flex">
              <p class="bold">Course Type:</p>
              <p flex>{{(!course.courseType || course.courseType==='') ? '--' :
                course.courseType}}</p>
            </div>

            <div flex layout="column" class="column flex">
              <p class="bold">Term Type:</p>
              <p flex>{{(!course.termType || course.termType==='') ? '--' :
                course.termType}}</p>
            </div>

          </div>
        </div>
      </div>

    </div>
    <!-- Course History Desktop Table End -->

    <!--correspondence-->

    <div class="table-background-white">
      <div flex class="top-bottom-margin-15" *ngIf="!(showHistorical || showRaw) && responsiveUI.isDesktop && authRoleClasses.privacyEdit">
        <div class="desktopEnrollments removeBorders">
          <div class="table tableHeader">
            <div layout="row" class="tableRow headerRow">
              <h2>Privacy Act Comments</h2>
            </div>
          </div>
        </div>

        <div class="worklist-history-accordion">
          <div class="worklist-header-titles flex-container privacyActHeaderTitles" layout="row" flex="100"
            *ngIf="student.privacyActComments.length > 0">
            <div class="flex-container flex-95">
              <div class="flex-15">User</div>
              <div flex="40">Message</div>
              <div class="flex-15">Entered</div>
              <div class="flex-15">Updated</div>
              <div class="flex-15">Edit</div>
            </div>
          </div>
          <div class="flex-container layout-row flex" *ngFor="let comment of student.privacyActComments">
            <div class="flex-container flex-95">
              <p class="flex-15">{{comment.createdBy}}</p>
              <p class="flex-40">{{comment.comments}}</p>
              <p class="flex-15">{{comment.dateEntered}}</p>
              <p class="flex-15">{{comment.dateUpdated}}</p>
              <p class="flex-15">
                <a (click)="updatePrivacyActComment(comment)" aria-label="Edit" tabindex="0">
                  <span>edit</span>
                </a>
              </p>
            </div>
          </div>


          <!-- Comments text area -->
          <div class="custom-input-container">
            <mat-form-field style="height: 130px; width: 100%;" class="example-full-width comment-box comments">
              <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || textValue}"
                for="inputField">Comments</mat-label>
              <label class="custom-label" [ngClass]="{'box-selected': inputField.focused || textValue}"
              for="inputField">Comments</label>
              <textarea matInput #inputField="matInput" style="height: 100px; border: 1px solid #888;"
                class="comment-text" [ngClass]="{'mat-input-has-value': textValue}" [(ngModel)]="textValue"
                name="inputField" maxlength="250"></textarea>
              <div class="md-errors-spacer">
                <div class="md-char-counter">{{textValue.length}} / 250</div>
              </div>
            </mat-form-field>
          </div>

          <!-- Bottom Row: State keys and Send Button -->
          <div class="layout-row privacy-input">
            <div class="custom-input-container state-student-key-box" flex="25">
              <mat-form-field style="border: 1px solid #888; height: 44px; width: 120%">
                <mat-label class="custom-label" style="margin-top: -5px;" for="stateKeyInput">State Student Key
                </mat-label>
                <label class="custom-label" style="margin-top: -5px;" for="stateKeyInput">State Student Key
                </label>
                <mat-select disableOptionCentering formControlName="stateStudentKey" style="padding-left: 5px;"
                  name="stateKeyInput">
                  <mat-option *ngFor="let state of student.relatedPrivacyActSSIDs" value="state.stateKey">
                    {{state.staStudKey}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div flex="60" class="commentbox-instructions">
              <p class="small worklist-commentbox-instructions"></p>
            </div>
            <button class="align-right md-button top-bottom-margin-8"
              (click)="submitPrivacyActComment()" [disabled]="!textValue"
              style="padding: 0px 50px;">
              Send
            </button>
          </div>
        </div>

      </div>
    </div>

    <!-- Flag and Move Notification Buttons Begin-->
    <div class="center" *ngIf="!authRoleClasses.superUser && (!responsiveUI.isDesktop)">
      <div class="div flagButton" (click)="inMergeWorklist || flagForMerge()" [disabled]="inMergeWorklist"
        id="mergeWorklistButton" *ngIf="authRoleClasses.initiateMerge">
        FLAG FOR MERGE
      </div>
      <div class="div flagButton" (click)="inSplitWorklist || flagForSplit()"
        *ngIf="authRoleClasses.initiateMerge && canSplit == true" [disabled]="inSplitWorklist"
        id="splitWorklistButton">
        FLAG FOR SPLIT
      </div>
      <div class="div flagButton" (click)="corDialogActivate('moveNotice')">
        Move Notice
      </div>
      <div class="div flagButton" (click)="corDialogActivate('requestData')">
        Request Data
      </div>
      <!-- Flag and Move Notification Buttons End -->

    </div>

  </div>
</div>