import { Component, OnInit } from '@angular/core';
import { AppConstants }  from './../../config/index.constants'
import {AuthService} from './../../services/auth.service'
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
    trainingCorner_url = AppConstants.trainingCorner;
    help_url = AppConstants.help;
    loggedIn: boolean = false;
    isLoggedInWithOTP: boolean = false;

    constructor(public responsiveUI: ResponsiveUIService,
      private cookieService: CookieService,
      private authService: AuthService) {
  
    }
  ngOnInit(): void {
    let userObj: any = this.cookieService.getObject('user');
    
    this.loggedIn = this.authService.isAuthenticated();
    this.isLoggedInWithOTP = userObj.isOTP;
  }   
}