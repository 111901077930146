export enum COLUMN_NUMBER {
    COLUMN_ONE = 'column1',
    COLUMN_TWO = 'column2',
    COLUMN_THREE = 'column3',
    COLUMN_FOUR = 'column4',
    COLUMN_FIVE = 'column5',
}

export enum REQUEST_TYPE {
    STUDENT_GRADE_RETENTION = 'STUDENT_GRADE_RETENTION',
    STUDENT_AGE_STUDENT_ID = 'STUDENT_AGE_STUDENT_ID',
    STUDENT_GENDER = 'STUDENT_GENDER',
    STUDENT_AGE = 'STUDENT_AGE',
    STUDENT_DISTINCT_STUDENT_COUNT = 'STUDENT_DISTINCT_STUDENT_COUNT',
    STUDENT_NEAR_MATCH_STUDENT_COUNT = 'STUDENT_NEAR_MATCH_STUDENT_COUNT',
    STUDENT_MULTI_STATE_STUDENT_COUNT = 'STUDENT_MULTI_STATE_STUDENT_COUNT'
}

