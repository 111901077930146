import { Component, Inject } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import * as angular from "angular";
import * as moment from 'moment';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from 'src/app/services/previous-route.service';

@Component({
  selector: 'generalMoveTo',
  templateUrl: './generalMoveTo.component.html',
  styleUrls: ['./generalMoveTo.component.scss']
})
export class GeneralMoveToComponent {
  // holds the set of selected stateKeys
  states: any[] = [];
  selectedStatesArray: any[] = [];
  selectedStatesArray2: any[] = [];
  fullStateArray: any[] = [];
  isMobile: boolean;
  mobileSelectedState: string = "- All -";
  mobileSelectedState2: string = "- All -";
  doubleHeader: any = {
    first: 'Move From',
    second: 'Move To'
  };
  qadToggle: boolean = false;
  // holds the current active filter parameters
  activeFilter: any = {};
  // holds the API call variables for ReportsService
  reportGroupVar: string = 'studentInfo';
  reportDrillDownVar: string = 'studentMobilityDrillDownInfo';
  //this object holds Report Data for initial display via reportInit()
  response: any = {};
  blankRes: boolean = false;
  //holds values for maintaining sort
  private sortAscending: boolean = true;
  private currentSortColumn: string = 'column1';
  //this object holds current & recent reporting period for display purposes
  displayData = {
    current: "",
    recent: "",
    category: "",
    subcategory: ""
  };
  // date object
  qadDate: any = {};
  showDrill: boolean = false;
  showFilter = true;
  authRoleClasses = {};
  drillDown: any;
  filterParameters: any = {};
  reportInfo: any = {};
  drillDownData: any = {};
  exportCall = "studentMobilityExport";
  drillExportCall = "studentMobilityDrillDownExport";
  stateMap = new Map();
  subscription = new Subscription();
  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private _ReportInfoService: ReportInfoService,
    public responsiveUI: ResponsiveUIService,
    private previousRouteService: PreviousRouteService) {
      
    this.isMobile = this.responsiveUI.isMobile;
    this.filterParameters = {
      qualArrDateFrom: null,
      qualArrDateTo: null,
      rptGrping: "National",
      rptMove: "Enrollments",
      selectedStates: null,
      moveType: 2,
      periodType: "No Period",
      categoryType: "Category 1",
      noyes: "no",
      mobilePrimaryState: null,
      mobileSecondaryStates: {},
      mobile: this.isMobile,
      boolInterMoves: false,
      boolIntraMoves: false
    };
    this.qadDate = {
      startMM: null,
      startDD: null,
      startYYYY: null,
      endMM: null,
      endDD: null,
      endYYYY: null
    };
    this.reportInfo.authRoleClasses = {};
  }

  async ngOnInit() {
    this.reportsService.sortColumn$.next({columnNumber: this.reportsService.sortColumn, sortOrder: this.reportsService.sortOrder}); // emit last set sortColumn state so reportsTable component can set the sortColumn and sortOrder in the subscription
    this.subscription.add(this.previousRouteService.previousRoute$.subscribe(route => {
      if (route === '/reports') {
        
      }
    }));
    if (this.isMobile) {
      this.filterParameters.noyes = "yes";
      this.filterParameters.periodType = "Current Period";
      this.mobileSelectedState = 'Alabama';
      this.mobileStateDropdown(1, 'Alabama', false);
      this.mobileSelectedState2 = '- All -'
    }
    await this.getReportInfo();
    await this.setSelectedStates();
    this.selectAll2();

    // Filter is not activated in mobile mode
    if (!this.isMobile) {
      this.filterActivate();
    }
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this._ReportInfoService.getReportInfo(['studentMobility_all', 'studentMobility']);
    console.log("Reportinfo: ", this.reportInfo);
    
    var temp = new Map();
    this.reportInfo.stateData.forEach(function (state) {
      temp.set(state.name.replace(/\s+$/, ''), state.stateKey);
    });
    this.stateMap = temp;

    await this.mineAndSetStatesArray(this.reportInfo.stateData);
  }

  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(obj);
        this.selectedStatesArray2.push(obj);
      }
    }
  }

  // set the select states in the filter object
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.studentMobility_all == true || this.isMobile) {
      this.filterParameters.selectedStates = null;
    } else {
      this.filterParameters.selectedStates = this.reportInfo.userState;
    }

    if (this.isMobile) {
      if (this.reportInfo.userState > -1) {
        this.mobileStateDropdown(this.reportInfo.userState, this.reportInfo.userStateName, false);
      } else {
        this.mobileSelectedState = 'Alabama';
        this.mobileStateDropdown(1, 'Alabama', false);
        this.mobileSelectedState2 = '- All -';
      }
    }
  }

  mobileStateDropdown(key, state, runReport) {
    this.selectNone();
    this.checkedStates(key);
    this.filterParameters.selectedStates = null;

    if (state == this.mobileSelectedState2) {
      this.mobileStateDropdown2(0, '- All -', false);
      this.mobileSelectedState2 = '- All -';
    }
    if (runReport) {    
      this.reportInit();
    }
  }

  mobileStateDropdown2(key, state, runReport) {
    //If state user, set mobileSelectedState == user's state
    if (this.reportInfo.userState > -1) {
      this.mobileSelectedState = this.reportInfo.userStateName;
    }
    this.selectNone2();
    if (key > 0) {
      this.checkedStates2(key);
    } else {
      this.selectAll2();
    }
    if (runReport) {    
      this.reportInit();
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    this.filterParameters.mobilePrimaryState = stateKey;
  }

  checkedStates2(stateKey) {
    this.filterParameters.mobileSecondaryStates = stateKey;
  }

  selectNone() {
    this.selectedStatesArray = [];
    this.filterParameters.mobilePrimaryState = this.selectedStatesArray;
  }

  selectNone2() {
    this.selectedStatesArray2 = [];
    this.filterParameters.mobileSecondaryStates = this.selectedStatesArray2;
  }

  selectAll2() {
    this.filterParameters.mobileSecondaryStates = "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,41,42,43,44,45,46,47,48,49,50,51"
  }

  // Trigger Filter Modal
  filterActivate() {
    this.filterParameters.qadDate = this.qadDate;
    this.filterParameters.reportInfo = this.reportInfo;
    this.filterParameters.mobileSelectedState = this.mobileSelectedState;
    this.filterParameters.mobileSelectedState2 = this.mobileSelectedState2;
    this.filterParameters.selectedStatesArray = this.selectedStatesArray;
    this.filterParameters.selectedStatesArray2 = this.selectedStatesArray2;

    this.showFilter = true;
    const dialogRef = this.dialog.open(FilterGeneralMoveTo, {
      height: 'auto',
      width: '50%',
      minHeight: '250px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.filterParameters
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activeFilter = result;
        this.filterParameters = result;
        this.qadDate = result.qadDate;
        this.mobileSelectedState = result.mobileSelectedState;
        this.mobileSelectedState2 = result.mobileSelectedState2;
        this.selectedStatesArray = result.selectedStatesArray;
        this.selectedStatesArray2 = result.selectedStatesArray2;
        this.reportInit();
        this.showFilter = false;
      }
    });
  }

  goToReport() {
    this.showDrill = false;
    this.blankRes = false;
  }

  //Pull in Report Data
  reportInit() {
    this.formatDate();
    var roles = this.reportInfo.authRoleClasses;
    if (roles.studentMobility_all != true && this.filterParameters.rptMove === 'Qualifying Moves') {
      this.filterParameters.rptGrping = "State";
    } else {
      this.filterParameters.rptGrping = "National";
    }
    console.log(this.filterParameters);

    //Remove extraneous variables from filterParameters
    this.filterParameters = {
      boolInterMoves: this.filterParameters.boolInterMoves,
      boolIntraMoves: this.filterParameters.boolIntraMoves,
      categoryType: this.filterParameters.categoryType,
      mobile: this.filterParameters.mobile,
      mobilePrimaryState: this.filterParameters.mobilePrimaryState,
      mobileSecondaryStates: this.filterParameters.mobileSecondaryStates,
      moveType: this.filterParameters.moveType,
      noyes: this.filterParameters.noyes,
      periodType: this.filterParameters.periodType,
      qualArrDateFrom: this.filterParameters.qualArrDateFrom,
      qualArrDateTo: this.filterParameters.qualArrDateTo,
      rptGrping: this.filterParameters.rptGrping,
      rptMove: this.filterParameters.rptMove,
      selectedStates: this.filterParameters.selectedStates
    }

    let apiCall = this.reportsService.studentMobility(this.filterParameters);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("GeneralMoveTo.reportInit(): ", data);
        this.blankRes = false;
        this.response = data;
        this.sortAscending = true;
        this.currentSortColumn = 'column1';
        if (this.response.mobileInfos.length === 0) {
          this.response.mobileInfos.push({ labelName: "Number of Moves", value: "0" })
        }
        if (this.response.rows.length === 0) {
          this.blankRes = true;
          subscribed.unsubscribe();
        }
      },
      error: error => {
        console.log("Error - GeneralMoveTo.reportInit(): ", error);
        this.blankRes = false;
        console.log(error);
        subscribed.unsubscribe();
      }
    });
  }

  formatDate() {
    if (this.qadDate.startYYYY || this.qadDate.startMM || this.qadDate.startDD) {
      var startEnrollmentDate = this.qadDate.startYYYY + '-' + this.qadDate.startMM + '-' + this.qadDate.startDD;
      this.filterParameters.qualArrDateFrom = moment(startEnrollmentDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.qualArrDateFrom = null;
    }
    if (this.qadDate.endYYYY || this.qadDate.endMM || this.qadDate.endDD) {
      var endEnrollmentDate = this.qadDate.endYYYY + '-' + this.qadDate.endMM + '-' + this.qadDate.endDD;
      this.filterParameters.qualArrDateTo = moment(endEnrollmentDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.qualArrDateTo = null;
    }
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(GeneralMoveToDDLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '250px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(GeneralMoveToLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: { params: this.authRoleClasses },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    }
  }

  //Pull in Drilldown data
  drillDownInit(data) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down
    let column = data.column;
    data = data.data;
    console.log("GeneralMoveTo - drillDownInit.data: ", data);
    console.log("GeneralMoveTo - drillDownInit.column: ", column);

    this.drillDown = {
      studentMobilityFilter: this.filterParameters,
      studentIDFilter: {
        districtId: null,
        category: this.stateMap.get(data.subcategory),
        category2: this.stateMap.get(data.category),
        beginRow: 1,
        endRow: 20,
        sortFields: "MSIX_ID_ASC"
      },
      exportType: "CSV"
    };
    if (this.filterParameters.rptMove === 'Qualifying Moves') {
      this.qadToggle = true;
      this.drillDown.studentIDFilter.stateKey = data.category;
      this.drillDown.studentIDFilter.category = data.countryCategory;
      this.drillDown.studentIDFilter.category2 = data.subcategory;
    } else {
      this.qadToggle = false;
    }

    this.drillDown.exportCount = data.count;

    //add states to DrillDown title
    this.displayData.subcategory = data.subcategory;
    this.displayData.category = data.category;

    //show Drill Down - hide Report
    this.showDrill = !this.showDrill;

    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    this.drillDownData = {};
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("GeneralMoveTo.updateDrilldown(): ", data);
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          console.log(this.drillDownData);
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - GeneralMoveTo.updateDrilldown(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(data) {
    this.drillDown.studentIDFilter.sortFields = data.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }
}

// Separate component for the GeneralMoveTo report's filter
@Component({
  selector: 'filterMoveTo',
  templateUrl: './filterMoveTo.html',
  styleUrls: ['./generalMoveTo.component.scss']
})
export class FilterGeneralMoveTo {
  isMobile: boolean;
  filterParameters: {
    qualArrDateFrom: any,
    qualArrDateTo: any,
    boolInterMoves: boolean,
    boolIntraMoves: boolean,
    rptGrping: string,
    rptMove: string,
    selectedStates: any,
    moveType: number,
    periodType: string,
    categoryType: string,
    noyes: string,
    mobile: boolean,
    mobilePrimaryState: any,
    mobileSecondaryStates: any,
    qadDate: any,
    reportInfo: any,
    mobileSelectedState: string,
    mobileSelectedState2: string,
    selectedStatesArray: any[],
    selectedStatesArray2: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterGeneralMoveTo>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.filterParameters = cloneDeep(data.params);
    this.isMobile = responsiveUI.isMobile;
    if (this.filterParameters.rptGrping == '' || !this.filterParameters.rptGrping) {
      this.resetFilter();
    }
  }

  resetFilter() {
    this.filterParameters = {
      qualArrDateFrom: null,
      qualArrDateTo: null,
      boolInterMoves: false,
      boolIntraMoves: false,
      rptGrping: "National",
      rptMove: "Enrollments",
      selectedStates: this.data.params.selectedStates,
      moveType: 2,
      periodType: "No Period",
      categoryType: "Category 1",
      noyes: "no",
      mobile: this.responsiveUI.isMobile,
      mobilePrimaryState: null,
      mobileSecondaryStates: null,
      qadDate: {},
      reportInfo: this.data.params.reportInfo,
      mobileSelectedState: this.data.params.mobileSelectedState,
      mobileSelectedState2: this.data.params.mobileSelectedState2,
      selectedStatesArray: [],
      selectedStatesArray2: []
    };
    this.selectAll2();
    if (this.isMobile) {
      this.filterParameters.noyes = "yes";
      this.filterParameters.periodType = "Current Period";
    }
  }

  resetDateFields() {
    this.filterParameters.qadDate = {
      startMM: null,
      startDD: null,
      startYYYY: null,
      endMM: null,
      endDD: null,
      endYYYY: null
    }
  }

  //Filter Logic
  filterLogicNo() {
    this.filterParameters.periodType = 'No Period';
    this.filterParameters.categoryType = 'Category 1';
  }

  filterLogicYes() {
    this.filterParameters.periodType = 'Most Recently Closed Period';
    this.filterParameters.qualArrDateFrom = null;
    this.filterParameters.qualArrDateTo = null;
    this.filterParameters.categoryType = 'Category 1';
    this.resetDateFields();
  }

  selectMoves(type) {
    if(type === 'inter') {
      this.filterParameters.boolInterMoves = !this.filterParameters.boolInterMoves;
    } else {
      this.filterParameters.boolIntraMoves = !this.filterParameters.boolIntraMoves;
    }
  }
  
  skipToQADStartDD() {
    if (this.filterParameters.qadDate.startMM) {
      if (this.filterParameters.qadDate.startMM.toString().length >= 2) {
        document.getElementById("qadStartDD").focus();
      }
    }
  }


  skipToQADStartYYYY() {
    if (this.filterParameters.qadDate.startDD) {
      if (this.filterParameters.qadDate.startDD.toString().length >= 2) {
        document.getElementById("qadStartYYYY").focus();
      }
    }
  }


  skipToQADEndDD() {
    if (this.filterParameters.qadDate.endMM) {
      if (this.filterParameters.qadDate.endMM.toString().length >= 2) {
        document.getElementById("qadEndDD").focus();
      }
    }
  }


  skipToQADEndYYYY() {
    if (this.filterParameters.qadDate.endDD) {
      if (this.filterParameters.qadDate.endDD.toString().length >= 2) {
        document.getElementById("qadEndYYYY").focus();
      }
    }
  }

  // set the select states in the filter object
  setSelectedStates() {
    var roles = this.filterParameters.reportInfo.authRoleClasses;
    if (roles.studentMobility_all == true || this.isMobile) {
      this.filterParameters.selectedStates = null;
    } else {
      this.filterParameters.selectedStates = this.filterParameters.reportInfo.userState;
    }

    if (this.isMobile) {
      if (this.filterParameters.reportInfo.userState > -1) {
        this.filterParameters.mobileSelectedState = this.filterParameters.reportInfo.userStateName;
        this.mobileStateDropdown(this.filterParameters.reportInfo.userState, this.filterParameters.reportInfo.userStateName);
      } else {
        this.filterParameters.mobileSelectedState = 'Alabama';
        this.mobileStateDropdown(1, 'Alabama');
      }
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    this.filterParameters.mobilePrimaryState = stateKey;
  }

  checkedStates2(stateKey) {
    this.filterParameters.mobileSecondaryStates = stateKey;
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.mobilePrimaryState = this.filterParameters.selectedStatesArray;
  }

  selectNone2() {
    this.filterParameters.selectedStatesArray2 = [];
    this.filterParameters.mobileSecondaryStates = this.filterParameters.selectedStatesArray2;
  }

  selectAll2() {
    this.filterParameters.mobileSecondaryStates = "1,2,3,4,5,6,7,8,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,41,42,43,44,45,46,47,48,49,50,51"
  }

  mobileStateDropdown(key, state) {
    this.selectNone();
    this.checkedStates(key);
    this.filterParameters.selectedStates = null;

    if (state == this.filterParameters.mobileSelectedState2) {
      this.mobileStateDropdown2(0, '- All -');
      this.filterParameters.mobileSelectedState2 = '- All -';
    }
  }
  mobileStateDropdown2(key, state) {
    this.selectNone2();
    if (key > 0) {
      this.checkedStates2(key);
    } else {
      this.selectAll2();
    }
  }

  onSubmit() {
    this.dialogRef.close(this.filterParameters);
  }

  onClose() {
    this.filterParameters = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.filterParameters = cloneDeep(this.data.params);
    this.dialogRef.close(this.filterParameters);
  }
}

// Separate component for the GeneralMoveTo report's Learn More dialog box
@Component({
  selector: 'generalMoveToLearnMore',
  templateUrl: 'generalMoveToLearnMore.html',
  styleUrls: ['./generalMoveTo.component.scss']
})
export class GeneralMoveToLearnMore {
  constructor(
    public dialogRef: MatDialogRef<GeneralMoveToLearnMore>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the GeneralMoveTo report's drilldown Learn More dialog box
@Component({
  selector: 'generalMoveToDDLearnMore',
  templateUrl: 'generalMoveToDDLearnMore.html',
  styleUrls: ['./generalMoveTo.component.scss']
})
export class GeneralMoveToDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<GeneralMoveToDDLearnMore>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

