<div class="potentialDuplicates">
  <div *ngIf="!showDrill">
    <div class="breadcrumb layout-row">
      <span><a routerLink="/reports">Reports</a></span>
      <span>></span>
      <span>
        <p>Potential Duplicates</p>
      </span>
    </div>

    <div class="header layout-row">
      <h1 class="flex header-padding">Potential Duplicates</h1>

      <div (click)="isMergeListEmpty || flagForMergeAllStudents()" [disabled]="isMergeListEmpty" class="flagButton layout-row" *ngIf="authRoleClasses.dataAdmins && enableFlagForMergeCheckbox">
        <i class="fa fa-compress" [ngClass]="isMergeListEmpty ? 'disabledColor':'iconColor'"
          aria-hidden="true"></i>
        <p style="font-size: .8rem;" [ngClass]="isMergeListEmpty ? 'disabledColor':'iconColor'">
          Flag for Merge</p>
      </div>
      <export-report class="flex-10" tabindex="0"
        [exportlink]="exportCall" 
        [exportfilter]="filterParameters" 
        [showpdf]="true"
        [showcsv]="true" 
        [blankres]="blankRes">
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="main-content layout-align-center-center">
      <div class="layout-column" flex="90">
        <div class="report-summary">
          <p>
            This report displays a list of MSIX Potential Duplicates or State MEP System Duplicates.
            Use the Filter to switch between the two types of potential duplicates. You can flag
            MSIX Potential Duplicates for merge directly from this report.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response"
          [showcheckbox]="authRoleClasses.dataAdmins && enableFlagForMergeCheckbox"
          [isreportlevelspecialsortinit]="isInit" 
          [hasreportlevelspecialsort]="true"
          [currentsortcolumn]="currentSortColumn" 
          [sortascending]="sortAscending"
          [drilldowninfo]="drillDownInfo"
          [emitRowClick]="true"
          (mycallback)="drillDownInit($event)" 
          (checkboxcallback)="checkedRecordForFlagForMerge($event)"
          (reportlevelspecialsort)="handleReportSpecialSortClick($event)" 
          (sortbycolumn)="sortByColumn($event)">
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>

        <div style="justify-content: center;" *ngIf="!blankRes" class="layout-row" layout-align="center center">
          <div flex="2">
            <button class="md-button" (click)="pageBack()" [disabled]="filterParameters.beginRow===1" aria-label="back">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div flex="2">
            <button class="md-button" (click)="pageForward()" [disabled]="availableReportRows <= filterParameters.endRow"
              aria-label="forward">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- details partial -->
  <!-- Angular no longer supports ng-include or a closely similar functionality-->
  <div *ngIf="showDrill" class="detailsPotentialDuplicates">
    <div class="breadcrumb layout-row">
      <span><a routerLink="/reports">Reports</a></span>
      <span>></span>
      <span><a (click)="goToReport()">Potential Duplicates</a></span>
      <span>></span>
      <span>
        <p>Potential Duplicates Details - {{drillDownData.staStuId}}</p>
      </span>
    </div>

    <div class="header">
      <h1 flex="90" class="header-padding">
        Potential Duplicates Details - {{drillDownData.staStuId}}
      </h1>
    </div>

    <hr layout-padding class="thickHr">
    <div class="details-section">
      <div class="report-summary drill-down-rs">
        <p>
          The data below displays the demographic information for a student record that the system has flagged as a
          potential duplicate.
          <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
    </div>
    <div class="details-section layout-row">
      <div flex class="details-subsection flex">
        <div class="details-subheader">
          <h2>Student Demographic information</h2>
        </div>
        <hr layout-padding class="thickHr">
        <div class="details-info-section layout-column">
          <div class="details-info layout-row">
            <p class="bold flex-50">First Name:</p>
            <p class="flex-50">{{drillDownData.stuFirstName}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">Last Name:</p>
            <p class="flex-50">{{drillDownData.stuLastName}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">State Student ID:</p>
            <p class="flex-50">{{drillDownData.staStuId}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">MSIX ID:</p>
            <p class="flex-50">
              <a (click)="goToStudentDetails(drillDownData.msixId)"
              [routerLink]="['/studentDetails']" 
              [queryParams]="{msixId: drillDownData.msixId, name: drillDownData.stuLastName + ', ' + drillDownData.stuFirstName}"
              target="_blank">
              {{drillDownData.msixId}}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div flex class="details-subsection flex">
        <div class="details-subheader">
          <h2 class="placeholder">Student Demographic information</h2>
        </div>
        <hr layout-padding class="thickHr">
        <div class="details-info-section layout-column">
          <div class="details-info layout-row">
            <p class="bold flex-50">State:</p>
            <p class="flex-50">{{drillDownData.state}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">Birth Date:</p>
            <p class="flex-50">{{drillDownData.stuBirthDate}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">Parent 1 Name:</p>
            <p class="flex-50">{{drillDownData.stuFPFirstName}} {{drillDownData.stuFPLastName}}</p>
          </div>
          <div class="details-info layout-row">
            <p class="bold flex-50">Parent 2 Name:</p>
            <p class="flex-50">{{drillDownData.stuMPFirstName}} {{drillDownData.stuMPLastName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>