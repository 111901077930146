import { Component, OnInit } from "@angular/core";
import { AppConstants }  from './../../config/index.constants'
import { AuthService } from "src/app/services/auth.service";
import { HelpPagesService } from "src/app/services/help-pages.service";
import { ResponsiveUIService } from "src/app/services/responsiveUI.service";

@Component({
  selector: 'training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  loggedIn: boolean = false;
  help_url: string = '';
  trainingCorner_url: string = '';

  constructor(private authService: AuthService,
    private _HelpPagesService: HelpPagesService,
    public responsiveUI: ResponsiveUIService) {

  }

  ngOnInit(): void {
    this.loggedIn = this.authService.isAuthenticated();
    this.help_url = AppConstants.help;
    this.trainingCorner_url = AppConstants.trainingCorner;
  }

  openPPTXFile(link) {
    return this._HelpPagesService.getPPTXFile(link);
  }

}

