import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-custom-assessments-type-control',
    templateUrl: './custom-assessments-type-control.component.html',
    styleUrls: ['./custom-assessments-type-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomAssessmentsTypeControlComponent
        }
]
})

export class CustomAssessmentsTypeControlComponent implements OnInit, ControlValueAccessor, OnDestroy{
    private _form: FormGroup = new FormGroup({});
    onTouched: () => {};
    onChangeSub: Subscription;
    
    get form() {
        return this._form;
    }

    assessmentTypes = [
        {label: "State Assessment", value: "1"}, 
        {label: "Advanced Placement Test", value: "2"}, 
        {label: "Language Proficiency Test", value: "3"}, 
        {label: "Exit Exam", value: "4"},
        {label: "GED", value: "5"},
        {label: "Special Education Assessment", value: "6"},
        {label: "Early Childhood Development Assessment", value: "7"},
        {label: "Other Achievement Test", value: "8"},
        {label: "State Assessment - Mathematics", value: "9"},
        {label: "State Assessment - Reading or Language Arts", value: "10"},
        {label: "State Assessment - English Language Proficiency", value: "11"},
    ]

    constructor() {
        
    }

    ngOnInit(): void {
      this.assessmentTypes.forEach(type => this.form.addControl(type.value, new FormControl(type.value)))
    }

    selectAll() {
        this.assessmentTypes.forEach(type => this.form.get(type.value).setValue(type.value))
      }
    
    selectNone() {
        this.assessmentTypes.forEach(type => this.form.get(type.value).reset())
    }

    writeValue(value: any): void {
        if (value) {
            this.form.setValue(value)
        }
    }

    registerOnChange(onChange: any): void {
        this.onChangeSub = this.form.valueChanges.subscribe(onChange)
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
       if (isDisabled) {
        this.form.disable();
       } else {
        this.form.enable();
       }
    }

    reset() {
        this.selectAll();
    }
    
    ngOnDestroy(): void {
        this.onChangeSub?.unsubscribe();
    }
}