<div class="dataLogic">
    <div class="breadcrumb layout-row" *ngIf="!showDrill">
      <span><a routerLink="/reports">Reports</a></span>
      <span>></span>
      <span><p>Data Logic Issues</p></span>
    </div>

    <div class="breadcrumb layout-row" *ngIf="showDrill">
      <span><a routerLink="/reports">Reports</a></span>
      <span>></span>
      <span><a (click)="goToReport()">Data Logic Issues</a></span>
      <span>></span>
      <span>
        <p [(ngModel)]="displayData" name="drillTitleBreadcrumb" ngDefaultControl>
          State: {{displayData.state}} // Logic Issue: {{displayData.recordName}}
        </p>
      </span>
    </div>

    <div class="header layout-row" *ngIf="!showDrill">
      <h1 class="header-padding flex-90">Data Logic Issues</h1>
      <export-report class="flex-10" tabindex="0"
        [exportlink]="exportCall" 
        [exportfilter]="filterParameters"
        [showpdf]="true" 
        [showcsv]="true" 
        [blankres]="blankRes">
      </export-report>
    </div>
    <div class="header layout-row" *ngIf="showDrill">
      <h1 class="flex-90 header-padding" [(ngModel)]="displayData" name="drillDownTitle" ngDefaultControl>
        State: {{displayData.state}} // Logic Issue: {{displayData.recordName}}
      </h1>
      <export-report class="flex-10" tabindex="0"
        [exportlink]="drilldownExportCall" 
        [exportcount]="drillDown.exportCount" 
        [exportfilter]="drillDown" 
        [showcsv]="true" >
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="layout-row layout-align-center-center"  *ngIf="showDrill">
      <div class="layout-column flex-90">
        <div class="report-summary drill-down-rs layout-row">
          <p>
            The table below lists the records containing data logic issues.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <no-results *ngIf="blankRes && showDrill"></no-results>
        <reports-table *ngIf="!blankRes && showDrill" 
          [responsedata]="drillDownData" 
          [shownavigation]="true" 
          [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
          (mycallback)="drillDownInit($event)"
          (drilldownsort)="handleDrillDownSortClick($event)"
          (handleMSIXIDClick)="handleMSIXIDClick($event)">
        </reports-table>
      </div>
    </div>

    <div class="layout-row layout-align-center-center" *ngIf="!showDrill">
      <div class="layout-column flex-90">
        <div class="report-summary layout-row">
          <p>
            This report displays the count of records for your State (default view) or at the national level that contain data elements
            that do not pass logic rules or are outside of an expected range.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response" 
          [currentsortcolumn]="currentSortColumn" 
          [sortascending]="sortAscending"
          (mycallback)="drillDownInit($event)"
          (sortbycolumn)="sortByColumn($event)">
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>
      </div>
    </div>
</div>