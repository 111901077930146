<div style="display: flex; flex-direction: row" class="reportsFilter learnMoreModal">
  <div aria-label="Child Count Learn More" class="md-dialog filter-dialog">
    <mat-toolbar style="display: flex; flex-direction: row; align-items: center;" class="md-toolbar-tools">
      <h2 class="filter-title">Learn More</h2>
      <span style="display: flex; flex: auto"></span>
      <button class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </mat-toolbar>

    <mat-dialog-content style="padding: 10px">
      <div class="md-dialog-content" style="padding: 12px">
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column; flex: auto">
            <p>Use this report to review potential duplicates of student records.<br />
              <br />
              For MSIX Potential Duplicates that should be merged, use the checkboxes to select one or more MSIX Student records that should be merged. After making your selection(s), select the Flag for Merge button to flag the selected MSIX Student
              records for merge. Then, you can initiate the merge from the Merge Record tab of your worklist. If MSIX Potential Duplicate records are for separate children, review the records in your State system to determine whether data needs to be
              corrected.<br />
              <br />
              To view a child's Consolidated Student Record, select the MSIX ID.<br />
              <br />
              Use the <b>Export</b> function to download a copy of the report
              in CSV format.
            </p>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>