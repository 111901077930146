<div class="childMobility">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
      margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

  <!--chart body for Mobilty Map-->
    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.dataAdmins">
        <b>Children Moving To and Children Moving From Maps</b><br />
        <br />
        These heat maps provide you with the count of migratory children that made moves that generated QADs
        to your State from your partner States in both the previous and current performance periods.
        The number within your State is the count of children that made intrastate moves during the performance period.
        Data in these charts is refreshed on the first day of every month.<br />
        <br />
        These charts provide the count of children moving not the count of moves.
        If a child made more than one move between your State and the same partner State in the performance period,
        then that child will only be counted once.
        If a child made moves to your State from more than one partner State in the performance period,
        that child will be counted once for each respective partner State.<br />
        <br />

        <b>QADs and Enrollments</b><br />
        <br />
        This table provides you with a monthly breakdown of the number of children enrolling in your State
        in the last 12 months and the number of those children that made a qualifying move that generated a QAD
        within the 12 months prior to the enrollment date.
        Use this table to gauge the mobility of your migratory population.
        Data in this chart is refreshed on the first day of every month.<br />
        <br />

        To determine if a child made a move that generated a QAD in the past year,
        this chart looks for a QAD in the 365 days prior to the enrollment date.
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>Children Moving To and Children Moving From Maps</b><br />
        <br />
        These heat maps provide you with the national count of migratory children that made interstate and intrastate moves
        that generated QADs in both the previous and current performance periods.
        Once filtered down to a selected State, the number within that State is the count of children
        that made intrastate moves in that State during the performance period.
        Data in these charts is refreshed on the first day of every month.<br />
        <br />
        These charts provide the count of children moving, not the count of moves.
        If a child made more than one move between a State and the same partner State in the performance period,
        then that child will only be counted once.
        If a child made moves to a State from more than one partner State in the performance period,
        that child will be counted once for each respective partner State.<br />
        <br />

        <b>QADs and Enrollments</b><br />
        <br />
        This table provides you with a monthly breakdown of the number of children enrolling in all States in the last 12 months
        and the number of those children that made a qualifying move that generated a QAD within the 12 months
        prior to the enrollment date. Use this table to gauge how mobile the migratory population is.
        Data in this chart is refreshed on the first day of every month.<br />
        <br />

        To determine if a child made a move that generated a QAD in the past year,
        this chart looks for a QAD in the 365 days prior to the enrollment date.
      </p>
    </div>
  </div>
</div>