<div class="dataManagement">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
        margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.dataAdmins">
        <b>Records Submission</b><br/>
        <br/>
        Use this chart to see the total volume of student records your State submitted in each of the last 12 
        months as well as how many of those records were rejected. You can also learn if the record was rejected 
        due to completeness or validity issues by exporting the data. Data in this chart is refreshed on the first 
        day of every month.<br/>
        <br/>
        <b>Withdrawals and Course History Submission</b><br/>
        <br/>
        Use this chart to monitor the completeness of key Course History data in MSIX. This chart tells you how 
        many of the children in Grades 9 – 12 who were withdrawn in each month of the selected performance period 
        were submitted with at least one course with the Academic Year (MDE #60) in the corresponding performance 
        period and either a Final Grade (MDE #66) or a Grade-to-Date (MDE #64). You can also use this chart to gauge 
        timeliness of your State’s submission to MSIX according to MSIX regulations, which require that updated Course 
        History information be submitted within 30 days of the end of the term (MSIX Regulation 200.85(b)(3)(ii)(A)).
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>Records Submission</b><br/>
        <br/>
        Use this chart to see the total volume of student records all States submitted in each of the last 12 
        months as well as how many of those records were rejected. You can also learn if the record was rejected 
        due to completeness or validity issues.<br/>
        <br/>
        <b>Withdrawals and Course History Submission</b><br/>
        <br/>
        Use this chart to monitor the completeness of key Course History data in MSIX. This chart tells you how 
        many of the children in Grades 9 – 12 nationally who were withdrawn in each month of the selected performance 
        period were submitted with at least one course with the Academic Year (MDE #60) in the corresponding 
        performance period and with either a Final Grade (MDE #66) or a Grade-to-Date (MDE #64). You can also use 
        this chart to gauge timeliness of each State’s submission to MSIX according to MSIX regulations which require 
        that updated Course History information be submitted within 30 days of the end of the term 
        (MSIX Regulation 200.85(b)(3)(ii)(A)).
      </p>
    </div>
  </div>
</div>