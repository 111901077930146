<div class="export-report" >
  <div layout="column">
      <div tabindex="0" mat-button [matMenuTriggerFor]="menu"
      (keyup.enter)="menuTrigger.toggleMenu()" #menuTrigger="matMenuTrigger" 
      class="exportButton layout-row">        
        <p><span class="small">Export</span></p>
        <i class="fa fa-caret-down color-green" aria-hidden="true"></i>
      </div>
      <mat-menu #menu="matMenu">
        <button class="layout-row" mat-menu-item (click)="export('CSV')" *ngIf="showcsv">
          <i class="fa fa-file-excel-o color-green" aria-hidden="true"></i>
          <span class='file-type small'>CSV</span>
        </button>
        <button class="layout-row" mat-menu-item (click)="export('PDF')" *ngIf="showpdf">
          <i class="fa fa-file-pdf-o color-green" aria-hidden="true"></i>
          <span class='file-type small'>PDF</span>
        </button>
        <button class="layout-row" mat-menu-item (click)="export('ZIP')" *ngIf="showzip">
          <i class="fa fa-file-zip-o color-green" aria-hidden="true"></i>
          <span class='file-type small'>ZIP</span>
        </button>
      </mat-menu>
  </div>
</div>
