import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentDetailsSummerService } from './child-details.service';
import { combineLatest, fromEvent, Observable, of, switchMap, tap, Subscription } from 'rxjs';
import { IChildDetails, IStudent } from './types';
import { AgeBirthThrough2Service } from '../reports/reconciliation/age-birth-through-2/ager-birth-through-2.service';
import { IDrillDownPayload } from '../reports/reconciliation/age-birth-through-2/age-birth-through-2';
import { MatButton } from '@angular/material/button';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-child-details',
    templateUrl: './child-details.component.html',
    styleUrls: ['./child-details.component.scss']

})
export class ChildDetailsComponent implements OnInit, OnDestroy {
    isExcluded: boolean;
    displayDate: string;
    msixId: string;
    idx: number;
    lastIdx: number;
    childDetails$: Observable<IChildDetails>;
    ids: string[] = [];
    lastUpdatedDefault: Date = new Date();
    hasMissingDateLogicIssue: boolean;
    residencyEstablishingDateOccursBeforeBirthDateDataLogic: boolean = false;
    qadOccursAfterResidencyEstablishingDateDataLog: boolean = false;
    EEDOccursBeforeResidencyDataLogic: boolean = false;
    enrollmentTypeIs07OrMissingDataLogic: boolean = false;
    graduationDateOccursBeforeResidencyEstablishingDateDataLogic: boolean = false;
    isSummer: boolean;
    drillDownParams: IDrillDownPayload;
    drillDownParams2: IDrillDownPayload;
    sub = new Subscription();

    // MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_2
    // RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_2
    // QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
    // EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
    // GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
    // ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_2

    constructor(private route: ActivatedRoute,
        private router: Router,
        private studentDetailsSummerService: StudentDetailsSummerService,
        private ageBirthThrough2Service: AgeBirthThrough2Service,
        private datePipe: DatePipe) {
        this.isExcluded = this.route.snapshot.queryParamMap.get('isExcluded') === 'true';
        this.displayDate = this.route.snapshot.queryParamMap.get('displayDate');
        this.isSummer = this.route.snapshot.queryParamMap.get('isSummer') === 'true';
        this.msixId = this.route.snapshot.queryParamMap.get('msixId');
        const detailsJson = decodeURIComponent(this.route.snapshot.queryParamMap.get('drillDownParams'));
        this.drillDownParams2 = JSON.parse(detailsJson);
        const params = this.route.snapshot.queryParams?.drillDownParams;
        this.drillDownParams = JSON.parse(decodeURIComponent(params));
        this.getIds();
        this.idx = this.ids.findIndex((id) => id === this.msixId);
    }

    ngOnInit(): void {
        this.childDetails$ = this.studentDetailsSummerService.getChildDetails(this.msixId).pipe(
            tap(res => {
                this.setLogicIssueVars(res);
            })
        );

        this.checkPageRecords();
    }

    // Reset logic issues
    resetLogicIssues() {
        this.hasMissingDateLogicIssue = false;
        this.residencyEstablishingDateOccursBeforeBirthDateDataLogic = false;
        this.qadOccursAfterResidencyEstablishingDateDataLog = false;
        this.EEDOccursBeforeResidencyDataLogic = false;
        this.enrollmentTypeIs07OrMissingDataLogic = false;
        this.graduationDateOccursBeforeResidencyEstablishingDateDataLogic = false;
    }

    setLogicIssueVars(res) {
        this.resetLogicIssues();
        
        if (res.failedLogicRules.includes('MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_1') || 
            res.failedLogicRules.includes('MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_2')) { 
                this.hasMissingDateLogicIssue = true;
        } else if (res.failedLogicRules.includes('RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_1') || 
        res.failedLogicRules.includes('RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_2')) {
            this.residencyEstablishingDateOccursBeforeBirthDateDataLogic = true;
        } else { 
            if (res.failedLogicRules.includes('QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2') || 
            res.failedLogicRules.includes('QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1')) {
                this.qadOccursAfterResidencyEstablishingDateDataLog = true;
            } 
            
            if (res.failedLogicRules.includes('EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1') || 
            res.failedLogicRules.includes('EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2')) {
                this.EEDOccursBeforeResidencyDataLogic = true;
            }
            
            if (res.failedLogicRules.includes('ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_2') || 
            res.failedLogicRules.includes('ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_1')) {
                this.enrollmentTypeIs07OrMissingDataLogic = true;
            }
            
            if (res.failedLogicRules.includes('GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2') || 
            res.failedLogicRules.includes('GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1')) {
                this.graduationDateOccursBeforeResidencyEstablishingDateDataLogic = true;
            }
        }
    }

    // TODO - 'merge' operator was not working as expected so need to look in to it.
    /* ngOnInit() {
        this.childDetails$ = merge([
            of('value'), 
            fromEvent(this.nextButton._elementRef.nativeElement, 'click'), 
            fromEvent(this.prevButton._elementRef.nativeElement, 'click')]).pipe(
                switchMap(() => {
                    return this.studentDetailsSummerService.getChildDetails(this.msixId).pipe(
                        tap(res => {
                            this.setLogicIssueVars(res);
                    )
                })
        )

        this.checkPageRecords();
    } */

    getIds() {
        this.ids = this.isExcluded ? localStorage.getItem('drillDownDataExcluded') ? JSON.parse(localStorage.getItem('drillDownDataExcluded')) : null 
        : localStorage.getItem('drillDownDataIncluded') ? JSON.parse(localStorage.getItem('drillDownDataIncluded')) : null;
    }

    // This function is called when 'Next' and 'Previous' records are fetched.
    getStudentInfo(step: number) {
        if (this.idx === 0 && step === -1) {
            return;
        }
        if (this.idx === this.ids.length - 1 && step === 1) {
            return;
        }
        this.idx += step;
        this.msixId = this.ids[this.idx];

        this.checkPageRecords();

        this.childDetails$ = this.studentDetailsSummerService.getChildDetails(this.msixId).pipe(
            tap(res => {
                this.setLogicIssueVars(res);
            })
        );
    }

    checkPageRecords() {
        if (this.msixId === this.ids.at(-2) || this.msixId === this.ids.at(-1)) {
            // Reset the drillDownParamsTab1 or drillDownParamsTab2 since a new tab was opened
            if (this.isExcluded) {
                this.ageBirthThrough2Service.drillDownParamsTab2 = this.drillDownParams;
            } else {
                this.ageBirthThrough2Service.drillDownParamsTab1 = this.drillDownParams;
            }

            // Get next page if available
            this.sub.add(this.ageBirthThrough2Service.getDrillDownDataForChildDetail(this.isExcluded).pipe(
                tap(() => this.getIds())
            ).subscribe());
        }
    }

    goToStudentDetails(msixId: string) {
        const url = `#/studentDetails?msixId=${msixId}&historical=true`
        window.open(url.toString(), '_blank');
    }


    ngOnDestroy() {
        this.sub.unsubscribe();
     }
}