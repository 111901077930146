<form [formGroup]="form">
    <label class="pFilterHeader">DRILL DOWN LEVEL:</label>
    <div class="drill-down-options">
        <mat-radio-group formControlName="rptGrping" (change)="onDrillDownLevelChange($event)">
            <mat-radio-button value="National">
                <div class="filterText">
                    National
                </div>
            </mat-radio-button>
            <mat-radio-button value="State">
                <div class="filterText">
                    {{user.userStateName}}
                </div>
            </mat-radio-button>
            <mat-radio-button value="Region">
                <div class="filterText">
                    {{user.userStateName}} <br />
                    Regions
                </div>
            </mat-radio-button>
            <mat-radio-button value="District">
                <div class="filterText">
                    {{user.userStateName}} <br />
                    Districts
                </div>
            </mat-radio-button>
            <mat-radio-button value="School">
                <div class="filterText">
                    {{user.userStateName}} <br />
                    Schools
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="rptGrping !== 'State' && rptGrping !== 'National'">
        <p>Use the search box below to enter the {{rptGrping}} you would like to add to the report. As you enter
            characters into the search field,
            a list of suggestions that match your input will be displayed.
            Select your desired {{rptGrping}} to add to the list of selected {{rptGrping}}.
            Add multiple selections or “Select All” to add all {{rptGrping}} in your State.</p>
        <br />
        <div class="layout-row selectButtons">
            <strong>{{rptGrping}} Search</strong> &nbsp; 
            <p style="margin-top: 2px !important">Select</p>
            <a style="margin-inline: 0.4rem;" (click)="onSelectAll()">All</a>
        </div>
        <ng-container *ngIf="filteredCollection$ | async as collection">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                <ng-container *ngFor="let item of collection; trackBy: trackByKey">
                    <mat-option [value]="item.description" *ngIf="!selected.has(item)">
                        {{item.description}}
                    </mat-option>
                </ng-container>
                
                
            </mat-autocomplete>
            <div class="auto-search-section">
                <input #searchTerm class="auto-complete" type="text" [formControl]="searchControl" matInput [matAutocomplete]="auto"> 
                <button *ngIf="searchTerm.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm.value = ''">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </ng-container>
        <mat-divider></mat-divider>
        <br>
        <p>The report output will only include data for the {{rptGrping}} displayed in the Selected {{rptGrping}} field
            below.
            Click on the ‘x’ to remove a selection. Click “Select None” to remove all selections.
            Check the Display Data By if you would like to see the data in your {{rptGrping}} organized by.</p>
        <br />
        <strong>Selected {{rptGrping}}s</strong> &nbsp; <span>Select <span class="highlighted"
                (click)="onSelectNone()">None</span></span>
        <section class="selected-area">
            <mat-chip-listbox class="selected-items">
                <mat-basic-chip *ngFor="let item of selected">
                    <button matChipRemove (click)="onSelectionRemove(item)">
                        <mat-icon>clear</mat-icon>
                    </button>
                    {{item.description}}
                </mat-basic-chip>
            </mat-chip-listbox>
            <mat-checkbox *ngIf="rptGrping === 'Region'" formControlName="dataByDist">
                Display Data by District
            </mat-checkbox>
            <mat-checkbox *ngIf="rptGrping === 'District'" formControlName="dataBySchool">Display Data by School
            </mat-checkbox>
        </section>
    </div>
</form>