<div class="dataCompleteness">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
      <span><a routerLink="/reports">Reports</a></span>
      <span class="font-16">></span>
      <span><p>Data Completeness</p></span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
      <span><a routerLink="/reports">Reports</a></span>
      <span class="font-16">></span>
      <span><a (click)="goToReport()">Data Completeness</a></span>
      <span class="font-16">></span>
      <span>
        <p [(ngModel)]="displayData" name="displayDataDD" ngDefaultControl>
        State: {{displayData.state}} // Incomplete Data Element: {{displayData.recordName}}
        </p>
      </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 class="flex-90 header-padding">Data Completeness</h1>
    <export-report class="flex-10"  tabindex="0"
      [exportlink]="exportCall" 
      [exportfilter]="filterParameters"
      [showpdf]="true" 
      [showcsv]="true" 
      [blankres]="blankRes">
    </export-report>
  </div>
  <div class="header layout-row" *ngIf="showDrill">
    <h1 [(ngModel)]="displayData" name="displayDataField" ngDefaultControl class="flex-90 header-padding">
      State: {{displayData.state}} // Incomplete Data Element: {{displayData.recordName}}
    </h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="exportCall" 
      [exportcount]="drillDown.exportCount" 
      [exportfilter]="exportParams"
      [showpdf]="false" 
      [showcsv]="true" 
      [blankres]="blankRes">
    </export-report>
  </div>

  <hr layout-padding class="thickHr">

  <div class="layout-row layout-align-center-center" *ngIf="showDrill">
    <div class="layout-column flex-90">
    <div class="report-summary drill-down-rs layout-row">
      <p>
        The table below lists the records that are missing the selected data element.
        <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
      </p>
    </div>
    <no-results *ngIf="blankRes && showDrill"></no-results>
    <reports-table *ngIf="!blankRes && showDrill"
      [responsedata]="drillDownData"
      [shownavigation]="true"
      [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
      (mycallback)="drillDownInit($event)"
      (drilldownsort)="handleDrillDownSortClick($event)">
    </reports-table>
    </div>
  </div>

  <div class="layout-row layout-align-center-center" *ngIf="!showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p>
            This report displays the counts and percentages of missing Minimum Data Elements (MDEs) in MSIX for your State (default view) or at the national level. 
        <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <filter-button (activateFilter)="filterActivate()"></filter-button>
      <reports-table *ngIf="!blankRes" 
        [responsedata]="response" 
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (mycallback)="drillDownInit($event)"
        (sortbycolumn)="sortByColumn($event)" >
      </reports-table>
      <no-results *ngIf="blankRes"></no-results>
    </div>
  </div>
</div>
