/**
 * This pipe can be applied to an array to sort either in ascending or descending order.
 * It can handle 1 dimensional array and object array with property specified.
 * 
 * Example usage: 
 *      1) One dimensional array:
 *          *ngFor (let year in years | sortBy:'asc')           // One dimensional array that contains list of years.
 *      2) Object array:
 *          *ngFor (let car of cars | sortBy:'asc':'modelName')     // Assuming 'modelName' is a property on the car object
 * 
 */
import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from "lodash";

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
    /* transform(value: any, ...args: any[]) {
        throw new Error("Method not implemented.");
    } */

    transform(value: object[], order = '', column: string = ''): any[] {
        // If array or order is not specified, then return the 
        if (!value || order === '' || !order) {
            return value;
        }

        // If order is not 'asc' / 'desc', then throw exception
        if (order !== 'asc' && order !== 'desc') {
            throw new Error("Incorrect sort order specified. It can be either 'asc' or 'desc' only.");
        }

        // If there is only one item in an array, then no need to perform sorting.
        if (value.length <= 1) {
            return value;
        }

        // If column is not specified, then it is a one dimeansional array.
        if (!column || column === '') {
            if(order==='asc') {
                return value.sort()
            } else {
                return value.sort().reverse();
            }
        }

        // If not a 1 dimensional array, then utilize lodash library's orderBy function to sort the array.
        return orderBy(value, [column], [order]);
    }
    
}