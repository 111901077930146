<div class="password-update-page background-awesome"layout-align="center start">
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
  'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title">
      <h1>Password Reset</h1>
    </div>

    <div class="errors flex-inline" *ngIf="errors.length > 0">
      <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
      <p class="validation">{{errors}}</p>
    </div>

    <div class="validations">
      <h3>Password must:</h3>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || (formData.newPassword.length < 12) || (formData.newPassword.length > 20)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="(formData.newPassword.length > 11) && (formData.newPassword.length < 21)"></i>
        <p flex=95 class="small">Be 12 to 20 characters long</p>
      </div>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || !oneUpperCharRegex.test(formData.newPassword)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="formData.newPassword && oneUpperCharRegex.test(formData.newPassword)"></i>
        <p flex=95 class="small">Have at least one capital letter</p>
      </div>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || !oneLowerCharRegex.test(formData.newPassword)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="formData.newPassword && oneLowerCharRegex.test(formData.newPassword)"></i>
        <p flex=95 class="small">Have at least one lowercase letter</p>
      </div>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || !oneNumberRegex.test(formData.newPassword)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="formData.newPassword && oneNumberRegex.test(formData.newPassword)"></i>
        <p flex=95 class="small">Have at least one number</p>
      </div>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || !oneSpecCharRegex.test(formData.newPassword)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="formData.newPassword && oneSpecCharRegex.test(formData.newPassword)"></i>
        <p flex=95 class="small">Have at least one special character</p>
      </div>
      <div layout=row class="layout-row paddingValidationItems">
        <i flex=5 class="fa fa-circle-o" aria-hidden="true" *ngIf="!formData.newPassword || !formData.newPassword || (formData.newPassword !== formData.confirmPassword)"></i>
        <i flex=5 class="fa fa-check-circle" aria-hidden="true" *ngIf="(formData.newPassword === formData.confirmPassword) && formData.newPassword"></i>
        <p flex=95 class="small">The two passwords must match</p>
      </div>
    </div>

    <div style="margin: -5px 5px">
      <form name="passResetForm" (ngSubmit)="submitForm()">
        <custom-mat-input 
          customlabel="New Password"
          errorlength="{{errors.length}}"
          textType="password"
          [(ngModel)]="formData.newPassword"
          name="newPassword"
          >
        </custom-mat-input>
        <div>
          <custom-mat-input 
            customlabel="Confirm Password"
            errorlength="{{errors.length}}"
            textType="password"
            [(ngModel)]="formData.confirmPassword"
            name="confirmPassword"
            >
          </custom-mat-input>
        </div>

        <div class="center" style="padding-top: 15px;">
          <button class="md-button" type="submit" [disabled]="!((formData.newPassword === formData.confirmPassword) && oneSpecCharRegex.test(formData.newPassword) && oneNumberRegex.test(formData.newPassword) && oneUpperCharRegex.test(formData.newPassword) && oneLowerCharRegex.test(formData.newPassword) && (formData.newPassword.length > 11) && (formData.newPassword.length < 21))">Submit</button>
        </div>

      </form>
      <p class="small bottom-text">A password cannot be saved that is the same as one of the previous 24 passwords.</p>
    </div>

  </div>
</div>
