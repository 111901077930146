import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDashboardPayload, IDrillDownPayload, MSSMDashboard } from './age-birth-through-2';
import { AppConstants } from "src/app/config/index.constants";
import { Observable, of, tap } from "rxjs";
import { ITableItemDataSource } from '../../types/interfaces';
// import { drillDownData, dashBoardData } from './tmp';
import { ActivatedRoute } from "@angular/router";


@Injectable({providedIn: 'root'})
export class AgeBirthThrough2Service {
    private dashboardData: MSSMDashboard;
    private apiUrl = AppConstants.api;
    private _displayDate: string;
    private _dashBoardParams: IDashboardPayload
    dashBoardFilter: {
        periodType: string,
        rptGrping: "State" | "National",
        states: string
    };

    private _drillDownParamsTab1: IDrillDownPayload = {
        isExcluded: false,
        category: '',
        page: 1,
        exportType: 'CSV',
        enableGraduationDates: true,
        sortAscending: true,
        periodType: '',
        selectedStates: [],
        rptGrping: '',
        firstName: '',
        lastName1: '',
        identifier: '',
        identifierType: '',
    }

    private _drillDownParamsTab2: IDrillDownPayload = {
        isExcluded: true,
        category: '',
        page: 1,
        exportType: 'CSV',
        enableGraduationDates: true,
        sortAscending: true,
        periodType: '',
        selectedStates: [],
        rptGrping: '',
        firstName: '',
        lastName1: '',
        identifier: '',
        identifierType: '',
    }
    
    drillDownDialogFormValues: {
        firstName: string,
        lastName1: string,
        identifier:string,
        identifierType: string
    };

    set drillDownParamsTab1(val: IDrillDownPayload) {
        this._drillDownParamsTab1 = val;
    } 

    get drillDownParamsTab1(): IDrillDownPayload {
        return this._drillDownParamsTab1;
    }
    set drillDownParamsTab2(val: IDrillDownPayload) {
        this._drillDownParamsTab2 = val;
    } 

    get drillDownParamsTab2(): IDrillDownPayload {
        return this._drillDownParamsTab2;
    }

    get dashBoardParams(): IDashboardPayload {
        return this._dashBoardParams;
    }

    set dashBoardParams(val: IDashboardPayload) {
        this._dashBoardParams = val
    }

    get displayDate(): string {
        return this._displayDate;
    }

    set displayDate(val: string) {
        this._displayDate = val;
    }

    constructor(private http: HttpClient, private route: ActivatedRoute) {

    }

    resetDrillDownParamsTab1() {
        const selectedStates = this.drillDownParamsTab1.selectedStates;
        const periodType = this.drillDownParamsTab1.periodType;
        const rptGrping = this.drillDownParamsTab1.rptGrping;
        this.drillDownParamsTab1 = {
            isExcluded: false,
            category:'',
            page: 1,
            exportType: 'CSV',
            enableGraduationDates: true,
            sortAscending: true,
            periodType,
            selectedStates,
            rptGrping,
            firstName: '',
            lastName1: '',
            identifier: '',
            identifierType: 'msix',
        }
        
    }

    resetDrillDownParamsTab2() {
        const periodType = this.drillDownParamsTab2.periodType;
        const rptGrping = this.drillDownParamsTab2.rptGrping;
        const selectedStates = this.drillDownParamsTab2.selectedStates;
        this.drillDownParamsTab2 = {
            isExcluded: true,
            category:'',
            page: 1,
            exportType: 'CSV',
            enableGraduationDates: true,
            sortAscending: true,
            periodType,
            selectedStates,
            rptGrping,
            firstName: '',
            lastName1: '',
            identifier: '',
            identifierType: 'msix',
        }
    }

 
    getDashboardData():Observable<MSSMDashboard>;
    getDashboardData(params: IDashboardPayload): Observable<MSSMDashboard>;

    getDashboardData(params?: IDashboardPayload): Observable<MSSMDashboard> {
        // return of(dashBoardData)
       if (!this.dashboardData || params) {
        if (!params) {
            params = this.dashBoardParams;
        }
        return this.http.post<MSSMDashboard>(this.apiUrl + 'reconciliation/birthThroughTwoDashboard', params).pipe(
            tap(dashboardData => this.dashboardData = dashboardData));
        }

       else if (!params) {
            return of(this.dashboardData);
        }
        
       
    }

    // This function is called from the reports table Next/Previous page navigation
    getDrillDownData(isExcluded: boolean) {
        return this.getDrillDownDataPrimary(isExcluded);
    }

    // This function is called from the child details Next/Previous record navigation
    getDrillDownDataForChildDetail(isExcluded: boolean) {
        return this.getDrillDownDataPrimary(isExcluded, true);
    }

    // This is the primary function to make the API call for getting page data.
    getDrillDownDataPrimary(isExcluded: boolean, forChildDetail: boolean = false): Observable<ITableItemDataSource> {
        const params = isExcluded ? this.drillDownParamsTab2 : this.drillDownParamsTab1;

        if (forChildDetail) {
            params.page += 1;
        }

        return this.http.post<ITableItemDataSource>(this.apiUrl + 'reconciliation/birthThroughTwoDrilldown', params).pipe(
            tap(data => {
                const key = isExcluded ? 'drillDownDataExcluded' : 'drillDownDataIncluded';

                const currentIdsLength = JSON.parse(localStorage.getItem(key))?.length ?? 0;
                const expectedStoredRecords = Math.min(params.page * 100, data?.totalAvailableRows);

                if (currentIdsLength < expectedStoredRecords) {
                    const currentStorage = localStorage.getItem(key) ?? "[]";
                    const currentStoredArray: string[] = JSON.parse(currentStorage);

                    const updatedStoredArray = currentStoredArray.concat(data.rows.map(r => r.originalResult.id));

                    localStorage.setItem(key, JSON.stringify(updatedStoredArray));
                }
            })
        );
    }

    getAgeBirthThrough2Export(params: IDrillDownPayload): Observable<HttpResponse<string>> {
        return this.http.post(this.apiUrl + 'reconciliation/birthThroughTwoDrilldown/export', params, {observe: 'response', responseType: 'blob' as 'text'});
      }
 }