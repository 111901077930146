<div class="reportsFilter corDialog-page" [formGroup]="CORForm" style="overflow: hidden">

  <div>
    <div aria-label="Move Notice" class="corDialog">
      <div>
        <div hide show-gt-md>
          <div class="toolbar md-toolbar-tools">
            <h2 *ngIf="filterParameters.corType == 'moveNotice' && filterParameters.mainPage == 'studentDetails'">Move
              Notice</h2>
            <h2 *ngIf="filterParameters.corType == 'moveNotice' && filterParameters.mainPage == 'savedList'">Multi-Move
              Notice</h2>
            <h2 *ngIf="filterParameters.corType == 'requestData'">Data Request</h2>
            <span style="flex: 1; box-sizing: border-box;"></span>
            <button tabindex="0" class="md-icon-button" (click)="closeDialog()" (keypress)="closeDialog()" aria-label="close dialog">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div style="padding: 0px 20px; height: 300px; overflow-y: scroll;">
          <div class="md-dialog-content">

            <div class="mainDiv">
              <div class="bottom-border-green padding-bottom-20" *ngIf="!responsiveUI.isDesktop && filterParameters.mainPage == 'studentDetails'">
                <div class="mainHeader">
                  <div *ngIf="filterParameters.corType == 'requestData'" class="block2 uppercase">Send Data Request
                  </div>
                  <div *ngIf="filterParameters.corType == 'moveNotice'" class="block2 uppercase">Move Notice</div>
                  <div class="flexSpaceBetween">
                    <h2 class="camelcase">{{filterParameters.studentName}}</h2>
                    <div class="mainHeaderRight">
                    </div>
                  </div>
                </div>
                <div>
                  <p flex>MSIX: {{filterParameters.msixId}} | State: {{filterParameters.student.statePostalCode}} </p>
                  <p flex>State ID: {{filterParameters.student.stateStudentId}} | Gender:
                    {{filterParameters.student.gender}}</p>
                </div>
              </div>

              <div class="md-dialog-content-body layout-column">
                <div name="pwResetForm">

                  <div class="dialog-margin-top-bottom-20 dialog-margin-bottom">
                    <div *ngIf="filterParameters.corType == 'requestData'" class="top-bottom-margin-10">
                      <h2 class="top-bottom-padding-2" *ngIf="!responsiveUI.isDesktop">Data Request</h2>
                      <p class="top-bottom-padding-2">To initiate a data request, please supply comments and assign a
                        recipient.
                      </p>
                    </div>

                    <!--move notice from student detail page-->
                    <div
                      *ngIf="filterParameters.corType == 'moveNotice' && filterParameters.mainPage == 'studentDetails'"
                      class="top-bottom-margin-10">
                      <h2 class="top-bottom-padding-2" *ngIf="!responsiveUI.isDesktop">Move Notice</h2>
                      <div class="custom-input-container top-bottom-padding-2">
                        <mat-form-field class="md-block move-notice-dropdown COR-selected moveNotice"
                          style="width: 98%; height: 38px; padding-bottom: 12px; padding-top: 0px">
                          <mat-select [(value)]="filterParameters.moveNotice" style="padding-left: 10px"
                            aria-label="Select if the user is moving from your area or to your area">
                            <mat-option [value]="true">
                              This student is moving <b>from our area to your area</b>
                            </mat-option>
                            <mat-option [value]="false">
                              Your student has recently <b>moved to our area</b>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <!--multi-move notice from saved list page-->
                    <div *ngIf="filterParameters.corType == 'moveNotice' && filterParameters.mainPage == 'savedList'"
                      class="top-bottom-margin-10">
                      <!--<h2 class="top-bottom-padding-2" *ngIf="!responsiveUI.isDesktop">Multi-Move Notice</h2>-->
                      <div class="custom-input-container top-bottom-padding-2">
                        <mat-form-field class="md-block move-notice-dropdown" style="border: 1px solid gray; width: 98%; height: 38px; padding-bottom: 12px; padding-top: 0px">
                          <mat-select [(value)]="filterParameters.moveNotice" name="move"
                            aria-label="Select if the user is moving from your area or to your area">
                            <mat-option [value]="true">
                              These students are moving <b>from our area to your area</b>
                            </mat-option>
                            <mat-option [value]="false">
                              These students have recently <b>moved to our area</b>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Comments text area -->
                    <div class="custom-input-container">
                      <mat-form-field style="height: 130px; width: 100%;" class="example-full-width comments">
                        <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || textValue}"
                          for="inputField">Comments</mat-label>
                        <textarea matInput #inputField="matInput" style="height: 100px; border: 1px solid #888;"
                          class="comment-text" [ngClass]="{'mat-input-has-value': textValue}" [(ngModel)]="textValue"
                          name="inputField" maxlength="250" [ngModelOptions]="{standalone: true}"></textarea>
                        <div class="md-errors-spacer">
                          <div class="md-char-counter">{{textValue.length}} / 250</div>
                        </div>
                      </mat-form-field>
                    </div>

                    <div *ngIf="filterParameters.corType == 'requestData'" flex class="commentbox-instructions">
                      <p class="small worklist-commentbox-instructions">
                        To protect the student's privacy, do not include any sensitive information such as Social
                        Security Numbers or other Personally Identifiable Information in Comments fields. Comments
                        entered will be shared unedited and in their entirety with other
                        MSIX Users.
                      </p>
                    </div>

                  </div>

                  <div class="margin-bottom-20">
                    <div *ngIf="filterParameters.corType == 'requestData'" class="top-bottom-margin-10">
                      <h2 class="top-bottom-padding-2">Recipient</h2>
                      <p class="top-bottom-padding-2">
                        Select the State, District and School to ensure the request for data is sent to the appropriate
                        Data Administrator.
                      </p>
                    </div>
                    <div *ngIf="filterParameters.corType == 'moveNotice'" class="top-bottom-margin-10">
                      <h2 class="top-bottom-padding-2">Recipient</h2>
                      <p class="top-bottom-padding-2">
                        Select the State, District and School to ensure the move notification is sent to the appropriate
                        Data Administrator.
                      </p>
                    </div>

                    <div class="custom-input-container state-select-box corDialog-recipient" flex="25">
                      <mat-form-field style="border: 1px solid; height: 36px; width: 98%; padding-top: 8px"
                        [ngClass]="{'COR-selected': filterParameters.corObject.state}">
                        <mat-label class="custom-label" style="margin-top: -5px; margin-left: 15px;"
                          for="stateKeyInput">
                          State
                        </mat-label>
                        <mat-select disableOptionCentering style="padding-left: 10px; padding-top: 10px"
                          name="stateKeyInput" [(value)]="filterParameters.selectedState.stateIndex"
                          (selectionChange)="getDistricts()">
                          <mat-option *ngFor="let state of filterParameters.states; let stateIndex = index"
                            [value]="stateIndex">
                            {{state.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="custom-input-container state-select-box corDialog-recipient" flex="25"
                      *ngIf="filterParameters.corObject.state">
                      <mat-form-field
                        style="border: 1px solid; height: 36px; width: 98%; margin-top: 5px; padding-top: 8px"
                        [ngClass]="{'COR-selected': filterParameters.corObject.district}">
                        <mat-label class="custom-label" style="margin-top: -5px; margin-left: 12px;"
                          for="districtInput">
                          District
                        </mat-label>
                        <mat-select disableOptionCentering style="padding-left: 10px; padding-top: 10px"
                          name="districtInput" [(value)]="filterParameters.selectedDistrict.districtIndex"
                          (selectionChange)="getSchoolsList()">
                          <mat-option
                            *ngFor="let district of filterParameters.districts | sortBy: 'asc': 'district.name'; let districtIndex = index"
                            [value]="districtIndex">
                            {{district.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="custom-input-container state-select-box corDialog-recipient" flex="25"
                      *ngIf="filterParameters.corObject.district">
                      <mat-form-field
                        style="border: 1px solid; height: 40px; width: 98%; margin-top: 5px; padding-top: 8px"
                        [ngClass]="{'COR-selected': schoolSelected}">
                        <mat-label class="custom-label" style="margin-top: -5px; margin-left: 15px;"
                          for="stateKeyInput">
                          School
                        </mat-label>
                        <mat-select disableOptionCentering style="padding-left: 10px; padding-top: 10px"
                          name="stateKeyInput" [(value)]="filterParameters.selectedSchool.schoolIndex"
                          (selectionChange)="enableSubmit()">
                          <mat-option
                            *ngFor="let school of filterParameters.schools | sortBy:'asc':'name'; let schoolIndex = index"
                            [value]="schoolIndex">
                            {{school.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div layout-gt-md="row" class="center margin-top-bottom-20" *ngIf="!responsiveUI.isDesktop">
                      <button *ngIf="filterParameters.corType == 'requestData'" class="md-button"
                        type="Submit" [disabled]="submitDisabled" (click)="onSubmit()" (keypress)="onSubmit()">Submit Request
                    </button>
                      <button *ngIf="filterParameters.corType == 'moveNotice'" class="md-button"
                        type="Submit" [disabled]="submitDisabled" (click)="onSubmit()" (keypress)="onSubmit()">Send Move Notification
                    </button>
                      <a tabindex="0" (click)="closeDialog()" (keypress)="closeDialog()">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="padding: 15px 30px; align-items: center;" class="layout-row" *ngIf="responsiveUI.isDesktop">
          <hr class="thinHr">
          <a tabindex="0" (click)="closeDialog()" (keypress)="closeDialog()"><span>Cancel</span></a>
          <span style="flex: 1; box-sizing: border-box;"></span>
          <button type="submit" (click)="onSubmit()" (keypress)="onSubmit()" class="md-button confirmButton" [disabled]="submitDisabled">
            SUBMIT
          </button>
        </div>

      </div>
    </div>
  </div>