<div layout="row" class="reportsFilter learnMoreModal">
  <div aria-label="Account List DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="flex-90">Learn more</h2>
      <span flex></span>
      <button class="md-icon-button md-button flex-10" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-column" flex>
        <p>Use this report to validate accuracy of demographic information or to review user account activities for periodic
          accounts review.
        </p>
        <div class="add-margin-top">
          <p>Click on the <b>User ID</b> to update the assigned role(s) or other information for the account. </p>
        </div>
        <div class="add-margin-top">
          <p>Use the breadcrumbs at the top of the page to return to the main Account List Report.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>