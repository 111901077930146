import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ProgressService } from '../services/progress.service';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    constructor(private progressService: ProgressService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.activeRequests === 0) {
            this.progressService.turnOnProgress();
        }
        this.activeRequests++;

        return next.handle(req).pipe(
            finalize(() =>{
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    this.progressService.turnOffProgress()}
                }
            )
        )
    }
}