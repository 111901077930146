<div class="accountList">
  <div class="breadcrumb" *ngIf="!showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><p>Account List</p></span>
  </div>

  <div *ngIf="!showDrill" class="layout-column">
    <div class="header layout-row">
      <h1 class="header-padding flex-90">Account List</h1>
      <export-report class="flex-10" tabindex="0"
        [exportlink]="exportCall" 
        [exportfilter]="filterParameters"
        [showpdf]="true" 
        [showcsv]="true" 
        [blankres]="blankRes">
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="reportBody">
      <div class="layout-column flex-90">
        <div class="report-summary layout-row">
          <p>
              This report lists all active, disabled, deactivated, locked, and expired MSIX user accounts in your State.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <filter-button (activateFilter)="filterActivate()"></filter-button>

        <!--New implementation-->
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response"
          [isreportlevelspecialsortinit]="isInit"
          [hasreportlevelspecialsort]="true"
          [currentsortcolumn]="currentSortColumn"
          [sortascending]="sortAscending"
          (mycallback)="drillDownInit($event)"
          (reportlevelspecialsort)="handleReportSpecialSortClick($event)"
          (sortbycolumn)="sortByColumn($event)">
        </reports-table>

        <no-results *ngIf="blankRes"></no-results>
      </div>
    </div>
  </div>

  <!-- details partial -->
  <div *ngIf="showDrill">
    <div class="detailsAccountList layout-column">
      <div class="breadcrumb">
        <div flex class="layout-row">
          <span><a routerLink="/reports">Reports</a></span>
          <span>></span>
          <span><a (click)="goToReport()">Account List</a></span>
          <span>></span>
          <span><p>User Account Details</p></span>
        </div>
      </div>
    
      <div class="header layout-row">
        <h1 flex="90" class="header-padding">User Account Details</h1>
      </div>
    
      <hr layout-padding class="thickHr">
    
      <div class="reportBody layout-align-center-center">
        <div class="layout-column flex-90">
          <div class="report-summary layout-row">
            <p>
              The data below displays the account information associated with the selected user. 
            </p>
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
            <div class="navigation-panel">
              <span class="button-wrapper" (click)="!disablePrev && navigateRecords('prev')">
                <i class="fa fa-chevron-left" aria-hidden="true" [ngClass]="{'disabled-grey' : disablePrev}"></i>
                <span class="navigation-text nav-prev" [ngClass]="{'disabled-grey' : disablePrev}">
                  Previous
                </span>
              </span>
              <span class="button-wrapper" (click)="!disableNext && navigateRecords('next')">
                <span class="navigation-text nav-next" [ngClass]="{'disabled-grey' : disableNext}">
                  Next
                </span><i class="fa fa-chevron-right" [ngClass]="{'disabled-grey' : disableNext}" aria-hidden="true"></i>
              </span>
            </div>
            
          </div>
        </div>
      </div>
    
      <div class="layout-row details-section">
        <div flex class="details-subsection">
          <div class="details-subheader">
            <h2>Selected User</h2>
          </div>
          <hr flex layout-padding class="thickHr">
          <div class="layout-column details-info-section">
            <div class="layout-row details-info">
              <p class="bold details-info-key-value">User ID:</p>
              <p class="details-info-key-value"><a (click)="goToUserDetails(drillDownDetails.userId)">{{drillDownDetails.userId}}</a></p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Role:</p>
              <p class="flex-50">{{drillDownDetails.multipleRoles}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Status:</p>
              <p class="flex-50">{{drillDownDetails.status}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Accepted ROB:</p>
              <p class="flex-50">{{drillDownDetails.rulesOfBehavior}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Accepted ROB Date:</p>
              <p class="flex-50">{{drillDownDetails.rulesOfBehaviorDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Completed Security Training:</p>
              <p class="flex-50">{{drillDownDetails.trngCmpltd}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Completed Security Training Date:</p>
              <p class="flex-50">{{drillDownDetails.trngCmpltdDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Creation Date:</p>
              <p class="flex-50">{{drillDownDetails.createDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Activation Date:</p>
              <p class="flex-50">{{drillDownDetails.activationDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Expiration Date:</p>
              <p class="flex-50">{{drillDownDetails.expirationDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">First Login Date:</p>
              <p class="flex-50">{{drillDownDetails.firstLoginDate}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Last Login Date:</p>
              <p class="flex-50">{{drillDownDetails.lastLoginDate}}</p>
            </div>
          </div>
          <div class="details-subheader">
            <h2>Demographic Information</h2>
          </div>
          <hr flex layout-padding class="thickHr">
          <div class="layout-column details-info-section">
            <div class="layout-row details-info">
              <p class="bold flex-50">First Name:</p>
              <p class="flex-50">{{drillDownDetails.firstName}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Last Name:</p>
              <p class="flex-50">{{drillDownDetails.lastName}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Address:</p>
              <p class="flex-50">{{drillDownDetails.addrLine1}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">City:</p>
              <p class="flex-50">{{drillDownDetails.addrCity}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">State:</p>
              <p class="flex-50">{{drillDownDetails.addrSta}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Zip Code:</p>
              <p class="flex-50">{{drillDownDetails.addrZipCde}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Email:</p>
              <p class="flex-50">{{drillDownDetails.emailAddr}}</p>
            </div>
            <div class="layout-row details-info">
              <p class="bold flex-50">Telephone:</p>
              <p class="flex-50">{{drillDownDetails.tlphn}}</p>
            </div>
          </div>
        </div>
    
        <div flex class="details-subsection">
          <div class="details-subheader">
            <h2>District Membership</h2>
          </div>
          <hr flex layout-padding class="thickHr">
          <div class="details-info-section scroll-active">
            <div class="layout-row" *ngFor="let district of assignedDistricts; trackBy: index">
              <p flex>{{district}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="loginDetailsReport">
        <div class="layout-row loginHeader" (click)="toggleHistory()">
          <div class="details-subheader">
            <h2>Login Details</h2>
          </div>
          <div>
            <div><i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showHistory"></i></div>
            <div><i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showHistory"></i></div>
            <div class="hideDiv">Test</div>
          </div>
        </div>
        <hr flex *ngIf="showHistory" layout-padding class="thickHr">
        <div class="layout-row loginBody" *ngIf="showHistory">
          <reports-table 
            [currentsortcolumn]="'column1'"
            [responsedata]="drillDownData">
          </reports-table>
          <export-report class="flex-10" tabindex="0"
            [exportlink]="loginExportCall" 
            [exportcount]="exportCount" 
            [exportfilter]="drillDown"
            [showpdf]="false" 
            [showcsv]="true">
          </export-report>
        </div>
      </div>
    </div>
    
  </div>
</div>
