import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Toast } from "ngx-toastr";
import { ResponsiveUIService } from "src/app/services/responsiveUI.service";
import { ToastService } from "src/app/services/toast.service";
import { WorklistService } from "src/app/services/worklist.service";

@Component({
  selector: 'worklist_reassign',
  templateUrl: './worklistReassign.component.html',
  styleUrls: ['./worklistReassign.component.scss']
})
export class WorklistReassignComponent implements OnInit {

  dataAdminObj: any;
  worklistId: any;
  parentWorklistId: any;
  isCOR: any;
  stateDataAdmins: any = [];
  regionalDataAdmins: any;
  districtDataAdmins: any;
  currentAdmins: any;
  dataAdmins: any = {
    currentAdmins: [{
      userState: ""
    }]
  };
  currentAdminUKey: any;
  checkedUsers: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {
  }

  ngOnInit(): void {
    this.dataAdminObj = {
      comments: '',
      currentAdmins: [],
      districtDataAdmins: [],
      newAdmins: [],
      parentWorklistId: '',
      regionalDataAdmins: [],
      stateDataAdmins: []
    };

    this.assignStateParams();
    this.getAdmins();
  }

  assignStateParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.isCOR = params['isCOR'];
      this.worklistId = params['worklistId'];
      this.parentWorklistId = params['parentWorklistId'];
    });
  }

  getAdmins() {
    var worklistId = this.worklistId;
    var isCOR = this.isCOR;

    let apiCall = this._Worklist.getWorklistAdminList(worklistId, isCOR);
    let subscribed = apiCall.subscribe({
      next: dt => {
        let data: any = dt;
        console.debug(data);
        this.stateDataAdmins = data.stateDataAdmins;
        this.regionalDataAdmins = data.regionalDataAdmins;
        this.districtDataAdmins = data.districtDataAdmins;
        this.currentAdmins = data.currentAdmins;
        this.dataAdmins = data;
        for (var i = 0; i < this.currentAdmins.length; i++) {
          if (this.currentAdmins[i]) {
            this.currentAdminUKey = this.currentAdmins[i].userKey;
            for (var j in this.stateDataAdmins) {
              for (var k in this.stateDataAdmins[j]) {
                var stateAdminName = this.stateDataAdmins[j];
                var stateAdminUKey = stateAdminName[k].userKey;
                if (stateAdminUKey == this.currentAdminUKey) {
                  stateAdminName[k].checked = true;
                  this.dataAdminObj.newAdmins.push(stateAdminName[k]);
                }
              }
            }
            for (var p in this.regionalDataAdmins) {
              this.regionalDataAdmins[p].sort((a, b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : a.firstName.toLowerCase() === b.firstName.toLowerCase() ? 0 : -1)
              for (var q in this.regionalDataAdmins[p]) {
                var regionalAdminName = this.regionalDataAdmins[p];
                var regionalAdminUKey = regionalAdminName[q].userKey;
                if (regionalAdminUKey == this.currentAdminUKey) {
                  regionalAdminName[q].checked = true;
                  this.dataAdminObj.newAdmins.push(regionalAdminName[q]);
                }
              }
            }
            for (var r in this.districtDataAdmins) {
              this.districtDataAdmins[r].sort((a, b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : a.firstName.toLowerCase() === b.firstName.toLowerCase() ? 0 : -1)
              for (var s in this.districtDataAdmins[r]) {
                var districtAdminName = this.districtDataAdmins[r];
                var districtAdminUKey = districtAdminName[s].userKey;
                if (districtAdminUKey == this.currentAdminUKey) {
                  districtAdminName[s].checked = true;
                  this.dataAdminObj.newAdmins.push(districtAdminName[s]);
                }
              }
            }
          }
        }
        this.sortStateDataAdmins();
        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.error.developerMessage, 0);
      }
    });
  }

  sortStateDataAdmins() {
    let values: any = Object.values(this.stateDataAdmins)[0];
    values = values.sort((a, b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : a.firstName.toLowerCase() === b.firstName.toLowerCase() ? 0 : -1);
    //Sort regions names
    this.regionalDataAdmins = Object.keys(this.regionalDataAdmins).sort().reduce(
      (obj, key) => {
        obj[key] = this.regionalDataAdmins[key];
        return obj;
      },
      {}
    );
    //Sort district names
    this.districtDataAdmins =Object.keys(this.districtDataAdmins).sort().reduce(
      (obj, key) => {
        obj[key] = this.districtDataAdmins[key];
        return obj;
      },
      {}
    );
  }
}

@Component({
  selector: 'worklist_reassign_page',
  templateUrl: './_reassign.html',
  styleUrls: ['./worklistReassign.component.scss']
})
export class WorklistReassignPageComponent implements OnInit {
  @Input() dataAdminObj: any;
  reassignDistrictAdminIndex: any;
  reassignRegionalAdminIndex: any;
  reassignStateAdminIndex: any;
  @Input() isCOR: boolean = true;
  @Input() parentWorklistId: any;
  @Input() worklistId: any;
  @Input() stateDataAdmins: any;
  @Input() regionalDataAdmins: any;
  @Input() districtDataAdmins: any;

  constructor(private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.assignStateParams();
  }

  assignStateParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.isCOR = params['isCOR'];
      this.worklistId = params['worklistId'];
      this.parentWorklistId = params['parentWorklistId'];
    });
  }

  //Returns number of checked users for reassign in each region/district
  getSelectedNum(value) {
    if(!value) { return 0; }
    let counter = 0;
    for (let item of value) {
      if (item.checked) {
        counter++;
      }
    }
    return counter;
  }

  addORRemoveChecked(admin, type) {
    var listIndex = this.dataAdminObj.newAdmins.indexOf(admin);
    if (listIndex === -1) {
      this.dataAdminObj.newAdmins.push(admin);
    } else {
      this.dataAdminObj.newAdmins.splice(listIndex, 1);
    }
  }

  reassignDistrictAdminDetails(index) {
    if (this.reassignDistrictAdminIndex === index) {
      this.reassignDistrictAdminIndex = -1;
    } else {
      this.reassignDistrictAdminIndex = index;
    }
  }

  reassignRegionalAdminDetails(index) {
    if (this.reassignRegionalAdminIndex === index) {
      this.reassignRegionalAdminIndex = -1;
    } else {
      this.reassignRegionalAdminIndex = index;
    }
  }

  reassignStateAdminDetails(index) {
    if (this.reassignStateAdminIndex === index) {
      this.reassignStateAdminIndex = -1;
    } else {
      this.reassignStateAdminIndex = index;
    }
  }

  goToWorklist() {
    this._router.navigate(['/worklist']);
  }

  reassignWorklistItem() {
    var worklistId = this.worklistId;
    var isCOR = this.isCOR;
    var adminObj1 = this.dataAdminObj;
    adminObj1.districtDataAdmins = null;
    adminObj1.regionalDataAdmins = null;
    adminObj1.stateDataAdmins = null;
    adminObj1.parentWorklistId = this.parentWorklistId;

    let apiCall = this._Worklist.updateWorklistAdminList(worklistId, adminObj1, isCOR);
    let subscribed = apiCall.subscribe({
      next: dt => {
        let data: any = dt;
        console.debug(data);
        this._Worklist.getWorklistCount();
        this._Toast.showToast('Worklist reassigned');
        this.goToWorklist();

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }
}
