import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StudentRecordsExchangeFilterDialogComponent } from '../student-records-exchange-filter-dialog/student-records-exchange-filter-dialog.component';
import { StudentRecordsExchangeService } from './student-record-exchange.service';
import { Observable, Subscription, tap } from 'rxjs';

@Component({
  selector: 'student-records',
  templateUrl: './studentRecordsExchange.component.html',
  styleUrls: ['./studentRecordsExchange.component.scss']
})
export class StudentRecordsExchangeComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  blankRes: boolean = false;
  currentSortColumn: any;
  sortAscending: any;
  response: any = {};
  filterParameters;
  exportLink$;
  constructor(
    public dialog: MatDialog,
    private service: StudentRecordsExchangeService) {
  }

  async ngOnInit() {
    await this.resetFilter();
    this.filterActivate();
    this.exportLink$ = this.service.studentRecordsExchangeDataExport.bind(this.service);
  }

  async resetFilter() {
    this.service.filterParams = {
      fromMM: "01",
      fromDD: "01",
      fromYYYY: "2013",
      toMM: null,
      toDD: null,
      toYYYY: null,
      groupBy: 'monthly'
    };
  }

  filterActivate() {
    // this.filterParameters.usageDataFrom = this.
    const dialogRef = this.dialog.open(StudentRecordsExchangeFilterDialogComponent, {
      height: 'auto',
      width: '650px',
      panelClass: 'cdk-overlay-container'
    });

    dialogRef.afterClosed().subscribe(filterSubmitted => {
      if(!filterSubmitted) return;
      const payload = {
        groupByField: this.service.filterParams.groupBy,
        usageDateFrom: this.formatDates().from,
        usageDateTo: this.formatDates().to,
        dataField: "userAgent",
        sortByField: "user"
      }
      this.filterParameters = payload;
      this.service.getStudentRecords(payload).pipe(
        tap(data => data.rows.reverse()),
        tap(data => this.response = data),
        tap(data => this.blankRes = !data.rows?.length)
      ).subscribe();
    })}

  private formatDates(): {from: string, to: string} {
    const from = new Date(`${this.service.filterParams.fromMM}/${this.service.filterParams.fromDD}/${this.service.filterParams.fromYYYY}`).getTime();
    const to = new Date(`${this.service.filterParams.toMM}/${this.service.filterParams.toDD}/${this.service.filterParams.toYYYY}`).getTime();
    return {from : !isNaN(from) ? from+'' : null, to: !isNaN(to)? to+'' : null};
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

}