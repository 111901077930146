import { Injectable } from '@angular/core';
import { AppConstants } from '../config/index.constants';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private toast: ToastrService) { }

  //hidedelay should be 5000
  showToast(msg, hideDelay = 5000) {
    //Error message
    if (hideDelay != 5000) {

      this.toast.show(
        ` <p>` + msg + `</p>
        <h5>CLOSE</h5>`,
        '',
        {
          enableHtml: true,
          timeOut: 0
        });
    } else { //Neutral/success message
      this.toast.show(
        ` <p> ` + msg + ` </p>
          <h4>OK</h4>`,
        '',
        {
          enableHtml: true,
          timeOut: 5000,
        });
    }
  }

  hideAllToast() {
    this.toast.clear();
  }

}
