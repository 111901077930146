import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { StudentRecordsExchangeService } from '../studentRecordsExchange/student-record-exchange.service';


@Component({
    selector: 'app-student-records-exchange',
    styleUrls: ['./student-records-exchange-filter-dialog.component.scss'],
    templateUrl: './student-records-exchange-filter-dialog.component.html'
})

export class StudentRecordsExchangeFilterDialogComponent implements OnInit, OnDestroy{
    form: FormGroup;
    subscription = new Subscription();
    @ViewChild('fromDD') fromDD: ElementRef
    @ViewChild('fromYYYY') fromYYYY: ElementRef
    @ViewChild('toDD') toDD: ElementRef
    @ViewChild('toYYYY') toYYYY: ElementRef
    constructor(private dialogRef: MatDialogRef<StudentRecordsExchangeFilterDialogComponent>, 
        private service: StudentRecordsExchangeService,
        private fb: FormBuilder) {}

    ngOnInit(): void {
        this.buildForm();
        this.prefillForm();
    }

    prefillForm() {
        if(!this.service.filterParams) return;
        this.form.setValue(this.service.filterParams);
    }

    buildForm() {
        this.form = this.fb.group({
            fromMM: ['', [Validators.min(1), Validators.max(12), Validators.maxLength(2), Validators.pattern(/^\d+$/)]],
            fromDD: ['', [Validators.min(1), Validators.max(30), Validators.maxLength(2), Validators.pattern(/^\d+$/)]],
            fromYYYY: ['', [Validators.min(1900), Validators.maxLength(4), Validators.pattern(/^\d+$/)]],
            toMM: ['', [Validators.min(1), Validators.max(12), Validators.maxLength(2), Validators.pattern(/^\d+$/)]],
            toDD: ['', [Validators.min(1), Validators.max(30), Validators.maxLength(2), Validators.pattern(/^\d+$/)]],
            toYYYY: ['', [Validators.min(1900), Validators.maxLength(4), Validators.pattern(/^\d+$/)]],
            groupBy: ['monthly'],
        })
    }

    onSubmit() {
        this.service.filterParams = this.form.value;
        this.dialogRef.close(true);
    }

    reset() {
        this.form.reset();
        this.form.get('fromMM').setValue('01');
        this.form.get('fromDD').setValue('01');
        this.form.get('fromYYYY').setValue('2013');
        this.form.get('groupBy').setValue('monthly');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}