<div class="dataManagement">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Data Management</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Data Management</a></span>
    <span>></span>
    <span>
      <p *ngIf="chart=='recordSubmission'" style="vertical-align: middle !important;">
        Record Submission
      </p>
      <p *ngIf="chart=='withdrawalCourseHistory'" style="vertical-align: middle !important;">
        Withdrawals and Course History Submission
      </p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 class="header-padding flex-90">Data Management</h1>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='recordSubmission'">
    <h1 class="header-padding" style="width:90%">Record Submission</h1>
    <div class="right-align">
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState && (isDesktop || isTablet)"
        [exportlink]="recordSubmissionExportCall" 
        [exportfilter]="dataManagementFilterParams.selectedState"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='withdrawalCourseHistory'">
    <h1 class="header-padding" style="width:90%">Withdrawals and Course History Submission</h1>
    <div class="right-align">
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState  && (isDesktop || isTablet)"
        [exportlink]="withdrawalCourseHistoryExportCall" 
        [exportfilter]="dataManagementExportObject"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <hr layout-padding class="thickHr">

  <div layout-align="center center" *ngIf="!showDrill"  class="bodyPadding">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p *ngIf="authRoleClasses.dataAdmins">
          The Data Management Dashboard charts allow you to quickly gauge how your State’s data is submitted to MSIX and
          identify any data discrepancies that require further investigation.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
        <p *ngIf="authRoleClasses.dataDashboards">
          The Data Management Dashboard charts allow you to quickly gauge how data is submitted to MSIX and identify
          any data discrepancies that require further investigation.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
      </div>

      <filter-button *ngIf="isTablet || isDesktop" (activateFilter)="filterActivate()" class="chart-row" flex=auto></filter-button>
      <div *ngIf="dataHasLoaded" [ngClass]="{'chart-row' : authRoleClasses.dataAdmins}">
        <div id="chart-section" class="chart-section vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">Record Submission</h2>
            <div class="right-align">
              <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState && (isDesktop || isTablet)"
                [exportlink]="recordSubmissionExportCall" 
                [exportfilter]="dataManagementFilterParams.selectedState"
                [showcsv]="true" 
                [showpdf]="false">
              </export-report>
            </div>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs flex-90 layout-row">
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text">
                  Last 12 Months ({{reportInfoService.getLast12Months()}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState && (isDesktop || isTablet)"
              flex=auto (keypress)="showDrilldown('recordSubmission')" (click)="showDrilldown('recordSubmission')">View Details
            </a>
          </div>
          <div *ngIf="recordSubmissionDataHasLoaded && !hideRecordSubmission">
            <msix-line-chart 
              id="recordSubmission"
              [id]="recordSubmissionId" 
              [chart]="recordSubmissionChart"  
              [data]="recordSubmissionData">
            </msix-line-chart>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hideRecordSubmission">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>
        <div id="chart-section" class="chart-section vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 style="width:90%">Withdrawals and Course History Submission</h2>
            <div class="right-align">
              <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState  && (isDesktop || isTablet)"
                [exportlink]="withdrawalCourseHistoryExportCall" 
                [exportfilter]="dataManagementExportObject"
                [showcsv]="true" 
                [showpdf]="false">
              </export-report>
            </div>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs layout-row flex-90">
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text" *ngIf="dataManagementFilterParams.periodType == 'PREVIOUS'">
                  Last Performance Period ({{dateObj.recent}})
                </p>
                <p class="perf-period-text" *ngIf="dataManagementFilterParams.periodType == 'CURRENT'">
                  Current Performance Period ({{dateObj.current}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="right-align" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.selectedState  && (isDesktop || isTablet)"
              flex=auto (keypress)="showDrilldown('withdrawalCourseHistory')" (click)="showDrilldown('withdrawalCourseHistory')">View Details
            </a>
          </div>
          <div *ngIf="courseHistSubmissionDataHasLoaded && !hideCourseHistSubmission">
            <msix-stacked-bar 
              id="withdrawalCourseHistory" 
              [id]="withdrawalCourseHistoryId"
              [chart]="withdrawalCourseHistoryChart" 
              [data]="withdrawalCourseHistoryData">
            </msix-stacked-bar>
            <p> Last Updated {{lastUpdated}}</p>
            <br />
          </div>
          <div class="no-data-display" *ngIf="hideCourseHistSubmission">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Drilldown Page-->
  <div class="layout-align-center-center bodyPadding" *ngIf="showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p *ngIf="chart=='recordSubmission'">
          The table below displays each student record your State submitted in each of the last 12 months that was rejected and the reason for rejection.
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
        <p *ngIf="chart=='withdrawalCourseHistory'">
          The table below displays each migratory child that was withdrawn over the course of the selected Performance Period and indicates which children were submitted with at least one course with the Academic Year (MDE #60) in the corresponding
          performance period and with either a Final Grade (MDE #66) or a Grade-to-Date (MDE #64).
          <a *ngIf="isTablet || isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary drill-down-rs layout-row">
        <span class="showing layout-row">Showing:</span>
        <span class="perf-period layout-row" *ngIf="chart=='withdrawalCourseHistory'">
          <p class="perf-period-text" *ngIf="dataManagementFilterParams.periodType == 'PREVIOUS'">
            Last Performance Period ({{dateObj.recent}})
          </p>
          <p class="perf-period-text" *ngIf="dataManagementFilterParams.periodType == 'CURRENT'">
            Current Performance Period ({{dateObj.current}})
          </p>
        </span>
        <span class="perf-period layout-row" *ngIf="chart=='recordSubmission'">
          <p class="perf-period-text">
            Last 12 Months ({{reportInfoService.getLast12Months()}})
          </p>
        </span>
      </div>
      <no-results *ngIf="blankRes"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>
</div>