<div layout-align="center start" class="layout-flex-row training-page background-creative" [ngClass]="{'main-border main-padding' : (responsiveUI.isDesktop), 'main-border-mobile main-padding-mobile' : (responsiveUI.isMobile || responsiveUI.isTablet)}">
  <div class="md-content" [ngClass]="{'side-padding' : (responsiveUI.isDesktop), 'side-padding-mobile' : (responsiveUI.isMobile || responsiveUI.isTablet)}">
    
    <h1 class="padding-top-10">Webinars</h1>
    <p>
      Click on the links below to access and download slides from past webinars.
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('School_Year_24-25_MSIX_Back_To_School_Webinar_08202024_508')" (click)="openPPTXFile('School_Year_24-25_MSIX_Back_To_School_Webinar_08202024_508')">School Year 24-25 MSIX Back to School Webinar</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('IDRC_MSIX_for_MEP_Data_Specialists_508_v1')" (click)="openPPTXFile('IDRC_MSIX_for_MEP_Data_Specialists_508_v1')">IDRC Presentation 2024</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('NASDME_Conference_April 2024_05222024_508')" (click)="openPPTXFile('NASDME_Conference_April 2024_05222024_508')">NASDME 2024: MSIX a Valuable Resource for the MEP</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('Cybersecurity_and_Account_Management_Webinar_04042024_508')" (click)="openPPTXFile('Cybersecurity_and_Account_Management_Webinar_04042024_508')">Cybersecurity and Account Management Webinar 2024</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('State Assessment Promising Practices Webinar_10242023_508')" (click)="openPPTXFile('State Assessment Promising Practices Webinar_10242023_508')">State Assessment Promising Practices Webinar 2023</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('School_Year_23-24_MSIX_Back_To_School_Webinar_08032023_508_v2_0')" (click)="openPPTXFile('School_Year_23-24_MSIX_Back_To_School_Webinar_08032023_508_v2_0')">School Year 23-24 MSIX Back to School Webinar</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('NASDME_Conference_2023_05012023_508')" (click)="openPPTXFile('NASDME_Conference_2023_05012023_508')">NASDME 2023: MSIX a Valuable Resource for the MEP</a>
    </p>
    <p>
      <a href="{{help_url}}/training/Webinars/ADM_2023_MSIX_Updates_for_SY_2022-23_03022023_508.pdf" target="_blank" rel="noopener noreferrer">Annual Directors Meeting 2023: MSIX Updates for SY 2022-2023</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Cybersecurity_Webinar_02232023_508')" (click)="openPPTXFile('MSIX_Cybersecurity_Webinar_02232023_508')">Cybersecurity and Account Management Webinar 2023</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('BTS_Student_Information_Reports_508_v2_0')" (click)="openPPTXFile('BTS_Student_Information_Reports_508_v2_0')">Small Group Session: Student Information Reports 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('BTS_Data_Requests_and_Move_Notices_508_v2_0')" (click)="openPPTXFile('BTS_Data_Requests_and_Move_Notices_508_v2_0')">Small Group Session: Data Requests and Move Notices 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('BTS_My_Lists_508_v2_0')" (click)="openPPTXFile('BTS_My_Lists_508_v2_0')">Small Group Session: My Lists 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Child_Counts_for_MEP_Allocations_508_v2_0')" (click)="openPPTXFile('MSIX_Child_Counts_for_MEP_Allocations_508_v2_0')">MSIX Child Counts for MEP Allocations 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('School_Year_22-23_MSIX_Back_to_School_Webinar_08112022_508_v1_1')" (click)="openPPTXFile('School_Year_22-23_MSIX_Back_to_School_Webinar_08112022_508_v1_1')">School Year 22-23 MSIX Back to School Webinar</a>
    </p>
    <p>
      <a href="assets/trainingCorner/webinars/MFA_Train_the_Trainer_Webinar_07142022_508_v1.3.pptx">MSIX MFA Train the Trainer Webinar 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('TMIP_MSIX_for_Recruiters_and_Program_Directors_030122_508_v1_0')" (click)="openPPTXFile('TMIP_MSIX_for_Recruiters_and_Program_Directors_030122_508_v1_0')">TMIP Presentation 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('Cybersecurity_and_Account_Management_Webinar_0242022_508_v1_0')" (click)="openPPTXFile('Cybersecurity_and_Account_Management_Webinar_0242022_508_v1_0')">Cybersecurity and Account Management Webinar 2022</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('IDRC_MSIX_for_Data_Specialists_508_v0_1')" (click)="openPPTXFile('IDRC_MSIX_for_Data_Specialists_508_v0_1')">IDRC Presentation 2021</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('School_Year_21_22_MSIX_Back_to_School_Webinar_508_v3_0')" (click)="openPPTXFile('School_Year_21_22_MSIX_Back_to_School_Webinar_508_v3_0')">School Year 21-22: Back to School Webinar 2021</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('NASDME_2021_National_MEP_Data_Trends_and_MSIX_Dashboards')" (click)="openPPTXFile('NASDME_2021_National_MEP_Data_Trends_and_MSIX_Dashboards')">NASDME 2021: National MEP Data Trends and MSIX Dashboards</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Cybersecurity_and_Accounts_Management_2021')" (click)="openPPTXFile('MSIX_Cybersecurity_and_Accounts_Management_2021')">MSIX Cybersecurity and Accounts Management 2021</a>
    </p>
    <p>
    <a class="report-type" tabindex="0" (keypress)="openPPTXFile('OY3_Recent_MSIX_Enhancements_Webinar_v1_1')" (click)="openPPTXFile('OY3_Recent_MSIX_Enhancements_Webinar_v1_1')">Recent MSIX Enhancements Webinar 2021</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Promising_Practices_Webinar_v508_Web_Version')" (click)="openPPTXFile('MSIX_Promising_Practices_Webinar_v508_Web_Version')">School Year 20-21: MSIX Promising Practices Webinar 2020</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('Security_Privacy_and_Account_Management_Webinar_2020')" (click)="openPPTXFile('Security_Privacy_and_Account_Management_Webinar_2020')">Security, Privacy, and Account Management Webinar 2020</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Back_To_School_Webinar_Deck_2019')" (click)="openPPTXFile('MSIX_Back_To_School_Webinar_Deck_2019')">Back to School Webinar 2019</a>
    </p>
    <p>
      <a class="report-type" tabindex="0" (keypress)="openPPTXFile('MSIX_Child_Count_Reconciliation_Webinar_Deck_2019')" (click)="openPPTXFile('MSIX_Child_Count_Reconciliation_Webinar_Deck_2019')">MSIX Child Count Reconciliation Webinar 2019</a>
    </p>
    <p>
      <a href="{{help_url}}/training/Webinars/Security_Privacy_Account_Management_Webinar_2019.pdf" target="_blank" rel="noopener noreferrer">Security, Privacy, and Account Management Webinar 2019</a>
    </p>
    <p>
      <a href="{{help_url}}/training/Webinars/MSIX_Student_File_Submission_Best_Practices_Webinar.pdf" target="_blank" rel="noopener noreferrer">MSIX Student File Submission: Best Practices</a>
    </p>
    <p>
      <a href="{{help_url}}/training/Account_Management_Webinar_Slides_February_2018_v2.pdf" target="_blank" rel="noopener noreferrer">Security, Privacy, and Account Management Webinar 2018</a>
    </p>
    <p>
      <a href="{{help_url}}/training/MSIX_Webinar-Managing_Worklist-1-11-2017-Change_of_Residency.pdf" target="_blank" rel="noopener noreferrer">Managing Worklist: Move Notifications & Data Requests</a>
    </p>
    <p>
      <a href="{{help_url}}/training/MSIX_Webinar-Managing_Worklist-1-11-2017-Merges_and_Splits.pdf" target="_blank" rel="noopener noreferrer">Managing Worklist: Merge & Split</a>
    </p>

    
    <h1 class="padding-top-30">Videos</h1>
    <p>
      <a href="{{help_url}}training/Data_Request_Video.mp4" download="Data_Request_Video.mp4">MSIX Data Requests Training Video</a> | 
      <a href="{{help_url}}training/Data_Request_Audio.mp3" download="Data_Request_Audio.mp3">Audio Description</a>
    </p>
    <p>
      <a href="{{help_url}}training/Move_Notice_Video.mp4" download="Move_Notice_Video.mp4">MSIX Move Notices Training Video</a> | 
      <a href="{{help_url}}training/Move_Notice_Audio.mp3" download="Move_Notice_Audio.mp3">Audio Description</a>
    </p>

    <h1 class="padding-top-30">Training Environment</h1>
    <p>
      Click on the link below to access material to support State training.
    </p>
    <p>
      <a href="{{help_url}}/training/MSIX Training Environment Reservation Guide_v1.1.pdf" target="_blank" rel="noopener noreferrer">MSIX Training Environment Reservation Guide</a>
    </p>
  </div>
</div>
