import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Subscription } from "rxjs";
import { UNPROTECTED_ROUTES } from "../config/index.constants";
import { AuthService } from "./auth.service";
import { LoginService } from "./login.service";
import { Keepalive } from "@ng-idle/keepalive";

const IDLE_TIME_SECONDS = 15 * 60; // if inactive for 15 minutes, app gets into idle state
const IDLE_DURATION = 5 // time user can be in idle state before signing out

@Injectable({
    providedIn: 'root'
})
export class IdleService implements OnDestroy {
    sub = new Subscription();
    
    idleMinutes = new BehaviorSubject(IDLE_TIME_SECONDS);
    isAuthenticated: boolean = false;

    constructor(private idle: Idle, 
        private authService: AuthService,
        private router: Router,
        private loginService: LoginService,
        private keepalive: Keepalive
    ) {}

    setIdle() {
        this.idle.setIdle(IDLE_TIME_SECONDS);
        this.idle.setTimeout(IDLE_DURATION);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        if (this.loginService.userIsLoggedIn$.subscribe((isLoggedIn: boolean) => {
            this.isAuthenticated = isLoggedIn;
            if (isLoggedIn) {
                this.idle.watch();
            }
            else {
                this.idle.stop();
            }
        }))

        this.idle.onIdleEnd.subscribe(() => console.log('no longer idle'));
        this.idle.onIdleStart.subscribe(() => console.log('you have gone idle'));
        this.idle.onTimeout.subscribe(() => this.stopWatching());
        this.keepalive.interval(15);
    }
      
    stopWatching(isIdle = true) {
        const currentUrl = this.router.url.split('?')[0];
        if (!this.authService.isAuthenticated() && isIdle && UNPROTECTED_ROUTES.some(r => r.includes(currentUrl))) {
            return;
        }
        else {
            this.idle.stop();
            if (this.authService.isAuthenticated() && isIdle && UNPROTECTED_ROUTES.some(r => r.includes(currentUrl))) {
               this.loginService.logout(false);
           } else {
               this.loginService.logout();
           }
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}