<div class="reportsTable">
  <div class="double-header" *ngIf="doubleHeader">
    <span class="student-info borderright">
      <div class="doubleText">
        Student Information
      </div>
    </span>
    <span class="qad borderright" *ngIf="qadToggle">
      <div class="doubleText">
        QAD
      </div>
    </span>
    <span class="moveFrom borderright">
      <div class="doubleText">{{doubleHeader.first}}</div>
    </span>
    <span [ngClass]="{moveTo : qadToggle===false, moveToQAD : qadToggle===true}">
      <div class="doubleText">{{doubleHeader.second}}</div>
    </span>
  </div>
  <div class="dynamic-table">
    <span style="padding: 0px 20px" *ngIf="showcheckbox">
      <p></p>
    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>0">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header1.percentOrNum, 'column-header': responsedata?.columnHeaders?.header1.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header1.clickable || sortColumn('column1', responsedata?.columnHeaders?.header1.sortAsc, responsedata?.columnHeaders?.header1.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header1.clickable || sortColumn('column1', responsedata?.columnHeaders?.header1.sortAsc, responsedata?.columnHeaders?.header1.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header1.value }}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>1">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header2.percentOrNum, 'column-header': responsedata?.columnHeaders?.header2.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header2.clickable || sortColumn('column2', responsedata?.columnHeaders?.header2.sortAsc, responsedata?.columnHeaders?.header2.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header2.clickable || sortColumn('column2', responsedata?.columnHeaders?.header2.sortAsc, responsedata?.columnHeaders?.header2.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header2.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>2">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header3.percentOrNum, 'column-header': responsedata?.columnHeaders?.header3.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header3.clickable || sortColumn('column3', responsedata?.columnHeaders?.header3.sortAsc, responsedata?.columnHeaders?.header3.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header3.clickable || sortColumn('column3', responsedata?.columnHeaders?.header3.sortAsc, responsedata?.columnHeaders?.header3.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header3.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>3">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header4.percentOrNum, 'column-header': responsedata?.columnHeaders?.header4.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header4.clickable || sortColumn('column4', responsedata?.columnHeaders?.header4.sortAsc, responsedata?.columnHeaders?.header4.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header4.clickable || sortColumn('column4', responsedata?.columnHeaders?.header4.sortAsc, responsedata?.columnHeaders?.header4.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header4.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>4" [ngClass]="{'borderright': showHeader===true}">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header5.percentOrNum, 
        'column-header': responsedata?.columnHeaders?.header5.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header5.clickable || sortColumn('column5', responsedata?.columnHeaders?.header5.sortAsc, responsedata?.columnHeaders?.header5.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header5.clickable || sortColumn('column5', responsedata?.columnHeaders?.header5.sortAsc, responsedata?.columnHeaders?.header5.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header5.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>5" [ngClass]="{borderright: qadRight===true}">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header6.percentOrNum, 'column-header': responsedata?.columnHeaders?.header6.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header6.clickable || sortColumn('column6', responsedata?.columnHeaders?.header6.sortAsc, responsedata?.columnHeaders?.header6.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header6.clickable || sortColumn('column6', responsedata?.columnHeaders?.header6.sortAsc, responsedata?.columnHeaders?.header6.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header6.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>6">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header7.percentOrNum, 'column-header': responsedata?.columnHeaders?.header7.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header7.clickable || sortColumn('column7', responsedata?.columnHeaders?.header7.sortAsc, responsedata?.columnHeaders?.header7.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header7.clickable || sortColumn('column7', responsedata?.columnHeaders?.header7.sortAsc, responsedata?.columnHeaders?.header7.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header7.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>7" [ngClass]="{borderright: showHeader===true && qadRight===false}">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header8.percentOrNum, 'column-header': responsedata?.columnHeaders?.header8.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header8.clickable || sortColumn('column8', responsedata?.columnHeaders?.header8.sortAsc, responsedata?.columnHeaders?.header8.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header8.clickable || sortColumn('column8', responsedata?.columnHeaders?.header8.sortAsc, responsedata?.columnHeaders?.header8.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header8.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>8" [ngClass]="{borderright: qadRight===true}">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header9.percentOrNum, 'column-header': responsedata?.columnHeaders?.header9.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header9.clickable || sortColumn('column9', responsedata?.columnHeaders?.header9.sortAsc, responsedata?.columnHeaders?.header9.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header9.clickable || sortColumn('column9', responsedata?.columnHeaders?.header9.sortAsc, responsedata?.columnHeaders?.header9.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header9.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column9'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column9'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>9">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header10.percentOrNum, 'column-header': responsedata?.columnHeaders?.header10.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header10.clickable || sortColumn('column10', responsedata?.columnHeaders?.header10.sortAsc, responsedata?.columnHeaders?.header10.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header10.clickable || sortColumn('column10', responsedata?.columnHeaders?.header10.sortAsc, responsedata?.columnHeaders?.header10.sortDesc)" 
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header10.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column10'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column10'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>10">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header11.percentOrNum, 'column-header': responsedata?.columnHeaders?.header11.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header11.clickable || sortColumn('column11', responsedata?.columnHeaders?.header11.sortAsc, responsedata?.columnHeaders?.header11.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header11.clickable || sortColumn('column11', responsedata?.columnHeaders?.header11.sortAsc, responsedata?.columnHeaders?.header11.sortDesc)"
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header11.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column11'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column11'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
    <span class="{{colHdrWidthClass}} table-padding" *ngIf="responsedata?.columnCount>11">
      <a tabindex="0" [ngClass]="{'rightJustify': responsedata?.columnHeaders?.header12.percentOrNum, 'column-header': responsedata?.columnHeaders?.header12.value != ''}" 
        (click)="!responsedata?.columnHeaders?.header12.clickable || sortColumn('column12', responsedata?.columnHeaders?.header12.sortAsc, responsedata?.columnHeaders?.header12.sortDesc)" 
        (keypress)="!responsedata?.columnHeaders?.header12.clickable || sortColumn('column12', responsedata?.columnHeaders?.header12.sortAsc, responsedata?.columnHeaders?.header12.sortDesc)"
        class="left-table-padding">
      {{responsedata?.columnHeaders?.header12.value}}
      <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column12'===currentSortColumn && !sortAscending"></i>
      <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column12'===currentSortColumn && sortAscending"></i>
      </a>

    </span>
  </div>

  <div>
    <div class="data-table">
      <reports-row 
        *ngFor="let rowinfo of responsedata?.rows"
        [headerinfo]="responsedata?.columnHeaders" 
        [rowdata]="rowinfo" 
        [msixIdShouldNavigate]="msixIdShouldNavigate"
        [showcheckbox]="showcheckbox" 
        [multiLeveled]="isMultiLeveled" 
        [drilldown]="isDrilldown" 
        [columnCount]="responsedata?.columnCount" 
        [sortColumn]="currentSortColumn" 
        [stopnavigate]="stopnavigate" 
        [enableworklink]="enableworklink"
        [pfsReport]="pfsReport"
        [emitRowClick]="emitRowClick"
        (mycallback)="rowItemClicked($event)"
        (handleMSIXIDClick)="onMSIXIDClick($event)"
        (checkboxcallback)="checkboxClicked($event)">
      </reports-row>
    </div>
  </div>

  <div *ngIf="shownavigation" class="layout-row center" layout-align="center center">
    <div layout="column" style="width: 50%">
      <button class="md-button pageNavBack" (click)="navigateDrillDown('back')" [disabled]="beginRow == 1" aria-label="back">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </button>
    </div>
    <div layout="column" style="width: 50%">
      <button class="md-button pageNavNext" (click)="navigateDrillDown('forward')" [disabled]="drilldowninfo.drillDown.exportCount <= endRow || responsedata?.totalAvailableRows <= drilldowninfo.drillDown.endRow" aria-label="forward">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
