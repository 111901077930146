<div class="fileSubmission">
  <div class="breadcrumb layout-row" *ngIf="!showDrill && !showMdeDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><p>File Submission</p></span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill && !showMdeDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a (click)="goToReport()">File Submission</a></span>
    <span>></span>
    <span><p>File Submission Details<p></span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="!showDrill && showMdeDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a (click)="goToReport()">File Submission</a></span>
    <span>></span>
    <span><a (click)="goToReport('Details')">File Submission Details</a></span>
    <span>></span>
    <span><p>File Submission MDE Details: {{mdeDrilldown}}<p></span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill && !showMdeDrill">
    <h1 class="flex-90 header-padding">File Submission</h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="exportCall" 
      [exportfilter]="exportParams"
      [showpdf]="true" 
      [showcsv]="true" 
      [blankres]="blankRes">
    </export-report>
  </div>

  <div class="header layout-row" *ngIf="showDrill && !showMdeDrill">
    <h1 class="flex-90 header-padding">File Submission Details</h1>
  </div>

  <div class="header layout-row" *ngIf="!showDrill && showMdeDrill">
    <h1 class="flex-90 header-padding">File Submission MDE Details: {{mdeDrilldown}}</h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="mdeExportCall" 
      [exportfilter]="mdeDrillDownExport"
      [showcsv]="true" 
      [blankres]="blankRes">
    </export-report>
  </div>

  <hr layout-padding class="thickHr flex">

  <div class="layout-row layout-align-center-center" *ngIf="showDrill && !showMdeDrill">
    <div class="layout-column flex-90">
        <div class="report-summary drill-down-rs layout-row">
          <p>
            The data below displays the file submission and student record details associated with the selected file. 
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
      <!--<div ng-include="'_detailsFileSubmission.html'"></div>-->
      <div>
        <div class="detailsFileSubmission">
          <div class="details-section layout-row">
            <div flex class="details-subsection">
              <div class="layout-column details-info-section">
                <div class="details-subheader">
                  <h2>File Submission Details</h2>
                </div>
                <hr flex layout-padding class="thickHr flex">
                <div class="layout-row details-info">
                  <p class="bold flex-50">SSII File Name:</p>
                  <p class="flex-50">{{drillDownDetails.fileName}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Begin Date:</p>
                  <p class="flex-50">{{drillDownDetails.formattedBeginEffectiveDate}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">End Date:</p>
                  <p class="flex-50">{{drillDownDetails.formattedEndEffectiveDate}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Student Count:</p>
                  <p class="flex-50">{{drillDownDetails.totalStudentCount | number}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Record Count:</p>
                  <p class="flex-50">{{drillDownDetails.totalRecordCount | number}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">File Format:</p>
                  <p class="flex-50">{{drillDownDetails.fileFormat}}</p>
                </div>
              </div>
              <div layout="column" class="details-info-section">
                <div class="details-subheader">
                  <h2>Student Record Details</h2>
                </div>
                <hr flex layout-padding class="thickHr flex">
                <div class="layout-row details-info">
                  <p class="bold flex-50">New Student Count:</p>
                  <p class="flex-50">{{drillDownDetails.newStudentCount}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Near Match Student Count:</p>
                  <p class="flex-50">{{drillDownDetails.nearMatchStudentCount}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Existing Student Count:</p>
                  <p class="flex-50">{{drillDownDetails.existingStudentCount}}</p>
                </div>
                <div class="layout-row details-info">
                  <p class="bold flex-50">Total Rejected Count:</p>
                  <p class="flex-50">{{drillDownDetails.totalStudentRejectionCount}}</p>
                </div>
              </div>
            </div>
        
            <div flex class="details-subsection">
              <div layout="column" class="details-info-section">
                <div class="details-subheader">
                  <h2>File Processing Information</h2>
                </div>
                <hr flex layout-padding class="flex thickHr">
                <div class="details-info-section">
                  <div class="layout-row details-info">
                    <p class="bold flex-50">File Status:</p>
                    <p class="flex-50">{{drillDownDetails.fileStatus}}</p>
                  </div>
                  <div class="layout-row details-info">
                    <p class="bold flex-50">Received Date:</p>
                    <p class="flex-50">{{drillDownDetails.formattedReceivedDate}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="layout-row details-section">
            <div flex class="details-subsection" *ngIf="drillDownData1.rows.length > 0">
              <div layout="column" class="details-info-section">
                <div class="details-subheader">
                  <h2>File Counts</h2>
                </div>
                <hr flex layout-padding class="thickHr flex" >
                <div class="layout-row details-info">
                  <reports-table class="count-table" 
                    [responsedata]="drillDownData1"
                    [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
                    [isreportlevelspecialsortinit]="isFileCountsInit" 
                    [hasreportlevelspecialsort]="true" 
                    [currentsortcolumn]="currentFileCountDetailsSortColumn" 
                    [sortascending]="fileCountDetailsSortAscending"
                    (reportlevelspecialsort)="handleReportSpecialSortClickForFileCounts($event)"
                    (sortbycolumn)="sortByColumnForFileCountDetails($event)" 
                    (mycallback)="drillDownMdeInit($event)" >
                  </reports-table>
                </div>
                <p>
                  Clicking on a specific MDE Category in the File Counts table will direct you to the corresponding drill down page for additional details on the submissions, errors, and rejections.
                </p>
              </div>
            </div>
            <div flex class="details-subsection" *ngIf="drillDownData2.rows.length > 0">
              <div class="layout-column details-info-section">
                <div class="details-subheader">
                  <h2>File Errors</h2>
                </div>
                <hr flex layout-padding class="thickHr flex">
                <div class="layout-row details-info">
                  <reports-table class="count-table" 
                    [responsedata]="drillDownData2"
                    [isreportlevelspecialsortinit]="isFileErrorsInit" 
                    [hasreportlevelspecialsort]="true"
                    [currentsortcolumn]="currentFileErrorDetailsSortColumn" 
                    [sortascending]="fileErrorDetailsSortAscending" 
                    (reportlevelspecialsort)="handleReportSpecialSortClickForFileErrors($event)"
                    (sortbycolumn)="sortByColumnForFileErrorDetails($event)" >
                  </reports-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <reports-table 
        [responsedata]="drillDownData" 
        (mycallback)="drillDownInit($event)">
      </reports-table>
    </div>
  </div>

  <div class="layout-row layout-align-center-center"  *ngIf="!showDrill && !showMdeDrill">
    <div class="layout-column flex-90">
      <div class="report-summary drill-down-rs layout-row">
        <p>
          This report displays the files submitted to MSIX from your State, along with the student count, record count, errors and rejections stemming from each data file. 
          <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <filter-button *ngIf="reportInfo.authRoleClasses.dataQuality" 
        (activateFilter)="filterActivate()">
      </filter-button>
      <reports-table *ngIf="!blankRes" 
        [responsedata]="response"
        [isreportlevelspecialsortinit]="isInit"
        [hasreportlevelspecialsort]="true"
        [currentsortcolumn]="currentSortColumn"
        [sortascending]="sortAscending"
        (mycallback)="drillDownInit($event)"
        (reportlevelspecialsort)="handleReportSpecialSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
      <no-results *ngIf="blankRes"></no-results>

      <div *ngIf="!blankRes" class="layout-row layout-align-center-center">
        <div class="layout-column" flex="2">
          <button class="md-button" (click)="pageBack()" [disabled]="reportPaginationBeginRow===1" aria-label="back"
            style="min-width: 88px; margin: 6px 8px">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
        </div>
        <div class="layout-column" flex="2">
          <button class="md-button" (click)="pageForward()" [disabled]="availableReportRows <= reportPaginationEndRow" 
            aria-label="forward" style="min-width: 88px; margin: 6px 8px">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--MDE Details drilldown page-->
  <div class="layout-row layout-align-center-center" *ngIf="!showDrill && showMdeDrill">
    <div class="layout-column flex-90">
      <div class="report-summary drill-down-rs layout-row">
         <!--depending on window size, copy can be too long and break down into a 2nd row; "Learn More" anchor needs to be to the right of the second line, not the entire copy-->
        <p *ngIf="mdeDrilldown === 'Student Demographic'">
          The data below displays the file submission and student record details associated with the Student Demographic MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="mdeDrilldown === 'QAD'">
          The data below displays the file submission and student record details associated with the QAD MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="mdeDrilldown === 'Enrollment'">
          The data below displays the file submission and student record details associated with Enrollment MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="mdeDrilldown === 'Assessment'">
          The data below displays the file submission and student record details associated with the Assessment MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="mdeDrilldown === 'Course History'">
          The data below displays the file submission and student record details associated with the Course History MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="mdeDrilldown === 'Non-MDE'">
          The data below displays the file submission and student record details associated with data required for file submissions that are not MDEs submitted in the selected file. 
          <a tabindex="0" class="learn-more" (keypress)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <div class="layout-row">
        <span class="showing">SSII File Name:</span>
        <span class="perf-period">
            <p class="perf-period-text">
                {{drillDownDetails.fileName}}
            </p>
        </span>
      </div>
      <div class="layout-row">
        <span class="showing">SSOREI File Name:</span>
        <span class="perf-period">
            <p class="perf-period-text">
                {{ssoreiName}}
            </p>
        </span>
      </div>
      <reports-table 
        [responsedata]="drillDownMdeDetails" 
        [isreportlevelspecialsortinit]="isMdeDetailsInit" 
        [hasreportlevelspecialsort]="true"  
        [currentsortcolumn]="currentMdeDetailsSortColumn" 
        [sortascending]="mdeDetailsSortAscending"
        (reportlevelspecialsort)="handleReportSpecialSortClickForMdeDetails($event)"
        (sortbycolumn)="sortByColumnForMdeDetails($event)">
      </reports-table>
    </div>
  </div>
</div>
