import { Component, Injectable, Input } from '@angular/core'
import { ToastrService } from 'ngx-toastr';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

import * as moment from 'moment';
import { ReportsService } from 'src/app/services/reports.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { HttpResponse } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { Observable, Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnDestroy {
  @Input() exportlink: any;
  @Input() exportfilter: any;
  @Input() showcsv: boolean = true;
  @Input() showpdf: boolean = false;
  @Input() showzip: boolean = false;
  @Input() exportcount: number;
  //Exportlink holds the API Call
  @Input() blankres: boolean = false;

  @Input() exportLink$: any; // TODO need to refactor to pass in an refeence to a method, cannot use obs, as need to add export type : pdf or exel [mdev]

  subscription = new Subscription()

  menuOpen = false;
  constructor(private toastService: ToastrService,
    private reportsService: ReportsService,
    private savedlistService: SavedListService) { }

  openExportMenu() {
    this.menuOpen = !this.menuOpen;
  }

  export(exportType: string) {
    if (this.blankres) {
      this.toastService.show(
        ` <p>No Report data available to export </p>
          <h4>OK</h4>`,
        '',
        {
          enableHtml: true,
          timeOut: 5000,
        });
    } else {//Start getting export data
      if ((this.showpdf || this.showzip) && this.exportfilter) {
        this.exportfilter.exportType = exportType;
      }
      //store original begin & end row values
      var beginOrig = "";
      var endOrig = "";
      //Grab entire report for export drilldown
      if (this.exportfilter?.studentIDFilter) {
        beginOrig = this.exportfilter.studentIDFilter.beginRow
        this.exportfilter.studentIDFilter.beginRow = 1;
        if (this.exportcount) {
          endOrig = this.exportfilter.studentIDFilter.endRow;
          this.exportfilter.studentIDFilter.endRow = this.exportcount;
        }
      }
      if (this.exportcount) {
        if (typeof this.exportcount === 'undefined') {
          this.exportcount = 0;
        }
      }

      // Piecing together the api call to get the export file
      // format should result in: this.reportsService + export link + ( + filter parameters + )
      // ex: this.reportsService.accountManagementExport(params);
      let apiCall: Observable<any>;
      let params = this.exportfilter;
      if (params) {
        if (beginOrig) params.beginRow = beginOrig;
        if (endOrig) params.endRow = endOrig;
        if (!this.exportLink$ && this.exportlink && this.exportfilter) {
          if (this.exportlink == 'exportSavedListFile') {
            apiCall = this.savedlistService[this.exportlink](params);
          } else {
            apiCall = this.reportsService[this.exportlink](params);
          }
          
        }
        else if (this.exportLink$ && typeof this.exportLink$ === 'function') {
          apiCall = this.exportLink$(params);
        }  else if (this.exportLink$) {
          apiCall = this.exportLink$;
        } 

      }
     
      this.subscription.add(apiCall.subscribe({
        next: data => {
            //Get filename from header
            var header = data.headers?.get('content-disposition');
            var date = moment().format('MM/DD/YYYY');
            var fileName = "report" + date + "." + exportType.toLowerCase();
            if (data.headers) {
              try {
                fileName = header?.split('attachment; filename=').pop();
                console.log("fileName is: ", fileName);
              } catch (err) { }
            }
            //Create file as Blob
            var file = new Blob([data.body], {
              type: 'application/' + exportType
            });
            var fileURL = window.URL.createObjectURL(file);
            var a = document.createElement('a');
            //Download blob
            if (window.navigator && navigator.msSaveBlob) {
              console.log("Downloading blob from navigator.msSaveBlob");
              navigator.msSaveBlob(file, fileName);
            } else {
              a.href = fileURL;
              a.target = '_blank';
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
            }
            // reset original begin & end row values
            if (this.exportfilter?.studentIDFilter) {
              if(beginOrig) this.exportfilter.studentIDFilter.beginRow = beginOrig;
              if(endOrig) this.exportfilter.studentIDFilter.endRow = endOrig;
            }
        },
        error: error => {
          console.log("Error - Export Report.export(): ", error);
        }
      })
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

