import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, filter, map, Observable, of, startWith, tap } from 'rxjs';
import { SavedListService } from 'src/app/services/savedlist.service';
import { ToastService } from 'src/app/services/toast.service';
import { IListItem, IUser } from 'src/app/types/interfaces';

@Component({
    selector: 'app-share-my-list-dialog',
    styleUrls: ['./share-my-list-dialog.component.scss'],
    templateUrl: './share-my-list-dialog.component.html'
})
export class ShareMyListDialogComponent implements OnInit {
    form: FormGroup;
    filteredLists$: Observable<IListItem[]>;
    filteredUsers$: Observable<IUser[]>
    mySavedListsArray: IListItem[];
    shareUserList: IUser[];
    selectedLists = new Set();
    selectedUsers = new Set();
    step: number = 1;
    numberOfRemainingLists: number;
    constructor(private fb: FormBuilder,
        private toastService: ToastService,
        private savedListService: SavedListService,
        private dialog: DialogRef<ShareMyListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            params: {
                comments: string, multiSelectMySavedListsData: IListItem[],
                multiSelectSelectedUsers: IUser[], mySavedListsArray: IListItem[], shareUserList: IUser[]
            }
        }) {
        this.mySavedListsArray = this.data.params.mySavedListsArray
        this.shareUserList = this.data.params.shareUserList
    }


    ngOnInit(): void {
        this.form = this.fb.group({
            listName: [],
            userName: [],
            comments: []
        })

        this.filteredLists$ = this.form.get('listName').valueChanges.pipe(
            startWith(''),
            map(val => (val?.trim() === '' ||val === undefined || val === null) ? '' : val),
            map(value => {
                return this.mySavedListsArray.filter(l => l.saveListName?.toLowerCase().includes(value?.toLowerCase()) && !this.selectedLists.has(l.saveListName?.toLowerCase()));
            })
        )
        this.filteredUsers$ = this.form.get('userName').valueChanges.pipe(
            startWith(''),
            map(val => (val?.trim() === '' ||val === undefined || val === null) ? '' : val),
            map(value => this.userFilter(value))
        )
    }

    userFilter(searchTerm: string) {
        const term = searchTerm ? searchTerm.toLowerCase() : '';
        return this.shareUserList.filter(u => (u.username?.toLowerCase().includes(term) || 
        u.email?.toLowerCase().includes(term) || u.firstName?.toLowerCase().includes(term) || 
        u.lastName?.toLowerCase().includes(term)) && !this.selectedUsers.has((u as any).userDisplayString)
    )
    }


    handleOptionSelected(evt: MatAutocompleteSelectedEvent) {
        if (this.step === 1) {
            this.selectedLists.add(evt.option.value);
            this.form.get('listName').reset();
        } else if (this.step === 2) {
            this.selectedUsers.add(evt.option.value);
            this.form.get('userName').reset();
        }
    }

    onListItemRemove(item: string) {
        if (this.selectedLists.has(item)) {
            this.selectedLists.delete(item)
        }
    }

    onUsernameRemove(item: string) {
        if (this.selectedUsers.has(item)) {
            this.selectedUsers.delete(item)
        }
    }

    handleAddAll() {
        if (this.step === 1) {
            for (const list of this.mySavedListsArray) {
                this.selectedLists.add(list.saveListName)
            }
            // triggering form.valueChanges obs so filteredLists$ gets updated
            this.form.get('listName').setValue('')
        } else if (this.step === 2) {
            for (const item of this.shareUserList) {
                this.selectedUsers.add((item as any).userDisplayString)
            }
            // triggering form.valueChanges obs so filteredUsers$ gets updated
            this.form.get('userName').setValue('')
        }
    }

    handleRemoveAll() {
        if (this.step === 1) {
            this.selectedLists.clear();
            // triggering form.valueChanges obs so filteredLists$ gets updated
            this.form.get('listName').setValue('')
        } else if (this.step === 2) {
            this.selectedUsers.clear();
            // triggering form.valueChanges obs so filteredUsers$ gets updated
            this.form.get('userName').setValue('')
        }
    }

    handleNext() {
        if (this.step >= 3) {
            const payload = {
                saveLists: this.data.params.mySavedListsArray.filter(l => this.selectedLists.has(l.saveListName)),
                users: this.data.params.shareUserList.filter(u => this.selectedUsers.has((u as any).userDisplayString)),
                comments: this.form.get('comments').value
            };
            this.savedListService.shareMySavedList(payload).pipe(
                tap(() => this.toastService.showToast('Your List(s) have been shared successfully.')),
                catchError((error) => {
                    this.toastService.showToast(error.data.developerMessage, 0);
                    return of(null)
                })
            ).subscribe();
            this.dialog.close();
        } else {
            this.step += 1;
        }
    }

    handlePrevious() {
        this.step -= 1;
    }
}