import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import * as angular from "angular";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router'; 
import { FormBuilder } from '@angular/forms';
import { AutoResume } from '@ng-idle/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'dataValidity',
  templateUrl: './dataValidity.component.html',
  styleUrls: ['./dataValidity.component.scss']
})
export class DataValidityComponent {
  showDrill: boolean = false;
  drillDown: any = {};
  drillDownData: any = {};
  reportInfo: any = {};
  showFilter: boolean = false;

  // holds the list of states
  states: any[] = [];

  // holds the set of selected stateKeys
  selectedStatesArray: any[] = [];
  fullStateArray: any[] = [];

  // holds the current active filter parameters
  filterParameters: any = {    
    dataViewType: "State",
    dataPeriodType: "No Period",
    noyes: "no",
    authRoleClasses: {},
    stateList: [],
    selectedStatesArray: []
  };
  activeFilter: any = {};
  activeSelectedStatesArray: any[] = [];

  //this object holds Report Data for initial display via reportInit()
  response: any = {};
  blankRes: boolean = false;

  //holds values for maintaining sort
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';

  // holds the API call variables for ReportsService
  reportGroupVar: string = 'studentInfo';
  reportDrillDownVar: string = '';

  //this object holds current & recent reporting period for display purposes
  displayData: any = {
    current: "",
    recent: "",
    state: "",
    recordName: ""
  };
  exportCall: string;
  exportParams: any = { params: {}, exportPath: '' };
  infoExportCall: string = 'dataValidityInfoExport';
  exportFilterParameters: { dataPeriodType: any; dataViewType: any; exportType: any; noyes: any; stateList: any; };
  exportDrilldownParams: { params: any; exportPath: any; };
  showNavigationProgress: boolean;

  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private userService: UserService,
    private reportInfoService: ReportInfoService,
    private _router: Router) {
  }

  ngOnInit() {
    this.resetFilter();
    this.getReportInfo();
  }

  goToReport() {
    this.showDrill = false;
    this.blankRes = false;
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(DataValidityDDLearnMore, {
        height: 'auto',
        width: '55%',
        panelClass: 'cdk-overlay-container',
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(DataValidityLearnMore, {
        height: 'auto',
        width: '55%',
        panelClass: 'cdk-overlay-container',  
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    }
  }

  //Pull in Drilldown data
  drillDownInit(data, column) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down
    let drillDownDetails = data.data;
    this.displayData.state = drillDownDetails.state;
    this.displayData.recordName = drillDownDetails.recordName;
    this.drillDown = {
      studentIDFilter: {
        stateKey: null,
        districtId: null,
        category: "No Data Submitted",
        category2: "No Data Submitted",
        beginRow: 1,
        endRow: 20,
        sortFields: "MSIX_ID_ASC"
      },
      dataPeriodType: this.filterParameters.dataPeriodType,
      studentDataQualityInfo: {
        state: drillDownDetails.state,
        recordType: drillDownDetails.recordType,
        recordName: drillDownDetails.recordName,
        qualityStatus: "Invalid",
        recordCount: 3
      }
    };
    this.drillDown.exportCount = drillDownDetails.recordCount;
    //show Drill Down - hide Report
    this.showDrill = !this.showDrill;
    this.updateDrilldown(this.drillDown);
    this.setExportData();
  }

  findPath(recordType) {
    var lookup = {
      "Assessments": "validityAssessmentInfo",
      "Course History": "validityCourseHistoryInfo",
      "Enrollments": "validityEnrollmentInfo",
      "Qualifying Moves": "validityQualMoveInfo",
      "Student": "validityStudentInfo",
      "Student Demographic": "validityStudentInfo"
    };
    return lookup[recordType];
  }

  updateDrilldown(drillDown) {
    this.reportDrillDownVar = this.findPath(this.drillDown.studentDataQualityInfo.recordType);
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data.rows.length === 0) {
          this.blankRes = true;
        } else {
          console.log("DataValidity.updateDrilldown(): ", data);
          this.blankRes = false;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - DataValidity.updateDrilldown(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.studentIDFilter.sortFields = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }



  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.dataQuality != true) {
      let arr = [{ key: this.reportInfo.userState }]
      this.filterParameters.stateList = arr;
    }
  }

  // sets filter initially and resets filter when called
  resetFilter() {
    this.filterParameters = {
      dataViewType: "State",
      stateList: [],
      dataPeriodType: "No Period",
      noyes: "no"
    }
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['dataQuality_all', 'dataQuality']);
    
    this.mineAndSetStatesArray(this.reportInfo.stateData);
    this.setSelectedStates();
    // generates report
    this.filterActivate();
  }


  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(responseData[i].stateKey);
        this.filterParameters.stateList.push(obj);
      }
    }
  }

  // Trigger Filter Modal
  filterActivate() {
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.userStateName = JSON.parse(JSON.stringify(this.reportInfo.userStateName));
    this.filterParameters.userState = JSON.parse(JSON.stringify(this.reportInfo.userState));
    this.filterParameters.recentPeriod = JSON.parse(JSON.stringify(this.reportInfo.recentPeriod));
    this.filterParameters.currentPeriod = JSON.parse(JSON.stringify(this.reportInfo.currentPeriod));
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));
    this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));

    this.showFilter = true;
    //Set activeFilter before opening to set back to if not submitting
    this.activeFilter = cloneDeep(this.filterParameters);
    console.log('filter should be showing');
    const dialogRef = this.dialog.open(FilterDataValidity, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.filterParameters = result;
        this.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.selectedStatesArray));
        this.submitFilter();
      } else {
        this.filterParameters = this.activeFilter;
      }
      this.showFilter = false;
    });
  }

  // This function is called when 'Submit' is clicked on the Data Validity report filter popup
  submitFilter() {
    console.log(this.filterParameters)

    if (this.filterParameters.stateList.length === 0) {
      this.blankRes = true;
      this.response = [];
    } else {
      this.submitReport();
    }
  }

  //initialize the report 
  submitReport() {
    this.filterParameters = {
      dataPeriodType: this.filterParameters.dataPeriodType,
      dataViewType: this.filterParameters.dataViewType,
      noyes: this.filterParameters.noyes,
      stateList: this.filterParameters.stateList
    }
    let apiCall = this.reportsService.dataValidityInfo(this.filterParameters);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("DataValidity.reportInit(): ", data);
        this.blankRes = false;
        this.response = data;
        this.sortAscending = true;
        this.currentSortColumn = 'column1';
        this.formatMDEPercentages();
        subscribed.unsubscribe();
      },
      error: error => {
        this.blankRes = false;
        console.log("Error - DataValidity.reportInit(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  formatMDEPercentages(){
    for(let state = 0; state < this.response.rows.length; state++) {
      let data = this.response.rows[state].rows;
      for(let row = 0; row < data.length; row++){
        this.response.rows[state].rows[row].column4.value = parseFloat(data[row].column4.value).toFixed(2);
      }
    }
  }

  setExportData() {
    this.exportFilterParameters = {
      dataPeriodType: this.filterParameters.dataPeriodType,
      dataViewType: this.filterParameters.dataViewType,
      exportType: this.filterParameters.exportType,
      noyes: this.filterParameters.noyes,
      stateList: this.filterParameters.stateList
    }
    var exportPath = this.findPath(this.drillDown.studentDataQualityInfo.recordType);
    exportPath = exportPath + "Export";
    this.exportCall = 'validityDrillDownExport';
    this.exportDrilldownParams = this.drillDown;
    this.exportDrilldownParams.exportPath = exportPath;
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  turnOnNavigationProgress() {
    console.log("navigating");
    this.showNavigationProgress = true;
  }

  turnOffNavigationProgress() {
    this.showNavigationProgress = false;
  }
}

// Separate component for the DataValidity report's filter
export interface FilterParameters {
  params: {
    userState: string,
    authRoleClasses: any,
    fullStateArray: any,
    selectedStatesArray: any,
    statesSelectedFlag: boolean
    dataViewType: any,
    currentPeriod: any,
    recentPeriod: any,
    noyes: any,
    dataPeriodType: any,
    stateList: any
  }
}
@Component({
  selector: 'filterDataValidity',
  templateUrl: './filterDataValidity.html',
  styleUrls: ['./dataValidity.component.scss']
})
export class FilterDataValidity {
  filterParameters: { 
    userState: string,
    authRoleClasses: any,
    fullStateArray: any,
    selectedStatesArray: any,
    statesSelectedFlag: boolean,
    dataViewType: any,
    currentPeriod: any,
    recentPeriod: any,
    noyes: any,
    dataPeriodType: any,
    stateList: any
  };
  states: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<FilterDataValidity>,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
    console.log("DATA passed in for the filter: ", data);
    this.filterParameters = data.params;
    console.log("filterParameters passed in for the filter: ", this.filterParameters);
  }

  resetFilter() {
    this.filterParameters.noyes = 'no';
    this.filterParameters.dataPeriodType = "No Period";
    this.filterParameters.dataViewType = "State"
    this.selectAll();
  }

  selectAll() {
    if (this.filterParameters.authRoleClasses.dataQuality == true) {
      this.filterParameters.selectedStatesArray = this.filterParameters.fullStateArray.map(state => state.key);
    } else {
      this.filterParameters.selectedStatesArray = [{ key: this.filterParameters.userState }]
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.stateList = [];
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    if (this.filterParameters.selectedStatesArray.indexOf(stateKey) > -1) {
      return true;
    } else {
      return false;
    }
  }
  
  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    var statesIndex = this.filterParameters.selectedStatesArray.indexOf(stateKey);
    if (statesIndex === -1) {
      this.filterParameters.selectedStatesArray.push(stateKey);
    } else {
      this.filterParameters.selectedStatesArray.splice(statesIndex, 1);
    }
  }

  onSubmit() {
    if(this.filterParameters.authRoleClasses.dataQuality) {
      this.filterParameters.stateList = [];
      for(let i=0; i<this.filterParameters.selectedStatesArray.length; i++){
        let obj = {
          key: this.filterParameters.selectedStatesArray[i]
        }
        this.filterParameters.stateList[i] = obj; //use i instead of 0
      }
    }
    this.dialogRef.close(this.filterParameters);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  //Filter Logic
  togglePeriodNo() {
    this.filterParameters.noyes = "no";
    this.filterParameters.dataPeriodType = 'No Period';
  }

  togglePeriodYes() {
    this.filterParameters.noyes = "yes";
    this.filterParameters.dataPeriodType = 'Most Recently Closed Period';
  }
}

// Separate component for the DataValidity report's Learn More dialog box
@Component({
  selector: 'dataValidityLearnMore',
  templateUrl: 'dataValidityLearnMore.html',
  styleUrls: ['./dataValidity.component.scss']
})
export class DataValidityLearnMore {
  constructor(
    public dialogRef: MatDialogRef<DataValidityLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the DataValidity report's drilldown Learn More dialog box
@Component({
  selector: 'dataValidityDDLearnMore',
  templateUrl: 'dataValidityDDLearnMore.html',
  styleUrls: ['./dataValidity.component.scss']
})
export class DataValidityDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<DataValidityDDLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}