import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { IssueService } from 'src/app/services/issue.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import 'moment-timezone';
import { Router } from '@angular/router';

@Component({
  selector: 'reports-row',
  templateUrl: './reportsRow.component.html',
  styleUrls: ['./reportsRow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ReportsRowComponent {
  @Input() drilldown: any;
  @Input() rowdata: any;
  @Input() headerinfo: any;
  @Input() columnCount: any;
  @Input() sortColumn: any;
  @Input() multiLeveled: any;
  @Input() stopnavigate: string;
  @Input() enableworklink: string;
  @Input() showcheckbox: any;
  @Input() pfsReport: boolean;
  @Input() emitRowClick: boolean;
  @Input() msixIdShouldNavigate: boolean = true;

  @Output() mycallback = new EventEmitter();
  @Output() checkboxcallback = new EventEmitter();
  @Output() handleMSIXIDClick?: EventEmitter<any> = new EventEmitter();

  activateTooltip: boolean;
  msixHighlight: boolean;
  worklistHighlight: boolean;
  tooltipUnderline: boolean;
  isChecked: boolean;
  isAlreadyChecked: boolean;
  authRoleClasses: any = {};
  constructor(
    private issueService: IssueService, 
    private authService: AuthService,
    private router: Router) {
    this.activateTooltip = false;
    this.msixHighlight = false;
    this.worklistHighlight = false;
    this.tooltipUnderline = false;
    this.isChecked = false;
    this.isAlreadyChecked = false;
    this.pfsReport = false;

    if (this.rowdata != undefined) {
      console.log("Rowinfo: ", this.rowdata);
    }
  }


  ngOnInit() {
    if (this.headerinfo.header1.value === 'MSIX ID' && !this.stopnavigate) {
      this.msixHighlight = true;
    }

    if (this.headerinfo.header7 && this.headerinfo.header7.value === 'Worklist ID' && this.enableworklink) {
      this.worklistHighlight = true;
    }

    if (this.headerinfo.header1.value === 'Logic Rule') {
      this.tooltipUnderline = true;
    }

    if (this.headerinfo.header1.value === 'Logic Issue') {
      this.activateTooltip = true;
    }

    if (this.rowdata.hasCheckedValueSet === undefined) {
      this.rowdata.hasCheckedValueSet = false;
    }
    if (this.rowdata.hasCheckedValueSet === true && this.rowdata.checked === true) {
      this.isAlreadyChecked = true;
      this.isChecked = true;
    }

    this.authService.isRoleClassAuthorized(['dataRequest_ga']).then(
      (res) => {
        this.authRoleClasses = res;
      }
    );
  }


  setIssueTooltip = function (identifier, targetProp, returnProp) {
    return this.issueService.setIssueData(identifier, targetProp, returnProp);
  }

  rowItemClicked = function (row, column, columnName) {
    this.mycallback.emit({
      row: row,
      column: column,
      columnName: columnName
    });
  };

  columnValue = function (colNum) {
    var colValue;
    if (this.rowdata[`column${colNum}`] && this.rowdata[`column${colNum}`].number) {
      colValue = this.getRowData(this.rowdata, this.rowdata[`column${colNum}`], `column${colNum}`);
      colValue = this.rowdata[`column${colNum}`].percentage ? (Math.round(colValue * 100) / 100).toFixed(2) : colValue;
    } else {
      colValue = this.getRowData(this.rowdata, this.rowdata[`column${colNum}`], `column${colNum}`);
    }
    return colValue.toLocaleString('en', {
      useGrouping: true
    });
  }

  clickHandle = function (colNum: number) {
    if (this.rowdata[`column${colNum}`].clickable) {
      this.rowItemClicked(this.rowdata, `column${colNum}`, this.headerinfo[`header${colNum}`].value)
    }
  }

  getColumnWidth = function () {

  }

  getColumnClasses(colNumber: string, headerNum: string) {
    const classes = { column: true };
    classes[`width${this.columnCount}`] = this.multiLeveled;
    classes[`widthfull${this.columnCount}`] = !this.multiLeveled;
    classes['rightJustify'] = this.headerinfo[headerNum].percentOrNum;
    classes['hideBorder'] = this.rowdata.depth > 1 && this.rowdata[colNumber].value === '';
    classes['highlight'] = this.sortColumn === colNumber;
    return classes;
  }
  getRowData = function (row, column, name) {
    // if this row has data, display it
    if (column && column.value) {
      if (column.hasBreak) {
        var strings = column.value.split('BREAK')
        var newValue = strings[0] + "\n" + strings[1]
        return newValue;
      }
      if (column.date && column.value.length > 10) {
        var parsedDate = moment(parseInt(column.value)).tz("America/New_York").format("MM/DD/YYYY");
        return parsedDate;
      }
      if (column.number && column.value > 999) {
        return Number(column.value);
      }
      return column.value;
    }
    if (row && row.column1 && !row.column1.value && !this.drilldown) {
      if (name === 'column1') {
        return '';
      }
      if (!row.column2.value) {
        if (name === 'column2') {
          return '';
        }
        if (!row.column3.value) {
          if (name === 'column3') {
            return '';
          }
          if (!row.column4.value) {
            if (name === 'column4') {
              return '';
            }
          }
        }
      }
    }
    return '-';
  }

  checkBoxClicked = function (rowData) {
    this.isChecked = !this.isChecked;

    this.checkboxcallback.emit({
      row: rowData,
      isChecked: this.isChecked
    });
  };

  onMSIXIDClick(columnValue, colNum) {
    if (this.rowdata[`column${colNum}`].clickable) {
      if (this.emitRowClick) {
        this.handleMSIXIDClick.emit(columnValue);
      } 
      else if (!this.authRoleClasses.dataRequest_ga) {
        const queryParams = { msixId: columnValue };
        const url = `#/studentDetails?${this.serializeParams(queryParams)}`;
        window.open(url);
      }
    }
  }

  private serializeParams(params: { [key: string]: any }): string {
    return Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  }

}
