import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-custom-course-type-control',
    templateUrl: './custom-course-type-control.component.html',
    styleUrls: ['./custom-course-type-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomCourseTypeControlComponent
        }
]
})

export class CustomCourseTypeControlComponent implements OnInit, ControlValueAccessor, OnDestroy{
    private _form: FormGroup = new FormGroup({});
    onTouched: () => {};
    onChangeSub: Subscription;
    
    get form() {
        return this._form;
    }

    courseType = [
        // col 1
        {label: "Other", value: "0"},
        {label: "Pre-Advanced", value: "3"},
        {label: "Not Applicable", value: "7"},

        // col 2
        {label: "Regular (Default)", value: "1"},
        {label: "Advanced Placement", value: "4"},
        {label: "Dual Enrollment", value: "8"},

        // col 3
        {label: "Honors", value: "2"},
        {label: "International Baccalaureate", value: "5"},
        {label: "Concurrent Enrollment", value: "9"},
       
    ] // no value equals 6 [mdev]

    constructor() {
        
    }

    ngOnInit(): void {
      this.courseType.forEach(type => this.form.addControl(type.value, new FormControl(type.value)));
    }

    selectAll() {
        this.courseType.forEach(type => this.form.get(type.value).setValue(type.value))
      }
    
    selectNone() {
        this.courseType.forEach(type => this.form.get(type.value).reset())
    }

    writeValue(value: any): void {
        if (value) {
            this.form.setValue(value)
        }
    }

    registerOnChange(onChange: any): void {
        this.onChangeSub = this.form.valueChanges.subscribe(onChange)
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
       if (isDisabled) {
        this.form.disable();
       } else {
        this.form.enable();
       }
    }

    reset() {
        this.selectAll();
    }
    
    ngOnDestroy(): void {
        this.onChangeSub?.unsubscribe();
    }
}