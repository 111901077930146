<div class="worklist-page">
    <div aria-label="Resolve / Dismiss Notice" class="confirmMergeDialog">
        <form>
            <div class="md-toolbar-tools toolbar">
                <h2 *ngIf="resolveType === 'data'">Resolve</h2>
                <h2 *ngIf="resolveType === 'move'">Dismiss</h2>
                <span flex></span>
                <button class="md-icon-button right-align" (click)="closeDialog()" aria-label="close dialog">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>

            <mat-dialog-content>
                <div class="md-dialog-content">
                    <div class="layout-flex-column" style="flex: 100%">
                        <div>
                            <div class="custom-input-container">
                                <mat-form-field class="md-block md-input-container-margins background-white comment-box">
                                    <mat-label class="custom-label" style="color: #4A4A4A !important;">Comments</mat-label>
                                    <textarea matInput class="comment-text custom-input"
                                        [(ngModel)]="corUpdateDR.message" maxlength="250" rows="2" md-select-on-focus
                                        *ngIf="resolveType === 'data'" name="comments"></textarea>
                                    <textarea matInput class="comment-text custom-input"
                                        [(ngModel)]="corUpdateMN.message" maxlength="250" rows="2" md-select-on-focus
                                        *ngIf="resolveType === 'move'" name="comments"></textarea>
                                    <div class="md-errors-spacer" *ngIf="resolveType === 'data'">
                                        <div class="md-char-counter">{{corUpdateDR.message.length}} / 250</div>
                                    </div>
                                    <div class="md-errors-spacer" *ngIf="resolveType === 'move'">
                                        <div class="md-char-counter">{{corUpdateMN.message.length}} / 250</div>
                                    </div>
                                </mat-form-field>
                            </div>
                            <p class="small worklist-commentbox-instructions" flex>
                                To protect the student's privacy, do not include any sensitive information such as
                                Social Security Numbers or other Personally
                                Identifiable Information in Comments fields. Comments entered will be shared unedited
                                and
                                in their entirety with other MSIX Users.
                            </p>
                        </div>
                    </div>
                    <hr class="thinHr">
                </div>
            </mat-dialog-content>

            <mat-dialog-actions class="layout-flex-row">
                <hr class="thinHr">
                <a (click)="closeDialog()" style="padding-left:15px"><span>Cancel</span></a>
                <span flex></span>
                <button (click)="callResolveRequest()" class="confirmButton md-button uppercase  md-no-focus right-align"
                    *ngIf="resolveType === 'data'" type="submit">Submit</button>
                <button (click)="callResolveNotice()" class="confirmButton md-button top-bottom-margin-8 right-align"
                    *ngIf="resolveType === 'move'" type="submit">Submit</button>
            </mat-dialog-actions>

        </form>
    </div>
</div>