<div class="studentDetails-dialog filter-dialog" [ngClass]="{'filter-tablet': responsiveUI.isTablet}" id="showDialog">
    <div class="reportsFilter filterMSSM">
        <!-- Filter Title -->
        <div class="toolbar">
            <h2>{{isSaved ? 'Edit/Remove Student Record From List' : 'Add Student Record'}}</h2>
            <span flex></span>
            <button class="md-icon-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
            margin-right: 0;">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
        <!--  [formGroup]="form"  -->
        <form (ngSubmit)="onSubmit()" style="overflow: auto;">
          <p *ngIf="!isSaved">
            Select the list(s) to which you would like to add this student record. To create a new list, select “Create New List” and enter a name for the new list.
          </p>
          <p *ngIf="isSaved">Select the lists to which you would like to add the student record.
            De-select the lists that you do not want the student record to be in.
            De-select all lists to remove the student record from all of your lists.
            Use the “Select All” link to select all lists. Use the “Select None” link to de-select all lists.
            To create a new list, select “Create New List” and enter a name for the new list.
          </p>
          <div class="layout-row selectButtons">
            <p>Select</p>
            <a tabindex="0" (click)="selectAll()" (keypress)="selectAll()">All</a>
            <p>|</p>
            <a tabindex="0" (click)="selectNone()" (keypress)="selectNone()">None</a>
          </div>
          <div class="lists">
            <div class="custom-input-container">
              <mat-checkbox (click)="checkNewList()" (keypress)="checkNewList()" [checked]="newListIsChecked || checkedListsTracker.newListName.length >= 1">
                <mat-form-field style="margin-top: -25px" placeholder="Create New List" 
                  class="example-full-width checkbox-label" [ngClass]="{'filter-mobile-input': responsiveUI.isMobile}">
                  <mat-label class="custom-label" [ngClass]="{'checkbox-selected': inputField.focused}">
                    Create New List
                  </mat-label>
                  <input matInput #inputField="matInput" name="Create New List" type="text"
                    [(ngModel)]="checkedListsTracker.newListName" [ngClass]="{'input-selected': inputField.focused}"
                    (input)="selectCreateListCheckbox()">
                </mat-form-field>
              </mat-checkbox>
            </div>
            <div class="error-message">
              <mat-error *ngIf="checkedListsTracker.newListName.length > 60">
                List names must be 60 characters or less.
              </mat-error>
              <mat-error *ngIf="checkCreateListName()">
                List name already exists. Enter a different name.
              </mat-error>
              <mat-error *ngIf="newListIsChecked && !checkedListsTracker.newListName">
                Enter a name for the new list.
              </mat-error>
            </div>
            <div *ngFor="let name of savedListsNames">
              <mat-checkbox (click)="setCheckedLists(name.saveListKey)" (keypress)="setCheckedLists(name.saveListKey)"
              [checked]="existsInCheckedLists(name.saveListKey)" style="font-size: 16px">
                <label style="font-size: 16px">{{name.saveListName}}</label>
              </mat-checkbox>
            </div>
          </div>
        </form> 
        <!-- CHECKBOXES -->
        <!--  Bottom Row -->
        <div class="layout-row filter-row">
          <a tabindex="0" class="resetButton" mat-dialog-close (keypress)="onClose()">
            Cancel
          </a>
            <span flex class="flex"></span>
            <button class="confirmButton md-button border" (click)="onSubmit()" (keypress)="onSubmit()"
            [disabled]="(checkedListsTracker?.checkedLists?.length == 0 && !isSaved && !newListIsChecked) ||
             (newListIsChecked && !checkedListsTracker?.newListName) || listNameExists || checkedListsTracker?.newListName?.length > 60">Submit</button>
        </div>
    </div>
</div>