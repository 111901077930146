<div class="dataValidity" [ngClass]="{'overlay': showNavigationProgress}">
    <div class="breadcrumb layout-row" *ngIf="!showDrill">
        <span><a routerLink="/reports">Reports</a></span>
        <span>></span>
        <span><p>Data Validity</p></span>
    </div>

    <div class="breadcrumb layout-row" *ngIf="showDrill">
        <span><a routerLink="/reports">Reports</a></span>
        <span>></span>
        <span><a (click)="goToReport()">Data Validity</a></span>
        <span>></span>
        <span><p [(ngModel)]="displayData">State: {{displayData.state}} - Invalid Data Element: {{displayData.recordName}}</p></span>
    </div>

    <div class="header layout-row" *ngIf="!showDrill">
      <h1 class="flex-90 header-padding">Data Validity</h1>
      <export-report 
        class="flex-10" 
        tabindex="0"
        [exportlink]="infoExportCall" 
        [exportfilter]="filterParameters"
        [showpdf]="true" 
        [showcsv]="true" 
        [blankres]="blankRes">
      </export-report>
    </div>
    <div class="header layout-row" *ngIf="showDrill">
      <h1 [(ngModel)]="displayData" class="flex-90 header-padding">State: {{displayData.state}} - Invalid Data Element: {{displayData.recordName}}</h1>
      <export-report
        class="flex-10" 
        tabindex="0"
        [exportlink]="exportCall"
        [exportcount]="drillDown.exportCount" 
        [exportfilter]="exportDrilldownParams"
        [showcsv]="true" 
        [blankres]="blankRes">
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="layout-row layout-align-center-center" *ngIf="showDrill">
      <div class="layout-column flex-90">
      <div class="report-summary drill-down-rs layout-row">
        <p>
          The table below lists the migratory children with records containing the invalid data element.
        <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <no-results *ngIf="blankRes && showDrill"></no-results>
      <reports-table
        *ngIf="!blankRes && showDrill"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
        (mycallback)="drillDownInit($event)"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (navigatestart)="turnOnNavigationProgress($event)"
        (navigateend)="turnOffNavigationProgress($event)">
      </reports-table>
      </div>
    </div>

    <div class="layout-row layout-align-center-center" *ngIf="!showDrill">
      <div class="layout-column flex-90">
        <div class="report-summary layout-row">
          <p>
              This report provides the counts and percentages of records with Minimum Data Elements (MDEs) that are invalid in MSIX for your State (default view) and at the national level. 
          <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response" 
          [currentsortcolumn]="currentSortColumn" 
          [sortascending]="sortAscending"
          (mycallback)="drillDownInit($event)"
          (sortbycolumn)="sortByColumn($event)" >
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>
      </div>
    </div>
</div>
