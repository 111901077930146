import * as d3 from 'd3';
import { Component, Input, SimpleChanges} from '@angular/core';
import * as angular from 'angular';

const ChartTypes = Object.freeze({
  QADENROLL: "qadEnrollChart",
  WORKLISTAGING: "worklistAgingChart",
  MEPSTAIR: "mepStairChart",
  PFSPOPULATION: "pfsPopulationChart"
});

// Make sure that the keys match the ChartTypes constant!
const TooltipHeights = {
  "mepStairChart": 50
};

const TooltipWidths = {
  "mepStairChart": 250
};

const DashboardClassName = {
  "mepStairChart": "dashboardDiv"
}

// Reference the examples housed under d3js.org with questions
@Component({
  selector: 'msix-table',
  templateUrl: './msixTable.component.html',
  styleUrls: ['./msixTable.component.scss']
})
  
// Reference the examples housed under d3js.org with questions
export class MsixTableComponent {
  // Gets info from Angular bindings, data is coming from the API as a DatabaseWrapper class containing the majority of chart info
  @Input() chart: any;
  @Input() data: any;
  @Input() id: any;

  constructor() {}

  ngOnInit() {
    this.paint();
  }
  // Call the paint function, add listeners for resize or if the chart attribute changes value (filter change)
  
  ngOnChanges(changes: SimpleChanges) {
    window.addEventListener("resize", this.paint);
    this.paint();
  }

  // Function for building the full chart
  paint() {
    d3.select("#" + this.id).selectAll("*").remove();

    var height = window.innerHeight / 1.8 > 500 ? window.innerHeight / 1.8 : 500;
    var width = document.getElementById("chart-section").offsetWidth > 800 ? document.getElementById("chart-section").offsetWidth : 800;
    if (this.chart == ChartTypes.MEPSTAIR) {
      height = window.innerHeight;
      width = document.getElementById("chart-section").offsetWidth > 2000 ? document.getElementById("chart-section").offsetWidth : 2000;
    }
    var margin = resolveMargin(this.chart, height, width);
    var xValues = resolveXValues(this.data);
    var yValues = resolveYValues(this.data);
    var columnFormat = resolveFormats(this.chart);
    var boxColor = resolveBoxColors(this.chart);
    var textColor = resolveTextColor(this.chart);
    var legendLabels = resolveLegendLabels(this.chart);
    var legendMap = resolveLegendMap(this.data);
    var data = resolveData(this.chart, this.data);
    var missingValue = resolveMissingValue(this.chart);

    var x = d3.scaleBand()
      .domain(xValues)
      .rangeRound([0, width - margin.left - margin.right])
      .padding(0.01);

    var xAxis = g => g
      .attr("transform", `translate(${margin.left}, ${(this.chart == ChartTypes.MEPSTAIR) ? 15 : 0} )`)
      .call(d3.axisBottom(x).tickFormat(d => {
        if (this.chart == ChartTypes.MEPSTAIR)
          return d;
        return legendMap[d];
      }))
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").remove())
      .selectAll(".tick text")
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .call(wrap, x.bandwidth() * .9);
        /*d => {
        return d.bandwidth() * .9;
        /*if (this.chart == ChartTypes.MEPSTAIR){
        console.log("MEPSTAIR chart - x.bandwidth: ", x.bandwidth());
          return x.bandwidth();
        } else {
          console.log("MEPSTAIR chart - x.bandwidth * .9: ", x.bandwidth() * .9);
          return x.bandwidth() * .9;
        }
      });*/

    var y = d3.scaleBand()
      .domain(yValues)
      .range([0, height - margin.top - margin.bottom])
      .padding(0.01);

    var yAxis = g => g
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .call(d3.axisLeft(y).tickSizeOuter(0))
      .call(g => g.selectAll(".tick line").remove())
      .call(g => g.select(".domain").remove())
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .selectAll(".tick text")
      .attr("font-weight", d => {
        if (this.chart == ChartTypes.PFSPOPULATION && d == "Annual Totals")
          return "bold";
        return;
      });

    // Define the div for the tooltip
    var div = d3.select("#" + this.id).append("div")
      .attr("class", "linechart-tooltip")
      .attr("overflow", "auto")
      .style("opacity", 0);

    var horizShift = 0
    if (window.innerWidth < 1100) {
      horizShift = 220;
    }

    // The actual svg element of the chart
    var svg = d3.select("#" + this.id).append('svg')
      .attr("class", "dashboard-svg")
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible");

    var dataG = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .attr("height", height)
      .attr("width", width);

    var tableG = dataG.append("g"); // The rectangles that make up the table

    tableG.selectAll()
      .data(data)
      .enter()
      .append("rect")
      .attr("stroke", "#BDBDBD")
      .attr("x", function (d) { return x(d.subcategory) })
      .attr("y", function (d) { return y(d.category) })
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", d => {
        return resolveBoxFill(this.chart, d, boxColor);
      })
      .style("mask", d => {
        if (this.chart == ChartTypes.WORKLISTAGING && d.subcategory == "responseTime" && d.value > 4)
          return "url(#worklist-stripe-mask)";
        return "";
      })
      .on("pointerover", (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        if (this.chart == ChartTypes.MEPSTAIR && d.value != 0 && d.percent != "") {
          div.transition()
            .duration(200)
            .style("opacity", .9);

          let xCoor = event.pageX + horizShift - 100;
          let width = document.getElementById(DashboardClassName[this.chart]).offsetWidth;

          if (xCoor + (TooltipWidths[this.chart]) > width * .99) // If the tooltip will display off screen, display it to the left of the mouse
            xCoor -= TooltipWidths[this.chart];

          div.html(resolveHover(this.chart, d))
            .style("left", xCoor + "px")
            .style("top", (event.pageY) + "px")
            .style("height", TooltipHeights[this.chart] + "px")
            .style("width", TooltipWidths[this.chart] + "px")
            .style("position", "absolute") // had to add styles from "linechart-tooltip" class to here specifically, class wasnt being applied
            .style("text-align", "center")
            .style("font", "12px sans-serif")
            .style("border", "0px")
            .style("padding", "2px")
            .style("background", "lightsteelblue")
            .style("border-radius", "8px")
            .style("pointer-events", "none");
        }
        return;
      })
      .on("pointerout", (event, d) => {
          if (event.pointerType == "touch") {
            event.preventDefault();
            event.stopPropagation();
            return;
          }

          if (this.chart == ChartTypes.MEPSTAIR && d.value != 0 && d.percent != "") {
            div.transition()
              .duration(500)
              .style("opacity", 0);
          }
      })
      .on("pointermove", (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        if (this.chart == ChartTypes.MEPSTAIR && d.value != 0 && d.percent != "") {
          let xCoor = event.pageX + horizShift - 100;
          let width = document.getElementById(DashboardClassName[this.chart]).offsetWidth;

          if (xCoor + (TooltipWidths[this.chart]) > width * .99)
            xCoor -= TooltipWidths[this.chart];

          div.style('top', (event.pageY - 60) + 'px')
            .style("left", xCoor + 'px');
        }
      })
      .on("touchend touchstart", (event, d) => {
        event.preventDefault();
      });

    var textG = dataG.append("g"); // The internal text of the table

    textG.selectAll("text")
      .attr("transform", `translate(${0},0)`)
      .attr("color", "#000000")
      .data(data)
      .join("text")
      .attr("pointer-events", "none")
      .attr("x", function (d) {
        return x(d.subcategory) + x.bandwidth() / 2
      })
      .attr("y", function (d) {
        return y(d.category) + y.bandwidth() / 2 + 5
      })
      .attr("text-anchor", "middle")
      .text((d, i) => {
        if (this.chart == ChartTypes.MEPSTAIR) {
          if (d.subcategory == "Retained Percentage") {
            if (d.category == "UG" || d.category == "00" || d.category == "Unknown" || d.category == "Total" || d.category == "PGroup")
              return "N/A";
        }
          else if (d.value == 0) {
            return;
          }
        }
        if (d.value == missingValue)
          return "-";
        if (columnFormat.length == 1) {
          return d3.format(columnFormat[0])(d.value);
        }
        return d3.format(columnFormat[xValues.indexOf(d.subcategory)])(d.value);
      })
      .attr("fill", d => resolveTextFill(this.chart, d, textColor))
      .attr("font-size", d => {
        return "16px";
      })
      .attr("font-weight", d => {
        if (this.chart == ChartTypes.PFSPOPULATION && d.category == "Annual Totals")
          return "bold";
        return;
      });

    svg.append("g")
      .call(xAxis);

    svg.append("g")
      .call(yAxis);

    if (this.chart == ChartTypes.WORKLISTAGING) {
      svg.append("g")
        .call(resolveLegend, this.chart, boxColor, legendLabels);
    }
    if (this.chart == ChartTypes.MEPSTAIR) {
      svg.append("g")
        .call(resolveLegend, this.chart, boxColor.slice(1), legendLabels);

      // Y Axis Label
      svg.append("text")
        .attr("class", "axis")
        .attr("transform", "rotate(-90)")
        .attr("y", 0)
        .attr("x", -((height - margin.bottom + margin.top) / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "15px")
        .style("color", "#4A4A4A")
        .text(this.data.y);

      // X Axis Label
      svg.append("text")
        .attr("class", "axis")
        .attr("y", 0)
        .attr("x", margin.left + (width - margin.left - margin.right) / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "15px")
        .style("color", "#4A4A4A")
        .text(this.data.x);
    }

    function resolveLegend(svg, chartType, color, label) {
      let alignment = 0;
      if (chartType == ChartTypes.WORKLISTAGING)
        alignment = -55
      const g = svg
        .attr("transform", `translate(${margin.left + 20},${height - color.length * 25 + 5 + alignment})`)
        .attr("text-anchor", "start")
        .attr("class", "legend")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "16px")
        .style("color", "#4A4A4A")
        .selectAll("g")
        .data(color)
        .join("g")
        .attr("transform", (d, i) => `translate(${0},${i * 25})`);

      g.append("rect")
        .attr("x", 0)
        .attr("width", 19)
        .attr("height", 19)
        .attr("fill", d => {
          return d;
        })
        .style("mask", (d, i) => {
          if (chartType == ChartTypes.WORKLISTAGING && i == 1)
            return "url(#worklist-stripe-mask)";
          return "";
        });


      g.append("text")
        .attr("x", 25)
        .attr("y", 9.5)
        .attr("dy", "0.35em")
        .text((d, i) => label[i]);
    }

    function resolveMargin(chartType, chartHeight, chartWidth) {
      let top = chartHeight == 500 ? chartHeight / 5.5 : 70;

      if (chartType == ChartTypes.WORKLISTAGING)
        return { top: top, right: 20, bottom: 150, left: 70 };

      if (chartType == ChartTypes.PFSPOPULATION) {
        if (chartWidth > 1000)
          return { top: top, right: 20, bottom: 20, left: 70 }
        return { top: top + 15, right: 20, bottom: 20, left: 115 }
      }

      if (chartType == ChartTypes.MEPSTAIR)
        return { top: 60, right: 20, bottom: 130, left: 70 };

      return { top: top, right: 20, bottom: 20, left: 70 };
    }

    function resolveXValues(fullData) {
      return fullData.groupCategories;
    }

    function resolveYValues(fullData) {
      // Yes this is supposed to grab the x-values
      return fullData.xValues;
  }

    function resolveFormats(chartType) {
      if (chartType == ChartTypes.QADENROLL)
        return [",", ",", ",", ","];
      if (chartType == ChartTypes.WORKLISTAGING)
        return [",", ",", ".2f", ",", ".2f"];
      if (chartType == ChartTypes.MEPSTAIR)
        return [",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ",", ".0%"];


      return [","];
    }

    function resolveData(chartType, fullData) {
      let adjustedData = JSON.parse(JSON.stringify(fullData.data[0]));
      for (let point of adjustedData) {
        point.value = Number(point.value);
      }

      if (chartType == ChartTypes.MEPSTAIR) {

        // Currently the API ordering means that the total total is at position 449
        // If the percentage breaks, check what array index total total is at and adjust
        let totalTotalValue = adjustedData[449].value;
        for (let point of adjustedData) {
          if (point.category != "Total" && point.subcategory != "Total" && point.subcategory != "Retained Percentage") {
            if (totalTotalValue == 0)
              point.percent = 0;
            else
              point.percent = point.value / totalTotalValue;
          } else if (point.subcategory == "Retained Percentage") {
              point.value /= 100;
              point.percent = "";
          } else if (point.subcategory == "Total" || point.category == "Total") {
              point.percent = "";
          }
        }
      }

      return adjustedData;
    }

    function resolveLegendMap(fullData) {
      return fullData.legendMap;
    }

    // Wraps text utilizing tspans
    function wrap(text, width, sizeChange) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word = words.pop();
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word != undefined) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            // counter++;
          }
          word = words.pop();
        }
      });
    }

    function resolveBoxColors(chartType) {
      if (chartType == ChartTypes.WORKLISTAGING)
        return ["#2CA02C", "#F0D0D0"];

      // Color index 0 is "empty", IE the neutral background color
      // If the neutral background changes, index 0 should be adjusted to reflect that
      if (chartType == ChartTypes.MEPSTAIR)
        return ["#FAFAFA", "#439F19", "#FCF1D4", "#73260D", "#8B8B79", "#000000"];
  }

    function resolveTextColor(chartType) {
      if (chartType == ChartTypes.MEPSTAIR)
        return ["#000000", "#000000", "#000000", "#FFFFFF", "#000000", "#FFFFFF"];
      return ["#000000"];
    }

    function resolveBoxFill(chartType, element, colors) {
      if (chartType == ChartTypes.WORKLISTAGING && element.subcategory == "responseTime") {
        if (element.value <= 4)
          return colors[0];
        return colors[1];
      }

      if (chartType == ChartTypes.MEPSTAIR) {
        return colors[element.additionalCategory];
      }

      return "#FAFAFA";
    }

    function resolveTextFill(chartType, element, colors) {
      if (chartType == ChartTypes.MEPSTAIR) {
        return colors[element.additionalCategory];
      }
      return colors[0];
    }

    function resolveLegendLabels(chartType) {
      if (chartType == ChartTypes.WORKLISTAGING)
        return ["In Compliance", "Out of Compliance"];
      if (chartType == ChartTypes.MEPSTAIR)
        return ["On Target", "One-Year Variation", "Two-Year Variation", "Three-Year Variation", "Likely Data Error"];
    }

    function resolveHover(chartType, element) {
      if (chartType == ChartTypes.MEPSTAIR) {
        if (element.percent == "")
          return;
        return "Percent of Migratory Children: " + d3.format(".2%")(element.percent);
      }
    }

    function resolveMissingValue(chartType) {
      return -1;
    }
  }

}