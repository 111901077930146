<div class="generalMoveFrom mobileReport">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p>General Move From</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a (click)="goToReport()">General Move From</a></span>
    <span>></span>
    <span>
      <p [(ngModel)]="displayData" name="displayData" ngDefaultControl>Moved From {{displayData.category}}: Moved To {{displayData.subcategory}}</p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 class="flex-90 header-padding">General Move From</h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="exportCall"
      [exportfilter]="filterParameters"
      [showpdf]="true" 
      [showcsv]="true"
      [blankres]="blankRes">
    </export-report>
  </div>

  <div class="header layout-row" *ngIf="showDrill">
    <h1 class="flex-90 header-padding" [(ngModel)]="displayData.subcategory" name="subcategory" ngDefaultControl>
      Moved From {{displayData.category}}:
      Moved To {{displayData.subcategory}}
    </h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="drillExportCall"
      [exportcount]="drillDown.exportCount" 
      [exportfilter]="drillDown" 
      [showpdf]="false" 
      [showcsv]="true" 
      [blankres]="blankRes">
    </export-report>
  </div>

  <hr layout-padding class="thickHr" *ngIf="!responsiveUI.isMobile">

  <div class="layout-row layout-align-center-center" *ngIf="showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary drill-down-rs layout-row">
        <p>
          The table below lists migratory children who have moved from your State to the selected State.
          <a class="learn-more"
          tabindex="0" (keyup.enter)="learnMoreActivate()"
           (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <no-results *ngIf="blankRes && showDrill"></no-results>
      <reports-table *ngIf="!blankRes && showDrill" 
        [responsedata]="drillDownData"
        (mycallback)="drillDownInit($event)" 
        (drilldownsort)="handleDrillDownSortClick($event)"
        [doubleHeader]="doubleHeader" 
        [qadToggle]="qadToggle" 
        [shownavigation]="true"
        [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}">
      </reports-table>
    </div>
  </div>

  <div class="layout-row layout-align-center-center" *ngIf="!showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p *ngIf="!isMobile">
          This report displays the counts of moves by migratory children who have moved from your State to another
          State, based on either enrollment records (default view) or qualifying moves.
          <a class="learn-more" tabindex="0" (keyup.enter)="learnMoreActivate()" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="isMobile">
          This report displays the counts of moves by migratory children who have moved from your State to another
          State, based on either enrollment records (default view) or qualifying moves.
        </p>
      </div>

      <div *ngIf="!isMobile">
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response" 
          (mycallback)="drillDownInit($event)"
          (sortbycolumn)="sortByColumn($event)" 
          [currentsortcolumn]="currentSortColumn"
          [sortascending]="sortAscending">
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>
      </div>

      <!--**********************MOBILE VIEW*************************-->
      <div class="mobile-view layout-column" *ngIf="isMobile && (!progressActivated && !indicator)">
        <label class="input-label">Primary State:</label>
        <!--State dropdown. Hidden when not superuser-->
        <mat-form-field *ngIf="reportInfo.authRoleClasses.studentMobility_all">
          <mat-select [(ngModel)]="mobileSelectedState" style="padding-left: 5px;" name="stateName" ngDefaultControl>
            <mat-option *ngFor="let state of states" [value]="state.name" (click)="mobileStateDropdown(state.stateKey, state.name, true)">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--*ngIf="authRoleClasses.studentInfo && filterParameters.ping=='State'"-->
        <div *ngIf="!reportInfo.authRoleClasses.studentMobility_all">
          <div style="font-family: 'roboto_slabregular', serif; font-size: 16px">{{mobileSelectedState}}</div>
          <br /><br />
        </div>

        <br>

        <label class="input-label">Secondary State:</label>
        <mat-form-field>
          <mat-select [(ngModel)]="mobileSelectedState2" style="padding-left: 5px;" name="state2Name" ngDefaultControl>
            <mat-option [value]="'- All -'" (click)="mobileStateDropdown2(0, '- All -', true)">- All -</mat-option>
            <mat-option *ngFor="let state2 of states" [value]="state2.name" (click)="mobileStateDropdown2(state2.stateKey, state2.name, true)">
              <div *ngIf="mobileSelectedState != state2.name">
                {{state2.name}}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="report-summary">
          <p><b>Current performance period</b></p>
          <p>{{reportInfo.currentPeriod}}</p>
        </div>

        <div class="stats-wrapper counts">
          <div *ngFor="let stat of response.mobileInfos">
            <div class="stats-item">
              <div class="label">
                {{stat.labelName}}
              </div>
              <div class="value">
                {{stat.value}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--***********************************************************************************-->

    </div>
  </div>
</div>