import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants }  from '../config/index.constants';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router'; 
import { roleClasses } from '../config/index.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;
  //private acUserRoles = AppConstants.roleClasses;
  private apiUrl = AppConstants.api;
  private router: string;
  private timeoutFlag: boolean;
  private currUser: any;
  private isRoleAuthorized: {};
  private authRoles: {};
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private cookieService: CookieService,
    private _router: Router) {
    
    this.router = _router.url;
    this.timeoutFlag = false;
    this.currUser = {
      betaUser: false,
      roles: []
    };
  }

  getAuthRoles() {
    return this.authRoles;
  }

  setAuthRoles(authorizedRoles) {
    this.authRoles = authorizedRoles;
  }
  
  // TODO - Is this being called from anywhere?
  getCurrentUser() {
    console.log('auth.service.getCurrentUser() - Am I being called?');
    // Get current user data.
    this.userService.getCurrentUser().then( data => {
      this.currUser = data;
    });

    return this.currUser;
  }

  clearData() {
    this.cookieService.removeAll();
    sessionStorage.clear();
    localStorage.clear();
    history.pushState(null, '')
  }

  clearHistoryState() {
    history.replaceState(null, '', window.location.href);
    window.addEventListener('popstate', () => {
      history.pushState(null, '', window.location.href)
    })
  }

  redirectToPrivacy(shouldRedirect:boolean = true) {
    if (!shouldRedirect) {
      this.http.post<any>(this.apiUrl + 'logout', {}).subscribe(() => {
        window.location.reload();
      })
    } else {
      this.http.post<any>(this.apiUrl + 'logout', {}).subscribe(() => {
        this._router.navigate(['/'], {replaceUrl: true})
        .then(() => {
                window.location.reload();
          })
      });
    }
  }
  
  logout(shouldRedirect: boolean = true): void {
    this.clearData();
    this.redirectToPrivacy(shouldRedirect)
    this.clearHistoryState();
    localStorage.setItem('logout-event', 'logout-'+ Math.random())
  }

  setTimeoutFlag(status: boolean) {
    this.timeoutFlag = status;
  }

  getTimeoutFlag() {
    return this.timeoutFlag;
  }

  isAuthenticated() {
    if (this.cookieService.getObject('user')) {
      return true;
    }
    return false;
  }
  
  isAuthorized(authorizedRoles) {
    if (!Array.isArray(authorizedRoles)) {
      authorizedRoles = [authorizedRoles];
    }
    if (!this.isAuthenticated()) {
      return false;
    }
    var roleAuthorized = false;
    
    let o = JSON.parse(this.cookieService.get('user'));
    let roles: string[] = JSON.parse(o.roles);

    for (var role in roles) {
      if (authorizedRoles.indexOf(roles[role]) !== -1) {
        return true;
      }
    }
    return roleAuthorized;
  }

  async isRoleClassAuthorized(roleClassArray): Promise<any> {

    // Get current user data.
    await this.userService.getCurrentUser().then( data => {
      this.currUser = data;
    });

    console.log("Authservice isRoleClassAuthorized(): ", this.currUser);

    this.isRoleAuthorized = await this.isRoleClassAuthorizedHelper(roleClassArray);
    return this.isRoleAuthorized;
  }

  private isRoleClassAuthorizedHelper(roleClassArray) {
    var isAuthorizedForClasses = {};
    for (var roleClass in roleClassArray) {
      var authorizedRoles = roleClasses[roleClassArray[roleClass]];
      if (authorizedRoles) {
        for (var role in this.currUser.roles) {
          if (authorizedRoles.indexOf(this.currUser.roles[role]) !== -1) {
            isAuthorizedForClasses[roleClassArray[roleClass]] = true;
            break;
          }
        }
      }
    }
    return isAuthorizedForClasses;
  }

  isBetaUser(){
    return this.currUser.betaUser;
  }

  init() {}
}