<div class="mepPopulation">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
        margin-right: 0;">        
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.dataAdmins">
        <b>MEP Age-for-Grade Breakdown</b><br/>
        <br/>
        This chart displays all migratory children with open enrollments in your State in the selected performance 
        period color-coded to indicate which children are on-target or at-risk based on their age and the grade 
        they are enrolled in. The “Retained” column contains the percentage of migratory children in each grade 
        who were enrolled in the same grade in the previous performance period. Use this chart to understand the 
        age and grade progression of your migratory population, identify records with likely data errors, and 
        understand your retention rate by grade. Data in this chart is refreshed on the first day of every month.<br/>
        <br/>
        <b>PFS, EL, and IEP Populations</b><br/>
        <br/>
        This chart displays every enrollment made in your State in each month of the selected performance period 
        and shows how many of those enrollments were submitted with PFS, EL, or IEP indicators of “Yes”. Use this 
        chart to understand the enrollment rates of students who receive these services as well as how many enrollments 
        have two of these indicators in order to better understand the needs of your State’s population. Data in this 
        chart is refreshed on the first day of every month.
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>MEP Age-for-Grade Breakdown</b><br/>
        <br/>
        This chart displays all migratory children with open enrollments in all States in the selected performance 
        period color-coded to indicate which children are on-target or at-risk based on their age and the grade 
        they are enrolled in. The “Retained” column contains the percentage of migratory children in each grade 
        who were enrolled in the same grade in the previous performance period. Use this chart to understand the 
        age and grade progression of the national migratory population, identify records with likely data errors, 
        and understand retention rates by grade. Data in this chart is refreshed on the first day of every month.<br/>
        <br/>
        <b>PFS, EL, and IEP Populations</b><br/>
        <br/>
        This chart displays every enrollment made in all States in each month of the selected performance period 
        and shows how many of those enrollments were submitted with PFS, EL, or IEP indicators of “Yes”. Use this 
        chart to understand the enrollment rates of students who receive these services as well as how many enrollments 
        have two of these indicators in order to better understand the needs of the national migratory population. 
        Data in this chart is refreshed on the first day of every month.
      </p>
    </div>
  </div>
</div>