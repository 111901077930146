import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatRadioChange } from '@angular/material/radio';
import { BehaviorSubject, Observable, Subscription, combineLatest, debounce, debounceTime, distinctUntilChanged, filter, map, mapTo, of, startWith, tap } from 'rxjs';
import { ReportsService } from 'src/app/services/reports.service';
import { DrillDownItem, UserService } from 'src/app/services/user.service';

export enum DRILL_DOWN_LVL {
    STATE = 'State',
    NATIONAL = 'National',
    REGION = 'Region',
    DISTRICT = 'District',
    SCHOOL = 'School'
}

@Component({
    selector: 'app-custom-drill-down-level-control',
    templateUrl: './custom-drill-down-level-control.component.html',
    styleUrls: ['./custom-drill-down-level-control.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: CustomDrillDownLevelControlComponent}
    ]
})
export class CustomDrillDownLevelControlComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input('user') user: any // need to type [mdev]
    @Output() displayByEvent = new EventEmitter<any>();
    regions: DrillDownItem [] = []
    schools:DrillDownItem [] = []; 
    districts: DrillDownItem[] = [];
    searchControl = new FormControl();
    filteredCollection$: Observable<any>;
    sub = new Subscription();
    selected = new Set<DrillDownItem>();
    collection: DrillDownItem[] = [];
    collectionChangeSource = new BehaviorSubject<DrillDownItem[]>([])
    form: FormGroup;
    private onChange: (value: DrillDownItem[]) => void = () => {};
    private onTouched: () => void = () => {};
    displayBySchoolBoolean: boolean = false;

    get rptGrping(){
        return this.form?.get('rptGrping')?.value;
    }
    constructor(private fb: FormBuilder, private userService: UserService, private reportsService: ReportsService ) {
        this.sub.add(userService.getRegionsFromState().subscribe(regions => this.regions = regions));
        this.sub.add(userService.getDistrictsFromState().subscribe(districts => this.districts = districts));
        this.sub.add(userService.getSchoolsFromState().subscribe(schools => this.schools = schools))

        
        const searchValue$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            // distinctUntilChanged(),
            // map(value => this.collection.filter(item => item.description.toLowerCase().indexOf(value.toLowerCase()) !== -1)),
        )

        const collection$ = this.collectionChangeSource.asObservable();

        this.filteredCollection$ = combineLatest([searchValue$, collection$]).pipe(
            map(([searchValue, collection]) => {
                if (searchValue.trim().length < 1) {
                    return []
                }
                const filtered = collection.filter(item => item.description.toLowerCase().indexOf(searchValue.toLowerCase())!== -1 && !this.selected.has(item));
                return filtered.length > 0 ? filtered : [{description: `No value matching "${searchValue}" was found.`}]
                }
            )
        )
    }

    ngOnInit(): void {
        this.buildForm();
        this.prefillForm();
    }
    
    writeValue(selectedItems: DrillDownItem[] ): void {
        if (selectedItems) {
            this.selected = new Set<DrillDownItem>(selectedItems);
          }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    buildForm() {
        this.form = this.fb.group({
            rptGrping: ["State"],
            dataByDist: [false],
            dataBySchool: [false],
        })
    }

    prefillForm() {
        if (this.reportsService.drillDownLevelControlValues) {
            this.form.setValue(this.reportsService.drillDownLevelControlValues.formValues);
            this.selected = this.reportsService.drillDownLevelControlValues.selected;
            this.collection = this.rptGrping === DRILL_DOWN_LVL.DISTRICT ? this.districts: this.rptGrping === DRILL_DOWN_LVL.REGION ? this.regions : this.schools;
        }
    }

    reset() {
        this.form.get('rptGrping').setValue('State');
        this.form.get('dataByDist').setValue(false);
        this.form.get('dataBySchool').setValue(false);
        this.onSelectNone();
    }
    
    onOptionSelected(option: MatAutocompleteSelectedEvent) {
        const item = this.collection.find(item => item.description === option.option.value);
        this.selected.add(item);
        this.searchControl.setValue('');
        this.onChange(Array.from(this.selected));
        this.onTouched();
    }

    trackByKey(_, item: DrillDownItem) {
        return (item.id ?? "" )+'|'+item.description;
    }

    onDrillDownLevelChange(selection: MatRadioChange) {
        if (selection.value !== 'District') {
            this.form.get('dataBySchool').setValue(false)
        }
        if (selection.value !== 'Region') {
            this.form.get('dataByDist').setValue(false);
        }
        this.onSelectNone();
        this.searchControl.setValue('');
        if (this.rptGrping === DRILL_DOWN_LVL.STATE || this.rptGrping === DRILL_DOWN_LVL.NATIONAL) {
            return;
        }
        this.collection = this.rptGrping === DRILL_DOWN_LVL.DISTRICT ? this.districts: this.rptGrping === DRILL_DOWN_LVL.REGION ? this.regions : this.schools;
        this.collectionChangeSource.next(this.collection);
    }

    onSelectionRemove(item: DrillDownItem) {
        this.selected.delete(item);
        this.onChange(Array.from(this.selected));
    }

    onSelectAll() {
        for (const item of this.collection) {
            this.selected.add(item);
        }
    }

    onSelectNone() {
        this.selected.clear();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
   
}