import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { AppConstants } from '../config/index.constants';
import { IdleService } from './idle.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = AppConstants.api;
  userObj: {
    roles: string,
    name: string,
    username: string,
    privacyFlag: boolean,
    robFlag: boolean,
    isOTP: boolean,
    betaUserFlag: boolean,
    passwordExpirationWarn: boolean
  };
  USER_CREDS_SESSION_ATTRIBUTE_NAME = 'usercreds';

  constructor(
    private http: HttpClient,
    private idleService: IdleService,
  ) {
      this.userObj = {
        roles: '',
        name: '',
        username: '',
        privacyFlag: false,
        robFlag: false,
        isOTP: false,
        betaUserFlag: false,
        passwordExpirationWarn: false};
    }

  login(credentials): Observable<any> {
    let params = {
      'isMFA': credentials.isMFA,
      'mfaCode': credentials.mfaCode,
      'showRegistrationDecision': credentials.showRegistrationDecision,
      'isRegistrationWorkflow': credentials.isRegistrationWorkflow,
      'needQRCode': credentials.needQRCode,
      'verifyQRCode': credentials.verifyQRCode
    };
    return this.http.get(this.apiUrl + 'user', {params}).pipe(
      tap(() => {
        this.idleService.startWatching()
      })
    );
  }
}