import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'admin-search-results',
  templateUrl: './adminSearchResults.component.html',
  styleUrls: ['./adminSearchResults.component.scss']
})
export class AdminSearchResultsComponent implements OnInit {

  private stateKey: number;
  private district: number;
  header: string;
  adminList: Array<any>;
  private errors: Array<any>;
  
  constructor(private route: ActivatedRoute,
    private _State: StateService,
    private _User: UserService,
    private _Toast: ToastService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {

    this.adminList = new Array();

    this.route.params.subscribe(params => {
      console.log(params);
      this.stateKey = params.stateKey;
      this.header = params.header;
      this.district = params.district;
    });
  }

  ngOnInit(): void {
    let apiCall = this._State.getDistrictAdmins(this.stateKey, this.district);
    let subscribed = apiCall.subscribe({
      next: dt => {
        console.log(dt);
        this.adminList = dt;

        subscribed.unsubscribe();
      },
      error: error => {
        let errMsg = "";

        if (error.error.developerMessage === "") {
          errMsg = error.error.message;
          this.errors = ["Error retrieving district administrator list. Please contact the MSIX Help Desk if you keep getting this error."];
        } else {
          this.errors = errMsg = error.error.developerMessage;
        }
        console.error(errMsg);
        this._Toast.showToast(this.errors, 0);

        subscribed.unsubscribe();
      }
    });
  }

  goBack() {
    this._router.navigate(['pwReset'], {
      queryParams: {
        header: this.header
      }
    });
  }
  
}
