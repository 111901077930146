
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, of, Subscription, tap } from 'rxjs';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { ToastService } from 'src/app/services/toast.service';
import { RequiredIfCheckedValidator } from '../../validators/required-if-checked';
import { ListNameExistsValidator } from '../../validators/list-name-exists.validator';
import { ListItem } from './types';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface IAddEditRemoveStudentFromListDialogData {
  currentlySavedLists: number[],
  isSaved: boolean,
}

export interface IStudentListsUpdatePayload {
  msixID: string,
  newListName: string,
  savedListKeys: number[],
  unsavedListKeys: number[]

}

@Component({
    selector: 'app-add-edit-student-to-list-dialog.component',
    styleUrls: ['./add-edit-student-to-list-dialog.component.scss'],
    templateUrl: './add-edit-student-to-list-dialog.component.html'
})

export class AddEditStudentToListDialogComponent implements OnInit, OnDestroy {
  savedLists: ListItem[] = [];
  newCheckedLists = new Set<number>();
  uncheckedLists = new Set<number>();
  prevSavedLists = new Set<number>();
  intermediateCheckedState = new Set<number>();
  subs = new Subscription();

  form: FormGroup = this.fb.group({
      newListName: new FormControl('', {
        validators: [Validators.maxLength(60)]
      }),
      newListNameCheck: new FormControl(false)
  }, {
  })
  
  constructor(
    public dialogRef: MatDialogRef<AddEditStudentToListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddEditRemoveStudentFromListDialogData,
    private savedListService: SavedListService,
    private toastService: ToastService,
    private fb: FormBuilder,
    public responsiveUIService: ResponsiveUIService,
  ) {
    this.getSavedListsNames();
    this.prevSavedLists = new Set(data.currentlySavedLists);
    this.intermediateCheckedState = new Set(data.currentlySavedLists);
  }
  
  ngOnInit(): void {
    this.form.get('newListName').addValidators([RequiredIfCheckedValidator(this.form)]);
  }

  setCheckedLists(evt: MatCheckboxChange, saveListKey: number) {
    if (evt.checked) {
      this.intermediateCheckedState.add(saveListKey);
      if (!this.prevSavedLists.has(saveListKey)) {
        this.newCheckedLists.add(saveListKey);
      }
    } else {
     this.intermediateCheckedState.delete(saveListKey)
      if (this.prevSavedLists.has(saveListKey)) {
        this.uncheckedLists.add(saveListKey);
      }
    }
  }

  
  getSavedListsNames() {
    this.savedListService.getSaveListsNames().pipe(
        // currently api returns My Default List as the last item in the array. this is a work around, as we need to display this as the second item on the UI.
        tap(listNames =>  {
          this.savedLists = [listNames.at(-1), ...listNames.slice(0, -1)];
          this.form.get('newListName').addValidators([ListNameExistsValidator(this.savedLists.map(item => item.saveListName))]);
        }),
        catchError((err) => {
            this.toastService.showToast(err, 0);
            return of(null)
        })
    ).subscribe();
  }

  
  selectAll() {
    for (let list of this.savedLists) {
      this.setCheckedLists({checked: true} as MatCheckboxChange, list.saveListKey);
    }
  }

  selectNone() {
    for (let list of this.savedLists) {
      this.setCheckedLists({checked: false} as MatCheckboxChange, list.saveListKey);
    }
  }

  onSubmit(): void {
    const payload: Omit<IStudentListsUpdatePayload, 'msixID'> = {newListName: this.form.get('newListName').value, savedListKeys: [...this.newCheckedLists], unsavedListKeys: [...this.uncheckedLists]}
    this.dialogRef.close(payload);
  }

  handleNewListFocus() {
    this.form.get('newListNameCheck').setValue(true)
  }

  handleNewListCheckToggle(evt: MatCheckboxChange) {
    if (!evt.checked) {
      this.form.get('newListName').setValue('');
    }
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }
}
