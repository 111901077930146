<div class="reportsFilter filterExportReport">
  <div aria-label="Filter Student Detail Record Export" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 style="font-weight: inherit" class="filterHeader filter-title">Consolidated Student Record Custom PDF Filter
      </h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" (keypress)="closeDialog()"
        aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <!--Filter Criteria-->
    <div class="md-dialog-content">
      <div class="layout-row" style="margin-bottom:20px">
        <div class="flex-30">
          <div class="filterHeader">
            SELECT FILTER CRITERIA:
          </div>
        </div>
        <div class="layout-column flex-70">
          <mat-radio-group [(ngModel)]="filterParameters.criteria" ng-change="setExportCriteria()">
            <div class="layout-row">
              <mat-radio-button value="default" class="flex-55">
                <p>Default Filter Criteria (all student information)</p>
              </mat-radio-button>
              <mat-radio-button value="enrollmentDate" class="flex-45">
                <p>Enrollment Date Range</p>
              </mat-radio-button>
            </div>
            <div layout="row">
              <mat-radio-button value="gradeLevel" class="flex-55">
                <p>Grade Level</p>
              </mat-radio-button>
            </div>
          </mat-radio-group>
          <div layout="row" [hidden]="!(filterParameters.criteria =='enrollmentDate')">
            <p>
              The Enrollment Date Range filter will not display Course History or Assessment information that is
              not associated with an enrollment of the selected date range.
            </p>
          </div>
        </div>
      </div>

      <!--Enrollment Date Range-->
      <div layout="column" class="filter-section" [hidden]="!(filterParameters.criteria =='enrollmentDate')">
        <div class="layout-row">
          <div class="flex-30">
            <div class="filterHeader">
              ENROLLMENT DATE RANGE:
            </div>
          </div>
          <div class="flex-70 layout-row date-picker export-filter">
            <div class="layout-row start-date-box flex-50">
              <div class="date" flex="20">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentStartMMExport"
                    customlabel="MM" [(ngModel)]="filterParameters.enrollmentDate.startMM" name="month"
                    [maxLength]="2" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
              <p class="date-slash" flex="5">/</p>
              <div class="date" flex="20">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentStartDDExport"
                    customlabel="DD" [(ngModel)]="filterParameters.enrollmentDate.startDD" name="day"
                    [maxLength]="2" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
              <p class="date-slash" flex="5">/</p>
              <div class="date" flex="30">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentStartYY"
                    customlabel="YYYY" [(ngModel)]="filterParameters.enrollmentDate.startYYYY" name="year"
                    [maxLength]="4" (change)="formatDate()" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
            </div>
            <div class="layout-row end-date-box flex-50">
              <div class="date" flex="20">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentEndMM"
                    customlabel="MM" [(ngModel)]="filterParameters.enrollmentDate.endMM" name="endMM"
                    [maxLength]="2" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
              <p class="date-slash" flex="5">/</p>
              <div class="date" flex="20">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentEndDD"
                    customlabel="DD" [(ngModel)]="filterParameters.enrollmentDate.endDD" name="endDD"
                    [maxLength]="2" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
              <p class="date-slash" flex="5">/</p>
              <div class="date" flex="30">
                <div class="md-block">
                  <custom-mat-input-for-filter class="dateInput" moveNextByMaxLength id="enrollmentEndYYYY"
                    customlabel="YYYY" [(ngModel)]="filterParameters.enrollmentDate.endYYYY" name="endYYYY"
                    [maxLength]="4" (change)="formatDate()" (ngsubmit)="onSubmit()">
                  </custom-mat-input-for-filter>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div layout="row">
          <div flex="30"></div>
          <div class="error-message" *ngIf="filterParameters.displayDateError" flex="70">
            <p>
              The Enrollment Date Range start date must occur before the end date.
            </p>
          </div>
        </div>
      </div>

      <!--Grade Level-->
      <div layout="row" class="filter-section" *ngIf="filterParameters.criteria =='gradeLevel'"
        style="margin-bottom: 60px;">
        <div layout="column" flex>
          <div class="filterHeader">GRADE LEVEL:</div>
          <div class="layout-row selectButtons">
            <p>Select</p>
            <a (click)="selectAllExportFilter('gradeLevel')" tabindex="0"
              (keypress)="selectAllExportFilter('gradeLevel')">
              All
            </a>
            <p>|</p>
            <a (click)="selectNoneExportFilter('gradeLevel')" tabindex="0"
              (keypress)="selectNoneExportFilter('gradeLevel')">
              None
            </a>
          </div>
          <div>
            <div style="flex-wrap: wrap" class="coursePOC layout-row export-filter">
              <mat-checkbox md-no-ink (click)="checkedGradeLevels(gradeLevel.value)" [value]="gradeLevel.value"
                [checked]="gradeLevel.checked" class="flex-30" style="margin-top: -18px;"
                *ngFor="let gradeLevel of filterParameters.gradeLevels">
                <p>{{gradeLevel.name}}</p>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <hr layout-padding class="thickHr">

      <!--Report Elements selection boxes-->
      <div layout="row" class="filter-section">
        <div layout="column" flex>
          <div class="filterHeader">REPORT ELEMENTS:</div>
          <div layout="row" class="layout-row selectButtons">
            <p>Select</p>
            <a (click)="selectAllExportFilter('elements')" tabindex="0" (keypress)="selectAllExportFilter('elements')">
              All
            </a>
            <p>|</p>
            <a (click)="selectNoneExportFilter('elements')" tabindex="0"
              (keypress)="selectNoneExportFilter('elements')">
              None
            </a>
          </div>
          <div>
            <div style="flex-wrap: wrap" class="coursePOC layout-row" layout="row" flex layout-wrap>
              <mat-checkbox md-no-ink style="padding-bottom: 20px" (click)="checkedElements(element.value)"
                [value]="element.value" [checked]="element.checked" class="flex-30"
                *ngFor="let element of filterParameters.reportElements" flex="30">
                <p>{{element.name}}</p>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <hr layout-padding class="thickHr">

      <!--Language radio buttons-->
      <div class="layout-row" style="margin-bottom:20px">
        <div class="flex-30">
          <div style="margin-top: 10px" class="filterHeader">
            LANGUAGE:
          </div>
        </div>
        <div class="flex-70">
          <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.language">
            <mat-radio-button value="English">
              <p>English</p>
            </mat-radio-button>
            <mat-radio-button value="Spanish">
              <p>Spanish</p>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div style="padding: 15px 30px; align-items: center;" class="layout-row">
      <a class="resetButton" (click)="resetExportFilter()" tabindex="0" (keypress)="resetExportFilter()">
        Reset Filters
      </a>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button style="margin: 0" type="submit" (click)="onSubmit()" class="confirmButton md-button"
        [disabled]="(filterParameters.criteria =='enrollmentDate' && (!filterParameters.enrollmentDate.startMM || 
          !filterParameters.enrollmentDate.startYYYY || !filterParameters.enrollmentDate.endMM || !filterParameters.enrollmentDate.endYYYY)) 
          || filterParameters.displayDateError || (filterParameters.gradeLevels.length == 0 && filterParameters.gradeLevels[17].checked == false)">
        SUBMIT
      </button>
    </div>
  </div>
</div>