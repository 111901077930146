<div *ngIf="mergeWorklist.length == 0 && validateMergeItems.length == 0 && nominateMatchItems.length == 0" class="layout-margin-worklist-module layout-padding-worklist-module">
  <h2>No Merge items found.</h2>
</div>

<!-- START Merge Worklist Desktop Table -->

<div *ngIf="responsiveUI.isDesktop && mergeWorklist.length > 0" 
  class="layout-margin-worklist-module layout-padding-worklist-module worklist-page">
  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Flagged for Merge</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders">
      <p class="worklist-description-text">
        Initiate a merge by selecting <b>at least two</b> records, assigning a master record, and clicking "Process Merge" at
        the bottom of the list. Remove a student record from your list by expanding the record and clicking "Remove
        from List.” Merge flags are automatically removed after 14 days from the created date.
      </p>
    </div>

    <div class="worklist-header-titles layout-flex-row" flex>
      <div class="flex-95">
        <div style="margin-left: 5%" class="flex-container flex-85" flex-offset="5">
          <div class="block2 flex-20">
            <a (click)="sortColumns('column1')">
              Student
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column2')">
              State
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-10">
            <a (click)="sortColumns('column3')">
              Sex
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column4')">
              DOB
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column5')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column6')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex">
            <a (click)="sortColumns('column7')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="flex layout-flex-row table2Row" *ngFor="let student of mergeWorklist" flex="100">
      <div class="flex-95 background-white">
        <div class="layout-flex-row">
          <div class="flex-5 layout-flex-column" style="margin-top: 15px;"
            [ngClass]="{'background-green-opacity-20' : student.checked, 'background-grey1' : !student.checked}">
            <div flex class="flex-container">
              <mat-checkbox [(ngModel)]="student.checked" id="mergeCheckbox" (change)="mergeSelectionLogic(student, student.demographics.msixId)"
                [value]="student.demographics.msixId" aria-label="Select Record for Merge" md-no-ink md-ink-ripple="false"
                class="float-right no-margins" name="selectStudent" ngDefaultControl></mat-checkbox>
            </div>
          </div>
          <div (click)="showMergeDetailsFn(student.demographics.msixId)" class="layout-flex-row section1 flex-85">
            <span class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p flex>
                {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p flex>{{student.demographics.submittingStateAbbv}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p flex>{{student.demographics.gender}}</p>
            </span>
            <span flex class="column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p flex>{{student.demographics.birthDate}}</p>
            </span>
            <span flex class="column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p flex>{{student.demographics.msixId}}</p>
            </span>
            <span flex class="column" [ngClass]="{highlight: currentSortColumn === 'column6'}">
              <p flex>{{student.demographics.studentStateID}}</p>
            </span>
            <span flex class="column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p flex class="layout-flex-column">
                <span class="days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                <span class="days-in-list">{{daysRemaining(student.dateCreated)}} remaining</span>
              </p>
            </span>
          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div>
        </div>

        <div class="section2 worklist-accordion-section2 background-grey1 layout-flex-row flex" *ngIf="studentMergeIndex === student.demographics.msixId" flex="100">

          <div class="col1 flex-25 layout-column" flex-offset="5">
            <div class="top-bottom-margin-8">
              <p><b>Parent 1:</b></p>
              <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
              </p>
            </div>
            <div class="col1 top-bottom-margin-8" flex-offset="5">
              <p><b>Parent 1 Email Address:</b></p>
              <p *ngIf="student.demographics.parent1EmailAddress">{{student.demographics.parent1EmailAddress}}
              </p>
              <p flex *ngIf="student.demographics.parent1EmailAddress == '' && !student.demographics.parent1EmailAddress">
                <br>
              </p>
            </div>
            <div class="col1 top-bottom-margin-8" flex-offset="5">
              <p><b>Parent 1 Phone Number:</b></p>
              <p *ngIf="student.demographics.parent1PhoneNumber">{{student.demographics.parent1PhoneNumber}}
              </p>
              <p flex *ngIf="student.demographics.parent1PhoneNumber == '' && !student.demographics.parent1PhoneNumber">
                <br>
              </p>
            </div>
          </div>
          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 2:</b></p>
              <p>
                {{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
              </p>
            </div>
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 2 Email Address:</b></p>
              <p *ngIf="student.demographics.parent2EmailAddress">{{student.demographics.parent2EmailAddress}}
              </p>
              <p flex *ngIf="student.demographics.parent2EmailAddress == '' && !student.demographics.parent2EmailAddress">
                <br>
              </p>
            </div>
            <div class="col1 top-bottom-margin-8">
              <p><b>Parent 2 Phone Number:</b></p>
              <p *ngIf="student.demographics.parent2PhoneNumber">{{student.demographics.parent2PhoneNumber}}
              </p>
              <p flex *ngIf="student.demographics.parent2PhoneNumber == '' && !student.demographics.parent2PhoneNumber">
                <br>
              </p>
            </div>
          </div>

          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>State ID:</b></p>
              <p>{{student.demographics.studentStateID}}</p>
            </div>
            <div class="col1 top-bottom-margin-8">
              <p><b>Multi-birth:</b></p>
              <p>{{student.demographics.multipleBirthFlag}}</p>
            </div>
          </div>
          <div class="flex-25 layout-flex-column col1">
            <a flex (click)="removeFromMergeList(student)">
              <span>Remove from List</span>
            </a>
          </div>

        </div>

      </div>

      <div (click)="showMergeDetailsFn(student.demographics.msixId)" class="flex-5 expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(studentMergeIndex === student.demographics.msixId)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="studentMergeIndex === student.demographics.msixId"></i>
      </div>

    </div>

  </div>

  <div class="float-right layout-flex-row left-right-margin-14">
    <div flex class="float-right layout-flex-row layout-align-end-start">
      <div class="layout-align-center-center">
        <button class="flex md-button" (click)="nominateMerge($event)" [disabled]="mergeCheckedNew.length < 2">
          Process Merge
        </button>
      </div>
    </div>

  </div>

</div>

<!-- END Merge Worklist Desktop End -->

<!--MOBILE AND TABLET START-->

<div flex class="worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && mergeWorklist.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet merge-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow no-padding">
              <p class="worklist-section-header">Merge Records</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To complete a merge request, access your worklist on desktop.</p>
        </div>
        <div>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of mergeWorklist">
            <div class="layout-flex-row">
              <div flex="65" class="flex-65 layout-flex-column" (click)="viewDetails(student)">
                <a class="uppercase">
                  {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''"> {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}
                  <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.remobirthDate}} |&nbsp;{{student.demographics.gender}}
                    | {{student.demographics.submittingStateAbbv}}</p>
                </div>
              </div>
              <div class="flex-35 layout-flex-row right-align">
                <button class="mobile-worklist-remove-button remove-button no-margins md-button" id="mobileTabletRemoveButton"
                  (click)="removeFromMergeList(student)">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!--MOBILE AND TABLET END-->