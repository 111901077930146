import { Component, Inject } from '@angular/core';
//import { Moment } from 'moment';
import * as moment from 'moment';
import * as angular from "angular";
//import * as debounce from 'lodash';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastService } from 'src/app/services/toast.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'potentialDuplicates',
  templateUrl: './potentialDuplicates.component.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class PotentialDuplicatesComponent {

  exportCall = "potentialDuplicatesInfoExport";
  router: any;
  showFilter: boolean = true;
  isInit: boolean = false;
  showDrill: boolean = false;

  jobsArray: any[];
  jobTitle: any;
  response: any;
  blankRes: boolean;
  userKeys: any[];
  // holds the list of states
  states: any[] = [];
  // holds the set of selected stateKeys
  fullStateArray: any = [];
  showHistory: boolean;
  disableWithdrawalDate: boolean;
  params: any;
  stateMap: any;
  allStates: any = [];

  resetFilters: boolean = true;
  reportInfo: any;
  drillDown: any;
  currentSortColumn: string;
  sortAscending: boolean;
  stayOnDrillDown: boolean;
  drillDownDetails: any;
  drillDownData: any;
  disablePrev: boolean;
  disableNext: boolean;
  userKeysIndex: number;
  reportGroupVar: string;
  reportDrillDownVar: string;
  assignedDistricts: any[];
  //hold export count for Reports
  exportCount: number;


  //holds filter parameters
  filterParameters: any = {
    rbtState: false,
    rbtMsix: true,
    rbtAll: null,
    selectedStates: [],
    availableStates: null,
    lastUpdateDateFrom: null,
    lastUpdateDateTo: null,
    beginRow: 1,
    endRow: 100,
    sortField: "STATE_ASC",
    groupChoice: {
      choice: "msix"
    },
    selectedStatesArray: [],
    fullStateArray: [],
    userStatusArray: []
  }
  selectedStatesArray: any = [];
  userStatusArray: any = [];
  userTypeArray: any = [];
  creationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  activationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  loginDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  expirationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };

  // holds the current active filter parameters
  activeFilter: any;
  activeSelectedStatesArray: any;
  activeStatusArray: any;
  activeTypeArray: any;
  activeCreationDate: any;
  activeActivationDate: any;
  activeLoginDate: any;
  activeExpirationDate: any;

  //this object holds current & recent reporting period for display purposes
  displayData = {
    current: "",
    recent: "",
    category: "",
    subcategory: ""
  };

  // 'Flag for Merge' functionality is available to only data admins.
  // When user visits this page, following roles are verified if they have it or not.
  roleClasses: any[] = [
    'dataAdmins'
  ];
  authRoleClasses: any = {};

  groupChoice: any = {
    choice: "msix"
  }
  enableFlagForMergeCheckbox: boolean = false;
  availableReportRows = 0;
  // holds the list of sudents that are selected to flag for merge
  listOfStudentsToFlagForMerge: any[] = [];
  isMergeListEmpty: boolean = true;

  drillDownInfo: any = {
    drillDown: {
      studentIDFilter: null
    }
  }
  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private authService: AuthService,
    private userService: UserService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private searchService: SearchService,
    private toastService: ToastService,
    private worklistService: WorklistService) {
    this.router = _router;
    this.response = {};
    this.blankRes = false;
    this.userKeys = [];
    this.userKeysIndex = 0;
    this.stayOnDrillDown = false;
    this.disablePrev = false;
    this.disableNext = false;
    this.reportGroupVar = "product";
    this.reportDrillDownVar = "findUserLoginInfo";
    //holds values for maintaining sort
    this.sortAscending = true;
    this.currentSortColumn = 'column1';
    this.exportCount = 0;
    this.showHistory = false;
    this.disableWithdrawalDate = false;
    this.reportInfo = {};
    this.drillDownDetails = {};
    this.drillDownData = {};
    this.stateMap = new Map();
    this.userStatusArray = [];
    this.jobTitle = {
      other: ''
    };
  }

  ngOnInit() {
    this.checkRoleClasses();
    this.getReportInfo();
  }

  // Check if the logged in user has any of the listed roles
  checkRoleClasses() {
    this.authService.isRoleClassAuthorized(this.roleClasses).then(
      (res) => {
        this.authRoleClasses = res;
        console.log("potentialDuplicates.checkRoleClasses(): ", res);
      }
    );
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['dataQuality_all', 'dataQuality']);
    this.mineAndSetStatesArray(this.reportInfo.stateData);
    this.setSelectedStates();
    // generates report
    this.filterActivate();
  }

  // sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.filterParameters.selectedStatesArray.push(responseData[i].stateKey);
        this.filterParameters.selectedStates.push(obj);
      }
    }
  }


  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    //SelectedStates defaults to entire array, but this checks for state user role
    if (roles.dataQuality == true) {
      var shortStates: any = [];
      var array = this.states;
      for (var i = 0; i < array.length; i++) {
        if (!(array[i].stateKey == "9" || array[i].stateKey == "40" || array[i].stateKey == "52")) {
          shortStates.push(array[i]);
        }
      }
      this.states = shortStates;
    } else {
      let arr = [{ key: this.reportInfo.userState }]
      this.filterParameters.selectedStates = arr;
    }
  }

  goToReport() {
    this.showDrill = false;
  }

  // Open student details page in new tab
  goToStudentDetails(id) {
    let userActivity = {
      msixID: id,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this.searchService.logUserActivity(userActivity);
  }

  // Learn More Activate
  learnMoreActivate() {
    if (this.showDrill) {
      const dialogRef = this.dialog.open(PotentialDuplicatesDDLearnMore, {
        height: 'auto',
        width: '60%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(PotentialDuplicatesLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.showFilter = false;
      });
    }

  }

  //Report Level Pagination - back
  pageBack() {
    this.isInit = false;
    if (this.filterParameters.beginRow > 1) {
      this.filterParameters.beginRow = this.filterParameters.beginRow - 100;
      this.filterParameters.endRow = this.filterParameters.endRow - 100;
      this.submitReport();
    }
  }

  //Report Level Pagination - forward
  pageForward() {
    this.isInit = false;
    this.filterParameters.beginRow = this.filterParameters.beginRow + 100;
    this.filterParameters.endRow = this.filterParameters.endRow + 100;
    this.submitReport();
  }

  handleReportSpecialSortClick(newSortKey) {
    this.isInit = false;
    this.filterParameters.sortField = newSortKey.sortKey;
    this.filterParameters.beginRow = 1;
    this.filterParameters.endRow = 100;
    this.submitReport();
  }

  // Trigger Filter Modal
  filterActivate() {
    this.showFilter = true;
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));
    this.filterParameters.userState = JSON.parse(JSON.stringify(this.reportInfo.userState));

    const dialogRef = this.dialog.open(FilterPotentialDuplicates, {
      height: 'auto',
      width: '50%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.activeFilter = result;
        this.filterParameters = result;
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // This function is called when 'Submit' is clicked on the Potential Duplicates report filter popup
  submitFilter() {
    this.isInit = true;
    this.filterParameters.beginRow = 1;
    this.filterParameters.endRow = 100;
    this.filterParameters.sortField = 'STATE_ASC';
    this.setGroupChoice();

    if (this.filterParameters.selectedStates.length === 0) {
      this.blankRes = true;
      this.response = [];
    } else {
      this.blankRes = false;
      this.submitReport();
    }
  }

  setGroupChoice() {
    if (this.filterParameters.groupChoice.choice === "msix") {
      this.filterParameters.rbtMsix = true;
      this.filterParameters.rbtState = false;
    } else {
      this.filterParameters.rbtMsix = false;
      this.filterParameters.rbtState = true;
    }
  }

  submitReport() {
    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.activeSelectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.selectedStatesArray));

    console.log(this.filterParameters);
    let apiCall = this.reportsService.potentialDuplicatesInfo(this.filterParameters);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.filterParameters = cloneDeep(this.activeFilter);
        this.response = data;
        this.enableFlagForMergeCheckbox = (this.filterParameters.groupChoice.choice === 'msix') ? true : false;

        this.availableReportRows = data.totalAvailableRows;
        console.log(this.response);
        subscribed.unsubscribe();
      },
      error: error => {
        subscribed.unsubscribe();
      }
    });
  }

  handleMSIXIDClick(msixId) {
    var url = '/msix/#/reports/studentDetails';
    url += '?msixId=' + msixId;
    window.open(url, '_blank');
  }

  //Pull in Drilldown data
  drillDownInit(data, column) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down
    this.drillDownData = data.data;
    var birthDate = this.drillDownData.stuBirthDate
    if (birthDate != moment(birthDate).format('MM/DD/YYYY')) {
      this.drillDownData.stuBirthDate = moment(birthDate).format('MM/DD/YYYY')
    }
    //show Drill Down - hide Report
    this.showDrill = !this.showDrill;
  }

  // Update the list of students selected to flag for merge 
  checkedRecordForFlagForMerge(eventData) {
    if (eventData.isChecked) {
      this.listOfStudentsToFlagForMerge.push(eventData.row.column3.value);
    } else {
      let i = this.listOfStudentsToFlagForMerge.indexOf(eventData.row.column3.value);
      this.listOfStudentsToFlagForMerge.splice(i, 1);
    }

    this.isMergeListEmpty = (this.listOfStudentsToFlagForMerge.length > 0) ? false : true;
  }

  // Call API to flag all the selected students for merge
  flagForMergeAllStudents() {
    let apiCall = this.worklistService.flagAllSelectedStudentsForMerge(this.listOfStudentsToFlagForMerge);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("PotentialDuplicates.flagForMergeAllStudents(): ", data);
        this.listOfStudentsToFlagForMerge = [];
        this.isMergeListEmpty = true;
        this.submitReport();
        this.toastService.showToast('Students have been added to merge worklist');
        subscribed.unsubscribe();
      },
      error: error => {
        this.toastService.showToast(error.data.developerMessage, 0);
        console.log("Error - PotentialDuplicates.flagForMergeAllStudents(): ", error);
        subscribed.unsubscribe();
      }
    });
  }


  //Updates which column and direction the table will be sorted by
  sortByColumn(params) {
    this.currentSortColumn = params.column;
    this.sortAscending = params.ascending;
  }
}

//Separate component for the PotentialDuplicates report's filter
export interface FilterParameters {
  params: {
    selectedStates: any;
    fullStateArray: any;
    selectedStatesArray: any;
    authRoleClasses: any;
    groupChoice: any;
    userState: string;
    states: []
  }
}
@Component({
  selector: 'filterPotentialDuplicates',
  templateUrl: './filterPotentialDuplicates.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class FilterPotentialDuplicates {
  filterParameters: any = {};
  constructor(public dialogRef: MatDialogRef<FilterPotentialDuplicates>,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
    this.filterParameters = JSON.parse(JSON.stringify(this.data.params));
  }

  // sets filter initially and resets filter when called
  resetFilter() {
    this.filterParameters = {
      rbtState: false,
      rbtMsix: true,
      rbtAll: null,
      selectedStates: this.data.params.selectedStates,
      availableStates: null,
      lastUpdateDateFrom: null,
      lastUpdateDateTo: null,
      beginRow: 1,
      endRow: 100,
      sortField: "STATE_ASC",
      groupChoice: {
        choice: "msix"
      },
      selectedStatesArray: [],
      fullStateArray: this.data.params.fullStateArray,
      userStatusArray: [],
      authRoleClasses: this.data.params.authRoleClasses,
      userState: this.data.params.userState,
      states: this.data.params.states
    }
    this.selectAll();
  }

  selectAll() {
    if (this.filterParameters.authRoleClasses.dataQuality == true) {
      this.filterParameters.selectedStatesArray = this.filterParameters.fullStateArray.map(state => state.key);
      this.filterParameters.selectedStates = this.filterParameters.fullStateArray;
    } else {
      this.filterParameters.selectedStates = [{ key: this.filterParameters.userState }]
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.selectedStates = this.filterParameters.selectedStatesArray;
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    if (this.filterParameters.selectedStatesArray.indexOf(stateKey) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    var statesIndex = this.filterParameters.selectedStatesArray.indexOf(stateKey);
    if (statesIndex === -1) {
      this.filterParameters.selectedStatesArray.push(stateKey);
    } else {
      this.filterParameters.selectedStatesArray.splice(statesIndex, 1);
    }
  }

  onSubmit() {
    if (this.filterParameters.authRoleClasses.dataQuality) {
      this.filterParameters.selectedStates = [];
      for (let i = 0; i < this.filterParameters.selectedStatesArray.length; i++) {
        let obj = {
          key: this.filterParameters.selectedStatesArray[i]
        }
        this.filterParameters.selectedStates[i] = obj;
      }
    }
    this.dialogRef.close(this.filterParameters);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  trackByMethod(index: number, el: any): number {
    return index;
  }
}

// Separate component for the PotentialDuplicates report's Learn More dialog box
@Component({
  selector: 'potentialDuplicatesLearnMore',
  templateUrl: 'potentialDuplicatesLearnMore.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class PotentialDuplicatesLearnMore {
  constructor(
    public dialogRef: MatDialogRef<PotentialDuplicatesLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the PotentialDuplicates report's drilldown Learn More dialog box
@Component({
  selector: 'potentialDuplicatesDDLearnMore',
  templateUrl: 'potentialDuplicatesDDLearnMore.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class PotentialDuplicatesDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<PotentialDuplicatesDDLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}