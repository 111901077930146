<div class="select">
    <div class="layout-row selectButtons">
        <p>Select</p>
        <a (click)="selectAll()">All</a>
        <p>|</p>
        <a (click)="selectNone()">None</a>
    </div>
</div>

<form [formGroup]="form">
        <div class="states">
            <div class="flex">
                <div class="col">
                    <ng-container *ngFor="let type of (courseType | slice:0:3)" >
                        <mat-checkbox [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
                <div class="col">
                    <ng-container *ngFor="let type of (courseType | slice:3:6)" >
                        <mat-checkbox  [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
                <div class="col">
                    <ng-container *ngFor="let type of (courseType | slice:6)" >
                        <mat-checkbox  [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
            </div>
        </div>
</form>