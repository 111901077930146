<div class="header">
    <p>Multi-Move Notice</p>
    <button class="md-icon-button" mat-dialog-close aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>

<div class="wrapper">
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-select formControlName="isLeaving">
                <mat-option [value]="false">
                    These students are moving <b>from our area to your area</b>
                </mat-option>
                <mat-option value="true">
                    These students have recently <b>moved to our area</b>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="text-area comments">
            <mat-label>Comments</mat-label>
            <textarea rows="3" matInput formControlName="comments" maxlength="250"></textarea>
        </mat-form-field>

        <div class="recipient">
            <h2>Recipient</h2>
            <p>Select the State, District and School to ensure the move notification is sent to the appropriate Data Administrator.</p>
            <mat-form-field appearance="outline" [hideRequiredMarker]="true">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                    <mat-option *ngFor="let state of data.states" [value]="state">
                        {{state.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>District</mat-label>
                <mat-select formControlName="district">
                    <ng-container *ngIf="districts$ | async as districts">
                        <mat-option *ngFor="let district of districts" [value]="district">
                            {{district.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>School</mat-label>
                <mat-select formControlName="school">
                    <ng-container *ngIf="schools$ | async as schools">
                        <mat-option *ngFor="let school of schools; index as i" [value]="i">
                            {{school.name}}
                        </mat-option>
                    </ng-container>
                    
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="action-buttons">
        <a tabindex="0" class="resetButton" mat-dialog-close>
            Cancel
        </a>
        <button class="confirmButton md-button" [disabled]="form.invalid" (click)="onSubmit()" (keypress)="onSubmit()">Submit</button>
        
    </div>
</div>

