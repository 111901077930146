<div class="resources-page background-creative main-padding">
  <div class="md-content side-padding half-width">
    <h1 class="padding-top-10 padding-bottom-15">Resources</h1>
    <div [style.flex-direction]="(responsiveUI.isMobile || responsiveUI.isTablet) ? 'column' : 'row'" style="display: flex;">
      <div class="column-l" [style.max-width]="(responsiveUI.isDesktop === true) ? '50%' : '100%'" layout-align="start start">
        <h3 class="padding-top-15">MSIX Multi-Factor Authentication</h3>
        <div style="display: flex; flex-direction: column;">
          <a class="padding-top-15" href="assets/trainingCorner/webinars/MFA_Train_the_Trainer_Webinar_07142022_508_v1.3.pptx">MSIX Train the Trainer Webinar 2022</a>
          <a href="assets/trainingCorner/resources/msixguides/Google_Authenticator_Configuration_Aid.pdf" >Guide to Configure Google Authenticator with MSIX</a>
          <a href="assets/trainingCorner/resources/msixguides/Microsoft_Authenticator_Configuration_Aid.pdf">Guide to Configure Microsoft Authenticator with MSIX</a>
          <a href="assets/trainingCorner/resources/msixguides/Authy_Configuration_Aid.pdf">Guide to Configure Authy with MSIX</a>
          <a href="assets/trainingCorner/resources/msixguides/Authenticator_Browser_Extension_Configuration_Steps_Aid.pdf">Guide to Configure Authenticator (Browser Extension) with MSIX</a>
        </div>

        <div>
          <h3 class="padding-top-15">Technical References</h3>
          <p class="padding-top-10" *ngIf="loggedIn && !isLoggedInWithOTP">Multi-Factor Authentication Reference Page</p>
          <a href="{{help_url}}tech/MSIX_Multifactor_Authentication_Reference_Page.pdf" *ngIf="loggedIn && !isLoggedInWithOTP">Multi-Factor Authentication Reference Page</a>

          <p class="padding-top-10" *ngIf="loggedIn && !isLoggedInWithOTP">Child Count Reconciliation Recommended Guidance</p>
          <a href="{{help_url}}tech/MSIX_Child_Count_Reconciliation_Recommended_Guidance_v1_4.pdf" *ngIf="loggedIn && !isLoggedInWithOTP">Child Count Reconciliation Recommended Guidance</a>

          <p class="padding-top-15" *ngIf="loggedIn && !isLoggedInWithOTP">Country and State Codes</p>
          <a href="{{help_url}}tech/countrystatecodes/MSIX_Country_Codes.xls" *ngIf="loggedIn && !isLoggedInWithOTP">MSIX Country Codes (XLS)</a>
          <a href="{{help_url}}tech/countrystatecodes/MSIX_State_Codes.xls" *ngIf="loggedIn && !isLoggedInWithOTP">MSIX State Codes (XLS)</a>
          <p class="padding-top-15" *ngIf="loggedIn && !isLoggedInWithOTP">Interface Requirements Specification</p>
          <a href="{{help_url}}tech/Data_Exchange_Interface_Requirements_Specifications_v1_3.pdf" *ngIf="loggedIn && !isLoggedInWithOTP">State's Assistance - Interface Requirements Specification, v1.3 (PDF)</a>
          <p class="padding-top-15" *ngIf="loggedIn && !isLoggedInWithOTP">Interface File Formats</p>
          <div style="display: flex; flex-direction: column" *ngIf="loggedIn && !isLoggedInWithOTP">
            <a href="{{help_url}}tech/Inbound_SSI_Delimited_Flat_File_Specification_v14.xls">Inbound SSI Delimited Flat File Specification, v14</a>
            <a href="{{help_url}}tech/MSIX-Inbound_SSI_v13.xsd">MSIX Inbound SSI.xsd, v13</a>
            <a href="{{help_url}}tech/Outbound_Response_Delimited_Flat_File_Specification.xls">Outbound Response Delimited Flat File Specification, v1.03</a>
            <a href="{{help_url}}tech/Outbound_Response_SSI_XML.xsd">Outbound Response SSI XML.xsd</a>
            <a href="{{help_url}}tech/Outbound_Merge_Split_Delimited_Flat_File_Specification.xls">Outbound Merge-Split File Specification (XLS)</a>
            <a href="{{help_url}}tech/Outbound_Merge_Split_SSI.xsd">Outbound Merge-Split SSI.xsd</a>
            <a href="{{help_url}}tech/Outbound_OnDemand_Delimited_Flat_File_Specification_v1.4.xls">Outbound OnDemand Delimited Flat File Specification (XLS)</a>
            <a href="{{help_url}}tech/Outbound_OnDemand_SSI_XML_File.xml">Outbound OnDemand SSI XML (XML/TXT)</a>
            <a href="{{help_url}}tech/Outbound_Response_Error_Delimited_Flat_File_Specification.xls">Outbound Response Error Delimited Flat File Specification (XLS)</a>
            <a href="{{help_url}}tech/Outbound_Response_Error_SSI.xsd">Outbound Response Error SSI.xsd</a>
          </div>
          <a href="{{help_url}}tech/Outbound_Merge_Split_SSI.XML" *ngIf="loggedIn && !isLoggedInWithOTP">Outbound_Merge-Split (XML/TXT)</a>
          <p class="padding-top-15">Other Supporting Technical Reference Materials</p>
          <a href="assets/trainingCorner/resources/tech/FERPA%20Memo%20FINAL.doc">FERPA Memo FINAL.doc, v4 (WORD)</a>
          <a href="assets/trainingCorner/resources/tech/MSIX Rules of Behavior_112024.doc">MSIX Rules of Behavior (WORD)</a>
          <p class="padding-top-15" *ngIf="loggedIn && !isLoggedInWithOTP">MSIX Web Services</p>
          <a href="{{help_url}}tech/MSIX_Web_Services_Onboarding_Guide_v1.2.pdf" *ngIf="loggedIn && !isLoggedInWithOTP">Web Service On-boarding Guide, v1.2 (PDF)</a>
        </div>
      </div>

      <div class="column-r" style="display: flex; flex-direction: column; max-width:fit-content; max-height: fit-content;" [style.flex]="((responsiveUI.isMobile && !responsiveUI.isPortrait) || (responsiveUI.isTablet)) ? '50%' : '100%'" layout-align="start start">
        <div flex class="contactBorder">
          <h3>Contact Us</h3>
          <p class="padding-top-15">For password reset and account updates</p>
          <a tabindex="0" [routerLink]="['/pwReset']">State Contact Search</a>
          <p class="padding-top-15">For all other needs</p>
          <p class="contactMethodText padding-top-5">By email</p>
          <a href="mailto:MSIXsupport@deloitte.com">MSIXsupport@deloitte.com</a>
          <p class="contactMethodText padding-top-5">By phone (9:30AM to 6:30PM ET)</p>
          <a href="tel:+1-866-878-9525">1-866-878-9525</a>
        </div>
      </div>
    </div>
  </div>
</div>