<div class="worklist-page" id="worklistpagesection">

  <!-- desktop worklist start-->
  <div *ngIf="responsiveUI.isDesktop">

    <div class="md-content" layout-padding >

      <div layout-margin class="mainDiv">

        <div class="layout-row layout-align-end-start">
          <div class="corHistoricalTab" (click)="showWorklistHistorySearch('mergeAndSplitWorklistHistory')" *ngIf="authRoleClasses.worklistSearch">
            <p>Merge & Split Worklist History</p>
          </div>
          <div class="historicalTab" (click)="showWorklistHistorySearch('corWorklistHistory')">
            <p>Data Request & Move Notice History</p>
          </div>
        </div>

        <div *ngIf="!(mergeWorklist === 0 && splitWorklist ===0 && validateMergeItems === 0 &&
         validateSplitItems === 0 && dataRequestItems === 0 && residencyChangeItems ===0 && nominateMatchItems === 0)">
          <div>

            <!--DESKTOP WORKLIST TABS-->
            <div>
              <h1 class="layout-margin left-right-margin-50">My Worklist</h1>

              <div class="customTabs left-right-margin-50 flex-inline" layout="flex">
                <div class="block customTab flex-container flex-20" (click)="dataRequestItems.length != 0 && changePartial('dataRequest')" [ngClass]="{'tabActive': (partialToDisplay === 'dataRequest'), 'tabDisabled': (dataRequestItems.length === 0)}">
                  <i class="fa fa-list" aria-hidden="true"></i>&nbsp;Data Request
                  <div class="block tabCount flex-container" *ngIf="dataRequestItems.length != 0">
                    {{dataRequestItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-20" (click)="(mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length) != 0 && changePartial('merge')" [ngClass]="{'tabActive': (partialToDisplay === 'merge'), 'tabDisabled': (mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length === 0)}"
                  ng-show="authRoleClasses.initiateMerge">
                  <i class="fa fa-compress" aria-hidden="true"></i>&nbsp;Merge Record
                  <div class="block tabCount flex-container" *ngIf="(mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length) != 0">
                    {{mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-20" (click)="(splitWorklist.length + validateSplitItems.length) != 0 && changePartial('split')" [ngClass]="{'tabActive': (partialToDisplay === 'split'), 'tabDisabled': (splitWorklist.length + validateSplitItems.length === 0)}"
                  ng-show="authRoleClasses.initiateMerge">
                  <i class="fa fa-expand" aria-hidden="true"></i>&nbsp;Split Record
                  <div  class="block tabCount flex-container" *ngIf="(splitWorklist.length + validateSplitItems.length) != 0">
                    {{splitWorklist.length + validateSplitItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-20" (click)="residencyChangeItems.length != 0 && changePartial('move')" [ngClass]="{'tabActive': (partialToDisplay === 'move'), 'tabDisabled': (residencyChangeItems.length === 0)}">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;Move Notice
                  <div class="block tabCount flex-container" *ngIf="residencyChangeItems.length != 0">
                    {{residencyChangeItems.length}}
                  </div>
                </div>
              </div>

              <hr class="thickHr worklistHr">
            </div>

            <!-- Partials -->
            <!-- <div ng-include="'_dataRequest.html'" ng-show="partialToDisplay === 'dataRequest'"></div> -->
            <worklist_dataRequest 
              *ngIf="partialToDisplay === 'dataRequest'" 
              [dataRequestItems]="dataRequestItems"
              [showResolve]="showResolve"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (sendcorrespondence)="sendDataRequestCorrespondence($event)">
            </worklist_dataRequest>
            <!-- <div ng-include="'_moveNotice.html'" ng-show="partialToDisplay === 'move'"></div> -->
            <worklist_moveNotice 
              *ngIf="partialToDisplay === 'move'" 
              [residencyChangeItems]="residencyChangeItems"
              [(showResolve)]="showResolve"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (sendcorrespondence)="sendMoveNoticeCorrespondence($event)">
            </worklist_moveNotice>
            <!-- <div ng-include="'_validateMerge.html'" ng-show="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"></div> -->
            <worklist_validateMerge 
              *ngIf="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"
              [validateMergeItems]="validateMergeItems"
              [worklistHistory]="worklistHistory"
              [showResolve]="showResolve"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (setvalidatemerge)="setValidateMerge($event)">
            </worklist_validateMerge>
            <!-- <div ng-include="'_nominateNearMatch.html'" ng-show="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"></div> -->
            <worklist_nominateNearMatch 
              *ngIf="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"
              [nominateMatchItems]="nominateMatchItems"
              [showResolve]="showResolve"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)">
            </worklist_nominateNearMatch>
            <!-- <div ng-include="'_validateSplit.html'" ng-show="(partialToDisplay === 'split') && authRoleClasses.validateMerge"></div> -->
            <worklist_validateSplit 
              *ngIf="(partialToDisplay === 'split') && authRoleClasses.validateMerge"
              [validateSplitItems]="validateSplitItems"
              [worklistSplitHistory]="worklistSplitHistory"
              [validateMergeIndex]="validateMergeIndex"
              [showResolve]="showResolve"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)">
            </worklist_validateSplit>
            <!-- <div ng-include="'_processMerge.html'" ng-show="partialToDisplay === 'merge'"></div> -->
            <worklist_processMerge 
              *ngIf="partialToDisplay === 'merge'"
              [validateMergeItems]="validateMergeItems"
              [nominateMatchItems]="nominateMatchItems"
              [mergeWorklist]="mergeWorklist"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)"
              (getWorklist)="getWorklist($event)">
            </worklist_processMerge>
            <!-- <div ng-include="'_processSplit.html'" ng-show="partialToDisplay === 'split'"></div> -->
            <worklist_processSplit 
              *ngIf="partialToDisplay === 'split'"
              [splitWorklist]="splitWorklist"
              [validateSplitItems]="validateSplitItems"
              [currentUser]="currentUser"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)"
              (getWorklist)="getWorklist($event)">
            </worklist_processSplit>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--mobile worklist start-->
  <div *ngIf="!responsiveUI.isDesktop">

    <div class="md-content" layout-padding >

      <div layout-padding class="mainDiv">

        <div class="no-padding" *ngIf="!(mergeWorklist === 0 && splitWorklist ===0 && validateMergeItems === 0 &&
         validateSplitItems === 0 && dataRequestItems === 0 && residencyChangeItems ===0 && nominateMatchItems === 0)">
          <div>

            <!--MOBILE AND DESKTOP WORKLIST TABS-->
            <div>
              <div class="customTabs flex-inline no-margins" layout="flex">
                <div class="block customTab flex-container flex-25" (click)="changePartial('dataRequest')" [ngClass]="{'tabActive': (partialToDisplay === 'dataRequest')}"
                  *ngIf="dataRequestItems.length != 0">
                  Data
                  <div class="block tabCount flex-container">
                    {{dataRequestItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-25" (click)="changePartial('merge')" [ngClass]="{'tabActive': (partialToDisplay === 'merge')}"
                  *ngIf="authRoleClasses.initiateMerge && (mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length) != 0">
                  Merge
                  <div class="block tabCount flex-container">
                    {{mergeWorklist.length + validateMergeItems.length + nominateMatchItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-25" (click)="changePartial('split')" [ngClass]="{'tabActive': (partialToDisplay === 'split')}"
                  ng-show="authRoleClasses.initiateMerge" *ngIf="(splitWorklist.length + validateSplitItems.length) != 0">
                  Split
                  <div class="block tabCount flex-container">
                    {{splitWorklist.length + validateSplitItems.length}}
                  </div>
                </div>
                <div class="block customTab flex-container flex-25" (click)="changePartial('move')" [ngClass]="{'tabActive': (partialToDisplay === 'move')}"
                  *ngIf="residencyChangeItems.length != 0">
                  Move
                  <div class="block tabCount flex-container">
                    {{residencyChangeItems.length}}
                  </div>
                </div>
              </div>

              <hr class="thickHr worklistHr">
            </div>

            <!-- Partials -->
            <worklist_dataRequest 
              *ngIf="partialToDisplay === 'dataRequest'" 
              [dataRequestItems]="dataRequestItems"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (sendcorrespondence)="sendDataRequestCorrespondence($event)">
            </worklist_dataRequest>
            <worklist_moveNotice 
              *ngIf="partialToDisplay === 'move'" 
              [residencyChangeItems]="residencyChangeItems"
              [(showResolve)]="showResolve"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (sendcorrespondence)="sendMoveNoticeCorrespondence($event)">
            </worklist_moveNotice>
            <worklist_validateMerge 
              *ngIf="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"
              [validateMergeItems]="validateMergeItems"
              [worklistHistory]="worklistHistory"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)"
              (setvalidatemerge)="setValidateMerge($event)">
            </worklist_validateMerge>
            <worklist_nominateNearMatch 
              *ngIf="(partialToDisplay === 'merge') && authRoleClasses.validateMerge"
              [nominateMatchItems]="nominateMatchItems"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)"
              (gotoreassign)="goToReassign($event)"
              (getWorklist)="getWorklist($event)">
            </worklist_nominateNearMatch>
            <worklist_validateSplit 
              *ngIf="(partialToDisplay === 'split') && authRoleClasses.validateMerge"
              [validateSplitItems]="validateSplitItems"
              [worklistSplitHistory]="worklistSplitHistory"
              [validateMergeIndex]="validateMergeIndex"
              (viewdetails)="viewDetails($event)"
              (gotoreassign)="goToReassign($event)"
              (resolvemodal)="resolveModal($event)">
            </worklist_validateSplit>
            <worklist_processMerge 
              *ngIf="partialToDisplay === 'merge'"
              [validateMergeItems]="validateMergeItems"
              [nominateMatchItems]="nominateMatchItems"
              [mergeWorklist]="mergeWorklist"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)"
              (getWorklist)="getWorklist($event)">
            </worklist_processMerge>
            <worklist_processSplit 
              *ngIf="partialToDisplay === 'split'"
              [splitWorklist]="splitWorklist"
              [validateSplitItems]="validateSplitItems"
              [currentUser]="currentUser"
              (viewdetails)="viewDetails($event)"
              (resolvemodal)="resolveModal($event)"
              (getWorklist)="getWorklist($event)">
            </worklist_processSplit>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>