<div class="accountManagement">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
      margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.acctMngt_sua">
        <b>Account Management</b><br />
        <br />
        This chart displays the MSIX usage metrics in your State for each of the last 12 months
        in order to understand how and when users are accessing MSIX.<br />
        <br />
        “Total Logins” refers to the total number of sessions users in your State had in MSIX.<br />
        <br />
        “Accounts with an Active Status” is a count of all accounts in the Active, Locked, or Expired status who are
        able to do a self-managed password reset. This data will be available beginning in March 2021. <br />
        <br />
        “Users Logging In” is a unique count of User IDs who accessed MSIX, regardless of the quantity of sessions.<br />
        <br />
        “New Accounts” refers to accounts created in that month.<br />
        <br />
        Data in this chart is refreshed monthly.<br />
        <br />
        To export the data, select “Export” and then open the downloaded zip file to select the dataset you would like to view.
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>Account Management</b><br />
        <br />
        This chart displays the MSIX usage metrics in all States for each of the last 12 months
        in order to understand how and when users are accessing MSIX.<br />
        <br />
        “Total Logins” refers to the total number of sessions users in your State had in MSIX.<br />
        <br />
        “Accounts with an Active Status” is a count of all accounts in the Active, Locked, or Expired status who are
        able to do a self-managed password reset. This data will be available beginning in March 2021.<br />
        <br />
        “Users Logging In” is a unique count of User IDs who accessed MSIX, regardless of the quantity of sessions.<br />
        <br />
        “New Accounts” refers to accounts created in that month.<br />
        <br />
        Data in this chart is refreshed monthly.<br />
        <br />
        To export the data, select “Export” and then open the downloaded zip file to select the dataset you would like to view.
      </p>
    </div>
  </div>
</div>