<div class="worklistHistoryResultsPartial background-grey1 no-padding-top">

  <!--no results found-->
  <div *ngIf="totalRecordsLength === 0" class="layout-flex-column center">
    <h1>No worklists found</h1>
  </div>

  <div *ngIf="totalRecordsLength > 0">

    <!--desktop/tablet search results-->
    <div class="layout-margin-2" *ngIf="!responsiveUI.isMobile && totalRecordsLength > 0">

      <div class="removeBorders">
        <div class="tableHeader">
          <div class="layout-flex-row tableRow headerRow">
            <h1 *ngIf="totalRecordsLength > 1">{{totalRecordsLength}}
              worklists found</h1>
            <h1 *ngIf="totalRecordsLength === 1">{{totalRecordsLength}}
              worklist found</h1>
          </div>
        </div>
      </div>

      <div class="headerTitles layout-flex-row">
        <div class="block flex-35">Student(s)</div>
        <div class="block2 flex-10">Worklist ID</div>
        <div class="block2 flex-20">Worklist Type</div>
        <div class="block2 flex-20">Worklist Status</div>
        <div class="block2 flex-15">Last Updated</div>
      </div>

      <div class="layout-flex-row table2Row flex-inline top-bottom-margin-15 flex" style="margin-left: 18px; padding: 20px 0px"
        *ngFor="let student of fullList">
        <a (click)="viewWorklistHistory(student)" class="flex-35">
          {{getName(student.stuName, 0)}}, {{getName(student.stuName, 1)}}
        </a>
        <p class="small flex-10">{{student.worklistId}}</p>
        <p class="small flex-20">{{student.worklistType}}</p>
        <p class="small flex-20">{{student.worklistStatus}}</p>
        <p class="small flex-15">{{student.lastUpdate}}</p>
      </div>
    </div>

    <div class="center">
      <button class="md-button" *ngIf="totalRecordsLength - (currentPage * itemsPerLoad) > 0"
        (click)="loadMoreResults()">
        Load More
      </button>
    </div>

  </div>