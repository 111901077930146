<div class="worklist-page">
  <div aria-label="Confirm Split" class="confirmMergeDialog">
    <!-- TODO - Need to figure out appropriate ngIf condition to replace ng-cloak-->
    <!--form ng-cloak-->
    <form>
      <mat-toolbar>
        <div class="md-toolbar-tools toolbar">
          <h2>Confirm Split Record</h2>
          <span style="display: flex; flex: auto"></span>
          <button class="md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">
          <div class="layout-flex-row">
            <p>
              Please confirm that you wish to split the following <b>2 individual records</b>. To edit the split records, select 'cancel' and make your correction.
            </p>
          </div>
          <hr class="thinHr">
          <div class="layout-flex-row layout-padding-l-r-17">
            <h3>1.&nbsp;{{modalInfo.masterName}}</h3>
            <span style="flex: 1; box-sizing: border-box"></span>
            <p>MSIX ID: {{modalInfo.masterId}}</p>
          </div>
          <div class="layout-flex-row layout-padding-l-r-17 flex-inline childrenNames" *ngFor="let child of modalInfo.childrenNames">
            <i class="fa fa-caret-right" aria-hidden="true"></i><p>{{child}}</p>
          </div>
          <hr class="thinHr">
          <div class="layout-flex-row layout-padding-l-r-17">
            <h3>2.&nbsp;{{modalInfo.orphanName}}</h3>
            <span style="flex: 1; box-sizing: border-box"></span>
            <p>MSIX ID: {{modalInfo.orphanId}}</p>
          </div>
          <hr class="thinHr">
          <div class="layout-flex-row">
            <div flex class="layout-flex-column layout-padding-l-r-17">
              <div class="layout-flex-row custom-input-container worklist-commentbox-padding">
                <div class="layout-flex-column" flex style="width: 95%">
                  <mat-form-field class="md-block md-input-container-margins background-white"
                  style="border: 2px solid gray">
                    <mat-label class="custom-label">Comments</mat-label>
                    <textarea matInput class="custom-input" [(ngModel)]="nominateSplitObject.comments" maxlength="250" rows="3" 
                      md-select-on-focus [ngModelOptions]="{standalone: true}"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="layout-flex-row commentbox-instructions">
                <p class="small worklist-commentbox-instructions">
                  To protect the student's privacy, do not include any sensitive information such as Social Security Numbers or other Personally Identifiable Information in Comments fields. Comments entered will be shared unedited and in their entirety with other MSIX
                  Users.
                </p>
              </div>
            </div>
          </div>
          <hr class="thinHr">
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="layout-flex-row">
        <hr class="thinHr">
        <a (click)="closeDialog()" style="padding-left:15px"><span>Cancel</span></a>
        <span flex></span>
        <button (click)="confirmingSplit()" class="uppercase md-no-focus md-button right-align">
          Confirm & Close
        </button>
      </mat-dialog-actions>

    </form>
  </div>
</div>
