<div class="reportsFilter filterPotentialDuplicates">
  <div aria-label="Potential Duplicates Filter" class="filter-dialog">
    <div style="flex-direction: column-reverse; display: flex;">

      <div class="layout-row md-dialog-actions">
        <a style="align-items: center; display: flex;" class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
          Reset Filters
        </a>
        <span class="flex" flex></span>
        <button (click)="onSubmit()" type="submit" class="confirmButton md-button">
          SUBMIT
        </button>
      </div>

      <div class="md-dialog-content">
        <div class="layout-column dupeChoice" flex>
          <div class="filterHeader">
            DISPLAY POTENTIAL DUPLICATES BASED ON:
          </div>
          <mat-radio-group class="margin-top" [(ngModel)]="filterParameters.groupChoice.choice">
            <mat-radio-button value="msix">
              <p>MSIX Potential Duplicates (1 State Student ID, multiple MSIX IDs)</p>
            </mat-radio-button>
            <mat-radio-button value="state">
              <p>State MEP System Potential Duplicates (1 MSIX ID, multiple SSIDs)</p>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="filterParameters.authRoleClasses.dataQuality">
          <div class="layout-column" flex>
            <div class="layout-row selectButtons">
              <p>Select</p>
              <a (click)="selectAll()">All</a>
              <p>|</p>
              <a (click)="selectNone()">None</a>
            </div>
            <div class="colPOC layout-wrap layout-row">
              <div class="flex-33" *ngFor="let state of filterParameters.states">
                <mat-checkbox md-no-ink (click)="checkedStates(state.stateKey)" [value]="state.stateKey"
                  [checked]="existsInSelectedStatesArray(state.stateKey)">
                  <p>{{state.name}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="md-toolbar-tools toolbar">
        <h2 class="filter-title">Potential Duplicates Filter</h2>
        <span flex></span>
        <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog"
          style="margin-left: auto; margin-right: 0;">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>