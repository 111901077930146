<div class="reportsFilter filterAccountList filter-dialog layout-column" style="height: 100%; overflow: hidden;">
  <div style="flex-direction: column-reverse; display: flex;">

    <div class="layout-row" style="padding: 15px 30px; align-items: center">
      <a class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
        Reset Filters
      </a>
      <span style="flex: 1; box-sizing: border-box"></span>
      <button type="submit" class="md-button confirmButton" style="margin: 0" (click)="onSubmit()">
        SUBMIT
      </button>
    </div>

    <div class="filter-dialog">
      <div class="md-dialog-content">
        <div class="filter-section dates">
          <div class="layout-row">
            <div class="flex-30">
              <p>
                METRICS DATE RANGE:
              </p>
            </div>

            <div class="flex-70 layout-row date-picker">
              <div class="layout-row start-date-box flex-50">
                <div class="data flex-20">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="MM"
                      [(ngModel)]="filterParameters.metricsDate.startMM" name="month" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
                <p class="date-slash flex-5">/</p>
                <div class="data flex-20">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="DD"
                      [(ngModel)]="filterParameters.metricsDate.startDD" name="day" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
                <p class="date-slash flex-5">/</p>
                <div class="date flex-30">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="YYYY"
                      [(ngModel)]="filterParameters.metricsDate.startYYYY" name="year" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
              </div>
              <div class="layout-row end-date-box flex-50">
                <div class="data flex-20">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="MM"
                      [(ngModel)]="filterParameters.metricsDate.endMM" name="month" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
                <p class="date-slash flex-5">/</p>
                <div class="data flex-20">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="DD"
                      [(ngModel)]="filterParameters.metricsDate.endDD" name="day" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
                <p class="date-slash flex-5">/</p>
                <div class="date flex-30">
                  <div class="md-block">
                    <custom-mat-input-for-filter class="dateInput" customlabel="YYYY"
                      [(ngModel)]="filterParameters.metricsDate.endYYYY" name="year" (ngsubmit)="onSubmit()">
                    </custom-mat-input-for-filter>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr layout-padding class="thickHr">

        <br />
        <div class="layout-row inputFields">
          <div class="layout-column flex-50">
            <mat-form-field placeholder="First Name" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': fNameField.focused}">
                First Name
              </mat-label>
              <input matInput #fNameField="matInput" name="searchFName" type="text"
                [(ngModel)]="filterParameters.firstName" [ngClass]="{'input-selected': fNameField.focused}">
            </mat-form-field>
            <br>
            <mat-form-field placeholder="User ID" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': userIdField.focused}">
                User ID
              </mat-label>
              <input matInput #userIdField="matInput" name="searchUserID" type="text"
                [(ngModel)]="filterParameters.userID" [ngClass]="{'input-selected': userIdField.focused}">
            </mat-form-field>
          </div>
          <div class="layout-column flex-50">
            <mat-form-field placeholder="Last Name" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': lNameField.focused}">
                Last Name
              </mat-label>
              <input matInput #lNameField="matInput" name="searchLName" type="text"
                [(ngModel)]="filterParameters.lastName" [ngClass]="{'input-selected': lNameField.focused}">
            </mat-form-field>
          </div>
        </div>
        <br>
        <br>
        <hr layout-padding class="thickHr">
        <div class="layout-row">
          <div class="layout-column flex-50">
            <p>USER STATUS:</p>
            <div class="multi-select-slim">
              <div *ngFor="let status of filterParameters.userStatusArray">
                <mat-checkbox md-no-ink [checked]="status.checked" [(ngModel)]="status.checked">
                  <filterText>{{status.description}}</filterText>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="layout-column flex-50">
            <p>USER ROLES:</p>
            <div class="layout-row selectButtonsNoMargin">
              <p>Select</p>
              <a (click)="selectRoles('All')">All</a>
              <p>|</p>
              <a (click)="selectRoles('None')">None</a>
            </div>
            <div class="multi-select-box">
              <div *ngFor="let role of filterParameters.userRolesArray">
                <mat-checkbox md-no-ink [checked]="role.checked" [(ngModel)]="role.checked">
                  <filterText>{{role.description}}</filterText>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <hr layout-padding class="thickHr" *ngIf="filterParameters.authRoleClasses.dataDashboards">
        <div class="layout-row" flex *ngIf="filterParameters.authRoleClasses.dataDashboards">
          <div class="layout-column" flex>
            <p>STATES:</p>
            <div class="layout-row selectButtons">
              <p>Select</p>
              <a (click)="selectAll()">All</a>
              <p>|</p>
              <a (click)="selectNone()">None</a>
            </div>
            <div class="colPOC layout-row layout-wrap" flex vertical>
              <mat-checkbox md-no-ink (click)="checkedStates(state.stateKey)"
                [checked]="existsInSelectedStatesArray(state.stateKey)" *ngFor="let state of filterParameters.states"
                class="flex-30">
                <filterText>{{state.name}}</filterText>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Account Metrics Filter</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

  </div>
</div>