import { Component, EventEmitter, Inject, Injectable, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment";
import { AuthService } from "src/app/services/auth.service";
import { ResponsiveUIService } from "src/app/services/responsiveUI.service";
import { SearchService } from "src/app/services/search.service";
import { StateService } from "src/app/services/state.service";
import { ToastService } from "src/app/services/toast.service";
import { User, UserService } from "src/app/services/user.service";
import { WorklistService } from "src/app/services/worklist.service";
import { OutputFileType } from "typescript";
import { cloneDeep } from 'lodash';
import { CookieService } from 'ngx-cookie';
import { catchError, of, tap } from "rxjs";

@Component({
  selector: 'worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.scss']
})
export class WorklistComponent implements OnInit {
  private mergeWorklist: any[] = [];
  private splitWorklist: any[] = [];
  private mergeChecked: any[] = [];
  private mergeCheckedNew: any[] = [];
  private isAdmin: boolean = false;
  private partialToDisplay: string = localStorage.getItem('savedTab');
  private nominateSplitObjectOrphan: boolean = false;
  private reassignStateAdminIndex: number = -1;
  private reassignRegionalAdminIndex: number = -1;
  private reassignDistrictAdminIndex: number = -1;
  corUpdateDR: corMessageObject = {
    message: "", 
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };
  corUpdateMN: corMessageObject = {
    message: "",
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };
  corMessageMN: corMessageObject = {
    message: "",
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };
  corMessageDR: corMessageObject = {
    message: "",
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };

  private dataRequestItems: any = [];
  private validateMergeItems: any = [];
  private nominateMatchItems: any = [];
  private validateSplitItems: any = [];
  private studentMergeIndex: number;
  private studentSplitIndex: number;
  private splitChildIndex: number;
  private mergeHistIndex: number;
  private splitHistIndex: number;
  private studentDRIndex: number;
  private studentMNIndex: number;
  private studentDRCIndex: number;
  private studentMNCIndex: number;
  private validateMergeIndex: number;
  private validateSplitIndex: number;
  private nominateMatchIndex: number;
  private workflowIndex: any = [];
  private worklistHistory: ArrayBuffer[] = [];
  private worklistSplitHistory: ArrayBuffer[] = [];
  private selectedMergeItems: any[] = [];
  private residencyChangeItems: any = [];
  private DRFilteredItems: any = [];
  private modalInfo: {
    masterName: string;
    masterId: string;
    childrenNames: any[];
    orphanName: string;
    orphanId: string;
  };
  private resolveType: any = {};
  private student: any = {};
  private currentUser: any = {};
  private reassignWorklistId: any = {};
  private isCOR: boolean = false;
  private parentWorklistId: any = '';
  private authRoleClasses: any = {};
  private showResolve: boolean = false;
  nominatedStudentChild: any = false;

  constructor(
    private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _State: StateService,
    private _User: UserService,
    private _Toast: ToastService,
    private _AuthService: AuthService,
    private _Search: SearchService,
    private userService: UserService,
    private authService: AuthService,
    private cookieService: CookieService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.checkIfAdmin();
    this.checkRoleClasses();
    this.getWorklist();
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  daysRemaining = function (dateCreated) {
    var dateNow = moment();
    var dueDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').add(14, 'days');
    var daysRemaining = dueDate.diff(dateNow.format("YYYY-MM-DD"), 'days');
    if (daysRemaining === 1) {
      return daysRemaining + ' day';
    }
    else {
      return daysRemaining + ' days';
    }
  };

  processMergeMasterToggle = function (student) {
    var selectedKey = student.key;
    var nominatedMergeItems = this.mergeWorklist;
    var canSubmit = false;
    for (var i = 0; i < nominatedMergeItems.length; i++) {
      if (nominatedMergeItems[i].key == selectedKey) {
        if (nominatedMergeItems[i].demographics.studentStateID === student.demographics.studentStateID) {
          nominatedMergeItems[i].master = true;
          canSubmit = true;
        } else {
          nominatedMergeItems[i].master = false;
          for (var j = 0; j < nominatedMergeItems[i].children; j++) {
            if (nominatedMergeItems[i].children[j].demographics.studentStateID === student.demographics.studentStateID) {
              nominatedMergeItems[i].children[j].master = true;
              canSubmit = true;
            } else {
              nominatedMergeItems[i].children[j].master = false;
            }
          }
        }
        break;
      }
    }
  };

  setPartial() {
    if (localStorage.getItem('savedTab') === null) {
      if (this.dataRequestItems.length != 0) {
        this.partialToDisplay = "dataRequest";
      } else if (this.mergeWorklist.length + this.validateMergeItems.length + this.nominateMatchItems.length != 0) {
        this.partialToDisplay = "merge";
      } else if (this.splitWorklist.length + this.validateSplitItems.length != 0) {
        this.partialToDisplay = "split";
      } else {
        this.partialToDisplay = "move";
      }
    }
  }

  changePartial(partial) {
    this.studentMergeIndex = -1;
    this.studentSplitIndex = -1;
    this.splitChildIndex = -1;
    this.mergeHistIndex = -1;
    this.splitHistIndex = -1;
    this.studentDRIndex = -1;
    this.studentMNIndex = -1;
    this.studentDRCIndex = -1;
    this.studentMNCIndex = -1;
    this.validateMergeIndex = -1;
    this.validateSplitIndex = -1;
    this.nominateMatchIndex = -1;
    this.partialToDisplay = partial;
    localStorage.setItem('savedTab', this.partialToDisplay);
  }

  viewDetails(data) {
    let student = data.student;
    let userActivity = {
      msixID: student.demographics.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this._Search.logUserActivity(userActivity);

    this._router.navigate(['/studentDetails'], {
      queryParams: {
        msixId: student.demographics.msixId,
        stateId: student.demographics.studentStateID,
        state: student.demographics.submittingState,
        name: (student.demographics.lastName1 + " " + student.demographics.lastName2 + ", " + student.demographics.firstName)
      }
    });
  }

  // START ACCORDION CODE
  showWorkflowDetails(index) {
    if (this.workflowIndex === index) {
      this.workflowIndex = -1;
    } else {
      this.workflowIndex = index;
    }
  }

  showDRCorrespondenceDetailsFn(index) {
    if (this.studentDRCIndex === index) {
      this.studentDRCIndex = -1;
    } else {
      this.studentDRCIndex = index;
    }
  }

  showMNCorrespondenceDetailsFn(index) {
    if (this.studentMNCIndex === index) {
      this.studentMNCIndex = -1;
    } else {
      this.studentMNCIndex = index;
    }
  }
  // END ACCORDION CODE


  arrayCompare(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.demographics.stateStudentKey == current.demographics.stateStudentKey
      }).length === 0;
    }
  }

  validateMergeAssignMasters() {
    for (var record in this.validateMergeItems) {
      this.validateMergeItems[record].master = true;
      this.validateMergeItems[record].canValidate = true;
    }
  }

  addSortableDOB(worklistItems, validateSplit) {
    worklistItems.forEach(function (item) {
      if (validateSplit) {
        item.orphan.demographics.birthDateSortable = moment(item.orphan.demographics.birthDate, 'MM/DD/YYYY').format('YYYYMMDD');
      } else {
        item.demographics.birthDateSortable = moment(item.demographics.birthDate, 'MM/DD/YYYY').format('YYYYMMDD');
      }
    });
  }

  getWorklist() {
    let apiCall = this._Worklist.getWorklistItems();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.debug(data);
        this.mergeWorklist = data.nominateMergeItems;
        this.splitWorklist = data.nominateSplitItems;
        this.validateMergeItems = data.validateMergeItems;
        this.validateSplitItems = data.validateSplitItems;
        this.residencyChangeItems = data.residencyChangeItems;
        this.dataRequestItems = data.dataRequestItems;
        this.nominateMatchItems = data.nominateMatchItems;
        this.addSortableDOB(this.mergeWorklist, false);
        this.addSortableDOB(this.validateMergeItems, false);
        this.addSortableDOB(this.splitWorklist, false);
        this.addSortableDOB(this.validateSplitItems, true);
        this.setPartial();
        this.validateMergeAssignMasters();

        //update worklist count in cookies
        let count = data.dataRequestItems.length + data.nominateMatchItems.length + data.nominateMergeItems.length + data.nominateSplitItems.length + data.residencyChangeItems.length + data.validateMergeItems.length + data.validateSplitItems.length;
        this.cookieService.putObject('worklistCount', count);
        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.error.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }


  resolveModal(data) {
    this.resolveType = data.type;
    this.student = data.student;

    const dialogRef = this.dialog.open(ResolveModalDialog, {
      height: 'auto',
      minHeight: '360px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        resolveType: this.resolveType
      },
    });

    dialogRef.afterClosed().subscribe(results => {
      if (this.resolveType === 'move') {
        this.corUpdateMN = results;
        this.resolveNotice(this.student);
      } else {
        this.corUpdateDR = results;
        this.resolveRequest(this.student);
      }
    });
  }

  sendMoveNoticeCorrespondence(corMessageMN) {
    this.corMessageMN = corMessageMN;
    this.sendCorrespondenceHelper(this.corMessageMN);
  }

  sendDataRequestCorrespondence(corMessageDR) {
    this.corMessageDR = corMessageDR;
    this.sendCorrespondenceHelper(this.corMessageDR);
  }

  sendCorrespondenceHelper(msg) {
    this._Worklist.sendChangeOfResidencyMessage(msg).pipe(
      tap(() => {
        this.getWorklist();
        this._Toast.showToast('Message has been sent')
        msg.message = ''
      }),
      catchError(()=> {
        this._Toast.showToast('Message failed to send');
        return of()
      })
    ).subscribe()
    
  }

  resolveNotice(student) {
    let index = this.residencyChangeItems.indexOf(student);
    this.corUpdateMN.corWorklistItem = student;
    let apiCall = this._Worklist.updateChangeOfResidency(this.corUpdateMN);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("Worklist - resolveNotice(): ", data);
        this.studentMNIndex = -1;
        this.corUpdateMN.message = '';
        this.residencyChangeItems.splice(index, 1);
        this._Worklist.getWorklistCount();
        this._Toast.showToast('Move notice dismissed');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - resolveNotice(): ", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  resolveRequest(student) {
    let index = this.dataRequestItems.indexOf(student);
    this.corUpdateDR.corWorklistItem = student;
    let apiCall = this._Worklist.updateChangeOfResidency(this.corUpdateDR);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("Worklist - resolveRequest(): ", data);
        this.studentDRIndex = -1;
        this.corUpdateDR.message = '';
        this.dataRequestItems.splice(index, 1);
        this._Worklist.getWorklistCount();
        this._Toast.showToast('Data request item resolved');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - resolveRequest(): ", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });

    this.dialog.closeAll(); //close since this is now a modal
  }


  checkIfAdmin() {
    this.userService.getCurrentUser().then(dt => {
      let data: any = dt;
      this.currentUser = data;
      console.debug(data);
      var currUserRoles = data.roles;
      for (var role in currUserRoles) {
        if (currUserRoles[role].indexOf("Data Administrator") !== -1) {
          this.isAdmin = true;
          break;
        }
      }
    });
  }

  goToReassign(data) {
    let student = data.student;
    let validateWorklistType = data.validateWorklistType;
    if (validateWorklistType == 'validateMerge') {
      this.reassignWorklistId = student.key;
      this.isCOR = false;
    } else if (validateWorklistType == 'nominateMatch') {
      this.reassignWorklistId = student.key;
      this.isCOR = false;
    } else if (validateWorklistType == 'validateSplit') {
      this.reassignWorklistId = student.orphan.key;
      this.parentWorklistId = student.orphan.parent;
      this.isCOR = false;
    } else if (validateWorklistType == 'dataRequest') {
      this.reassignWorklistId = student.key;
      this.isCOR = true;
    }
    this._router.navigate(['/worklistReassign'], {
      queryParams: {
        worklistId: this.reassignWorklistId,
        parentWorklistId: this.parentWorklistId != '' ? this.parentWorklistId : '',
        isCOR: this.isCOR
      }
    });
  }

  showWorklistHistorySearch(worklistHistorySearchType) {
    this._router.navigate(['/worklistHistory'], {
      queryParams: {
        worklistHistorySearchType: worklistHistorySearchType
      }
    });
  }

  checkRoleClasses() {
    this.authService.isRoleClassAuthorized(['primarySecondary', 'initiateMerge', 'validateMerge', 'worklistSearch']).then(data => {
      this.authRoleClasses = data;
      var roles = this.authRoleClasses;
      if (roles.primarySecondary == true) {
        this.showResolve = !this.showResolve;
      }
    });
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }

  setValidateMerge(data) {
    this.validateMergeIndex = data.i;
  }
}

@Component({
  selector: 'worklist_dataRequest',
  templateUrl: './_dataRequest.html',
  styleUrls: ['./worklist.component.scss']
})
export class WorklistDataRequestComponent implements OnInit {

  @Input() dataRequestItems: any[];
  @Input() showResolve;
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() gotoreassign?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();
  DRFilteredItems: any[];
  studentDRIndex: number = -1;
  DRFilterOptions: string[] = ['All Open Requests', 'Requests Assigned to Me', 'Requests from Me'];
  selectedDRFilter: string;

  currentSortColumn: string = 'column7';
  sortAscending: boolean = false;
  corMessageDR: corMessageObject = {
    message: "",
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };
  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService) {

  }

  ngOnInit(): void {
    this.selectedDRFilter = this.DRFilterOptions[0];
    this.DRFilteredItems = this.dataRequestItems;
    this.sortDR();
  }

  goToSearch() {
    this._router.navigate(['/searchResults']);
  }

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortDR(); //sorting columns for data requests
  }


  sortDR() {
    if (this.currentSortColumn === 'column1') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.demographics.submittingStateAbbv > b.demographics.submittingStateAbbv ? 1 : a.demographics.submittingStateAbbv === b.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.creator.stateCode > b.creator.stateCode ? 1 : a.creator.stateCode === b.creator.stateCode ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column8') {
      this.DRFilteredItems = this.DRFilteredItems.sort((a, b) => a.daysToResolve > b.daysToResolve ? 1 : a.daysToResolve === b.daysToResolve ? 0 : -1);
    }

    if (this.sortAscending === false) {
      this.DRFilteredItems = this.DRFilteredItems.reverse();
    }
  }

  showDRDetailsFn(index) {
    if (this.studentDRIndex === index) {
      this.studentDRIndex = -1;
    } else {
      this.studentDRIndex = index;
    }
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Data Request - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Data Request - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }


  filterDROptions(filter) {
    this.DRFilteredItems = [];
    if (filter == 'Requests Assigned to Me') {
      for (var i in this.dataRequestItems) {
        if (this.dataRequestItems[i].isCreator === false) {
          this.DRFilteredItems.push(this.dataRequestItems[i]);
        }
      }
    }
    else if (filter == 'Requests from Me') {
      for (var k in this.dataRequestItems) {
        if (this.dataRequestItems[k].isCreator === true) {
          this.DRFilteredItems.push(this.dataRequestItems[k]);
        }
      }
    }
    else {
      this.DRFilteredItems = this.dataRequestItems;
    }
  }

  isWorklistRead(flag) {
    if (!flag || flag === false) {
      return true;
    }
    else {
      return false;
    }
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  goToReassign(drStudent) {
    this.gotoreassign.emit({
      student: drStudent,
      validateWorklistType: 'dataRequest'
    })
  }

  resolveModal(drStudent) {
    this.resolvemodal.emit({
      student: drStudent,
      type: 'data'
    });
  }

  sendDataRequestCorrespondence(drStudent) {
    this.corMessageDR.corWorklistItem = drStudent;
    this.sendcorrespondence.emit(this.corMessageDR);
  }

  viewDetails(drStudent) {
    this.viewdetails.emit({ student: drStudent });
  }
}

@Component({
  selector: 'worklist_moveNotice',
  templateUrl: './_moveNotice.html',
  styleUrls: ['./worklist.component.scss']
})
export class MoveNoticeComponent implements OnInit {

  @Input() residencyChangeItems;
  @Input() showResolve;
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();

  currentSortColumn: string = "column7";
  sortAscending: boolean = false;
  MNFilterOptions = ['All Open Notices', 'Notices Assigned to Me', 'Notices from Me'];
  selectedMNFilter: string;
  MNFilteredItems: any = {};
  studentMNIndex;
  sortOrderMN: string;
  corMessageMN: corMessageObject = {
    message: "",
    corWorklistItem: {}, 
    flags: [], 
    reasonForResolve: 'other'
  };
  //@Output() worklistItemsReportBreadcrumbEvent = new EventEmitter();

  constructor(private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService) {

  }

  ngOnInit(): void {
    this.selectedMNFilter = this.MNFilterOptions[0];
    this.MNFilteredItems = this.residencyChangeItems;
    this.sortMN();
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  goToSearch() {
    this._router.navigate(['/searchResults']);
  }

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortMN(); //sorting columns for move notices
  }

  sortMN() {
    let allSameItems: boolean = false; //boolean to track if all values in a column are the same
    if (this.currentSortColumn === 'column1') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.itemType > b.itemType ? 1 : a.itemType === b.itemType ? 0 : -1);
      allSameItems = true;
      let first = this.MNFilteredItems[0].itemType;
      for (let item of this.MNFilteredItems) {
        if (item.itemType != first) {
          allSameItems = false;
          break;
        }
      }
    }
    else if (this.currentSortColumn === 'column6') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.creator.stateCode > b.creator.stateCode ? 1 : a.creator.stateCode === b.creator.stateCode ? 0 : -1);
      allSameItems = true;
      let first = this.MNFilteredItems[0].creator.stateCode;
      for (let item of this.MNFilteredItems) {
        if (item.creator.stateCode != first) {
          allSameItems = false;
          break;
        }
      }
    }
    else if (this.currentSortColumn === 'column7') {
      this.MNFilteredItems = this.MNFilteredItems.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }

    //only reverse the sort order if the sorting boolean is false and if the first and last items in the sort column aren't the same
    if (!this.sortAscending && !allSameItems) {
      this.MNFilteredItems = this.MNFilteredItems.reverse();
    }
  }

  viewDetails(mnStudent) {
    this.viewdetails.emit({ student: mnStudent });
  }

  showMNDetailsFn(index) {
    if (this.studentMNIndex === index) {
      this.studentMNIndex = -1;
    } else {
      this.studentMNIndex = index;
    }
  }

  sendMoveNoticeCorrespondence(mnStudent) {
    this.corMessageMN.corWorklistItem = mnStudent;
    this.sendcorrespondence.emit(this.corMessageMN);
  }

  goToReassign(mnStudent) {
    this._router.navigate(['/worklistReassign'], {
      queryParams: {
        worklistId: mnStudent.key,
        parentWorklistId: '',
        isCOR: true
      }
    });
  }

  resolveModal(mnStudent) {
    this.resolvemodal.emit({
      student: mnStudent,
      type: 'move'
    });
  }

  filterMNOptions(filter) {
    this.MNFilteredItems = [];
    if (filter == 'Notices Assigned to Me') {
      for (var i in this.residencyChangeItems) {
        if (this.residencyChangeItems[i].isCreator === false) {
          this.MNFilteredItems.push(this.residencyChangeItems[i]);
        }
      }
    }
    else if (filter == 'Notices from Me') {
      for (var k in this.residencyChangeItems) {
        if (this.residencyChangeItems[k].isCreator === true) {
          this.MNFilteredItems.push(this.residencyChangeItems[k]);
        }
      }
    }
    else {
      this.MNFilteredItems = this.residencyChangeItems;
    }
  }

  isWorklistRead(flag): boolean {
    if (!flag || flag === false) {
      return true;
    } else {
      return false;
    };
  }

  updateReadFlag(student){
    if (!student.isWorklistRead || student.isWorklistRead === false){
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Move Notice - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Move Notice - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }
}

@Component({
  selector: 'worklist_nominateNearMatch',
  templateUrl: './_nominateNearMatch.html',
  styleUrls: ['./worklist.component.scss']
})
export class NominateNearMatchComponent implements OnInit {

  //@Output() worklistItemsReportBreadcrumbEvent = new EventEmitter();
  @Input() nominateMatchItems: any[];
  @Input() showResolve;
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();
  @Output() getWorklist?= new EventEmitter<any>();
  private nominateMatchObject: WorklistTransactionObject = {  
    action: '',
    master: {},
    children: [],
    canceled: [],
    comments: '',
    orphan: {},
    worklistId: ''
  };
  private selectedNearMatchItems: any[];

  currentSortColumn: string = 'column1';
  sortAscending: boolean = true;
  nominateMatchIndex: number;

  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {

  }

  ngOnInit(): void {
    this.sortNM();
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortNM(); //sorting columns for near match
  }

  sortNM() {
    if (this.currentSortColumn === 'column1') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.submittingStateAbbv > b.demographics.submittingStateAbbv ? 1 : a.demographics.submittingStateAbbv === b.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.gender > b.demographics.gender ? 1 : a.demographics.gender === b.demographics.gender ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.birthDate > b.demographics.birthDate ? 1 : a.demographics.birthDate === b.demographics.birthDate ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column8') {
      this.nominateMatchItems = this.nominateMatchItems.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }
 
    if (this.sortAscending === false) {
        this.nominateMatchItems = this.nominateMatchItems.reverse();
    }
  }

  viewDetails(nmStudent) {
    this.viewdetails.emit({ student: nmStudent });
  }


  goToReassign(nmStudent) {
    this._router.navigate(['/worklistReassign'], {
      queryParams: {
        worklistId: nmStudent.key,
        parentWorklistId: '',
        isCOR: false
      }
    });
  }

  isWorklistRead(flag): boolean {
    if (!flag || flag === false) {
      return true;
    } else {
      return false;
    };
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Near Match - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Near Match - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }

  nominateOrRejectMatchClick(student, action) {
    let index = this.nominateMatchItems.indexOf(student);
    this.nominateMatchObject.action = action;
    this.nominateMatchObject.master = student;
    for (var i in student.children) {
      if (this.selectedNearMatchItems.indexOf(student.children[i]) > -1) {
        this.nominateMatchObject.children.push(student.children[i])
      } else {
        this.nominateMatchObject.canceled.push(student.children[i]);
      }
    }
    var worklistId = student.key;

    let apiCall = this._Worklist.processMatch(this.nominateMatchObject, worklistId);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("Worklist - Nominate Near Match - nominateOrRejectMatchClick(): ", data);
        this.nominateMatchItems.splice(index, 1);
        this.getWorklist.emit();
        //this.nominateMatchIndex is not actually an index but rather the student.key
        //student.key is expected as this.nominateMatchIndex in the logic that expands
        //and closes the individual worklist sections
        //toggle here
        
        if (action == 'validate') {
          this._Toast.showToast('Near match record has been processed');
        } else {
          this._Toast.showToast('Near match record has been rejected');
        }
        this.emptyNominateMatchObject();
        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - Nominate Near Match - nominateOrRejectMatchClick(): ", error);
        // this._Toast.showToast(error.error.developerMessage, 0);
        this.emptyNominateMatchObject();

        subscribed.unsubscribe();
      }
    });
  }

  emptyNominateMatchObject() {
    this.nominateMatchObject = {
      action: '',
      master: {},
      children: [],
      canceled: [],
      comments: '',
      orphan: {},
      worklistId: ''
    };
    this.selectedNearMatchItems = [];
  }

  nominateMatchDetailsFn(index) {
    if (this.nominateMatchIndex === index) {
      this.nominateMatchIndex = -1;
    } else {
      this.nominateMatchIndex = index;
    }
    this.selectedNearMatchItems = [];
  }

  selectNearMatch(studentChild) {
    if (this.selectedNearMatchItems.includes(studentChild)) {
      var nearMatchIndex = this.selectedNearMatchItems.indexOf(studentChild);
      this.selectedNearMatchItems.splice(nearMatchIndex, 1);
    } else {
      this.selectedNearMatchItems.push(studentChild);
    }
  }
}

@Component({
  selector: 'worklist_processMerge',
  templateUrl: './_processMerge.html',
  styleUrls: ['./worklist.component.scss']
})
export class ProcessMergeComponent implements OnInit {

  @Input() nominateMatchItems: any[];
  @Input() validateMergeItems: any[];
  @Input() mergeWorklist: any[];

  @Output() viewdetails?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();
  @Output() getWorklist?= new EventEmitter<any>();
  private countofChecked: number = 0;
  currentSortColumn: string;
  sortAscending: boolean;
  studentMergeIndex: number;
  mergeCheckedNew: any[] = [];
  private nominateMergeObject: any;
  private selectedMergeItems: any[];
  private mergeChecked: any[] = [];

  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {
  }

  ngOnInit(): void {
    this.sortPM();
  }
  
  mergeSelectionLogic(student, index) {
    var listIndex = this.mergeCheckedNew.indexOf(index);
    if (listIndex === -1) {
      this.mergeCheckedNew.push(index);
    } else {
      this.mergeCheckedNew.splice(listIndex, 1);
    }
  }

  removeFromMergeList(student) {
    let removeMergeIndex = this.mergeWorklist.indexOf(this.mergeWorklist);
    let worklistID = student.key;
    let stateStudentKey = student.demographics.stateStudentKey;

    let apiCall = this._Worklist.removeFromWorklist(worklistID, stateStudentKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("Worklist -  Process Merge - removeFromMergeList():", data);
        this.studentMergeIndex = -1;

        this.mergeWorklist.splice(removeMergeIndex, 1);
        this._Toast.showToast('Student removed from merge worklist');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist -  Process Merge - removeFromMergeList():", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });

    //Adjust array of checked boxes if one entry is removed:
    var index = this.mergeCheckedNew.indexOf(this.studentMergeIndex);
    if (index > -1) {
      this.mergeCheckedNew.splice(index, 1);
      for (var j = index; j < this.mergeCheckedNew.length; j++) {
        this.mergeCheckedNew[j] = this.mergeCheckedNew[j] - 1;
      }
    } else {
      for (var k in this.mergeCheckedNew) {
        if (this.mergeCheckedNew[k] > this.studentMergeIndex) {
          this.mergeCheckedNew[k] = this.mergeCheckedNew[k] - 1;
        }
      }
    }
  }

  showMergeDetailsFn(index) {
    if (this.studentMergeIndex === index) {
      this.studentMergeIndex = -1;
    } else {
      this.studentMergeIndex = index;
    }
  }

  nominateMerge(ev) {
    this.getSelectedMergeItems();
    this.nominateMergeObject = {
      master: {},
      children: [],
      comments: ''
    };

    let dialogRef = this.dialog.open(ConfirmMergeDialog, {
      data: {
        selectedMergeItems: this.selectedMergeItems,
        nominateMergeObject: this.nominateMergeObject
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //Prevent re-submission if dialog is just closed
      if(result){
        this.selectedMergeItems = result.selectedMergeItems;
        this.nominateMergeObject = result.nominateMergeObject;
        this.processMergeApi();
      }
    });
  }

  getSelectedMergeItems() {
    this.selectedMergeItems = [];
    for (var record in this.mergeWorklist) {
      if (this.mergeWorklist[record].checked) {
        this.selectedMergeItems.push(this.mergeWorklist[record]);
      }
    }
  }

  // DIALOG BOX
  processMergeApi() {
    for (var record in this.selectedMergeItems) {
      if (this.selectedMergeItems[record] !== this.nominateMergeObject.master) {
        this.nominateMergeObject.children.push(this.selectedMergeItems[record]);
      }
    }

    let apiCall = this._Worklist.processMerge(this.nominateMergeObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.removeItemsFromMergeArray();
        this.countofChecked = 0;
        this.mergeChecked = [];
        this.getWorklist.emit();
        this._Toast.showToast('Merge has been processed');
        console.debug(data);

        subscribed.unsubscribe();
      },
      error: error => {
        this._Toast.showToast(error.error.developerMessage, 0);
        console.error(error);

        subscribed.unsubscribe();
      }
    });
  }

  removeItemsFromMergeArray() {
    for (var record in this.selectedMergeItems) {
      var index = this.mergeWorklist.indexOf(this.selectedMergeItems[record]);
      this.mergeWorklist.splice(index, 1);
    }
  }

  daysRemaining = function (dateCreated) {
    var dateNow = moment();
    var dueDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').add(14, 'days');
    var daysRemaining = dueDate.diff(dateNow.format("YYYY-MM-DD"), 'days');
    if (daysRemaining === 1) {
      return daysRemaining + ' day';
    }
    else {
      return daysRemaining + ' days';
    }
  };

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  goToSearch() {
    this._router.navigate(['/searchResults']);
  }

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortPM(); //sorting columns for move notices
  }

  sortPM() {
    if (this.currentSortColumn === 'column1') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.submittingStateAbbv > b.demographics.submittingStateAbbv ? 1 : a.demographics.submittingStateAbbv === b.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.gender > b.demographics.gender ? 1 : a.demographics.gender === b.demographics.gender ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.birthDateSortable > b.demographics.birthDateSortable ? 1 : a.demographics.birthDateSortable === b.demographics.birthDateSortable ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.mergeWorklist = this.mergeWorklist.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }

    if (this.sortAscending === false) {
      this.mergeWorklist = this.mergeWorklist.reverse();
    }
  }

  viewDetails(pmStudent) {
    this.viewdetails.emit({ student: pmStudent });
  }

  isWorklistRead(flag): boolean {
    if (!flag || flag === false) {
      return true;
    } else {
      return false;
    };
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Process Merge - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Process Merge - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }
}

@Component({
  selector: 'worklist_processSplit',
  templateUrl: './_processSplit.html',
  styleUrls: ['./worklist.component.scss']
})
export class ProcessSplitComponent implements OnInit {
  @Input() splitWorklist: any[];
  @Input() validateSplitItems: any[];
  @Input() currentUser: any;
  currentSortColumn: string = 'column1';
  sortAscending: boolean = true;
  studentSplitIndex: number;
  splitChildIndex: number;
  //@Output() worklistItemsReportBreadcrumbEvent = new EventEmitter();
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() getWorklist?= new EventEmitter<any>();
  @Output() setmodalinfo?= new EventEmitter<object>();
  private nominatedStudentChild: any = null;
  private nominateSplitObjectOrphan: boolean = false;
  private nominateSplitObject: WorklistTransactionObject = {
    action: '',
    master: {},
    children: [],
    canceled: [],
    comments: '',
    orphan: {},
    worklistId: ''
  };
  modalInfo: { masterName: string; masterId: string; childrenNames: any[]; orphanName: string; orphanId: string; } = 
    { masterName: "", masterId: "", childrenNames: [], orphanName: "", orphanId: ""} ;

  sortOrderFlagged: any;


  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {

  }

  ngOnInit(): void {
    this.sortPS();
  }

  showSplitDetailsFn(index) {
    if (this.studentSplitIndex === index) {
      this.studentSplitIndex = -1;
    } else {
      this.studentSplitIndex = index;
    }
    this.nominateSplitObjectOrphan = false;
    this.nominatedStudentChild = null;
  }


  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortPS(); //sorting columns for process split
  }

  sortPS() {
    if (this.currentSortColumn === 'column1') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.submittingStateAbbv > b.demographics.submittingStateAbbv ? 1 : a.demographics.submittingStateAbbv === b.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.gender > b.demographics.gender ? 1 : a.demographics.gender === b.demographics.gender ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.birthDateSortable > b.demographics.birthDateSortable ? 1 : a.demographics.birthDateSortable === b.demographics.birthDateSortable ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.splitWorklist = this.splitWorklist.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }

    if (this.sortAscending === false) {
        this.splitWorklist = this.splitWorklist.reverse();
    }
  }

  daysRemaining = function (dateCreated) {
    var dateNow = moment();
    var dueDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').add(14, 'days');
    var daysRemaining = dueDate.diff(dateNow.format("YYYY-MM-DD"), 'days');
    if (daysRemaining === 1) {
      return daysRemaining + ' day';
    }
    else {
      return daysRemaining + ' days';
    }
  };

  removeFromSplitList(student) {
    let index = this.splitWorklist.indexOf(student);
    var worklistID = student.key;
    var stateStudentKey = student.demographics.stateStudentKey;

    let apiCall = this._Worklist.removeFromWorklist(worklistID, stateStudentKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("Worklist -  Process Split - removeFromSplitList():", data);
        this.studentSplitIndex = -1;
        this.splitWorklist.splice(index, 1);
        this._Toast.showToast('Student removed from split worklist');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - Process Split - removeFromSplitList():", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }
  
  selectChildforSplit(studentChild) {
    if (this.nominatedStudentChild === studentChild) {
      this.nominateSplitObjectOrphan = false;
      this.nominatedStudentChild = null;
      return;
    }
    var splitParent = this.getItemByMsixId(this.splitWorklist, this.studentSplitIndex);
    for (var i = 0; i < splitParent.children.length; i++) {
      if (splitParent.children[i].demographics.stateStudentKey == studentChild.demographics.stateStudentKey) {
        splitParent.children[i].orphan = true;
      } else {
        splitParent.children[i].orphan = false;
      }
    }
    this.nominateSplitObjectOrphan = true;
    this.nominatedStudentChild = studentChild;
  }

  getItemByMsixId(objectList, msixId) {
    for (var item in objectList) {
      if (msixId === objectList[item].demographics.msixId) {
        return objectList[item];
      }
    }
  }

  confirmNominatedSplit(ev) {
    var modalInfo = {
      masterName: "",
      masterId: "",
      childrenNames: [],
      orphanName: "",
      orphanId: ""
    };
    var nominateSplitRecord = this.getItemByMsixId(this.splitWorklist, this.studentSplitIndex);
    this.nominateSplitObject.master = nominateSplitRecord;
    modalInfo.masterName = nominateSplitRecord.demographics.lastName1 + ", " + nominateSplitRecord.demographics.firstName + " " + nominateSplitRecord.demographics.midName;
    modalInfo.masterId = nominateSplitRecord.demographics.msixId;
    for (var i in nominateSplitRecord.children) {
      if (nominateSplitRecord.children[i].orphan) {
        this.nominateSplitObject.orphan = nominateSplitRecord.children[i];
        modalInfo.orphanName = nominateSplitRecord.children[i].demographics.lastName1 + ", " + nominateSplitRecord.children[i].demographics.firstName + " " + nominateSplitRecord.children[i].demographics.midName;
        modalInfo.orphanId = nominateSplitRecord.children[i].demographics.msixId;
      } else {
        this.nominateSplitObject.children.push(nominateSplitRecord.children[i]);
        modalInfo.childrenNames.push(nominateSplitRecord.children[i].demographics.lastName1 + ", " + nominateSplitRecord.children[i].demographics.firstName + " " + nominateSplitRecord.children[i].demographics.midName);
      }
    }
    this.modalInfo = modalInfo;

    let dialogRef = this.dialog.open(ConfirmSplitDialog, {
      data: {
        modalInfo: this.modalInfo,
        nominateSplitObject: this.nominateSplitObject
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //Prevent re-submission if dialog is just closed
      if(result.confirm){
        this.processSplitApi(result.comments);
      }
    });
  }

  processSplitApi(comments) {
    this.nominateSplitObject.comments = comments;
    let apiCall = this._Worklist.processSplit(this.nominateSplitObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.debug(data);
        this.splitWorklist.splice(this.studentSplitIndex, 1);
        this.getWorklist.emit();
        this._Toast.showToast('Split has been processed');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  
  showSplitChildFn(index) {
    if (this.splitChildIndex === index) {
      this.splitChildIndex = -1;
    } else {
      this.splitChildIndex = index;
    }
  }

  viewDetails(psStudent) {
    this.viewdetails.emit({ student: psStudent });
  }

}

@Component({
  selector: 'worklist_validateMerge',
  templateUrl: './_validateMerge.html',
  styleUrls: ['./worklist.component.scss']
})
export class ValidateMergeComponent implements OnInit {

  private validateOrRejectMergeObject: WorklistTransactionObject = {  
    action: '',
    master: {},
    children: [],
    canceled: [],
    comments: '',
    orphan: {},
    worklistId: ''
  };
  @Input() validateMergeItems: any[];
  @Input() worklistHistory: any[];
  @Input() showResolve;
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() gotoreassign?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();
  @Output() setvalidatemerge?= new EventEmitter<object>();
  currentSortColumn: string = 'column1';
  sortAscending: boolean = true;
  validateMergeIndex: number;
  mergeHistIndex: number;
  sortOrderValidate: any;
  //@Output() worklistItemsReportBreadcrumbEvent = new EventEmitter();

  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {

  }

  ngOnInit(): void {
    this.sortVM();
  }

  showMergeHistFn(index) {
    if (this.mergeHistIndex === index) {
      this.mergeHistIndex = -1;
    } else {
      this.mergeHistIndex = index;
    }
  }
  
  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  goToSearch() {
    this._router.navigate(['/searchResults']);
  }

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortVM(); //sorting columns for validate merge
  }

  sortVM() {
    if (this.currentSortColumn === 'column1') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.lastName1 > b.demographics.lastName1 ? 1 : a.demographics.lastName1 === b.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.submittingStateAbbv > b.demographics.submittingStateAbbv ? 1 : a.demographics.submittingStateAbbv === b.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.gender > b.demographics.gender ? 1 : a.demographics.gender === b.demographics.gender ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.birthDateSortable > b.demographics.birthDateSortable ? 1 : a.demographics.birthDateSortable === b.demographics.birthDateSortable ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.msixId > b.demographics.msixId ? 1 : a.demographics.msixId === b.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.demographics.studentStateID > b.demographics.studentStateID ? 1 : a.demographics.studentStateID === b.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column8') {
      this.validateMergeItems = this.validateMergeItems.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : a.dateCreated === b.dateCreated ? 0 : -1);
    }

    if (this.sortAscending === false) {
        this.validateMergeItems = this.validateMergeItems.reverse();
    }
  }

  viewDetails(vmStudent) {
    this.viewdetails.emit({ student: vmStudent });
  }

  showValidateMergeDetailsFn(key) {
    this.validateOrRejectMergeObject.comments = '';
    if (this.validateMergeIndex === key) {
      this.validateMergeIndex = -1;
    } else {
      this.validateMergeIndex = key;
      let apiCall = this._Worklist.getWorklistHistory(key, '');
      let subscribed = apiCall.subscribe({
        next: data => {
          this.worklistHistory = data;
          console.debug(data);

          subscribed.unsubscribe();
        },
        error: error => {
          console.error(error);
          this._Toast.showToast(error.error.developerMessage, 0);

          subscribed.unsubscribe();
        }
      });
    }
    this.setvalidatemerge.emit({i: this.validateMergeIndex});
  }

  validateOrRejectMergeClick(action) {
    this.validateOrRejectMergeObject.children = [];
    this.validateOrRejectMergeObject.action = action;
    var validatedMergeItem = this.getItemById(this.validateMergeItems, this.validateMergeIndex);
    this.validateOrRejectMergeObject.master = validatedMergeItem;
    this.validateOrRejectMergeObject.children = validatedMergeItem.children;
    this.validateRejectMergeHelper(action);
  }

  getItemById(objectList, studentKey) {
    for (var item in objectList) {
      if (studentKey === objectList[item].key) {
        return objectList[item];
      }
    }
  }

  validateRejectMergeHelper(action) {
    //get index number
    var index = 0;
    for (var i = 0; i < this.validateMergeItems.length; i++) {
      if (this.validateMergeItems[i].key === this.validateMergeIndex) {
        index = i;
      }
    }

    let apiCall = this._Worklist.validateMerge(this.validateOrRejectMergeObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        this._Worklist.getWorklistCount();
        this.validateMergeItems.splice(index, 1);
        if (action === 'validate') {
          this._Toast.showToast('Merge was validated');
        } else {
          this._Toast.showToast('Merge was rejected');
        }
        console.log("Worklist - Validate Merge - validateRejectMergeHelper():", data);

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - Validate Merge - validateRejectMergeHelper():", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  goToReassign(mnStudent) {
    this.gotoreassign.emit({
      student: mnStudent,
      validateWorklistType: 'validateMerge'
    })
  }

  isWorklistRead(flag): boolean {
    if (!flag || flag === false) {
      return true;
    } else {
      return false;
    };
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Validate Merge - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Validate Merge - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }
}

@Component({
  selector: 'worklist_validateSplit',
  templateUrl: './_validateSplit.html',
  styleUrls: ['./worklist.component.scss']
})
export class ValidateSplitComponent implements OnInit {

  @Input() validateSplitItems;
  @Input() worklistSplitHistory;
  @Input() showResolve;
  @Output() viewdetails?= new EventEmitter<object>();
  @Output() gotoreassign?= new EventEmitter<object>();
  @Output() resolvemodal?= new EventEmitter<object>();
  @Output() sendcorrespondence?= new EventEmitter<object>();
  currentSortColumn: string = 'column1';
  sortAscending: boolean = true;
  validateSplitIndex;
  splitHistIndex;
  
  validateOrRejectSplitObject: WorklistTransactionObject = {
    action: '',
    master: {},
    children: [],
    canceled: [],
    comments: '',
    orphan: {},
    worklistId: ''
  };
  @Input() validateMergeIndex: any;
  //@Output() worklistItemsReportBreadcrumbEvent = new EventEmitter();

  constructor(private _router: Router,
    private dialog: MatDialog,
    public responsiveUI: ResponsiveUIService,
    private _Worklist: WorklistService,
    private _Toast: ToastService) {
  }

  ngOnInit(): void {
    this.sortVS();
  }

  showSplitHistFn(index) {
    if (this.splitHistIndex === index) {
      this.splitHistIndex = -1;
    } else {
      this.splitHistIndex = index;
    }
  }

  convertDateCreated = function (dateCreated) {
    var convertedCreateDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YYYY');
    return convertedCreateDate;
  };

  goToSearch() {
    this._router.navigate(['/searchResults']);
  }

  daysOnList = function (dateCreated) {
    var dateNow = moment();
    var createDate = moment(dateCreated, 'YYYY-MM-DD hh:mm:ss');
    var daysOnList = dateNow.diff(createDate, 'days');
    return daysOnList;
  };

  sortColumns(column) {
    if (this.currentSortColumn === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.currentSortColumn = column;
      this.sortAscending = true;
    }
    this.sortVS(); //sorting columns for validate split
  }

  sortVS() {
    if (this.currentSortColumn === 'column1') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.lastName1 > b.orphan.demographics.lastName1 ? 1 : a.orphan.demographics.lastName1 === b.orphan.demographics.lastName1 ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column2') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.worklistId > b.worklistId ? 1 : a.worklistId === b.worklistId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column3') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.submittingStateAbbv> b.orphan.demographics.submittingStateAbbv ? 1 : a.orphan.demographics.submittingStateAbbv === b.orphan.demographics.submittingStateAbbv ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column4') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.gender > b.orphan.demographics.gender ? 1 : a.orphan.demographics.gender === b.orphan.demographics.gender ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column5') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.birthDateSortable > b.orphan.demographics.birthDateSortable ? 1 : a.orphan.demographics.birthDateSortable === b.orphan.demographics.birthDateSortable ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column6') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.msixId > b.orphan.demographics.msixId ? 1 : a.orphan.demographics.msixId === b.orphan.demographics.msixId ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column7') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.demographics.studentStateID > b.orphan.demographics.studentStateID ? 1 : a.orphan.demographics.studentStateID === b.orphan.demographics.studentStateID ? 0 : -1);
    }
    else if (this.currentSortColumn === 'column8') {
      this.validateSplitItems = this.validateSplitItems.sort((a, b) => a.orphan.dateCreated > b.orphan.dateCreated ? 1 : a.orphan.dateCreated === b.orphan.dateCreated ? 0 : -1);
    }

    if (this.sortAscending === false) {
      this.validateSplitItems = this.validateSplitItems.reverse();
    }
  }

  viewDetails(vsStudent) {
    this.viewdetails.emit({ student: vsStudent });
  }

  showValidateSplitDetailsFn(index, worklistId) {
    this.validateOrRejectSplitObject.comments = '';
    if (this.validateSplitIndex === index) {
      this.validateSplitIndex = -1;
    } else {
      this.validateSplitIndex = index;
      //get worklist split history

      let apiCall = this._Worklist.getWorklistHistory(worklistId, '');
      let subscribed = apiCall.subscribe({
        next: data => {
          this.worklistSplitHistory = data;
          console.log("Worklist - Validate Split - showValidateSplitDetailsFn(): ", data);

          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Validate Split - showValidateSplitDetailsFn(): ", error);
          this._Toast.showToast(error.error.developerMessage, 0);

          subscribed.unsubscribe();
        }
      });
    }
  }

  validateOrRejectSplitClick(student, action) {
    var index = this.validateSplitItems.indexOf(student);

    this.validateOrRejectSplitObject.action = action;
    var validatedSplitItems = this.getValidateSplitItemById(this.validateSplitItems, this.validateSplitIndex);
    this.validateOrRejectSplitObject.master = validatedSplitItems.master;
    this.validateOrRejectSplitObject.orphan = validatedSplitItems.orphan;
    this.validateOrRejectSplitObject.worklistId = validatedSplitItems.worklistId;
    for (var i in validatedSplitItems.children) {
      this.validateOrRejectSplitObject.children.push(validatedSplitItems.children[i]);
    }

    let apiCall = this._Worklist.validateSplit(this.validateOrRejectSplitObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.validateSplitItems.splice(index, 1);
        this._Toast.showToast(data.body);
        console.log("Worklist - Validate Split - validateOrRejectSplitClick(): ", data);

        subscribed.unsubscribe();
      },
      error: error => {
        console.error("Error - Worklist - Validate Split - validateOrRejectSplitClick(): ", error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  getValidateSplitItemById(objectList, msixId) {
    for (var item in objectList) {
      if (msixId === objectList[item].orphan.demographics.msixId) {
        return objectList[item];
      }
    }
  }

  goToReassign(mnStudent) {
    this.gotoreassign.emit({
      student: mnStudent,
      validateWorklistType: 'validateSplit'
    })
  }

  isWorklistRead(flag): boolean {
    if (!flag || flag === false) {
      return true;
    } else {
      return false;
    };
  }

  updateReadFlag(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.key);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Validate Split - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Validate Split - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }

  updateReadFlagSplit(student) {
    if (!student.isWorklistRead || student.isWorklistRead === false) {
      let apiCall = this._Worklist.updateUnreadFlag(student.worklistId);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("Worklist - Validate Split - updateUnreadFlag(): ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.error("Error - Worklist - Validate Split - updateUnreadFlag(): ", error);
          subscribed.unsubscribe();
        }
      });
    }
    student.isWorklistRead = true; //for unbolding without calling new payload
  }
}

@Component({
  selector: 'worklist_confirmMerge',
  templateUrl: './confirmMerge.html',
  styleUrls: ['./worklist.component.scss']
})
export class ConfirmMergeDialog {

  selectedMergeItems: any;
  nominateMergeObject: any;

  constructor(public dialogRef: MatDialogRef<ConfirmMergeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public responsiveUI: ResponsiveUIService) {

    this.selectedMergeItems = data.selectedMergeItems;
    this.nominateMergeObject = data.nominateMergeObject;
  }

  confirmingMerge() {
    // Parent (i.e. Worklist) has subscribed to this output event so it will be triggered
    let processMergeUpdate = {
      selectedMergeItems: this.selectedMergeItems,
      nominateMergeObject: this.nominateMergeObject
    };
    this.dialogRef.close(processMergeUpdate);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'worklist_confirmSplit',
  templateUrl: './confirmSplit.html',
  styleUrls: ['./worklist.component.scss']
})
export class ConfirmSplitDialog {

  modalInfo: any = {};
  nominateSplitObject: any;

  constructor(public dialogRef: MatDialogRef<ConfirmSplitDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public responsiveUI: ResponsiveUIService) {

    this.modalInfo = data.modalInfo;
    this.nominateSplitObject = data.nominateSplitObject;
  }

  confirmingSplit() {
    this.dialogRef.close({confirm: true, comments: this.nominateSplitObject.comments});
  }

  closeDialog() {
    this.dialogRef.close({confirm: false});
  }
}

@Component({
  selector: 'worklist_resolveModal',
  templateUrl: './resolveModal.html',
  styleUrls: ['./worklist.component.scss']
})
export class ResolveModalDialog {

  resolveType: string;
  @Output() processResolveRequest = new EventEmitter();
  corUpdateDR: corMessageObject = {
    corWorklistItem: {},
    flags: [],
    message: '',
    reasonForResolve: 'other'
  };
  corUpdateMN: corMessageObject = {
    corWorklistItem: {},
    flags: [],
    message: '',
    reasonForResolve: 'other'
  };

  constructor(public dialogRef: MatDialogRef<ResolveModalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
    this.resolveType = data.resolveType;
    if (data.corUpdateMN) {
      this.corUpdateMN = cloneDeep(data.corUpdateMN); 
    } 
    else if (data.corUpdateDR) {
      this.corUpdateDR = cloneDeep(data.corUpdateDR);
    }
  }

  callResolveRequest() {
    this.dialogRef.close(this.corUpdateDR);
  }

  callResolveNotice() {
    this.dialogRef.close(this.corUpdateMN );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

interface WorklistTransactionObject {
  action: '',
  master: {},
  children: any[],
  canceled: any[],
  comments: '',
  orphan: {},
  worklistId: ''
}

interface corMessageObject {
  corWorklistItem: {},
  flags: [],
  message: '',
  reasonForResolve: 'other'
}