<div layout="row" class="reportsFilter learnMoreModal">
  <div aria-label="General Move From DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools md-toolbar toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="display: flex; flex: auto"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div layout="row">
        <div layout="column" flex>
          <p>Click on an <b>MSIX ID</b> to view the child’s Consolidated Student Record for more details on a child’s
            enrollments and qualifying moves.
          </p>
          <div class="add-margin-top">
            <p>Use the <b>Export</b> function to download a copy of the report in CSV format.</p>
          </div>
          <div class="add-margin-top">
            <p>Use the breadcrumbs at the top of the page to return to the main General Move To Report.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>