<div class="reportsFilter filterDataValidity">
  <div aria-label="Filter Data Validity" class="filter-dialog">
    <div style="flex-direction: column-reverse; display: flex;">

      <div class="layout-row" style="padding: 15px 30px; align-items: center">
        <a class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
          Reset Filters
        </a>
        <span style="flex: 1; box-sizing: border-box"></span>
        <button type="submit" class="md-button confirmButton" style="margin: 0" (click)="onSubmit()">
          SUBMIT
        </button>
      </div>

      <div class="md-dialog-content"
        [ngClass]="{'height-185': ((filterParameters.dataViewType != 'State' || !filterParameters.authRoleClasses.dataQuality) && filterParameters.noyes=='yes'), 
      'height-120': ((filterParameters.dataViewType != 'State' || !filterParameters.authRoleClasses.dataQuality) && filterParameters.noyes=='no')}">
        <div class="layout-row filter-section">
          <div class="layout-column flex-40">
            <div class="filterHeader">
              PERFORMANCE PERIOD FILTER:
            </div>
          </div>
          <div class="layout-column flex-60">
            <mat-radio-group style="margin-top: -10px" class="layout-row" [(ngModel)]="filterParameters.noyes">
              <mat-radio-button class="noTog" value="no" (click)="togglePeriodNo()">
                <p>No</p>
              </mat-radio-button>
              <mat-radio-button class="yesTog" value="yes" (click)="togglePeriodYes()">
                <p>Yes</p>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="layout-row filter-section" *ngIf="filterParameters.noyes=='yes'">
          <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.dataPeriodType">
            <mat-radio-button value="Most Recently Closed Period" [checked]="true">
              <p>Last performance period <br />{{filterParameters.recentPeriod}}</p>
            </mat-radio-button>
            <mat-radio-button class="currentPeriod" value="Current Period" style="margin-left: 6px;">
              <p>Current performance period <br />{{filterParameters.currentPeriod}}</p>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <hr layout-padding class="thickHr">
        <div class="layout-row filter-section">
          <div class="layout-column flex-40">
            <div class="filterHeader">
              DRILL DOWN LEVEL:
            </div>
          </div>
          <div class="layout-column flex-60">
            <mat-radio-group style="margin-top: -10px" class="layout-row" [(ngModel)]="filterParameters.dataViewType">
              <mat-radio-button (click)="selectAll()" value="National">
                <p>National</p>
              </mat-radio-button>
              <mat-radio-button class="stateBut" value="State">
                <div *ngIf="!filterParameters.authRoleClasses.dataQuality" (click)="setSelectedStates()">
                  <p>{{filterParameters.userStateName}}</p>
                </div>
                <div *ngIf="filterParameters.authRoleClasses.dataQuality" (click)="selectAll()">
                  <p>State</p>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="filterParameters.authRoleClasses.dataQuality && filterParameters.dataViewType=='State'">
          <div>
            <div class="layout-column" flex>
              <div class="layout-row selectButtons">
                <p>Select</p>
                <a (click)="selectAll()">All</a>
                <p>|</p>
                <a (click)="selectNone()">None</a>
              </div>
              <div class="colPOC layout-wrap layout-row">
                <mat-checkbox md-no-ink (click)="checkedStates(state.stateKey)"
                  [checked]="existsInSelectedStatesArray(state.stateKey)" *ngFor="let state of filterParameters.states"
                  class="flex-30">
                  <p>{{state.name}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="md-toolbar-tools toolbar">
        <h2 class="filter-title">Data Validity Filter</h2>
        <span style="flex: 1; box-sizing: border-box;"></span>
        <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      
    </div>
  </div>