import { Component, Inject, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { StateService } from 'src/app/services/state.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShareMyListDialogComponent } from '../share-my-list-dialog/share-my-list-dialog.component';
import { AddEditStudentToListDialogComponent, IAddEditRemoveStudentFromListDialogData, IStudentListsUpdatePayload } from '../add-edit-student-to-list-dialog/add-edit-student-to-list-dialog.component';
import { catchError, of, Subscription, tap } from 'rxjs';
import { AcceptEditDeleteSharedListDialogComponent, IAcceptEditDeleteSharedListDialogData } from '../accept-edit-delete-shared-list-dialog/accept-edit-delete-shared-list-dialog.component';
import { IUser } from '../reports/types/interfaces';
import { ListNameExistsValidator } from 'src/app/validators/list-name-exists.validator';
import { IListItem } from 'src/app/types/interfaces';
import { IDialogData, MoveNoticeDialogComponent } from '../move-notice-dialog/move-notice-dialog.component';

@Component({
  selector: 'savedList',
  templateUrl: './savedList.component.html',
  styleUrls: ['./savedList.component.scss']
})
export class SavedListComponent implements OnDestroy {
  sub = new Subscription();
  savelists: any[] = [];
  savedListsNames: any[] = [];
  checkedLists: any[] = [];
  uncheckedLists: any[] = [];
  newCheckedLists: any[] = [];
  newListIsChecked: boolean = false;
  deleteListisChecked: boolean = false;
  deleteShareListCheckbox: boolean = false;
  listNameExists: boolean = false;
  filterParameters: any = {};
  checkedMoveNotice: any[] = [];
  uncheckedMoveNotice: any[] = [];
  states: any[] = [];
  showSharedWithMe: boolean = false;
  isMySavedList: boolean = true;
  originialShareUserList: any[] = [];
  sharedLists: any[] = [];
  newListName: string = '';
  shareListName: string = '';
  savelistObject: any = {};
  msixId: string = '0';
  currentMultiMoveList: string = "";
  shareInfo: any = {
    mySavedListsArray: [],
    multiSelectMySavedListsData: [],
    shareUserList: [],
    multiSelectSelectedUsers: [],
    comments: ''
  }
  editDeleteListKey: any = {};
  editListName: string = '';
  deleteListName: string = '';
  deleteShareListName: string = '';
  editListNameExists: boolean = false;
  shareListNameExists: boolean = false;
  submitDisabled: boolean = true;
  corObject: any = {};
  selectedState: any = {};
  selectedDistrict: any = {};
  selectedSchool: any = {};
  districts: any[] = [];
  schools: any[] = [];
  corType: string = '';
  mainPage: string = '';
  indicator: boolean = false;
  stateKey: any = {};
  errors: any = {};
  authRoleClasses: any = {};
  shareListKey: any = {};
  sharedByUser: any = {};
  acceptedOrDeletedList: any = {};
  exportCall: string = "exportSavedListFile";
  CORFilterParameters: any = {};
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private savedListService: SavedListService,
    private searchService: SearchService,
    private userService: UserService,
    private stateService: StateService,
    private worklistService: WorklistService,
    public responsiveUI: ResponsiveUIService) { }

  ngOnInit() {
    this.checkRoleClasses();
    this.getSavedList();
    this.getSavedListsNames();
    this.getStateList();
    this.getShareListUsers();
    this.getSharedLists();
  }


  getSavedList() {
    let subscribed = this.savedListService.getSavelist().subscribe({
      next: data => {
        console.log("SavedList.getSavedList: ", data);
        this.savelists = data;
        for (let list of this.savelists){
          if (list.saveListName === 'My Default List') {
            (list as any).showList = true; //MSIX-3475 My Default List should be expanded by default
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSavedList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Retrieves a list of the names of a user's saved lists
  getSavedListsNames() {
    let apiCall = this.savedListService.getSaveListsNames();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSavedListsNames: ", data);
        this.savedListsNames = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSavedListsNames: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  getSaveListsforMsixId(msixId) {
    let subscribed = this.savedListService.getSaveListsForMsixId(msixId).subscribe({
      next: data => {
        this.checkedLists = data;
        this.openSaveEditDialog(msixId);
        subscribed.unsubscribe();
      },
      error: error => {
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  async checkRoleClasses() {
    await this.authService.isRoleClassAuthorized(['multiMoveNotice']).then(
      (res) => {
        this.authRoleClasses = res;
      }
    );
  }

  // may change once comps are finalized
  getStateList() {
    this.stateService.getStatesData().subscribe(res => this.states = res);
  }

  viewDetails(student) {
    let userActivity = {
      msixID: student.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this.searchService.logUserActivity(userActivity);
    this._router.navigate(['studentDetails'], {
      queryParams: {
        msixId: student.msixId,
        name: student.stuName
      }
    });
  }

  // Share My List Dialog
  shareMyList() {
    this.shareInfo.mySavedListsArray = cloneDeep(this.savelists);
    this.shareInfo.shareUserList = cloneDeep(this.originialShareUserList);

    // Remove 'My Default List' as an option to be able to share the list.
    this.shareInfo.mySavedListsArray = this.shareInfo.mySavedListsArray.filter(
      listItem => (listItem.saveListName !== 'My Default List' && listItem.listStudents.length > 0));

    this.shareInfo.multiSelectMySavedListsData = [];
    this.shareInfo.multiSelectSelectedUsers = [];

    this.dialog.open(ShareMyListDialogComponent, {
      height: 'auto',
      width: '700px',
      minHeight: 'fit-content',
      maxHeight: '600px',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.shareInfo
      },
    });
  }

  //Retrieves a list of users and their details to share a list with
  getShareListUsers() {
    var userDisplayString = '';
    this.userService.getActiveLockedExpiredUsers().subscribe({
      next: data => {
        console.log("SavedList.getActiveLockedExpiredUsers: ", data);
        for (let user of data) {
          let loggedInUsername = this.cookieService.get('username');
          if (loggedInUsername.toUpperCase() != user.username.toUpperCase()) {
            userDisplayString = user.firstName + " " + user.lastName + " [" + user.userState + "; " + user.email + "; " + user.username + "]";
            (user as any).userDisplayString = userDisplayString;
            this.originialShareUserList.push(user);
          }
        }
      },
      error: error => {
        console.log("Error - SavedList.getActiveLockedExpiredUsers: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
      }
    });
  }

  //Opens Create New List popup window
  openCreateListDialog() {
    //Object to pass info needed to the CreateNewList dialog
    const dialogRef = this.dialog.open(CreateNewList, {
      height: 'auto',
      width: this.responsiveUI.isMobile ? '65%' : '550px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.savedListsNames
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSavedList();
        this.getSavedListsNames();
      }
    });
  }

  openSaveEditDialog(msixId: string) {
    this.msixId = msixId;
    const savedEditListObj: IAddEditRemoveStudentFromListDialogData = {
      currentlySavedLists: this.checkedLists,
      isSaved: true,
      
    };
    const dialogRef = this.dialog.open(AddEditStudentToListDialogComponent, {
      height: 'auto',
      width: '650px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: savedEditListObj
    });

    dialogRef.afterClosed().subscribe((result: Omit<IStudentListsUpdatePayload, 'msixID'>) => {
      if (result) {
        this.updateStudentLists(result);
      }
    });
  }

  updateStudentLists(data: Omit<IStudentListsUpdatePayload, 'msixID'> ) {
     const payload: IStudentListsUpdatePayload = {
      msixID: this.msixId,
      ...data
     }
     this.sub.add(this.savedListService.updateStudentToSavedLists(payload).pipe(
      tap(() => {
        this.getSavedList();
        this.getSavedListsNames();
      })
     ).subscribe())
  }

  openEditDeleteListDialog(saveListName: string, listKey: number) {
    this.sub.add(this.savedListService.getSaveListsNames().pipe(
      tap(savedListsNames => {
        const editDeleteListObj: IAcceptEditDeleteSharedListDialogData = {
          listName: saveListName,
          savedListsNames: savedListsNames
        }
        const dialogRef = this.dialog.open(AcceptEditDeleteSharedListDialogComponent, {
          height: 'auto',
          width: '550px',
          minHeight: '250px',
          maxHeight: 'fit-content',
          hasBackdrop: true,
          disableClose: false,
          data: editDeleteListObj
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.handleEditDeleteList(result, listKey)
          }
        });
      })).subscribe())
  }

  handleEditDeleteList(result: {toDelete: boolean, listName: string}, listKey: number) {
    if (result.toDelete) {
      this.sub.add(this.savedListService.deleteSavedList(listKey).pipe(
          tap(() => {
            this.getSavedList();
            this.getSavedListsNames();
          }),
          catchError((error) => {
              this.toastService.showToast(error.data.developerMessage, 0);
              return of(null)
          })
      ).subscribe())
    } else {
      this.sub.add(this.savedListService.editSavedList(listKey, result.listName).pipe(
          tap(() => {
            this.getSavedList();
            this.getSavedListsNames();
          }),
          catchError((error) => {
              this.toastService.showToast(error.data.developerMessage, 0);
              return of(null)
          })
      ).subscribe())
    }

  }

  sortColumn(columnName, saveList) {
    if (saveList.sortColumn == columnName) {
      saveList.sortAscending = !saveList.sortAscending;
    } else {
      saveList.sortAscending = true;
    }
    saveList.sortColumn = columnName;

    let apiCall = this.savedListService.sortSavedList(saveList);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.sortSavedList: ", data);
        for (let list of this.savelists) {
          if (list.saveListKey == saveList.saveListKey) {
            let index = this.savelists.indexOf(list);
            this.savelists[index] = data;
            (this.savelists[index] as any).showList = true;
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.sortSavedList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  openMultiMoveNoticeDialog() {
    if (this.checkedMoveNotice.length > 0) {
      const data: IDialogData = {
        states: this.states,
        corType: 'multiMove',
        checkedMoveNotice: this.checkedMoveNotice}
        
      this.dialog.open(MoveNoticeDialogComponent, {
        width: '750px',
        hasBackdrop: true,
        disableClose: false,
        data,
      });
    }
  }

  //Functionality reduced to only remove the student from saved lists while in mobile view
  removeStudentInMobile(msixId, listId) {
    this.savelistObject = {
      msixID: msixId,
      savedListKeys: [],
      unsavedListKeys: [listId],
      newListName: this.newListName
    }
    let apiCall = this.savedListService.updateStudentToSavedLists(this.savelistObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.removeStudentInMobile(): ", data);
        this.getSavedList();
        this.checkedLists = [];
        this.uncheckedLists = [];
        this.newCheckedLists = [];
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.removeStudentInMobile(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Removes list in mobile view
  deleteListInMobile(saveListKey) {
    let apiCall = this.savedListService.deleteSavedList(saveListKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.deleteListInMobile(): ", data);
        this.getSavedList();
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.deleteListInMobile(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //
  //Retrieves a list of the lists that have been shared from other users
  getSharedLists() {
    let apiCall = this.savedListService.getSharedLists();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSharedLists(): ", data);
        this.sharedLists = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSharedLists(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Opens the modal to accept or delete a shared list
  openAcceptDeleteListDialog(saveListName: string, saveListKey:number, sharedByUser: any) {
    this.sub.add(this.savedListService.getSaveListsNames().pipe(
      tap(savedListsNames => {
        const acceptDeleteSharedListObj: IAcceptEditDeleteSharedListDialogData = {
          listName: saveListName,
          acceptShared: true,
          savedListsNames: savedListsNames
        }
        const dialogRef = this.dialog.open(AcceptEditDeleteSharedListDialogComponent, {
          height: 'auto',
          width: '550px',
          minHeight: '250px',
          maxHeight: 'fit-content',
          hasBackdrop: true,
          disableClose: false,
          data: acceptDeleteSharedListObj
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.acceptDeleteList(saveListKey, sharedByUser, result);
          }
        });
      })
    ).subscribe())

  }

  acceptDeleteList(saveListKey: number, sharedByUser: IUser, result:{listName: string, toDelete: boolean}) {
    const payload = {
      sharedByUser,
      sharedSaveListKey: saveListKey,
      sharedSaveListName: result.listName,
      toDelete: result.toDelete
    };
    this.sub.add(this.savedListService.acceptDeleteSharedList(payload).pipe(
      tap(() => {
        this.getSavedList();
        this.getSharedLists();
      }),
      catchError((error) => {
        this.toastService.showToast(error.data.developerMessage, 0);
        return of(error)
      })
    ).subscribe())
  }

  deleteSharedListFromMobile(shareListName, shareListKey, sharedByUser) {
    let acceptedOrDeletedList = {
      sharedSaveListName: shareListName,
      sharedSaveListKey: shareListKey,
      sharedByUser: sharedByUser,
      toDelete: true
    }

    let apiCall = this.savedListService.acceptDeleteSharedList(acceptedOrDeletedList);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.acceptDeleteSharedListAPICall(): ", data);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.acceptDeleteSharedListAPICall(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Displays the Send Multi-Move Notice button and checkboxes if items 
  //from another saved list have not already been selected
  checkCurrentMultiMoveList(saveListKey) {
    if (this.currentMultiMoveList == '') {
      return true;
    }
    else if (this.currentMultiMoveList === saveListKey) {
      return true;
    } else {
      return false;
    }
  }

  selectAllforMoveNotice(saveListKey, listStudents) {
    for (let student of listStudents) {
      let index = this.checkedMoveNotice.indexOf(student.msixId);
      if (index === -1) {
        this.checkedMoveNotice.push(student.msixId);
      }
    }

    if (!this.currentMultiMoveList) {
      this.currentMultiMoveList = saveListKey;
    }
  }

  selectNoneforMoveNotice(saveListKey) {
    if (this.currentMultiMoveList === saveListKey) {
      this.checkedMoveNotice = [];
      this.currentMultiMoveList = "";
    }
  }

  checkedMoveNoticeStudents(saveListKey, msixId) {
    this.currentMultiMoveList = saveListKey;
    let checkedIndex = this.checkedMoveNotice.indexOf(msixId);
    if (checkedIndex === -1) {
      this.checkedMoveNotice.push(msixId);
    } else {
      this.checkedMoveNotice.splice(checkedIndex, 1);
    }

    if (this.checkedMoveNotice.length == 0) {
      this.currentMultiMoveList = "";
    }
  }

  existsInMoveNotice(msixId) {
    let index = this.checkedMoveNotice.indexOf(msixId);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  createExportObj(key) {
    let exportList: any = {
      saveListKey: key
    };
    return exportList;
  }
 ngOnDestroy(): void {
   this.sub.unsubscribe();
 }
 }

// Separate component for the createNewList dialog
@Component({
  selector: 'createNewList',
  templateUrl: './createNewList.html',
  styleUrls: ['./savedList.component.scss']
})
export class CreateNewList {
  savedListsNames: IListItem[] = [];
  form: FormGroup
  constructor(
    public dialogRef: MatDialogRef<CreateNewList>,
    private savedListService: SavedListService,
    private toastService: ToastService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.savedListsNames = cloneDeep(data.params);
    this.form = this.fb.group({
      newListName: ['', [Validators.required, Validators.maxLength(60), ListNameExistsValidator(this.savedListsNames.map(l => l.saveListName))]]
    })
  }

  onClose() {
    this.dialogRef.close();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  // Calls service api to send new list name to the backend to be created
  createNewSaveList() {
    let apiCall = this.savedListService.createNewSaveList(this.form.get('newListName').value);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.createNewSaveList: ", data);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.createNewSaveList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
    this.dialogRef.close(true);
  }
}

// Separate component for the accepting or deleting a shared list
// @Component({
//   selector: 'acceptDeleteSharedList',
//   templateUrl: './acceptDeleteSharedList.html',
//   styleUrls: ['./savedList.component.scss']
// })
// export class AcceptDeleteSharedList {
//   acceptDeleteSharedListObj: any = {
//     shareListName: '',
//     deleteShareListName: '',
//     savedListsNames: [],
//     shareListNameExists: false,
//     shareListKey: 0,
//     sharedByUser: {}
//   };
//   deleteShareListCheckbox: boolean = false;
//   constructor(
//     public dialogRef: MatDialogRef<AcceptDeleteSharedList>,
//     private savedListService: SavedListService,
//     private toastService: ToastService,
//     @Inject(MAT_DIALOG_DATA) public data: any) {
//     this.acceptDeleteSharedListObj = cloneDeep(data.params);
//   }

//   // checks the input box on the Share/Delete List modal to see if it matches the name of another list
//   checkShareListName() {
//     for (let list of this.acceptDeleteSharedListObj.savedListsNames) {
//       if (this.acceptDeleteSharedListObj.shareListName.toUpperCase() === list.saveListName.toUpperCase()) {
//         this.acceptDeleteSharedListObj.shareListNameExists = true;
//         return true;
//       }
//     }
//     this.acceptDeleteSharedListObj.shareListNameExists = false;
//     return false;
//   }


//   //toggles the checkbox to delete a shared list and display a message
//   toggleShareDelete() {
//     this.deleteShareListCheckbox = !this.deleteShareListCheckbox;
//     if (this.deleteShareListCheckbox) {
//       this.acceptDeleteSharedListObj.shareListName = this.acceptDeleteSharedListObj.deleteShareListName;
//     }
//   }

//   // This function is the main call from desktop view to accept or delete the shared list
//   acceptDeleteSharedList() {
//     let acceptedOrDeletedList: any = {
//       sharedSaveListName: this.acceptDeleteSharedListObj.shareListName,
//       sharedSaveListKey: this.acceptDeleteSharedListObj.shareListKey,
//       sharedByUser: this.acceptDeleteSharedListObj.sharedByUser,
//       toDelete: this.deleteShareListCheckbox
//     }

//     let apiCall = this.savedListService.acceptDeleteSharedList(acceptedOrDeletedList);
//     let subscribed = apiCall.subscribe({
//       next: data => {
//         console.log("SavedList.acceptDeleteSharedListAPICall(): ", data);
//         subscribed.unsubscribe();
//       },
//       error: error => {
//         console.log("Error - SavedList.acceptDeleteSharedListAPICall(): ", error);
//         this.toastService.showToast(error.data.developerMessage, 0);
//         subscribed.unsubscribe();
//       }
//     });

//     this.dialogRef.close();
//   }

//   onClose() {
//     this.dialogRef.close();
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }
// }

// Separate component for the saveEditList dialog
// @Component({
//   selector: 'saveEditList',
//   templateUrl: '../student-details/student-details-dialog.html',
//   styleUrls: ['./savedList.component.scss']
// })
// export class SaveEditList {
//   savedListsNames: any[] = [];
//   isSaved: boolean = true;
//   newListIsChecked: boolean = false;
//   listNameExists: boolean = false;
//   checkedListsTracker: any = {};
//   msixId: number = 0;

//   constructor(
//     public dialogRef: MatDialogRef<SaveEditList>,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private savedlistService: SavedListService,
//     private toastService: ToastService,
//     public responsiveUI: ResponsiveUIService) {
//     this.checkedListsTracker = cloneDeep(data.params.checkedListsTracker);
//     this.savedListsNames = this.data.params.savedListsNames;
//     this.msixId = data.params.studentMsixId;
//   }

//   // creates a new list and adds the student to that list
//   checkNewList() {
//     if (!this.newListIsChecked) {
//       this.checkedListsTracker.newListName = '';
//     }
//   }

//   // sets the ng-checked to true or false based on if savedListKey exists in the checkedLists
//   existsInCheckedLists(saveListKey) {
//     let index = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
//     if (index > -1) {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   checkCreateListName() {
//     for (let list of this.savedListsNames) {
//       if (this.checkedListsTracker.newListName && list.saveListName.toUpperCase() === this.checkedListsTracker.newListName.trim().toUpperCase()) {
//         this.listNameExists = true;
//         return true;
//       }
//     }
//     this.listNameExists = false;
//     return false;
//   }

//   // adds and removes a saved student from the selected saved lists
//   setCheckedLists(saveListKey) {
//     let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
//     let uncheckedIndex = this.checkedListsTracker.uncheckedLists.indexOf(saveListKey);
//     let newCheckedIndex = this.checkedListsTracker.newCheckedLists.indexOf(saveListKey);
//     if (checkedIndex === -1) {
//       this.checkedListsTracker.checkedLists.push(saveListKey);
//       this.checkedListsTracker.newCheckedLists.push(saveListKey);
//       if (uncheckedIndex > -1) {
//         this.checkedListsTracker.uncheckedLists.splice(uncheckedIndex, 1);
//       }
//     } else {
//       this.checkedListsTracker.checkedLists.splice(checkedIndex, 1);
//       if (newCheckedIndex > -1) {
//         this.checkedListsTracker.newCheckedLists.splice(newCheckedIndex, 1);
//       }
//       this.checkedListsTracker.uncheckedLists.push(saveListKey);
//     }
//   }

//   //checks the checkbox to create a new list if the user enters a new list name
//   selectCreateListCheckbox() {
//     if (this.checkedListsTracker.newListName.length > 0) {
//       this.newListIsChecked = true;
//     } else {
//       this.newListIsChecked = false;
//     }
//   }

//   //Add student to all of the saved lists
//   selectAll() {
//     for (let list of this.savedListsNames) {
//       let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(list.saveListKey);
//       if (checkedIndex === -1) {
//         this.checkedListsTracker.checkedLists.push(list.saveListKey);
//         this.checkedListsTracker.newCheckedLists.push(list.saveListKey);
//       }
//     }
//     this.checkedListsTracker.uncheckedLists = [];
//   }

//   //Remove student from all lists
//   selectNone() {
//     for (let key of this.checkedListsTracker.checkedLists) {
//       this.checkedListsTracker.uncheckedLists.push(key);
//     }
//     this.checkedListsTracker.checkedLists = [];
//     this.checkedListsTracker.newCheckedLists = [];
//     this.newListIsChecked = false;
//   }

//   onClose() {
//     this.dialogRef.close();
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

//   onSubmit(): void {
//     let savelistObject = {
//       msixID: this.msixId,
//       savedListKeys: this.checkedListsTracker.newCheckedLists,
//       unsavedListKeys: this.checkedListsTracker.uncheckedLists,
//       newListName: this.checkedListsTracker.newListName
//     }

//     let apiCall = this.savedlistService.updateStudentToSavedLists(savelistObject);
//     let subscribed = apiCall.subscribe({
//       next: data => {
//         console.log(data);

//         this.checkedListsTracker.uncheckedLists = [];
//         this.checkedListsTracker.newCheckedLists = [];
//         subscribed.unsubscribe();
//       },
//       error: error => {
//         console.log(error);
//         this.toastService.showToast(error.data.developerMessage, 0);
//         subscribed.unsubscribe();
//       }
//     });
//     this.dialogRef.close();
//   }
// }

// Separate component for the EditDeleteList dialog
// @Component({
//   selector: 'editDeleteList',
//   templateUrl: './editDeleteList.html',
//   styleUrls: ['./savedList.component.scss']
// })
// export class EditDeleteList {
//   savedListsNames: any[] = [];
//   editListName: string = '';
//   deleteListName: string = '';
//   deleteListisChecked: boolean = false;
//   editListNameExists: boolean = false;
//   editDeleteListKey: any = {};
//   constructor(
//     public dialogRef: MatDialogRef<EditDeleteList>,
//     private savedListService: SavedListService,
//     private toastService: ToastService,
//     @Inject(MAT_DIALOG_DATA) public data: any) {
//     this.savedListsNames = this.data.params.savedListsNames;
//     this.editListName = this.data.params.editListName;
//     this.deleteListName = this.data.params.editListName;
//     this.editDeleteListKey = this.data.params.editDeleteListKey;
//   }

//   onClose() {
//     this.dialogRef.close();
//   }


//   onNoClick(): void {
//     this.dialogRef.close();
//   }

//   // checks the input box on the Edit/Delete List page to see if it matches the name of another list
//   checkEditListName() {
//     for (let list of this.savedListsNames) {
//       if (this.editListName.toUpperCase() !== this.deleteListName.toUpperCase() && list.saveListName.toUpperCase() === this.editListName.trim().toUpperCase()) {
//         this.editListNameExists = true;
//         return true;
//       }
//     }
//     this.editListNameExists = false;
//     return false;
//   }

//   toggleDelete() {
//     this.deleteListisChecked = !this.deleteListisChecked;
//     if (this.deleteListisChecked) {
//       this.editListName = this.deleteListName;
//     }
//   }

//   editDeleteList() {
//     if (this.deleteListisChecked) {
//       let apiCall = this.savedListService.deleteSavedList(this.editDeleteListKey);
//       let subscribed = apiCall.subscribe({
//         next: data => {
//           console.log("SavedList.deleteSavedList: ", data);
//           subscribed.unsubscribe();
//         },
//         error: error => {
//           console.log("Error - SavedList.deleteSavedList: ", error);
//           this.toastService.showToast(error.data.developerMessage, 0);
//           subscribed.unsubscribe();
//         }
//       });
//     } else {
//       let apiCall = this.savedListService.editSavedList(this.editDeleteListKey, this.editListName);
//       let subscribed = apiCall.subscribe({
//         next: data => {
//           console.log("SavedList.editSavedList: ", data);
//           subscribed.unsubscribe();
//         },
//         error: error => {
//           console.log("Error - SavedList.editSavedList: ", error);
//           this.toastService.showToast(error.data.developerMessage, 0);
//           subscribed.unsubscribe();
//         }
//       });
//     }

//     this.dialogRef.close();
//   }
// }

// Separate component for the COR dialog
// @Component({
//   selector: 'corDialog',
//   templateUrl: '../student-details/corDialog.html',
//   styleUrls: ['./savedList.component.scss']
// })
// export class CorDialog {
//   filterParameters: any;
//   CORForm = this.formBuilder.group({
//     stateIndex: null,
//     districtIndex: null,
//     schoolIndex: null,
//     moveNotice: null,
//     multiMoveNotice: null,
//     comments: null
//   });
  
//   indicator: boolean = false;
//   textValue: string = '';
//   submitDisabled: boolean = true;
//   selectedDistrict: any;
//   selectedSchool: any;
//   corObject: any;
//   selectedState: any;
//   stateKey: any[] = [];
//   states: any[] = [];
//   districts: any[] = [];
//   errors: any;
//   currentMultiMoveList: string = "";
//   constructor(
//     public dialogRef: MatDialogRef<CorDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     private formBuilder: FormBuilder,
//     public responsiveUI: ResponsiveUIService,
//     private stateService: StateService,
//     private toastService: ToastService,
//     private savedListService: SavedListService) {
//     this.filterParameters = cloneDeep(this.data.params);
//     this.filterParameters.moveNotice = true;
//   }

//   getDistricts() {
//     this.submitDisabled = false;
//     this.filterParameters.selectedSchool.schoolIndex = null;
//     this.filterParameters.corObject.district = null;
//     this.filterParameters.corObject.school = null;

//     if (!this.filterParameters.selectedState.stateIndex) {
//       this.filterParameters.selectedState.stateIndex = 0;
//     }

//     this.stateKey = this.filterParameters.states[this.filterParameters.selectedState.stateIndex].stateKey;
//     this.filterParameters.corObject.state = this.filterParameters.states[this.filterParameters.selectedState.stateIndex];
//     if (this.stateKey) {
//       let apiCall = this.stateService.getStateDistricts2(this.stateKey);
//       // Callback for success
//       let subscribed = apiCall.subscribe({
//         next: data => {
//           this.filterParameters.districts = data;
//           subscribed.unsubscribe();
//         },
//         // Callback for failure
//         error: err => {
//           console.log(err);
//           this.toastService.showToast(err.developerMessage, 0);
//           this.errors = err.data.errors;
//           subscribed.unsubscribe();
//         }
//       });
//     } else {
//     }
//   }

//   getSchoolsList() {
//     this.filterParameters.selectedSchool.schoolIndex = null;
//     this.filterParameters.corObject.district = this.filterParameters.districts[this.filterParameters.selectedDistrict.districtIndex];
//     this.filterParameters.corObject.school = null;


//     if (this.filterParameters.corObject.district) {
//       let apiCall = this.stateService.getDistrictSchools(this.stateKey, this.filterParameters.corObject.district.key);
//       // Callback for success
//       let subscribed = apiCall.subscribe({
//         next: data => {
//           console.log(data);
//           this.filterParameters.schools = data;
//           subscribed.unsubscribe();
//         },
//         // Callback for failure
//         error: err => {
//           console.log(err);
//           this.toastService.showToast(err.developerMessage, 0);
//           this.errors = err.data.errors;
//           subscribed.unsubscribe();
//         }
//       });
//     } else {
//     }
//   }

//   onSubmit() {
//     this.filterParameters.corObject.comments = this.textValue;
//     this.filterParameters.corObject.school = this.filterParameters.selectedSchool;
//     this.filterParameters.corObject.isLeaving = this.filterParameters.moveNotice;
//     let multiMoveNotification = {
//       msixIDs: this.filterParameters.checkedMoveNotice,
//       moveNotification: this.filterParameters.corObject
//     }
    
//     let apiCall = this.savedListService.sendMultiMoveNotice(multiMoveNotification);
//     let subscribed = apiCall.subscribe({
//       next: data => {
//         console.log("SavedList.sendMultiMoveNotice: ", data);
//         this.filterParameters.checkedMoveNotice = [];
//         this.currentMultiMoveList = "";
//         this.toastService.showToast('Your multi-move notification has been sent');
//         subscribed.unsubscribe();
//       },
//       error: error => {
//         console.log("Error - SavedList.sendMultiMoveNotice: ", error);
//         this.toastService.showToast(error.data.developerMessage, 0);
//         subscribed.unsubscribe();
//       }
//     });
//     this.dialogRef.close();
//   }

//   closeDialog() {
//     this.dialogRef.close();
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }
// }