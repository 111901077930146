import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, Observable, of, pairwise, startWith, Subscription, tap } from 'rxjs';
import { SavedListService } from 'src/app/services/savedlist.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { IDistrict, IStateData } from 'src/app/types/interfaces';

export interface IDialogData {
        states: IStateData[],
        checkedMoveNotice?: string[],
        corType?: 'moveNotice' | 'requestData' | 'multiMove'
}

type MoveNoticePayload = {
    comments: string;
    isLeaving: boolean;
    district: IDistrict;
    school: {
        schoolIndex: string | null;
    };
    state: IStateData;
}

type MultiMoveNoticePayload = {
    moveNotification: MoveNoticePayload,
    msixIDs?: string[]
};

@Component({
    selector: 'app-move-notice-dialog.component',
    templateUrl: './move-notice-dialog.component.html',
    styleUrls: ['./move-notice-dialog.component.scss']
})
export class MoveNoticeDialogComponent implements OnInit, OnDestroy {
    form: FormGroup;
    data: IDialogData;
    subs = new Subscription();
    districts$: Observable<any[]>;
    schools$: Observable<any[]>;
    title: string = 'Multi-Move Notice';

    constructor(private fb: FormBuilder, 
        private stateService: StateService,
        private toastService: ToastService,
        private dialogRef: MatDialogRef<MoveNoticeDialogComponent>,
        private worklistService: WorklistService,
        private savedListService: SavedListService,
        @Inject(MAT_DIALOG_DATA) data: IDialogData) {
       
        this.data = data;
        this.title = this.data.corType === 'moveNotice' ? 'Move Notice' : this.data.corType === 'requestData' ? 'Data Request' : 'Multi-Move Notice'
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            isLeaving: [false],
            comments: [''],
            state: [null, [Validators.required]],
            district: [],
            school: []
        })
    }

    ngOnInit(): void {
      
        this.subs.add(this.form.valueChanges.pipe(
            startWith(this.form.value),
            pairwise(),
            tap(([prev, curr]) => {
                const stateChange = prev.state?.stateKey !== curr.state?.stateKey;
                const districtChange = prev.district?.key !== curr.district?.key;
               if (stateChange) {
                    this.districts$ = this.stateService.getStateDistricts2(curr.state.stateKey)
               }
               if (districtChange) {
                this.schools$ = this.stateService.getDistrictSchools(curr.state.stateKey, curr.district.key)
               }
            }),
          
        ).subscribe());

       
    }

    onSubmit() {
        const payload: MoveNoticePayload = {
            comments: this.form.get('comments').value,
            isLeaving: this.form.get('isLeaving').value,
            district: this.form.get('district').value,
            school: {
                schoolIndex: this.form.get('school').value,
            },
            state: this.form.get('state').value,
        }

        if (this.data.corType === 'moveNotice' || this.data.corType === 'requestData') {
            this.dialogRef.close(payload)
        } else {
            (payload as unknown as MultiMoveNoticePayload).moveNotification = payload;
            (payload as unknown as MultiMoveNoticePayload).msixIDs = this.data.checkedMoveNotice;
           this.subs.add(this.savedListService.sendMultiMoveNotice(payload).pipe(
            tap(() => {
                
                this.toastService.showToast('Your multi-move notification has been sent')}),
            catchError((error) => {
                this.toastService.showToast(error.data.developerMessage, 0);
                return of(null)
            }),
            finalize(() => this.dialogRef.close())
    
           ).subscribe())
        }
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}