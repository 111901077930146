import { Injectable } from '@angular/core';
import {AppConstants} from '../../config/index.constants';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IChildDetails, IStudent, IStudentDetailsHistorical } from './types';
// import { studentDetails, studentHistorical, childDetails } from './tmp';

const API_URL =  AppConstants.api;

@Injectable({providedIn: 'root'})
export class StudentDetailsSummerService {
    constructor(private http: HttpClient) { }

    getStudentDetailsSummer(msixId: string): Observable<IStudent> {
        // return of(studentDetails)
        return this.http.get<IStudent>(API_URL + 'student/msix/' + msixId);
    }

    getHistoricalViewData(msixId: string): Observable<IStudentDetailsHistorical> {
        // return of(studentHistorical)
        return this.http.get<IStudentDetailsHistorical>(API_URL + `student/msix/${msixId}/historical`);
    }

    getRawDetails(msixId: string): Observable<any> {
        return this.http.get<any>(API_URL + `student/msix/${msixId}/raw`);
    }

    getChildDetails(msixId: string): Observable<IChildDetails> {
        // return of(childDetails) as any
        return this.http.get<any>(API_URL + `reconciliation/msix/${msixId}`);
    }
}