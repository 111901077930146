import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HTMLExportService {
    toolbarDisplay: boolean;

    constructor() {
        this.toolbarDisplay = true;
    }

    hideToolbar() {
        this.toolbarDisplay = false;
    }

    showToolbar() {
        this.toolbarDisplay = true;
    }

}
