<div layout="row" class="reportsFilter learnMoreModal">
  <div aria-label="General Move From Learn More" class="filter-dialog">
    <div class="md-toolbar-tools md-toolbar toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="display: flex; flex: auto"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div layout="row">
        <div layout="column" flex>
          <p>A child will appear on this report if the child has two or more enrollment records or qualifying moves in
            multiple States. Use this report to understand mobility patterns for migratory children in your State.
          </p>
          <div class="add-margin-top">
            <p>Use the <b>Filter</b> function to change the performance period and select whether you want the moves
              reported
              by enrollments or qualifying moves. </p>
          </div>
          <div class="add-margin-top">
            <p>Select a <b>Moved To State</b> or country name to view the list of migratory children who moved from
              your
              State to the selected State.
            </p>
          </div>
          <div class="add-margin-top">
            <p>Use the <b>Export</b> function to download a copy of the report in CSV or PDF format.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>