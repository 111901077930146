import { Component, Inject } from '@angular/core';
//import { Moment } from 'moment';
import * as moment from 'moment';
import * as angular from "angular";
//import * as debounce from 'lodash';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SearchService } from 'src/app/services/search.service';
import { AccountMetricsService } from './accountMetrics.service';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'accountMetrics',
  templateUrl: './accountMetrics.component.html',
  styleUrls: ['./accountMetrics.component.scss']
})
export class AccountMetricsComponent {
  router: string;
  userID: string = '';
  showFilter: boolean = true;
  isInit: boolean = false;
  showDrill: boolean = false;
  isReportLoadedAtLeaseOnce: boolean = false;
  userRoles: any = {};

  jobsArray: any[];
  jobTitle: any;
  response: any;
  blankRes: boolean = true;
  exportCall: any = 'getAccountMetricsExport';
  drilldownExportCall: any = '';
  drilldownExportParams: any = {};
  drillDownBlankRes: boolean = false;
  userKeys: any[];
  // holds the list of states
  states: any[] = [];
  // holds the set of selected stateKeys
  fullStateArray: any[] = [];
  showHistory: boolean;
  disableWithdrawalDate: boolean;
  params: any;
  stateMap: any;
  userRolesArray: any[] = [];
  drillDownType: string = '';
  districtKeys: any[] = [];
  districtChips: any[] = [];

  resetFilters: boolean;
  reportInfo: any;
  drillDown: any = {};
  currentSortColumn: string;
  sortAscending: boolean;
  stayOnDrillDown: boolean;
  drillDownDetails: any;
  drillDownData: any;
  disablePrev: boolean;
  disableNext: boolean;
  userKeysIndex: number;
  reportGroupVar: string;
  reportDrillDownVar: string;
  assignedDistricts: any[];
  //hold export count for Reports
  exportCount: number;
  accountMetricsReportBeingRow: number;
  accountMetricsReportEndRow: number;


  //holds filter parameters
  filterParameters: any;
  selectedStatesArray: any[];
  userTypeArray: any;
  metricsDate: any = {};

  // holds the current active filter parameters
  activeFilter: any;
  activeSelectedStatesArray: any;
  activeStatusArray: any;
  activeTypeArray: any;
  activeMetricsDate: any;

  //this object holds current & recent reporting period for display purposes
  displayData = {
    current: "",
    recent: "",
    category: "",
    subcategory: ""
  };
  subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private cookieService: CookieService,
    private searchService: SearchService,
    private userService: UserService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private service: AccountMetricsService,
    private previousRouteService: PreviousRouteService) {
    this.router = _router.url;
    this.response = {};
    this.drillDown = {};
    this.userKeys = [];
    this.resetFilters = false;
    this.userKeysIndex = 0;
    this.stayOnDrillDown = false;
    this.disablePrev = false;
    this.disableNext = false;
    this.reportGroupVar = "report";
    this.reportDrillDownVar = "findUserAccountMetrics";
    //holds values for maintaining sort
    this.sortAscending = true;
    this.currentSortColumn = 'column1';
    this.exportCount = 0;
    this.showHistory = false;
    this.disableWithdrawalDate = false;
    this.reportInfo = {};
    this.drillDownDetails = {};
    this.drillDownData = {};
    this.stateMap = new Map();
    this.filterParameters = {
      selectedStatesList: [],
      filterRolesList: [],
      firstName: null,
      lastName: null,
      userID: null,
      metricsDate: {},
      userStatusArray: []
    };
    this.selectedStatesArray = [];
    this.userTypeArray = [];
    this.jobTitle = {
      other: ''
    };
  }

  ngOnInit() {
    // Set User Roles list for the User Account Metrics report. Not all roles are displayed.
    this.userRoles = this.cookieService.getObject('userRoles');
    for (var idx = this.userRoles.length - 1; idx >= 0; idx--) {
      let item = this.userRoles[idx];
      if (item.description !== 'District Data Administrator' &&
        item.description !== 'MSIX Primary User' &&
        item.description !== 'Regional Data Administrator' &&
        item.description !== 'Secondary User' &&
        item.description !== 'State Data Administrator') {
        this.userRoles.splice(idx, 1);
      }
    }

    this.resetFilter();

    // Setting active Metrics Date to default empty values.
    this.activeMetricsDate = JSON.parse(JSON.stringify(this.metricsDate));
    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.getReportInfo();
  }

  // gets info needed to generate report
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['accountMetrics', 'userAccount', 'superUser', 'dataDashboards']);

    this.mineAndSetStatesArray(this.reportInfo.stateData);
    this.setSelectedStates();
    this.setDefaultUserStatusSelection();
    this.setDefaultUserRoleSelection();
    this.filterActivate();
  }

  // Trigger Filter Modal
  filterActivate() {
    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.userState = JSON.parse(JSON.stringify(this.reportInfo.userState));
    this.filterParameters.metricsDate = JSON.parse(JSON.stringify(this.metricsDate));
    this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));
    this.filterParameters.userRolesArray = JSON.parse(JSON.stringify(this.userRolesArray));
    this.filterParameters.userRoles = JSON.parse(JSON.stringify(this.userRoles));
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));

    this.showFilter = true;
    console.log('DataManagement filter has been opened');
    const dialogRef = this.dialog.open(FilterAccountMetrics, {
      height: 'auto',
      width: '58%',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log('Filter has been closed', result);
      //Prevent re-submission if dialog is just closed
      if (result) {
        this.filterParameters = result;
        this.metricsDate = JSON.parse(JSON.stringify(this.filterParameters.metricsDate));
        this.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.selectedStatesArray));
        this.userRolesArray = JSON.parse(JSON.stringify(this.filterParameters.userRolesArray));
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // This function is called when 'Submit' is clicked on the Account Metrics report filter popup
  submitFilter() {
    // Reset sort column for the Account Metrics report
    this.isInit = true;
    this.filterParameters.sort = 'USER_ID_ASC';

    // Resetting it to the first page
    this.filterParameters.beginRow = 1;
    this.filterParameters.endRow = 20;

    this.sortByColumn('column1', true);

    this.submitReport();
  }

  // initialize the report
  submitReport() {
    this.formatDate();


    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.activeSelectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
    this.filterParameters.filterStatusList = this.getSelectedStatusArray();
    this.filterParameters.filterRolesList = this.getSelectedUserRolesArray();
    this.activeMetricsDate = JSON.parse(JSON.stringify(this.metricsDate));

    if (this.filterParameters && this.filterParameters.selectedStatesList && this.filterParameters.filterRolesList &&
      (this.filterParameters.selectedStatesList.length === 0 || this.filterParameters.filterStatusList?.length === 0 || this.filterParameters.filterRolesList.length === 0)) {
      this.blankRes = true;
      this.response = {};
    } else {
      this.drillDown = this.filterParameters;
      let apiCall = this.reportsService.getAccountMetricsInfo(this.filterParameters);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("AccountMetrics.submitReport(): ", data);
          this.blankRes = false;
          this.response = data;
          this.response.drillDown = this.drillDown;
          if (this.response.rows.length === 0) {
            this.blankRes = true;
          }
          this.userKeys = [];
          this.userKeys = this.response.rows.map((i) => i.originalResult.userKey);

          //Update sorted data in service
          this.service.accountMetricsData = data;
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - AccountMetrics.submitReport(): ", error);
          this.blankRes = true;
          subscribed.unsubscribe();
        }
      });
    }
  }

  goToReport() {
    this.isInit = false;
    this.showDrill = false;

    if (this.accountMetricsReportBeingRow && this.accountMetricsReportEndRow) {
      // If accountMetricsReportBeingRow and accountMetricsReportEndRow are set, then coming back from drill down page.
      // Reset the beingRow and endRow filter parameters as it was changed when user navigated to drilldown page.
      this.filterParameters.beginRow = this.accountMetricsReportBeingRow;
      this.filterParameters.endRow = this.accountMetricsReportEndRow;
    } else {
      this.filterParameters.beginRow = 1;
      this.filterParameters.endRow = 20;
    }

    this.filterParameters.sort = 'USER_ID_ASC';
    this.reportDrillDownVar = "findUserAccountMetrics";
    this.response = this.service.accountMetricsData;
  }

  // Gets the drilldown export based on the filter parameters set and the for the User that is drilldowned to.
  setExportData() {
    let params = JSON.parse(JSON.stringify(this.filterParameters));
    params.userID = this.userID;
    this.drilldownExportParams = params;
    this.drilldownExportCall = 'getAccountMetricsDrilldownExport';
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(AccountMetricsDDLearnMore, {
        height: 'auto',
        width: '55%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: { params: this.drillDownType },
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(AccountMetricsLearnMore, {
        height: 'auto',
        width: '55%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        this.showFilter = false;
      });
    }
  }

  handleReportSpecialSortClick(newSortKey) {
    this.isInit = false;
    this.filterParameters.sort = newSortKey.sortKey;
    this.resetFilters = false;
    this.submitReport();
  }

  formatDate() {
    if (this.filterParameters.metricsDate.startYYYY || this.filterParameters.metricsDate.startMM || this.filterParameters.metricsDate.startDD) {
      var startMetricsDate = this.filterParameters.metricsDate.startYYYY + '-' + this.filterParameters.metricsDate.startMM + '-' + this.filterParameters.metricsDate.startDD;
      this.filterParameters.metricsStartDate = moment(startMetricsDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.metricsStartDate = null;
    }
    if (this.filterParameters.metricsDate.endYYYY || this.filterParameters.metricsDate.endMM || this.filterParameters.metricsDate.endDD) {
      var endMetricsDate = this.filterParameters.metricsDate.endYYYY + '-' + this.filterParameters.metricsDate.endMM + '-' + this.filterParameters.metricsDate.endDD;
      this.filterParameters.metricsEndDate = moment(endMetricsDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.metricsEndDate = null;
    }
  }


  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.userAccount == true) {
      this.filterParameters.selectedStatesList = this.selectedStatesArray;
    } else {
      this.filterParameters.selectedStatesList = [{ key: this.reportInfo.userState }];
    }
  }

  // Sets the default selection of User Roles
  setDefaultUserRoleSelection() {
    let allUserRoles = this.userRoles;

    this.userRolesArray = [];
    for (var i = 0; i < allUserRoles.length; i++) {
      let isChecked = true;

      this.userRolesArray.push({
        key: allUserRoles[i].key,
        description: allUserRoles[i].description,
        checked: isChecked
      });

    }

    this.filterParameters.filterRolesList = this.getSelectedUserRolesArray();
  }

  // Resets the selected roles array that is two-way bind to the checkboxes
  setSelectedUserRolesArray() {
    if (!this.filterParameters || !this.filterParameters.filterRolesList) {
      this.setDefaultUserRoleSelection();
    } else {
      for (var i = 0; i < this.userRolesArray.length; i++) {
        let foundRole = this.filterParameters.filterRolesList.find(role => role.key == this.userRolesArray[i].key);
        if (foundRole) {
          this.userRolesArray[i].checked = true;
        } else {
          this.userRolesArray[i].checked = false;
        }
      }
    }
  }

  // Creates and returns an array of only selected User statuses.
  getSelectedUserRolesArray() {
    let selectedRoles = [];

    for (var i = 0; i < this.userRolesArray.length; i++) {
      if (this.userRolesArray[i].checked == true) {
        selectedRoles.push(this.userRolesArray[i]);
      }
    }

    return selectedRoles;
  }


  // Creates and returns an array of only selected User statuses.
  getSelectedStatusArray() {
    let selectedStatus = [];

    for (var i = 0; i < this.filterParameters.userStatusArray.length; i++) {
      if (this.filterParameters.userStatusArray[i].checked == true) {
        selectedStatus.push(this.filterParameters.userStatusArray[i]);
      }
    }

    return selectedStatus;
  }

  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(obj);
      }
    }

    this.activeSelectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
  }

  //Pull in Drilldown data
  drillDownInit(data, column, columnName, drillDownCount) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down

    console.log("Drilldown data: " + data);
    this.isInit = false;
    this.showDrill = true;
    this.drillDownType = data.columnName;
    this.userID = data.data.userID;
    this.drillDownDetails = data;
    // date unix > readable conversion
    if (moment(this.drillDownDetails.metricsDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.metricsDate) {
      this.drillDownDetails.metricsDate = moment(parseInt(this.drillDownDetails.metricsDate)).format("MM/DD/YYYY");
    }

    //replace missing data with "-"
    let obj = this.drillDownDetails
    for (var key in obj) {
      if (obj[key] === "Invalid date" || obj[key] === null || obj[key] === " ") {
        obj[key] = "-"
      }
    }
    this.filterParameters.userKey = data.data.userKey;
    this.filterParameters.drillDownReport = data.columnName;
    if (data.columnName === "Consolidated Student Records Downloaded") {
      this.filterParameters.sort = 'MSIX_ID_ASC';
    } else {
      this.filterParameters.sort = 'WORKLIST_ID_ASC';
    }

    // Save the current page information that user is on for the User Account Metrics reprot before user navigates to drilldown page.
    this.accountMetricsReportBeingRow = this.filterParameters.beginRow;
    this.accountMetricsReportEndRow = this.filterParameters.endRow;

    this.filterParameters.beginRow = 1;
    this.filterParameters.endRow = 20;
    this.drillDown = this.filterParameters;
    this.drillDown.totalAvailableRows = data.drillDownCount;

    this.stayOnDrillDown = false;
    window.scrollTo(0, 0);
    this.updateDrilldown(this.drillDown);
    this.userKeysIndex = this.userKeys.indexOf(data.data.userKey);

    // disable prev / next button based on location in results
    if (this.userKeysIndex === 0) {
      this.disablePrev = true;
    } else {
      this.disablePrev = false;
    }
    if ((this.userKeysIndex === this.userKeys.length - 1)) {
      this.disableNext = true;
    } else {
      this.disableNext = false;
    }
  }

  // get Drill down data
  updateDrilldown(drillDown) {
    this.drillDownData = {};
    this.reportDrillDownVar = "findUserAccountMetricsDrillDown";
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("AccountMetrics.submitReport(): ", data);
        if (data.rows.length === 0) {
          this.drillDownBlankRes = true;
        } else {
          this.drillDownBlankRes = false;
          this.drillDownData = data;
          this.drillDownData.totalAvailableRows = drillDown.totalAvailableRows;
          this.drillDownData.drillDown = drillDown;
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - AccountMetrics.submitReport(): ", error);
        subscribed.unsubscribe();
      }
    });
    this.setExportData();
  }


  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sort = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  resetFilter() {
    this.resetFilters = true;
    this.filterParameters = {
      firstName: null,
      lastName: null,
      userID: null,
      metricsStartDate: null,
      metricsEndDate: null,
      selectedStatesList: null,
      availableStatesList: [],
      sort: 'USER_ID_ASC',
      beginRow: 1,
      endRow: 20,
      exportType: 'CSV'
    };
    this.metricsDate = {
      startMM: null,
      startDD: null,
      startYYYY: null,
      endMM: null,
      endDD: null,
      endYYYY: null
    };

    this.setDefaultUserStatusSelection();   // Set default User Status selection and filter Status list.
    this.setDefaultUserRoleSelection();     // Set default User Rolese selection.
  }


  // Sets the default selection of User Status
  setDefaultUserStatusSelection() {
    let allUserStatuses: any = this.cookieService.getObject('userStatuses');

    this.filterParameters.userStatusArray = [];
    for (var i = 0; i < allUserStatuses.length; i++) {
      let isChecked = true;

      if (allUserStatuses[i].description == "Deactivated") {
        isChecked = false;
      }

      this.filterParameters.userStatusArray.push({
        key: allUserStatuses[i].key,
        description: allUserStatuses[i].description,
        checked: isChecked
      });

    }
  }

  skipToMetricsStartDD() {
    if (this.metricsDate.startMM) {
      if (this.metricsDate.startMM.toString().length >= 2) {
        document.getElementById("metricsStartDD").focus();
      }
    }
  }

  skipToMetricsStartYYYY() {
    if (this.metricsDate.startDD) {
      if (this.metricsDate.startDD.toString().length >= 2) {
        document.getElementById("metricsStartYYYY").focus();
      }
    }
  }

  skipToMetricsEndDD() {
    if (this.metricsDate.endMM) {
      if (this.metricsDate.endMM.toString().length >= 2) {
        document.getElementById("metricsEndDD").focus();
      }
    }
  }

  skipToMetricsEndYYYY() {
    if (this.metricsDate.endDD) {
      if (this.metricsDate.endDD.toString().length >= 2) {
        document.getElementById("metricsEndYYYY").focus();
      }
    }
  }

  navigateRecords(direction) {
    this.isInit = false;
    if (direction === 'next') {
      this.userKeysIndex++;
    } else if (direction === 'prev') {
      this.userKeysIndex--;
    }
    this.stayOnDrillDown = true;
    this.drillDownInit(this.response.rows[this.userKeysIndex].originalResult, '', '', '');
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data, ascending) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  //Pull in Drilldown data for worklist
  openIDPage(data, column) {
    console.log("drilldown page name: " + this.drillDownType);
    if (data.column === 'column1' && this.drillDownType != 'Consolidated Student Records Downloaded') {
      let workListType = data.type;

      // Setting the worklist type to 'DataRequest' for the Data Requests drilldowns (Initiated and Responded To) as it is not passed back from the API call
      if (this.drillDownType == 'Data Requests Initiated' || this.drillDownType == 'Data Requests Responded To') {
        workListType = 'DataRequest';
      }
      //TODO 
      /* var url = this.$state.href('app.worklistHistoryRecord', {
        worklistId: data.worklistKey,
        worklistType: workListType,
        worklistStatus: data.status
      });
      window.open(url, '_blank'); */
    }
    else if (data.column === 'column3' || this.drillDownType === 'Consolidated Student Records Downloaded') {
      let userActivity = {
        msixID: data.msixID,
        pageName: 'APP_STU_VW_CONS',
        activityNotes: '',
        sessionID: ''
      }
      this.searchService.logUserActivity(userActivity);

      // Open Student Details Consolidated view page in new tab
      //TODO 
      /* var studentDetailsConsURL = this.$state.href('app.studentDetails', {
        msixId: data.msixID
      });
      window.open(studentDetailsConsURL, '_blank'); */
    }
  }
}

//Separate component for the AccountMetrics report's filter
export interface FilterParameters {
  params: {
    selectedStatesArray: any;
    userStatusArray: any;
    userTypeArray: any;
    metricsDate: any;
  }
}
@Component({
  selector: 'filterAccountMetrics',
  templateUrl: './filterAccountMetrics.html',
  styleUrls: ['./accountMetrics.component.scss']
})
export class FilterAccountMetrics {
  filterParameters: any;
  selectedStatesArray: any;
  userTypeArray: any;
  metricsDate: any;
  resetFilters: boolean = false;
  districtKeys: any[] = [];
  districtChips: any[] = [];
  userRoles: any = {};
  userRolesArray: any[] = [];
  fullStateArray: any[] = [];
  reportInfo: any = {};
  constructor(public dialogRef: MatDialogRef<FilterAccountMetrics>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cookieService: CookieService) {
    this.filterParameters = data.params;
  }

  onSubmit() {
    this.dialogRef.close(this.filterParameters);
  }

  // sets filter initially and resets filter when called
  resetFilter() {
    this.resetFilters = true;
    this.filterParameters = {
      metricsDate: {},
      firstName: "",
      lastName: "",
      userId: null,
      userRolesArray: {},
      userRoles: this.data.params.userRoles,
      authRoleClasses: this.data.params.authRoleClasses,
      states: this.data.params.states,
      fullStateArray: this.data.params.fullStateArray,
      selectedStatesArray: this.data.params.selectedStatesArray
    }
    this.selectAll();
    this.setDefaultUserStatusSelection();   // Set default User Status selection and filter Status list.
    this.setDefaultUserRoleSelection();     // Set default User Rolese selection.
    //this.selectAllTypes();
    this.districtKeys = [];
    this.districtChips = [];
  }


  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    let array = this.filterParameters.selectedStatesArray;
    let index = array.map(function (e) {
      return e.key;
    }).indexOf(stateKey);
    if (index === -1) {
      let obj = {
        key: stateKey
      }
      this.filterParameters.selectedStatesArray.push(obj);
    } else {
      this.filterParameters.selectedStatesArray.splice(index, 1);
    }
    this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray;
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    let array = this.filterParameters.selectedStatesArray;
    let index = array.map(function (e) {
      return e.key
    }).indexOf(stateKey);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  // Sets the default selection of User Roles
  setDefaultUserRoleSelection() {
    let allUserRoles = this.filterParameters.userRoles;

    this.filterParameters.userRolesArray = [];
    for (var i = 0; i < allUserRoles.length; i++) {
      let isChecked = true;

      this.filterParameters.userRolesArray.push({
        key: allUserRoles[i].key,
        description: allUserRoles[i].description,
        checked: isChecked
      });

    }

    this.filterParameters.filterRolesList = this.getSelectedUserRolesArray();
  }

  // Sets the default selection of User Status
  setDefaultUserStatusSelection() {
    let allUserStatuses: any = this.cookieService.getObject('userStatuses');

    this.filterParameters.userStatusArray = [];
    for (var i = 0; i < allUserStatuses.length; i++) {
      let isChecked = true;

      if (allUserStatuses[i].description == "Deactivated") {
        isChecked = false;
      }

      this.filterParameters.userStatusArray.push({
        key: allUserStatuses[i].key,
        description: allUserStatuses[i].description,
        checked: isChecked
      });

    }
  }

  skipToMetricsStartDD() {
    if (this.metricsDate.startMM) {
      if (this.metricsDate.startMM.toString().length >= 2) {
        document.getElementById("metricsStartDD").focus();
      }
    }
  }

  skipToMetricsStartYYYY() {
    if (this.metricsDate.startDD) {
      if (this.metricsDate.startDD.toString().length >= 2) {
        document.getElementById("metricsStartYYYY").focus();
      }
    }
  }

  skipToMetricsEndDD() {
    if (this.metricsDate.endMM) {
      if (this.metricsDate.endMM.toString().length >= 2) {
        document.getElementById("metricsEndDD").focus();
      }
    }
  }

  skipToMetricsEndYYYY() {
    if (this.metricsDate.endDD) {
      if (this.metricsDate.endDD.toString().length >= 2) {
        document.getElementById("metricsEndYYYY").focus();
      }
    }
  }

  selectAll() {
    var roles = this.filterParameters.authRoleClasses;
    if (roles.userAccount == true) {
      this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.fullStateArray));
      this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray
    } else {
      this.filterParameters.selectedStatesList = [{ key: this.filterParameters.userState }]
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray
  }

  selectRoles(allOrNone) {
    let isChecked = false;

    if (allOrNone == 'All') {
      isChecked = true;
    }

    for (var i = 0; i < this.filterParameters.userRolesArray.length; i++) {
      this.filterParameters.userRolesArray[i].checked = isChecked;
    }

    this.filterParameters.filterRolesList = this.getSelectedUserRolesArray();
  }

  // Creates and returns an array of only selected User statuses.
  getSelectedUserRolesArray() {
    let selectedRoles = [];

    for (var i = 0; i < this.filterParameters.userRolesArray.length; i++) {
      if (this.filterParameters.userRolesArray[i].checked == true) {
        selectedRoles.push(this.filterParameters.userRolesArray[i]);
      }
    }

    return selectedRoles;
  }

  closeDialog(): void {
    this.setSelectedStatusArray();
    this.setSelectedUserRolesArray();
    this.dialogRef.close();
  }

  // Resets the selected roles array that is two-way bind to the checkboxes
  setSelectedUserRolesArray() {
    if (!this.filterParameters || !this.filterParameters.filterRolesList) {
      this.setDefaultUserRoleSelection();
    } else {
      for (var i = 0; i < this.userRolesArray.length; i++) {
        let foundRole = this.filterParameters.filterRolesList.find(role => role.key == this.userRolesArray[i].key);
        if (foundRole) {
          this.userRolesArray[i].checked = true;
        } else {
          this.userRolesArray[i].checked = false;
        }
      }
    }
  }

  // Creates and returns an array of only selected User statuses.
  getSelectedStatusArray() {
    let selectedStatus = [];

    for (var i = 0; i < this.filterParameters.userStatusArray.length; i++) {
      if (this.filterParameters.userStatusArray[i].checked == true) {
        selectedStatus.push(this.filterParameters.userStatusArray[i]);
      }
    }

    return selectedStatus;
  }

  // Resets the selected status array that is two-way bind to the checkboxes
  setSelectedStatusArray() {
    if (!this.filterParameters || !this.filterParameters.filterStatusList) {
      this.setDefaultUserStatusSelection();
    } else {
      for (var i = 0; i < this.filterParameters.userStatusArray.length; i++) {
        let foundStatus = this.filterParameters.filterStatusList.find(role => role.key == this.filterParameters.userStatusArray[i].key);
        if (foundStatus) {
          this.filterParameters.userStatusArray[i].checked = true;
        } else {
          this.filterParameters.userStatusArray[i].checked = false;
        }
      }
    }
  }

}

// Separate component for the AccountMetrics report's Learn More dialog box
@Component({
  selector: 'accountMetricsLearnMore',
  templateUrl: 'accountMetricsLearnMore.html',
  styleUrls: ['./accountMetrics.component.scss']
})
export class AccountMetricsLearnMore {
  constructor(
    public dialogRef: MatDialogRef<AccountMetricsLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the AccountMetrics report's drilldown Learn More dialog box
export interface LearnMoreDDParameters {
  drillDownType: string
}
@Component({
  selector: 'accountMetricsDDLearnMore',
  templateUrl: 'accountMetricsDDLearnMore.html',
  styleUrls: ['./accountMetrics.component.scss']
})
export class AccountMetricsDDLearnMore {
  drillDownType: string = '';
  constructor(
    public dialogRef: MatDialogRef<AccountMetricsDDLearnMore>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.drillDownType = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}