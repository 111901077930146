
<mat-toolbar matDialogTitle>
    <h2>{{step === 1 ? 'Select List to Share (Step 1 of 3)' : step === 2 ? 'Select Users to Share List With (Step 2 of 3)' : 'Share Lists Summary (Step 3 of 3)' }}</h2>
    <button class="md-button md-icon-button" matDialogClose aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</mat-toolbar>

<mat-dialog-content *ngIf="step === 1">
    <p>
        Use the List Search box to search for the name of the list you want to share, then select the desired list from
        the dropdown results. The list name will appear in the Selected Lists box.
    </p>
    <form [formGroup]="form">
        <div class="label">LIST SEARCH:&nbsp;</div>Select <span class="link" (click)="handleAddAll()">All</span>
        <mat-form-field appearance="outline">
            <input matInput formControlName="listName" [matAutocomplete]="auto" />
            <mat-autocomplete #auto [autoActiveFirstOption]="true" (optionSelected)="handleOptionSelected($event)">
                <ng-container *ngFor="let list of filteredLists$ | async ">
                    <mat-option [value]="list.saveListName">{{list.saveListName}}</mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
        <ng-container *ngIf="mySavedListsArray.length - selectedLists.size === 0">
            <span class="no-lists">You have no Lists available to share.</span>
        </ng-container>
    </form>
    <mat-divider></mat-divider>
    <div class="selected">
        <p>
            Use the green “X” to remove lists from the selection. Select “Next” to choose the users who will receive the
            lists.
        </p>
        <div class="label">SELECTED LIST(S):&nbsp;</div>Select <span class="link"
            (click)="handleRemoveAll()">None</span>
        <mat-chip-listbox class="selected-lists-box">
            <mat-basic-chip *ngFor="let listName of selectedLists" (removed)="onListItemRemove(listName)">
                <button matChipRemove>
                    <mat-icon class="clear-chip-icon">clear</mat-icon>
                </button>
                {{listName}}
            </mat-basic-chip>
        </mat-chip-listbox>

    </div>

</mat-dialog-content>

<mat-dialog-content *ngIf="step === 2">
    <p>
        Search for the users who will receive the lists using their username, first name, last name, or email. Select the desired users from the dropdown results. The users will appear in the Selected Users box.
    </p>
    <form [formGroup]="form">
        <div class="label">USER SEARCH:</div>   
        <mat-form-field appearance="outline">
            <input matInput formControlName="userName" [matAutocomplete]="auto" />
            <mat-autocomplete #auto [autoActiveFirstOption]="true" (optionSelected)="handleOptionSelected($event)">
                <ng-container *ngFor="let user of filteredUsers$ | async ">
                    <mat-option [value]="user.userDisplayString">{{user.userDisplayString}}</mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
        <ng-container *ngIf="shareUserList.length - selectedUsers.size === 0">
            <span class="no-lists">You have no Lists available to share.</span>
        </ng-container>
    </form>
    <mat-divider></mat-divider>
    <div class="selected">
        <p>
            Use the green “X” to remove users from the selection. Select “Review” to view the Share Lists Summary and verify your selected lists and users.
        </p>
        <div class="label">SELECTED USER(S):&nbsp;</div>Select <span class="link"
            (click)="handleRemoveAll()">None</span>
        <mat-chip-listbox class="selected-lists-box">
            <mat-basic-chip *ngFor="let username of selectedUsers" (removed)="onUsernameRemove(username)">
                <button matChipRemove>
                    <mat-icon class="clear-chip-icon">clear</mat-icon>
                </button>
                {{username}}
            </mat-basic-chip>
        </mat-chip-listbox>

    </div>

</mat-dialog-content>

<mat-dialog-content *ngIf="step === 3">
    <p>
        Verify the selected lists and users. Use the green “X” to remove list or user from the selections. Enter an optional comment to provide additional information regarding the shared lists. Select “Submit” to share the lists. The users will receive an email notification of the shared lists and be prompted to accept or delete the list on their My Lists page.
    </p>
    
    <div  class="selected">
        <div class="label">You are sharing the following list(s):</div>
        <mat-chip-listbox class="selected-lists-box">
            <mat-basic-chip *ngFor="let listName of selectedLists" (removed)="onListItemRemove(listName)">
                <button matChipRemove>
                    <mat-icon class="clear-chip-icon">clear</mat-icon>
                </button>
                {{listName}}
            </mat-basic-chip>
        </mat-chip-listbox>
    </div>
    
    <div class="selected">
        <div class="label">With the following users(s):</div>
        <mat-chip-listbox class="selected-lists-box">
            <mat-basic-chip *ngFor="let username of selectedUsers" (removed)="onUsernameRemove(username)">
                <button matChipRemove>
                    <mat-icon class="clear-chip-icon">clear</mat-icon>
                </button>
                {{username}}
            </mat-basic-chip>
        </mat-chip-listbox>
    </div>

    <form [formGroup]="form">
        <div class="label">Add a comment below:</div>
        <mat-form-field appearance="outline">
            <textarea formControlName='comments' matInput></textarea>
        </mat-form-field>
    </form>

</mat-dialog-content>


<mat-dialog-actions>
    <a *ngIf="step !== 1">
        <span (click)="handlePrevious()">Back</span>
    </a>
    <button class="md-button confirm-btn" 
        [disabled]="step === 1 ? selectedLists.size === 0 : step === 2 ? selectedUsers.size === 0 : selectedLists.size === 0 || selectedUsers.size === 0" 
        (click)="handleNext()">
        {{step === 1 ? 'NEXT' : step === 2 ? 'REVIEW' : 'SUBMIT'}}
    </button>
</mat-dialog-actions>