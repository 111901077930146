import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent {
  @Output() activateFilter = new EventEmitter(); 

  
}

