<div class="reportsFilter filterFileSubmission">
  <div aria-label="Filter File Submission" class="filter-dialog">
    <div style="flex-direction: column-reverse; display: flex;">
      <div class="layout-row" style="padding: 3px 30px; align-items: center">
        <a class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
          Reset Filters
        </a>
        <span style="flex: 1; box-sizing: border-box"></span>
        <button type="submit" class="confirmButton md-button" (click)="onSubmit()">
          SUBMIT
        </button>
      </div>


      <div *ngIf="filterParameters.authRoleClasses.dataQuality">
        <div class="layout-column" flex>
          <div class="layout-row selectButtons">
            <p>Select</p>
            <a (click)="selectAll()">All</a>
            <p>|</p>
            <a (click)="selectNone()">None</a>
          </div>

          <div class="colPOC layout-wrap layout-row md-input-container">
            <mat-checkbox (click)="checkedStates(state.stateKey)"
              [checked]="existsInSelectedStatesArray(state.stateKey)" *ngFor="let state of filterParameters.states"
              class="flex-30">
              <p>{{state.name}}</p>
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="md-toolbar-tools toolbar">
        <h2 class="filter-title">File Submission Filter</h2>
        <span style="flex: 1; box-sizing: border-box;"></span>
        <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

    </div>


  </div>
</div>