<div class="worklistReassign-page" id="worklistpagesection">

  <!--worklist history view record start-->
  <div>

    <div class="md-content" layout-padding>
      <div layout-margin class="mainDiv">
          <!-- Partials -->
          <worklist_reassign_page
            [isCOR]="isCOR"
            [worklistId]="worklistId"
            [parentWorklistId]="parentWorklistId"
            [stateDataAdmins]="stateDataAdmins"
            [regionalDataAdmins]="regionalDataAdmins"
            [districtDataAdmins]="districtDataAdmins"
            [dataAdminObj]="dataAdminObj">
          </worklist_reassign_page>            
      </div>
    </div>
  </div>
  <!--worklist history view record end-->

</div>
