import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { Router } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl = AppConstants.api;
  router: string;
  constructor(
    private http: HttpClient,
    private _router: Router) {
      this.router = _router.url;
    }

  postSearchResults(formData) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'student/search', formData);
  }

  postPrivacyActComments(privAct) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'student/stateStudent/' + privAct.stateStudentKey + '/privacy', privAct);
  }

  updatePrivacyActComments(privAct) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'student/stateStudent/' + privAct.stateStudentKey + '/privacy', privAct);
  }

  getStudentDetails(msixId) {
    return this.http.get<any>(this.apiUrl + 'student/msix/' + msixId);
  }

  getStudentRawDetails(msixId) {
    return this.http.get<any>(this.apiUrl + 'student/msix/' + msixId + '/raw');
  }

  getStudentRawDetailsAlt(stateId, state) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'student/state/' + stateId + '/raw?stateName=' + state);
  }

  getStudentHistoricalDetails(msixId) {
    return this.http.get<any>(this.apiUrl + 'student/msix/' + msixId + '/historical');
  }

  getStudentHistoricalDetailsAlt(stateId, state) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'student/state/' + stateId + '/historical?stateName=' + state);
  }

  exportStudentFile(msixId) {
    return this.http.get(this.apiUrl + 'student/msix/' + msixId + '/file', {observe: 'response', responseType: 'blob' as 'text'});
  }

  exportStudentFileToState(msixId) {
    return this.http.get(this.apiUrl + 'student/msix/' + msixId + '/file');
  }

  //API call to log in the database everytime the user performs an action.
  logUserActivity(userActivity) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'user/userActivity', userActivity).subscribe();
  }

  //API call to export the filtered info from the Consolidated Student Record on the Student Details page
  recordReportExport(msixId, exportObj) {
    return this.http.post(this.apiUrl + 'student/recordReportExport?id=' + msixId, exportObj, {observe: 'response', responseType: 'blob' as 'text'});
  }

}
