import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { IStateDataShort } from 'src/app/types/interfaces';

@Component({
    selector: 'app-custom-states-control',
    templateUrl: './custom-states-control.component.html',
    styleUrls: ['./custom-states-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomStatesControlComponent
        }
    ]
})

export class CustomStatesControlComponent implements OnInit, ControlValueAccessor, OnDestroy{
    @Input('deselectAll') deselectAll: boolean = false; 
    @Input('singleSelect') singleSelect?: boolean = false;
    private _form: FormGroup = this.fb.group({
        selectedState: ''
    })
    private _states: IStateDataShort[] = [];
    onTouched: () => {};
    onChange: (value: any) => {};
    subscription: Subscription = new Subscription();

    get states () {
        return this._states;
    }

    get form() {
        return this._form;
    }

    set form(val) {
        this._form = val;
    }

    @Input() defaultSelectAll: boolean = true;

    constructor(private stateService: StateService, private fb: FormBuilder) {
        
    }

    ngOnInit(): void {
        this.subscription.add(this.stateService.getStatesData().subscribe(res => {
            this._states = res;
            if (!this.singleSelect) {
                this._form.removeControl('selectedState')
                res.forEach(state => this.form.addControl((state as unknown as IStateDataShort).stateKey, new FormControl()));
            } else {
                this.form = this.fb.group({
                    selectedState: ''
                })
                
            }
            if (!this.deselectAll && !this.singleSelect) {
                this.selectAll();
            }
        }));
    }


    selectAll() {
        this.states.forEach(st => this.form.get(st.stateKey).setValue(st.stateKey))
      }
    
    selectNone() {
        this.states.forEach(st => this.form.get(st.stateKey).reset())
    }

    writeValue(value: any): void {
        if (!value) return;
        if (this.singleSelect) {
            this.form.get('selectedState').setValue(value)
        }
        else {
            this.form.setValue(value)
        }
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
        this.subscription.add(this.form.valueChanges.subscribe(v => {
            if (this.singleSelect) {
                v = v.selectedState;
                onChange(v)
            } else {
                onChange(v)
            }
        } ));
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
       if (isDisabled) {
        this.form.disable();
       } else {
        this.form.enable();
       }
    }

    reset() {
        this.form.reset();
        if (!this.deselectAll && !this.singleSelect) {
            this.selectAll();
        }
    }
    
    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}