<div class="worklistReassign-page" style="margin: 0px 30px">

  <div class="layout-margin top-margin-10">

    <div class="layout-flex-row layout-align-end-start">
      <div class="historicalTab" (click)="goToWorklist()">
        <p>Worklist</p>
      </div>
    </div>

    <div class="mainHeader">
      <div class="block2 section-title">Worklist</div>
      <div class="flexSpaceBetween">
        <h2 class="camelcase worklistId">Reassign Worklist #{{parentWorklistId != '' ? parentWorklistId : worklistId}}</h2>
      </div>
    </div>
  </div>

  <hr class="thinHr">

  <div *ngIf="!responsiveUI.isDesktop" class="layout-margin">
    <p class="layout-padding">To complete a worklist reassign, access your worklist on desktop.</p>
  </div>

  <!--Body Start-->
  <div *ngIf="responsiveUI.isDesktop" [ngClass]="{'flexSpaceBetweenTop removeBorders' : responsiveUI.isDesktop}"
    class="layout-flex-column">

    <!-- State Data Admins Start -->
    <div class="layout-padding layout-margin">
      <div class="tableHeader">
        <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
          <p>State Data Administrators</p>
        </div>
      </div>

      <div class="layout-flex-row table2Row background-white flex"
        *ngFor="let admin of stateDataAdmins | keyvalue; let sdaIndex = index">

        <div class="flex-95">

          <div class="flex">
            <div (click)="reassignStateAdminDetails(sdaIndex)" class="layout-flex-row flex-container section1 flex">
              <p class="flex">{{admin.key}}</p>              
              <p class="flex numberSelected">{{ getSelectedNum(admin.value) }} selected</p>
            </div>
          </div>

          <div class="layout-flex-column section2 worklist-accordion-section2 background-white flex"
            *ngIf="reassignStateAdminIndex === sdaIndex">
            <div>
              <div class="worklist-header-titles layout-flex-row">
                <div class="flex-container flex" flex-offset="5">
                  <div class="block2 flex">Name</div>
                  <div class="block2 flex">Email</div>
                  <div class="block2 flex">Account Status</div>
                </div>
              </div>
              <div class="worklist-repeat-container">
                <div class="layout-flex-row table2Row" *ngFor="let stateAdmin of admin.value">

                  <div class="layout-flex-row flex">
                    <div class="flex-5" [ngClass]="{'background-green-opacity-20' : stateAdmin.checked, 'background-grey1' : !stateAdmin.checked}">
                      <div class="flex flex-container">
                        <mat-checkbox [(ngModel)]="stateAdmin.checked" (click)="addORRemoveChecked(stateAdmin, 'state')"
                          value="stateAdmin.userKey" aria-label="Select Record for Merge" md-no-ink
                          md-ink-ripple="false" class="float-right no-margins top-80" [disabled]="stateAdmin.disableUser == 'Disabled'"></mat-checkbox>
                      </div>
                    </div>
                    <div class="layout-flex-row flex-container section1 background-white flex-95">
                      <p class="flex">{{stateAdmin.firstName}} {{stateAdmin.lastName}}</p>
                      <p class="flex offset-middle">{{stateAdmin.email}}</p>
                      <p class="flex">{{stateAdmin.disableUser}}</p>
                    </div>

                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>
        <div (click)="reassignStateAdminDetails(sdaIndex)" class="flex-5 expandColumn-white flex-container">
          <i class="fa fa-chevron-down accordion-icon" aria-hidden="true"
            *ngIf="!(reassignStateAdminIndex === sdaIndex)"></i>
          <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="reassignStateAdminIndex === sdaIndex"></i>
        </div>
      </div>


    </div>
    <!-- State Data Admins End -->

    <!-- Regional Data Admins Start -->
    <div class="layout-padding layout-margin">
      <div class="tableHeader">
        <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
          <p>Regional Data Administrators</p>
        </div>
      </div>


      <div class="layout-row table2Row table2Row background-white flex"
        *ngFor="let admin of regionalDataAdmins | keyvalue; let rdaIndex = index">

        <div class="flex-95">
          <div flex>
            <div (click)="reassignRegionalAdminDetails(rdaIndex)" class="layout-flex-row flex-container section1 flex">

              <p class="flex">Region {{admin.key}}</p>
              <p class="flex numberSelected">{{ getSelectedNum(admin.value) }} selected</p>
            </div>
          </div>

          <div class="layout-column section2 worklist-accordion-section2 background-white flex"
            *ngIf="reassignRegionalAdminIndex === rdaIndex">
            <div>
              <div class="worklist-header-titles layout-flex-row">
                <div class="flex-container flex" flex-offset="5">
                  <div class="block2 flex">Name</div>
                  <div class="block2 flex">Email</div>
                  <div class="block2 flex">Account</div>
                </div>
              </div>

              <div class="worklist-repeat-container">
                <div class="layout-flex-row table2Row"
                  *ngFor="let regionalAdmin of admin.value">

                  <div class="layout-flex-row flex">
                    <div class="flex-5"
                      [ngClass]="{'background-green-opacity-20' : regionalAdmin.checked, 'background-grey1' : !regionalAdmin.checked}">
                      <div class="flex flex-container">
                        <mat-checkbox [(ngModel)]="regionalAdmin.checked" (click)="addORRemoveChecked(regionalAdmin, 'regional')"
                          value="regionalAdmin.userKey" aria-label="Select Record for Merge" md-no-ink
                          md-ink-ripple="false" class="float-right no-margins"></mat-checkbox>
                      </div>
                    </div>
                    <div class="layout-flex-row flex-container section1 background-white flex-95">
                      <p class="flex">{{regionalAdmin.firstName}} {{regionalAdmin.lastName}}</p>
                      <p class="flex offset-middle">{{regionalAdmin.email}}</p>
                      <p class="flex">{{regionalAdmin.disableUser}}</p>
                    </div>


                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>

        <div (click)="reassignRegionalAdminDetails(rdaIndex)" class="flex-5 expandColumn-white flex-container">
          <i class="fa fa-chevron-down accordion-icon" aria-hidden="true"
            *ngIf="!(reassignRegionalAdminIndex === rdaIndex)"></i>
          <i class="fa fa-chevron-up accordion-icon" aria-hidden="true"
            *ngIf="reassignRegionalAdminIndex === rdaIndex"></i>
        </div>
      </div>


    </div>
    <!-- Regional Data Admins End -->

    <!-- District Data Admins Start -->
    <div class="layout-padding layout-margin">
      <div class="tableHeader">
        <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
          <p>District Data Administrators</p>
        </div>
      </div>

      <div class="layout-flex-row table2Row background-white flex"
        *ngFor="let admin of districtDataAdmins | keyvalue; let ddaIndex = index">

        <div class="flex-95">

          <div class="flex">
            <div (click)="reassignDistrictAdminDetails(ddaIndex)" class="flex layout-flex-row flex-container section1">
              <p class="flex">{{admin.key}}</p>
              <p class="flex numberSelected">{{ getSelectedNum(admin.value) }} selected</p>
            </div>
          </div>

          <div class="layout-flex-column section2 worklist-accordion-section2 background-white flex"
            *ngIf="reassignDistrictAdminIndex === ddaIndex">
            <div>
              <div class="worklist-header-titles layout-flex-row">
                <div class="flex-container flex" flex-offset="5">
                  <div class="block2 flex">Name</div>
                  <div class="block2 flex">Email</div>
                  <div class="block2 flex">Account</div>
                </div>
              </div>
              <div class="worklist-repeat-container">
                <div class="layout-flex-row table2Row" *ngFor="let districtAdmin of admin.value">

                  <div class="layout-flex-row flex">
                    <div class="flex-5"
                      [ngClass]="{'background-green-opacity-20' : districtAdmin.checked, 'background-grey1' : !districtAdmin.checked}">
                      <div class="flex flex-container">
                        <mat-checkbox [(ngModel)]="districtAdmin.checked" (click)="addORRemoveChecked(districtAdmin, 'district')"
                          value="districtAdmin.userKey" aria-label="Select Record for Merge" md-no-ink
                          md-ink-ripple="false" class="float-right no-margins"></mat-checkbox>
                      </div>
                    </div>
                    <div class="layout-flex-row flex-container section1 background-white flex-95">
                      <p class="flex">{{districtAdmin.firstName}} {{districtAdmin.lastName}}</p>
                      <p class="flex offset-middle">{{districtAdmin.email}}</p>
                      <p class="flex">{{districtAdmin.disableUser}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div (click)="reassignDistrictAdminDetails(ddaIndex)" class="flex-5 expandColumn-white flex-container">
          <i class="fa fa-chevron-down accordion-icon" aria-hidden="true"
            *ngIf="!(reassignDistrictAdminIndex === ddaIndex)"></i>
          <i class="fa fa-chevron-up accordion-icon" aria-hidden="true"
            *ngIf="reassignDistrictAdminIndex === ddaIndex"></i>
        </div>

      </div>

    </div>
    <!-- District Data Admins End -->


    <div class="layout-flex-row layout-padding-l-r-17">
      <div class="flex-60">
        <div class="flex custom-input-container worklist-commentbox-padding">
          <mat-form-field class="md-block md-input-container-margins background-white comment-box" 
            style="border: 2px solid gray; width: 100%; padding-right:1px; padding-bottom:1px">
            <mat-label class="custom-label">Comments</mat-label>
            <textarea matInput class="custom-input comment-text" [(ngModel)]="dataAdminObj.comments" maxlength="250"
              md-maxlength="250" rows="3" md-select-on-focus></textarea>
          </mat-form-field>
        </div>
        <div class="flex commentbox-instructions">
          <p class="small worklist-commentbox-instructions">
            To protect the student's privacy, do not include any sensitive information such as Social Security
            Numbers
            or other Personally Identifiable Information in Comments fields. Comments entered above will be shared
            unedited and in their entirety with other MSIX Users.
          </p>
        </div>
      </div>

      <div class="flex-40 layout-row">
        <a (click)="goToWorklist()" class="right-align" style="margin-top:20px">Cancel</a>
        <button class="right-align md-button" style="height: 25%" type="Submit" (click)="reassignWorklistItem()"
          [disabled]="dataAdminObj.newAdmins.length === 0">Reassign</button>
      </div>
    </div>

  </div>
  <!--Body End-->

</div>