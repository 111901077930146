import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AppConstants } from 'src/app/config/index.constants';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'login',
  templateUrl: './setChallengeQuestions.component.html',
  styleUrls: ['./setChallengeQuestions.component.scss']
})
export class SetChallengeQuestionsComponent implements OnInit {

  private title: string;
  private flag: boolean
  private allKeys: Set<number>;
  private currKeysList: number[];
  private currResponsesList: string[];
  private question1Set: Set<number>;
  private question2Set: Set<number>;
  private question3Set: Set<number>;
  private question4Set: Set<number>;
  private question5Set: Set<number>;
  private user: any;
  private userName: string;
  question1: number;
  response1: string;
  question2: number;
  response2: string;
  question3: number;
  response3: string;
  question4: number;
  response4: string;
  question5: number;
  response5: string;
  private prevQuestion1: number;
  private prevQuestion2: number;
  private prevQuestion3: number;
  private prevQuestion4: number;
  private prevQuestion5: number;
  question1Array: number[];
  question2Array: number[];
  question3Array: number[];
  question4Array: number[];
  question5Array: number[];
  errors: string;
  allChallengeQuestions: string[];
  appVersion: string;
  response1FormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
  response2FormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
  response3FormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
  response4FormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
  response5FormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
  question1Selected = false;

  matcher = new MyErrorStateMatcher();
  
  constructor(private _User: UserService,
    private cookieService: CookieService,
    private _router: Router,
    private _Toast: ToastService,
    public responsiveUI: ResponsiveUIService) {
      this.allChallengeQuestions = Array<string>();
      this.allKeys = new Set<number>();
      this.currKeysList = Array<number>();
      this.currResponsesList = Array<string>();
  }

  ngOnInit(): void {
    
    let userObj:any = JSON.parse(this.cookieService.get('user'));
    this.userName = userObj.username;

    let apiCall = this._User.getAllChallengeQuestions();
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        for (let challengeQuestion of data) {
          let cq: any = challengeQuestion;
          this.allChallengeQuestions[cq.key] = cq.question;
          this.allKeys.add(cq.key);
        }

        //store the previously selected challenge question
        this.prevQuestion1 = this.question1;
        this.prevQuestion2 = this.question2;
        this.prevQuestion3 = this.question3;
        this.prevQuestion4 = this.question4;
        this.prevQuestion5 = this.question5;

        this.question1Set = new Set(this.allKeys);
        this.question2Set = new Set(this.allKeys);
        this.question3Set = new Set(this.allKeys);
        this.question4Set = new Set(this.allKeys);
        this.question5Set = new Set(this.allKeys);

        this.question1Array = Array.from(this.question1Set).sort();
        this.question2Array = Array.from(this.question2Set).sort();
        this.question3Array = Array.from(this.question3Set).sort();
        this.question4Array = Array.from(this.question4Set).sort();
        this.question5Array = Array.from(this.question5Set).sort();
        
        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log('Error received while getting all challenge questions for the user.' + error);
        this._Toast.showToast(error.data.developerMessage, 0);
        
        subscribed.unsubscribe();
      }
    });

    this.setUpQuestionsAndAnswersForUser();
    this.appVersion = AppConstants.appVersion;
  }

  submitForm() {
    this.response1 = this.response1FormControl.value;
    this.response2 = this.response2FormControl.value;
    this.response3 = this.response3FormControl.value;
    this.response4 = this.response4FormControl.value;
    this.response5 = this.response5FormControl.value;
    
    //send selected questions and answers to api call
    this.user = {
      username: this.userName,
      challengeQuestionsAndAnswers: [
        {
          key: this.question1,
          answer: this.response1.trim()
        },
        {
          key: this.question2,
          answer: this.response2.trim()
        },
        {
          key: this.question3,
          answer: this.response3.trim()
        },
        {
          key: this.question4,
          answer: this.response4.trim()
        },
        {
          key: this.question5,
          answer: this.response5.trim()
        }
      ]
    }

    let apiCall = this._User.saveSelectedChallengeQuestions(this.user);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        console.log("Successfully called save selected challenge questions API call.");
        //go to set a new password
        this.goToPasswordUpdate();

        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log('Error received while saving challenge questions: ' + error);
        if (error.data.codes !== '' && error.data.codes.length > 0) {
          var showToast = false;
          for (let errCode in error.data.codes){
            if (errCode === '3000'){
              this.errors = 'You cannot set the same question for more than one challenge question on your account. Please select five different questions for your challenge questions.';
            } else {
              showToast = true;
            }
          }
          if (showToast && (this.errors === '' || this.errors.length < 1)){
            this._Toast.showToast('There was an error saving your challenge questions. Please contact the MSIX Help Desk if you keep getting this error.');
          }
          
          subscribed.unsubscribe();
        } else {
          this._Toast.showToast('There was an error saving your challenge questions. Please contact the MSIX Help Desk if you keep getting this error.');
        }
      }
    });
  }

  goToPasswordUpdate() {
    this._router.navigate(['passwordUpdate']);
    this._Toast.showToast('Challenge Questions and responses have been saved!');
  }

  goToLogin() {
    this._router.navigate(['app.login']);
  }

  resetFields() {
    this.userName = this.userName;
    this.response1 = '';
    this.response2 = '';
    this.response3 = '';
    this.response4 = '';
    this.response5 = '';
    this.question1 = -1;
    this.question2 = -2;
    this.question3 = -3;
    this.question4 = -4;
    this.question5 = -5;
  }

  setUpQuestionsAndAnswersForUser() {

    let apiCall = this._User.getAllCQsforUser(this.userName);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        //console.log('Successfully getAllCQsforUser: ' + res);

        let d:any = res;

        if (d.isChallengeQuestionSet) {
          for (var challengeQuestion of d.challengeQuestions){
            this.currKeysList.push(challengeQuestion.key);
            this.currResponsesList.push(challengeQuestion.answer);
          }
          this.question1 = this.currKeysList[0];
          this.question2 = this.currKeysList[1];
          this.question3 = this.currKeysList[2];
          this.question4 = this.currKeysList[3];
          this.question5 = this.currKeysList[4];
          this.response1 = this.currResponsesList[0];
          this.response2 = this.currResponsesList[1];
          this.response3 = this.currResponsesList[2];
          this.response4 = this.currResponsesList[3];
          this.response5 = this.currResponsesList[4];
          /* this.response1FormControl = new FormControl(this.response1, [Validators.required, Validators.maxLength(35)])
          this.response2FormControl = new FormControl(this.response2, [Validators.required, Validators.maxLength(35)]);
          this.response3FormControl = new FormControl(this.response3, [Validators.required, Validators.maxLength(35)]);
          this.response4FormControl = new FormControl(this.response4, [Validators.required, Validators.maxLength(35)]);
          this.response5FormControl = new FormControl(this.response5, [Validators.required, Validators.maxLength(35)]); */
          this.response1FormControl.setValue(this.response1);
          this.response2FormControl.setValue(this.response2);
          this.response3FormControl.setValue(this.response3);
          this.response4FormControl.setValue(this.response4);
          this.response5FormControl.setValue(this.response5);

          //store the previously selected challenge question
          this.prevQuestion1 = this.question1;
          this.prevQuestion2 = this.question2;
          this.prevQuestion3 = this.question3;
          this.prevQuestion4 = this.question4;
          this.prevQuestion5 = this.question5;

          this.removeSelectedQuestion(1, this.question1);
          this.removeSelectedQuestion(2, this.question2);
          this.removeSelectedQuestion(3, this.question3);
          this.removeSelectedQuestion(4, this.question4);
          this.removeSelectedQuestion(5, this.question5);
        } //else: questions and responses are already reset
        
        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log('Error receiving all challenge questions and answers for the user.' + error);
        this._Toast.showToast(error.data.developerMessage, 0);
        
        subscribed.unsubscribe();
      }
    });
  }

  addAndRemoveSelectedQuestion(arrNumber, key){
    this.addDeselectedQuestion(arrNumber);
    this.removeSelectedQuestion(arrNumber, key);
    this.clearAnswer(arrNumber);
  }

  removeSelectedQuestion(arrNumber, key){
    if (1 === arrNumber){
      this.prevQuestion1 = key;
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    } else if (2 === arrNumber){
      this.prevQuestion2 = key;
      this.question1Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    }  else if (3 === arrNumber){
      this.prevQuestion3 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    } else if (4 === arrNumber){
      this.prevQuestion4 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question5Set.delete(key);
    }  else if (5 === arrNumber){
      this.prevQuestion5 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
    }
    this.question1Array = Array.from(this.question1Set).sort();
    this.question2Array = Array.from(this.question2Set).sort();
    this.question3Array = Array.from(this.question3Set).sort();
    this.question4Array = Array.from(this.question4Set).sort();
    this.question5Array = Array.from(this.question5Set).sort();
  }

  addDeselectedQuestion(arrNumber){
    if (1 === arrNumber && this.prevQuestion1 > 0){
      this.question2Set.add(this.prevQuestion1);
      this.question3Set.add(this.prevQuestion1);
      this.question4Set.add(this.prevQuestion1);
      this.question5Set.add(this.prevQuestion1);
    } else if (2 === arrNumber && this.prevQuestion2 > 0) {
      this.question1Set.add(this.prevQuestion2);
      this.question3Set.add(this.prevQuestion2);
      this.question4Set.add(this.prevQuestion2);
      this.question5Set.add(this.prevQuestion2);
    } else if (3 === arrNumber && this.prevQuestion3 > 0) {
      this.question1Set.add(this.prevQuestion3);
      this.question2Set.add(this.prevQuestion3);
      this.question4Set.add(this.prevQuestion3);
      this.question5Set.add(this.prevQuestion3);
    } else if (4 === arrNumber && this.prevQuestion4 > 0) {
      this.question1Set.add(this.prevQuestion4);
      this.question2Set.add(this.prevQuestion4);
      this.question3Set.add(this.prevQuestion4);
      this.question5Set.add(this.prevQuestion4);
    } else if (5 === arrNumber && this.prevQuestion5 > 0) {
      this.question1Set.add(this.prevQuestion5);
      this.question2Set.add(this.prevQuestion5);
      this.question3Set.add(this.prevQuestion5);
      this.question4Set.add(this.prevQuestion5);
    }
    this.question1Array = Array.from(this.question1Set).sort();
    this.question2Array = Array.from(this.question2Set).sort();
    this.question3Array = Array.from(this.question3Set).sort();
    this.question4Array = Array.from(this.question4Set).sort();
    this.question5Array = Array.from(this.question5Set).sort();
  }

  clearAnswer(arrNumber){
    if (arrNumber === 1){
      this.response1 = '';
      this.response1FormControl.setValue('');
    } else if (arrNumber === 2){
      this.response2 = '';
      this.response2FormControl.setValue('');
    } else if (arrNumber === 3){
      this.response3 = '';
      this.response3FormControl.setValue('');
    } else if (arrNumber === 4){
      this.response4 = '';
      this.response4FormControl.setValue('');
    } else if (arrNumber === 5){
      this.response5 = '';
      this.response5FormControl.setValue('');
    }
  }
}