import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ResponsiveUIService } from "src/app/services/responsiveUI.service";
import { ToastService } from "src/app/services/toast.service";
import { WorklistService } from "src/app/services/worklist.service";

@Component({
  selector: 'worklisthistory_record',
  templateUrl: './worklistHistoryRecord.component.html',
  styleUrls: ['./worklistHistoryRecord.component.scss']
})
export class WorklistHistoryRecordComponent implements OnInit {
  private showOverview: number = -1;
  private showNewRecord: number = -1;
  private showExistingRecord: number = -1;
  private showMaster: number = -1;
  private showMergeRecord: number = -1;
  showReceiveMSIX: boolean = true;
  showRetainMSIX: boolean = true;
  private showStudentRecord: number = -1;
  private showSchoolInfo: number = -1;
  private showCORCorrespondence: number = -1;
  showWorkflow: boolean = true;

  emptyWorklistDevMessage: boolean = false;
  worklistId: string;
  worklistType: string;
  workflowRecord: any = {};
  worklistStatus: any;
  corMessagesLength: number = 0;
  workflowIndex: any;
  itemHistoryLength: boolean;

  constructor(private activatedRoute: ActivatedRoute,
    private _Worklist: WorklistService,
    private _Toast: ToastService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.worklistId = params['worklistId'];
      this.worklistType = params['worklistType'];
      this.worklistStatus = params['worklistStatus'];
    });
  }

  ngOnInit(): void {
    this.getWorklistHistoryRecord();
  }

  async getWorklistHistoryRecord() {
    var worklistType;
    var mergeOptionsArray = ['UserInitiatedMerge', 'User Initiated Merge', 'UserInitiatedMergeValidation', 'User Initiated Merge Validation', 'ValidateUserInitiatedMerge', 'Validate User Initiated Merge', 'ValidateMerge', 'Validate Merge'];
    var splitOptionsArray = ['ValidateSplit', 'Validate Split', 'UserInitiatedSplit', 'User Initiated Split', 'UserInitiatedSplitValidation', 'User Initiated Split Validation'];
    var matchOptionsArray = ['NearMatch', 'Near Match', 'NearMatchValidation', 'Near Match Validation', 'ETLNearMatchValidation', 'ETL Near Match Validation', 'ETLNearMatch', 'ETL Near Match'];
    var moveOptionsArray = ['MovedToOurArea', 'Moved To Our Area', 'LeavingOurArea', 'Leaving Our Area']
    var dataOptionsArray = ['DataRequest', 'Data Request']

    if (mergeOptionsArray.indexOf(this.worklistType) > -1) {
      worklistType = 'merge';
    } else if (splitOptionsArray.indexOf(this.worklistType) > -1) {
      worklistType = 'split';
    } else if (matchOptionsArray.indexOf(this.worklistType) > -1) {
      worklistType = 'match'
    } else if (moveOptionsArray.indexOf(this.worklistType) > -1) {
      worklistType = 'move'
    } else if (dataOptionsArray.indexOf(this.worklistType) > -1) {
      worklistType = 'data'
    }
    var worklistId = this.worklistId;

    let apiCall = this._Worklist.getWorklistHistoryRecord(worklistId, worklistType);
    let subscribed = await apiCall.subscribe({
      next: data => {
        console.debug(data);
        this.workflowRecord = data;
        if (worklistType == 'data' || worklistType == 'move') {
          this.corMessagesLength = data.corMessages.length;
        }
        if (this.workflowRecord.master) {
          this.workflowRecord.worklistHistory = this.workflowRecord.master.worklistHistory;
        }
        if(this.workflowRecord.worklistItemHistory) {    
          this.itemHistoryLength = this.workflowRecord.worklistItemHistory.length > 0;
        }
        if(!this.workflowRecord.demographics) {
          this.workflowRecord.demographics = {};
        }

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        if (error.error.developerMessage.indexOf('Unable to find worklist') !== -1) {
          this.emptyWorklistDevMessage = true;
        } else {
          this.emptyWorklistDevMessage = false;
        }
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  goToWorklist() {
    this._router.navigate(['worklist']);
  }

  showHideWorkflow() {
    this.showWorkflow = !this.showWorkflow;
    this.itemHistoryLength = this.workflowRecord.worklistItemHistory.length > 0;
  }

  showWorkflowDetails(index) {
    if (this.workflowIndex === index) {
      this.workflowIndex = -1;
    } else {
      this.workflowIndex = index;
    }
  }
}