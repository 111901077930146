<div class="accountMetrics">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><p>Account Metrics</p></span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Account Metrics</a></span>
    <span>></span>
    <span><p>{{drillDownType}}</p></span>
  </div>

  <div *ngIf="!showDrill">
    <div class="header layout-row">
      <h1 class="flex-90 header-padding">Account Metrics</h1>
      <export-report 
        class="flex-10" 
        tabindex="0"
        [exportlink]="exportCall" 
        [exportfilter]="filterParameters"
        [showpdf]="false" 
        [showcsv]="true">
    </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="layout-row layout-align-center-center">
      <div class="layout-column flex-90">
        <div class="report-summary layout-row">
          <p>
            This report lists the MSIX users in your State and their total logins, student record searches, 
            the number of Consolidated Student Records viewed and downloaded, the number of Data Requests 
            initiated and responded to, and the number of Move Notices initiated and responded to.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" 
          [responsedata]="response" 
          [isreportlevelspecialsortinit]="isInit" 
          [hasreportlevelspecialsort]="true" 
          [currentsortcolumn]="currentSortColumn" 
          [sortascending]="sortAscending"
          [shownavigation]="true" 
          [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
          (mycallback)="drillDownInit($event)"
          (reportlevelspecialsort)="handleReportSpecialSortClick($event)"
          (sortbycolumn)="sortByColumn($event)" >
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>
      </div>
    </div>
  </div>

  <!-- Drilldown page -->
  <div *ngIf="showDrill">
    <div class="header layout-row">
      <h1 class="flex-90 header-padding">{{drillDownType}}</h1>
      <export-report 
        class="flex-10" 
        tabindex="0"
        [exportlink]="drilldownExportCall" 
        [exportfilter]="drilldownExportParams"
        [showpdf]="false" 
        [showcsv]="true">
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div class="layout-row layout-align-center-center">
      <div class="layout-column flex-90">
        <div class="report-summary layout-row">
          <p *ngIf="drillDownType=='Data Requests Initiated'">
            The table below lists the Worklist IDs of the Data Requests this user has initiated along with current Status, 
            whether the Data Request is compliant with MSIX Regulations, the date it was initiated, the date it was last 
            updated, and the date it was resolved, if applicable.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
          <p *ngIf="drillDownType=='Data Requests Responded To'">
            The table below lists the Worklist IDs of the Data Requests this user has responded to along with current Status, 
            whether the Data Request is compliant with MSIX Regulations, the date it was initiated, the date it was last updated, 
            and the date it was resolved, if applicable.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
          <p *ngIf="drillDownType=='Move Notices Initiated'">
            The table below lists the Worklist IDs of the Move Notices the selected user has initiated along with the type of 
            Move Notice, the associated MSIX ID and SSID, current Status, recipient district, and the dates the Move Notice was 
            created and last updated.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
          <p *ngIf="drillDownType=='Move Notices Responded To'">
            The table below lists the Worklist IDs of the Move Notices the selected user responded to along with the type of 
            Move Notice, the associated MSIX ID and SSID,  current Status, recipient district, and the dates it was created and 
            last updated.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
          <p *ngIf="drillDownType=='Consolidated Student Records Downloaded'">
            The table below lists the MSIX IDs associated with the Consolidated Student Records the selected user has downloaded 
            along with the district corresponding to the Student Record and the date it was downloaded.
            <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
          </p>
        </div>
        <div class="drill-down-rs layout-row flex-90">
          <span class="showing layout-row">Showing:</span>
          <span class="perf-period layout-row">
            <p class="perf-period-text">
              {{userID}}
            </p>
          </span>
        </div>
        <reports-table
          *ngIf="!drillDownBlankRes"
          [responsedata]="drillDownData"
          [shownavigation]="true"
          [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}"
          (drilldownsort)="handleDrillDownSortClick($event)"
          (mycallback)="openIDPage($event)">
        </reports-table>
        <no-results *ngIf="drillDownBlankRes"></no-results>
      </div>
    </div>
  </div>
</div>
