<!-- <div class="myaccount-page"  [ngClass]="{'main-border main-padding' : ($mdMedia('gt-md')), 'main-border-mobile main-padding-mobile' : ($mdMedia('xs') || $mdMedia('sm') || $mdMedia('md'))}"> -->
<div class="myaccount-page main-border">

  <div class="mainDiv layout-margin">
    <div class="mainHeader">
      <div *ngIf="responsiveUI.isDesktop || responsiveUI.isTablet" class="block2 uppercase">My Account Page</div>
      <div class="layout-row flexSpaceBetween">
        <h2>{{ formData.username }}</h2>
        <div class="mainHeaderRight">
          <div>
            <p class="small" style="padding-right: 16px">Denotes required (*)</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="thinHr">

  <!-- Sub Header Row Desktop Begin -->
  <div class="desktopStudentIds layout-margin" [ngClass]="{'layout-row': !responsiveUI.isMobile}">
    <div [ngClass]="{'flex-baseline' : !responsiveUI.isMobile}">
      <h2 class="block2 roleTitleFont uppercase">User Role(s):</h2>
      <div class="layout-column">
        <div class="layout-row" *ngFor="let role of rolesList">
          <p *ngIf="role != 'MSIX Primary User'">{{role}}</p>
          <p *ngIf="role == 'MSIX Primary User'">Primary User</p>
        </div>
      </div>
    </div>
    <!-- <div [ngClass]="{'flex-baseline' : ($mdMedia('gt-xs'))}"  *ngIf="formData.districtNames.length >0">
        <h2 class="block2 uppercase">District(s):</h2>
          <div class="layout-column">
            <div class="layout-row" *ngFor="let val of formData.districtNames track by $index">
              <p>{{val}}</p>
            </div>
          </div>
      </div>
      <div [ngClass]="{'flex-baseline' : ($mdMedia('gt-xs'))}"  *ngIf="formData.regionNames.length > 0">
        <h2 class="block2 uppercase">Region(s):</h2>
        <div class="layout-column">
          <div class="layout-row" *ngFor="let val of formData.regionNames track by $index">
            <p>{{val}}</p>
          </div>
        </div>
      </div> -->
  </div>
  <!-- Sub Header Row Desktop End -->

  <hr class="thinHr">

  <div class="errors flex-inline" *ngIf="errors.length > 0">
    <div>
      <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
      <p class="validation">{{errors}}</p>
    </div>
  </div>

  <div class="layout-margin">
    <form name="myAccountForm" [formGroup]="myAccountForm" (ngSubmit)="updateUser()">
      <div [ngClass]="{'layout-flex-row': responsiveUI.isDesktop || responsiveUI.isTablet}">

        <div class="col1Right flex-xs-100 flex-45">
          <div class="forms">
            <custom-mat-input customlabel="First Name" [isrequired]=true [(ngModel)]="formData.firstName"
              (showerror)="myAccountForm.controls['firstName'].touched && myAccountFormError('firstName', 'required')"
              formControlName="firstName" name="fName" errormessage="Please provide a first name.">
            </custom-mat-input>

            <div>
              <custom-mat-input style="padding-top: 30px" customlabel="Last Name" [isrequired]=true
                [(ngModel)]="formData.lastName" errormessage="Please provide a last name."
                (showerror)="myAccountForm.controls['lastName'].touched && myAccountFormError('lastName', 'required')"
                formControlName="lastName" name="lName" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>

            <div class="email" [ngClass]="{'email-xs': responsiveUI.isMobile, 'email-md': responsiveUI.isTablet}">
              <custom-mat-input style="padding-top: 30px" customlabel="Email" [isdisabled]="true"
                [(ngModel)]="formData.email" formControlName="email" name="email" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>

            <div class="layout-flex-row flex-baseline">
              <custom-mat-input class="flex-65" customlabel="Phone" errorlength="{{errors.length}}"
                [isdisabled]=disabledFlag [isrequired]=true [(ngModel)]="formData.userPhone"
                (showerror)="myAccountForm.controls['userPhone'].touched && myAccountFormError('userPhone', 'required')"
                errormessage="Please provide a phone number." formControlName="userPhone" (ngsubmit)="updateUser()">
              </custom-mat-input>
              <div style="display: flex; flex: 5%" class="flexCenter">
                <p>x</p>
              </div>
              <custom-mat-input class="flex-30 extension" [ngClass]="{'extension-md': responsiveUI.isTablet,
                  'extension-sm': responsiveUI.isMobile}" customlabel="Extension" errorlength="{{errors.length}}"
                [isdisabled]=disabledFlag formControlName="userPhoneExt" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>
          </div>

          <div style="padding-top: 20px">
            <!--MFA Section-->
            <h2>Multi-Factor Authentication</h2>
            <hr class="thinHr">
            <p class="header"><b>Multi-Factor Authentication has been configured: {{isMFAConfigured}}</b></p>
            <!-- <h2> {{ formData.username }} </h2> -->
            <a tabindex="0" (keypress)="setupMFA()" (click)="setupMFA()">Configure a Multi-Factor Authentication
              Application</a>
          </div>
        </div>
        <span style="width: 10%"></span>
        <div class="col1Right flex-45 flex-xs-100">
          <div>
            <div class="layout-column emailFrequency">
              <p class="header">Worklist email frequency</p>
              <mat-radio-group formControlName="receiveNotification" [(ngModel)]="formData.receiveNotification"
                name="emailNotificationInput" class="layout-flex-column" style="margin-left: -10px;">
                <mat-radio-button value="YES">
                  <p>Real-time (as they happen)</p>
                </mat-radio-button>
                <mat-radio-button value="BAT">
                  <p>One email a day (digest)</p>
                </mat-radio-button>
                <mat-radio-button value="NO">
                  <p>No emails</p>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="passwordReset">
              <p class="header">Password</p>
              <mat-checkbox style="margin-left: -10px;" formControlName="isPasswordReset" (change)="resetPassword()"
                aria-label="reset my password">
                <p>Reset my password</p>
              </mat-checkbox>
              <p class="subP">(will send a password reset email)</p>
            </div>

            <!-- Challenge Question/Response 1 - START -->
            <div class="custom-input-container challengeQuestions" style="display: flex; padding-top: 20px;">
              <mat-form-field class="md-block" style="flex: auto">
                <mat-label class="custom-label select-label">Challenge Question 1</mat-label>
                <mat-select [(value)]="formData.question1" [ngClass]="{'mat-input-has-value': formData.question1}">
                  <mat-option *ngFor="let key of question1Array" [value]="key"
                    (click)="addAndRemoveSelectedQuestion(1, key)">
                    {{allChallengeQuestions[key]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="custom-input-container right-margin response" [ngClass]="{'error': (myAccountForm.controls['response1'].touched && myAccountFormError('response1', 'required'))
                  || myAccountFormError('response1', 'maxlength'), 'response-mobile': responsiveUI.isMobile}">
              <mat-form-field class="md-input-container-margins md-block" style="flex: auto; width: 100%;"
                [floatLabel]="myAccountForm.controls['response1'].touched && myAccountFormError('response1', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label" for="response1Input">Response 1</mat-label>
                <input matInput class="custom-input" type="text" formControlName="response1"
                  [(ngModel)]="formData.response1" name="response1Input"
                  [ngClass]="{'mat-input-has-value': myAccountForm.controls['response1'].value, 
                    'input-error': (myAccountForm.controls['response1'].touched && myAccountFormError('response1', 'required')) || myAccountFormError('response1', 'maxlength')}"
                  required>
              </mat-form-field>
              <mat-error class="error-message"
                *ngIf="myAccountForm.controls['response1'].touched && myAccountFormError('response1', 'required')">
                Please provide a response.</mat-error>
              <mat-error class="error-message" *ngIf="myAccountFormError('response1', 'maxlength')">Responses must be 35
                characters or less.</mat-error>
            </div>
            <!-- Challenge Question/Response 1 - END -->

            <!-- Challenge Question/Response 2 - START -->
            <div class="custom-input-container challengeQuestions" style="display: flex; padding-top: 20px;">
              <mat-form-field class="md-block" style="flex: auto">
                <mat-label class="custom-label select-label">Challenge Question 2</mat-label>
                <mat-select [(value)]="formData.question2" [ngClass]="{'mat-input-has-value': formData.question2}">
                  <mat-option *ngFor="let key of question2Array" [value]="key"
                    (click)="addAndRemoveSelectedQuestion(2, key)">
                    {{allChallengeQuestions[key]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="custom-input-container right-margin response" [ngClass]="{'error': (myAccountForm.controls['response2'].touched && myAccountFormError('response2', 'required')) 
                || myAccountFormError('response2', 'maxlength'), 'response-mobile': responsiveUI.isMobile}">
              <mat-form-field class="md-input-container-margins md-block" style="flex: auto; width: 100%;"
                [floatLabel]="myAccountForm.controls['response2'].touched && myAccountFormError('response2', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label" for="response2Input">Response 2</mat-label>
                <input matInput class="custom-input" type="text" formControlName="response2"
                  [(ngModel)]="formData.response2" name="response2Input"
                  [ngClass]="{'mat-input-has-value': myAccountForm.controls['response2'].value, 
                    'input-error': (myAccountForm.controls['response2'].touched && myAccountFormError('response2', 'required')) || myAccountFormError('response2', 'maxlength')}"
                  required>
              </mat-form-field>
              <mat-error class="error-message"
                *ngIf="myAccountForm.controls['response2'].touched && myAccountFormError('response2', 'required')">
                Please provide a response.</mat-error>
              <mat-error class="error-message" *ngIf="myAccountFormError('response2', 'maxlength')">Responses must be 35
                characters or less.</mat-error>
            </div>
            <!-- Challenge Question/Response 2 - END -->
          </div>
        </div>
      </div>
      <!-- Section 1 End -->
      <!-- Section 2 Begin -->
      <div flex class="address">
        <h2>Address</h2>
        <div class="main" [ngClass]="{'layout-flex-row': responsiveUI.isDesktop || responsiveUI.isTablet}">
          <div class="flex-xs-100 flex-45">
            <hr class="thinHr">
            <div [ngClass]="{'addressLine-md': responsiveUI.isTablet, 'addressLine-sm': responsiveUI.isMobile}">
              <custom-mat-input customlabel="Address, line 1" [isdisabled]=disabledFlag formControlName="addLine1"
                [(ngModel)]="formData.addLine1" name="addLine1Input" [userform]="false" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>
            <div [ngClass]="{'addressLine-md': responsiveUI.isTablet, 'addressLine-sm': responsiveUI.isMobile}">
              <custom-mat-input customlabel="Address, line 2" [isdisabled]=disabledFlag formControlName="addLine2"
                [(ngModel)]="formData.addLine2" name="addLine2Input" [userform]="false" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>
            <div [ngClass]="{'addressLine-md': responsiveUI.isTablet, 'addressLine-sm': responsiveUI.isMobile}">
              <custom-mat-input customlabel="City" [isdisabled]=disabledFlag formControlName="addCity"
                [(ngModel)]="formData.addCity" name="addCityInput" [userform]="false" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>
            <div class="md-input state" [ngClass]="{'state-xs': responsiveUI.isMobile, 'state-md': responsiveUI.isTablet}">
              <custom-mat-input customlabel="State" [isdisabled]="true" formControlName="addState"
                [(ngModel)]="formData.userStateName" name="addStateInput" [userform]="false" (ngsubmit)="updateUser()">
              </custom-mat-input>
            </div>
            <!-- Job Titles Section Begin -->
            <div class="topSpacing">
              <div class="flex-inline">
                <p>Job Title</p>
                <p class="required-asterisk">&nbsp;*</p>
              </div>
              <hr class="thinHr">
              <div class="layout-row" class="userRoles" style="display: flex; flex-wrap: wrap;">
                <div style="width: 50%" *ngFor="let job of jobsArray">
                  <div *ngIf="job.jobTitleDesc !== 'Other'">
                    <mat-checkbox [checked]="job.selected" (change)="addRemoveJob($event, job)"
                      [disabled]="disabledFlag" aria-label="job">
                      <p>{{job.jobTitleDesc}}</p>
                    </mat-checkbox>
                  </div>
                  <div *ngIf="job.jobTitleDesc == 'Other'">
                    <mat-checkbox [checked]="job.selected" (change)="addRemoveJob($event, job)"
                      [disabled]="disabledFlag" aria-label="job">
                      <p>{{job.jobTitleDesc}}: Please Specify</p>
                    </mat-checkbox>
                    <input matInput (change)="updateOtherJobTitle(job)" class="other-input"
                      [(ngModel)]="jobTitle.other" [ngModelOptions]="{standalone: true}"
                      [disabled]="!job.selected || disabledFlag" type="text" name="otherJobTitle">
                  </div>
                </div>
              </div>
            </div>
            <!-- Job Titles Section End -->
          </div>
          <span style="width: 10%"></span>
          <!-- Challenge Question/Response 3 - START -->
          <div class="flex-xs-100 flex-45">
            <div class="custom-input-container challengeQuestions" style="display: flex;">
              <mat-form-field class="md-block" style="width: 100%;">
                <mat-label class="custom-label select-label">Challenge Question 3</mat-label>
                <mat-select [(value)]="formData.question3" [ngClass]="{'mat-input-has-value': formData.question3}">
                  <mat-option *ngFor="let key of question3Array" [value]="key"
                    (click)="addAndRemoveSelectedQuestion(3, key)">
                    {{allChallengeQuestions[key]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="custom-input-container right-margin response" [ngClass]="{'error': (myAccountForm.controls['response3'].touched && myAccountFormError('response3', 'required')) 
                || myAccountFormError('response3', 'maxlength'), 'response-mobile': responsiveUI.isMobile}">
              <mat-form-field class="md-input-container-margins md-block" style="width: 100%;"
                [floatLabel]="myAccountForm.controls['response3'].touched && myAccountFormError('response3', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label">Response 3</mat-label>
                <input matInput class="custom-input" type="text" formControlName="response3"
                  [(ngModel)]="formData.response3" name="response3Input"
                  [ngClass]="{'mat-input-has-value': myAccountForm.controls['response3'].value, 'input-error': (myAccountForm.controls['response3'].touched && myAccountFormError('response3', 'required')) || myAccountFormError('response3', 'maxlength')}"
                  required>
              </mat-form-field>
              <mat-error class="error-message"
                *ngIf="myAccountForm.controls['response3'].touched && myAccountFormError('response3', 'required')">
                Please provide a response.</mat-error>
              <mat-error class="error-message" *ngIf="myAccountFormError('response3', 'maxlength')">Responses must be 35
                characters or less.</mat-error>
            </div>
            <!-- Challenge Question/Response 3 - END -->
            <!-- Challenge Question/Response 4 - START -->
            <div class="custom-input-container challengeQuestions" style="display: flex; padding-top: 20px;">
              <mat-form-field class="md-block" style="width: 100%;">
                <mat-label class="custom-label select-label">Challenge Question 4</mat-label>
                <mat-select [(value)]="formData.question4" [ngClass]="{'mat-input-has-value': formData.question4}">
                  <mat-option *ngFor="let key of question4Array" [value]="key"
                    (click)="addAndRemoveSelectedQuestion(4, key)">
                    {{allChallengeQuestions[key]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="custom-input-container right-margin response" [ngClass]="{'error': (myAccountForm.controls['response4'].touched && myAccountFormError('response4', 'required')) 
                || myAccountFormError('response4', 'maxlength'), 'response-mobile': responsiveUI.isMobile}">
              <mat-form-field class="md-input-container-margins md-block" style="width: 100%;"
                [floatLabel]="myAccountForm.controls['response4'].touched && myAccountFormError('response4', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label">Response 4</mat-label>
                <input matInput class="custom-input" type="text" formControlName="response4"
                  [(ngModel)]="formData.response4" name="response4Input"
                  [ngClass]="{'mat-input-has-value': myAccountForm.controls['response4'].value, 'input-error': (myAccountForm.controls['response4'].touched && myAccountFormError('response4', 'required')) || myAccountFormError('response4', 'maxlength')}"
                  required>
              </mat-form-field>
              <mat-error class="error-message"
                *ngIf="myAccountForm.controls['response4'].touched && myAccountFormError('response4', 'required')">
                Please provide a response.</mat-error>
              <mat-error class="error-message" *ngIf="myAccountFormError('response4', 'maxlength')">Responses must be 35
                characters or less.</mat-error>
            </div>
            <!-- Challenge Question/Response 4 - END -->
            <!-- Challenge Question/Response 5 - START -->
            <div class="custom-input-container challengeQuestions" style="display: flex; padding-top: 20px;">
              <mat-form-field class="md-block" style="width: 100%;">
                <mat-label class="custom-label select-label">Challenge Question 5</mat-label>
                <mat-select [(value)]="formData.question5" [ngClass]="{'mat-input-has-value': formData.question5}">
                  <mat-option *ngFor="let key of question5Array" [value]="key"
                    (click)="addAndRemoveSelectedQuestion(5, key)">
                    {{allChallengeQuestions[key]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="custom-input-container right-margin response" [ngClass]="{'error': (myAccountForm.controls['response5'].touched && myAccountFormError('response5', 'required')) 
                || myAccountFormError('response5', 'maxlength'), 'response-mobile': responsiveUI.isMobile}">
              <mat-form-field class="md-input-container-margins md-block" style="width: 100%;"
                [floatLabel]="myAccountForm.controls['response5'].touched && myAccountFormError('response5', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label">Response 5</mat-label>
                <input matInput class="custom-input" type="text" formControlName="response5"
                  [(ngModel)]="formData.response5" name="response5Input"
                  [ngClass]="{'mat-input-has-value': myAccountForm.controls['response5'].value, 'input-error': (myAccountForm.controls['response5'].touched && myAccountFormError('response5', 'required')) || myAccountFormError('response5', 'maxlength')}"
                  required>
              </mat-form-field>
              <mat-error class="error-message"
                *ngIf="myAccountForm.controls['response5'].touched && myAccountFormError('response5', 'required')">
                Please provide a response.</mat-error>
              <mat-error class="error-message" *ngIf="myAccountFormError('response5', 'maxlength')">Responses must be 35
                characters or less.</mat-error>
            </div>
            <!-- Challenge Question/Response 5 - END -->
          </div>
        </div>
      </div>
      <!-- Section 2 End -->
      <!-- Section 3 Begin -->

      <div flex class="buttonSection">
        <hr class="thinHr">
        <div class="buttons" [ngClass]="{'float-right' : !responsiveUI.isMobile}">
          <a class="cancelButton" (click)="cancel($event)" (keypress)="cancel($event)" tabindex="0">
            <span>Cancel</span></a>
          <!-- <md-button type="submit" class="confirmButton" ng-disabled="((jsonStringify(formData) === jsonStringify(formData)) && !resetPassword)
              || !formData.question1 || !formData.question2 || !formData.question3 || !formData.question4 || !formData.question5 
              || formData.response1.length < 2 || formData.response2.length < 2 || formData.response3.length < 2 || formData.response4.length < 2 || formData.response5.length < 2
              || !formData.response1 || !formData.response2|| !formData.response3 || !formData.response4 || !formData.response5
              || !(areAllValidJobTitleSelected())">
              SUBMIT
            </md-button> -->
          <button class="md-button confirmButton" type="submit" [disabled]="(myAccountForm.pristine && jobTitlesPristine) || 
            !formData.question1 || !formData.question2 || !formData.question3 || !formData.question4 || !formData.question5 || 
            formData.response1.length < 2 || formData.response2.length < 2 || formData.response3.length < 2 || 
            formData.response4.length < 2 || formData.response5.length < 2 || !isJobTitleSelectionValid() || 
            !formData.firstName || !formData.lastName || !formData.userPhone">
            SUBMIT
          </button>
        </div>
      </div>

      <!-- Section 3 End -->

    </form>
  </div>


</div>