import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private progressActivated = new BehaviorSubject<boolean>(false);
  progressActivated$: Observable<boolean>;
  constructor() {
    this.progressActivated$ = this.progressActivated.asObservable();
  }

  turnOnProgress() {
    this.progressActivated.next(true);
  }

  turnOffProgress() {
    this.progressActivated.next(false);
  }
}
