import { Component } from '@angular/core';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent  {
  constructor(public responsiveUI: ResponsiveUIService) {
  
  }
  
  openNav() {
    document.getElementById("sidenav-right").style.width = "250px";
  }
  
  closeNav() {
    document.getElementById("sidenav-right").style.width = "0";
  }
}
