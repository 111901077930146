<div class="reports">
  <div class="main-content">
    <div class="header">
      <h1 class="header-padding">Reports</h1>
    </div>

    <hr class="thickHr main-bar">

    <div layout="row" layout-align="center center">
      <div style="max-width: 100%" class="layout-flex-column">
        <div class="report-summary layout-flex-row">
          <p>
            MSIX reports provide information for Migrant Education Program (MEP) planning, data analysis, and compliance with MEP and
            MSIX regulations. The list of reports available depends on your user access role within MSIX. If you are looking
            for a report and do not see it in the list below, ask your State, Regional, or District Data Administrator. Contact
            the MSIX Help Desk to suggest new reports or request a one-time ad hoc report.
          </p>
        </div>
        <div class="reports-list">
          <div *ngIf="authRoleClasses.dataDashboards || authRoleClasses.dataDashboard_studentAchievement_with_sua || authRoleClasses.dataDashboard_studentAchievement_with_sda">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Dashboards</h3>
                <p class="report-type-description">Dashboards display charts containing data about key topics to help you make decisions 
                  about your migrant education program, manage your data in MSIX, and understand how users in your State use MSIX. </p>
              </div>
              <div class="report-type-subsection layout-flex-column">
                <div class="report-type-group-dashboard">
                  <a class="report-type" tabindex="0" routerLink="/accountManagement" *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sua">Account Management</a>
                  <a class="report-type" tabindex="0" routerLink="/dataManagement"
                    *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda">Data Management</a>
                  <a class="report-type" tabindex="0" routerLink="/recordsExchange" *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda">Records Exchange</a>
                  <a class="report-type" tabindex="0" routerLink="/childMobility"
                    *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda">Child Mobility</a>
                  <a class="report-type" tabindex="0" routerLink="/mepPopulation" *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda">MEP Population</a>
                  <a class="report-type" tabindex="0" routerLink="/studentAchievement" *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda">Student Achievement</a>
                </div>
                <!-- <div class="report-type-group" flex>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('recordsExchange')" (click)="goToReport('recordsExchange')" 
                    *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda && authRoleClasses.dataDashboard_studentAchievement_with_sua">
                    Records Exchange
                  </a>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('studentAchievement')" (click)="goToReport('studentAchievement')" 
                    *ngIf="authRoleClasses.dataDashboard_studentAchievement_with_sda && !authRoleClasses.dataDashboard_studentAchievement_with_sua">
                    Student Achievement
                  </a>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('studentAchievement')" (click)="goToReport('studentAchievement')" 
                    *ngIf="!(authRoleClasses.dataDashboard_studentAchievement_with_sda && !authRoleClasses.dataDashboard_studentAchievement_with_sua) &&
                    (authRoleClasses.dataDashboard_studentAchievement_with_sda && authRoleClasses.dataDashboard_studentAchievement_with_sua)">
                    Student Achievement
                  </a>
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="authRoleClasses.studentInfo_with_da_ga || authRoleClasses.studentInfo_with_da_ga_mpu_msu">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Student Information</h3>
                <p class="report-type-description">Student Information reports display data made available for exchange among
                  States, including student count, MSIX child count, demographics, enrollment details, course history, and
                  assessments. [34 CFR § 200.85(b)(3)]</p>
              </div>
              <div class="report-type-subsection layout-flex-column" *ngIf=" authRoleClasses.studentInfo_with_da_ga || authRoleClasses.studentInfo_with_da_ga_mpu_msu">
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/studentCount')" (click)="goToReport('/reports/studentInfo/studentCount')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">MSIX ID Count</a>
                  <a class="report-type" tabindex="0"  (keypress)="goToReport('/reports/studentInfo/enrollments-mde')" (click)="goToReport('/reports/studentInfo/enrollments-mde')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">Enrollments (MDE Type)</a>
                    <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/gradeRetention')" (click)="goToReport('/reports/studentInfo/gradeRetention')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga">Grade Retention</a>
                </div>
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/studentChildCount')" (click)="goToReport('/reports/studentInfo/studentChildCount')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga">MSIX Child Count</a>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/enrollments-multiple-states')" (click)="goToReport('/reports/studentInfo/enrollments-multiple-states')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">Enrollments (Multiple States)</a>
                    <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/course-history')" (click)="goToReport('/reports/studentInfo/course-history')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">Course History</a>
                </div>
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/studentDemographics')" (click)="goToReport('reports/studentInfo/studentDemographics')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">Demographics</a>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('/reports/studentInfo/potentialMissedEnrollments')" (click)="goToReport('reports/studentInfo/potentialMissedEnrollments')"
                    *ngIf="authRoleClasses.studentInfo_with_da_ga">Potential Missed Enrollments</a>
                  
                    <a class="report-type" tabindex="0" (click)="goToReport('reports/studentInfo/assessments')" 
                    (keypress)="goToReport('reports/studentInfo/assessments')" 
                    *ngIf="authRoleClasses.studentInfo_with_da_ga_mpu_msu">Assessments</a>
                </div>
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="authRoleClasses.mergeAndSplitWorklist_all && !(responsiveUI.isMobile && responsiveUI.isPortrait)">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Worklists</h3>
                <p class="report-type-description">Worklist reports display data on total worklist items, including outstanding
                  worklist items. [CFR § 200.85(b)(3)(iii)]</p>
              </div>
              <div class="report-type-subsection">
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" (click)='goToReport("/reports/worklists/mergeAndSplitByUser")'
                    *ngIf="authRoleClasses.mergeAndSplitWorklist_all" >Merges & Splits by User</a>
                  <a class="report-type" tabindex="0" (click)='goToReport("/reports/worklists/mergeAndSplitByAge")'
                    *ngIf="authRoleClasses.mergeAndSplitWorklist_all">Merges & Splits by Age</a>
                  <a class="report-type" tabindex="0" routerLink='/reports/worklists/dataRequest'
                    *ngIf="authRoleClasses.dataRequest">Data Requests by Age</a>
                </div>
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" routerLink='/reports/worklists/moveNotice'
                  *ngIf="authRoleClasses.moveNotice">Move Notices</a>
                </div>
                <!-- <div class="report-type-group" flex>
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="authRoleClasses.dataQuality_all && !(responsiveUI.isMobile && responsiveUI.isPortrait)">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Data Quality</h3>
                <p class="report-type-description">Data Quality reports help State and Regional Data Administrators verify
                  data quality compliance levels by showing recent files submitted by States and displaying student records
                  that contain incomplete or invalid data, potential duplicates, and data logic issues. [34 CFR § 200.85
                  (d)]</p>
              </div>
              <div class="report-type-subsection">
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" routerLink="/fileSubmission"
                    *ngIf="authRoleClasses.dataQuality_all">File Submission</a>
                  <a class="report-type" tabindex="0" routerLink="/dataValidity"
                    *ngIf="authRoleClasses.dataQuality_all">Data Validity</a>
                  <a class="report-type" tabindex="0" routerLink="/dataCompleteness"
                    *ngIf="authRoleClasses.dataQuality_all">Data Completeness</a>
                </div>
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type data-quality" tabindex="0" routerLink="/potentialDuplicates"
                    *ngIf="authRoleClasses.dataQuality_all && !authRoleClasses.dataQuality_sbs">Potential Duplicates</a>
                  <a class="report-type" tabindex="0" routerLink="/dataLogic"
                    *ngIf="authRoleClasses.dataQuality_all">Data Logic Issues</a>
                  <!-- Empty one for alignment -->
                  <a class="report-type" tabindex="0" *ngIf="authRoleClasses.dataQuality_all"> </a>
                </div>
                <!-- <div class="report-type-group" flex>
                  <a class="report-type" tabindex="0" (keypress)="goToReport('dataCompleteness')" (click)="goToReport('dataCompleteness')"
                    *ngIf="authRoleClasses.dataQuality_all">Data </a>
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="(authRoleClasses.childCountReconciliation || isBetaUser) && !(responsiveUI.isMobile && responsiveUI.isPortrait)">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Reconciliation</h3>
                <p class="report-type-description">Reconciliation reports help you identify data quality 
                  issues in student records that may cause differences in child and subcategory counts 
                  between MSIX and your State system. Use these reports to review and reconcile Child Count
                  and subcategory counts against your State system.</p>
              </div>
              <div class="report-type-subsection">
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type" tabindex="0" routerLink="/reports/reconciliation/dashboard">Category 1 and Category 2 Child Count</a>
                  <a class="report-type" tabindex="0" routerLink="/reports/reconciliation/age-birth-through-2/dashboard">Age Birth through 2</a>
                  <a class="report-type" tabindex="0" routerLink="/reports/reconciliation/pfsReport">PFS, EL, IEP, QAD</a>
                </div>
                <!-- <div class="report-type-group" style="flex: auto">
                </div>
                <div class="report-type-group" style="flex: auto">
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="authRoleClasses.studentMobility">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>Student Mobility</h3>
                <p class="report-type-description">Student Mobility reports display data on children moving to and from different
                  States. Use these reports to analyze patterns and plan identification and recruitment (ID&R) efforts.</p>
              </div>
              <div class="report-type-subsection" *ngIf="authRoleClasses.studentMobility">
                <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.studentMobility">
                  <a class="report-type-50" tabindex="0" routerLink='/generalMoveFrom'>General Move From</a>
                  <a class="report-type" tabindex="0" routerLink='/generalMoveTo'>General Move To</a>
                </div>
                <!-- <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.studentMobility">
                  
                </div>
                <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.studentMobility">
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>

          <div *ngIf="(authRoleClasses.userAccount_with_ga || authRoleClasses.accountMetrics) && !(responsiveUI.isMobile && responsiveUI.isPortrait)">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>User Account</h3>
                <p class="report-type-description">The Account List Report displays profile information for all MSIX users
                  within your State, including roles and contact details, to facilitate accounts management and periodic
                  reviews of user accounts. [CFR § 200.85 (f)(2)]</p>
              </div>
              <div class="report-type-subsection" *ngIf="authRoleClasses.userAccount_with_ga || authRoleClasses.accountMetrics">
                <div class="report-type-group" style="flex: auto">
                  <a class="report-type-50" tabindex="0" routerLink="/accountList" *ngIf="authRoleClasses.userAccount_with_ga">Account List</a>
                  <a class="report-type" tabindex="0" routerLink="/accountMetrics" *ngIf="authRoleClasses.accountMetrics">Account Metrics</a>
                </div>
                <!-- <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.accountMetrics">
                </div> -->
              </div>
            </div>
            <hr layout-padding class="thickHr worklistHr">
          </div>
          
          <div *ngIf="authRoleClasses.msixUsage && !(responsiveUI.isMobile && responsiveUI.isPortrait)">
            <div class="report-type-section">
              <div class="report-type-subsection layout-flex-column">
                <h3>MSIX Usage</h3>
                <p class="report-type-description">MSIX Usage reports display page hits and the number of student records
                  viewed by users in different States.</p>
              </div>
              <div class="report-type-subsection" *ngIf="authRoleClasses.msixUsage">
                <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.msixUsage">
                  <a class="report-type-50" tabindex="0" routerLink="/reports/msixUsage/userPageCounts">User Page Counts</a>
                  <a class="report-type" tabindex="0" routerLink="/reports/msixUsage/studentRecordsExchange">Student Records Exchange</a>
                </div>
                <!-- <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.msixUsage">
                  
                </div>
                <div class="report-type-group" style="flex: auto" *ngIf="authRoleClasses.msixUsage">
                </div> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>