import { Component, Inject } from '@angular/core';
//import { Moment } from 'moment';
import * as moment from 'moment';
import * as angular from "angular";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountListService } from './accountList.service';
import { Subscription } from 'rxjs';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { COLUMN_NUMBER } from '../../types/enums';

@Component({
  selector: 'accountList',
  templateUrl: './accountList.component.html',
  styleUrls: ['./accountList.component.scss']
})
export class AccountListComponent {
  router: string;
  showFilter: boolean = true;
  isInit: boolean = false;
  showDrill: boolean = false;
  isReportLoadedAtLeaseOnce: boolean = false;
  exportCall: string = 'findUserInfoExport';
  loginExportCall: string = 'findUserLoginInfoExport';

  jobsArray: any[] = []
  response: any = {};
  blankRes: boolean = false;
  userKeys: any[] = [];
  // holds the list of states
  states: any[] = [];
  // holds the set of selected stateKeys
  fullStateArray: any = [];
  showHistory: boolean;
  disableWithdrawalDate: boolean;
  params: any;
  stateMap: any;
  allStates: any = [];

  resetFilters: boolean = true;
  reportInfo: any;
  drillDown: any = {};
  currentSortColumn: string;
  sortAscending: boolean;
  stayOnDrillDown: boolean;
  drillDownDetails: any = {};
  drillDownData: any = {};
  disablePrev: boolean;
  disableNext: boolean;
  userKeysIndex: number;
  reportGroupVar: string;
  reportDrillDownVar: string;
  assignedDistricts: any[];
  //hold export count for Reports
  exportCount: number;


  //holds filter parameters
  filterParameters: any = {
    firstName: null,
    lastName: null,
    activeFlg: null,
    disabledFlg: null,
    deactivatedFlg: null,
    robYesFlg: null,
    robNoFlg: null,
    userID: null,
    activeStartDate: null,
    activeEndDate: null,
    createStartDate: null,
    createEndDate: null,
    expireStartDate: null,
    expireEndDate: null,
    lastLoginStartDate: null,
    lastLoginEndDate: null,
    selectedStatesList: null,
    availableStatesList: [],
    exportType: null,
    districts: null,
    regionDrill: false,
    districtDrill: true,
    sort: 'USER_ID_ASC',
    otherJobTitle: null,
    jobTitles: [],
    states: [],
    activationDate: {}
  };
  selectedStatesArray: any = [];
  userStatusArray: any = [];
  userTypeArray: any = [];
  creationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  activationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  loginDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };
  expirationDate: any = {
    startMM: null,
    startDD: null,
    startYYYY: null,
    endMM: null,
    endDD: null,
    endYYYY: null
  };

  // holds the current active filter parameters
  activeFilter: any;
  activeSelectedStatesArray: any;
  activeStatusArray: any;
  activeTypeArray: any;
  activeCreationDate: any;
  activeActivationDate: any;
  activeLoginDate: any;
  activeExpirationDate: any;

  //this object holds current & recent reporting period for display purposes
  displayData = {
    current: "",
    recent: "",
    category: "",
    subcategory: ""
  };

  userStatus = [
    {
      key: 1,
      code: 'Active',
      desc: 'Active'
    },
    {
      key: 2,
      code: 'Disabled',
      desc: 'Disabled'
    },
    {
      key: 3,
      code: 'Deactivated',
      desc: 'Deactivated'
    },
    {
      key: 4,
      code: 'Locked',
      desc: 'Locked'
    },
    {
      key: 5,
      code: 'Expired',
      desc: 'Expired'
    }
  ];

  userTypes: any[];
  //Holds user types
  baseUserTypes = [
    {
      key: 2,
      code: 'State Data Administrator',
      desc: 'State Data Administrator'
    },
    {
      key: 3,
      code: 'MSIX Primary User',
      desc: 'MSIX Primary User'
    },
    {
      key: 4,
      code: 'District Data Administrator',
      desc: 'District Data Administrator'
    },
    {
      key: 5,
      code: 'State User Administrator',
      desc: 'State User Administrator'
    },
    {
      key: 6,
      code: 'Secondary User',
      desc: 'Secondary User'
    },
    {
      key: 8,
      code: 'State Batch Submitter',
      desc: 'State Batch Submitter'
    },
    {
      key: 9,
      code: 'State Regional Administrator',
      desc: 'State Regional Administrator'
    },
    {
      key: 10,
      code: 'Regional Data Administrator',
      desc: 'Regional Data Administrator'
    },
    {
      key: 11,
      code: 'Regional User Administrator',
      desc: 'Regional User Administrator'
    }
  ];

  userTypesOME_SU = [
    {
      key: 1,
      code: 'OME User Administrator',
      desc: 'OME User Administrator'
    },
    {
      key: 7,
      code: 'Government Administrator',
      desc: 'Government Administrator'
    },
    {
      key: 12,
      code: 'MSIX Privacy Act Administrator',
      desc: 'MSIX Privacy Act Administrator'
    }
  ];

  userTypesSU = [{
    key: 13,
    code: 'Super User',
    desc: 'Super User'
  }];

  subscription = new Subscription();
  constructor(
    public dialog: MatDialog,
    private reportsService: ReportsService,
    private userService: UserService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private service: AccountListService,
    private previousRouteService: PreviousRouteService) {
    this.router = _router.url;
    this.userKeysIndex = 0;
    this.stayOnDrillDown = false;
    this.disablePrev = false;
    this.disableNext = false;
    this.reportGroupVar = "product";
    this.reportDrillDownVar = "findUserLoginInfo";
    //holds values for maintaining sort
    this.sortAscending = true;
    this.currentSortColumn = 'column1';
    this.exportCount = 0;
    this.showHistory = false;
    this.disableWithdrawalDate = false;
    this.reportInfo = {};
    this.stateMap = new Map();
    this.userStatusArray = [];
    this.filterParameters.otherJobTitle = null;
  }

  async ngOnInit() {
    this.reportsService.sortColumn$.next({columnNumber: this.reportsService.sortColumn, sortOrder: this.reportsService.sortOrder}); // emit last set sortColumn state so reportsTable component can set the sortColumn and sortOrder in the subscription
    this.sortAscending = this.reportsService.sortOrder === 'asc'; 
    this.subscription.add(this.previousRouteService.previousRoute$.subscribe(async prevRoute => {
      if(prevRoute === '/reports') {
        this.reportsService.filterDialogValues = null;
        this.reportsService.drillDownLevelControlValues = null;
        this.reportsService.sortColumn$.next({columnNumber: COLUMN_NUMBER.COLUMN_ONE, sortOrder: 'asc'});
        this.reportsService.sortColumn = COLUMN_NUMBER.COLUMN_ONE;
        this.reportsService.sortOrder = 'asc';
        await this.getJobTitles('');
      } 
    }));
  }

  goToReport() {
    this.isInit = false;
    this.showDrill = false;
    this.filterParameters.userID = null;
    this._router.navigate(['accountList']);
  }

  //Gets the list of the job titles to display and select
  getJobTitles(userKey) {
    let apiCall = this.userService.getJobTitles(userKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        for (var i in data) {
          data[i].selected = true;
        }
        this.jobsArray = data;

        //Pull the information to load the filter
        this.getReportInfo();
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - accountList.getJobTitles(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  // gets info needed to load the filter
  async getReportInfo() {
    this.reportInfo = await this.reportInfoService.getReportInfo(['userAccount_with_oua', 'userAccount_with_ga', 'userAccount', 'superUser']);
    console.log("this.reportInfo: ", this.reportInfo);

    this.mineAndSetStatesArray(this.reportInfo.stateData);
    this.setStatusArray(this.userStatus);
    let tempUserTypeArray = JSON.parse(JSON.stringify(this.baseUserTypes));
    if (this.reportInfo.authRoleClasses.userAccount == true) {
      tempUserTypeArray = tempUserTypeArray.concat(this.userTypesOME_SU)
    }
    if (this.reportInfo.authRoleClasses.superUser == true) {
      tempUserTypeArray = tempUserTypeArray.concat(this.userTypesSU)
    }
    this.userTypes = JSON.parse(JSON.stringify(tempUserTypeArray));
    this.setTypesArray(this.userTypes);
    this.setSelectedStates();
    this.setSelectedStatus(this.resetFilters);
    this.setSelectedTypes();
    this.filterActivate();
  }

  filterActivate() {

    if (this.isReportLoadedAtLeaseOnce) {
      if (this.filterParameters.jobTitles && this.filterParameters.jobTitles.length > 0) {
        for (var i in this.jobsArray) {
          var jt = this.filterParameters.jobTitles.find(({ jobTitleKey }) => jobTitleKey === this.jobsArray[i].jobTitleKey);

          // If jt was found, then it means it was selected during last report load
          this.jobsArray[i].selected = (jt) ? true : false;
        }
      } else {
        // Report loaded with no job titles selected
        this.deselectAllJobTitles();
      }
    } else {
      // Report was never loaded so it should revert back to the default selection
      this.selectAllJobTitles();
    }

    //combine the fields necessary into 1 object (filterParameters) to pass to the filter
    this.filterParameters.fullStateArray = JSON.parse(JSON.stringify(this.fullStateArray));
    this.filterParameters.states = JSON.parse(JSON.stringify(this.states));
    this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
    this.filterParameters.userStatusArray = JSON.parse(JSON.stringify(this.userStatusArray));
    this.filterParameters.userTypeArray = JSON.parse(JSON.stringify(this.userTypeArray));
    this.filterParameters.creationDate = JSON.parse(JSON.stringify(this.creationDate));
    this.filterParameters.activationDate = JSON.parse(JSON.stringify(this.activationDate));
    this.filterParameters.loginDate = JSON.parse(JSON.stringify(this.loginDate));
    this.filterParameters.expirationDate = JSON.parse(JSON.stringify(this.expirationDate));
    this.filterParameters.authRoleClasses = JSON.parse(JSON.stringify(this.reportInfo.authRoleClasses));
    this.filterParameters.userTypes = JSON.parse(JSON.stringify(this.userTypes));
    this.filterParameters.userStatus = JSON.parse(JSON.stringify(this.userStatus));
    this.filterParameters.userState = JSON.parse(JSON.stringify(this.reportInfo.userState));
    this.filterParameters.jobsArray = JSON.parse(JSON.stringify(this.jobsArray));

    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.showFilter = true;
    console.log('Passed to Account List filter: ', this.filterParameters);
    const dialogRef = this.dialog.open(FilterAccountList, {
      height: 'auto',
      width: '58%',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.filterParameters },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      //Prevent re-submission if dialog is just closed
      if (result) {
        this.filterParameters = result;
        //parse back out the fields that were combined into 1 object (filterParameters) for the filter
        this.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.selectedStatesArray));
        this.userStatusArray = JSON.parse(JSON.stringify(this.filterParameters.userStatusArray));
        this.userTypeArray = JSON.parse(JSON.stringify(this.filterParameters.userTypeArray));
        this.creationDate = JSON.parse(JSON.stringify(this.filterParameters.creationDate));
        this.activationDate = JSON.parse(JSON.stringify(this.filterParameters.activationDate));
        this.loginDate = JSON.parse(JSON.stringify(this.filterParameters.loginDate));
        this.expirationDate = JSON.parse(JSON.stringify(this.filterParameters.expirationDate));
        this.jobsArray = JSON.parse(JSON.stringify(this.filterParameters.jobsArray));
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // This function is called when 'Submit' is clicked on the Account List report filter popup
  submitFilter() {
    // Reset sort column for the Account List report
    this.isInit = true;
    this.filterParameters.sort = 'USER_ID_ASC';
    this.sortByColumn({ column: 'column1', ascending: true });
    this.submitReport();
  }

  // initialize the report
  submitReport() {
    let atLeastOneFilterHasInvalidDate = false;

    this.formatDate();

    if (this.filterParameters.createStartDate === 'Invalid date' || this.filterParameters.createEndDate === 'Invalid date' ||
      this.filterParameters.activeStartDate === 'Invalid date' || this.filterParameters.activeEndDate === 'Invalid date' ||
      this.filterParameters.lastLoginStartDate === 'Invalid date' || this.filterParameters.lastLoginEndDate === 'Invalid date' ||
      this.filterParameters.expireStartDate === 'Invalid date' || this.filterParameters.expireEndDate === 'Invalid date') {
      atLeastOneFilterHasInvalidDate = true;
    }

    this.activeFilter = JSON.parse(JSON.stringify(this.filterParameters));
    this.activeSelectedStatesArray = JSON.parse(JSON.stringify(this.selectedStatesArray));
    this.activeStatusArray = JSON.parse(JSON.stringify(this.userStatusArray));
    this.activeTypeArray = JSON.parse(JSON.stringify(this.userTypeArray));
    this.activeCreationDate = JSON.parse(JSON.stringify(this.creationDate));
    this.activeActivationDate = JSON.parse(JSON.stringify(this.activationDate));
    this.activeLoginDate = JSON.parse(JSON.stringify(this.loginDate));
    this.activeExpirationDate = JSON.parse(JSON.stringify(this.expirationDate));

    if (this.filterParameters.selectedStatesList.length === 0 ||
      this.filterParameters.filterStatusList.length === 0 ||
      this.filterParameters.filterRolesList.length === 0 ||
      atLeastOneFilterHasInvalidDate) {

      this.blankRes = true;
      this.response = []
    } else {
      this.isReportLoadedAtLeaseOnce = true;
      let apiCall = this.reportsService.getAccountListInfo(this.filterParameters);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("AccountList.submitReport(): ", data);
          this.blankRes = false;
          this.response = data;
          if (this.response.rows.length === 0) {
            this.blankRes = true;
          }
          this.userKeys = [];
          this.userKeys = this.response.rows.map((i) => i.originalResult.userKey);
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - AccountList.submitReport(): ", error);
          this.blankRes = false;
          subscribed.unsubscribe();
        }
      });
    }
  }

  goToUserDetails(id) {
    this._router.navigate(['userDetails', {
      username: id
    }]);
  }

  // Learn More Activate
  learnMoreActivate() {
    this.showFilter = true;
    if (this.showDrill) {
      const dialogRef = this.dialog.open(AccountListDDLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.showFilter = false;
      });
    } else {
      const dialogRef = this.dialog.open(AccountListLearnMore, {
        height: 'auto',
        width: '50%',
        minHeight: '325px',
        maxHeight: 'fit-content',
        maxWidth: '95vw',
        hasBackdrop: true,
        disableClose: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        this.showFilter = false;
      });
    }
  }

  handleReportSpecialSortClick(newSortKey) {
    this.isInit = false;
    this.filterParameters.sort = newSortKey.sortKey;
    this.resetFilters = false;
    this.submitReport();
  }

  formatDate() {
    if (this.filterParameters.creationDate.startYYYY || this.filterParameters.creationDate.startMM || this.filterParameters.creationDate.startDD) {
      var startCreateDate = this.filterParameters.creationDate.startYYYY + '-' + this.filterParameters.creationDate.startMM + '-' + this.filterParameters.creationDate.startDD;
      this.filterParameters.createStartDate = moment(startCreateDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.createStartDate = null;
    }
    if (this.filterParameters.creationDate.endYYYY || this.filterParameters.creationDate.endMM || this.filterParameters.creationDate.endDD) {
      var endCreateDate = this.filterParameters.creationDate.endYYYY + '-' + this.filterParameters.creationDate.endMM + '-' + this.filterParameters.creationDate.endDD;
      this.filterParameters.createEndDate = moment(endCreateDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.createEndDate = null;
    }
    if (this.filterParameters.activationDate.startYYYY || this.filterParameters.activationDate.startMM || this.filterParameters.activationDate.startDD) {
      var startActivateDate = this.filterParameters.activationDate.startYYYY + '-' + this.filterParameters.activationDate.startMM + '-' + this.filterParameters.activationDate.startDD;
      this.filterParameters.activeStartDate = moment(startActivateDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.activeStartDate = null;
    }
    if (this.filterParameters.activationDate.endYYYY || this.filterParameters.activationDate.endMM || this.filterParameters.activationDate.endDD) {
      var endActivateDate = this.filterParameters.activationDate.endYYYY + '-' + this.filterParameters.activationDate.endMM + '-' + this.filterParameters.activationDate.endDD;
      this.filterParameters.activeEndDate = moment(endActivateDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.activeEndDate = null;
    }
    if (this.filterParameters.loginDate.startYYYY || this.filterParameters.loginDate.startMM || this.filterParameters.loginDate.startDD) {
      var startLoginDate = this.filterParameters.loginDate.startYYYY + '-' + this.filterParameters.loginDate.startMM + '-' + this.filterParameters.loginDate.startDD;
      this.filterParameters.lastLoginStartDate = moment(startLoginDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.lastLoginStartDate = null;
    }
    if (this.filterParameters.loginDate.endYYYY || this.filterParameters.loginDate.endMM || this.filterParameters.loginDate.endDD) {
      var endLoginDate = this.filterParameters.loginDate.endYYYY + '-' + this.filterParameters.loginDate.endMM + '-' + this.filterParameters.loginDate.endDD;
      this.filterParameters.lastLoginEndDate = moment(endLoginDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.lastLoginEndDate = null;
    }
    if (this.filterParameters.expirationDate.startYYYY || this.filterParameters.expirationDate.startMM || this.filterParameters.expirationDate.startDD) {
      var startExpireDate = this.filterParameters.expirationDate.startYYYY + '-' + this.filterParameters.expirationDate.startMM + '-' + this.filterParameters.expirationDate.startDD;
      this.filterParameters.expireStartDate = moment(startExpireDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.expireStartDate = null;
    }
    if (this.filterParameters.expirationDate.endYYYY || this.filterParameters.expirationDate.endMM || this.filterParameters.expirationDate.endDD) {
      var endExpireDate = this.filterParameters.expirationDate.endYYYY + '-' + this.filterParameters.expirationDate.endMM + '-' + this.filterParameters.expirationDate.endDD;
      this.filterParameters.expireEndDate = moment(endExpireDate, "YYYY-MM-DD").format('x');
    } else {
      this.filterParameters.expireEndDate = null;
    }
  }

  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.userAccount == true) {
      this.filterParameters.selectedStatesList = this.selectedStatesArray
    } else {
      this.filterParameters.selectedStatesList = [{ key: this.reportInfo.userState }];
    }
  }

  setSelectedStatus(reset) {
    if (reset) {
      this.userStatusArray = [];
      var mainArr = this.userStatus
      for (var i = 0; i < mainArr.length; i++) {
        if (mainArr[i].code != "Deactivated") {
          var arr = this.userStatusArray;
          arr.push(mainArr[i])
        }
      }
      this.filterParameters.filterStatusList = this.userStatusArray;
    }
  }

  setSelectedTypes() {
    var roles = this.reportInfo.authRoleClasses;
    var array = this.userTypes
    if (roles.userAccount != true) {
      for (var key in array) {
        if (array[key].key == 1 || array[key].key == 7 || array[key].key == 12) {
          array.splice(Number(key), 1);
        }
      }
    }
    this.filterParameters.filterRolesList = array;
  }

  // removes DC, PR, and WY from states array and sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(obj);
      }
    }
  }

  setStatusArray(statusData) {
    for (var i in statusData) {
      this.userStatusArray.push(statusData[i]);
    }
  }

  setTypesArray(typeData) {
    for (var i in typeData) {
      this.userTypeArray.push(typeData[i]);
    }
  }

  //Pull in Drilldown data
  drillDownInit(data) {
    this.reportsService.sortColumn$.next(null); // emit null, so reportsTable does not set sort column saved in reports service for the drill down
    this.service.currentSortColumn = this.currentSortColumn;
    this.service.sortAscending = this.sortAscending;
    console.log("Data for drillDownInit: ", data);

    this.isInit = false;
    if (data.data) {
      this.drillDownDetails = data.data;
    } else {
      this.drillDownDetails = data;
    }
    // date unix > readable conversion
    if (moment(this.drillDownDetails.createDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.createDate) {
      this.drillDownDetails.createDate = moment(parseInt(this.drillDownDetails.createDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.firstLoginDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.firstLoginDate) {
      this.drillDownDetails.firstLoginDate = moment(parseInt(this.drillDownDetails.firstLoginDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.lastLoginDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.lastLoginDate) {
      this.drillDownDetails.lastLoginDate = moment(parseInt(this.drillDownDetails.lastLoginDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.rulesOfBehaviorDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.rulesOfBehaviorDate) {
      this.drillDownDetails.rulesOfBehaviorDate = moment(parseInt(this.drillDownDetails.rulesOfBehaviorDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.trngCmpltdDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.trngCmpltdDate) {
      this.drillDownDetails.trngCmpltdDate = moment(parseInt(this.drillDownDetails.trngCmpltdDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.expirationDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.expirationDate) {
      this.drillDownDetails.expirationDate = moment(parseInt(this.drillDownDetails.expirationDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.activationDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.activationDate) {
      this.drillDownDetails.activationDate = moment(parseInt(this.drillDownDetails.activationDate)).format("MM/DD/YYYY");
    }
    if (moment(this.drillDownDetails.userLoginDate, "MM/DD/YYYY").format("MM/DD/YYYY") != this.drillDownDetails.userLoginDate) {
      this.drillDownDetails.userLoginDate = moment(parseInt(this.drillDownDetails.userLoginDate)).format("MM/DD/YYYY");
    }

    //replace missing data with "-"
    let obj = this.drillDownDetails
    for (var key in obj) {
      if (obj[key] === "Invalid date" || obj[key] === null || obj[key] === " ") {
        obj[key] = "-"
      }
    }
    this.filterParameters.userID = this.drillDownDetails.userId;
    this.drillDown = this.filterParameters;

    //show Drill Down - hide Report
    if (this.stayOnDrillDown == false) {
      this.showDrill = !this.showDrill;
    }
    this.stayOnDrillDown = false;
    window.scrollTo(0, 0);
    this.updateDrilldown(this.drillDown);
    this.getUserDistricts(this.filterParameters.userID);
    this.userKeysIndex = this.userKeys.indexOf(this.drillDownDetails.userKey);

    //this.drillDownData.query = drillDown;
    this.showDrill = true;

    // disable prev / next button based on location in results
    if (this.userKeysIndex === 0) {
      this.disablePrev = true;
    } else {
      this.disablePrev = false;
    }
    if ((this.userKeysIndex === this.userKeys.length - 1)) {
      this.disableNext = true;
    } else {
      this.disableNext = false;
    }
  }

  getUserDistricts(username) {
    this.userService.getUser(username).then(data => {
      this.assignedDistricts = data.districtNames;
    });
  }

  // get Drill down data
  updateDrilldown(drillDown) {
    this.drillDownData = {};
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.drillDownData = data;
        this.exportCount = this.drillDownData.rows.length
        this.drillDownData.query = drillDown;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.studentIDFilter.sortFields = newSortKey;
    this.updateDrilldown(this.drillDown);
  }
  toggleHistory() {
    this.showHistory = !this.showHistory;
  }

  // enables or disables the withdrawal date field on filter modal
  toggleWithdrawal() {
    this.disableWithdrawalDate = !this.disableWithdrawalDate;
  }

  navigateRecords(direction) {
    this.isInit = false;
    if (direction === 'next') {
      this.userKeysIndex++;
    } else if (direction === 'prev') {
      this.userKeysIndex--;
    }
    this.stayOnDrillDown = true;
    this.drillDownInit(this.response.rows[this.userKeysIndex].originalResult);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(params) {
    this.currentSortColumn = params.column;
    this.sortAscending = params.ascending;
  }

  // Select all the Job Titles on the filter.
  selectAllJobTitles() {
    for (var i in this.jobsArray) {
      this.jobsArray[i].selected = true;
    }
  }

  // Deselect all the Job Titles on the filter.
  deselectAllJobTitles() {
    for (var i in this.jobsArray) {
      this.jobsArray[i].selected = false;
    }
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
}
}

//Separate component for the AccountList report's filter
export interface FilterParameters {
  params: {
    filterParameters: any;
    fullStateArray: any;
    selectedStatesArray: any;
    userStatusArray: any;
    userTypeArray: any;
    creationDate: any;
    activationDate: any;
    loginDate: any;
    expirationDate: any;
    jobsArray: any;
    authRoleClasses: any;
    userTypes: any;
    userStatus: any;
    userState: any;
  }
}
@Component({
  selector: 'filterAccountList',
  templateUrl: './filterAccountList.html',
  styleUrls: ['./accountList.component.scss']
})
export class FilterAccountList {
  filterParameters: any = {};
  resetFilters: boolean = false;
  userStatusArray: any[] = [];
  userTypeArray: any[] = [];
  form = this.fb.group({
    rptGrping: '',
    selectedState: ''
  });
  el: any = null;
  focusOnEEDD: boolean;
  disableOther: boolean = false;
  constructor(public dialogRef: MatDialogRef<FilterAccountList>,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters,
    private fb: FormBuilder,
    private userService: UserService) {
    this.filterParameters = JSON.parse(JSON.stringify(this.data.params));
    this.form = fb.group({
      rptGrping: ['National', Validators.required],
      selectedState: ['', Validators.required]
    });
    this.el = document.getElementById("eligExpDateFromDD");
  }

  ngOnInit() {
    this.el = document.getElementById("eligExpDateFromDD");
  }

  //Gets the list of the job titles to display and select
  getJobTitles(userKey) {
    let apiCall = this.userService.getJobTitles(userKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        for (var i in data) {
          data[i].selected = true;
        }
        this.filterParameters.jobsArray = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - accountList.getJobTitles(): ", error);
        subscribed.unsubscribe();
      }
    });
  }

  resetFilter() {
    this.resetFilters = true;
    this.filterParameters = JSON.parse(JSON.stringify(this.data.params));
    this.filterParameters.firstName = '';
    this.filterParameters.lastName = '';
    this.filterParameters.userID = '';
    this.filterParameters.creationDate = {
      startMM: "",
      startDD: "",
      startYYYY: "",
      endMM: "",
      endDD: "",
      endYYYY: ""
    };
    this.filterParameters.activationDate = {
      startMM: "",
      startDD: "",
      startYYYY: "",
      endMM: "",
      endDD: "",
      endYYYY: ""
    };
    this.filterParameters.loginDate = {
      startMM: "",
      startDD: "",
      startYYYY: "",
      endMM: "",
      endDD: "",
      endYYYY: ""
    };
    this.filterParameters.expirationDate = {
      startMM: "",
      startDD: "",
      startYYYY: "",
      endMM: "",
      endDD: "",
      endYYYY: ""
    };
    this.getJobTitles('');
    this.selectAllJobTitles();
    this.selectAll();
    this.setStatusArray(this.filterParameters.userStatus)
    this.setSelectedStatus(this.resetFilters);
    this.selectAllTypes();
  }

  // Select all the Job Titles on the filter.
  selectAllJobTitles() {
    for (var i in this.filterParameters.jobsArray) {
      this.filterParameters.jobsArray[i].selected = true;
    }
  }

  // Deselect all the Job Titles on the filter.
  deselectAllJobTitles() {
    for (var i in this.filterParameters.jobsArray) {
      this.filterParameters.jobsArray[i].selected = false;
    }
    this.disableOther = true;
  }

  skipToCreationStartDD() {
    if (this.filterParameters.creationDate.startMM) {
      if (this.filterParameters.creationDate.startMM.toString().length >= 2) {
        this.el.focus();
      }
    }
  }

  skipToCreationStartYYYY() {
    if (this.filterParameters.creationDate.startDD) {
      if (this.filterParameters.creationDate.startDD.toString().length >= 2) {
        document.getElementById("creationStartYYYY").focus();
      }
    }
  }

  skipToCreationEndDD() {
    if (this.filterParameters.creationDate.endMM) {
      if (this.filterParameters.creationDate.endMM.toString().length >= 2) {
        document.getElementById("creationEndDD").focus();
      }
    }
  }

  skipToCreationEndYYYY() {
    if (this.filterParameters.creationDate.endDD) {
      if (this.filterParameters.creationDate.endDD.toString().length >= 2) {
        document.getElementById("creationEndYYYY").focus();
      }
    }
  }

  skipToActivationStartDD() {
    if (this.filterParameters.activationDate.startMM) {
      if (this.filterParameters.activationDate.startMM.toString().length >= 2) {
        document.getElementById("activationStartDD").focus();
      }
    }
  }

  skipToActivationStartYYYY() {
    if (this.filterParameters.activationDate.startDD) {
      if (this.filterParameters.activationDate.startDD.toString().length >= 2) {
        document.getElementById("activationStartYYYY").focus();
      }
    }
  }

  skipToActivationEndDD() {
    if (this.filterParameters.activationDate.endMM) {
      if (this.filterParameters.activationDate.endMM.toString().length >= 2) {
        document.getElementById("activationEndDD").focus();
      }
    }
  }

  skipToActivationEndYYYY() {
    if (this.filterParameters.activationDate.endDD) {
      if (this.filterParameters.activationDate.endDD.toString().length >= 2) {
        document.getElementById("activationEndYYYY").focus();
      }
    }
  }

  skipToLoginStartDD() {
    if (this.filterParameters.loginDate.startMM) {
      if (this.filterParameters.loginDate.startMM.toString().length >= 2) {
        document.getElementById("loginStartDD").focus();
      }
    }
  }


  skipToLoginStartYYYY() {
    if (this.filterParameters.loginDate.startDD) {
      if (this.filterParameters.loginDate.startDD.toString().length >= 2) {
        document.getElementById("loginStartYYYY").focus();
      }
    }
  }


  skipToLoginEndDD() {
    if (this.filterParameters.loginDate.endMM) {
      if (this.filterParameters.loginDate.endMM.toString().length >= 2) {
        document.getElementById("loginEndDD").focus();
      }
    }
  }


  skipToLoginEndYYYY() {
    if (this.filterParameters.loginDate.endDD) {
      if (this.filterParameters.loginDate.endDD.toString().length >= 2) {
        document.getElementById("loginEndYYYY").focus();
      }
    }
  }

  skipToExpirationStartDD() {
    if (this.filterParameters.expirationDate.startMM) {
      if (this.filterParameters.expirationDate.startMM.toString().length >= 2) {
        // this.el.focus();
        this.focusOnEEDD = true;
      }
    }
  }

  skipToExpirationStartYYYY() {
    if (this.filterParameters.expirationDate.startDD) {
      if (this.filterParameters.expirationDate.startDD.toString().length >= 2) {
        document.getElementById("expirationStartYYYY").focus();
      }
    }
  }

  skipToExpirationEndDD() {
    if (this.filterParameters.expirationDate.endMM) {
      if (this.filterParameters.expirationDate.endMM.toString().length >= 2) {
        document.getElementById("expirationEndDD").focus();
      }
    }
  }

  skipToExpirationEndYYYY() {
    if (this.filterParameters.expirationDate.endDD) {
      if (this.filterParameters.expirationDate.endDD.toString().length >= 2) {
        document.getElementById("expirationEndYYYY").focus();
      }
    }
  }

  // Updates the 'selected' property for the updated Job Title checkbox on the filter popup. 
  checkJobTitles(jtk) {
    var jobTitle = this.filterParameters.jobsArray.find(({ jobTitleKey }) => jobTitleKey === jtk);

    jobTitle.selected = !jobTitle.selected;

    this.disableOther = (jtk === 10) ? !this.disableOther : this.disableOther;
  }


  selectAll() {
    var roles = this.filterParameters.authRoleClasses;
    if (roles.userAccount == true) {
      this.filterParameters.selectedStatesArray = JSON.parse(JSON.stringify(this.filterParameters.fullStateArray))
      this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray
    } else {
      this.filterParameters.selectedStatesList = [{ key: this.filterParameters.userState }]
    }
  }

  selectNone() {
    this.filterParameters.selectedStatesArray = [];
    this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray;
  }

  selectAllTypes() {
    this.setTypesArray(this.filterParameters.userTypes);
    this.setSelectedTypes();
  }

  selectNoneTypes() {
    this.filterParameters.userTypeArray.length = 0;
    this.filterParameters.filterRolesList = this.filterParameters.userTypeArray;
  }

  setStatusArray(statusData) {
    for (var i in statusData) {
      this.userStatusArray.push(statusData[i]);
    }
  }

  setTypesArray(typeData) {
    for (var i in typeData) {
      this.filterParameters.userTypeArray.push(typeData[i]);
    }
  }

  // sets the ng-checked to true or false based on if stateKey exists in the selectedStatesArray
  existsInSelectedStatesArray(stateKey) {
    let array = this.filterParameters.selectedStatesArray
    let index = array.map(function (e) {
      return e.key
    }).indexOf(stateKey);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  existsInSelectedStatusArray(obj) {
    var array = this.filterParameters.filterStatusList;
    var typeIndex = array.map(function (e) {
      return e.desc;
    }).indexOf(obj.desc);
    if (typeIndex > -1) {
      return true;
    } else {
      return false;
    }
  }

  existsInSelectedTypesArray(obj) {
    var array = this.filterParameters.userTypeArray;
    var typeIndex = array.map(function (e) {
      return e.desc;
    }).indexOf(obj.desc);
    if (typeIndex > -1) {
      return true;
    } else {
      return false;
    }
  }

  // adds and removes stateKeys from the selectedStatesArray based on user click of states' checkbox
  checkedStates(stateKey) {
    let array = this.filterParameters.selectedStatesArray;
    let index = array.map(function (e) {
      return e.key;
    }).indexOf(stateKey);
    if (index === -1) {
      let obj = {
        key: stateKey
      }
      this.filterParameters.selectedStatesArray.push(obj);
    } else {
      this.filterParameters.selectedStatesArray.splice(index, 1);
    }
    this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray
  }

  checkedStatus(obj) {
    var array = this.filterParameters.filterStatusList;
    var typeIndex = array.map(function (e) {
      return e.desc;
    }).indexOf(obj.desc);
    if (typeIndex === -1) {
      this.filterParameters.filterStatusList.push(obj);
    } else {
      this.filterParameters.filterStatusList.splice(typeIndex, 1);
    }
    //this.filterParameters.filterStatusList = this.userStatusArray
  }

  checkedTypes(obj) {
    var array = this.filterParameters.userTypeArray;
    var typeIndex = array.map(function (e) {
      return e.desc;
    }).indexOf(obj.desc);
    if (typeIndex === -1) {
      this.filterParameters.userTypeArray.push(obj);
    } else {
      this.filterParameters.userTypeArray.splice(typeIndex, 1);
    }
    this.filterParameters.filterRolesList = this.filterParameters.userTypeArray;
  }

  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.filterParameters.authRoleClasses;
    if (roles.userAccount == true) {
      this.filterParameters.selectedStatesList = this.filterParameters.selectedStatesArray
    } else {
      this.filterParameters.selectedStatesList = [{ key: this.filterParameters.userState }];
    }
  }

  setSelectedStatus(reset) {
    if (reset) {
      this.userStatusArray = [];
      var mainArr = this.filterParameters.userStatus
      for (var i = 0; i < mainArr.length; i++) {
        if (mainArr[i].code != "Deactivated") {
          var arr = this.userStatusArray;
          arr.push(mainArr[i])
        }
      }
      this.filterParameters.filterStatusList = this.userStatusArray;
    }
  }

  setSelectedTypes() {
    var roles = this.filterParameters.authRoleClasses;
    var array = this.filterParameters.userTypes
    if (roles.userAccount != true) {
      for (var key in array) {
        if (array[key].key == 1 || array[key].key == 7 || array[key].key == 12) {
          array.splice(Number(key), 1);
        }
      }
    }
    this.filterParameters.filterRolesList = array;
  }

  onSubmit() {
    // Set the list of selected JobTitles
    this.filterParameters.jobTitles = [];
    for (var key in this.filterParameters.jobsArray) {
      if (this.filterParameters.jobsArray[key].selected) {
        this.filterParameters.jobTitles.push(this.filterParameters.jobsArray[key]);
      }
    }
    this.dialogRef.close(this.filterParameters);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the AccountList report's Learn More dialog box
@Component({
  selector: 'accountListLearnMore',
  templateUrl: 'accountListLearnMore.html',
  styleUrls: ['./accountList.component.scss']
})
export class AccountListLearnMore {
  constructor(
    public dialogRef: MatDialogRef<AccountListLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the AccountList report's drilldown Learn More dialog box
@Component({
  selector: 'accountListDDLearnMore',
  templateUrl: 'accountListDDLearnMore.html',
  styleUrls: ['./accountList.component.scss']
})
export class AccountListDDLearnMore {
  constructor(
    public dialogRef: MatDialogRef<AccountListDDLearnMore>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
