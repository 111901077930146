import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-custom-assessments-interpretation-control',
    templateUrl: './custom-assessments-interpretation-control.component.html',
    styleUrls: ['./custom-assessments-interpretation-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomAssessmentsInterpretationControlComponent
        }
]
})

export class CustomAssessmentsInterpretationControlComponent implements OnInit, ControlValueAccessor, OnDestroy{
    private _form: FormGroup = new FormGroup({});
    onTouched: () => {};
    onChangeSub: Subscription;
    
    get form() {
        return this._form;
    }

    assessmentInterpretation = [
        {label: "Advanced", value: "6"},
        {label: "Passed", value: "1"},
        {label: "Basic", value: "4"},
        {label: "Other", value: "8"},
        {label: "Proficient or Above", value: "10"},
        {label: "Failed", value: "2"},
        {label: "Below Basic", value: "3"},
        {label: "Invalid/Not Submitted", value: "0, -1"},
        {label: "Proficient", value: "5"},
        {label: "Not Proficient", value: "9"},
        {label: "Far Below Basic", value: "7"},
    ]

    constructor() {
        
    }

    ngOnInit(): void {
      this.assessmentInterpretation.forEach(type => this.form.addControl(type.value, new FormControl(type.value)))
    }

    selectAll() {
        this.assessmentInterpretation.forEach(type => this.form.get(type.value).setValue(type.value))
      }
    
    selectNone() {
        this.assessmentInterpretation.forEach(type => this.form.get(type.value).reset())
    }

    writeValue(value: any): void {
        if (value) {
            this.form.setValue(value)
        }
    }

    registerOnChange(onChange: any): void {
        this.onChangeSub = this.form.valueChanges.subscribe(onChange)
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
       if (isDisabled) {
        this.form.disable();
       } else {
        this.form.enable();
       }
    }

    reset() {
        this.selectAll();
    }
    
    ngOnDestroy(): void {
        this.onChangeSub?.unsubscribe();
    }
}