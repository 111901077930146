<div class="recordsExchange">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
        margin-right: 0;">        
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div>
      <div class="md-dialog-content">
        <p *ngIf="data.params =='topRecordsExchange'">
          Click on the MSIX ID to view a child’s Consolidated Student Record or the Worklist ID to view the Worklist History page.<br />
          <br />
          Use the Export function to download a copy of the report in CSV format.<br />
          <br />
          Use the breadcrumbs at the top of the page to return to the main Records Exchange Dashboard.
        </p>
        <p *ngIf="data.params =='dataReqWorklist'">
          Click on the Worklist ID to view the Worklist History page.<br />
          <br />
          Use the Export function to download a copy of the report in CSV format.<br />
          <br />
          Use the breadcrumbs at the top of the page to return to the main Records Exchange Dashboard.
        </p>
      </div>
    </div>
  </div>
</div>