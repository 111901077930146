<div class="worklist-page">
  <div aria-label="Confirm Merge" class="confirmMergeDialog">
    <!-- TODO - Need to figure out appropriate ngIf condition to replace ng-cloak-->
    <!--form ng-cloak-->
    <form>
      <mat-toolbar>
        <div class="md-toolbar-tools toolbar">
          <h2>Select Master Record</h2>
          <span style="display: flex; flex: auto"></span>
          <button class="md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">
          <div class="layout-flex-row">
            <p>
              Please select a master record and confirm that you wish to merge the following
              <b>{{(selectedMergeItems.length)}} records</b>. To edit the merged records, select 'cancel' and make your correction.
            </p>
          </div>
          <hr class="thinHr">
          <div class="">
            <div class="layout-flex-row">
              <div class="block2 flex-15">Master</div>
              <div class="block2 flex-20">Name</div>
              <div class="block2 flex-20">MSIX ID</div>
              <div class="block2 flex-20">State ID</div>
              <div class="block2 flex-20">Date of Birth</div>
            </div>
          </div>

          <mat-radio-group [(ngModel)]="nominateMergeObject.master" class="md-primary mergeDialogRadioGroup" 
            [ngModelOptions]="{standalone: true}">
            <mat-radio-button *ngFor="let record of selectedMergeItems" [value]="record" class="layout-row flex">
              <div class="layout-flex-row" style="width: 600px;">
                <div style="margin-left: 10%" class="flex-20 confirmMergeNameColumn">
                  <p>{{record.demographics.lastName1}}
                    <span *ngIf="record.demographics.lastName2 !=''">&nbsp;{{record.demographics.lastName2}}</span>, {{record.demographics.firstName}}<span class="removeUnderline" *ngIf="record.demographics.midName != ''">&nbsp;{{record.demographics.midName}}</span>
                  </p>
                </div>
                <div class="flex-20">
                  <p>{{record.demographics.msixId}}</p>
                </div>
                <div class="flex-20">
                  <p>{{record.demographics.studentStateID}}</p>
                </div>
                <div class="flex-20">
                  <p>{{record.demographics.birthDate}}</p>
                </div>
              </div>
              <br>
            </mat-radio-button>
          </mat-radio-group>

          <hr class="thinHr">
          <div class="layout-flex-column layout-padding-l-r-17">
            <div flex class="custom-input-container worklist-commentbox-padding">
              <mat-form-field class="md-block md-input-container-margins background-white" style="width: 100%; border: 2px solid gray">
                <mat-label class="custom-label">Comments</mat-label>
                <textarea matInput class="custom-input" [(ngModel)]="nominateMergeObject.comments" maxlength="250" rows="3" 
                  md-select-on-focus [ngModelOptions]="{standalone: true}"></textarea>
                <div class="md-errors-spacer">
                  <div class="md-char-counter">{{nominateMergeObject.comments.length}} / 250</div>
                </div>
              </mat-form-field>
            </div>
            <div flex class="commentbox-instructions">
              <p class="small worklist-commentbox-instructions">
                To protect the student's privacy, do not include any sensitive information such as Social Security Numbers or other Personally Identifiable Information in Comments fields. Comments entered will be shared unedited and in their entirety with
                other MSIX Users.
              </p>
            </div>
          </div>
          <hr class="thinHr">
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="layout-flex-row">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span style="display: flex; flex: auto"></span>
        <button (click)="confirmingMerge()" class="confirmButton md-button" [disabled]="!nominateMergeObject.master.demographics"
          style="margin: 0">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
