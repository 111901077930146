import { Component, Input, Output, forwardRef, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomMatInputForFilterComponent),
  multi: true
};

@Component({
  selector: 'custom-mat-input-for-filter',
  templateUrl: './customMatInputForFilter.component.html',
  styleUrls: ['./customMatInputForFilter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomMatInputForFilterComponent
    }
  ]
})

export class CustomMatInputForFilterComponent implements ControlValueAccessor {
  @Input() searchTextValue: string = "";
  @Input() customlabel: string = "Missing customlabel";
  @Input() errorlength: number;
  @Input() nondateinput: boolean = false;
  @Input() inputFieldName: string = "inputField";
  @Input() inputFieldID: string = "inputFieldID"
  @Input() inputdisabled: boolean = false;
  @Input() formcontrolname: string = "";
  @Input() maxLength: number;
  @Input() nextInputElement?: CustomMatInputForFilterComponent;

  @ViewChild('inputField') inputField: MatInput

  @Output() model: string;
  @Output() updateForm = new EventEmitter<any>();
  @Output() ngsubmit? = new EventEmitter<any>();

  onChange = (searchTextValue) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;
  element: HTMLElement;
  
  constructor() { } 
  
  ngOnInit() {
    this.element = document.getElementById("filterInput");
  }

  onValueChanged($event) {
    this.markAsTouched();
    this.onChange(this.searchTextValue);
    this.updateForm.emit({formcontrol: this.formcontrolname, value: this.searchTextValue});
  }

  writeValue(searchTextValue: string): void {
    this.searchTextValue = searchTextValue;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onSubmit() {
    this.ngsubmit.emit();
  }

  @HostListener('keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value?.length >= this.maxLength && this.nextInputElement) {
      this.nextInputElement?.inputField?.focus()
    }
  }

}
