<div class="filterMsixUsage">
    <div class="md-toolbar-tools toolbar layout-row">
      <h2>User Page Counts Filter</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog"
        style="margin-left: auto; margin-right: 0;">
        <i (click)="onClose()" class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

  <div mat-dialog-content class="md-dialog-content">
    <div style="flex-direction: row">
      <div style="flex-direction: column; flex: auto">
        <p class="pFilterHeader">
          Date Ranges:
        </p>
      </div>
    </div>

    <div class="layout-row filter-section dates">
      <div class="flex-30">
        <p class="pFilterHeader">
          Usage Date Range:
        </p>
      </div>

      <div class="flex-70 layout-row date-picker ">
        <div class="layout-row start-date-box flex-50">
          <div class="date flex-20">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageStartMMPageCounts" customlabel="MM"
                [nextInputElement]="startDD"
                [(ngModel)]="filterParameters.usageDate.startMM" name="month" [maxLength]="2">
              </custom-mat-input-for-filter>
            </div>
          </div>
          <p class="date-slash flex-5">/</p>
          <div class="date flex-20">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageStartDDPageCounts" customlabel="DD" #startDD
                [nextInputElement]="startYYYY"
                [(ngModel)]="filterParameters.usageDate.startDD" name="month" [maxLength]="2">
              </custom-mat-input-for-filter>
            </div>
          </div>
          <p class="date-slash flex-5">/</p>
          <div class="date flex-30">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageStartYYYYPageCounts" customlabel="YYYY" #startYYYY
                [(ngModel)]="filterParameters.usageDate.startYYYY" name="month" [maxLength]="4">
              </custom-mat-input-for-filter>
            </div>
          </div>
        </div>

        <div class="layout-row end-date-box flex-50">

          <div class="date flex-20">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageEndMMPageCounts" customlabel="MM"
                [(ngModel)]="filterParameters.usageDate.endMM" name="month" [maxLength]="2" [nextInputElement]="endDD">
              </custom-mat-input-for-filter>
            </div>
          </div>
          <p class="date-slash flex-5">/</p>

          <div class="date flex-20">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageEndDDPageCounts" customlabel="DD" #endDD
                [nextInputElement]="endYYYY"
                [(ngModel)]="filterParameters.usageDate.endDD" name="month" [maxLength]="2">
              </custom-mat-input-for-filter>
            </div>
          </div>
          <p class="date-slash flex-5">/</p>

          <div class="date flex-30">
            <div class="md-block">
              <custom-mat-input-for-filter class="dateInput" id="usageEndYYYYPageCounts" customlabel="YYYY" #endYYYY
                [(ngModel)]="filterParameters.usageDate.endYYYY" name="month" [maxLength]="4">
              </custom-mat-input-for-filter>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-row filter-section">
      <div class="flex-30">
        <p class="pFilterHeader">
          Data Field:
        </p>
      </div>
      <mat-radio-group style="margin-top: -10px" class="layout-row" [(ngModel)]="filterParameters.dataField"
        aria-label="Select an option" name="dataField">
        <mat-radio-button value="component" style="padding-right: 45px; width: 130px">
          <p class="radio-label">Component</p>
        </mat-radio-button>
        <mat-radio-button value="userAgent" flex-offset="10" style="width: 130px">
          <p class="radio-label">User Agent</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="layout-row filter-section">
      <div class="layout-column flex-30">
        <p class="pFilterHeader">
          Group By:
        </p>
      </div>
      <mat-radio-group style="margin-top: -10px" class="layout-row flex" [(ngModel)]="filterParameters.groupByField"
        aria-label="Select an option" name="groupByField">
        <mat-radio-button value="monthly" style="padding-right: 45px; width: 130px">
          <p class="radio-label">Monthly</p>
        </mat-radio-button>
        <mat-radio-button value="yearly" flex-offset="10" style="width: 130px">
          <p class="radio-label">Yearly</p>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="layout-row" style="padding: 15px 30px; align-items: center">
    <a style="align-items: center; display: flex;" class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
      Reset Filters
    </a>
    <span flex class="flex"></span>
    <button type="submit" class="md-button confirmButton" style="margin: 0" (click)="onSubmit()">
      SUBMIT
    </button>
  </div>
</div>