import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { CustomMatInputForFilterComponent } from 'src/app/components/customMatInputForFilter/customMatInputForFilter.component';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'msixUsage',
  templateUrl: './msixUsage.component.html',
  styleUrls: ['./msixUsage.component.scss']
})
export class MsixUsageComponent implements OnInit {
  
  exportCall: string = 'MSIXUsageInfoExport';
  showFilter: boolean = true;
  isInit: boolean = true;
  
  currentSortColumn: string;
  sortAscending = true;
  blankRes: boolean = true;

  filterParameters: any = {
    sort: 'USER_ID_ASC',
    usageDateFrom: null,
    usageDateTo: null,
    dataField: "component",
    groupByField: "monthly",
    usageDate: {}
  }
  usageDate: any = { 
    startMM: '',
    startDD: '',
    startYYYY: '', 
    endMM: '',
    endDD: '',
    endYYYY: '' 
  };
  activeUsageDate: any = { 
    startMM: '',
    startDD: '',
    startYYYY: '', 
    endMM: '',
    endDD: '',
    endYYYY: '' 
  };

  activeFilter: any = {};
  response: any = {};
  userKeys: any[];
  constructor (
    private _router: Router,
    public dialog: MatDialog,
    private reportsService: ReportsService) {
  }

  async ngOnInit() {
    await this.resetFilter();
    this.filterActivate();
  }

  async resetFilter() {
    this.filterParameters = {
      "usageDateFrom": null,
      "usageDateTo": null,
      "dataField": "component",
      "groupByField": "monthly"
    }
    this.usageDate = {
      startMM: null,
      startDD: null,
      startYYYY: null,
      endMM: null,
      endDD: null,
      endYYYY: null
    }
  }
  
  // Trigger Filter Modal
  filterActivate() {
    //combine the fields necessary into 1 object (filterParameters) to pass to the filter
    this.filterParameters.usageDate = cloneDeep(this.usageDate);

    this.activeFilter = cloneDeep(this.filterParameters);
    this.showFilter = true;
    const dialogRef = this.dialog.open(FilterMsixUsage, {
      panelClass: 'cdk-overlay-container',
      height: 'fit-content',
      width: '50%',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.filterParameters},
    });

    dialogRef.afterClosed().subscribe(result => {
      //Result should == filterParameters of FilterMsixUsage
      console.log('The dialog was closed', result);
      if(result) {
        this.filterParameters = result;
        //parse back out the fields that were combined into 1 object (filterParameters) for the filter
        this.usageDate = cloneDeep(this.filterParameters.usageDate);
        
        this.submitFilter();
      }
      this.showFilter = false;
    });
  }

  // This function is called when 'Submit' is clicked on the MSIX Usage report filter popup
  submitFilter() {
    // Reset sort column for the MSIX Usage report
    this.isInit = true;
    this.submitReport();
  }

  submitReport() {   
    this.formatDate();
    console.log("FP: ", this.filterParameters);

    this.activeFilter = cloneDeep(this.filterParameters);
    this.activeUsageDate = cloneDeep(this.usageDate);

    let apiCall = this.reportsService.MSIXUsageInfo(this.filterParameters);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("MSIXUsage.submitReport(): ", data);
          this.blankRes = false;
          this.response = data;

          if (this.response.rows.length === 0) {
            this.blankRes = true;
          }

          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - MSIXUsage.submitReport(): ", error);
          this.blankRes = true;
          subscribed.unsubscribe();
        }
      });
  }

  // format string date into UNIX and set filter parameter
  formatDate() {
    if (this.usageDate.startYYYY || this.usageDate.startMM || this.usageDate.startDD) {
      var startUsageDate = this.usageDate.startYYYY + '-' + this.usageDate.startMM + '-' + this.usageDate.startDD;
      this.filterParameters.usageDateFrom = moment(startUsageDate).format('x');
    } else {
      this.filterParameters.usageDateFrom = null;
    }

    if (this.usageDate.endYYYY || this.usageDate.endMM || this.usageDate.endDD) {
      var endUsageDate = this.usageDate.endYYYY + '-' + this.usageDate.endMM + '-' + this.usageDate.endDD;
      this.filterParameters.usageDateTo = moment(endUsageDate).format('x');
    } else {
      this.filterParameters.usageDateTo = null;
    }
  }

  /* handleReportSpecialSortClick(newSortKey) {
    this.isInit = false;
    this.filterParameters.sort = newSortKey.sortKey;
    this.submitReport();
  } */

  //Updates which column and direction the table will be sorted by
  sortByColumn(data) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

}


@Component({
  selector: 'msixUsage-dialog',
  templateUrl: 'filterMsixUsage.html',
  styleUrls: ['./msixUsage.component.scss']
})
export class FilterMsixUsage {
  filterParameters: any = {};
  usageDate: { startMM: any; startDD: any; startYYYY: any; endMM: any; endDD: any; endYYYY: any; };
  constructor(public dialogRef: MatDialogRef<FilterMsixUsage>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) public document: Document) {
      this.filterParameters = cloneDeep(data.params);
  }

  onSubmit() {
    this.dialogRef.close(this.filterParameters);
  }

  onClose() {
    this.dialogRef.close();
  }

  resetFilter() {
    this.filterParameters = {
      usageDate: {
        startMM: '',
        startDD: '',
        startYYYY: '',
        endMM: '',
        endDD: '',
        endYYYY: ''
      },
      dataField: "component",
      groupByField: "monthly"
    }
  }

 
}