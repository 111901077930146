<div class="reportsFilter learnMoreModal">
  <div aria-label="Account Metrics DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-row">
        <div class="layout-column" flex>
          <p *ngIf="drillDownType=='Data Requests Initiated'">
            Use this page to track the quantity, status, and actions taken on data requests initiated by the selected user.<br/>
            <br/>
            If a data request has been open for more than 4 days with no response from the assigned user, it will display as 
            not compliant [34 CFR § 200.85 (e)]. Select the Worklist ID to view the Worklist History page and use the 
            correspondence feature to communicate with the assigned MSIX user about the status of the Data Request. <br/>
            <br/>
            Select the export button to download a zip file containing CSV files of all drilldown data for the selected 
            user including Data Requests Initiated and Responded To, Move Notices Initiated and Responded To, and 
            Consolidated Student Records Downloaded.<br/>
            <br/>
            Use the breadcrumbs at the top of the page to return to the main report.
          </p>
          <p *ngIf="drillDownType=='Data Requests Responded To'">
            Use this page to track the quantity, status, and actions taken on data requests responded to by the selected user.<br/>
            <br/>
            If a data request has been open for more than 4 days with no response from the assigned user, it will display as 
            not compliant [34 CFR § 200.85 (e)]. Select the Worklist ID to view the Worklist History page and use the 
            correspondence feature to communicate about the status of the Data Request.<br/>
            <br/>
            Select the export button to download a zip file containing CSV files of all drilldown data for the selected user 
            including Data Requests Initiated and Responded To, Move Notices Initiated and Responded To, and Consolidated 
            Student Records Downloaded.<br/>
            <br/>Use the breadcrumbs at the top of the page to return to the main report.
          </p>
          <p *ngIf="drillDownType=='Move Notices Initiated'">
            Use this page to track the quantity, status, and actions taken on move notices initiated by the selected user.<br/>
            <br/>
            Move From Notices are represented as Leaving Our Area and Move To Notices are represented as Moved To Our Area in the 
            Move Notice Type column.<br/>
            <br/>
            Select the Worklist ID to view the Worklist History and correspond with the Move Notice recipient.<br/>
            <br/>
            Select the MSIX ID to view the child’s Consolidated Student Record.<br/>
            <br/>
            Select the export button to download a zip file containing CSV files of all drilldown data for the selected 
            user including Data Requests Initiated and Responded To, Move Notices Initiated and Responded To, and 
            Consolidated Student Records Downloaded.<br/>
            <br/>Use the breadcrumbs at the top of the page to return to the main report.
          </p>
          <p *ngIf="drillDownType=='Move Notices Responded To'">
            Use this page to track the quantity, status, and actions taken on move notices responded to by the selected user.<br/>
            <br/>
            Move From Notices are represented as Leaving Our Area and Move To Notices are represented as Moved To Our Area in the 
            Move Notice Type column.<br/>
            <br/>
            Select the Worklist ID to view the Worklist History and correspond with other assignees of the Move Notice.<br/>
            <br/>
            Select the MSIX ID to view the child’s Consolidated Student Record.<br/>
            <br/>
            Select the export button to download a zip file containing CSV files of all drilldown data for the selected 
            user including Data Requests Initiated and Responded To, Move Notices Initiated and Responded To, and 
            Consolidated Student Records Downloaded.<br/>
            <br/>Use the breadcrumbs at the top of the page to return to the main report.
          </p>
          <p *ngIf="drillDownType=='Consolidated Student Records Downloaded'">
            Use this page to track the consolidated student records downloaded by the user.<br/>
            <br/>
            Use the breadcrumbs at the top of the page to return to the main report.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>