import * as d3 from 'd3';
import { Component, Input, SimpleChanges} from '@angular/core';
import * as angular from 'angular';

const ChartTypes = Object.freeze({
  TOPREV: "toPrevChart",
  TOCURR: "toCurrChart",
  FROMPREV: "fromPrevChart",
  FROMCURR: "fromCurrChart"
});

// Make sure that the keys match the ChartTypes constant!
const TooltipHeights = {
  "toPrevChart": "30px",
  "toCurrChart": "30px",
  "fromPrevChart": "30px",
  "fromCurrChart": "30px"
};

const TooltipWidths = {
  "toPrevChart": "100px",
  "toCurrChart": "100px",
  "fromPrevChart": "100px",
  "fromCurrChart": "100px"
};

// Reference the examples housed under d3js.org with questions
@Component({
  selector: 'msix-map',
  templateUrl: './msixMap.component.html',
  styleUrls: ['./msixMap.component.scss']
})

// Reference the examples housed under d3js.org with questions
export class MsixMapComponent {
  // Gets info from Angular bindings, data is coming from the API as a DatabaseWrapper class containing the majority of chart info
  @Input() chart: any;
  @Input() data: any;
  @Input() id: any;

  constructor() {}

  ngOnInit() {
      this.paint();
  }
  // Call the paint function, add listeners for resize or if the chart attribute changes value (filter change)
  
  ngOnChanges(changes: SimpleChanges) {
      window.addEventListener("resize", this.paint);
      this.paint();
  }

  // Function for building the full chart
  paint() {
    // The JSON file is a big polygon generation list and also includes label placement on a mercator projection calculation
    d3.json('./assets/us-states-labels.json').then(usData => {
      d3.select("#" + this.id).selectAll("*").remove();

        var margin = resolveMargin(this.chart);
        var height = window.innerHeight / 1.8 > 340 ? window.innerHeight / 1.8 : 340;
        var width = document.getElementById("chart-section").offsetWidth > 600 ? document.getElementById("chart-section").offsetWidth : 600;
        var lowColor = '#F9F9F9'; // All scales start from background color
        var highColor = resolveColors(this.chart);

        var data = resolveData(this.data);

        var projection;

        // Keeps 16:9 aspect ratio
        if ((height - margin.bottom) / width <= .5625) {
          projection = d3.geoAlbersUsa()
            .fitHeight(height - margin.bottom - margin.top, usData);
          width = (height - margin.bottom) * (16 / 9);
        } else {
          projection = d3.geoAlbersUsa()
            .fitWidth(width - margin.left - margin.right, usData);
          height = width * (9 / 16) + margin.bottom;
        }
        var path = d3.geoPath().projection(projection);

        var minVal = 0;
        var maxVal = 10;

        if (data) {
          for (let row of data[0]) {
            for (let j = 0; j < usData.features.length; j++) {
              // let jsonState = usData.features[j].properties.name;
              if (row.category === usData.features[j].properties.name) {
                  usData.features[j].properties.value = row.value;
                  maxVal = maxVal < row.value ? row.value : maxVal;
              }
            }
          }
        }


        var ramp = d3.scaleLinear().domain([minVal, maxVal]).range([lowColor, highColor]);

        // Small screen adjustment (carryover from original lineChart directive)
        var horizShift = 0;
        if (window.innerWidth < 1100) {
          horizShift = 50;
        }

        // Define the div for the tooltip
        // Selects msix-map element (4 of them: 1 for each mobility chart) and then appends a div element
        var div = d3.select("#" + this.id).append("div")
          .attr("class", "mapchart-tooltip")
          .style("opacity", 0);

        var svg = d3.select("#" + this.id).append('svg')
          .attr("class", "dashboard-svg")
          .attr("viewBox", [0, 0, width, height])
          .style("overflow", "visible");

        var mapG = svg.append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);
        var stateG = mapG.append("g");
        var labelG = mapG.append("g");

        stateG.selectAll("path")
          .data(usData.features)
          .enter()
          .append("path")
          .attr("d", path)
          .style("stroke", "#000")
          .style("stroke-width", "1")
          .style("fill", function (d) { return ramp(d.properties.value) })
          // Add the hover on each state
          .on("pointerover", (event, d) => {
            if (event.pointerType == "touch") {
              event.preventDefault();
              event.stopPropagation();
              return;
            }

            div.transition()
              .duration(200)
              .style("opacity", .9);

            div.html(resolveHover(this.chart, d))
              .style("top", (event.pageY) + "px")
              .style("left", (event.pageX) + "px")
              .style("height", TooltipHeights[this.chart])
              .style("width", TooltipWidths[this.chart])
              .style("position", "absolute") // had to add styles from "mapchart-tooltip" class to here specifically, class wasnt being applied
              .style("text-align", "center")
              .style("font", "12px sans-serif")
              .style("border", "0px")
              .style("padding", "2px")
              .style("background", "lightsteelblue")
              .style("border-radius", "8px")
              .style("pointer-events", "none");
          })
          .on("pointerout", (event, d) => {
            if (event.pointerType == "touch") {
              event.preventDefault();
              event.stopPropagation();
              return;
            }

            div.transition()
              .duration(500)
              .style("opacity", 0);
          })
          .on('pointermove', (event, d) => {
            if (event.pointerType == "touch") {
              event.preventDefault();
              event.stopPropagation();
              return;
            }

            div.style('top', (event.pageY - 87) + 'px')
              .style('left', (event.pageX - horizShift - 100) + 'px');
          })
          .on("touchend touchstart", (event, d) => {
            event.preventDefault();
          });

          labelG.selectAll("labels")
            .data(usData.features)
            .enter().append("text")
            .attr("class", "labels")
            .attr("text-anchor", "middle")
            .attr("pointer-events", "none")
            .attr("font-size", 10)
            .attr("transform", d => {
              if (d.label.labelX == 0 || d.label.labelY == 0)
                return `translate(${path.centroid(d)[0]},${path.centroid(d)[1]})`;
              return `translate(${projection([d.label.labelX, d.label.labelY])[0]},${projection([d.label.labelX, d.label.labelY])[1]})`;
            })
            .attr("x", 0)
            .attr("y", 0)
            .text(d => d.properties.abbr);

          labelG.selectAll("line")
            .data(usData.features.filter(d => d.label.lineX1 != d.label.lineX2 || d.label.lineY1 != d.label.lineY2))
            .enter().append("line")
            .style("stroke", "black")
            .attr("x1", d => projection([d.label.lineX1, d.label.lineY1])[0])
            .attr("y1", d => projection([d.label.lineX1, d.label.lineY1])[1])
            .attr("x2", d => projection([d.label.lineX2, d.label.lineY2])[0])
            .attr("y2", d => projection([d.label.lineX2, d.label.lineY2])[1]);

          // add a legend
          var w = width * .95;
          var h = 30;

          var key = svg
            .append("g")
            .attr("width", w)
            .attr("height", h)
            .attr("class", "legend")
            .style("font-family", "Work Sans, sans-serif")
            .style("font-size", "16px")
            .style("color", "#4A4A4A")
            .attr("transform", `translate(${width / 2 - w / 2},${height - h})`);

          var legend = key.append("defs")
            .append("svg:linearGradient")
            .attr("id", this.chart + "-gradient")
            .attr("x1", "100%")
            .attr("y1", "100%")
            .attr("x2", "0%")
            .attr("y2", "100%")
            .attr("spreadMethod", "pad");

          legend.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", highColor)
            .attr("stop-opacity", 1);

          legend.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", lowColor)
            .attr("stop-opacity", 1);

          key.append("rect")
            .attr("width", w)
            .attr("height", h)
            .style("fill", "url(#" + this.chart + "-gradient" + ")")
            .attr("stroke", "gray")
            .attr("transform", `translate(${0},${0})`);

          var y = d3.scaleLinear()
            .range([w, 0])
            .domain([maxVal, minVal]).nice(5);

          var yAxis = d3.axisTop(y).ticks(5);

          key.append("g")
            .attr("class", "axis")
            .style("font-family", "Work Sans, sans-serif")
            .style("font-size", "15px")
            .style("color", "#4A4A4A")
            .attr("transform", `translate(${0},${0})`)
            .call(yAxis);
      });

      function resolveColors(chartType) {
        if (chartType == ChartTypes.TOPREV) {
          return "#0095A8";
        }
        if (chartType == ChartTypes.FROMPREV) {
          return "#470183";
        }
        if (chartType == ChartTypes.TOCURR) {
          return "#2CA02C";
        }
        if (chartType == ChartTypes.FROMCURR) {
          return "#F74B00";
        }
      }

      function resolveMargin(chartType) {
        return { top: 20, right: 40, bottom: 80, left: 10 };
      }

      function resolveData(fullData) {
        if (fullData.data === undefined) {
          return false;
        }
        let adjustedData = JSON.parse(JSON.stringify(fullData.data));
        for (let row of adjustedData) {
          for (let point of row) {
              point.value = Number(point.value);
          }
        }
        return adjustedData;
      }

      function resolveHover(chartType, d) {
        return d.properties.abbr + "<br/>" + d.properties.value;
      }
  }

}