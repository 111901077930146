<div class="create-edit-delete-new">
    <div class="header">
      <h2>{{title}}</h2>
      <button class="md-button" type="button" mat-dialog-close>
        <i class="fa fa-times ng-scope" aria-hidden="true"></i>
      </button>
    </div>

    <form [formGroup]="form">
      <div class="hint">Edit the name of this list by entering a new list name in the “List Name” field.
        Delete this list by selecting “Delete List”.
        Any action on this list will only affect this list.
      </div>

      <mat-form-field appearance="outline">
        <mat-label>List Name</mat-label>
        <input matInput formControlName="listName"  />
        <mat-error *ngIf="form.get('listName').errors?.required || form.get('listName').errors?.maxlength">List names must be 60 characters or less.</mat-error>
        <mat-error *ngIf="form.get('listName').errors?.duplicateListName">List name already exists. Enter a different name.</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="toDelete">
        <mat-label>Delete List</mat-label>
      </mat-checkbox>

      <div class="hint" *ngIf="form.get('toDelete').value">
        Selecting Delete List will permanently delete this list.
        This action cannot be undone.
      </div>
      <div class="action-buttons">
        <button class="cancel-btn" mat-dialog-close mat-button>Cancel</button>
        <button class="md-button" type="submit" (click)="onSubmit()"
          [disabled]="form.invalid">Submit</button>
      </div>
    </form>
</div>