<div class="reportsFilter recordsExchange">
    <div aria-label="MSIX Usage Filter" class="filter-dialog">
        <div class="md-toolbar-tools toolbar">
            <h2> Student Records Exchange Filter </h2>
            <span flex></span>
            <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog"
                style="margin-left: auto; margin-right: 0;">
                <i matDialogClose class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>

<div class="mat-dialog-content">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-label class="label">DATE RANGES: </mat-label>
            <div class="date-range">
                <mat-label class="label">USAGE DATE RANGE: </mat-label>
                <div class="date-filter-input">
                    <mat-form-field appearance="fill">
                        <mat-label>MM</mat-label>
                        <input app-focus-next-element [focusElement]="fromDD" [requiredLength]="2" maxlength="2" matInput formControlName="fromMM">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>DD</mat-label>
                        <input #fromDD maxlength="2"  app-focus-next-element [focusElement]="fromYYYY" [requiredLength]="2" matInput formControlName="fromDD">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>YYYY</mat-label>
                        <input #fromYYYY maxlength="4" matInput formControlName="fromYYYY">
                    </mat-form-field>

                </div>
                <div class="date-filter-input to-dates">
                    <mat-form-field appearance="fill">
                        <mat-label>MM</mat-label>
                        <input app-focus-next-element [focusElement]="toDD" [requiredLength]="2" maxlength="2" matInput formControlName="toMM">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>DD</mat-label>
                        <input app-focus-next-element [focusElement]="toYYYY" [requiredLength]="2" maxlength="2" #toDD maxlength="2" matInput formControlName="toDD">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>YYYY</mat-label>
                        <input #toYYYY maxlength="4" matInput formControlName="toYYYY">
                    </mat-form-field>

                </div>
        </div>

        <div class="group-by">
            <mat-label class="label">GROUP BY:</mat-label>
            <mat-radio-group formControlName="groupBy" class="flex layout-row" style="margin-top: -10px">
                <mat-radio-button value="monthly">Monthly</mat-radio-button>
                <mat-radio-button value="yearly" style="margin-right: 285px">Yearly</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="action-btn">
            <a style="align-items: center; display: flex;" class="resetButton" type="button" tabindex="0" (keypress)="reset()" (click)="reset()">
                Reset Filters
            </a>
            <button type="submit" class="md-button confirmButton" style="margin: 0" (click)="onSubmit()">
                Submit
            </button>
        </div>
    </form>
</div>