<div class="resetPasswordDialog">
  <div aria-label="Confirm Deactivation" class="confirmDeactivateDialog">
    <!-- TODO - Need to figure out appropriate ngIf condition to replace ng-cloak-->
    <!--form ng-cloak-->
    <form>
      <mat-toolbar>
        <div class="align-right layout-row md-toolbar-tools toolbar">
          <h2 style="padding-top: 20px">Confirm Cancel</h2>
          <span style="flex: auto;"></span>
          <button style="border: none; margin-left: 435px" class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content style="padding: 0px">
        <div class="md-dialog-content">
          <div style="display: flex; flex-direction: row;" class="center">
            <p style="padding: 20px">Please confirm that you wish to cancel. Your changes will not be saved.</p>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions style="display: flex; flex-direction: row; padding: 0px 20px;">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span style="flex:auto"></span>
        <button (click)="cancelGoBack()" class="md-button confirmButton">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </div>

</div>  
