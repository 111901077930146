import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ResponsiveUIService } from '../../services/responsiveUI.service';
import { WorkHistoryService } from './work-history-results.service';

@Component({
  selector: 'app-work-history-results',
  templateUrl: './work-history-results.component.html',
  styleUrls: ['./work-history-results.component.scss']
})
export class WorklistHistoryResultsComponent implements OnInit {

  @Input() totalRecordsLength: number = 30;
  @Input() currentPage: number;
  @Input() itemsPerLoad: number;
  @Input() fullList: any[] = [];
  @Input() searchObject: any = {};
  nextPageStart: number;
  totalItemsToLoad: number;
  @Input() partialWlList: any[] = [];
  router: any;

  constructor(public responsiveUI: ResponsiveUIService,
    private _router: Router,
    private service: WorkHistoryService) {
  }

  ngOnInit(): void {

  }

  viewWorklistHistory(student) {

    this.service.setWorkHistoryResults(this.searchObject);
    var worklistId = student.worklistId;
    var worklistStatus = student.worklistStatus;
    var worklistType = student.worklistType;

    this._router.navigate(['/worklistHistoryRecord'], {
      queryParams: {
        worklistId: worklistId,
        worklistType: worklistType,
        worklistStatus: worklistStatus
      }
    });
  }

  getName(studentName, index) {
    return studentName.split(',')[index];
  }

  loadMoreResults() {
    var loopCount;

    this.currentPage++;
    this.nextPageStart = (this.currentPage - 1) * this.itemsPerLoad;
    this.totalItemsToLoad = this.currentPage * this.itemsPerLoad;
    if (this.partialWlList.length < this.totalItemsToLoad) {
      loopCount = this.partialWlList.length;
    } else {
      loopCount = this.totalItemsToLoad;
    }
    for (var i = this.nextPageStart; i < loopCount; i++) {
      this.fullList.push(this.partialWlList[i]);
    }
  }

}