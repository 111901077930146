<div class="accountManagement" id="showDialog">

  <div style="flex-direction: column; display: flex">
    <!-- Filter Title -->
    <div class="toolbar">
      <h2>Account Management Filter</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
      margin-right: 0;">
        <i (click)="onClose()" class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <!-- Filter Body -->
    <div class="md-dialog-content">
      <div class="layout-row filter-space">
        <div class="filterHeader flex-40">DRILL DOWN LEVEL:</div>
        <mat-radio-group class="flex-60 flex-inline" [(ngModel)]="accountManagementFilterParams.rptGrping">
          <mat-radio-button value="National" style="padding-right: 45px;" (click)="selectNational()">
            <p class="radio-label">National</p>
          </mat-radio-button>
          <mat-radio-button value="State" flex-offset="10" (click)="selectState()">
            <p class="radio-label">State</p>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="layout-row stateSection" *ngIf="accountManagementFilterParams.rptGrping == 'State'">
        <div class="layout-column" flex>
          <div class="filterHeader">STATES:</div>
          <div class="colPOC layout-row" vertical>
            <mat-radio-group *ngIf="responsiveUI.isDesktop" class="state-chart flex-inline" [(ngModel)]="accountManagementFilterParams.selectedState">
              <mat-radio-button *ngFor="let state of accountManagementFilterParams.states" value="{{state.name}}" class="flex-30">
                <p class="radio-label">{{state.name}}</p>
              </mat-radio-button>
            </mat-radio-group>
            <mat-radio-group *ngIf="responsiveUI.isTablet" class="state-chart flex-inline" [(ngModel)]="accountManagementFilterParams.selectedState">
              <mat-radio-button *ngFor="let state of accountManagementFilterParams.states" value="{{state.name}}" class="flex-50">
                <p class="radio-label">{{state.name}}</p>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div> 
    <!-- End filter body -->

    <!-- Buttons on Bottom Row -->
    <div class="layout-row filter-row">
      <a class="resetFilter" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">Reset Filters</a>
      <span flex class="flex"></span>
      <button style="width: 35%;" class="button md-button" (click)="onSubmit()"
        [disabled]="(accountManagementFilterParams.rptGrping == 'State' && !accountManagementFilterParams.selectedState)">
        Submit
      </button>
    </div>

  </div>
</div>