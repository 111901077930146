<!-- TODO - Need to upgrade the conditional styling -->
<!--div class="myaccount-page userDetails-page" [ngClass]="{'main-border main-padding' : ($mdMedia('gt-md')), 'main-border-mobile main-padding-mobile' : ($mdMedia('xs') || $mdMedia('sm') || $mdMedia('md'))}"-->
<div class="myaccount-page userDetails-page main-padding newUser-page">

  <div>
    <div layout-margin class="mainDiv layout-margin">
      <div class="mainHeader">
        <div class="flexSpaceBetween">
          <h2>Account Creation</h2>
          <div class="mainHeaderRight">
            <div>
              <p class="small rightPadding">Denotes required (*)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="thinHr">

    <div layout-margin class="userDetails layout-margin">
      <form *ngIf="newUserForm" [formGroup]="newUserForm" (ngSubmit)="addNewUser()" flex>
        <!-- Section 1 Begin -->
        <!--div layout-gt-xs="row" flex-->
        <div style="display:flex; flex-direction: row">
          <div style="flex:45%;" flex-xs="100">
            <div class="custom-input-container" [ngClass]="{'error': newUserForm.controls['firstName'].touched && 
              newUserFormError('firstName', 'required')}">
              <mat-form-field class="md-block required" [floatLabel]="newUserForm.controls['firstName'].touched && 
                newUserFormError('firstName', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label" for="firstNameInput">First Name</mat-label>
                <input matInput class="custom-input" formControlName="firstName" name="firstName" id="firstNameInput"
                  [ngClass]="{'mat-input-has-value': newUserForm.get('firstName').value,
                  'input-error': newUserForm.controls['firstName'].touched && newUserFormError('firstName', 'required')}">
              </mat-form-field>
              <mat-error class="error-message" *ngIf="newUserForm.controls['firstName'].touched && newUserFormError('firstName', 'required')">Please provide a first name.</mat-error>
            </div>
            <div class="custom-input-container" [ngClass]="{'error': newUserForm.controls['lastName'].touched && 
              newUserFormError('lastName', 'required')}">
              <mat-form-field class="md-block required" [floatLabel]="newUserForm.controls['lastName'].touched && 
                newUserFormError('lastName', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label" for="lastNameInput">Last Name</mat-label>
                <input matInput class="custom-input" formControlName="lastName" name="lastName" id="lastNameInput" 
                  [ngClass]="{'mat-input-has-value': newUserForm.get('lastName').value,
                  'input-error': newUserForm.controls['lastName'].touched && newUserFormError('lastName', 'required')}">
              </mat-form-field>
              <mat-error class="error-message" *ngIf="newUserForm.controls['lastName'].touched && newUserFormError('lastName', 'required')">Please provide a last name.</mat-error>
            </div>
            <div class="custom-input-container" [ngClass]="{'error': newUserForm.controls['email'].touched && 
              newUserFormError('email', 'required')}">
              <mat-form-field class="md-block required" [floatLabel]="newUserForm.controls['email'].touched && 
              newUserFormError('email', 'required') ? 'always' : 'auto'">
                <mat-label class="custom-label" for="emailInput">Email</mat-label>
                <input matInput class="custom-input" formControlName="email" type="email" name="email" id="emailInput"
                  [ngClass]="{'mat-input-has-value': newUserForm.get('email').value,
                  'input-error': newUserForm.controls['email'].touched && newUserFormError('email', 'required')}">
              </mat-form-field>
              <mat-error class="error-message" *ngIf="newUserForm.controls['email'].touched && newUserFormError('email', 'required') || newUserFormError('email','email')">Please provide a valid email address.</mat-error> 
            </div>
            <div class="flex-baseline" style="margin-right: -10px;">
              <div style="flex: 65%" class="custom-input-container" 
              [ngClass]="{'error': newUserForm.controls['userPhone'].touched && newUserFormError('userPhone', 'required')}">
                <mat-form-field class="md-block required" [floatLabel]="newUserForm.controls['userPhone'].touched && 
                  newUserFormError('userPhone', 'required') ? 'always' : 'auto'">
                  <mat-label class="custom-label" for="newUserPhoneInput">Phone</mat-label>
                  <input matInput class="custom-input" maxlength="11" formControlName="userPhone" name="userPhone" id="newUserPhoneInput"
                    [ngClass]="{'mat-input-has-value': newUserForm.get('userPhone').value,
                    'input-error': newUserForm.controls['userPhone'].touched && newUserFormError('userPhone', 'required')}">
                </mat-form-field>
                <mat-error class="error-message" *ngIf="newUserForm.controls['userPhone'].touched && newUserFormError('userPhone', 'required')">Please provide a phone number.</mat-error>
              </div>
              <div style="display: flex; flex: 5%" class="flexCenter">
                <p>x</p>
              </div>
              <!-- TODO - Need to figure out the conditional css to be applied>
              <div flex="30" class="custom-input-container" [ngClass]="{'error': loginForm.userPhoneExt.$invalid && loginForm.userPhoneExt.$touched}"-->
              <div style="display: flex; flex: 30%" class="custom-input-container">
                <mat-form-field class="md-block">
                  <mat-label class="custom-label" for="userPhoneExtInput">Extension</mat-label>
                  <input matInput class="custom-input" formControlName="userPhoneExt" name="userPhoneExt" id="userPhoneExtInput"
                    [ngClass]="{'mat-input-has-value': newUserForm.get('userPhoneExt').value}">
                </mat-form-field>
              </div>
            </div>
          </div>
          <span style="flex: auto;"></span>
          <div class="col1Right" style="flex:45%;" flex-xs="100">
            <div layout="column">
              <div class="passwordReset">
                <p class="header" style="color: #888 !important">Active From/To (Format: MM/DD/YYYY)</p>
                <div class="flex-inline" style="margin-left: -5px; margin-bottom: -20px; margin-top: -5px;">
                  <div class="custom-input-container" style="display: flex; max-width: 35%">
                    <mat-form-field class="md-block activeDate">
                      <mat-label class="custom-label">Active From</mat-label>
                      <input matInput class="custom-input" formControlName="activeDate" name="activationDateStrInput"
                         [ngClass]="{'mat-input-has-value': newUserForm.get('activeDate').value}"
                         style="padding-top: 15px !important;">
                    </mat-form-field>
                  </div>
                  <i class="fa fa-minus" aria-hidden="true" style="display: flex;"></i>
                  <div class="custom-input-container" style="display: flex; max-width: 35%">
                    <mat-form-field class="md-block activeDate">
                      <mat-label class="custom-label">Active To</mat-label>
                      <input matInput class="custom-input" formControlName="expirationDate" name="expirationDateStrInput"
                       [ngClass]="{'mat-input-has-value': newUserForm.get('expirationDate').value}"
                       style="padding-top: 15px !important;">
                    </mat-form-field>
                  </div>
                </div>
                <p style="padding-top: 20px;"><i>Optional: Specify dates to limit application access</i></p>
              </div>
            </div>
          </div>
        </div>
        <!-- User Roles Section Begin -->
        <div class="regions" *ngIf="responsiveUI.isDesktop">
          <div class="flex-inline">
            <p>User Role(s)</p><p><span class="required-asterisk">&nbsp;*</span></p>
          </div>
          <hr class="thinHr">
          <div class="layout-row userRoles" style="flex-wrap:wrap; display:flex" formArrayName="rolesStr">
            <div class="flex-25" *ngFor="let role of rolesArray">
              <mat-checkbox (change)="addRemoveRole($event)" [disabled]="checkRoleDisabled(role)" 
                aria-label="role" [value]="role">
                <p>{{role}}</p>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <!-- User Roles Section End -->
        <!-- Job Titles Section Begin -->
        <div class="regions">
          <div class="flex-inline">
            <p>Job Title</p><p><span class="required-asterisk">&nbsp;*</span></p>
          </div>
          <hr class="thinHr">
          <div class="layout-row userRoles" style="flex-wrap:wrap; display:flex" formArrayName="jobTitles">
            <div *ngFor="let job of newUserJobsArray" [ngClass]="job.jobTitleDesc === 'Other' ? 'flex-75' : 'flex-25'">
              <div *ngIf="job.jobTitleDesc !== 'Other'">
                <mat-checkbox [checked]="job.selected" (change)="addRemoveJobNewUser($event, job)" aria-label="job">
                  <p>{{job.jobTitleDesc}}</p>
                </mat-checkbox>
              </div>
              <div *ngIf="job.jobTitleDesc === 'Other'" style="display:flex; flex-direction: column;" >
                <mat-checkbox [checked]="job.selected" (change)="addRemoveJobNewUser($event, job)" aria-label="job" name="newUserOtherJobTitleCheckbox">
                  <p>{{job.jobTitleDesc}}: Please Specify</p>
                </mat-checkbox>
                <input matInput [disabled]="!job.selected" type="text" name="otherJobTitle" class="other-input"
                  [value]="job.otherJobTitleDesc" (input)="job.otherJobTitleDesc = $event.target.value">
              </div>
            </div>
          </div>
        </div>
        <!-- Job Titles Section End -->
        <!-- Section 2 Begin -->
        <div flex class="address">
          <p>Work Address</p>
          <hr class="thinHr">
          <div layout-gt-xs="row" flex class="main">
            <div flex-gt-xs="45" flex-xs="100" style="display:flex; width: 50%; flex-direction: column;">
              <div class="custom-input-container">
                <mat-form-field class="md-block">
                  <mat-label class="custom-label" for="addLine1Input">Address, line 1</mat-label>
                  <input matInput class="custom-input" formControlName="addLine1" name="addLine1Input"
                    [ngClass]="{'mat-input-has-value': newUserForm.get('addLine1').value}">
                </mat-form-field>
              </div>
              <div class="custom-input-container">
                <mat-form-field class="md-block">
                  <mat-label class="custom-label" for="addLine2Input">Address, line 2</mat-label>
                  <input matInput class="custom-input" formControlName="addLine2" name="addLine2Input"
                    [ngClass]="{'mat-input-has-value': newUserForm.get('addLine2').value}">
                </mat-form-field>
              </div>
              <div class="custom-input-container">
                <mat-form-field class="md-block">
                  <mat-label class="custom-label" for="addCityInput">City</mat-label>
                  <input matInput class="custom-input" formControlName="addCity" name="addCityInput"
                  [ngClass]="{'mat-input-has-value': newUserForm.get('addCity').value}">
                </mat-form-field>
              </div>

              <div class="custom-input-container newUserState" *ngIf="states && states.length" style="padding-top: 10px;">
                <mat-form-field style="width: 98.5%;" class="md-block state-selection">
                  <mat-label class="custom-label" style="margin-top: -5px;" for="userStateInput">State</mat-label>
                  <mat-select disableOptionCentering (selectionChange)="getAdminFlags()" formControlName="addState" 
                    name="userStateInput" style="padding-left: 7px; padding-top: 3px">
                    <mat-option value="-1">No State</mat-option>
                    <mat-option *ngFor="let state of states" [value]="state.stateKey">
                      {{state.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <span flex></span>
          </div>
        </div>
        <!-- Section 2 End -->
        <!-- Regions Section Begin -->
        <div class="regions" *ngIf="responsiveUI.isDesktop && isRegionRoleSelected()">
          <div class="flex-inline">
            <p>Assigned Region(s)</p><p style="color: #00A569 !important;" class="required-asterisk">&nbsp;*</p>
          </div>
          <hr class="thinHr">
          <div class="layout-row layout-wrap flex userRoles">
            <p *ngIf="stateRegions.length ===0">
              Your selected state does not have any regions
            </p>
            <div class="flex-25" *ngFor="let region of stateRegions">
              <mat-checkbox [checked]="isAssignedRegion(region)" (click)="addRegionOrDistrict(region, formData.regions)" aria-label="region">
                <p>{{region.name}}</p>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <!-- Regions Section End -->
        <!-- Districts Section Begin -->
        <div class="regions" *ngIf="responsiveUI.isDesktop && (isDistrictDataRoleSelected() && formData.userState)">
          <div class="flex-inline">
            <p>Assigned District(s)</p><p style="color: #00A569 !important;" class="required-asterisk">&nbsp;*</p>
          </div>
          <hr class="thinHr">
          <div layout="row" layout-wrap flex class="userRoles districtSection">
            <p *ngIf="districtsArray.length ===0">
              Your selected state does not have any districts
            </p>
            <mat-checkbox style="width: 25%;" *ngFor="let district of districtsArray | sortBy:'asc':'name' " [checked]="isAssignedDistrict(district)" (click)="addRegionOrDistrict(district, formData.districts)" aria-label="region">
              <p>{{district.name}}</p>
            </mat-checkbox>
          </div>
        </div>
        <!-- Districts Section End -->
        <!-- Section 3 Begin -->
        
        <hr class="thinHr">
        <div style="display:flex; flex-direction: row-reverse;">
          <div class="buttons">
            <!-- TODO - Need to upgrade the conditional styling
            <button class="md-button" (click)="addNewUser()" class="confirmButton" [disabled]="loginForm.$invalid || checkDistrictRegionSelected(newUserRolesObj) || !hasTrueValue(newUserRolesObj)
              || !(areAllValidJobTitleSelectedForNewUser())"-->
            <button type="submit" class="md-button confirmButton" 
            [disabled]="areDistrictRegionSelectionValid(newUserRolesObj) 
              || !(areAllValidJobTitleSelectedForNewUser()) || newUserForm.invalid">
              Add User
            </button>
          </div>
        </div>
        <!-- Section 3 End -->
      </form>
    </div>

  </div>

</div>
