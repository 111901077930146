<div class="footer">
  <!-- *ngIf="($ctrl.$mdMedia('gt-md') && (currentPath === 'app.login' || currentPath === 'app.pwReset' || currentPath === 'app.adminSearchResults' || currentPath === 'app.privacy' || currentPath === '/login' || currentPath === '/pwreset' || currentPath === '/privacy' || (currentPath.indexOf('adminsearchresults') !== -1)))" -->
  <div class="md-toolbar md-scroll-shrink">
    <div class="footer flexSpaceBetween" layout="row" flex>
      <div class="block footer-spacing requestAccount uppercase" routerLink="requestAccount" flex-gt-md="20" flex-md="25" flex-offset-gt-md="30" flex-offset-md="25">
        Request an account
      </div>
      <div class="block footer-spacing uppercase" routerLink="resources" flex-gt-md="15" flex-md="20">
        Resources
      </div>
      <div class="block footer-spacing uppercase" routerLink="help" flex>
        Contact
      </div>
    </div>
  </div>
</div>
