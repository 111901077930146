import { Component, Input, Output, EventEmitter} from '@angular/core';
import { IssueService } from 'src/app/services/issue.service';
import * as moment from 'moment';

@Component({
  selector: 'issue-dropdown',
  templateUrl: './issueDropdown.component.html',
  styleUrls: ['./issueDropdown.component.scss', '../../views/reports/reconciliation/pfs-el-iep-qad/childDetail/childDetail.component.scss']
})

export class IssueDropdownComponent {
  @Input() modaltitle: any;
  @Input() issuedata: any;
  @Input() iscatoneex: any;

  showModal: boolean;
  directiveReady: boolean;
  constructor(
    private issueService: IssueService) {
      this.showModal = false;
      this.directiveReady = true;
    } 
    
  setIssueData = function(issue, targetProp, returnProp) {
    return this.issueService.setIssueData(issue, targetProp, returnProp, this.modaltitle);
  }

  filterIssue = function(issue){
    if (issue === 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_1' || issue === 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_2'){
      return issue;
    }
  }

  openCloseModal = function () {
    this.showModal = !this.showModal;
  }

  colorSelector = function() {
    return this.iscatoneex == "true" ? 'orange-top-border' : 'red-top-border';
  }

}
