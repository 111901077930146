import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-student-details-historical-raw-view',
  templateUrl: './student-details-historical-raw-view.component.html',
  styleUrls: ['./student-details-historical-raw-view.component.scss']
})
 export class StudentDetailsHistoricalRawViewComponent{
    @Input() rawHistoricalDataElements!: any;
    @Input() showHistorical!: any;
    rawIndex = -1;
    rawAddIDIndex = -1;
    rawQualMovesIndex = -1;
    rawEnrIndex = -1;
    rawEnrItemsIndex = -1;
    rawEnrItemDataAdminIndex = -1;
    IdHistory = -1;
    IdItem = -1;
    courseIndex = -1;
    courseItemIndex = -1;
    assessmentIndex = -1;
    assessmentItemIndex = -1;
    rawDataAdminsNested = -1;
    rawAssessmentsNested = -1;
    rawCoursesNested = -1;
    rawIDNested = -1;
  
    constructor(){
    }
  
    showRawFn(index) {
      if (this.rawIndex === index) {
        this.rawIndex = -1;
      } else {
        this.rawIndex = index;
      }
      this.sortAssessments(index);
    }
  
    //Sorts each list of assessments under the state of this index
    //this.rawHistoricalDataElements[index] = state
    sortAssessments(index) {
      for (let enrollmentIndex in this.rawHistoricalDataElements[index].enrollments) {
        this.rawHistoricalDataElements[index].enrollments[enrollmentIndex].assessments.sort((a,b) => 
          b.asmtAdminDate.substring(2) - a.asmtAdminDate.substring(2)
        );
      }
    }
  
    showRawAddID(index) {
      if (this.rawAddIDIndex === index) {
        this.rawAddIDIndex = -1;
      } else {
        this.rawAddIDIndex = index;
      }
    }
  
    showRawQualMoves(index) {
      if (this.rawQualMovesIndex === index) {
        this.rawQualMovesIndex = -1;
      } else {
        this.rawQualMovesIndex = index;
      }
    }
  
    showRawEnrFn(index) {
      if (this.rawEnrIndex === index) {
        this.rawEnrIndex = -1;
      } else {
        this.rawEnrIndex = index;
      }
    }
  
    showRawEnrItems(index) {
      if (this.rawEnrItemsIndex === index) {
        this.rawEnrItemsIndex = -1;
      } else {
        this.rawEnrItemsIndex = index;
      }
    }
  
    showRawEnrItemDataAdmin(index) {
      if (this.rawEnrItemDataAdminIndex === index) {
        this.rawEnrItemDataAdminIndex = -1;
      } else {
        this.rawEnrItemDataAdminIndex = index;
      }
    }
  
    showIdHistory(index) {
      if (this.IdHistory === index) {
        this.IdHistory = -1;
      } else {
        this.IdHistory = index;
      }
    }
  
    showIdItem(index) {
      if (this.IdItem === index) {
        this.IdItem = -1;
      } else {
        this.IdItem = index;
      }
    }
  
    showCourseHistory(index) {
      if (this.courseIndex === index) {
        this.courseIndex = -1;
      } else {
        this.courseIndex = index;
      }
    }
  
    showCourseItemHistory(index) {
      if (this.courseItemIndex === index) {
        this.courseItemIndex = -1;
      } else {
        this.courseItemIndex = index;
      }
    }
  
    showAssessmentHistory(index) {
      if (this.assessmentIndex === index) {
        this.assessmentIndex = -1;
      } else {
        this.assessmentIndex = index;
      }
    }
  
    showAssessmentItemHistory(index) {
      if (this.assessmentItemIndex === index) {
        this.assessmentItemIndex = -1;
      } else {
        this.assessmentItemIndex = index;
      }
    }
  
    showDataAdminsNested(index) {
      if (this.rawDataAdminsNested === index) {
        this.rawDataAdminsNested = -1;
      } else {
        this.rawDataAdminsNested = index;
      }
    }
    showAssessmentsNested(index) {
      if (this.rawAssessmentsNested === index) {
        this.rawAssessmentsNested = -1;
      } else {
        this.rawAssessmentsNested = index;
        this.assessmentItemIndex = -1;
      }
    }
    showCoursesNested(index) {
      if (this.rawCoursesNested === index) {
        this.rawCoursesNested = -1;
      } else {
        this.rawCoursesNested = index;
        this.courseItemIndex = -1;
      }
    }
  
    showIDHistoryNested(index) {
      if (this.rawIDNested === index) {
        this.rawIDNested = -1;
      } else {
        this.rawIDNested = index;
        this.courseItemIndex = -1;
      }
    }
  
  }