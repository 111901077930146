<div class="reportsFilter learnMoreModal">
  <div aria-label="Filter Submission MDE DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" (keypress)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
    <div class="md-dialog-content" style="height: 350px; overflow-y: scroll">
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'Assessment'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the “Total MDEs Submitted” column reflects the number of MDE values submitted in the file for each MDE.<br /><br />
        The count in the “Total MDEs Missing” column reflects the number of MDE values submitted as null in the file for each MDE.<br /><br />
        The count in the “Total MDEs Rejected” column reflects the number of MDE values that were rejected in the file for each MDE. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report.
      </p>
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'Course History'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the “Total MDEs Submitted” column reflects the number of MDE values submitted in the file for each MDE.<br /><br />
        The count in the “Total MDEs Missing” column reflects the number of MDE values submitted as null in the file for each MDE.<br /><br />
        The count in the “Total MDEs Rejected” column reflects the number of MDE values that were rejected in the file for each MDE. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report.
      </p>
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'Enrollment'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the “Total MDEs Submitted” column reflects the number of MDE values submitted in the file for each MDE.<br /><br />
        The count in the “Total MDEs Missing” column reflects the number of MDE values submitted as null in the file for each MDE.<br /><br />
        The count in the “Total MDEs Rejected” column reflects the number of MDE values that were rejected in the file for each MDE. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report.<br /><br />
      </p>
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'QAD'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the “Total MDEs Submitted” column reflects the number of MDE values submitted in the file for each MDE.<br /><br />
        The count in the “Total MDEs Missing reflects” column the number of MDE values submitted as null in the file for each MDE.<br /><br />
        The count in the “Total MDEs Rejected” column reflects the number of MDE values that were rejected in the file for each MDE. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report.<br /><br />
      </p>
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'Student Demographic'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the ‘Total MDEs Submitted’ column reflects the number of MDE values submitted in the file for each MDE.<br /><br />
        The count in the “Total MDEs Missing” column reflects the number of MDE values submitted as null in the file for each MDE.<br /><br />
        The count in the “Total MDEs Rejected” column reflects the number of MDE values that were rejected in the file for each MDE. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report or the File Submission Details page.<br /><br />
      </p>
      <p *ngIf="learnMoreParameters.mdeDrilldown == 'Non-MDE'">
        Use this report to determine trends in data submission success, errors, and rejection rates.<br /><br />
        The count in the “Total Non-MDEs Submitted” column reflects the number of Non-MDE values submitted in the file for each field.<br /><br />
        The count in the “Total Non-MDEs Rejected” column reflects the number of Non-MDE values that were rejected in the file for each field. For each MDE rejected, one of the Error Types (Greater than MAX length, Invalid Format, Invalid Range or Precision, and Invalid Value) may be marked as the error type.<br /><br />
        Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.<br /><br />
        Please note that the additional File Submission MDE Details will only be available for files submitted after 9/19/2021.<br /><br />
        Use the breadcrumbs at the top of the page to return to the main File Submission Report.
      </p>
    </div>
  </div>
</div>