import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { AppConstants } from "src/app/config/index.constants";
import { Observable } from 'rxjs';
import { ITableItemDataSource } from "../../types/interfaces";
@Injectable({providedIn: 'root'})

export class StudentRecordsExchangeService {
    private apiUrl = AppConstants.api;

    private _filterParams:any

    constructor(private http: HttpClient) {}

    set filterParams(params:any) {
        this._filterParams = params;
    }
    get filterParams() {
        return this._filterParams;
    }

    getStudentRecords(params: any): Observable<ITableItemDataSource> {
        // return timer(1000).pipe(
        //     switchMap(() => of(mainTableData))
        // )
        return this.http.post<ITableItemDataSource>(this.apiUrl + 'usageInfo/studentRecordsExchangeData', params)
    }

    studentRecordsExchangeDataExport(params): Observable<any> {
        return this.http.post(this.apiUrl + 'usageInfo/studentRecordsExchangeDataExport', params, {observe: 'response', responseType: 'blob' as 'text'});
      }
    
}