import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveUIService {

    public isMobile: boolean = false;
    public isTablet: boolean = false;
    public isPortrait: boolean = true;
    public isDesktop: boolean = true;

    constructor(public breakPointObserver: BreakpointObserver) {
        console.log("***CDK LAYOUT BREAKPOINTS - START***");
        console.log("Handset portrait: " + Breakpoints.HandsetPortrait);
        console.log("Handset landscape: " + Breakpoints.HandsetLandscape);
        console.log("Tablet portrait: " + Breakpoints.TabletPortrait);
        console.log("Tablet landscape: " + Breakpoints.TabletLandscape);
        console.log("***CDK LAYOUT BREAKPOINTS - END***");
        
        this.breakPointObserver.observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape]).subscribe(result => {

            let breakpoints = result.breakpoints;

            if (breakpoints[Breakpoints.HandsetPortrait]) {
                this.isMobile = true;
                this.isTablet = false;
                this.isPortrait = true;
                this.isDesktop = false;
                console.log("*&*&*& Handset portrait detected. *&*&*&");
            } else if (breakpoints[Breakpoints.HandsetLandscape]) {
                console.log("*&*&*& Handset landscape detected. *&*&*&");
                this.isMobile = true;
                this.isTablet = false;
                this.isPortrait = false;
                this.isDesktop = false;
            } else if (breakpoints[Breakpoints.TabletPortrait]) {
                console.log("*&*&*& Tablet portrait detected. *&*&*&");
                this.isMobile = false;
                this.isTablet = true;
                this.isPortrait = true;
                this.isDesktop = false;
            } else if (breakpoints[Breakpoints.TabletLandscape]) {
                console.log("*&*&*& Tablet landscape detected. *&*&*&");
                this.isMobile = false;
                this.isTablet = true;
                this.isPortrait = false;
                this.isDesktop = false;
            } else {
                // Desktop mode
                console.log("*&*&*& Some other layout detected. *&*&*& ");
                this.isMobile = false;
                this.isTablet = false;
                this.isPortrait = false;
                this.isDesktop = true;
            }
        });
    }
}