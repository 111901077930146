import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable, throwError, of, Subscription, BehaviorSubject } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { IPerformancePeriod } from '../views/reports/types/interfaces';
// import { currentReportingPeriodRep, mostRecentlyClosedReportingPeriodRep } from '../views/reports/studentInfo/msix-id-count/temp';
// import { mainTableChildCount } from '../views/reports/reconciliation/category-1-and-category-2-child-count/childCount/tmp';
import { COLUMN_NUMBER } from '../views/reports/types/enums';

export interface MSSMDashboard {
  pfsCount: number;
  elCount: number;
   iepCount: number;
   qadCount: number;
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://test.msix.ed.gov/msix-api/service/'
  })
};

export type sortColumnState = {
  columnNumber: COLUMN_NUMBER, 
  sortOrder: 'asc' | 'desc'
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements OnDestroy {
  private _filterDialogValues: any;
  private _drillDownLevelControlValues: any;

  subscription: Subscription = new Subscription();

  private apiUrl = AppConstants.api;

  private currentReportingPeriod: IPerformancePeriod;
  private recentlyClosedReportingPeriod: IPerformancePeriod;

  private dateObj: PPDateObject;

  filterParamsUpdated = new BehaviorSubject<boolean>(false);
  filterParamsUpdated$: Observable<boolean> = this.filterParamsUpdated.asObservable();

  sortColumn: COLUMN_NUMBER = COLUMN_NUMBER.COLUMN_ONE;
  sortOrder: 'asc' | 'desc' = 'asc';
  sortColumn$ = new BehaviorSubject<sortColumnState>({columnNumber: this.sortColumn, sortOrder: this.sortOrder});
  
  constructor(
    public http: HttpClient) {
    this.dateObj = {
      current: '',
      recent: '',
      currentStartYear: 0,
      currentEndYear: 0,
      recentStartYear: 0,
      recentEndYear: 0
    }

  }

  // stores last filter dilaog's values
  set filterDialogValues(val) {
    this._filterDialogValues = val;
  }
  get filterDialogValues() {
    return this._filterDialogValues;
  }

  set drillDownLevelControlValues(val) {
    this._drillDownLevelControlValues = val;
  }

  get drillDownLevelControlValues() {
    return this._drillDownLevelControlValues;
  }


  //General Reports
  getReportsURL(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'reports');
  }

  getCurrentReportingPeriod(): Observable<IPerformancePeriod> {
      // return of(currentReportingPeriodRep);

    if (!this.currentReportingPeriod) {
      const obs = this.http.get<IPerformancePeriod>(this.apiUrl + 'studentInfo/currentReportingPeriodRep');
      this.subscription.add(
        this.http.get<IPerformancePeriod>(this.apiUrl + 'studentInfo/currentReportingPeriodRep')
          .subscribe(period => this.currentReportingPeriod = period));
      return obs;
      // return of(currentReportingPeriodRep);
    }
    return of(this.currentReportingPeriod);
  }

  getRecentlyClosedReportingPeriod(): Observable<IPerformancePeriod> {
    // return of(mostRecentlyClosedReportingPeriodRep)
    if (!this.recentlyClosedReportingPeriod) {
      const obs = this.http.get<IPerformancePeriod>(this.apiUrl + 'studentInfo/mostRecentlyClosedReportingPeriodRep');
      this.subscription.add(
        this.http.get<IPerformancePeriod>(this.apiUrl + 'studentInfo/mostRecentlyClosedReportingPeriodRep')
          .subscribe(period => this.recentlyClosedReportingPeriod = period));
      return obs;
    }
    return of(this.recentlyClosedReportingPeriod);
  }

  //Student Mobility
  studentMobility(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/studentMoveMobilityInfo', params);
  }

  studentMobilityExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/studentMoveMobilityInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  studentMobilityDrillDownExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/studentMobilityDrillDownInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  drillDownView(params, reportGroupVar, reportDrillDownVar): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + reportGroupVar + '/' + reportDrillDownVar, params);
  }

  getMobilityValidValues(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'studentInfo/mobilityValidValues');
  }

   getStateUsValidValues(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'studentInfo/stateUSValidValues');
  }

  // This method calls API to get the list of MEP Participating states.
  getMEPParticipantStates(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'report/MEPParticipantStates');
  }

  //Student Count
  getStudentCountInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/msixStudentCountInfo', params);
  }

  msixStudentCountInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/msixStudentCountInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  studentIDInfoSimilarExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/studentIDInfoSimilarExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //Missed Enrollment
  missedEnrollmentInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/missedEnrollmentInfo', params);
  }

  missedEnrollmentUniqueCount(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/missedEnrollmentUniqueCount', params);
  }

  missedEnrollmentInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/missedEnrollmentInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  missedEnrollmentDrillDownInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/missedEnrollmentDrillDownInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //Enrollment by MDE && Student Course History
  studentInfoSimilar(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/studentInfoSimilar', params);
  }

  studentInfoSimilarExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/studentInfoSimilarExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  studentInfoSimilarDrillDownExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/studentInfoSimilarDrillDownExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //Student Course History
  studentDemographics(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/studentAgeInfo', params);
  }

  //Potential Duplicates
  potentialDuplicatesInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/potentialDuplicatesInfo', params);
  }

  potentialDuplicatesInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/potentialDuplicatesInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  isRegionEnabled(stateKey): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'product/isRegionEnabled/' + stateKey);
  }





  // Account List
  getAccountListInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'product/findUserInfo', params);
  }

  // Account Metrics
  getAccountMetricsInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'report/findUserAccountMetrics', params);
  }

  // Account Metrics export
  getAccountMetricsExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'report/findUserAccountMetricsExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // Account Metrics drilldown export
  getAccountMetricsDrilldownExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'report/findUserAccountMetricsDrilldownExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  findUserInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'product/findUserInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  findUserLoginInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'product/findUserLoginInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // Data Completeness
  dataCompletenessInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/dataCompletenessInfo', params);
  }

  dataCompletenessTotalInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/dataCompletenessTotalInfo', params);
  }

  dataCompletenessInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/dataCompletenessInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //extra param here
  completenessDrillDownExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/' + params.exportPath, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // Data Validity
  dataValidityInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/dataValidityInfo', params);
  }

  dataValidityInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/dataValidityInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //extra param here
  validityDrillDownExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/' + params.exportPath, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // Data Logic
  dataLogicInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'studentInfo/dataLogicInfo', params);
  }

  dataLogicInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/dataLogicInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  dataLogicDrillDownInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'studentInfo/dataLogicDrillDownInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // File Submission
  fileSubmissionFindAllStates(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'product/findAllStates');
  }

  fileSubmissionLoadInfoSelectedStates(statesString, beginRow, endRow, sortField): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'product/findDataLoadInfo/' + statesString + '/' + beginRow + '/' + endRow + '/' + sortField);
  }

  fileSubmissionLoadInfoAllStates(beginRow, endRow, sortField): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'product/findDataLoadInfoAll' + '/' + beginRow + '/' + endRow + '/' + sortField);
  }

  //API call to export the File Submission MDE drilldown page 
  fileSubmissionMdeExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'product/fileSubmissionMdeExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //extra param here
  findDataLoadInfoExport(params): Observable<any> {
    return this.http.get(this.apiUrl + 'product/findDataLoadInfoExport' + '/' + params.statesString + '/' + params.exportType, { observe: 'response', responseType: 'blob' as 'text' });
  }

  findDataLoadInfoExportAll(exportType): Observable<any> {
    return this.http.get(this.apiUrl + 'product/findDataLoadInfoExport' + '/' + exportType, { observe: 'response', responseType: 'blob' as 'text' });
  }

  findCORWorklistAgingCount(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'product/findCORWorklistAgingCount', params);
  }

  // Service method to call API for Move Notices report counts
  findMoveNoticesCount(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'report/findMoveNoticesCounts', params);
  }

  // Service method to call API for Move Notices report counts export
  findMoveNoticesCountExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'report/findMoveNoticesCountsExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  MSIXUsageInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'usageInfo/MSIXUsageInfo', params);
  }

  MSIXUsageInfoExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'usageInfo/MSIXUsageInfoExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  findCORWorklistAgingCountExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'product/findCORWorklistAgingCountExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // Dashboards
  //API call to filter the content on the Student Achievement dashboard
  getStudentAchievementDataFilter(stateName, performancePeriod): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/studentAchievementData?stateName=' + stateName + '&performancePeriod=' + performancePeriod);
  }

  //API call to filter the content on the Child Mobility dashboard
  getChildMobilityFilter(stateName): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/childMobilityData?stateName=' + stateName);
  }

  //API call to filter the content on the Data Management dashboard
  getDataManagementFilter(stateName, performancePeriod): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/dataManagementData?stateName=' + stateName + '&performancePeriod=' + performancePeriod);
  }

  //API call to filter the content on the Records Exchange dashboard
  getRecordsExchangeFilter(stateName, performancePeriod): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/recordsExchangeData?stateName=' + stateName + '&performancePeriod=' + performancePeriod);
  }

  //API call to filter the content on the Account Management dashboard
  getAccountManagementFilter(stateName): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/accountManageData?stateName=' + stateName);
  }

  //API call to filter the content on the MEP Population dashboard
  getMepPopulationFilter(stateName, performancePeriod): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/mepPopulationData?stateName=' + stateName + '&performancePeriod=' + performancePeriod);
  }

  reconciliationDashInfo(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'reconciliation/dashboard', params);
  }

  reconciliationChildCount(params): Observable<any> {
    // return of(mainTableChildCount)
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'reconciliation/childCount', params);
  }

  reconciliationChildCountDetail(params): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'reconciliation/msix/' + params);
  }

  reconciliationExport(params): Observable<any> {
    let report = params.report;
    let exportParams = params.params;
    return this.http.post(this.apiUrl + 'reconciliation/' + report + '/export', exportParams, { observe: 'response', responseType: 'blob' as 'text' });
  }

  reconciliationMdeExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'reconciliation/mde/' + params.report + '/export', params.params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  reconciliationPotentialDupes(params): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + `reconciliation/potentialDuplicates/${params.rowStart}/${params.rowEnd}/${params.sort}/${params.selectedState}/${params.periodType}`);
  }

  reconciliationNearMatches(params): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + `reconciliation/nearMatches/${params.rowStart}/${params.rowEnd}/${params.sort}/${params.selectedState}/${params.periodType}`);
  }

  reconciliationMdeReport(report, params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'reconciliation/mde/' + report, params);
  }

  reconciliationMdeDetails(params): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'reconciliation/mde/details', params);
  }

  /**
 * API call for getting the details for Child Count Reconciliation Grade Level Issues report.
 * 
 * @param {*} params 
 * @returns 
 */
  reconciliationChildCountGradeLevelIssues(params) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'reconciliation/childCountReconGradeLevelIssues', params);
  }

  private handleError(): any {
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  /**
 * API call for getting export with the details for Child Count Reconciliation Grade Level Issues report.
 * @param {*} report 
 * @param {*} params 
 * @returns 
 */
  reconciliationChildCountGradeLevelIssuesExport(params) {
    return this.http.post(this.apiUrl + 'reconciliation/childCountReconGradeLevelIssues/export', params,
    { observe: 'response', responseType: 'blob' as 'text' });
  }

  getMSSMDashboardCount(params): Observable<any> {
    return this.http.post<MSSMDashboard>(this.apiUrl + 'reconciliation/mssmdashboard', params);
  }

  // API Call for MSSM dashboard counts
  getMSSMDrillDown(params): Observable<any> {
    return this.http.post<String[]>(this.apiUrl + 'reconciliation/mssmdrilldown', params);
  }

  getMSSMExport(params): any {
    return this.http.post(this.apiUrl + 'reconciliation/mssmdrilldown/export', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  // API call to get PFS, EL, IEP, and QAD Grade Level Issues drilldown
  getMSSMGradeLevelIssues(params): Observable<any> {
    return this.http.post<String[]>(this.apiUrl + 'reconciliation/mssmdrilldowngradelevelissues', params);
  }

  // API call to get PFS, EL, IEP, and QAD Grade Level Issues drilldown export
  getMSSMGradeLevelIssuesExport(params): any {
    return this.http.post(this.apiUrl + 'reconciliation/mssmdrilldowngradelevelissues/export', params,
      { observe: 'response', responseType: 'blob' as 'text' });
  }

  getAgeBirthThrough2DashboardCount(params): Observable<any> {
    return this.http.post<MSSMDashboard>(this.apiUrl + 'reconciliation/birthThroughTwoDashboard', params);
  }

  // API Call for MSSM dashboard counts
  getAgeBirthThrough2DrillDown(params): Observable<any> {
    return this.http.post<String[]>(this.apiUrl + 'reconciliation/birthThroughTwoDrilldown', params);
  }

  getAgeBirthThrough2Export(params): any {
    return this.http.post(this.apiUrl + 'reconciliation/birthThroughTwoDrilldown/export', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Graduation/HSE chart on the Student Achievement dashboard
  graduationHseExport(stateName): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/graduationHseExport?stateName=' + stateName, stateName, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Grade 11 Algebra chart on the Student Achievement dashboard
  grade11AlgebraExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/grade11AlgebraExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Mobility Maps on the Child Mobility dashboard
  mobilityMapExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/moveMapExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod + '&direction=' + params.direction, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the QAD Enrollements on the Child Mobility dashboard
  qadEnrollmentsExport(stateName): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/qadEnrollExport?stateName=' + stateName, stateName, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Top 10 Records Exchange on the Records Exchange dashboard
  topRecordsExchangeExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/topRecordsExchangeExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Worklist Aging on the Records Exchange dashboard
  worklistAgingExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/worklistAgingExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Record Submission on the Data Management dashboard
  recordSubmissionExport(stateName): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/recordSubmissionExport?stateName=' + stateName, stateName, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Withdrawal Course History on the Data Management dashboard
  withdrawalCourseHistoryExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/withdrawalCourseHistoryExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Account Management chart on the Account Management dashboard
  accountManagementExport(stateName): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/accountManageExport?stateName=' + stateName, stateName, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the MEP Age-for-Grade Breakdown chart on the MEP Population dashboard
  mepStairExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/mepStairExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the PFS, EL, and IEP Population chart on the MEP Population dashboard
  pfsPopulationExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'dashboard/pfsPopulationExport?stateName=' + params.stateName + '&performancePeriod=' + params.performancePeriod, params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to export the Move Notice Partner State details table
  findMoveNoticeDetailsExport(params): Observable<any> {
    return this.http.post(this.apiUrl + 'report/findMoveNoticeDetailsExport', params, { observe: 'response', responseType: 'blob' as 'text' });
  }

  getFirstDayOfMonth(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/getFirstDayOfMonth');
  }

  getPreviousDay(): Observable<any> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'dashboard/getPreviousDay');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export interface PPDateObject {
  current: string,
  recent: string,
  currentStartYear: number,
  currentEndYear: number,
  recentStartYear: number,
  recentEndYear: number
}

export interface StartEndDateObject {
  startMM: string,
  startDD: string,
  startYYYY: string,
  endMM: string,
  endDD: string,
  endYYYY: string
}