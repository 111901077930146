<div class="dataManagement" id="showDialog">

  <div style="flex-direction: column; display: flex">
    <!-- Filter Title -->
    <div class="toolbar">
      <h2>Data Management Filter</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="onClose()" aria-label="close dialog" style="margin-left: auto; 
      margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <!-- Filter Body -->
    <div class="md-dialog-content layout-column">
      <div class="filterHeader">BASE REPORT ON PERFORMANCE PERIOD:</div>
      <mat-radio-group [(ngModel)]="dataManagementFilterParams.periodType">
        <mat-radio-button value="PREVIOUS" style="margin-left: -10px" (click)="selectPeriodType('PREVIOUS')">
          <p class="radio-label">Last performance period ({{dataManagementFilterParams.dateObj.recent}})</p>
        </mat-radio-button>
        <mat-radio-button value="CURRENT" style="margin-left: -10px" (click)="selectPeriodType('CURRENT')">
          <p class="radio-label">Current performance period ({{dataManagementFilterParams.dateObj.current}})</p>
        </mat-radio-button>
      </mat-radio-group>
      <div class="layout-row filter-space" *ngIf="dataManagementFilterParams.authRoleClasses.dataDashboards">
        <div class="filterHeader flex-40">DRILL DOWN LEVEL:</div>
        <mat-radio-group class="flex-60 flex-inline" [(ngModel)]="dataManagementFilterParams.rptGrping">
          <mat-radio-button value="National" style="padding-right: 45px;" (click)="selectNational()">
            <p class="radio-label">National</p>
          </mat-radio-button>
          <mat-radio-button value="State" flex-offset="10" (click)="selectState()">
            <p class="radio-label">State</p>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="layout-row stateSection" *ngIf="dataManagementFilterParams.rptGrping == 'State' && dataManagementFilterParams.authRoleClasses.dataDashboards">
        <div class="layout-column" flex>
          <div class="filterHeader">STATES:</div>
          <mat-input-container>
            <div class="colPOC layout-row" vertical>
              <mat-radio-group *ngIf="responsiveUI.isDesktop" class="state-chart flex-inline" [(ngModel)]="dataManagementFilterParams.selectedState">
                <mat-radio-button *ngFor="let state of dataManagementFilterParams.states" value="{{state.name}}" class="flex-30">
                  <p class="radio-label">{{state.name}}</p>
                </mat-radio-button>
              </mat-radio-group>
              <mat-radio-group *ngIf="responsiveUI.isTablet" class="state-chart flex-inline" [(ngModel)]="dataManagementFilterParams.selectedState">
                <mat-radio-button *ngFor="let state of dataManagementFilterParams.states" value="{{state.name}}" class="flex-50">
                  <p class="radio-label">{{state.name}}</p>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-input-container>
        </div>
      </div>
    </div> 
    <!-- End filter body -->

    <!-- Buttons on Bottom Row -->
    <div class="layout-row filter-row">
      <a class="resetFilter" tabindex="0" (click)="resetFilter()" (keypress)="resetFilter()">Reset Filters</a>
      <span flex class="flex"></span>
      <button style="width: 35%;" class="button md-button" (click)="onSubmit()"
        [disabled]="(dataManagementFilterParams.rptGrping == 'State' && !dataManagementFilterParams.selectedState)
        || !dataManagementFilterParams.periodType">
        Submit
      </button>
    </div>

  </div>
</div>