<!--Raw/Historical View (Desktop only)-->
<div class="studentDetails-page" style="margin: 0px; padding: 0">
    <!--State Raw/Historical View-->
    <div *ngFor="let state of rawHistoricalDataElements; let stateIndex = index" 
      class="newtable2Row table-row-margin" [ngClass]="{activeOutline: rawIndex === stateIndex}">
      <div class="layout-row" flex>
        <div style="width: 95%" class="">
          <div (click)="showRawFn(stateIndex)" class="state-view-flex-container section1 greyBottomBorder">
            <h3 flex class="bold">{{state.stateName}} <span class="idTitle">({{state.stateID}})</span></h3>
          </div>
        </div>
        <div (click)="showRawFn(stateIndex)" class="expandColumn-green flex-container greyBottomBorder" flex>
          <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawIndex === stateIndex)"></i>
          <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawIndex === stateIndex"></i>
        </div>
      </div>
  
      <!--Demographics-->
      <div flex class="layout-row worklist-history-accordion borderBottomGreen" *ngIf="rawIndex === stateIndex">
        <div class="font-13 layout-column flex-historical">
          <div>
            <p flex class="flex-historical bold">Student Name:</p>
            <p class="flex-historical">{{state.demographics[0].firstName}} {{state.demographics[0].lastName1}}
              {{state.demographics[0].lastName2}}</p>
          </div>
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Birth Date:</p>
            <p class="flex-historical">{{(!state.demographics[0].birthDate || state.demographics[0].birthDate==='') ? '--' :
              state.demographics[0].birthDate}}</p>
          </div>
        </div>
        <div class="font-13 layout-column flex-historical">
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Multiple Birth:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].multipleBirthFlag || state.demographics[0].multipleBirthFlag==='') ? '--' :
              state.demographics[0].multipleBirthFlag}}</p>
          </div>
        </div>
        <div class="font-13 layout-column flex-historical">
          <div>
            <p flex class="flex-historical bold">Sex:
            </p>
            <p *ngIf="showHistorical" class="flex-historical">{{(state.demographics[0].gender.toUpperCase() !== "MALE" && state.demographics[0].gender.toUpperCase() !== "FEMALE" && state.demographics[0].gender.toUpperCase() !== "OTHER") ? '--' :
              state.demographics[0].gender}}</p>
            <p *ngIf="!showHistorical" class="flex-historical">{{state.demographics[0].gender}}</p>
          </div>
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Birth Date Verification:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].birthDateVerification || state.demographics[0].birthDateVerification==='')
              ? '--' : state.demographics[0].birthDateVerification}}</p>
          </div>
        </div>
        <div class="font-13 layout-column flex-historical">
          <div>
            <p flex class="flex-historical bold">Parent 1:
            </p>
            <p class="flex-historical" *ngIf="(state.demographics[0].maleParentFirstName || state.demographics[0].maleParentLastName)">{{state.demographics[0].maleParentFirstName}}
              {{state.demographics[0].maleParentLastName}}
            </p>
            <p class="flex-historical" *ngIf="(state.demographics[0].maleParentFirstName === '' && state.demographics[0].maleParentLastName=== '') || (!state.demographics[0].maleParentFirstName && !state.demographics[0].maleParentLastName)">
              --
            </p>
          </div>
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Parent 2:
            </p>
            <p class="flex-historical" *ngIf="(state.demographics[0].femaleParentFirstName || state.demographics[0].femaleParentLastName)">{{state.demographics[0].femaleParentFirstName}}
              {{state.demographics[0].femaleParentLastName}}
            </p>
            <p class="flex-historical" *ngIf="(state.demographics[0].femaleParentFirstName === '' && state.demographics[0].femaleParentLastName=== '') || (!state.demographics[0].femaleParentFirstName && !state.demographics[0].femaleParentLastName)">
              --
            </p>
          </div>
        </div>
        <div class="font-13 layout-column flex-historical">
          <div>
            <p flex class="flex-historical bold">Parent 1 Email Address:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].parent1EmailAddress || state.demographics[0].parent1EmailAddress==='') ? '--' :
              state.demographics[0].parent1EmailAddress}}</p>
          </div>
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Parent 2 Email Address:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].parent2EmailAddress || state.demographics[0].parent2EmailAddress==='')
              ? '--' : state.demographics[0].parent2EmailAddress}}</p>
          </div>
        </div>
        <div class="font-13 layout-column flex-historical">
          <div>
            <p flex class="flex-historical bold">Parent 1 Phone Number:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].parent1PhoneNumber || state.demographics[0].parent1PhoneNumber==='') ? '--' :
              state.demographics[0].parent1PhoneNumber}}</p>
          </div>
          <div class="demographicsColumn">
            <p flex class="flex-historical bold">Parent 2 Phone Number:
            </p>
            <p class="flex-historical">{{(!state.demographics[0].parent1PhoneNumber || state.demographics[0].parent1PhoneNumber==='')
              ? '--' : state.demographics[0].parent1PhoneNumber}}</p>
          </div>
        </div>
      </div>
      <!--Demographics End-->
  
      <!--Additional Accordions-->
      <div flex layout=row *ngIf="rawIndex === stateIndex">
        <span flex=5></span>
        <div class="subAccordion flex-90">
          <!--Additional State IDs-->
          <div>
            <div class="subAccordionBackground">
              <div flex class="layout-align-center-none layout-row borderLightGreyOpen additionalTitles" 
                [ngClass]="{borderBottomLightGrey: rawAddIDIndex === stateIndex}">
                <div style="width: 95%" class="">
                  <div (click)="showRawAddID(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">Additional State or Migrant IDs</p>
                  </div>
                </div>
                <div (click)="showRawAddID(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawAddIDIndex === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawAddIDIndex === stateIndex"></i>
                </div>
              </div>
  
              <div *ngIf="rawAddIDIndex === stateIndex">
                <div flex class="worklist-history-accordion accordionHeaders">
                  <div class="worklist-header-title-full-underline" flex>
                    <div class="worklist-header-titles no-border left-padding-6 ieNoPadding layout-row" style="width: 95%">
                      <div class="block2 flex-historical" flex>State Student ID</div>
                      <div class="block2 flex-historical" flex>State Student ID Type</div>
                    </div>
                  </div>
                  <div class="section1 layout-row" *ngFor="let altId of state.alternateStateStudentIDs" flex="95">
                    <p class="flex-historical" *ngIf="altId.altId">{{altId.altId}}</p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!altId.altId || altId.altId ===''"
                      flex></i>
                    <p class="flex-historical" *ngIf="altId.altIdType">{{altId.altIdType}}</p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!altId.altIdType || altId.altIdType ===''"
                      flex></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Additional State IDs End-->
  
          <!--Qualifying Moves-->
          <div>
            <div class="subAccordionBackground">
              <div flex class="layout-align-center-none layout-row borderLightGreyOpen additionalTitles" 
                [ngClass]="{borderBottomLightGrey: rawQualMovesIndex === stateIndex}">
                <div style="width: 95%" class="">
                  <div (click)="showRawQualMoves(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">Qualifying Moves</p>
                  </div>
                </div>
                <div (click)="showRawQualMoves(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawQualMovesIndex === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawQualMovesIndex === stateIndex"></i>
                </div>
              </div>
  
              <div layout=row *ngIf="rawQualMovesIndex === stateIndex">
                <div flex class="worklist-history-accordion accordionHeaders">
                  <div class="worklist-header-title-full-underline" flex>
                    <div class="worklist-header-titles no-border left-padding-6 ieNoPadding" class="layout-row" flex="95">
                      <block2 class="flex-historical">Qualifying Arrival Date</block2>
                      <block2 class="flex-historical">Eligibility Expiration Date</block2>
                      <block2 class="flex-historical">Qualifying Move From</block2>
                      <block2 class="flex-historical">Qualifying Move To</block2>
                    </div>
                  </div>
  
                  <div class="section1 layout-row" *ngFor="let move of state.qualifyingMoves">
                    <p class="flex-historical" *ngIf="move.qualArrivalDate">{{move.qualArrivalDate}}</p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!move.qualArrivalDate || move.qualArrivalDate ===''"
                      ></i>
                    <p class="flex-historical" *ngIf="move.eligibilityExpirationDate">{{move.eligibilityExpirationDate}}</p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!move.eligibilityExpirationDate || move.eligibilityExpirationDate ===''"
                      flex></i>
                    <p class="flex-historical" *ngIf="move.qadFromCity">{{move.qadFromCity}}
                      <span *ngIf="move.qadFromState !== ''">,
                      </span>{{move.qadFromState}}
                      <span *ngIf="move.qadFromCountry !== ''">,
                      </span>{{move.qadFromCountry}}
                    </p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!move.qadFromCity || move.qadFromCity ===''"
                      flex></i>
                    <p class="flex-historical" *ngIf="move.qadToCity">{{move.qadToCity}}
                      <span *ngIf="move.qadToState !== ''">,
                      </span>{{move.qadToState}}
                    </p>
                    <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!move.qadToCity || move.qadToCity ===''"
                      flex></i>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
          <!--Qualifying Moves End-->
  
          <!--Enrollments-->
          <div>
            <div class="subAccordionBackground">
  
              <!--Enrollments Main Accordion-->
              <div class="layout-row layout-align-center-none borderLightGreyOpen additionalTitles" 
                [ngClass]="{borderBottomLightGrey: rawEnrIndex === stateIndex}">
                <div style="width: 95%" class="">
                  <div (click)="showRawEnrFn(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">Enrollments</p>
                  </div>
                </div>
                <div (click)="showRawEnrFn(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawEnrIndex === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawEnrIndex === stateIndex"></i>
                </div>
              </div>
  
              <!--Enrollment Items Header-->
              <div *ngIf="rawEnrIndex === stateIndex">
                <div style="padding: 18px 0px 0px 0px" class="worklist-history-accordion additionalTitles">
                  <div class="worklist-header-title-full-underline" flex>
                    <div class="worklist-header-titles no-border left-padding-6 ieNoPadding layout-row flex-95">
                      <block2 class="flex-5">State</block2>
                      <block2 class="flex-15-historical">School or Project</block2>
                      <block2 class="flex-15-historical">Enrollment Date</block2>
                      <block2 class="flex-15-historical">Withdrawal Date</block2>
                      <block2 class="flex-historical">Grade</block2>
                      <block2 class="flex-historical">Med Alert</block2>
                      <block2 class="flex-historical">Immun</block2>
                      <block2 class="flex-historical">EL</block2>
                      <block2 class="flex-historical">PFS</block2>
                      <block2 class="flex-historical">IEP</block2>
                    </div>
                  </div>
  
                  <!--Enrollment Item Accordions-->
                  <div *ngFor="let enrItem of state.enrollments; let enrIndex = index" class="newtable2Row table-row-margin">
                    <div class="flex-historical layout-row">
                      <div style="width: 95%">
                        <div (click)="showRawEnrItems(enrIndex)" class="flex-container section1 greyBottomBorder">
                          <p class="flex-5">{{enrItem.state}}</p>
                          <p class="flex-15-historical" *ngIf="enrItem.schoolProjectName">{{enrItem.schoolProjectName}}</p>
                          <i class="flex-15-historical fa fa-minus removeIconMargin" aria-hidden="true" *ngIf="(!enrItem.schoolProjectName || enrItem.schoolProjectName === '') && (!enrItem.schoolProjectName || enrItem.schoolProjectName === '')"
                            ></i>
                          <p class="flex-15-historical">{{enrItem.enrollmentDate}}</p>
                          <p *ngIf="enrItem.withdrawalDate" class="flex-15-historical">{{enrItem.withdrawalDate}}</p>
                          <i class="fa fa-minus removeIconMargin flex-15-historical" aria-hidden="true" *ngIf="(!enrItem.withdrawalDate || enrItem.withdrawalDate == '')"
                            ></i>
                          <p class="flex-historical">{{enrItem.gradeLevel}}</p>
                          <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="!enrItem.medAlert" flex></i>
                          <p *ngIf="enrItem.medAlert" class="flex-historical">{{enrItem.medAlert}}</p>
                          <p class="flex-historical" *ngIf="enrItem.immunizationFlag">{{enrItem.immunizationFlag}}</p>
                          <i class="fa fa-minus removeIconMargin flex-historical" aria-hidden="true" *ngIf="(!enrItem.immunizationFlag || enrItem.immunizationFlag == '')"
                            flex></i>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="enrItem.lepIndicator==='Yes' || enrItem.lepIndicator==='YES' || enrItem.lepIndicator==='yes'"
                            flex>Yes</p>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="!enrItem.lepIndicator || enrItem.lepIndicator==='No' || enrItem.lepIndicator==='NO' || enrItem.lepIndicator==='no'"
                            flex>No</p>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="enrItem.pfsFlag==='Yes' || enrItem.pfsFlag==='YES' || enrItem.pfsFlag==='yes'"
                            flex>Yes</p>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="!enrItem.pfsFlag || enrItem.pfsFlag==='No' || enrItem.pfsFlag==='NO' || enrItem.pfsFlag==='no'"
                            flex>No</p>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="enrItem.iepIndicator==='Yes' || enrItem.iepIndicator==='YES'|| enrItem.iepIndicator==='yes'"
                            flex>Yes</p>
                          <p class="removeIconMargin flex-historical" aria-hidden="true" *ngIf="!enrItem.iepIndicator || enrItem.iepIndicator==='No' || enrItem.iepIndicator==='NO' || enrItem.iepIndicator==='no'"
                            flex>No</p>
                        </div>
                      </div>
                      <div (click)="showRawEnrItems(enrIndex)" class="expandColumn-green flex-container greyBottomBorder flex-historical"
                        >
                        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawEnrItemsIndex === enrIndex)"></i>
                        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawEnrItemsIndex === enrIndex"></i>
                      </div>
                    </div>
  
                    <!--Enrollment Items-->
                    <div class="newtable2Row table-row-margin" *ngIf="rawEnrItemsIndex === enrIndex">
                      <div class="layout-row flex-95 section2" *ngIf="rawEnrItemsIndex === enrIndex">
                        <div class="flex-historical col2-container layout-column">
                          <div class="layout-row">
                            <p class="enrItemText">Enrollment Type:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.enrollmentType
                              || enrItem.enrollmentType==='') ? '--' : enrItem.enrollmentType}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">MEP Project Type:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.mepPrjctTypeCode
                              || enrItem.mepPrjctTypeCode==='') ? '--' : enrItem.mepPrjctTypeCode}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Continuation of Services Reason:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.continuingServiceReasonCode
                              || enrItem.continuingServiceReasonCode==='') ? '--' : enrItem.continuingServiceReasonCode}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Residency Date:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.residencyDate
                              || enrItem.residencyDate==='') ? '--' : enrItem.residencyDate}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Residency Verification Date:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.residencyVerificationDate
                              || enrItem.residencyVerificationDate==='') ? '--' : enrItem.residencyVerificationDate}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">District of Residence:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.residencyDistrictId
                              || enrItem.residencyDistrictId==='') ? '--' : enrItem.residencyDistrictId}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Algebra 1 or Equivalent Indicator:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.algebra1orEquivalentIndicator
                              || enrItem.algebra1orEquivalentIndicator==='') ? '--' :
                              enrItem.algebra1orEquivalentIndicator}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Graduation/HSE Date:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.graduationHSEDate
                              || enrItem.graduationHSEDate==='') ? '--' : enrItem.graduationHSEDate}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Graduation/HSE Indicator:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.graduationHSEIndicator
                              || enrItem.graduationHSEIndicator==='') ? '--' : enrItem.graduationHSEIndicator}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Designated Graduation School:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.designatedGradSchool
                              || enrItem.designatedGradSchool==='') ? '--' : enrItem.designatedGradSchool}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Enrollment Comment:</p>
                            <!-- The top margin needs to match the text size of the enrollment comment (above). Current size for both is 13px-->
                            <div class="dash-divider historical-view-dash" style="background-position: top; margin:13px 5px 0px 5px;"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.enrollmentComment
                              || enrItem.enrollmentComment==='') ? '--' : enrItem.enrollmentComment}}</p>
                          </div>
                        </div>
                        <div class="flex-historical layout-column">
                          <div class="layout-row">
                            <p class="enrItemText">School District ID:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.schoolDistrictId
                              || enrItem.schoolDistrictId==='') ? '--' : enrItem.schoolDistrictId}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">School District Name:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.schoolDistrict
                              || enrItem.schoolDistrict==='') ? '--' : enrItem.schoolDistrict}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">School NCES ID:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.schoolNCESID ||
                              enrItem.schoolNCESID==='') ? '--' : enrItem.schoolNCESID}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">School Telephone Number:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.schoolTelephone
                              || enrItem.schoolTelephone==='') ? '--' : enrItem.schoolTelephone}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Facility Name:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText" *ngIf="(!enrItem.facilityName || enrItem.facilityName === '') && (!enrItem.schoolFacilityName || enrItem.schoolFacilityName === '')">--</p>
                            <p class="align-right enrItemDataText" *ngIf="enrItem.facilityName && (!enrItem.schoolFacilityName || enrItem.schoolFacilityName === '')">{{enrItem.facilityName}}</p>
                            <p class="align-right enrItemDataText" *ngIf="enrItem.schoolFacilityName && (!enrItem.facilityName || enrItem.facilityName === '')">{{enrItem.schoolFacilityName}}</p>
                            <p class="align-right enrItemDataText" *ngIf="enrItem.facilityName && enrItem.schoolFacilityName">{{enrItem.facilityName}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Facility Address:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.facilityLongAddress
                              || enrItem.facilityLongAddress==='') ? '--' : enrItem.facilityLongAddress}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Facility Location:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.facilityLocation
                              || enrItem.facilityLocation==='') ? '--' : enrItem.facilityLocation}}</p>
                          </div>
                          <div class="layout-row">
                            <p class="enrItemText">Out of State Transcript Indicator:</p>
                            <div class="dash-divider historical-view-dash"></div>
                            <p class="align-right enrItemDataText">{{(!enrItem.outofStateTranscript
                              || enrItem.outofStateTranscript==='') ? '--' : enrItem.outofStateTranscript}}</p>
                          </div>
                        </div>
                      </div>
                      <!--Enrollment Items End-->
                      <!-- Begin nested class history -->
                      <div class="nested-items-container">
                        <div flex class="layout-row layout-align-start-none">
                          <div class="nested-record-item borderLightGreyOpen layout flex-95"
                            (click)="showCoursesNested(enrIndex)"
                            [ngClass]="{borderBottomLightGrey: rawCoursesNested == enrIndex}">
                            <div class="flex-historical layout-row layout-align-center-none">
                              <div style="width: 95%" class="">
                                <div class="flex-container section1">
                                  <p class="nested-item-title flex-historical">Course History</p>
                                </div>
                              </div>
                              <div class="expandColumn-white flex-container flex-historical">
                                <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawCoursesNested === enrIndex)"></i>
                                <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawCoursesNested === enrIndex"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dataAdminList nested-items-container layout-row flex-95 layout-align-start-none" 
                          *ngIf="rawCoursesNested === enrIndex">
                          <div class="flex-historical">
                            <!--Course Items Header-->
                            <div class="worklist-header-title-full-underline flex-historical">
                              <div class="worklist-header-titles left-padding-6 no-border ieNoPadding flex-95 layout-row" flex="95">
                                <block2 class="flex-15-historical">Course Title</block2>
                                <block2 class="flex-15-historical">Academic Year</block2>
                                <block2 class="flex-historical">Subject Area</block2>
                                <block2 class="flex-10-historical" >Final Grade</block2>
                                <block2 class="flex-historical">Credits Granted</block2>
                                <block2 class="flex-historical">Grade-To-Date</block2>
                                <block2 class="flex-10-historical" >Clock Hours</block2>
                              </div>
                            </div>
                            <!--Course Item Accordions-->
                            <!--  | orderBy: '-beginAcademicYear' -->
                            <div *ngFor="let courseItem of enrItem.courses; let courseIndex = index" 
                              class="newtable2Row table-row-margin">
                              <div class="layout-row flex-historical">
                                <div class="flex-95">
                                  <div (click)="showCourseItemHistory(courseIndex)" class="flex-container section1 greyBottomBorder">
                                    <i class="flex-25 fa fa-minus" aria-hidden="true" *ngIf="!courseItem.courseTitle || courseItem.courseTitle===''"></i>
                                    <p class="flex-15-historical" *ngIf="courseItem.courseTitle">{{courseItem.courseTitle}}</p>
  
                                    <i class="flex-15-historical fa fa-minus" aria-hidden="true" *ngIf="!(courseItem.beginAcademicYear || courseItem.endAcademicYear) || (courseItem.beginAcademicYear==='' || courseItem.endAcademicYear==='')"></i>
                                    <p class="flex-15-historical" *ngIf="courseItem.beginAcademicYear && courseItem.endAcademicYear">{{courseItem.beginAcademicYear}}-{{courseItem.endAcademicYear}}</p>
  
                                    <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.subjectArea || courseItem.subjectArea===''"></i>
                                    <p class="flex-historical" *ngIf="courseItem.subjectArea">{{courseItem.subjectArea}}</p>
  
                                    <i  class="flex-10-historical fa fa-minus" aria-hidden="true" *ngIf="!courseItem.finalGrade || courseItem.finalGrade===''"></i>
                                    <p *ngIf="courseItem.finalGrade" class="flex-10-historical" >{{courseItem.finalGrade}}</p>
  
                                    <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.creditsGranted || courseItem.creditsGranted===''"></i>
                                    <p class="flex-historical" *ngIf="courseItem.creditsGranted">{{courseItem.creditsGranted}}</p>
  
                                    <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.gradeToDate || courseItem.gradeToDate===''"></i>
                                    <p class="flex-historical" *ngIf="courseItem.gradeToDate">{{courseItem.gradeToDate}}</p>
  
                                    <i  class="flex-10-historical fa fa-minus" aria-hidden="true" *ngIf="!courseItem.clockHours || courseItem.clockHours===''"></i>
                                    <p class="flex-10-historical"  *ngIf="courseItem.clockHours">{{courseItem.clockHours}}</p>
                                  </div>
                                </div>
                                <div (click)="showCourseItemHistory(courseIndex)" 
                                  class="expandColumn-green flex-container greyBottomBorder flex-historical"
                                  >
                                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(courseItemIndex === courseIndex)"></i>
                                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="courseItemIndex === courseIndex"></i>
                                </div>
                              </div>
  
                              <!--Course Item-->
                              <div class="layout-row worklist-history-accordion newtable2Row table-row-margin interiorContainer font-13"
                                *ngIf="courseItemIndex === courseIndex">
                                <div class="column layout-column flex-25">
                                  <p class="bold">Course Section:</p>
                                  <p class="flex-historical">{{(!courseItem.courseSection || courseItem.courseSection==='') ? '--' :
                                    courseItem.courseSection}}</p>
                                </div>
                                <div class="column layout-column flex-25">
                                  <p class="bold">Course Type:</p>
                                  <p class="flex-historical">{{(!courseItem.courseType || courseItem.courseType==='') ? '--' :
                                    courseItem.courseType}}</p>
                                </div>
                                <div class="column layout-column flex-25">
                                  <p class="bold">Term Type:</p>
                                  <p class="flex-historical">{{(!courseItem.termType || courseItem.termType==='') ? '--' :
                                    courseItem.termType}}</p>
                                </div>
  
                              </div>
                              <!--Course Item End-->
                            </div>
                          </div>
                          <!--Course Item Accordions End-->
                        </div>
  
                        <!-- End Course History -->
                        <!-- Assessments -->
                        <div flex="95" class="flex-95 layout-align-start-none" layout-align="start none">
                          <div class="nested-record-item borderLightGreyOpen" flex layout=row (click)="showAssessmentsNested(enrIndex)"
                            [ngClass]="{borderBottomLightGrey: rawAssessmentsNested == enrIndex}">
                            <div class="layout-row flex-historical layout-align-center-none">
                              <div style="width: 95%" class="">
                                <div class="flex-container section1">
                                  <p class="nested-item-title flex-historical" flex>Assessments</p>
                                </div>
                              </div>
                              <div class="expandColumn-white flex-container" flex>
                                <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawAssessmentsNested === enrIndex)"></i>
                                <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawAssessmentsNested === enrIndex"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dataAdminList nested-items-container flex-95 layout-align-start-none" *ngIf="rawAssessmentsNested === enrIndex" layout=row
                          flex="95" layout-align="start none">
                          <!--Assessment Items Header-->
                          <div flex>
                            <div class="worklist-header-title-full-underline" flex>
                              <div class="worklist-header-titles left-padding-6 no-border ieNoPadding layout-row flex-95">
                                <block2 class="flex-20">Assessment Title</block2>
                                <block2 class="flex-historical">Date</block2>
                                <block2 class="flex-25">Content</block2>
                                <block2 class="flex-15-historical">Type</block2>
                                <block2 class="flex-historical">Interpretation</block2>
                              </div>
                            </div>
  
                            <!--Assessment Item Accordions  | orderBy: '-asmtAdminDate'-->
                            <div *ngFor="let assessmentItem of enrItem.assessments; let assessmentIndex = index" class="newtable2Row table-row-margin">
                              <div class="layout-row">
                                <div style="width: 95%" class="">
                                  <div (click)="showAssessmentItemHistory(assessmentIndex)" class="flex-container section1 greyBottomBorder">
                                    <p class="flex-20" *ngIf="assessmentItem.asmtTitle">{{assessmentItem.asmtTitle}}</p>
                                    <i class="flex-20 fa fa-minus" aria-hidden="true" *ngIf="!assessmentItem.asmtTitle || assessmentItem.asmtTitle===''"></i>
  
                                    <p class="flex-historical" *ngIf="assessmentItem.asmtAdminDate">{{assessmentItem.asmtAdminDate}}</p>
                                    <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtAdminDate || assessmentItem.asmtAdminDate===''"></i>
  
                                    <p class="flex-25" *ngIf="assessmentItem.asmtContent">{{assessmentItem.asmtContent}}</p>
                                    <i class="flex-25 fa fa-minus" aria-hidden="true" *ngIf="!assessmentItem.asmtContent || assessmentItem.asmtContent===''"></i>
  
  
                                    <p class="flex-15-historical" *ngIf="showHistorical && assessmentItem.asmtType">{{assessmentItem.asmtType}}</p>
                                    <i class="flex-15-historical fa fa-minus" aria-hidden="true" *ngIf="showHistorical && (!assessmentItem.asmtType || assessmentItem.asmtType==='')"></i>
  
                                    <p class="flex-15-historical" *ngIf="!showHistorical && assessmentItem.asmtTypeCode">{{assessmentItem.asmtTypeCode}}</p>
                                    <i class="flex-15-historical fa fa-minus" aria-hidden="true" *ngIf="!showHistorical && (!assessmentItem.asmtTypeCode || assessmentItem.asmtTypeCode === '')"></i>
  
                                    <p class="flex-historical" *ngIf="assessmentItem.asmtInterpretation">{{assessmentItem.asmtInterpretation}}</p>
                                    <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtInterpretation || assessmentItem.asmtInterpretation===''"></i>
                                  </div>
                                </div>
                                <div (click)="showAssessmentItemHistory(assessmentIndex)" class="expandColumn-green flex-container greyBottomBorder"
                                  flex>
                                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(assessmentItemIndex === assessmentIndex)"></i>
                                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="assessmentItemIndex === assessmentIndex"></i>
                                </div>
                              </div>
  
                              <!--Assessment Item-->
                              <div class="layout-row worklist-history-accordion newtable2Row table-row-margin interiorContainer font-13"
                                *ngIf="assessmentItemIndex === assessmentIndex">
                                <div class="column layout-column flex-25">
                                  <p class="bold">Reporting Method:</p>
                                  <p class="flex-historical">{{(!assessmentItem.asmtRptMethod || assessmentItem.asmtRptMethod==='') ? '--' :
                                    assessmentItem.asmtRptMethod}}</p>
                                </div>
  
                                <div class="column layout-column flex-25">
                                  <p class="bold">Score Results:</p>
                                  <p class="flex-historical">{{(!assessmentItem.scoreResults || assessmentItem.scoreResults==='') ? '--' :
                                    assessmentItem.scoreResults}}</p>
                                </div>
                              </div>
                              <!--Assessment Item End-->
                            </div>
                          </div>
                          <!--Assessment Item Accordions End-->
                        </div>
  
                        <!-- End Assessments -->
                        <!-- Data admins -->
                        <div flex="95" class="flex-95 layout-align-start-none">
                          <div class="nested-record-item borderLightGreyOpen borderBottomLightGrey" flex layout=row
                            (click)="showDataAdminsNested(enrIndex)">
                            <div class="layout-row flex-historical layout-align-center-none">
                              <div style="width: 95%" class="">
                                <div  class="flex-container section1">
                                  <p class="nested-item-title flex-historical" flex>Data Administrators</p>
                                </div>
                              </div>
                              <div  class="expandColumn-white flex-container" flex>
                                <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawDataAdminsNested === enrIndex)"></i>
                                <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawDataAdminsNested === enrIndex"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dataAdminList flex-95 layout-align-start-none" *ngIf="rawDataAdminsNested === enrIndex" layout=row flex="95"
                          layout-align="start none">
                          <div flex *ngIf="rawEnrItemsIndex === enrIndex">
                            <div>
                              <div class="worklist-header-title-full-underline" flex>
                                <div class="worklist-header-titles left-padding-6 no-border ieNoPadding layout-row flex-95" 
                                  flex="95">
                                  <block2 class="flex-30">Name</block2>
                                  <block2 class="flex-30">Phone</block2>
                                  <block2 class="flex-historical">Email</block2>
                                </div>
                              </div>
                              <!--  | orderBy: 'fname' -->
                              <div class="section1 layout-row flex-95" *ngFor="let admin of enrItem.ddas">
                                <p class="flex-30">{{admin.fname}} {{admin.lname}}</p>
                                <p class="flex-30">{{admin.telephone}}</p>
                                <p class="flex-historical">{{admin.email}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- End Data admins -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Enrollments End-->
  
          <!--Course History No Enrollment-->
          <div>
            <div class="subAccordionBackground additionalTitles">
              <!--Course Main Accordion-->
              <div class="layout-row flex-historical layout-align-center-none borderLightGreyOpen" 
                [ngClass]="{borderBottomLightGrey: courseIndex === stateIndex}">
                <div style="width: 95%">
                  <div (click)="showCourseHistory(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">Course History Not Associated with an Enrollment</p>
                  </div>
                </div>
                <div (click)="showCourseHistory(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(courseIndex === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="courseIndex === stateIndex"></i>
                </div>
              </div>
  
              <div *ngIf="courseIndex === stateIndex">
                <div flex class="worklist-history-accordion">
                  <!--Course Items Header-->
                  <div class="worklist-header-title-full-underline flex-historical">
                    <div class="worklist-header-titles no-border ieNoPadding left-padding-6 layout-row flex-95">
                      <block2 class="flex-15-historical">Course Title</block2>
                      <block2 class="flex-15-historical">Academic Year</block2>
                      <block2 class="flex-historical">Subject Area</block2>
                      <block2 class="flex-10-historical" >Final Grade</block2>
                      <block2 class="flex-historical">Credits Granted</block2>
                      <block2 class="flex-historical">Grade-To-Date</block2>
                      <block2 class="flex-10-historical" >Clock Hours</block2>
                    </div>
                  </div>
  
                  <!--Course Item Accordions  | orderBy: '-beginAcademicYear'-->
                  <div *ngFor="let courseItem of state.coursesWoEnrollment; let courseWoIndex = index" 
                    class="newtable2Row table-row-margin">
                    <div class="layout-row">
                      <div style="width: 95%" class="">
                        <div (click)="showCourseItemHistory(courseWoIndex)" class="flex-container section1 greyBottomBorder">
                          <i class="flex-25 fa fa-minus" aria-hidden="true" *ngIf="!courseItem.courseTitle || courseItem.courseTitle===''"></i>
                          <p class="flex-15-historical" *ngIf="courseItem.courseTitle">{{courseItem.courseTitle}}</p>
  
                          <i class="flex-15-historical fa fa-minus" aria-hidden="true" *ngIf="!(courseItem.beginAcademicYear || courseItem.endAcademicYear) || (courseItem.beginAcademicYear==='' || courseItem.endAcademicYear==='')"></i>
                          <p class="flex-15-historical" *ngIf="courseItem.beginAcademicYear && courseItem.endAcademicYear">{{courseItem.beginAcademicYear}}-{{courseItem.endAcademicYear}}</p>
  
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.subjectArea || courseItem.subjectArea===''"></i>
                          <p class="flex-historical" *ngIf="courseItem.subjectArea">{{courseItem.subjectArea}}</p>
  
                          <i  class="flex-10-historical fa fa-minus" aria-hidden="true" *ngIf="!courseItem.finalGrade || courseItem.finalGrade===''"></i>
                          <p *ngIf="courseItem.finalGrade" class="flex-10-historical" >{{courseItem.finalGrade}}</p>
  
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.creditsGranted || courseItem.creditsGranted===''"></i>
                          <p class="flex-historical" *ngIf="courseItem.creditsGranted">{{courseItem.creditsGranted}}</p>
  
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!courseItem.gradeToDate || courseItem.gradeToDate===''"></i>
                          <p class="flex-historical" *ngIf="courseItem.gradeToDate">{{courseItem.gradeToDate}}</p>
  
                          <i  class="flex-10-historical fa fa-minus" aria-hidden="true" *ngIf="!courseItem.clockHours || courseItem.clockHours===''"></i>
                          <p class="flex-10-historical"  *ngIf="courseItem.clockHours">{{courseItem.clockHours}}</p>
                        </div>
                      </div>
                      <div (click)="showCourseItemHistory(courseWoIndex)" class="expandColumn-green flex-container greyBottomBorder"
                        flex>
                        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(courseItemIndex === courseWoIndex)"></i>
                        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="courseItemIndex === courseWoIndex"></i>
                      </div>
                    </div>
  
                    <!--Course Item-->
                    <div class="layout-row worklist-history-accordion newtable2Row table-row-margin interiorContainer font-13"
                      *ngIf="courseItemIndex === courseWoIndex">
                      <div class="column layout-column flex-25">
                        <p class="bold">Course Section:</p>
                        <p class="flex-historical">{{(!courseItem.courseSection || courseItem.courseSection==='') ? '--' :
                          courseItem.courseSection}}</p>
                      </div>
                      <div class="column layout-column flex-25">
                        <p class="bold">Course Type:</p>
                        <p class="flex-historical">{{(!courseItem.courseType || courseItem.courseType==='') ? '--' :
                          courseItem.courseType}}</p>
                      </div>
                      <div class="column layout-column flex-25">
                        <p class="bold">Term Type:</p>
                        <p class="flex-historical">{{(!courseItem.termType || courseItem.termType==='') ? '--' :
                          courseItem.termType}}</p>
                      </div>
                    </div>
                    <!--Course Item End-->
                  </div>
                  <!--Course Item Accordions End-->
                </div>
  
              </div>
            </div>
          </div>
          <!--Course History No Enrollment End-->
  
          <!--Assessments No Enrollment-->
          <div>
            <div class="subAccordionBackground additionalTitles">
  
              <!--Assessment Main Accordion-->
              <div class="layout-row flex-historical layout-align-center-none borderLightGreyOpen" 
                [ngClass]="{borderBottomLightGrey: assessmentIndex === stateIndex}">
                <div style="width: 95%" class="">
                  <div (click)="showAssessmentHistory(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">Assessments Not Associated with an Enrollment</p>
                  </div>
                </div>
                <div (click)="showAssessmentHistory(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(assessmentIndex === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="assessmentIndex === stateIndex"></i>
                </div>
              </div>
  
              <div *ngIf="assessmentIndex === stateIndex">
                <div flex class="worklist-history-accordion">
                  <!--Assessment Items Header-->
                  <div class="worklist-header-title-full-underline" flex>
                    <div class="worklist-header-titles no-border ieNoPadding left-padding-6 layout-row flex-95">
                      <block2 class="flex-historical">Assessment Title</block2>
                      <block2 class="flex-historical">Date</block2>
                      <block2 class="flex-historical">Content</block2>
                      <block2 class="flex-historical">Type</block2>
                      <block2 class="flex-historical">Interpretation</block2>
                    </div>
                  </div>
  
                  <!--Assessment Item Accordions  | orderBy: '-asmtAdminDate'-->
                  <div *ngFor="let assessmentItem of state.assessmentsWoEnrollment; let assessmentWoIndex = index" 
                  class="newtable2Row table-row-margin">
                    <div class="layout-row">
                      <div style="width: 95%" class="">
                        <div (click)="showAssessmentItemHistory(assessmentWoIndex)" class="flex-container section1 greyBottomBorder">
                          <p class="flex-historical" *ngIf="assessmentItem.asmtTitle">{{assessmentItem.asmtTitle}}</p>
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtTitle || assessmentItem.asmtTitle===''"></i>
  
                          <p class="flex-historical" *ngIf="assessmentItem.asmtAdminDate">{{assessmentItem.asmtAdminDate}}</p>
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtAdminDate || assessmentItem.asmtAdminDate===''"></i>
  
                          <p class="flex-historical" *ngIf="assessmentItem.asmtContent">{{assessmentItem.asmtContent}}</p>
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtContent || assessmentItem.asmtContent===''"></i>
  
                          <p class="flex-historical" *ngIf="assessmentItem.asmtType">{{assessmentItem.asmtType}}</p>
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtType || assessmentItem.asmtType===''"></i>
  
                          <p class="flex-historical" *ngIf="assessmentItem.asmtInterpretation">{{assessmentItem.asmtInterpretation}}</p>
                          <i class="fa fa-minus flex-historical" aria-hidden="true" *ngIf="!assessmentItem.asmtInterpretation || assessmentItem.asmtInterpretation===''"></i>
                        </div>
                      </div>
                      <div (click)="showAssessmentItemHistory(assessmentWoIndex)" class="expandColumn-green flex-container greyBottomBorder"
                        flex>
                        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(assessmentItemIndex === assessmentWoIndex)"></i>
                        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="assessmentItemIndex === assessmentWoIndex"></i>
                      </div>
                    </div>
  
                    <!--Assessment Item-->
                    <div class="layout-row layout-align-center-center worklist-history-accordion newtable2Row table-row-margin interiorContainer font-13"
                      *ngIf="assessmentItemIndex === assessmentWoIndex">
                      <span class="flex-10-historical"></span>
                      <div class="layout-column">
                        <p flex class="flex-historical bold">Reporting Method:</p>
                        <p class="flex-historical">{{(!assessmentItem.asmtRptMethod || assessmentItem.asmtRptMethod==='') ? '--' :
                          assessmentItem.asmtRptMethod}}</p>
                      </div>
  
                      <div class="layout-column">
                        <p flex class="flex-historical bold">Score Results:</p>
                        <p class="flex-historical">{{(!assessmentItem.scoreResults || assessmentItem.scoreResults==='') ? '--' :
                          assessmentItem.scoreResults}}</p>
                      </div>
                      <span class="flex-10-historical"></span>
                    </div>
                    <!--Assessment Item End-->
                  </div>
                  <!--Assessment Item Accordions End-->
                </div>
              </div>
            </div>
          </div>
          <!--Assessments No Enrollment End-->
  
          <!--ID History-->
          <div *ngIf="showHistorical">
            <div class="subAccordionBackground additionalTitles">
              <div class="layout-row flex-historical layout-align-center-none borderLightGreyOpen borderBottomLightGrey">
                <div style="width: 95%" class="">
                  <div (click)="showIdHistory(stateIndex)" class="flex-container section1">
                    <p class="flex-historical">ID History</p>
                  </div>
                </div>
                <div (click)="showIdHistory(stateIndex)" class="expandColumn-white flex-container" flex>
                  <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(IdHistory === stateIndex)"></i>
                  <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="IdHistory === stateIndex"></i>
                </div>
              </div>
  
              <!--Alt IDs-->
              <div *ngIf="IdHistory === stateIndex" style="padding-top: 20px;">
                <div *ngFor="let altId of state.ssidHistory[0].historyList; let last = last; let idIndex = index">
                  <div class="worklist-id-history-accordion">
                    <div class="nested-items-container" style="padding-bottom: 0px;">
                      <div flex class="layout-row layout-align-start-none">
                        <div class="nested-record-item borderLightGreyOpen flex-100"
                          (click)="showIDHistoryNested(idIndex)" [ngClass]="{borderBottomLightGrey: (last || rawIDNested === idIndex)}">
                          <div class="layout-row flex-historical layout-align-center-none">
                            <div class="flex-95">
                              <div class="flex-container section1">
                                <block2 class="center-title flex-historical">
                                  MSIX ID: {{altId.msixId}}
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="altId.worklistType">
                                  Event: {{altId.worklistType}}
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="!altId.worklistType || altId.worklistType===''">
                                  Event: --
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="altId.createdDate">
                                  Effective Date: {{altId.createdDate}}
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="!altId.createdDate || altId.createdDate===''">
                                  Effective Date: --
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="altId.worklistKey">
                                  ID: {{altId.worklistKey}}
                                </block2>
                                <block2 class="center-title flex-historical" *ngIf="!altId.worklistKey || altId.worklistKey===''">
                                  Worklist ID: --
                                </block2>
                              </div>
                            </div>
                            <div class="expandColumn-white flex-container" flex>
                              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(rawIDNested === idIndex)"></i>
                              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="rawIDNested === idIndex"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="rawIDNested === idIndex">
                    <div flex class="worklist-id-items">
                      <div class="worklist-header-title-full-underline" flex>
                        <div class="flex-95 worklist-header-titles no-border ieNoPadding left-padding-6 
                            layout-row" flex="95" style="margin-left: 3px"> 
                          <block2 class="flex-historical">Submitted</block2>
                          <block2 class="flex-historical">ID</block2>
                          <block2 class="flex-historical">State</block2>
                        </div>
                      </div>
                      <!--Submissions Items-->
                      <!-- altId is taken from state.ssidHistory[0].historyList  -->
                      <div *ngFor="let submission of altId.submissions; let subIndex = index" class="newtable2Row table-row-margin" 
                        [ngClass]="{activeOutline: IdItem === (subIndex.toString() + idIndex.toString())}">
                        <div class="layout-row">
                          <div style="width: 95%" class="">
                            <div (click)="showIdItem(subIndex.toString() + idIndex.toString())" class="flex-container section1 greyBottomBorder">
                              <p class="flex-historical">{{submission.submissionDate}}</p>
                              <p class="flex-historical">{{submission.subHistory.stateId}}</p>
                              <p class="flex-historical">{{submission.subHistory.stateName}}</p>
                            </div>
                          </div>
                          <div (click)="showIdItem(subIndex.toString() + idIndex.toString())" class="expandColumn-green flex-container"
                            flex>
                            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(IdItem === (subIndex.toString() + idIndex.toString()))"></i>
                            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="IdItem === (subIndex.toString() + idIndex.toString())"></i>
                          </div>
                        </div>
  
                        <!--Submission Item Details-->
                        <div flex class="layout-row worklist-history-accordion newtable2Row table-row-margin interiorContainer"
                          *ngIf="IdItem === (subIndex.toString() + idIndex.toString())">
                          <div class="font-13 layout-column flex-historical">
                            <div>
                              <p flex class="flex-historical bold">Student Name:
                              </p>
                              <p class="flex-historical">{{submission.firstName}} {{submission.lastName1}} {{submission.lastName2}}
                              </p>
                            </div>
                            <div class="demographicsColumn">
                              <p flex class="flex-historical bold">DOB:
                              </p>
                              <p class="flex-historical" *ngIf="submission.birthDate">{{submission.birthDate}}</p>
                              <p class="flex-historical" *ngIf="(submission.birthDate === '' || !submission.birthDate)">
                                --
                              </p>
                            </div>
                          </div>
                          <div class="font-13 layout-column flex-historical">
                            <div>
                              <p flex class="flex-historical bold">Born in:
                              </p>
                              <p class="flex-historical" *ngIf="(submission.birthCity || submission.birthCountry)">{{submission.birthCity}},
                                {{submission.birthCountry}}
                              </p>
                              <p class="flex-historical" *ngIf="(submission.birthCity === '' && submission.birthCountry=== '') || (!submission.birthCity && !submission.birthCountry)">
                                --
                              </p>
                            </div>
                            <div class="demographicsColumn">
                              <p flex class="flex-historical bold">Multi-birth:
                              </p>
                              <p class="flex-historical" *ngIf="submission.multipleBirthFlag">{{submission.multipleBirthFlag}}</p>
                              <p class="flex-historical" *ngIf="(submission.multipleBirthFlag === '' || !submission.multipleBirthFlag)">
                                --
                              </p>
                            </div>
                          </div>
                          <div class="font-13 layout-column flex-historical">
                            <div>
                              <p flex class="flex-historical bold">Sex:
                              </p>
                              <p class="flex-historical" *ngIf="(submission.gender.toUpperCase() === 'MALE' || submission.gender.toUpperCase() ==='FEMALE' || submission.gender.toUpperCase() ==='OTHER')">{{submission.gender}}</p>
                              <p class="flex-historical" *ngIf="(submission.gender.toUpperCase() !== 'MALE' && submission.gender.toUpperCase() !=='FEMALE' && submission.gender.toUpperCase() !=='OTHER')">
                                --
                              </p>
                            </div>
                            <div class="demographicsColumn">
                              <p flex class="flex-historical bold">Birth Date Verification:
                              </p>
                              <p class="flex-historical" *ngIf="submission.birthDateVerification">{{submission.birthDateVerification}}</p>
                              <p class="flex-historical" *ngIf="(submission.birthDateVerification === '' || !submission.birthDateVerification)">
                                --
                              </p>
                            </div>
                          </div>
                          <div class="font-13 layout-column flex-historical">
                            <div>
                              <p flex class="flex-historical bold">Parent 1:
                              </p>
                              <p class="flex-historical" *ngIf="(submission.femaleParentFirstName || submission.femaleParentLastName)">{{submission.femaleParentFirstName}},
                                {{submission.femaleParentLastName}}
                              </p>
                              <p class="flex-historical" *ngIf="(submission.femaleParentFirstName === '' && submission.femaleParentLastName=== '') || (!submission.femaleParentFirstName && !submission.femaleParentLastName)">
                                --
                              </p>
                            </div>
                            <div class="demographicsColumn">
                              <p flex class="flex-historical bold">Parent 2:
                              </p>
                              <p class="flex-historical" *ngIf="(submission.maleParentFirstName || submission.maleParentLastName)">{{submission.maleParentFirstName}},
                                {{submission.maleParentLastName}}
                              </p>
                              <p class="flex-historical" *ngIf="(submission.maleParentFirstName === '' && submission.maleParentLastName=== '') || (!submission.maleParentFirstName && !submission.maleParentLastName)">
                                --
                              </p>
                            </div>
                          </div>
                        </div>
                        <!--Submission Item Details End-->
                      </div>
                      <!--Submission Items End-->
                    </div>
                  </div>
                </div>
              </div>
              <!--Alt IDs End-->
            </div>
          </div>
          <!--ID History End-->
          <span class="flex-5"></span>
        </div>
        <!--State Accordions End-->
      </div>
      <!--Additional Accordions-->
  
    </div>
  
  </div>