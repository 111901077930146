import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function RequiredIfCheckedValidator(form: AbstractControl): ValidatorFn {
    return (ctrl: AbstractControl) : ValidationErrors | null => {
        if (!ctrl || !form) return null;
        const newListNameChecked = form.get('newListNameCheck')?.value;
        const newListName = form.get('newListName')?.value;
        if (newListNameChecked && !newListName.trim().length) {
            return {newListNameRequired: true}
        }
        return null;
    }
}