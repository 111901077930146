<div class="reportsFilter filterMoveFrom">
  <div aria-label="General Move From Filter" class="filter-dialog">
    <div style="flex-direction: column-reverse; display: flex;">
      <div class="layout-row md-dialog-actions">
        <a style="align-items: center; display: flex;" class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
          Reset Filters
        </a>
        <span class="flex"></span>
        <button type="submit" class="confirmButton md-button" (click)="onSubmit()">
          SUBMIT
        </button>
      </div>
      <div class="md-dialog-content">
        <div class="layout-row filter-section">
          <div class="flex layout-column">
            <div class="filterHeader">
              PERFORMANCE PERIOD:
            </div>
          </div>
          <div class="flex layout-column" style="margin-top: -10px">
            <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.noyes">
              <mat-radio-button value="no" (click)="filterLogicNo()">No</mat-radio-button>
              <mat-radio-button value="yes" (click)="filterLogicYes()">Yes</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="layout-row filter-section" *ngIf="filterParameters.noyes=='yes'">
          <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.periodType">
            <mat-radio-button value="Most Recently Closed Period" style="margin-right: 7px;">
              Last performance period <br />{{filterParameters.reportInfo.recentPeriod}}
            </mat-radio-button>
            <mat-radio-button class="currentPeriod" value="Current Period" style="margin-left: 0;">
              Current performance period <br />{{filterParameters.reportInfo.currentPeriod}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <hr *ngIf="filterParameters.noyes=='yes'" layout-padding class="thickHr">
        <div *ngIf="filterParameters.noyes=='yes'">
          <div class="layout-row filter-section">
            <div class="filterHeader"> PERFORMANCE CATEGORY:</div>
          </div>
          <div class="layout-row">
            <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.categoryType" class="filter-section">
              <mat-radio-button value="Category 1">
                Category 1 / Show all eligible
              </mat-radio-button>
              <mat-radio-button class="cat2" value="Category 2">
                Category 2 / Summer only
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <hr class="layout-padding thickHr">
        <div class="layout-row filter-section">
          <div class="layout-column flex">
            <div class="filterHeader"> REPORT MOVE BY:</div>
          </div>
          <div class="layout-column flex" style="margin-top: -10px">
            <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.rptMove">
              <mat-radio-button value="Enrollments">
                Enrollments
              </mat-radio-button>
              <mat-radio-button value="Qualifying Moves">
                Qualifying moves
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="layout-column" *ngIf="filterParameters.rptMove=='Qualifying Moves'">
          <mat-checkbox md-no-ink [checked]="!filterParameters.boolInterMoves" (click)="selectMoves('inter')">
            Include International moves
          </mat-checkbox>
          <mat-checkbox md-no-ink [checked]="!filterParameters.boolIntraMoves" (click)="selectMoves('intra')">
            Include Intrastate moves
          </mat-checkbox>
        </div>
        <div class="layout-row dates" *ngIf="filterParameters.rptMove=='Qualifying Moves' && filterParameters.noyes=='no'">
          <div class="date-range student-info-dates">
            <mat-label class="pFilterHeader">QUALIFYING ARRIVAL DATE RANGE: </mat-label>
            <div class="date-filter-input">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="qadDateFromDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.qadDate.startMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #qadDateFromDD
                  [focusElement]="qadDateFromYYYY" maxlength="2" matInput id="qadDateFromDD"
                  [(ngModel)]="filterParameters.qadDate.startDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput #qadDateFromYYYY id="qadDateFromYYYY"
                  [(ngModel)]="filterParameters.qadDate.startYYYY">
              </mat-form-field>
            </div>
            <div class="date-filter-input">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="qadDateToDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.qadDate.endMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #qadDateToDD [focusElement]="qadDateToYYYY"
                  maxlength="2" matInput id="qadDateToDD" [(ngModel)]="filterParameters.qadDate.endDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="qadDateToYYYY" #qadDateToYYYY
                  [(ngModel)]="filterParameters.qadDate.endYYYY">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="md-toolbar-tools toolbar">
        <h2 class="filter-title">General Move From Filter</h2>
        <span flex></span>
        <button class="md-icon-button md-button" (click)="onClose()" aria-label="close dialog"
          style="margin-left: auto; margin-right: 0;">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>