import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WorkHistoryService {

    workHistoryResults: any = null;
    constructor() {

    }

    setWorkHistoryResults(results) {
        this.workHistoryResults = results;
    }

    getWorkHistoryResults() {
        return this.workHistoryResults;
    }
}
