import { Component, Input, Output, ViewChild, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomMatInputComponent),
  multi: true
};

@Component({
  selector: 'custom-mat-input',
  templateUrl: './customMatInput.component.html',
  styleUrls: ['./customMatInput.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomMatInputComponent
    }
  ]
})

export class CustomMatInputComponent implements ControlValueAccessor {
  textValue: string = "";
  nonNumError: boolean = false;
  
  @Input() customlabel: string = "Missing customlabel";
  @Input() controlName?: string = "firstName";
  @Input() formControlName?: string;
  @Input() errorlength: number;
  @Input() textType?: string = 'text';
  @Input() mask?: boolean = false;
  @Input() isdisabled?: boolean = false;
  @Input() errormessage?: string = '';
  @Input() maxcharacter?: number;
  @Input() isrequired?: boolean = false;                    // This input is only for controlling required asterisk (*) in the floating label
  @Input() isNumeric?: boolean = false;
  @Input() errorname?: string;
  @Input() showerror?: boolean = false;
  @Input() userform?: boolean = true;

  @Output() model: string;
  @Output() errorcallback? = new EventEmitter<object>();
  @Output() ngsubmit? = new EventEmitter<any>();

  @ViewChild("inputField") inputref: MatInput

  onChange = (textValue) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;
  userDetailsForm: any;

  constructor() {
    /* console.log('customMatInput constructor called');
    console.log(this.customlabel);
    console.log(this.errorlength); */

  } 
  
  ngOnInit() {
    /* console.log('customMatInput init called');
    console.log(this.errorlength);
    console.log('custommatinput isdiabled: ' + this.isdisabled);
    console.log('custommatinput showerror: ' + this.showerror); */

    this.userDetailsForm = new FormGroup({
      firstName: new FormControl({value: '', disabled: this.isdisabled}, [Validators.required]),
    });
  }

  onValueChanged($event) {
    this.markAsTouched();
    console.log('Custom mat input - onValueChanged() called');

    if (this.isNumeric) {
      if (isNaN($event.data)) {
        this.nonNumError = true;
      } else {
        this.nonNumError = false;
      }
    } 

    this.onChange(this.textValue);
  }

  _keyDown(event: any) {
    //Ignore if not masking or if backspacing/tabbing
    if(!this.mask || event.key === "Backspace" || event.key === "Tab" || event.key === "Enter") {return;}
    const pattern = /[0-9\+\-\ ]/;

    if (!pattern.test(event.key)) {
      // invalid character, prevent input
      event.preventDefault();
    }
}

  writeValue(textValue: string): void {
    this.textValue = textValue;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  checkError() {
    if(!this.userform) {
      return false;
    }
    return this.userDetailsForm.controls[this.controlName].hasError('required');

    // this.errorcallback.emit({controlName: this.formControlName, errorName: this.errorname});
  }

  onSubmit() {
    this.ngsubmit.emit();
  }

  /* setFocus(event) {
    event.srcElement.parentElement.parentElement.children[1].focus();
  } */
}
