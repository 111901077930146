<div class="reportsFilter learnMoreModal">
  <div aria-label="Data Completeness DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-row">
        <div class="layout-column" flex>
          <p>Use this report to verify data transmission success and completion rates for Minimal Data Elements. Completion
            rates for key fields that determine eligibility, child count, enrollment, course placement and credit accrual
            should be compared with State systems. Any disparities in completion rates should be researched and corrected
            in the State system for successful data transmission.
          </p>
          <div class="add-margin-top">
            <p>Click on the <b>MSIX ID</b> to view a child’s Consolidated Student Record. </p>
          </div>
          <div class="add-margin-top">
            <p>Use the <b>Export</b> function to download a copy of the report in CSV format.
            </p>
          </div>
          <div class="add-margin-top">
            <p>Use the breadcrumbs at the top of the page to return to the main Data Completeness Report.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>