<div class="reportsFilter learnMoreModal">
  <div aria-label="Data Validity Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-column" flex>
        <p>Use this report to identify the invalid MDEs so you can correct the data in your State system and resubmit
          the files to MSIX.
        </p>
        <div class="add-margin-top">
          <p>Use the <b>Filter</b> function to select a performance period and change the drill down level to National
            or State.</p>
        </div>
        <div class="add-margin-top">
          <p>Click on a row to view the list of migratory children that contain invalid data for a specific MDE (State
            level view only).
          </p>
        </div>
        <div class="add-margin-top">
          <p>Use the <b>Export</b> function to download a copy of the report in CSV or PDF format.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>