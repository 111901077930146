<div layout="row" class="reportsFilter learnMoreModal">
  <div aria-label="File Submission DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-column" flex>
        <p>Use this report to determine trends in data submission success, errors, and rejection rates.</p>
        <div class="add-margin-top">
          <p>Please review the State Systems Outbound Response Error Interface (SSOREI) file located on your State’s FTP server with your technical team to identify records that were rejected by MSIX. When you have resolved these errors, resubmit the files to MSIX.</p>
        </div>
        <div class="add-margin-top">
          <p>Please note that the additional File Submission MDE Details are only available for files submitted after 9/19/2021.</p>
        </div>
        <div class="add-margin-top">
          <p>Use the breadcrumbs at the top of the page to return to the main File Submission Report.</p>
        </div>
      </div>
    </div>
  </div>
</div>