import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ITableItemDataSource } from '../../types/interfaces';

@Injectable({providedIn: 'root'})


export class AccountMetricsService {

    /** main table observable */
    accountMetricsData: {} = {};
}