<div class="help-page background-cover" [style.padding]="(responsiveUI.isDesktop) ? '40px' : '0px'">
  
  <div class="background-creative">
    <!-- TODO - Need to figure out the $mdMedia stuff. -->
    <!-- <div [ngClass]="{'side-padding' : ($ctrl.$mdMedia('gt-md')), 'side-padding-mobile' : ($ctrl.$mdMedia('xs') || $ctrl.$mdMedia('sm') || $ctrl.$mdMedia('md'))}"> -->
    <div>
      <h1 class="padding-top-10 padding-left-15">Contact Us</h1>
      <div layout-gt-md="row" layout="column" class="padding-left-15" flex>
        <div layout="column" flex layout-align="start start">
          <div flex>
            <p class="padding-top-15">
              For password reset and account updates
            </p>
            <a tabindex="0" [routerLink]="['/pwReset']">State Contact Search</a>
            <p class="padding-top-15">
              For all other needs
            </p>
            <p class="contactMethodText padding-top-15">
              By email
            </p>
            <a href="mailto:MSIXsupport@deloitte.com">MSIXsupport@deloitte.com</a>
            <p class="contactMethodText padding-top-15">
              By phone (9:30AM to 6:30PM ET)
            </p>
            <a href="tel:+1-866-878-9525">
              1-866-878-9525
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
