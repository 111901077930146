<div class="privacy background-awesome"> <!--layout="row" layout-align="center start"-->
    <!--  -->
    <div class="privacy upgrade" style="letter-spacing: initial;" 
      [ngClass]="{'prvcyBxDsktp': responsiveUI.isDesktop, 'prvcyBxTbltPrtrt': (responsiveUI.isTablet && responsiveUI.isPortrait), 'prvcyBxTbltLndscp': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'prvcyBxMblPrtrt': (responsiveUI.isMobile && responsiveUI.isPortrait), 'prvcyBxMblLndscp': (responsiveUI.isMobile && !responsiveUI.isPortrait)}" >
      <h1 class="nudge-left" style="padding-top: 8px;">Welcome to MSIX</h1>
      <div tabindex="0" class="privacyNotice" style="margin-top: 8px">
        <p class="noPadding customLineHeightForPrivacy">You must <span class="make-bold">READ</span> and <span class="make-bold">ACCEPT</span> the Warning and Privacy Act Information below to proceed to the MSIX login page.</p>
        <h3>WARNING</h3>
        <p class="customLineHeightForPrivacy">
          You are accessing a U.S. Federal Government computer system intended to be solely accessed by individual users
          expressly authorized to access the system by the U.S. Department of Education. Usage may be monitored,
          recorded, and/or subject to audit. For security purposes and in order to ensure that the system remains
          available to all expressly authorized users, the U.S. Department of Education monitors the system to identify
          unauthorized users. Anyone using this system expressly consents to such monitoring and recording. Unauthorized
          use of this information system is prohibited and subject to criminal and civil penalties. Except as expressly
          authorized by the U.S. Department of Education, unauthorized attempts to access, obtain, upload, modify,
          change, and/or delete information on this system are strictly prohibited and are subject to criminal
          prosecution under 18 U.S.C § 1030, and other applicable statutes, which may result in fines and imprisonment.
          For purposes of this system, unauthorized access includes, but is not limited to: </p>
          <p class="customLineHeightForPrivacy">
            Any access by an employee or agent of a commercial entity, or other third party, who is not the individual
            user, for purposes of commercial advantage or private financial gain (regardless of whether the commercial
            entity or third party is providing a service to an authorized user of the system); and
          </p>
          <p class="customLineHeightForPrivacy">        Any access in furtherance of any criminal or tortious act in violation of the Constitution or laws of the
            United States or any State.
            If system monitoring reveals information indicating possible criminal activity, such evidence may be provided
            to law enforcement personnel.</p>
        <!-- <h3>BY USING THIS INFORMATION SYSTEM, YOU UNDERSTAND AND CONSENT TO THE FOLLOWING:</h3> -->
        <!-- <ul>
          <li><p>You have no reasonable expectation of privacy regarding any communication or data transiting or stored on this information system.</p></li>
          <li><p>At any time, the government may monitor, intercept, search and seize any communication or data transiting or stored on this information system.</p></li>
          <li><p>Any communication or data transiting or stored on this information system may be disclosed or used for any lawful purpose.</p></li>
          <li><p>Using a security plugin or similar technology, the U.S. Government may check all equipment, including personal equipment, connecting to the U.S. Government networks and the U.S. Government may collect necessary and appropriate data to determine suitability of such equipment for access to U.S. Government networks.</p></li>
        </ul>   -->
  
        <h3>PRIVACY ACT INFORMATION</h3>
        <p class="customLineHeightForPrivacy">
          You must read the statement below, and click “Accept" to access the Department of Education's MSIX website.
          Some users may need to scroll to the bottom of the screen to see the “Accept" button. This is a United States
          Department of Education computer system, which may be accessed and used only for official Government business
          by authorized personnel. Unauthorized access or use of this computer system may subject violators to criminal,
          civil, and/or administrative action. This system contains personal information protected under the provisions
          of the Privacy Act of 1974, 5 U.S.C. § 552a – as amended. Violations of the provisions of the provisions of
          the Act may subject the offender to criminal penalties.
        </p>
      </div> <!--End Privacy Notice-->
      <div layout-gt-md="row" class="privacyButtons center">
        <button class="md-button" (click)="acceptWarn()" routerLinkActive="active" ariaCurrentWhenActive="page">Accept</button>
      </div> 
    </div>
  </div> 

  <!-- 
        <h1 class="nudge-left">Welcome to MSIX</h1> -->
        <!--  -->