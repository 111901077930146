<div class="accountManagement">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Account Management</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Account Management</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Account Management</p>
    </span>
  </div>

  <div class="header layout-row"*ngIf="!showDrill">
    <h1 flex="90" class="header-padding">Account Management</h1>
  </div>

  <div class="header layout-row" *ngIf="showDrill">
    <h1  class="header-padding" style="width:90%">Account Management</h1>
    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.acctMngt_sua || (accountManagementFilterParams.rptGrping == 'State' && accountManagementFilterParams.selectedState)) && (responsiveUI.isDesktop || responsiveUI.isTablet)" 
      [exportlink]="exportCall" 
      [exportfilter]="accountManagementFilterParams.selectedState"
      [showcsv]="true" 
      [showpdf]="false">
    </export-report>
  </div>

  <hr layout-padding class="thickHr">

  <div layout-align="center center" *ngIf="!showDrill" class="bodyPadding">
    <div class="layout-column" flex="90">
      <div class="report-summary layout-row">
        <p *ngIf="authRoleClasses.acctMngt_sua">
          The Account Management Dashboard provides user administrators with insight into MSIX account metrics and user activity levels in your State.
          <a *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
        <p *ngIf="authRoleClasses.dataDashboards">
          The Account Management Dashboard provides user administrators with insight into MSIX account metrics and user activity levels in all States.
          <a *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
      </div>

      <filter-button *ngIf="!(authRoleClasses.acctMngt_sua || responsiveUI.isMobile)" (activateFilter)="filterActivate()" class="chart-row"></filter-button>
      <div *ngIf="dataHasLoaded" [ngClass]="{'chart-row' : authRoleClasses.acctMngt_sua}">
        <div id="chart-section" class="chart-section vertical-align">
          <div class="layout-row" style="margin-bottom: 5px">
            <h2 flex=90>Account Management</h2>
            <export-report class="right-align" tabindex="0" *ngIf="(authRoleClasses.acctMngt_sua || (accountManagementFilterParams.rptGrping == 'State' && accountManagementFilterParams.selectedState)) && (responsiveUI.isDesktop || responsiveUI.isTablet)"
              [exportlink]="exportCall" 
              [exportfilter]="accountManagementFilterParams.selectedState"
              [showcsv]="true" 
              [showpdf]="false">
            </export-report>
          </div>
          <div class="layout-row">
            <div class="drill-down-rs layout-row flex-90">
              <span class="showing layout-row">Showing:</span>
              <span class="perf-period layout-row">
                <p class="perf-period-text">
                  Last 12 Months ({{reportInfoService.getLast12Months()}})
                </p>
              </span>
            </div>
            <a tabindex="0" class="flex-10 right-align"
              *ngIf="(authRoleClasses.acctMngt_sua || (accountManagementFilterParams.rptGrping == 'State' && accountManagementFilterParams.selectedState)) && (responsiveUI.isDesktop || responsiveUI.isTablet)"
              flex=auto (keypress)="showDrilldown()" (click)="showDrilldown()">View Details
            </a>
          </div>
          <div *ngIf="accountManagementDataHasLoaded && !hideAccountManagement">
            <msix-line-chart 
              id="accountManagement"
              [id]="id" 
              [chart]="chart" 
              [data]="accountManagementData">
            </msix-line-chart>
          </div>
          <div class="no-data-display" *ngIf="hideAccountManagement">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
          <p> Last Updated {{lastUpdated}}</p>
          <br />
        </div>
      </div>

    </div>
  </div>

  <!--Account Management Drilldown Page-->
  <div class="layout-align-center-center bodyPadding" *ngIf="showDrill">
    <div class="layout-column" flex="90">
      <div class="report-summary layout-row">
        <p>
          The tables in each tab below list the users that are included in the corresponding dataset displayed in the chart.
          <a tabindex="0" *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToDrillDownLearnMore()" (click)="goToDrillDownLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary drill-down-rs layout-row">
        <span class="showing layout-row">Showing:</span>
        <span class="perf-period layout-row">
          <p class="perf-period-text">
            Last 12 Months ({{reportInfoService.getLast12Months()}})
          </p>
        </span>
      </div>

      <div class="customTabs flex-inline" layout="flex">
        <p class="customTab flex-container flex-25" (keypress)="changeTab('totalLogins')" (click)="changeTab('totalLogins')" [ngClass]="{'tabActive': (activeTab === 'totalLogins')}">
          TOTAL LOGINS
        </p>
        <p class="customTab flex-container flex-25" (keypress)="changeTab('activeAccounts')" (click)="changeTab('activeAccounts')" [ngClass]="{'tabActive': (activeTab === 'activeAccounts')}">
          ACCOUNTS WITH AN ACTIVE STATUS
        </p>
        <p class="customTab flex-container flex-25" (keypress)="changeTab('usersLoggingIn')" (click)="changeTab('usersLoggingIn')" [ngClass]="{'tabActive': (activeTab === 'usersLoggingIn')}">
          USERS LOGGING IN
        </p>
        <p class="customTab flex-container flex-25" (keypress)="changeTab('newAccounts')" (click)="changeTab('newAccounts')" [ngClass]="{'tabActive': (activeTab === 'newAccounts')}">
          NEW ACCOUNTS
        </p>
      </div>

      <no-results *ngIf="blankRes && tabChangeDone"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>
</div>