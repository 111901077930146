<!--START Validate Record Split Desktop-->

<div *ngIf="responsiveUI.isDesktop && validateSplitItems.length > 0" class="worklist-page layout-margin-worklist-module layout-padding-worklist-module">

  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Validate Record Split</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders">
      <p class="padding-6px worklist-description-text">
        Expand the row to see the records identified as potentially separate migratory children. Validate the split to
        assign a new unique MSIX ID to the newer student record. Reject the split to release the records unchanged
        under the existing MSIX IDs.
      </p>
    </div>

    <div class="worklist-header-titles layout-flex-row flex">
      <div class="flex-container flex-95">
        <div class="block2 flex-15">
          <a (click)="sortColumns('column1')">
            Student
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-15">
          <a (click)="sortColumns('column2')">
            Worklist ID
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-10">
          <a (click)="sortColumns('column3')">
            State
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-10">
          <a (click)="sortColumns('column4')">
            Sex
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-15">
          <a (click)="sortColumns('column5')">
            DOB
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-15">
          <a (click)="sortColumns('column6')">
            MSIX ID
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-15">
          <a (click)="sortColumns('column7')">
            State ID
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
        <div class="block2 flex-15">
          <a (click)="sortColumns('column8')">
            Created
            <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && !sortAscending"></i>
            <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column8'===currentSortColumn && sortAscending"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="layout-flex-row table2Row" *ngFor="let student of validateSplitItems" style="flex: 100%">

      <div class="flex-95 background-white">

        <!--orphan record-->
        <div class="layout-row">
          <div style="flex: 90%"  (click)="showValidateSplitDetailsFn(student.orphan.demographics.msixId, student.worklistId); updateReadFlagSplit(student)" class="section1 layout-flex-row">
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.orphan.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.orphan.demographics.lastName2 !=''"> {{student.orphan.demographics.lastName2}}</span>,
                {{student.orphan.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.orphan.demographics.midName != ''"> {{student.orphan.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.worklistId}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.orphan.demographics.submittingStateAbbv}}</p>
            </span>
            <span class="flex-10 column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.orphan.demographics.gender}}</p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.orphan.demographics.birthDate}}</p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column6'}" *ngIf="student.orphan.demographics.msixId == ''">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">N/A</p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column6'}" *ngIf="student.orphan.demographics.msixId != ''">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.orphan.demographics.msixId}}</p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p flex [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">{{student.orphan.demographics.studentStateID}}</p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column8'}">
              <p class="flex layout-flex-column" [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                <span class="days-in-list">{{convertDateCreated(student.orphan.dateCreated)}}</span>
                <span class="days-in-list">{{daysOnList(student.orphan.dateCreated)}} days in list</span>
              </p>
            </span>
          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student.orphan)" class="top-bottom-margin-8 flexCenter">
                <span class="no-underline">Full Record</span>
              </a>
            </p>
          </div>
        </div>

        <div class="flex section2 worklist-accordion-section2 background-white" *ngIf="validateSplitIndex === student.orphan.demographics.msixId">
          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 1:</b></p>
              <p>{{student.orphan.demographics.maleParentFirstName}}
                {{student.orphan.demographics.maleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Email Address:</b></p>
              <p>{{student.orphan.demographics.parent1EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Phone Number:</b></p>
              <p>{{student.orphan.demographics.parent1PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 2:</b></p>
              <p>
                {{student.orphan.demographics.femaleParentFirstName}}
                {{student.orphan.demographics.femaleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Email Address:</b></p>
              <p>{{student.orphan.demographics.parent2EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Phone Number:</b></p>
              <p>{{student.orphan.demographics.parent2PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 top-bottom-margin-8 flex-25 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>State ID:</b> {{student.orphan.demographics.studentStateID}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Multi-birth:</b></p>
              <p>{{student.orphan.demographics.multipleBirthFlag}}</p>
            </div>
          </div>
        </div>

        <!--parent record-->

        <div class="background-gray">

          <div class="background-gray border-gray-thin" *ngIf="validateSplitIndex === student.orphan.demographics.msixId">

            <div style="flex: 90%" class="layout-flex-row flex-container">
              <div style="flex: 100%" class="layout-flex-row flex-container section1" (click)="showValidateSplitDetailsFn(student.orphan.demographics.msixId, student.worklistId); updateReadFlagSplit(student)">
                <span class="flex-15 column">
                  <a class="studentFullName" (click)="viewDetails(student.master)" flex>
                    {{student.master.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.master.demographics.lastName2 !=''"> {{student.master.demographics.lastName2}}</span>,
                    {{student.master.demographics.firstName}}
                    <span class="removeUnderline" *ngIf="student.master.demographics.midName != ''"> {{student.master.demographics.midName}}</span>
                  </a>
                </span>
                <span class="flex column">
                  <p flex>{{student.worklistId}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.master.demographics.submittingStateAbbv}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.master.demographics.gender}}</p>
                </span>
                <span class="flex column">
                  <p flex>{{student.master.demographics.birthDate}}</p>
                </span>
                <span class="flex-15 column">
                  <p flex>{{student.master.demographics.msixId}}</p>
                </span>
                <span class="flex-15 column">
                  <p flex>{{student.master.demographics.studentStateID}}</p>
                </span>
                <span class="flex column">
                  <p class="flex layout-flex-column">
                    <span class="days-in-list">{{convertDateCreated(student.master.dateCreated)}}</span>
                    <span class="days-in-list">{{daysOnList(student.master.dateCreated)}} days in list</span>
                  </p>
                </span>
              </div>
            </div>

            <div class="flex section2 worklist-validate-accordion background-gray">
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1:</b></p>
                  <p>{{student.master.demographics.maleParentFirstName}}
                    {{student.master.demographics.maleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Email Address:</b></p>
                  <p>{{student.master.demographics.parent1EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 1 Phone Number:</b></p>
                  <p>{{student.master.demographics.parent1PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2:</b></p>
                  <p>
                    {{student.master.demographics.femaleParentFirstName}}
                    {{student.master.demographics.femaleParentLastName}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Email Address:</b></p>
                  <p>{{student.master.demographics.parent2EmailAddress}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Parent 2 Phone Number:</b></p>
                  <p>{{student.master.demographics.parent2PhoneNumber}}
                  </p>
                </div>
              </div>
              <div class="col1 top-bottom-margin-8 flex-25 layout-column">
                <div class="top-bottom-margin-8">
                  <p><b>State ID:</b> {{student.master.demographics.studentStateID}}
                  </p>
                </div>
                <div class="top-bottom-margin-8">
                  <p><b>Multi-birth:</b></p>
                  <p>{{student.master.demographics.multipleBirthFlag}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!--split history-->

        <div class="layout-flex-row top-bottom-margin-15 worklist-box-border background-white" *ngIf="validateSplitIndex === student.orphan.demographics.msixId">

          <div class="flex-95">
            <div class="header-pad">
              <a>
                <span>Split History</span>
              </a>
            </div>

            <div class="worklist-history-accordion" *ngIf="splitHistIndex === student.orphan.demographics.msixId">
              <div class="worklist-header-titles flex-container layout-flex-row" style="flex: 100%">
                <div class="flex-container" style="flex: 100%">
                  <div class="block2 flex">Workflow</div>
                  <div class="block2 flex">State</div>
                  <div class="block2 flex">User</div>
                  <div class="block2 flex">Status</div>
                  <div class="block2 flex">Date</div>
                </div>
              </div>
              <div *ngFor="let wLitem of worklistSplitHistory" class="worklist-header-titles flex-container layout-flex-row" style="flex: 100%">
                <div class="flex-container flex-95">
                  <p class="flex">{{wLitem.eventType}}</p>
                  <p class="flex">{{wLitem.userState}}</p>
                  <p class="flex">{{wLitem.actingUser}}</p>
                  <p class="flex">{{wLitem.status}}</p>
                  <p class="flex">{{wLitem.eventDate}}</p>
                </div>
              </div>
            </div>

          </div>

          <div style="flex: 5%" (click)="showSplitHistFn(student.orphan.demographics.msixId)" class="flex-5 expandColumn-grey3 flex-container">
            <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(splitHistIndex === student.orphan.demographics.msixId)"></i>
            <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="splitHistIndex === student.orphan.demographics.msixId"></i>
          </div>

        </div>

        <!--comments-->

        <div class="layout-flex-row top-margin-15" *ngIf="validateSplitIndex === student.orphan.demographics.msixId" flex>
          <div class="layout-flex-column" style="flex: 100%">
            <div>
              <div class="custom-input-container">
                <mat-form-field class="md-block md-input-container-margins background-white flex"
                style="border: 2px solid gray; width: 100%">
                  <mat-label class="custom-label">Comments</mat-label>
                  <textarea matInput class="custom-input comment-text" [(ngModel)]="validateOrRejectSplitObject.comments" maxlength="250"
                    md-maxlength="250" rows="2" md-select-on-focus></textarea>
                </mat-form-field>
              </div>
              <p class="small worklist-commentbox-instructions flex">
                To protect the student's privacy, do not include any sensitive information such as Social Security
                Numbers or other Personally
                Identifiable Information in Comments fields. Comments entered will be shared unedited and in their
                entirety
                with other MSIX Users.
              </p>
            </div>
          </div>
        </div>

        <div class="layout-flex-row right-align" *ngIf="validateSplitIndex === student.orphan.demographics.msixId" style="flex: 100%">
          <div class="layout-flex-column right-align">
            <a *ngIf="!showResolve" (click)="goToReassign(student)" class="top-bottom-margin-8 flexCenter"
              style="margin: auto;">
              <span>Reassign</span>
            </a>
          </div>
          <div class="layout-flex-column button-margin right-align">
            <button flex (click)="validateOrRejectSplitClick(student, 'reject')" class="worklist-reject-button md-button">
              Reject Split
            </button>
          </div>
          <div class="layout-flex-column button-margin right-align">
            <button flex (click)="validateOrRejectSplitClick(student, 'validate')" class="md-button">
              Validate Split
            </button>
          </div>
        </div>

      </div>

      <div (click)="showValidateSplitDetailsFn(student.orphan.demographics.msixId, student.worklistId); updateReadFlagSplit(student)"
        class="flex-5 expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(validateSplitIndex === student.orphan.demographics.msixId)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="validateSplitIndex === student.orphan.demographics.msixId"></i>
      </div>

    </div>

  </div>
</div>

<!--END Validate Record Split Desktop-->


<!--MOBILE AND TABLET START-->

<div class="flex worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && validateSplitItems.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet validate split-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow no-padding">
              <p class="worklist-section-header">Validate Split Records</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To validate a split, access your worklist on desktop.</p>
        </div>
        <div hide-gt-md>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of validateSplitItems">
            <div class="layout-flex-row" (click)="viewDetails(student.master)">
              <div class="flex layout-flex-column">
                <a class="uppercase">
                  {{student.master.demographics.lastName1}}<span *ngIf="student.master.demographics.lastName2 !=''" class="removeUnderline"> {{student.master.demographics.lastName2}}</span>,
                  {{student.master.demographics.firstName}}
                  <span class="removeUnderline" *ngIf="student.master.demographics.midName != ''"> {{student.master.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.worklistId}} | DOB:
                    {{student.master.demographics.birthDate}} |&nbsp;{{student.master.demographics.gender}} |
                    {{student.master.demographics.submittingStateAbbv}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MOBILE AND TABLET END-->