<div class="select">
    <span  (click)="selectAll()" >Select <span class="select-state">All</span></span> | <span class="select-state" (click)="selectNone()" >None</span>
</div>

<form [formGroup]="form">
        <div class="states">
            <div class="flex">
                <div class="col">
                    <ng-container *ngFor="let type of (assessmentTypes | slice:0:4)" >
                        <mat-checkbox [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
                <div class="col">
                    <ng-container *ngFor="let type of (assessmentTypes | slice:4:8)" >
                        <mat-checkbox  [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
                <div class="col">
                    <ng-container *ngFor="let type of (assessmentTypes | slice:8)" >
                        <mat-checkbox  [formControlName]="type.value" (blur)="onTouched()">
                            {{type.label}}
                        </mat-checkbox>
                    </ng-container>
                </div>
            </div>
        </div>
</form>