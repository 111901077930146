import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants }  from './../../config/index.constants'
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
  selector: 'request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss']
})
export class RequestAccountComponent {  
  trainingCorner_url = AppConstants.trainingCorner;

  constructor(private _router: Router,
    public responsiveUI: ResponsiveUIService) {

  }

  goToAdminSearch() {
    this._router.navigate(['pwReset'], {
      queryParams: {
        header: 'State Contact Search'
      }
    });
  }
}
