<div class="recordsExchange">
    <div class="breadcrumb layout-row" *ngIf="!showDrill">
      <span><a tabindex="0" routerLink="/reports">Reports</a></span>
      <span>></span>
      <span>
        <p style="vertical-align: middle !important;">Records Exchange</p>
      </span>
    </div>

    <div class="breadcrumb layout-row" *ngIf="showDrill">
      <span><a tabindex="0" routerLink="/reports">Reports</a></span>
      <span>></span>
      <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Records Exchange</a></span>
      <span>></span>
      <span *ngIf="chart=='topRecordsExchange'">
        <p style="vertical-align: middle !important;">Top 10 Records Exchange States</p>
      </span>
      <span *ngIf="chart=='dataReqWorklist'">
        <p style="vertical-align: middle !important;">Data Request Worklist Aging</p>
      </span>
    </div>

    <div class="header layout-row" *ngIf="!showDrill">
      <h1 flex="90" class="header-padding">Records Exchange</h1>
    </div>

    <div class="header layout-row" *ngIf="showDrill && chart=='topRecordsExchange'">
      <h1 style="width:90%" class="header-padding">Top 10 Records Exchange States</h1>
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState && !responsiveUI.isMobile"
        [exportlink]="topRecordsExchangeExportCall" 
        [exportfilter]="recordsExchangeExportObject"
        [showcsv]="false" 
        [showpdf]="false"
        [showzip]="true">
      </export-report>
    </div>

    <div class="header layout-row" *ngIf="showDrill && chart=='dataReqWorklist'">
      <h1 style="width:90%" class="header-padding">Data Request Worklist Aging</h1>
      <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState  && !responsiveUI.isMobile"
        [exportlink]="worklistAgingExportCall" 
        [exportfilter]="recordsExchangeExportObject"
        [showcsv]="false" 
        [showpdf]="false"
        [showzip]="true">
      </export-report>
    </div>

    <hr layout-padding class="thickHr">

    <div layout-align="center center" *ngIf="!showDrill" class="bodyPadding">
      <div class="layout-column" flex="90">
        <div class="report-summary layout-row">
          <p *ngIf="authRoleClasses.dataAdmins">
            The Records Exchange Dashboard helps you understand how your State coordinates with other States
            to exchange information about migratory children.
            <a tabindex="0" *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
          </p>
          <p *ngIf="authRoleClasses.dataDashboards">
            The Records Exchange Dashboard helps you understand how States coordinate with each other to
            exchange information about migratory children.
            <a tabindex="0" *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
          </p>
        </div>

        <!--Desktop view-->
        <filter-button *ngIf="!responsiveUI.isMobile" (activateFilter)="filterActivate()" class="chart-row" flex=auto></filter-button>
        <div *ngIf="dataHasLoaded" [ngclass]="{'chart-row' : (authRoleClasses.dataAdmins || responsiveUI.isMobile || responsiveUI.isTablet)}">
          <div id="chart-section" class="chart-section vertical-align">
            <div class="layout-row" style="margin-bottom: 5px">
              <h2 style="width:90%">Top 10 Records Exchange States</h2>
              <div class="right-align">
                <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState && !responsiveUI.isMobile"
                  [exportlink]="topRecordsExchangeExportCall" 
                  [exportfilter]="recordsExchangeExportObject"
                  [showcsv]="false" 
                  [showpdf]="false"
                  [showzip]="true">
                </export-report>
              </div>
            </div>
            <div class="layout-row">
              <div class="drill-down-rs layout-row" flex=90>
                <span class="showing layout-row">Showing:</span>
                <span class="perf-period layout-row">
                  <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'PREVIOUS'">
                    Last Performance Period ({{dateObj.recent}})
                  </p>
                  <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'CURRENT'">
                    Current Performance Period ({{dateObj.current}})
                  </p>
                </span>
              </div>
              <a tabindex="0" class="view-details" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState && !responsiveUI.isMobile"
                (keypress)="showDrilldown('topRecordsExchange')" (click)="showDrilldown('topRecordsExchange')">View Details
              </a>
            </div>
            <div *ngIf="top10RecExDataHasLoaded && !hideTop10RecEx">
              <msix-bar-chart 
                id="topRecordsExchange"
                [id]="topRecordsExchangeId" 
                [chart]="topRecordsExchangeChart" 
                [data]="topRecordsExchangeData">
              </msix-bar-chart>
              <p> Last Updated {{lastUpdated}}</p>
              <br />
            </div>
            <div class="no-data-display" *ngIf="hideTop10RecEx">
              <div class="no-data-display-text">
                No Data to Display
              </div>
            </div>
          </div>
          <div id="chart-section" class="chart-section vertical-align" ng-class="{'chart-row' : (responsiveUI.isMobile || responsiveUI.isTablet)}">
            <div class="layout-row" style="margin-bottom: 5px">
              <h2 style="width:90%">Data Request Worklist Aging</h2>
              <div class="right-align">
                <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState  && !responsiveUI.isMobile"
                  [exportlink]="worklistAgingExportCall" 
                  [exportfilter]="recordsExchangeExportObject"
                  [showcsv]="false" 
                  [showpdf]="false"
                  [showzip]="true">
                </export-report>
              </div>
            </div>
            <div class="layout-row" style="margin-bottom:20px">
              <div class="drill-down-rs" class="layout-row" flex=90>
                <span class="showing layout-row">Showing:</span>
                <span class="perf-period layout-row">
                  <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'PREVIOUS'">
                    Last Performance Period ({{dateObj.recent}})
                  </p>
                  <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'CURRENT'">
                    Current Performance Period ({{dateObj.current}})
                  </p>
                </span>
              </div>
              <a class="view-details" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && recordsExchangeFilterParams.rptGrping == 'State' && recordsExchangeFilterParams.selectedState  && !responsiveUI.isMobile"
                (keypress)="showDrilldown('dataReqWorklist')" (click)="showDrilldown('dataReqWorklist')">View Details
              </a>
            </div>
            <div *ngIf="dataReqWorklistDataHasLoaded && !hideDataReqWorklist">
              <msix-table 
                id="worklistAging" 
                [id]="worklistAgingId" 
                [chart]="worklistAgingChart" 
                [data]="worklistAgingData">
              </msix-table>
              <p> Last Updated {{lastUpdated}}</p>
              <br />
            </div>
            <div class="no-data-display" *ngIf="hideDataReqWorklist">
              <div class="no-data-display-text">
                No Data to Display
              </div>
            </div>
          </div>
        </div>

        <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <pattern id="diagonal-stripe-2" patternUnits="userSpaceOnUse" width="10" height="10">
              <image
                xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzInLz4KPC9zdmc+"
                x="0" y="0" width="10" height="10"> </image>
            </pattern>
            <mask id="worklist-stripe-mask" x="0" y="0">
              <rect x="0" y="0" width="1000" height="1000" fill="url(#diagonal-stripe-2)" />
            </mask>
          </defs>
        </svg>

      </div>
    </div>

    <!--Top 10 Records Exchange Drilldown Page-->
    <div class="layout-align-center-center bodyPadding" *ngIf="showDrill">
      <div class="layout-column" flex="90">
        <div class="report-summary layout-row">
          <p *ngIf="chart=='topRecordsExchange'">
            The tables in each tab below list the migratory children that are included in the corresponding dataset displayed in the chart.
            <a tabindex="0" *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
          </p>
          <p *ngIf="chart=='dataReqWorklist'">
            The table below lists the data requests your State received during each month of the selected Performance Period and 
            the dates each was received, responded to, and resolved.
            <a tabindex="0" *ngIf="!responsiveUI.isMobile" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
          </p>
        </div>
        <div class="report-summary drill-down-rs layout-row">
          <span class="showing layout-row">Showing:</span>
          <span class="perf-period layout-row">
            <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'PREVIOUS'">
              Last Performance Period ({{dateObj.recent}})
            </p>
            <p class="perf-period-text" *ngIf="recordsExchangeFilterParams.periodType == 'CURRENT'">
              Current Performance Period ({{dateObj.current}})
            </p>
          </span>
        </div>

        <div class="customTabs flex-inline" style="align-items: baseline" layout="flex" *ngIf="chart=='topRecordsExchange'">
          <p class="customTab flex-container" flex="25" (click)="changeTab('childrenMoving')"
            (keypress)="changeTab('childrenMoving')" [ngClass]="{'tabActive': (activeTab === 'childrenMoving')}"
            style="text-transform:uppercase">
            CHILDREN MOVING TO OR FROM {{recordsExchangeFilterParams.selectedState}}
          </p>
          <p class="customTab flex-container" flex="25" (click)="changeTab('studentRecords')"
            (keypress)="changeTab('studentRecords')" [ngClass]="{'tabActive': (activeTab === 'studentRecords')}"
            style="text-transform:uppercase">
            STUDENT RECORDS VIEWED BY {{recordsExchangeFilterParams.selectedState}}
          </p>
          <p class="customTab flex-container" flex="25" (click)="changeTab('moveNotices')"
            (keypress)="changeTab('moveNotices')" [ngClass]="{'tabActive': (activeTab === 'moveNotices')}"
            style="text-transform:uppercase">
            MOVE NOTICES SENT OR RECEIVED BY {{recordsExchangeFilterParams.selectedState}}
          </p>
          <p class="customTab flex-container" flex="25" (click)="changeTab('dataRequests')"
            (keypress)="changeTab('dataRequests')" [ngClass]="{'tabActive': (activeTab === 'dataRequests')}"
            style="text-transform:uppercase">
            DATA REQUESTS SENT OR RECEIVED BY {{recordsExchangeFilterParams.selectedState}}
          </p>
        </div>

        <no-results *ngIf="blankRes && tabChangeDone"></no-results>
        <reports-table *ngIf="!blankRes"
          [responsedata]="drillDownData"
          [shownavigation]="true"
          [drilldowninfo]="drillDownInfo"
          [currentsortcolumn]="currentSortColumn" 
          [sortascending]="sortAscending"
          (mycallback)="drillDownWorklist($event)"
          (drilldownsort)="handleDrillDownSortClick($event)"
          (sortbycolumn)="sortByColumn($event)">
        </reports-table>
      </div>
    </div>
</div>