
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { IListItem } from 'src/app/types/interfaces';
import { ListNameExistsValidator } from 'src/app/validators/list-name-exists.validator';

export interface IAcceptEditDeleteSharedListDialogData {
    savedListsNames: IListItem[],
    listName: string,
    acceptShared?: boolean,
}


@Component({
    selector: 'app-accept-edit-delete-shared-list-dialog.component',
    styleUrls: ['./accept-edit-delete-shared-list-dialog.component.scss'],
    templateUrl: './accept-edit-delete-shared-list-dialog.component.html'
})

export class AcceptEditDeleteSharedListDialogComponent implements OnInit, OnDestroy {
    savedLists: IListItem[] = [];
    title = 'Edit/Delete List';
    form: FormGroup;
    sub = new Subscription();
    constructor(
      public dialogRef: MatDialogRef<AcceptEditDeleteSharedListDialogComponent>,
      private fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: IAcceptEditDeleteSharedListDialogData) {
      this.savedLists = this.data.savedListsNames;
      this.title = this.data.acceptShared ? 'Accept/Delete List' : this.title;
      this.form = this.fb.group({
          listName: [this.data.listName, [Validators.maxLength(60), ListNameExistsValidator(this.savedLists.map(l => l.saveListName)
            .filter(name => !!name)
            .filter(name => name.toLowerCase() !== this.data.listName.toLowerCase())), Validators.required]],
          toDelete: [false]
      })
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe(({toDelete}) => {
            if (toDelete) {
                this.form.get('listName').disable();
            } else {
                this.form.get('listName').enable();
            }
        })
    }
  
    onSubmit() {
      this.dialogRef.close({toDelete: this.form.get('toDelete').value, 
        listName: this.form.get('toDelete').value ? this.data.listName :  this.form.get('listName').value});
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
