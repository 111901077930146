import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { EncoderService } from './encoder.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    
    constructor(private authenticationService: AuthService,private encoderService: EncoderService,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private cookieService: CookieService) {
    }

    // This is an interceptor for all HTTP calls from the front-end.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log('HI: ' + req.url);

        //let token = this.tokenExtractor.getToken() as string;
        console.log('is user authenticated? ' + this.authenticationService.isAuthenticated());

        if (!this.authenticationService.isAuthenticated() && req.url.indexOf('basicauth') === -1) {
            console.log('User is not authenticated. Will set Authorization header.');

            let authReq: any = null;

            if (req.url.includes('user/resetPasswordRequest') ||
                req.url.includes('user/verifyChallengequestions') ||
                req.url.includes('/state') ||
                req.url.includes('/user/admin') || 
				req.url.includes('/user/challengeQAndAVerification') ||
                req.url.includes('/user/resetAuthenticatedUserMFA')) {
                // Do not add 'Authorization' header and no credentials to pass
                authReq = req.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                    })
                });
            } else {
                authReq = req.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + sessionStorage.getItem('usercreds')
                    }),
                    withCredentials: true
                });
            }

            return next.handle(authReq);
        } else {

            console.log('httpInterceptor: isAuthenticated()? - ' + this.authenticationService.isAuthenticated() + ' for URL: ' + req.url);
            
            let authReq = req.clone({
                withCredentials: true
            });

            /* console.log('Req headers: ' + req.headers);
            console.log('AuthReq headers: ' + authReq.headers);

            console.log('Req withCredentials: ' + req.withCredentials);
            console.log('AuthReq withCredentials: ' + authReq.withCredentials);

            console.log('Req body: ' + req.body);
            console.log('AuthReq body: ' + authReq.body);

            console.log('Req params: ' + req.params);
            console.log('AuthReq params: ' + authReq.params);

            console.log('Req urlWithParams: ' + req.urlWithParams);
            console.log('AuthReq urlWithParams: ' + authReq.urlWithParams);

            console.log('cookies: ' + this.cookieService.getAll()); */

            return next.handle(authReq);
        }
    }
}
