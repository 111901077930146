<div class="reportsFilter filterAccountList filter-dialog layout-column" style="height: 100%; overflow: hidden;">
  <div style="flex-direction: column-reverse; display: flex;">

    <div class="layout-row" style="padding: 15px 30px; align-items: center">
      <a class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
        Reset Filters
      </a>
      <span style="flex: 1; box-sizing: border-box"></span>
      <button type="submit" class="md-button confirmButton" style="margin: 0" (click)="onSubmit()">
        SUBMIT
      </button>
    </div>

    <div class="filter-dialog" id="filter-content-id">
      <div class="md-dialog-content">
        <div class="layout-column filter-section dates">
          <div class="date-range student-info-dates">
            <mat-label class="date-filter-input flex-33">CREATION DATE:</mat-label>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="creationFromDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.creationDate.startMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #creationFromDD
                  [focusElement]="creationDateFromYYYY" maxlength="2" matInput id="creationFromDD"
                  [(ngModel)]="filterParameters.creationDate.startDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="creationDateFromYYYY" #creationDateFromYYYY
                  [(ngModel)]="filterParameters.creationDate.startYYYY">
              </mat-form-field>
            </div>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="creationDateToDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.creationDate.endMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #creationDateToDD [focusElement]="creationDateToYYYY"
                  maxlength="2" matInput id="creationDateToDD" [(ngModel)]="filterParameters.creationDate.endDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="creationDateToYYYY" #creationDateToYYYY
                  [(ngModel)]="filterParameters.creationDate.endYYYY">
              </mat-form-field>
            </div>
          </div>
          <div class="date-range student-info-dates">
            <mat-label class="date-filter-input flex-33">ACTIVATION DATE:</mat-label>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="activationDateFromDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.activationDate.startMM" style="padding: 0px !important">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #activationDateFromDD
                  [focusElement]="activationDateFromYYYY" maxlength="2" matInput id="activationDateFromDD"
                  [(ngModel)]="filterParameters.activationDate.startDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="activationDateFromYYYY" #activationDateFromYYYY
                  [(ngModel)]="filterParameters.activationDate.startYYYY">
              </mat-form-field>
            </div>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="activationDateToDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.activationDate.endMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #activationDateToDD [focusElement]="activationDateToYYYY"
                  maxlength="2" matInput id="activationDateToDD" [(ngModel)]="filterParameters.activationDate.endDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="activationDateToYYYY" #activationDateToYYYY
                  [(ngModel)]="filterParameters.activationDate.endYYYY">
              </mat-form-field>
            </div>
          </div>

          <div class="date-range student-info-dates">
            <mat-label class="date-filter-input flex-33">LOGIN DATE:</mat-label>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="loginDateFromDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.loginDate.startMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #loginDateFromDD
                  [focusElement]="loginDateFromYYYY" maxlength="2" matInput id="loginDateFromDD"
                  [(ngModel)]="filterParameters.loginDate.startDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="loginDateFromYYYY" #loginDateFromYYYY
                  [(ngModel)]="filterParameters.loginDate.startYYYY">
              </mat-form-field>
            </div>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="loginDateToDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.loginDate.endMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #loginDateToDD [focusElement]="loginDateToYYYY"
                  maxlength="2" matInput id="loginDateToDD" [(ngModel)]="filterParameters.loginDate.endDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="loginDateToYYYY" #loginDateToYYYY
                  [(ngModel)]="filterParameters.loginDate.endYYYY">
              </mat-form-field>
            </div>
          </div>
          <div class="date-range student-info-dates">
            <mat-label class="date-filter-input flex-33">EXPIRATION DATE:</mat-label>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="expirationDateFromDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.expirationDate.startMM" style="padding: 0px !important">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #expirationDateFromDD
                  [focusElement]="expirationDateFromYYYY" maxlength="2" matInput id="expirationDateFromDD"
                  [(ngModel)]="filterParameters.expirationDate.startDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="expirationDateFromYYYY" #expirationDateFromYYYY
                  [(ngModel)]="filterParameters.expirationDate.startYYYY">
              </mat-form-field>
            </div>
            <div class="date-filter-input flex-33">
              <mat-form-field appearance="fill">
                <mat-label>MM</mat-label>
                <input app-focus-next-element [requiredLength]="2" [focusElement]="expirationDateToDD" maxlength="2"
                  matInput [(ngModel)]="filterParameters.expirationDate.endMM">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>DD</mat-label>
                <input app-focus-next-element [requiredLength]="2" #expirationDateToDD [focusElement]="expirationDateToYYYY"
                  maxlength="2" matInput id="expirationDateToDD" [(ngModel)]="filterParameters.expirationDate.endDD">
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>YYYY</mat-label>
                <input maxlength="4" matInput id="expirationDateToYYYY" #expirationDateToYYYY
                  [(ngModel)]="filterParameters.expirationDate.endYYYY">
              </mat-form-field>
            </div>
          </div>
        </div>

        <hr layout-padding class="thickHr">

        <div>
          <div class="layout-row filter-section">
            <p class="filterHeader">
              ROB STATUS:
            </p>
          </div>
          <div class="layout-row">
            <mat-checkbox md-no-ink [(ngModel)]="filterParameters.robYesFlg">
              <p>Accepted ROB</p>
            </mat-checkbox>
            <mat-checkbox md-no-ink [(ngModel)]="filterParameters.robNoFlg">
              <p>Not Accepted ROB</p>
            </mat-checkbox>
          </div>
        </div>

        <hr layout-padding class="thickHr">

        <!-- <div>
        <div class="layout-row filter-section">
          <p>
            LOGIN STATUS:
          </p>
        </div>
        <div class="layout-row">
          <mat-checkbox md-no-ink [(ngModel)]="filterParameters.loginPassed" ng-true-value="true"
            ng-false-value="false">
            <p>Passed</p>
          </mat-checkbox>
          <mat-checkbox md-no-ink [(ngModel)]="filterParameters.loginFailed" ng-true-value="true"
            ng-false-value="false">
            <p>Failed</p>
          </mat-checkbox>
        </div>
      </div> 

      <hr layout-padding class="thickHr">-->
        <br />

        <div class="layout-row inputFields">
          <div class="layout-column flex-50">
            <mat-form-field placeholder="First Name" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': fNameField.focused}">
                First Name
              </mat-label>
              <input matInput #fNameField="matInput" name="searchFName" type="text"
                [(ngModel)]="filterParameters.firstName" [ngClass]="{'input-selected': fNameField.focused}">
            </mat-form-field>
            <br>
            <mat-form-field placeholder="User ID" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': userIdField.focused}">
                User ID
              </mat-label>
              <input matInput #userIdField="matInput" name="searchUserID" type="text"
                [(ngModel)]="filterParameters.userID" [ngClass]="{'input-selected': userIdField.focused}">
            </mat-form-field>
          </div>
          <div class="layout-column flex-50">
            <mat-form-field placeholder="Last Name" class="example-full-width ">
              <mat-label class="custom-label" [ngClass]="{'checkbox-selected': lNameField.focused}">
                Last Name
              </mat-label>
              <input matInput #lNameField="matInput" name="searchLName" type="text"
                [(ngModel)]="filterParameters.lastName" [ngClass]="{'input-selected': lNameField.focused}">
            </mat-form-field>
          </div>
        </div>
        <br>
        <br>
        <hr layout-padding class="thickHr">
        <div class="layout-row">
          <div class="layout-column flex-50">
            <p class="filterHeader">USER STATUS:</p>
            <div class="multi-select-slim">
              <div *ngFor="let status of filterParameters.userStatus">
                <mat-checkbox md-no-ink (click)="checkedStatus(status)" [checked]="existsInSelectedStatusArray(status)">
                  <p>{{status.desc}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="layout-column flex-50">
            <p class="filterHeader">USER ROLES:</p>
            <div class="layout-row selectButtonsNoMargin">
              <p>Select</p>
              <a (click)="selectAllTypes()">All</a>
              <p>|</p>
              <a (click)="selectNoneTypes()">None</a>
            </div>
            <div class="multi-select-box">
              <div *ngFor="let user of filterParameters.userTypes">
                <mat-checkbox md-no-ink (click)="checkedTypes(user)" [checked]="existsInSelectedTypesArray(user)">
                  <p>{{user.desc}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!-- Job Title section - Begin -->
        <hr layout-padding class="thickHr">
        <div class="layout-row filter-section">
          <p class="filterHeader">
            JOB TITLE:
          </p>
        </div>
        <div class="layout-row selectButtons">
          <p>Select</p>
          <a (click)="selectAllJobTitles()">All</a>
          <p>|</p>
          <a (click)="deselectAllJobTitles()">None</a>
        </div>
        <div class="layout-row colPOC layout-wrap">
          <div class="layout-row flex-33" *ngFor="let job of filterParameters.jobsArray">
            <div *ngIf="job.jobTitleDesc !== 'Other'">
              <mat-checkbox [checked]="job.selected" (click)="checkJobTitles(job.jobTitleKey)" aria-label="job">
                <p>{{job.jobTitleDesc}}</p>
              </mat-checkbox>
            </div>
            <div class="layout-column" *ngIf="job.jobTitleDesc === 'Other'">
              <mat-checkbox [checked]="job.selected" (click)="checkJobTitles(job.jobTitleKey)" aria-label="job">
                <p>{{job.jobTitleDesc}}</p>
              </mat-checkbox>
              <div style="margin-left: 30px">
                <input *ngIf="job.jobTitleDesc === 'Other'" style="height: 15px; margin: 0; padding-top: 5px"
                  [(ngModel)]="filterParameters.otherJobTitle" [disabled]="disableOther">
              </div>
            </div>
          </div>
        </div>

        <!-- Job Title section - End -->

        <!-- Include in Export section - Begin -->
        <hr layout-padding class="thickHr">

        <div class="layout-row filter-section">
          <p class="filterHeader">
            INCLUDE IN EXPORT:
          </p>
        </div>
        <div class="layout-row">
          <mat-checkbox md-no-ink [(ngModel)]="filterParameters.regionDrill" [checked]="filterParameters.regionDrill"
            ng-true-value="true" ng-false-value="false">
            <p>Region</p>
          </mat-checkbox>
          <mat-checkbox md-no-ink [(ngModel)]="filterParameters.districtDrill"
            [checked]="filterParameters.districtDrill" ng-true-value="true" ng-false-value="false">
            <p>District</p>
          </mat-checkbox>
        </div>

        <!-- Include in Export section - End -->

        <hr layout-padding class="thickHr" *ngIf="filterParameters.authRoleClasses.userAccount">

        <div class="layout-row" flex *ngIf="filterParameters.authRoleClasses.userAccount">
          <div class="layout-column" flex>
            <p class="filterHeader">STATES:</p>
            <div class="layout-row selectButtons">
              <p>Select</p>
              <a (click)="selectAll()">All</a>
              <p>|</p>
              <a (click)="selectNone()">None</a>
            </div>

            <div class="colPOC layout-row layout-wrap" flex vertical>
              <mat-checkbox md-no-ink (click)="checkedStates(state.stateKey)"
                [checked]="existsInSelectedStatesArray(state.stateKey)" *ngFor="let state of filterParameters.states"
                class="flex-30">
                <p>{{state.name}}</p>
              </mat-checkbox>
            </div>

          </div>
        </div>
        <hr layout-padding class="thickHr">

      </div>
    </div>

    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Account List Filter</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-button md-icon-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

  </div>
</div>