<div class="issue-dropdown" *ngIf="issuedata">
  <div class="content-box" [ngClass]="colorSelector()" style="margin-bottom:30px">
    <div class="layout-column">
      <div (click)="openCloseModal()">
        <div class="layout-row md-toolbar-tools content-title">
          <i class="fa fa-exclamation-triangle"></i>
          <h2 class="content-title-text">Data {{modaltitle}} Issue</h2>
          <span style="display: flex; flex: auto"></span>
          <div aria-label="close dialog">
            <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="showModal"></i>
            <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="!showModal"></i>
          </div>
        </div>
      </div>

      <md-dialog-content style="margin-bottom: 10px" *ngIf="!showModal">
        <div class="md-dialog-content content-area">
          <div *ngFor="let issue of issuedata; let lastElement = last" class="issue-wrapper">
            <div class="layout-column" [ngClass]="{'last': lastElement}">
              <div class="key-text">
                {{setIssueData(issue, 'key', 'title')}}
              </div>
              <div class="content-area-text" [innerHtml]="setIssueData(issue, 'key', 'text')">
              </div>
              <div id="residencyDateCriteria" *ngIf="issue == 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_1' || issue == 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_2'" class="content-area-text"></div>
              <hr class="issue-divide">
            </div>
          </div>
        </div>
      </md-dialog-content>
    </div>
  </div>
</div>
