<div class="reportsFilter learnMoreModal">
  <div aria-label="Potential Duplicates Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span flex></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog"
        style="margin-left: auto; margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <p>
        Use the <b>Filter</b> to select which type of potential duplicates you want to show in the report.<br />
        <br />
        When the report is filtered to show the MSIX Potential Duplicates, you can use the checkboxes to select one or
        more MSIX Student records that should be merged. After making your selection(s), select the Flag for Merge
        button to flag the
        selected MSIX Student records for merge. Then, you can initiate the merge from the Merge Record tab of your
        worklist. If MSIX Potential Duplicate records are for separate children, review the records in your State system
        to determine whether
        data needs to be corrected.<br />
        <br />
        Click on the row to view the child's student demographic page.<br />
        <br />
        Use the <b>Export</b> function to download a copy of the report in CSV or PDF format.
      </p>
    </div>
  </div>
</div>