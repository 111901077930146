import { Directive, AfterViewInit, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
@Directive({
    selector: '[app-focus-next-element]',
    providers: []
})

export class FocusNextElementDirective  {
    @Input() focusElement: HTMLInputElement;
    @Input() requiredLength: number

    constructor(private renderer: Renderer2, private element: ElementRef) {
    }

    @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent) {
        const value = (event.currentTarget as HTMLInputElement).value;
        if (value.length === this.requiredLength) {
            this.focusElement.focus();
        }
    }

}