<div class="childMobility">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Child Mobility</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill && chart == 'moveToMap'">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Child Mobility</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Children Moving To {{childMobilityFilterParams.selectedState}}</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill && chart=='moveFromMap'">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Child Mobility</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">Children Moving From {{childMobilityFilterParams.selectedState}}</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill && chart=='qadEnroll'">
    <span><a tabindex="0" routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a tabindex="0" (keypress)="goToReport()" (click)="goToReport()">Child Mobility</a></span>
    <span>></span>
    <span>
      <p style="vertical-align: middle !important;">QADs and Enrollments</p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">
    <h1 class="flex-90 header-padding">Child Mobility</h1>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='moveToMap'">
    <h1 class="flex-90 header-padding">Children Moving To {{childMobilityFilterParams.selectedState}}</h1>
    <div class="right-align">
      <export-report  tabindex="0" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
        [exportlink]="mobilityMapExportCall" 
        [exportfilter]="mapExport"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='moveFromMap'">
    <h1 class="flex-90 header-padding">Children Moving From {{childMobilityFilterParams.selectedState}}</h1>
    <div class="right-align">
      <export-report tabindex="0" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
        [exportlink]="mobilityMapExportCall" 
        [exportfilter]="mapExport"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <div class="header layout-row" *ngIf="showDrill && chart=='qadEnroll'">
    <h1 class="flex-90 header-padding">QADs and Enrollments</h1>
    <div class="right-align">
      <export-report tabindex="0" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
        [exportlink]="qadEnrollmentsExportCall" 
        [exportfilter]="childMobilityFilterParams.selectedState"
        [showcsv]="true" 
        [showpdf]="false">
      </export-report>
    </div>
  </div>

  <hr layout-padding class="thickHr">

  <div layout-align="center center" *ngIf="!showDrill" class="bodyPadding">
    <div class="layout-column">
      <div class="report-summary layout-row">
        <p *ngIf="authRoleClasses.dataAdmins">
          The Child Mobility Dashboard allows you to understand interstate and intrastate mobility patterns of the migratory children in your State.
          <a *ngIf="responsiveUI.isTablet || responsiveUI.isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
        <p *ngIf="authRoleClasses.dataDashboards">
          The Child Mobility Dashboard allows you to understand interstate and intrastate mobility patterns of migratory children in all States.
          <a *ngIf="responsiveUI.isTablet || responsiveUI.isDesktop" tabindex="0" (keypress)="goToLearnMore()" (click)="goToLearnMore()">Learn More</a>
        </p>
      </div>

      <filter-button *ngIf="!authRoleClasses.dataAdmins && (responsiveUI.isTablet || responsiveUI.isDesktop)" (activateFilter)="filterActivate()" class="chart-row" flex=auto></filter-button>
      <div *ngIf="dataHasLoaded" [ngClass]="{'chart-row' : authRoleClasses.dataAdmins}">
        <!--Mobility Map section-->
        <div>
          <div *ngIf="!hideMobilityMap && mobilityMapDataHasLoaded">

            <!--Desktop-->
            <!--Move To maps-->
            <div class="layout-row" *ngIf="responsiveUI.isDesktop">
              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving To All States</h2>
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving To {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="toPrevExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class=" drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Last Performance Period ({{dateObj.recent}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                    (keypress)="showDrilldown('moveToMap', 'PREVIOUS')" (click)="showDrilldown('moveToMap', 'PREVIOUS')">View Details
                  </a>
                </div>
                <msix-map 
                  id="toPrev"
                  [id]="toPrevId" 
                  [chart]="toPrevChart"  
                  [data]="toPrevData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>

              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving To All States</h2>
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving To {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="toCurrExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class=" drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Current Performance Period ({{dateObj.current}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                    (keypress)="showDrilldown('moveToMap', 'CURRENT')" (click)="showDrilldown('moveToMap', 'CURRENT')">View Details
                  </a>
                </div>
                <msix-map 
                  id="toCurr"
                  [id]="toCurrId" 
                  [chart]="toCurrChart"  
                  [data]="toCurrData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>
            </div>

            <!--Move From maps-->
            <div class="chart-row layout-row" *ngIf="responsiveUI.isDesktop">
              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving From All States</h2>
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving From {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="fromPrevExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class=" drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Last Performance Period ({{dateObj.recent}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                    (keypress)="showDrilldown('moveFromMap', 'PREVIOUS')" (click)="showDrilldown('moveFromMap', 'PREVIOUS')">View Details
                  </a>
                </div>
                <msix-map 
                  id="fromPrev"
                  [id]="fromPrevId" 
                  [chart]="fromPrevChart"  
                  [data]="fromPrevData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>

              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving From All States</h2>
                  <h2 style="width:90%" *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving From {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="fromCurrExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class=" drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Current Performance Period ({{dateObj.current}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="(authRoleClasses.dataAdmins || authRoleClasses.dataDashboards) && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState"
                    (keypress)="showDrilldown('moveFromMap', 'CURRENT')" (click)="showDrilldown('moveFromMap', 'CURRENT')">View Details
                  </a>
                </div>
                <msix-map 
                  id="fromCurr"
                  [id]="fromCurrId" 
                  [chart]="fromCurrChart"  
                  [data]="fromCurrData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>
            </div>
            <!--Mobile-->
            <!--Move To maps-->
            <div *ngIf="responsiveUI.isMobile || responsiveUI.isTablet">
              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving To All States</h2>
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving To {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="toPrevExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class=" drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Last Performance Period ({{dateObj.recent}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details"*ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                    (keypress)="showDrilldown('moveToMap', 'PREVIOUS')" (click)="showDrilldown('moveToMap', 'PREVIOUS')">View Details
                  </a>
                </div>
                <msix-map 
                  id="toPrev"
                  [id]="toPrevId" 
                  [chart]="toPrevChart"  
                  [data]="toPrevData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>

              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving To All States</h2>
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving To {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="toCurrExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class="drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Current Performance Period ({{dateObj.current}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                    (keypress)="showDrilldown('moveToMap', 'CURRENT')" (click)="showDrilldown('moveToMap', 'CURRENT')">View Details
                  </a>
                </div>
                <msix-map 
                  id="toCurr"
                  [id]="toCurrId" 
                  [chart]="toCurrChart"  
                  [data]="toCurrData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>
            </div>
            <!--Move From maps-->
            <div *ngIf="responsiveUI.isMobile || responsiveUI.isTablet">
              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving From All States</h2>
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving From {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="text-align:right" tabindex="0" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="fromPrevExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class="drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Last Performance Period ({{dateObj.recent}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                    (keypress)="showDrilldown('moveFromMap', 'PREVIOUS')" (click)="showDrilldown('moveFromMap', 'PREVIOUS')">View Details
                  </a>
                </div>
                <msix-map 
                  id="fromPrev"
                  [id]="fromPrevId" 
                  [chart]="fromPrevChart"  
                  [data]="fromPrevData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>

              <div id="chart-section" class="chart-section vertical-align">
                <div class="layout-row" style="margin-bottom: 5px">
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'National'" class="flex-90">Children Moving From All States</h2>
                  <h2 *ngIf="childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState" class="flex-90">
                    Children Moving From {{childMobilityFilterParams.selectedState}}</h2>
                  <div class="right-align">
                    <export-report style="width:10%" tabindex="0" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                      [exportlink]="mobilityMapExportCall" 
                      [exportfilter]="fromCurrExport"
                      [showcsv]="true" 
                      [showpdf]="false">
                    </export-report>
                  </div>
                </div>
                <div class="layout-row">
                  <div class="drill-down-rs layout-row flex-90">
                    <span class="showing layout-row">Showing:</span>
                    <span class="perf-period layout-row">
                      <p class="perf-period-text">
                        Current Performance Period ({{dateObj.current}})
                      </p>
                    </span>
                  </div>
                  <a tabindex="0" class="view-details" *ngIf="responsiveUI.isTablet && (authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState))"
                    (keypress)="showDrilldown('moveFromMap', 'CURRENT')" (click)="showDrilldown('moveFromMap', 'CURRENT')">View Details
                  </a>
                </div>
                <msix-map 
                  id="fromCurr"
                  [id]="fromCurrId" 
                  [chart]="fromCurrChart"  
                  [data]="fromCurrData">
                </msix-map>
                <p> Last Updated {{lastUpdated}}</p>
                <br />
              </div>
            </div>
          </div>
          <div class="no-data-display" *ngIf="hideMobilityMap">
            <div class="no-data-display-text">
              No Data to Display
            </div>
          </div>
        </div>

        <!--QAD & Enrollments section-->
        <div class="chart-row chart-section">
          <div id="chart-section" class="chart-row vertical-align">
            <div class="layout-row" style="margin-bottom: 5px">
              <h2 style="width:90%">QADs and Enrollments</h2>
              <div class="right-align">
                <export-report style="width:10%" tabindex="0" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState)"
                [exportlink]="qadEnrollmentsExportCall" 
                [exportfilter]="childMobilityFilterParams.selectedState"
                [showcsv]="true" 
                [showpdf]="false">
              </export-report>
              </div>
            </div>
            <div class="layout-row">
              <div class="drill-down-rs layout-row" style="width:90%">
                <span class="showing layout-row">Showing:</span>
                <span class="perf-period layout-row">
                  <p class="perf-period-text">
                    Last 12 Months ({{reportInfoService.getLast12Months()}})
                  </p>
                </span>
              </div>
              <a tabindex="0" class="view-details" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && authRoleClasses.dataAdmins || (authRoleClasses.dataDashboards && childMobilityFilterParams.rptGrping == 'State' && childMobilityFilterParams.selectedState)"
                (keypress)="showDrilldown('qadEnroll')" (click)="showDrilldown('qadEnroll')">View Details
              </a>
            </div>
            <div *ngIf="!hideQadEnrollments && qadEnrollmentsDataHasLoaded">
              <msix-table 
                id="qadEnroll"
                [id]="qadEnrollId" 
                [chart]="qadEnrollChart"  
                [data]="qadEnrollData">
              </msix-table>
              <p> Last Updated {{lastUpdated}}</p>
              <br />
            </div>
            <div class="no-data-display" *ngIf="hideQadEnrollments">
              <div class="no-data-display-text">
                No Data to Display
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Children Moving Drilldown Page-->
  <div class="layout-align-center-center layout-row bodyPadding" *ngIf="showDrill && (chart=='moveFromMap' || chart=='moveToMap')">
    <div class="layout-column flex-100">
      <div class="report-summary layout-row">
        <p *ngIf="chart=='moveToMap'">
          The table below lists the migratory children that made qualifying moves that generated a QAD to your State from your partner States in the selected performance period.
          <a *ngIf="responsiveUI.isTablet || responsiveUI.isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
        <p *ngIf="chart=='moveFromMap'">
          The table below lists the migratory children that made qualifying moves that generated a QAD from your State to your partner States in the selected performance period.
          <a *ngIf="responsiveUI.isTablet || responsiveUI.isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary drill-down-rs layout-row">
        <span class="showing layout-row">Showing:</span>
        <span class="perf-period layout-row">
          <p class="perf-period-text" *ngIf="childMobilityFilterParams.periodType == 'PREVIOUS'">
            Last Performance Period ({{dateObj.recent}})
          </p>
          <p class="perf-period-text" *ngIf="childMobilityFilterParams.periodType == 'CURRENT'">
            Current Performance Period ({{dateObj.current}})
          </p>
        </span>
      </div>

      <no-results *ngIf="blankRes"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>

  <!--QAD Enrollments Drilldown Page-->
  <div class="layout-align-center-center layout-row bodyPadding" *ngIf="showDrill && chart=='qadEnroll'">
    <div class="layout-column flex-90">
      <div class="report-summary">
        <p>
          The table below lists the children enrolling in your State in the last 12 months and their most recent QAD.
          <a *ngIf="responsiveUI.isTablet || responsiveUI.isDesktop" tabindex="0" (keypress)="goToDDLearnMore()" (click)="goToDDLearnMore()">Learn More</a>
        </p>
      </div>
      <div class="report-summary drill-down-rs layout-row">
        <span class="showing layout-row">Showing:</span>
        <span class="perf-period layout-row">
          <p class="perf-period-text">
            Last 12 Months ({{reportInfoService.getLast12Months()}})
          </p>
        </span>
      </div>

      <no-results *ngIf="blankRes"></no-results>
      <reports-table *ngIf="!blankRes"
        [responsedata]="drillDownData"
        [shownavigation]="true"
        [drilldowninfo]="drillDownInfo"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        (drilldownsort)="handleDrillDownSortClick($event)"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
    </div>
  </div>
</div>