import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

Injectable({providedIn: 'root'})

export class PreviousRouteService {
  private _previousRoute: string = '';
  private _currentRoute: string = '';
  private previousRoute = new BehaviorSubject(this._previousRoute);
  previousRoute$: Observable<string>;

  constructor() {
    this.previousRoute$ = this.previousRoute.asObservable();
  }

  routeChange(currRoute: string) {
    this._previousRoute = this._currentRoute;
    this._currentRoute = currRoute;
    this.previousRoute.next(this._previousRoute);
  }


}