import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { AppConstants } from '../config/index.constants';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

const API_URL = AppConstants.api;
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userObj: {
    roles: string,
    name: string,
    username: string,
    privacyFlag: boolean,
    robFlag: boolean,
    isOTP: boolean,
    betaUserFlag: boolean,
    passwordExpirationWarn: boolean
  };
  USER_CREDS_SESSION_ATTRIBUTE_NAME = 'usercreds';
  private userIsLoggedIn = new BehaviorSubject<boolean>(!!sessionStorage.getItem('usercreds'));
  userIsLoggedIn$ = this.userIsLoggedIn.asObservable();

  constructor( private http: HttpClient, private router: Router,  private cookieService: CookieService) {}

  login(credentials: any): Observable<any> {
    const params = {
      isMFA: credentials.isMFA,
      mfaCode: credentials.mfaCode,
      showRegistrationDecision: credentials.showRegistrationDecision,
      isRegistrationWorkflow: credentials.isRegistrationWorkflow,
      needQRCode: credentials.needQRCode,
      verifyQRCode: credentials.verifyQRCode
    };
    localStorage.clear();
    return this.http.get(API_URL + 'user', {params}).pipe(
      tap(() => this.userIsLoggedIn.next(true))
    );
  }

  clearData() {
    this.cookieService.removeAll();
    sessionStorage.clear();
    localStorage.clear();
    history.pushState(null, '')
  }

  clearBrowserHistory() {
    history.replaceState(null, '', window.location.href);
    window.addEventListener('popstate', () => {
      history.pushState(null, '', window.location.href)
    })
  }

  redirectToPrivacy(shouldRedirect:boolean = true) {
    if (!shouldRedirect) {
      this.http.post<any>(API_URL + 'logout', {}).subscribe(() => {
        window.location.reload();
      })
    } else {
      this.http.post<any>(API_URL + 'logout', {}).subscribe(() => {
        this.router.navigate(['/'], {replaceUrl: true})
        .then(() => {
                window.location.reload();
          })
      });
    }
  }
  
  logout(shouldRedirect: boolean = true): void {
    this.clearData();
    this.userIsLoggedIn.next(false);
    this.redirectToPrivacy(shouldRedirect)
    this.clearBrowserHistory();
    localStorage.setItem('logout-event', 'logout-'+ Math.random());
  }
}