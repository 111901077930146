<div class="background-cover">
  
<div class="requestAccount-page background-creative main-padding" [ngClass]="{'main-padding' : responsiveUI.isDesktop}">
  <div class="side-padding" [ngClass]="{'side-padding' : responsiveUI.isDesktop, 'side-padding-mobile' : (responsiveUI.isMobile || responsiveUI.isTablet)}">
    <h2 class="padding-top-10">User Access Process</h2>
    <div [ngClass]="{'layout-flex-row': responsiveUI.isDesktop}">
      <div class="half-width layout-flex-column" layout-align="start start">
        <h3 class="padding-top-10">
          To request access to MSIX, please:
        </h3>
        <ol>
          <li><p>Download the MSIX User Access Guide and Application and complete the "User Application for Access to MSIX" application form.</p></li>
          <li><p>Submit your application to your supervisor (Verifying Authority).</p></li>
          <li><p>The Verifying Authority will review the application and confirm your identity and need for access. Upon completion, the Verifying Authority will return the form to you.</p></li>
          <li><p>Forward the completed form to your State/Regional Approval Authority (designated State Contact).</p></li>
          <li><p>Your State/Regional Approval Authority will review and approve your application and then create your account.</p></li>
        </ol>
      </div>
    </div>
    <h2 class="padding-top-10">User Access Guide and Application</h2>
    <div [ngClass]="{'layout-flex-row': responsiveUI.isDesktop}" class="layout-flex-column">
      <div class="layout-flex-column" style="flex: 50%" layout-align="start start">
        <p class="padding-top-10 flex-inline">
          The User Access Guide and Application is available in both&nbsp;
          <a href="assets/trainingCorner/UserAccessGuideApplication/MSIXUserAccessGuideandApplication.docx">Word</a>
          &nbsp;and&nbsp;<a href="assets/trainingCorner/UserAccessGuideApplication/MSIXUserAccessGuideandApplication.pdf">PDF</a>&nbsp;format.
        </p>
      </div>
    </div>
    <h2 class="padding-top-25">Find Your State Contact</h2>
    <div layout-gt-md="row" layout="column">
      <div class="layout-flex-column" style="flex:50%" layout-align="start start">
        <p class="padding-top-10">
          You can find your designated State Contact by using the
          <a style="margin-bottom: 2px" tabindex="0" (click)="goToAdminSearch()">State Contact Search</a>
          or by contacting the MSIX Help Desk at&nbsp;<a href="mailto:MSIXSupport@deloitte.com">MSIXSupport@deloitte.com</a>
          or&nbsp;<a href="tel:+1-866-878-9525">1-866-878-9525</a>.
        </p>
      </div>
    </div>
    <h3 class="padding-top-25">
      Download the latest version of Adobe Acrobat Reader.
    </h3>
    <a href="http://www.adobe.com/products/acrobat/readstep2.html">Adobe Acrobat Reader</a>
  </div>
</div>
</div>