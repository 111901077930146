import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { EncoderService } from '../services/encoder.service';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class WorklistService {
  private apiUrl = AppConstants.api;
  router: string;
  passwordResetFlag: boolean;
  challengeQuestionResetFlag: boolean;
  //current: User;
  constructor(
    private http: HttpClient,
    private encoderService: EncoderService,
    private cookieService: CookieService,
    private loginService: LoginService,
    private authService: AuthService,
    private _router: Router) {
      this.router = _router.url;
      //this.current = null;
      this.passwordResetFlag = false;
      this.challengeQuestionResetFlag = false;
    }

  getWorklistCount() {
    this.authService.isRoleClassAuthorized(['primarySecondary']).then(data => {

      let worklistItemsAPI = this.getWorklistItems();
      let worklistItemsSubscribed = worklistItemsAPI.subscribe({
        next: data => {
          console.log(data);
          
          let count = data.dataRequestItems.length + data.nominateMatchItems.length + data.nominateMergeItems.length + data.nominateSplitItems.length + data.residencyChangeItems.length + data.validateMergeItems.length + data.validateSplitItems.length;
          
          //putting in cookies instead
          this.cookieService.putObject('worklistCount', count);

          worklistItemsSubscribed.unsubscribe();
        },
        error: error => {
          console.error('Error occurred in getWorklistCount() function: ' + error);

          worklistItemsSubscribed.unsubscribe();
        }
      });
    });
  }

  getWorklistItems(): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'worklist');
  }

  updateUnreadFlag(worklistId) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'worklist/' + worklistId + '/worklistread', '');
  }

  initiateMoveNotification(msixId, moveNotificationObject) {
    return this.http.put(this.apiUrl + 'student/msix/' + msixId + '/move', 
      moveNotificationObject, {observe: 'response', responseType: 'blob' as 'text'});
  }

  initiateDataRequest(msixId, moveNotificationObject) {
    return this.http.put(this.apiUrl + 'student/msix/' + msixId + '/data', 
      moveNotificationObject, {observe: 'response', responseType: 'blob' as 'text'});
  }

  removeFromWorklist(worklistID, stateStudentKey) {
    return this.http.delete(this.apiUrl + 'worklist/' + worklistID + '/' + stateStudentKey, {observe: 'response', responseType: 'blob' as 'text'});
  }

  getWorklistHistory(worklistId, type) {
    var isCor = false;
    if (type && (type === 'data' || type === 'move')) {
      isCor = true;
    }
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'worklist/' + worklistId + '/history?isCor=' + isCor);
  }

  getDataRequests() {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'worklist/dataRequests');
  }

  flagStudentforMerge(msixID) {
    return this.http.put(this.apiUrl + 'student/msix/' + msixID + '/merge', '',
     {observe: 'response', responseType: 'blob' as 'text'});
  }

  // This function receives an array of MSIX IDs and it calls API to flag all of them for merge
  // Upon success it needs to update the grid (Potential Duplicates report or Child Count Reconciliation Potential Duplicates report)
  flagAllSelectedStudentsForMerge(msixIDs): Observable<any> {
    return this.http.put(this.apiUrl + 'student/mergeAll', msixIDs, { observe: 'response', responseType: 'blob' as 'text' });

  }

  flagStudentforSplit(msixID) {
    return this.http.put(this.apiUrl + 'student/msix/' + msixID + '/split', '', { observe: 'response', responseType: 'text' });

  }

  validateMerge(validateMergeObject): Observable<any>  {
    return this.http.put(this.apiUrl + 'worklist/' + validateMergeObject.master.key + '/merge', validateMergeObject, { observe: 'response', responseType: 'text' });
  }

  validateSplit(validateSplitObject) {
    return this.http.put(this.apiUrl + 'worklist/' + validateSplitObject.worklistId + '/split', validateSplitObject, { observe: 'response', responseType: 'text' });

  }

  processMerge(nominateMergeObject) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'worklist/' + nominateMergeObject.master.key + '/merge', nominateMergeObject);

  }

  processMatch(nominateMatchObject, worklistId) {
    return this.http.put(this.apiUrl + 'worklist/' + worklistId + '/merge', nominateMatchObject, 
      { observe: 'response', responseType: 'text' });
  }

  processSplit(nominateSplitObject) {
    nominateSplitObject.master.children = [];
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'worklist/' + nominateSplitObject.master.key + '/split', nominateSplitObject);

  }

  sendChangeOfResidencyMessage(corMessage): any {
    return this.http.put(this.apiUrl + 'worklist/' + corMessage.corWorklistItem.key + '/cor/message', corMessage, { observe: 'response', responseType: 'text' });
  }

  updateChangeOfResidency(corUpdate) {
    return this.http.put(this.apiUrl + 'worklist/' + corUpdate.corWorklistItem.key + '/cor/validate', corUpdate, { observe: 'response', responseType: 'text' });
  }

  searchCOR(searchObject): any {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'worklist/search', searchObject);
  }

  getWorklistHistoryRecord(worklistId, worklistType): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'worklist/' + worklistId + '?worklistType=' + worklistType);
  }

  getWorklistAdminList(worklistId, isCOR) {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'worklist/' + worklistId + '/admin' + '?isCOR=' + isCOR);
  }

  updateWorklistAdminList(worklistId, adminObj, isCOR) {
    return this.http.put(this.apiUrl + 'worklist/' + worklistId + '/admin' + '?isCOR=' + isCOR, adminObj, 
     {observe: 'response', responseType: 'blob' as 'text'});
  }

}
