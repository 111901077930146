<div class="worklistHistory-page" id="worklistpagesection">
  
    <div *ngIf="!responsiveUI.isDesktop">
      <div class="md-content">
        <div layout-margin class="mainDiv">
  
          <div layout-margin class="top-margin-10">
  
            <div class="mainHeader">
              <div class="block2 uppercase">Worklist History Search</div>
            </div>
            <div>
              <p>To complete a worklist reassign, access your worklist on desktop.</p>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  
    <!--worklist history search start-->
    <div *ngIf="responsiveUI.isDesktop">
  
      <div class="md-content layout-padding">
        <div class="layout-margin mainDiv">
  
          <div class="layout-flex-row" style="margin: 7px 0px; margin-top: -4px; ">
            <div class="historicalTab right-align" (click)="goToWorklist()">
              <p>Worklist</p>
            </div>
          </div>
  
          <!-- Partials -->
  
          <!-- <div class="worklist-history-search-box" ng-include="'_worklistHistorySearch.html'"></div> -->
          <app-work-history-search class="worklist-history-search-box"
            (searchforstudents)="searchInit($event)"
            [title]="title"
            [worklistHistorySearchType]="worklistHistorySearchType"
            [states]="states">
          </app-work-history-search>
  
        </div>
      </div>
    </div>
    <!--worklist history search end-->
  
    <!--worklist history search results start-->
    <div *ngIf="responsiveUI.isDesktop">
  
      <div class="md-content">
        <div>

          <!-- Partials -->
          <!-- <div class="no-margins" ng-include="'_worklistHistorySearchResults.html'"></div> -->
          <app-work-history-results class="no-margins"
            [totalRecordsLength]="totalRecordsLength"
            [fullList]="fullList"
            [currentPage]="currentPage"
            [itemsPerLoad]="itemsPerLoad"
            [searchObject]="searchObject"
            [partialWlList]="partialWlList">
          </app-work-history-results>
  
        </div>
      </div>
    </div>
    <!--worklist history search results end-->
  
  
    <!--reassign end-->
  
  </div>
  