import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subscription } from "rxjs";
import { UNPROTECTED_ROUTES } from "../config/index.constants";
import { AuthService } from "./auth.service";

const IDLE_TIME_SECONDS = 15 * 60; // if inactive for 15 minutes, app gets into idle state
const IDLE_DURATION = 5 // time user can be in idle state before signing out

// temporary values for testing
// const IDLE_TIME_SECONDS = 10; // if inactive for 15 minutes, app gets into idle state
// const IDLE_DURATION = 5 // time user can be in idle state before signing out

export enum IDLE_TIMER_STATE {
    Stopped = 'Stopped',
    Started = 'Started'
}
@Injectable({
    providedIn: 'root'
})
export class IdleService implements OnDestroy {
    sub = new Subscription();
    constructor(private idle: Idle, 
        private authService: AuthService,
        private router: Router
    ) {
        this.idle.setIdle(IDLE_TIME_SECONDS); // Idle time
        this.idle.setTimeout(IDLE_DURATION); // Timeout duration
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); 


       this.sub.add(idle.onIdleEnd.subscribe(() => {
        console.log('no longer idle')
      }));

      this.sub.add(idle.onTimeout.subscribe(() => {
          console.log('idle time out')
          this.stopWatching();
      }));
      
      this.sub.add(idle.onIdleStart.subscribe(() => {
        console.log('you have gone idle')
      }));
    }

    startWatching() {
        this.idle.watch();
    }
      
    stopWatching(isIdle = true) {
        const currentUrl = this.router.url.split('?')[0]
        if (!this.authService.isAuthenticated() && isIdle && UNPROTECTED_ROUTES.some(r => r.includes(currentUrl))) {
            return;
        }
        else {
            this.idle.stop();
            if (this.authService.isAuthenticated() && isIdle && UNPROTECTED_ROUTES.some(r => r.includes(currentUrl))) {
               this.authService.logout(false);
           } else {
               this.authService.logout();
           }
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}