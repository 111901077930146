import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AppConstants } from 'src/app/config/index.constants';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'answerChallengeQuestions',
  templateUrl: './answerChallengeQuestions.component.html',
  styleUrls: ['./answerChallengeQuestions.component.scss']
})
export class AnswerChallengeQuestionsComponent implements OnInit {

  private title: string;
  private flag: boolean;
  private userListOfQuestionsAndAnswers: any;
  private keysList: string[] = [];
  private questionsList: string[] = [];
  private isChallengeQuestionSet: boolean;
  private userName: string;
  appVersion: string;
  private userChallengeQuestion: any;
  question1: string;
  response1: string;
  question2: string;
  response2: string;
  question3: string;
  response3: string;

  constructor(private _User: UserService,
    private cookieService: CookieService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
  }

  ngOnInit() {
    this.resetFields();
    this.userListOfQuestionsAndAnswers = this.cookieService.getObject('challengeQuestionsList');
    this.isChallengeQuestionSet = (this.cookieService.get('isChallengeQuestionSet').toLowerCase().trim() == 'true' ? true : false);
    this.userName = decodeURIComponent(this.cookieService.get('username'));
    this.setUpQuestions();
    this.appVersion = AppConstants.appVersion;
  }
  
  submitForm() {
    if (this.isChallengeQuestionSet) {
      //send selected questions and answers to api call
      this.userChallengeQuestion = {
        challengeQuestions: [
          {
            key: this.keysList[0],
            question: this.question1, 
            answer: this.response1.trim()
          },
          {
            key: this.keysList[1],
            question: this.question2,
            answer: this.response2.trim()
          },
          {
            key: this.keysList[2],
            question: this.question3,
            answer: this.response3.trim()
          }
        ]
      }
    
      let apiCall = this._User.verifyChallengeQuestions(this.userName, this.userChallengeQuestion);
      let subscribed = apiCall.subscribe({
        // Callback for success
        next: data => {
          console.log("Successfully called verify challenge questions answer API call.");
          
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: error => {
          console.log("Error occurred when verify challenge questions answer API call was made: " + error);
          
          subscribed.unsubscribe();
        }
      });

    }

    //take user to generic message page to check their email
    this.goToCheckEmailMsg();
  }

  goToCheckEmailMsg(){
      this._router.navigate(['checkEmailMsg']);
  }

  resetFields() {
    this.userName = this.userName;
    this.response1 = '';
    this.response2 = '';
    this.response3 = '';
  }

  setUpQuestions() {
      var num = 1;
      for (var challengeQuestion of this.userListOfQuestionsAndAnswers){
          this.keysList.push(challengeQuestion.key);
          this.questionsList.push(challengeQuestion.question);
      }
      this.question1 = this.questionsList[0];
      this.question2 = this.questionsList[1];
      this.question3 = this.questionsList[2];
  }
}
  