export {AppConstants}
const AppConstants = {
  api: '/msix-api/service/',
  help: '/msix-api/help/',
  trainingCorner: '/msix/trainingCorner/',
  appName: 'MSIX',
  appVersion: '1.7.24',
  userRoles: {
    all: '*',
    allUsers: ['State Batch Submitter', 'OME User Administrator', 'State Data Administrator', 'District Data Administrator', 'MSIX Primary User', 'State User Administrator', 'Secondary User', 'Government Administrator', 'State Regional Administrator', 'MSIX Privacy Act Administrator', 'Super User', 'Regional Data Administrator', 'Regional User Administrator'],
    sbs: 'State Batch Submitter',
    oua: 'OME User Administrator',
    sda: 'State Data Administrator',
    dda: 'District Data Administrator',
    mpu: 'MSIX Primary User',
    sua: 'State User Administrator',
    msu: 'Secondary User',
    ga: 'Government Administrator',
    sra: 'State Regional Administrator',
    mpa: 'MSIX Privacy Act Administrator',
    su: 'Super User',
    rda: 'Regional Data Administrator',
    rua: 'Regional User Administrator'
  },
  mobilePixels: 600
};

export const UNPROTECTED_ROUTES = ['/requestAccount', '/help', '/resources', '/pwReset', '/', '/login', '/forgot-password', '/answerChallengeQuestions', '/checkEmailMsg', '/adminSearchResults']

export { authorizedRolesByRoute }
const authorizedRolesByRoute: Record<string, any> = {
  '/': AppConstants.userRoles.allUsers,
  '/login': AppConstants.userRoles.allUsers,
  '/requestAccount': AppConstants.userRoles.allUsers,
  '/resources': AppConstants.userRoles.allUsers,
  '/help': AppConstants.userRoles.allUsers,
  '/pwReset': AppConstants.userRoles.allUsers,
  '/pfsReport': AppConstants.userRoles.allUsers,
  '/ageBirthThrough2Report': AppConstants.userRoles.allUsers,
  '/reports': AppConstants.userRoles.allUsers,
  '/reconciliation/dashboard': AppConstants.userRoles.allUsers,
  '/userAdmin': [AppConstants.userRoles.rua, AppConstants.userRoles.sua, AppConstants.userRoles.su, AppConstants.userRoles.oua],
  '/worklist': AppConstants.userRoles.allUsers,
  '/searchResults': [AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.mpa, AppConstants.userRoles.su],
  '/home': AppConstants.userRoles.allUsers
};

// Used in various report configurations
export { roleClasses }
const roleClasses = {
  myAccount: AppConstants.userRoles.allUsers,
  studentSearch: [AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.mpa, AppConstants.userRoles.su],
  reports: [AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.rua, AppConstants.userRoles.sua, AppConstants.userRoles.ga, AppConstants.userRoles.oua, AppConstants.userRoles.sbs, AppConstants.userRoles.su],
  sendInfo: [AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.su],
  initiateMerge: [AppConstants.userRoles.mpu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.su],
  validateMerge: [AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.su],
  worklist: [AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.su],
  worklistSearch: [AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.sda, AppConstants.userRoles.su],
  stateAdmin: [AppConstants.userRoles.sua, AppConstants.userRoles.oua, AppConstants.userRoles.su],
  stateCreateAdmin: [AppConstants.userRoles.sua, AppConstants.userRoles.oua],
  regionalAdmin: [AppConstants.userRoles.rua, AppConstants.userRoles.sua],
  districtAdmin: [AppConstants.userRoles.rua, AppConstants.userRoles.sua],
  passwords: [AppConstants.userRoles.rua, AppConstants.userRoles.sua, AppConstants.userRoles.oua, AppConstants.userRoles.su],
  enableRegion: [AppConstants.userRoles.sra, AppConstants.userRoles.su],
  editRegion: [AppConstants.userRoles.sra, AppConstants.userRoles.su],
  superUser: [AppConstants.userRoles.su],
  omeUser: [AppConstants.userRoles.oua],
  privacyEdit: [AppConstants.userRoles.mpa, AppConstants.userRoles.su],
  govUser: [AppConstants.userRoles.oua, AppConstants.userRoles.su],
  primarySecondary: [AppConstants.userRoles.mpu, AppConstants.userRoles.msu],
  dataAdmins: [AppConstants.userRoles.sda, AppConstants.userRoles.rda, AppConstants.userRoles.dda],
  //reports home page
  dashboard: AppConstants.userRoles.allUsers,
  //reports pages
  userAccount: [AppConstants.userRoles.oua, AppConstants.userRoles.su, AppConstants.userRoles.ga],
  userAccount_with_oua: [AppConstants.userRoles.oua],
  userAccount_with_ga: [AppConstants.userRoles.oua, AppConstants.userRoles.sua, AppConstants.userRoles.rua, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  studentInfo: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  studentInfo_with_da_ga: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  studentInfo_with_da_ga_mpu_msu: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  studentMobility: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.mpu, AppConstants.userRoles.msu, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  mergeAndSplitWorklist_with_da: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.su],
  mergeAndSplitWorklist_with_ga: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  mergeAndSplitWorklist_all: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataQuality: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataQuality_all: [AppConstants.userRoles.sbs, AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataQuality_sbs: [AppConstants.userRoles.sbs],
  changeOfResidency: [AppConstants.userRoles.sda, AppConstants.userRoles.dda, AppConstants.userRoles.rda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  usageReport: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  studentMobility_all: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  msixUsage: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  childCountReconciliation: [AppConstants.userRoles.su, AppConstants.userRoles.sda, AppConstants.userRoles.rda, AppConstants.userRoles.dda, AppConstants.userRoles.ga],
  dataRequest: [AppConstants.userRoles.sda, AppConstants.userRoles.rda, AppConstants.userRoles.dda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  moveNotice: [AppConstants.userRoles.sda, AppConstants.userRoles.rda, AppConstants.userRoles.dda, AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataRequest_su_ga: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataRequest_ga: [AppConstants.userRoles.ga],
  dataDashboards: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataDashboard_studentAchievement: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  dataDashboard_studentAchievement_with_sua: [AppConstants.userRoles.ga, AppConstants.userRoles.su, AppConstants.userRoles.sua],
  dataDashboard_studentAchievement_with_sda: [AppConstants.userRoles.ga, AppConstants.userRoles.su, AppConstants.userRoles.rda, AppConstants.userRoles.dda, AppConstants.userRoles.sda],
  hideReconDashFull: [AppConstants.userRoles.ga],
  childCountReconciliationNational: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  multiMoveNotice: [AppConstants.userRoles.sda, AppConstants.userRoles.rda, AppConstants.userRoles.dda, AppConstants.userRoles.mpu, AppConstants.userRoles.msu],
  moveNoticeNational: [AppConstants.userRoles.ga, AppConstants.userRoles.su],
  acctMngt_sua:[AppConstants.userRoles.sua],
  accountMetrics:[AppConstants.userRoles.su, AppConstants.userRoles.ga, AppConstants.userRoles.sua, AppConstants.userRoles.sda]
}

// export default AppConstants;
