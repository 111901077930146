<div class="reportsFilter learnMoreModal">
  <div aria-label="Potential Duplicates DD Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span flex></span>
      <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog"
        style="margin-left: auto; margin-right: 0;">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-column">
        <p>
          Click on the <b>MSIX ID</b> to view a child’s Consolidated Student Record.
        </p>
        <div class="add-margin-top">
          <p>Use the <b>Export</b> function to download a copy of the report in CSV format.</p>
        </div>
        <div class="add-margin-top">
          <p>
            Use the breadcrumbs at the top of the page to return to the main Potential Duplicates
            Report.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>