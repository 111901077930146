import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
@Directive({
    selector: '[custom-tooltip]',
    exportAs: 'custom-tooltip'
})
export class CustomTooltipDirective {
    @Input()
    tooltipTemplate: TemplateRef<any>;

    constructor(
        private el: ElementRef, 
        private renderer: Renderer2, 
        private viewContainerRef: ViewContainerRef) {}

    @HostListener('mouseenter') onMouseEnter() {
       this.create()
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hide();
    }

    hide() {
        const tooltip = this.el.nativeElement.querySelector('.custom-tooltip')
        this.renderer.removeChild(this.el.nativeElement, tooltip);
    }
    create() {
        let tooltip = this.el.nativeElement.querySelector('.custom-tooltip')
        if (tooltip) return;
        tooltip = this.renderer.createElement('div');
        this.renderer.addClass(tooltip, 'custom-tooltip');
        this.renderer.setStyle(tooltip, 'position', 'absolute');
        this.renderer.setStyle(tooltip, 'background-color', 'hsla(0, 0%, 0%, 0.7)');
        this.renderer.setStyle(tooltip, 'padding', '1rem');
        this.renderer.setStyle(tooltip, 'border-radius', '0.25rem');
        this.renderer.setStyle(tooltip, 'color', 'white');
        this.renderer.setStyle(tooltip, 'width', '40rem');
        this.renderer.setStyle(tooltip, 'transform', 'translate(3rem, -5rem)');

        const viewRef = this.viewContainerRef.createEmbeddedView(this.tooltipTemplate);

        viewRef.detectChanges();

        const tooltipHTMLContent = viewRef.rootNodes[0];

        this.renderer.appendChild(tooltip, tooltipHTMLContent);

        this.renderer.appendChild(this.el.nativeElement, tooltip);

    }
}