<div class="userDetails-page"
  [ngClass]="{'main-padding' : responsiveUI.isDesktop, 'main-padding-mobile-tablet' : (responsiveUI.isTablet || responsiveUI.isMobile)}">

  <div *ngIf="showModal" class="md-backdrop"></div>
  <div>

    <div class="layout-margin mainDiv" [ngClass]="{'reduce-margin-mobile': responsiveUI.isMobile}">
      <div class="mainHeader" style="margin: 8px;">
        <div class="textUppercase block2">User Record</div>
        <div class="flexSpaceBetween">
          <h2>{{currUser.username}}</h2>
          <div class="mainHeaderRight" style="padding-right: 20px;">
            <div>
              <p class="small">Denotes required (*)</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="thinHr">

    <!-- Sub Header Row Desktop Begin -->
    <div class="layout-margin desktopStudentIDs" style="display: flex; flex-direction: row-reverse;">
      <div flex layout-gt-xs="row" style="padding-right: 20px;">
        <button (click)="openResetPasswordDialog($event)" class="md-button confirmButton"
          [disabled]="userDetailsForm.controls['disableUser'].value === 'Deactivated'">
          RESET PASSWORD
        </button>
      </div>
    </div>
    <div class="layout-margin desktopStudentIds" style="display: flex; flex-direction: row-reverse;">
      <div flex layout-gt-xs="row" style="padding-right: 14px;">
        <button (click)="openResetMfaDialog($event)" class="md-button confirmButton"
          [disabled]="isMFAConfigured === 'No' || userDetailsForm.controls['disableUser'].value === 'Deactivated'">
          RESET MFA
        </button>
      </div>
    </div>
    <!-- Sub Header Row Desktop End -->

    <div class="layout-margin userDetails">
      <form name="loginForm" [formGroup]="userDetailsForm" flex role="form">
        <fieldset [disabled]="disabledFlag" [ngClass]="{'disableForm': disabledFlag}">
          <!-- Section 1 Begin -->
          <div style="display: flex;"
            [ngClass]="{'layout-flex-row': (responsiveUI.isDesktop || responsiveUI.isTablet), 'layout-flex-column': responsiveUI.isMobile }">
            <div style="margin-top: 25px;"
              [ngStyle]="{'width': (responsiveUI.isMobile && responsiveUI.isPortrait) ? '100%' : '45%'}">
              <custom-mat-input customlabel="First Name" controlName="firstName" errorlength="{{errors.length}}"
                [isdisabled]=disabledFlag [isrequired]=true errormessage="Please provide a first name."
                formControlName="firstName">
              </custom-mat-input>
              <div>
                <custom-mat-input customlabel="Last Name" errorlength="{{errors.length}}" [isdisabled]=disabledFlag
                  [isrequired]=true
                  [showerror]="userDetailsForm.controls['lastName'].touched && userDetailsFormError('lastName', 'required')"
                  errormessage="Please provide a last name." formControlName="lastName">
                </custom-mat-input>
              </div>
              <div>
                <custom-mat-input customlabel="Email" errorlength="{{errors.length}}" [isdisabled]=disabledFlag
                  [isrequired]=true
                  [showerror]="userDetailsForm.controls['email'].touched && userDetailsFormError('email', 'required')"
                  errormessage="Please provide a valid email address." formControlName="email">
                </custom-mat-input>
              </div>
              <div style="display: flex; flex-direction: row;" class="flex-baseline">
                <div class="phone">
                  <custom-mat-input customlabel="Phone" errorlength="{{errors.length}}" [isdisabled]=disabledFlag
                    [isrequired]=true
                    [showerror]="userDetailsForm.controls['userPhone'].touched && userDetailsFormError('userPhone', 'required')"
                    errormessage="Please provide a phone number." formControlName="userPhone" style="flex: 65%">
                  </custom-mat-input>
                </div>
                <div style="display: flex; flex: 5%" class="flexCenter">
                  <p>x</p>
                </div>
                <div class="extension" [ngClass]="{'input-tablet-portrait': responsiveUI.isTablet && responsiveUI.isPortrait, 
                  'input-tablet-landscape': responsiveUI.isTablet && !responsiveUI.isPortrait}">
                  <custom-mat-input customlabel="Extension" errorlength="{{errors.length}}" [isdisabled]=disabledFlag
                    formControlName="userPhoneExt">
                  </custom-mat-input>
                </div>
              </div>
            </div>
            <span style="display: flex; flex: auto"></span>
            <div class="col1Right"
              [ngStyle]="{'width': (responsiveUI.isMobile && responsiveUI.isPortrait) ? '100%' : '45%'}">
              <div layout="column">
                <div style="margin-bottom: 20px;">
                  <div class="flex-inline accountStatus">
                    <p style="flex:none; width:30%">Account Status</p>
                    <p style="flex: auto;">{{userDetailsForm.controls['disableUser'].value}}</p>
                  </div>
                  <div class="flex-inline accountStatus">
                    <p style="flex:none; width:30%">Multi-Factor Authentication has been configured: </p>
                    <p style="flex: auto;">{{isMFAConfigured}}</p>
                  </div>
                  <p class="labelHeader">User Administrators may manually disable or deactivate user accounts. User
                    accounts become active once the user has saved a new password to the account.
                    Resetting passwords will force users to set or review challenge questions on their MSIX account.</p>
                  <mat-radio-group formControlName="disableUser" class="flex-inline" style="margin-left: -10px;">
                    <mat-radio-button value="Disabled" style="padding-right: 45px;">
                      <p>Disable</p>
                    </mat-radio-button>
                    <mat-radio-button value="Deactivated" flex-offset="10">
                      <p>Deactivate</p>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="passwordReset" *ngIf="responsiveUI.isDesktop">
                  <p class="labelHeader" style="padding-bottom: 5px; z-index: 1; position: relative;">
                    Active Dates (Format: MM/DD/YYYY)
                  </p>
                  <div class="flex-inline activeDates">
                    <custom-mat-input style="height: 85px;" customlabel="Active From" errorlength="{{errors.length}}"
                      [isdisabled]=disabledFlag formControlName="activeDate">
                    </custom-mat-input>
                    <i class="fa fa-minus" style="display: flex;" aria-hidden="true"></i>
                    <custom-mat-input style="height: 85px;" customlabel="Active To" errorlength="{{errors.length}}"
                      [isdisabled]=disabledFlag formControlName="expirationDate">
                    </custom-mat-input>
                  </div>
                  <p style="padding-top: 5px;"><i>Optional: Specify dates to limit application access</i></p>
                </div>
                <div class="loginDetails" *ngIf="responsiveUI.isDesktop">
                  <div class="flex-inline">
                    <p class="labelHeader" style="width: 30%">Rules of Behavior</p>
                    <p class="data">{{formData.robDate}}</p>
                    <p class="labelHeader">&nbsp;(last accepted)</p>
                  </div>
                  <div class="flex-inline">
                    <p class="labelHeader" style="width: 30%">Active Since</p>
                    <p class="data">{{formData.firstLogin}}</p>
                  </div>
                  <div class="flex-inline">
                    <p class="labelHeader" style="width: 30%">Last Login</p>
                    <p class="data">{{formData.lastLogin}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- User Roles Section Begin -->
          <div class="regions" *ngIf="responsiveUI.isDesktop">
            <div class="flex-inline">
              <p>User Role(s)</p>
              <p><span class="required-asterisk">&nbsp;*</span></p>
            </div>
            <hr class="thinHr">
            <div class="layout-row userRoles" style="flex-wrap:wrap; display:flex">
              <div flex="25" *ngFor="let role of rolesArrayWithSelection" style="display:flex; flex:25%; height: 50%">
                <mat-checkbox [checked]="role.selected" (change)="addRemoveRole($event, role, 'update')"
                  [disabled]="checkRoleDisabled(role)" aria-label="role" style="height: 50%">
                  <p *ngIf="role.role=='MSIX Primary User'">Primary User</p>
                  <p *ngIf="role.role!='MSIX Primary User'">{{role.role}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <!-- User Roles Section End -->
          <!-- Job Titles Section Begin -->
          <div class="regions" *ngIf="responsiveUI.isDesktop" style="margin-bottom: 35px;">
            <div class="flex-inline">
              <p>Job Title</p>
              <p><span class="required-asterisk">&nbsp;*</span></p>
            </div>
            <hr class="thinHr">
            <div class="layout-row userRoles" style="flex-wrap:wrap; display:flex">
              <div *ngFor="let job of jobsArray" [ngClass]="job.jobTitleDesc === 'Other' ? 'flex-75' : 'flex-25'">
                <div *ngIf="job.jobTitleDesc !== 'Other'">
                  <mat-checkbox [checked]="job.selected" (change)="addRemoveJob($event, job)" [disabled]="disabledFlag"
                    aria-label="job">
                    <p>{{job.jobTitleDesc}}</p>
                  </mat-checkbox>
                </div>
                <div layout="column" *ngIf="job.jobTitleDesc === 'Other'" style="display: grid;">
                  <mat-checkbox [checked]="job.selected" (change)="addRemoveJob($event, job)" [disabled]="disabledFlag"
                    aria-label="job" style="margin-bottom: 5px;">
                    <p>{{job.jobTitleDesc}}: Please Specify</p>
                  </mat-checkbox>
                  <input matInput (change)="updateOtherJobTitle($event, job)" class="other-input"
                    [(ngModel)]="otherJobTitle" [ngModelOptions]="{standalone: true}"
                    [maxLength]="100"
                    [disabled]="!job.selected || disabledFlag" type="text" name="otherJobTitle">
                </div>
              </div>
            </div>
          </div>
          <!-- Job Titles Section End -->
          <!-- Section 1 End -->
          <!-- Section 2 Begin -->
          <div class="address" *ngIf="responsiveUI.isDesktop">
            <p>Work Address</p>
            <hr class="thinHr">
            <div layout-gt-xs="row" style="display:flex" class="main">
              <div style="width:45%" flex-xs="100">
                <div class="custom-input-container userDetails-Address">
                  <mat-form-field floatLabel="always">
                    <mat-label class="custom-label" for="addLine1Input">Address, line 1</mat-label>
                    <input matInput class="custom-input mat-input-has-value" formControlName="addLine1"
                      name="addLine1Input" [disabled]="disabledFlag"
                      [ngClass]="{'custom-input-disabled': disabledFlag == true}">
                  </mat-form-field>
                </div>
                <div class="custom-input-container userDetails-Address">
                  <mat-form-field floatLabel="always">
                    <mat-label class="custom-label" for="addLine2Input">Address, line 2</mat-label>
                    <input matInput class="custom-input mat-input-has-value" formControlName="addLine2"
                      name="addLine2Input" [disabled]="disabledFlag"
                      [ngClass]="{'custom-input-disabled': disabledFlag == true}">
                  </mat-form-field>
                </div>
                <div class="custom-input-container userDetails-Address">
                  <mat-form-field floatLabel="always">
                    <mat-label class="custom-label" for="addCityInput">City</mat-label>
                    <input matInput class="custom-input mat-input-has-value" formControlName="addCity"
                      name="addCityInput" [disabled]="disabledFlag"
                      [ngClass]="{'custom-input-disabled': disabledFlag == true}">
                  </mat-form-field>
                </div>
                <div class="custom-input-container userDetails-state" style="padding-top: 13px; margin-right: 10px">
                  <mat-form-field style="width: 99.5%; border: 2px solid; height: 35px; padding-bottom: 5px"
                    [ngClass]="(disabledFlag == true) ? 'custom-input-disabled' : 'custom-input'">
                    <mat-label class="custom-label" style="margin-top: -5px; margin-left: 14px" for="stateInput">State
                    </mat-label>
                    <mat-select formControlName="userState" name="stateInput"
                      style="padding-left: 12px; padding-top: 4px;" [disabled]="disabledFlag">
                      <mat-option value="-1">No State</mat-option>
                      <mat-option *ngFor="let state of states" [value]="state.stateKey">
                        {{state.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <span flex></span>
            </div>
          </div>
          <!-- Section 2 End -->
          <!-- Regions Section Begin -->
          <div class="regions"
            *ngIf="responsiveUI.isDesktop && (hasRole('Regional User Administrator') || hasRole('Regional Data Administrator'))">
            <div class="flex-inline">
              <p>Assigned Region(s)</p>
              <p class="required-asterisk">&nbsp;*</p>
            </div>
            <hr class="thinHr">
            <div class="userRoles layout-flex-row">
              <p *ngIf="stateRegions.length === 0">
                Your selected state does not have any regions
              </p>
              <div style="display: flex; flex-wrap: wrap;">
                <mat-checkbox style="flex: 25%;" *ngFor="let region of stateRegions"
                  [checked]="isAssignedRegion(region)" (click)="addRegionOrDistrict(region, formData.regions)"
                  aria-label="region">
                  <p>{{region.name}}</p>
                </mat-checkbox>
              </div>
            </div>
          </div>
          <!-- Regions Section End -->
          <!-- Districts Section Begin -->
          <div class="regions"
            *ngIf="responsiveUI.isDesktop && userShowDistricts && hasRole('District Data Administrator')">
            <div class="flex-inline">
              <p>Assigned District(s)</p>
              <p class="required-asterisk">&nbsp;*</p>
            </div>
            <hr class="thinHr">
            <div layout="row" layout-wrap flex class="userRoles districtSection">
              <p *ngIf="districtsArray.length ===0">
                Your selected state does not have any districts
              </p>
              <mat-checkbox style="width: 25%;" *ngFor="let district of districtsArray | sortBy:'asc':'name'"
                [checked]="isAssignedDistrict(district)"
                (click)="!isSuperUser && addRegionOrDistrict(district, formData.districts)" aria-label="region"
                [disabled]="isSuperUser">
                <p>{{district.name}}</p>
              </mat-checkbox>
            </div>
          </div>
          <!-- Districts Section End -->
          <!-- Section 3 Begin -->
          <div flex [ngClass]="{'buttonSection' : !responsiveUI.isMobile}">
            <hr class="thinHr">
            <!-- [disabled]="userDetailsForm.pristine && (userDetailsForm.controls['disableUser'].value != 'Disabled' && userDetailsForm.controls['disableUser'].value != 'Deactivated')" -->
            <div class="buttons" [ngClass]="{'float-right' : !responsiveUI.isMobile, 'center' : responsiveUI.isMobile}"
              style="display: flex; align-items: center; padding-right: 20px;">
              <a flex (click)="cancel($event)" *ngIf="!disabledFlag"
                [ngClass]="{'aDisabled': (userDetailsForm.pristine && (!roleFlag) && isJobTitleSelectionValid())}"
                style="margin-right: 10px;">
                <span>Cancel</span>
              </a>
              <button flex (click)="updateUser($event)" class="md-button confirmButton" [disabled]="(userDetailsForm.status != 'VALID') || 
                isJobTitleNotSelected() ||
                (userDetailsForm.pristine && !roleFlag && isJobTitleSelectionValid() 
                  && (userDetailsForm.controls['disableUser'].value != 'Disabled' || userDetailsForm.controls['disableUser'].value != 'Deactivated') 
                  && areDistrictRegionSelectionValid() && !hasRegionOnDistrictTouched)">
                UPDATE RECORD
              </button>
            </div>
          </div>
          <!-- Section 3 End -->
        </fieldset>
      </form>
    </div>

  </div>

</div>