<div class="admin-search-results-page background-awesome" style="display: flex; flex-direction: row" layout-align="center start">
  <!-- TODO - Need to figure out flex stuff for different size screens-->
  <!-- <div class="md-content flex-child-login pwReset-container-max-height" flex-xs="95" flex-sm="50" flex-md="35" flex-lg="30" flex-xl="15" layout-margin> -->
  <div class="md-content flex-child-login pwReset-container-max-height"
  [ngClass]="{'admnSrchRsltBxDsktp': responsiveUI.isDesktop, 'admnSrchRsltBxTbltPrtrt': (responsiveUI.isTablet && responsiveUI.isPortrait), 'admnSrchRsltBxTbltLndscp': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'admnSrchRsltBxMblPrtrt': (responsiveUI.isMobile && responsiveUI.isPortrait), 'admnSrchRsltBxMblLndscp': (responsiveUI.isMobile && !responsiveUI.isPortrait)}">

    <h1 style="margin-bottom: 11px !important;">{{header}}</h1>
    <a style="margin-bottom: 11px !important;" class="back-link" tabindex="0" (click)="goBack()" (keypress)="goBack()"><i class="fa fa-chevron-left"></i><span>Search Again</span></a>
    <p id="h1Details" *ngIf="header === 'Resetting Your Password'">For a password reset, please contact your designated MSIX State Contact.</p>
    <br>
    <h3 style="margin-bottom: 11px !important;">{{adminList.length}} State Contacts Found</h3>

    <!-- TODO - Need to figure out hte mdMedia stuff -->
    <!-- <hr class="blackHr" *ngIf="$ctrl.$mdMedia('gt-xs')">
    <hr class="thickHr" *ngIf="$ctrl.$mdMedia('xs')"> -->
    <!-- <hr class="blackHr" *ngIf="$ctrl.$mdMedia('gt-xs')"> -->
    <hr class="blackHr thickHr">

    <div *ngFor="let admin of adminList | sortBy:'asc':'firstName'" [value]="admin.name" class="adminRow">
      <div class="flexSpaceBetween">
        <div>
          <h3>{{admin.firstName}}&nbsp;{{admin.lastName}}</h3>
        </div>
        <!-- TODO - Need to figure out mdMedia stuff -->
        <!-- <div *ngIf="$ctrl.$mdMedia('xs')">
          <a class="mailTo" href="mailto:{{admin.email}}?Subject=MSIX%20Password%20Reset%20Request"><i class="fa fa-envelope fa-lg" aria-hidden="true"></i></a>
          <a href="tel:{{admin.userPhone}}"><i class="fa fa-phone fa-lg" aria-hidden="true"></i></a>
        </div> -->
      </div>
      <hr class="thinHr">
      <!-- <div *ngIf="$ctrl.$mdMedia('gt-xs')" class="contactRow"> -->
      <div class="contactRow">
        <div style="display: flex; flex-direction: row" class="flex-inline">
          <a styles="display:flex; flex: 10%" href="mailto:{{admin.email}}?Subject=MSIX%20Password%20Reset%20Request"><i style="margin-right: 15px;" class="fa fa-envelope fa-lg" aria-hidden="true"></i></a>
          <p class="small">{{admin.email}}</p>
        </div>
        <div style="display: flex; flex-direction: row" class="flex-inline">
          <a styles="display:flex; flex: 10%" href="tel:{{admin.userPhone}}"><i style="margin-right: 19px;" class="fa fa-phone fa-lg" aria-hidden="true"></i></a>
          <p class="small">{{admin.userPhone}}</p>
        </div>
      </div>
      <!-- TODO - Need to figure out mdMedia -->
      <!-- <hr class="blackHr" *ngIf="$ctrl.$mdMedia('gt-xs')"> -->
      <hr class="blackHr">
    </div>
  </div>
</div>
