<div class="recordsExchange">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
        margin-right: 0;">        
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.dataAdmins">
        <b>Top 10 Records Exchange States</b><br />
        <br />
        This chart shows your top 10 partner States based on the number of migratory children making qualifying
        moves in the selected performance period. Use this chart to see your State’s interstate coordination
        efforts with these States by analyzing key indicators of interstate coordination. These key indicators
        include (read from left to right) students records viewed, move notices sent/received, and data requests
        sent/received. Data in this chart is refreshed on the first day of every month.<br />
        <br />
        “Children Moving/To From” refers to the number of children that made a qualifying move that generated
        a QAD to or from your State and the partner State in the selected performance period.<br />
        <br />
        “Student Records Viewed” refers to the number of unique consolidated student records with State IDs in
        the partner State that MSIX users in your State viewed in MSIX in the selected performance period.<br />
        <br />
        “Move Notices Sent/Received” refers to the number of Move Notices your State sent to or received from
        the partner State in the selected performance period.<br />
        <br />
        “Data Requests Sent/Received” refers to the number of Data Requests your State sent to or received from
        the partner State in the selected performance period.<br />
        <br />
        <b>To export the data, select “Export” and then open the downloaded zip file to select the dataset you would like to view.</b><br />
        <br />
        <br />
        <b>Data Request Worklist Aging</b><br />
        <br />
        Use this chart to understand how your State addresses Data Requests received for each month of the
        performance period and your State’s compliance with the MSIX Regulations. Data in this chart is
        refreshed one day after the status of the Data Request changes.<br />
        <br />
        To determine compliance with the MSIX Regulations, this chart refers to MSIX Regulation
        <i>200.85(b)(3)(iii)(A)</i>, which requires that Data Requests receive a response within
        four working days of receipt.
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>Top 10 Records Exchange States</b><br />
        <br />
        This chart shows the top 10 States based on the number of migratory children making qualifying 
        moves in the selected performance period. Use this chart to see State interstate coordination 
        efforts by analyzing key indicators of interstate coordination. These key indicators include student 
        records viewed, data requests sent/received, and move notices sent/received. Data in this chart will be 
        refreshed on the first day of every month.<br />
        <br />
        “Children Moving/To From” refers to the number of children that made a qualifying move that generated
        a QAD to or from the State in the selected performance period.<br />
        <br />
        “Student Records Viewed” refers to the number of unique consolidated student records with State IDs in
        partner States that MSIX users in that State viewed in MSIX in the selected performance period.<br />
        <br />
        “Move Notices Sent/Received” refers to the number of Move Notices that State sent to or received from
        partner States in the selected performance period.<br />
        <br />
        “Data Requests Sent/Received” refers to the number of Data Requests the State sent to or received
        from partner States in the selected performance period.<br />
        <br />
        <b>To export the data, select “Export” and then open the downloaded zip file to select the dataset you 
        would like to view.</b><br />
        <br />
        <br />
        <b>Data Request Worklist Aging</b><br />
        <br />
        Use this chart to understand how all States address Data Requests received for each month of the
        performance period and compliance with the MSIX Regulations. Data in this chart will be refreshed
        one day after the status of the Data Request changes.<br />
        <br />
        To determine compliance with the MSIX Regulations, this chart refers to MSIX Regulation
        <i>200.85(b)(3)(iii)(A)</i>, which requires that Data Requests receive a response within
        four working days of receipt.
      </p>
    </div>
  </div>
</div>