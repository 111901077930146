<div class="childMobility">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
      margin-right: 0;">
          <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <p *ngIf="data.params =='moveFromMap' || data.params =='moveToMap'">
        Click on the MSIX ID to view a child’s Consolidated Student Record.<br />
        <br />
        Use the Export function to download a copy of the report in CSV format.<br />
        <br />
        Use the breadcrumbs at the top of the page to return to the main Child Mobility Dashboard.
      </p>
      <p *ngIf="data.params =='qadEnroll'">
        Click on the MSIX ID to view a child’s Consolidated Student Record.<br />
        <br />
        Use the Export function to download a copy of the report in CSV format.<br />
        <br />
        Use the breadcrumbs at the top of the page to return to the main Child Mobility Dashboard.
      </p>
    </div>
  </div>
</div>