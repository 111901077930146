<form (ngSubmit)="onSubmit()">
  <div class="custom-mat-input-component-for-filter custom-input-container center top-input-padding"
    [ngClass]="{'date-input': (nondateinput==false)}">
    <mat-form-field [ngClass]="{'mff-input-has-value': searchTextValue}">
      <mat-label class="custom-label-for-filter"
        [ngClass]="{'label-error' : errorlength > 0, 'box-selected': inputField.focused}" attr.for="{{inputFieldID}}">
        {{customlabel}}</mat-label>
      <input matInput #inputField="matInput" class="custom-input-for-filter" type="text" [(ngModel)]="searchTextValue"
        attr.name="{{inputFieldName}}" attr.id="{{inputFieldID}}" (input)="onValueChanged($event)" 
        [ngClass]="{'input-error' : errorlength > 0,'mat-input-has-value': searchTextValue, 'non-date-input': (nondateinput==true), 
        'disabled': inputdisabled}" maxlength="{{maxLength}}" [ngModelOptions]="{standalone: true}" #filterInput>
    </mat-form-field>
  </div>
</form>