<div class="reportsFilter learnMoreModal">
  <div aria-label="Account List Learn More" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn more</h2>
      <span style="flex: 1; box-sizing: border-box;"></span>
      <button class="md-icon-button md-button" mat-dialog-close (click)="closeDialog()" aria-label="close dialog">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-column" flex>
        <p>Use this report to monitor key MSIX usage metrics for users in your State.<br/>
          <br/>
          Use the Filter function to set a custom date range, search for a specific user, or to filter the list 
          of users by User Status or User Roles.<br/>
          <br/>
          Select the value for Consolidated Student Records Downloaded associated with a specific user to view 
          a list of Consolidated Student Records downloaded by the user.<br/>
          <br/>
          Select the value for Data Requests or Move Notices initiated or responded to for a specific user to 
          see details about the Data Request or Move Notice worklists the user performed actions on.<br/>
          <br/>
          Use the Export function to download a copy of the report in CSV format.<br/>
          <br/>
          Use the breadcrumbs at the top of the page to return to the main reports page.
        </p>
      </div>
    </div>
  </div>
</div>