<div class="msixUsage">
  <div class="breadcrumb layout-row">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p>User Page Counts</p>
    </span>
  </div>

  <div class="header layout-row">
    <h1 class="header-padding flex-90">User Page Counts</h1>
    <export-report class="flex-10" tabindex="0"
      [exportlink]="exportCall" 
      [exportfilter]="filterParameters" 
      [showcsv]="true"
      [showpdf]="true"
      [blankres]="blankRes">
    </export-report>
  </div>

  <hr layout-padding class="thickHr">

  <div class="layout-row layout-align-center-center">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p>
          Counts of hits by visitors on each area of MSIX
        </p>
      </div>
      <filter-button (activateFilter)="filterActivate()"></filter-button>
      <reports-table *ngIf="!blankRes" 
        [responsedata]="response" 
        [isreportlevelspecialsortinit]="isInit"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="sortAscending"
        [shownavigation]="false"
        (sortbycolumn)="sortByColumn($event)">
      </reports-table>
      <no-results *ngIf="blankRes"></no-results>
    </div>
  </div>
</div>