<div class="studentAchievement">
  <div aria-label="Filter Student Count" class="filter-dialog">
    <div class="md-toolbar-tools toolbar">
      <h2 class="filter-title">Learn More</h2>
      <span flex></span>
      <button class="md-icon-button md-button" mat-dialog-close aria-label="close dialog" style="margin-left: auto; 
        margin-right: 0;">        
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content learnMoreScrollable">
      <p *ngIf="authRoleClasses.dataAdmins">
        <b>Migratory Graduation/HSE</b><br/>
        <br/>
        This chart displays the percentage of 12<sup>th</sup> grade migratory children in your State who graduated or received 
        a High School Equivalency (HSE) in each of the last 5 performance periods. An HSE is a recognized alternative 
        to a high school diploma that meets State guidelines. This chart also allows you to compare your State’s graduation 
        and HSE rates to the national average. Data in this chart is refreshed on the first day of the month.<br/>
        <br/>
        <b>Migratory Children Enrolling in Grade 11 with Credit for Algebra 1</b><br/>
        <br/>
        This chart displays the total number of new Grade 11 enrollments by migratory children in your State each month for 
        the selected performance period and how many of those migratory children received Algebra 1 or equivalent credit on 
        an enrollment from Grades 7 to 10. Credit for Algebra 1 or equivalent is determined by evaluating all Grade 7 to 10 
        enrollments for a “Yes” value on the Algebra 1 or Equivalent Indicator. Any State may have submitted the Algebra 1 or 
        Equivalent Indicator value. Data in this chart is refreshed on the first day of the month.
      </p>
      <p *ngIf="authRoleClasses.dataDashboards">
        <b>Migratory Graduation/HSE</b><br/>
        <br/>
        This chart displays the percentage of 12<sup>th</sup> grade migratory children nationwide who graduated or received a High 
        School Equivalency (HSE). An HSE is a recognized alternative to a high school diploma that meets State guidelines. 
        Filtering this chart by State allows you to compare a State’s graduation and HSE rates to the national average. 
        Data in this chart is refreshed on the first day of the month.<br/>
        <br/>
        <b>Migratory Children Enrolling in Grade 11 with Credit for Algebra 1</b><br/>
        <br/>
        This chart displays the total number of new Grade 11 enrollments by migratory children nationally in each month for the 
        selected performance period and how many of those migratory children received Algebra 1 or equivalent credit on an 
        enrollment from Grades 7 to 10. Credit for Algebra 1 or equivalent is determined by evaluating all Grade 7 to 10 
        enrollments for a “Yes” value on the Algebra 1 or Equivalent Indicator. Filtering this chart by State allows you 
        to see this data on a State level. Data in this chart is refreshed on the first day of the month.
      </p>
    </div>
  </div>
</div>