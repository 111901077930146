import { Component } from '@angular/core';
import { ProgressService } from '../../services/progress.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    progressActivated$: Observable<boolean>;
    constructor(private progressService: ProgressService) {
        this.progressActivated$ = this.progressService.progressActivated$
    }

    
}