<div class="reportsFilter filterDataLogic">
  <div aria-label="Filter Data Logic" class="filter-dialog">
    <div style="flex-direction: column-reverse; display: flex;">

      <div class="layout-row" style="padding: 3px 30px; align-items: center">
        <a class="resetButton" tabindex="0" (keypress)="resetFilter()" (click)="resetFilter()">
          Reset Filters
        </a>
        <span style="flex: 1; box-sizing: border-box"></span>
        <button (click)="onSubmit()" type="submit" class="confirmButton md-button">
          SUBMIT
        </button>
      </div>

      <div class="md-dialog-content" [ngClass]="{'height-185': ((filterParameters.dataViewType != 'State' || 
        !filterParameters.authRoleClasses.dataQuality) && filterParameters.noyes=='yes'), 'height-120': 
        ((filterParameters.dataViewType != 'State' || !filterParameters.authRoleClasses.dataQuality) && filterParameters.noyes=='no')}">
        <div class="layout-row filter-section">
          <div class="flex-40">
            <p class="filterHeader">
              PERFORMANCE PERIOD FILTER:
            </p>
          </div>
          <div class="layout-column flex-60" style="margin-top: -10px">
            <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.noyes">
              <mat-radio-button value="no" (click)="togglePeriodNo()" style="width: 30%">
                <p>No</p>
              </mat-radio-button>
              <mat-radio-button value="yes" (click)="togglePeriodYes()">
                <p>Yes</p>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="layout-row filter-section" *ngIf="filterParameters.noyes=='yes'">
          <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.dataPeriodType">
            <mat-radio-button value="Most Recently Closed Period">
              <p>Last performance period <br />{{filterParameters.recentPeriod}}</p>
            </mat-radio-button>
            <mat-radio-button value="Current Period">
              <p>Current performance period <br />{{filterParameters.currentPeriod}}</p>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <hr layout-padding class="thickHr">
        <div class="layout-row filter-section">
          <div class="layout-column flex-40">
            <p class="filterHeader">
              DRILL DOWN LEVEL:
            </p>
          </div>
          <div class="layout-column flex-60" style="margin-top: -10px">
            <mat-radio-group class="layout-row" [(ngModel)]="filterParameters.dataViewType">
              <mat-radio-button value="National" (click)="selectAll()" style="width: 30%">
                <p>National</p>
              </mat-radio-button>
              <mat-radio-button *ngIf="!filterParameters.authRoleClasses.dataQuality" 
                (click)="setSelectedStates()" value="State">
                <p>{{filterParameters.userStateName}}</p>
              </mat-radio-button>
              <mat-radio-button *ngIf="filterParameters.authRoleClasses.dataQuality" 
                (click)="selectAll()" value="State">
                <p>State</p>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="layout-row" flex
          *ngIf="filterParameters.authRoleClasses.dataQuality && filterParameters.dataViewType=='State'">
          <div class="layout-column" flex>
            <div class="layout-row selectButtons">
              <p>Select</p>
              <a (click)="selectAll()">All</a>
              <p>|</p>
              <a (click)="selectNone()">None</a>
            </div>
            <div class="colPOC layout-wrap layout-row">
              <mat-checkbox md-no-ink (click)="checkedStates(state.stateKey)"
                *ngFor="let state of filterParameters.states" class="flex-30"
                [checked]="existsInSelectedStatesArray(state.stateKey)">
                <p>{{state.name}}</p>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="md-toolbar-tools toolbar">
        <h2 class="filter-title">Data Logic Filter</h2>
        <span style="flex: 1; box-sizing: border-box;"></span>
        <button class="md-icon-button md-button" (click)="closeDialog()" aria-label="close dialog">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>