<!-- Possibly not used anywhere -->
<div class="savedList-page"
  [ngClass]="{'main-padding' : responsiveUI.isDesktop, 'main-border-mobile main-padding-mobile' : (responsiveUI.isMobile || responsiveUI.isTablet)}">

  <div *ngIf="responsiveUI.isDesktop" class="layout-row">
    <h1 flex class="mainTitle flex-60">My Lists</h1>
    <button tabindex="0" class="md-button newListButton flex-20" type="button" (click)="shareMyList()">
      Share
    </button>
    <button tabindex="0" class="md-button newListButton flex-21" style="margin-left:10px" type="button" (click)="openCreateListDialog()">
      Create New List
    </button>
  </div>
  <div *ngIf="responsiveUI.isTablet" class="layout-row dashboard-module-title-background">
    <h3 class="dashboard-module-title-text flex-80" style="height:35px; padding-left:17px;"
      layout-align="start center">My Lists</h3>
  </div>
  <hr *ngIf="responsiveUI.isDesktop || responsiveUI.isTablet" class="thickHr worklistHr">

  <div layout-margin>
    <h3 *ngIf="savelists.length == 0">No items in your saved list</h3>

    <!--MOBILE ONLY-->
    <div class="dashboard-module" *ngIf="responsiveUI.isMobile && savelists.length > 0">
      <div layout-padding class="dashboard-module-title-background layout-row">
        <h1 hide-gt-xs class="h1-no-margins no-padding flex-80">My Lists</h1>
        <div class="folderPlus right-align">
          <i class="fa fa-folder flex-20" style="font-size: 25px;"></i>
          <div class="overlayPlus">
            <i class="fa fa-plus" (click)="openCreateListDialog()" ng-touch="openCreateListDialog()"></i>
          </div>
        </div>
      </div>

      <div layout-padding class="dashboard-module-body" hide-gt-xs>

        <!--mobile savedList-->
        <div class="top-bottom-margin-8 no-padding" *ngIf="savelists.length > 0">

          <div class="layout-padding-l layout-padding-r layout-padding-bottom-15">
            <p>The following student records have been added to lists.
              To edit or remove which lists the student record has been added to, select the “Edit/Remove” button for
              that student record.
              To create a new list, select the “Create New List” button.
              Lists expire after 455 days of inactivity.
              To send a multi-move notice, expand a list and select the student records that should be added to the
              multi-move notice.
              Once the student records have been selected, select “Send Multi-Move Notice” on that list.
              Student records must be selected from the same list to be sent on a single multi-move notice.
              To share a list with other MSIX users, select the “Share” button.
              Sharing a list and any subsequent actions by the list receiver will not affect the student records saved
              to your list.
              Lists that have been shared with you will appear under the Shared With Me header for you to Accept or
              Delete.
            </p>
          </div>

          <!-- List Title-->
          <div>
            <div class="customTabs layout-row savelists">
              <div class="layout-row flex-75">
                <h3 class="save-list-description worklist-description-text listTitle">
                  Shared With Me
                </h3>
                <block class="tabCount flex-container listCount">
                  {{sharedLists.length}}
                </block>
              </div>
              <i *ngIf="showSharedWithMe && sharedLists.length > 0" (click)="showSharedWithMe = !showSharedWithMe"
                class="fa fa-chevron-up accordion-icon ng-scope collapseList flex-15"></i>
              <i *ngIf="!showSharedWithMe && sharedLists.length > 0" (click)="showSharedWithMe = !showSharedWithMe"
                class="fa fa-chevron-down accordion-icon ng-scope collapseList flex-15"></i>
            </div>
            <div *ngIf="showSharedWithMe && sharedLists.length > 0" class="results-border top-bottom-padding-10">
              <div *ngFor="let list of sharedLists" class="layout-row">
                <div flex>
                  <p class="small small-grey2andahalf uppercase">{{list.sharedSaveListName}} |
                    {{list.sharedByUser.lastName}}, {{list.sharedByUser.firstName}}, {{list.sharedByUser.email}},
                    {{list.sharedByUser.userStateName}} | {{list.sharedOnDate}}</p>
                </div>
                <div flex="10" layout-align="end center">
                  <i class="fa fa-trash"
                    (click)="deleteSharedListFromMobile(list.sharedSaveListName, list.sharedSaveListKey, list.sharedByUser)"
                    ng-touch="deleteSharedListFromMobile(list.sharedSaveListName, list.sharedSaveListKey, list.sharedByUser)"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="layout-padding-l layout-padding-r customTabs" *ngFor="let list of savelists">
            <div class="desktopEnrollments removeBorders">
              <div class="tableRow headerRow no-padding layout-row">
                <div class="layout-row flex-75">
                  <h3 class="save-list-description worklist-description-text listTitle">{{list.saveListName}}
                  </h3>
                  <block class="tabCount flex-container listCount">
                    {{list.listStudents.length}}
                  </block>
                </div>

                <div>
                  <i class="fa fa-trash" *ngIf="list.saveListName !== 'My Default List'"
                    (click)="deleteListInMobile(list.saveListKey)" ng-touch="deleteListInMobile(list.saveListKey)"
                    flex="10"></i>
                  <div *ngIf="list.saveListName === 'My Default List'" flex="10"></div>
                  <i *ngIf="list.showList" (click)="list.showList = !list.showList"
                    class="fa fa-chevron-up accordion-icon ng-scope collapseList" flex="15"></i>
                  <i *ngIf="!list.showList" (click)="list.showList = !list.showList"
                    class="fa fa-chevron-down accordion-icon ng-scope collapseList" flex="15"></i>
                </div>
              </div>
            </div>

            <!--List's days to expire-->
            <div *ngIf="list.saveListName !== 'My Default List'" class="col1" flex="30">
              <div class="top-bottom-margin-8">
                <p class="daysToExpire"><b>Expires in:</b> {{list.daysToExpire}} days</p>
              </div>
            </div>

            <div *ngIf="list.showList" class="results-border top-bottom-padding-10">
              <div *ngFor="let student of list.listStudents">
                <div class="layout-row">
                  <div class="flex-90 layout-column">
                    <a class="uppercase" (click)="viewDetails(student)">{{student.stuName}}</a>
                    <div flex>
                      <p class="small small-grey2andahalf uppercase">
                        State: {{student.state}} | Sex: {{student.gender}} | DOB: {{student.dob}} | ID:
                        {{student.msixId}} |
                        <span class="small uppercase"> State ID: {{student.stateId}}</span>
                      </p>
                    </div>
                  </div>
                  <!-- ToDo -->
                  <div class="flex-10 layout-row layout-align-end-center">
                    <i class="fa fa-times-circle" id="mobileTabletRemoveButton"
                      (click)="removeStudentInMobile(student.msixId, list.saveListKey)"
                      ng-touch="removeStudentInMobile(student.msixId, list.saveListKey)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!--DESKTOP AND TABLET-->
    <div *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet) && savelists.length > 0" class="saveList-Desktop-Box">

      <div class="background-white">
        <h3 *ngIf="responsiveUI.isTablet" class="dashboard-module-title-text layout-row flex-80" style="height:35px; padding-left:17px;" layout-align="start center">
          My Lists</h3>
        <div class="desktopEnrollments removeBorders">
          <p class="save-list-description worklist-description-text">
            The following student records have been added to lists.
            To edit or remove which lists the student record has been added to, select the “Edit/Remove” button for that
            student record.
            To create a new list, select the “Create New List” button.
            Lists expire after 455 days of inactivity.
            To send a multi-move notice, expand a list and select the student records that should be added to the
            multi-move notice.
            Once the student records have been selected, select “Send Multi-Move Notice” on that list.
            Student records must be selected from the same list to be sent on a single multi-move notice.
            To share a list with other MSIX users, select the “Share” button.
            Sharing a list and any subsequent actions by the list receiver will not affect the student records saved to
            your list.
            Lists that have been shared with you will appear under the Shared With Me header for you to Accept or
            Delete.
          </p>
        </div>
        <!--Display these buttons only in tablet mode-->
        <div class="layout-row" *ngIf="responsiveUI.isTablet">
          <div flex></div>
          <button tabindex="0" class="md-button mobile-newListButton right-align" type="button" flex="20" (click)="shareMyList()">Share</button>
          <button tabindex="0" class="md-button mobile-newListButton right-align" type="button" style="margin-left: 10px" (click)="openCreateListDialog()">
            Create New List
          </button>
        </div>

        <!-- Shared with me Section -->
        <div>
          <div class="customTabs layout-row savelists">
            <div class="layout-row flex-90">
              <h3 class="save-list-description worklist-description-text listTitle">Shared With Me</h3>
              <block class="tabCount flex-container listCount">
                {{sharedLists.length}}
              </block>
            </div>
            <i tabindex="0" *ngIf="showSharedWithMe && sharedLists.length > 0" (click)="showSharedWithMe = !showSharedWithMe" (keypress)="showSharedWithMe = !showSharedWithMe"
              class="fa fa-chevron-up accordion-icon ng-scope collapseList flex-10 align-right"></i>
            <i tabindex="0" *ngIf="!showSharedWithMe && sharedLists.length > 0" (click)="showSharedWithMe = !showSharedWithMe" (keypress)="showSharedWithMe = !showSharedWithMe"
              class="fa fa-chevron-down accordion-icon ng-scope collapseList flex-10 align-right"></i>
          </div>
          <!--Shared With Me table headers-->
          <div *ngIf="showSharedWithMe && sharedLists.length > 0"
            class="worklist-header-titles left-right-margin-8 left-padding-6 flex-100">
            <div class="flex-container flex-90">
              <block2 class="flex-30">List Name
                <!--<i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'LIST_NAME' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'LIST_NAME' && !list.sortAscending"></i>-->
              </block2>
              <block2 class="flex-30">List Sent By
                <!--<i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'LIST_SENT_BY' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'LIST_SENT_BY' && !list.sortAscending"></i>-->
              </block2>
              <block2 class="flex-20">Sent Date
                <!--<i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'SENT_DATE' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'SENT_DATE' && !list.sortAscending"></i>-->
              </block2>
              <block2 class="flex-20">Comments
                <!--<i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'COMMENTS' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'COMMENTS' && !list.sortAscending"></i>-->
              </block2>
            </div>
            <span class="flex-10"></span>
          </div>


          <!--List's saved -->
          <div *ngIf="showSharedWithMe" layout-align="center center" class="table2Row">
            <div *ngFor="let list of sharedLists" class="layout-row">
              <div class="background-white flex-90">
                <div class="layout-row section1">
                  <div class="layout-row flex-container flex-100">
                    <p class="flex-30 boldSaveListName">{{list.sharedSaveListName}}</p>
                    <p class="flex-30 breakLongWords">{{list.sharedByUser.lastName}},
                      {{list.sharedByUser.firstName}}<br>{{list.sharedByUser.email}}<br>{{list.sharedByUser.userStateName}}
                    </p>
                    <p class="flex-20">{{list.sharedOnDate}}</p>
                    <p class="flex-20">{{list.comments}}</p>
                  </div>
                </div>
              </div>

              <div class="flex-10" style="margin-top:10px">
                <button tabindex="0" class="md-button mobile-worklist-remove-button remove-button"
                  (click)="openAcceptDeleteListDialog(list.sharedSaveListName, list.sharedSaveListKey, list.sharedByUser)">
                  Accept/Delete
                </button>
              </div>
            </div>

          </div>
          <hr *ngIf="true" class="thinHr">
        </div>

        <!--Iterate through all of the user's savelists-->
        <div *ngFor="let list of savelists">

          <!--List Title-->
          <div class="customTabs layout-row savelists">
            <div class="layout-row flex-60">
              <h3 class="save-list-description worklist-description-text listTitle">{{list.saveListName}}
              </h3>
              <block class="tabCount flex-container listCount">
                {{list.listStudents.length}}
              </block>
            </div>

            <div tabindex="0" *ngIf="authRoleClasses.multiMoveNotice && checkCurrentMultiMoveList(list.saveListKey)"
              (click)="openMultiMoveNoticeDialog()" (keypress)="openMultiMoveNoticeDialog()" class="flex-15 layout-row">
              <i class="fa fa-arrow-right flex-15" [ngClass]="{'enabledMoveNotice': checkedMoveNotice.length > 0}"
                aria-hidden="true" style="padding-top:8px;"></i>
              <p class="moveNoticeButton  flex-85" [ngClass]="{'enabledMoveNotice': checkedMoveNotice.length > 0}">Send Multi-Move Notice</p>
            </div>
            <div class="flex-15" *ngIf="!(authRoleClasses.multiMoveNotice  && checkCurrentMultiMoveList(list.saveListKey))">
            </div>

            <button tabindex="0" *ngIf="list.saveListName !== 'My Default List'" class="md-button newUserButton editListButton flex-25" type="button"
              (click)="openEditDeleteListDialog(list.saveListName, list.saveListKey)">
              Edit/Delete List</button>
            <div *ngIf="list.saveListName === 'My Default List'" class="flex-25"></div>
            <i tabindex="0" *ngIf="list.showList" (click)="list.showList = !list.showList" (keypress)="list.showList = !list.showList"
              class="fa fa-chevron-up accordion-icon ng-scope collapseList"></i>
            <i tabindex="0" *ngIf="!list.showList" (click)="list.showList = !list.showList" (keypress)="list.showList = !list.showList"
              class="fa fa-chevron-down accordion-icon ng-scope collapseList"></i>
          </div>

          <div class="header layout-row">
            <div class="col1 flex-90">
              <div *ngIf="list.saveListName !== 'My Default List'" class="top-bottom-margin-8">
                <p class="daysToExpire"><b>Expires in:</b> {{list.daysToExpire}} days</p>
              </div>
            </div>
            <div *ngIf="list.showList">
              <export-report class="flex-10" 
                [exportlink]="exportCall"
                [exportfilter]="createExportObj(list.saveListKey)" 
                [showpdf]="false" 
                [showcsv]="true">
              </export-report>
            </div>
          </div>

          <hr *ngIf="!list.showList" class="thinHr">

          <div *ngIf="list.showList && authRoleClasses.multiMoveNotice" class="layout-row selectButtons flex-100"
            style="padding-left: 12px">
            <div class="layout-row flex-10" style="padding-right: 5px">
              <p>Select</p>
              <a tabindex="0" (click)="selectAllforMoveNotice(list.saveListKey, list.listStudents)" (keypress)="selectAllforMoveNotice(list.saveListKey, list.listStudents)" style="padding-left: 5px">All</a>
              <p>|</p>
              <a tabindex="0" (click)="selectNoneforMoveNotice(list.saveListKey)" (keypress)="selectNoneforMoveNotice(list.saveListKey)" style="padding-left: 5px">None</a>
            </div>
          </div>

          <!--List's table headers-->
          <div *ngIf="list.showList"
            class="worklist-header-titles left-right-margin-8 left-padding-6 layout-row">
            <div class="flex-container flex-100">
              <block2 tabindex="0" class="flex-30 header-pointer" (click)="sortColumn('STUDENT_NAME', list)" (keypress)="sortColumn('STUDENT_NAME', list)">Student(s)
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STUDENT_NAME' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STUDENT_NAME' && !list.sortAscending"></i>
              </block2>
              <block2 tabindex="0" class="flex-10 header-pointer" (click)="sortColumn('STATE', list)" (keypress)="sortColumn('STATE', list)">State
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STATE' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STATE' && !list.sortAscending"></i>
              </block2>
              <block2 tabindex="0" class="flex-10 header-pointer" (click)="sortColumn('SEX', list)" (keypress)="sortColumn('SEX', list)">Sex
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'SEX' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'SEX' && !list.sortAscending"></i>
              </block2>
              <block2 tabindex="0" class="flex-10 header-pointer" (click)="sortColumn('DOB', list)" (keypress)="sortColumn('DOB', list)">DOB
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'DOB' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'DOB' && !list.sortAscending"></i>
              </block2>
              <block2 tabindex="0" class="flex-15 header-pointer" (click)="sortColumn('MSIX_ID', list)" (keypress)="sortColumn('MSIX_ID', list)">MSIX ID
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'MSIX_ID' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'MSIX_ID' && !list.sortAscending"></i>
              </block2>
              <block2 tabindex="0" class="flex-15 header-pointer" (click)="sortColumn('STATE_ID', list)" (keypress)="sortColumn('STATE_ID', list)">State ID
                <i class="fa fa-caret-up fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STATE_ID' && list.sortAscending"></i>
                <i class="fa fa-caret-down fa-lg" aria-hidden="true"
                  *ngIf="list.sortColumn === 'STATE_ID' && !list.sortAscending"></i>
              </block2>
              <span class="flex-10"></span>
            </div>
          </div>


          <!--List's saved -->
          <div *ngIf="list.showList" layout-align="center center" class="table2Row">
            <div *ngFor="let student of list.listStudents" class="layout-row">
              <div class="flex-100">
                <div class="background-white">
                  <div class="section1">
                    <div class="flex-container layout-row flex-100">
                      <mat-checkbox tabindex="0"
                        *ngIf="authRoleClasses.multiMoveNotice && checkCurrentMultiMoveList(list.saveListKey)"
                        (click)="checkedMoveNoticeStudents(list.saveListKey, student.msixId)"
                        (keypress)="checkedMoveNoticeStudents(list.saveListKey, student.msixId)" class="flex-5"
                        [checked]="existsInMoveNotice(student.msixId)"></mat-checkbox>
                      <a tabindex="0" (click)="viewDetails(student)" (keypress)="viewDetails(student)" class="flex-35">{{student.stuName}}</a>
                      <p class="flex-10">{{student.state}}</p>
                      <p class="flex-10">{{student.gender}}</p>
                      <p class="flex-10">{{student.dob}}</p>
                      <p class="flex-15">{{student.msixId}}</p>
                      <p class="flex-15">{{student.stateId}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-10" style="margin-top:10px">
                <button tabindex="0" class="md-button mobile-worklist-remove-button remove-button right-align"
                  (click)="getSaveListsforMsixId(student.msixId)">
                  Edit/Remove
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>