<div class="generalMoveTo mobileReport">
  <div class="breadcrumb layout-row" *ngIf="!showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span>
      <p>General Move To</p>
    </span>
  </div>

  <div class="breadcrumb layout-row" *ngIf="showDrill">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><a (click)="goToReport()">General Move To</a></span>
    <span>></span>
    <span>
      <p [(ngModel)]="displayData" name="displayData" ngDefaultControl>Moved To {{displayData.category}}: Moved From
        {{displayData.subcategory}}</p>
    </span>
  </div>

  <div class="header layout-row" *ngIf="!showDrill">

    <h1 class="flex-90 header-padding">General Move To</h1>
    <export-report tabindex="0" class="flex-10" [exportlink]="exportCall" [exportfilter]="filterParameters" [showpdf]="true"
      [showcsv]="true" [blankres]="blankRes">
    </export-report>
  </div>
  <div class="header layout-row" *ngIf="showDrill">
    <h1 class="flex-90 header-padding" [(ngModel)]="displayData.subcategory" name="categories" ngDefaultControl>
      Moved To {{displayData.category}}:
      Moved From {{displayData.subcategory}}</h1>
    <export-report tabindex="0" class="flex-10" [exportlink]="drillExportCall" [exportcount]="drillDown.exportCount"
      [exportfilter]="drillDown" [showpdf]="false" [showcsv]="true" [blankres]="blankRes">
    </export-report>
  </div>

  <hr class="layout-padding thickHr hide-xs">

  <div class="layout-row layout-align-center-center" *ngIf="showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary drill-down-rs layout-row">
        <p>
          The table below lists migratory children who have moved to your State from the selected State.
          <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
      </div>
      <no-results *ngIf="blankRes && showDrill"></no-results>
      <reports-table *ngIf="!blankRes && showDrill" [responsedata]="drillDownData" (mycallback)="drillDownInit($event)"
        (drilldownsort)="handleDrillDownSortClick($event)" [doubleHeader]="doubleHeader" [qadToggle]="qadToggle"
        [shownavigation]="true" [drilldowninfo]="{drillDown: drillDown, reportGroupVar, reportDrillDownVar}">
      </reports-table>
    </div>
  </div>

  <div class="layout-row layout-align-center-center" *ngIf="!showDrill">
    <div class="layout-column flex-90">
      <div class="report-summary layout-row">
        <p *ngIf="!isMobile">
          This report displays the counts of moves by migratory children who have moved to your State from another
          State, based on either enrollment records (default view) or qualifying moves.
          <a class="learn-more" (click)="learnMoreActivate()">Learn More</a>
        </p>
        <p *ngIf="isMobile">
          This report displays the counts of moves by migratory children who have moved to your State from another
          State, based on either enrollment records (default view) or qualifying moves.
        </p>
      </div>

      <div *ngIf="!isMobile">
        <filter-button (activateFilter)="filterActivate()"></filter-button>
        <reports-table *ngIf="!blankRes" [responsedata]="response" (mycallback)="drillDownInit($event)"
          (sortbycolumn)="sortByColumn($event)" [currentsortcolumn]="currentSortColumn" [sortascending]="sortAscending">
        </reports-table>
        <no-results *ngIf="blankRes"></no-results>
      </div>

      <!--**********************MOBILE VIEW*************************-->
      <div class="mobile-view layout-column" *ngIf="isMobile && (!progressActivated && !indicator)">

        <label class="input-label">Primary State:</label>

        <!--State dropdown. Hidden when not superuser-->
        <mat-form-field *ngIf="reportInfo.authRoleClasses.studentMobility_all">
          <mat-select [(ngModel)]="mobileSelectedState" style="padding-left: 10px;" name="stateName">
            <mat-option *ngFor="let state of states" [value]="state.name"
              (click)="mobileStateDropdown(state.stateKey, state.name, true)">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="!reportInfo.authRoleClasses.studentMobility_all">
          <div style="font-family: 'roboto_slabregular', serif; font-size: 16px">{{reportInfo.userStateName}}</div>
          <br /><br />
        </div>

        <br>

        <label class="input-label">Secondary State:</label>
        <mat-form-field>
          <mat-select [(ngModel)]="mobileSelectedState2" style="padding-left: 10px;" name="stateName2">
            <mat-option [value]="'- All -'" (click)="mobileStateDropdown2(0, '- All -', true)">- All -</mat-option>
            <mat-option *ngFor="let state2 of states" [value]="state2.name"
              (click)="mobileStateDropdown2(state2.stateKey, state2.name, true)"
              [ngClass]="{'removeSelectedState': mobileSelectedState === state2.name}">
              {{state2.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br>

        <div class="report-summary">
          <p><b>Current performance period</b></p>
          <p>{{reportInfo.currentPeriod}}</p>
        </div>

        <div class="stats-wrapper">
          <div *ngFor="let stat of response.mobileInfos">
            <div class="stats-item">
              <div class="label">
                {{stat.labelName}}
              </div>
              <div class="value">
                {{stat.value}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--***********************************************************************************-->

    </div>
  </div>
</div>