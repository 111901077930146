<div class="select" *ngIf="!singleSelect">
    <div class="layout-row selectButtons">
        <p style="margin-top: 2px !important">Select</p>
        <a (click)="selectAll()">All</a>
        <p>|</p>
        <a (click)="selectNone()">None</a>
    </div>
</div>

<form [formGroup]="form" *ngIf="!singleSelect; else singleSelectTempl" class="border">
        <div class="states">
            <div class="flex">
                <ng-container *ngFor="let state of states" >
                    <mat-checkbox [formControlName]="state.stateKey" (blur)="onTouched()">
                        {{state.name}}
                    </mat-checkbox>
                </ng-container>
            </div>
        </div>
</form>

<ng-template #singleSelectTempl>
    <form [formGroup]="form" class="border">
    <mat-radio-group formControlName="selectedState">
        <div class="flex">
            <ng-container *ngFor="let state of states" >
                <mat-radio-button [value]="state.stateKey" (blur)="onTouched()">
                    {{state.name}}
                </mat-radio-button>
            </ng-container>
        </div>
    </mat-radio-group>
    </form>

</ng-template>