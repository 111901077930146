import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'passwordUpdate',
  templateUrl: './passwordUpdate.component.html',
  styleUrls: ['./passwordUpdate.component.scss']
})
export class PasswordUpdateComponent implements OnInit {

  private title: string;
  formData: any = {
    newPassword: '',
    confirmPassword: ''
  };
  oneUpperCharRegex = new RegExp('(?=.*[A-Z])');
  oneLowerCharRegex = new RegExp('(?=.*[a-z])');
  oneNumberRegex = new RegExp('(?=.*[0-9])');
  oneSpecCharRegex = new RegExp('(?=.*[!@#\$%\^\&*.()])');
  errors: string[];

  constructor(private _User: UserService,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router,
    private _Toast: ToastService,
    public responsiveUI: ResponsiveUIService) {

    this.errors = [];
  }

  ngOnInit(): void {
    
  }

  submitForm() {
    if (this.formData.confirmPassword !== this.formData.newPassword) return;
    let userObj:any = JSON.parse(this.cookieService.get('user'));
    let username = userObj.username;
    let apiCall = this._User.updatePassword(username, this.formData.newPassword);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        console.log(res);
        this._User.setPasswordResetFlag(true);

        let userObj: any;
        userObj = this.cookieService.getObject('user');
        userObj.isOTP = false;
        this.cookieService.putObject('user', userObj);
        this.router.navigate(['/']);
        
        this._Toast.showToast('Password updated!');
        this.loginService.logout();

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        if ((error.error && error.error.developerMessage && error.error.developerMessage === 'error_pwds_in_history') || 
          (error.data && error.data.message && error.data.message === 'error_pwds_in_history')) {
          this.errors = ['This password was found in your password history. Please select a different one.'];
        }

        subscribed.unsubscribe();
      }
    });
  }
}


