<div class="create-edit-delete-new">
    <div class="header">
      <h2>Create New List</h2>
      <button class="md-button" type="button" (click)="onClose()" (keypress)="onClose()">
        <i class="fa fa-times ng-scope"></i>
      </button>
    </div>

    <form (ngSubmit)="createNewSaveList()" [formGroup]="form">
      <div class="hint">Please enter a name for the new list:</div>
      <mat-form-field appearance="outline" [hideRequiredMarker]="true">
        <mat-label>List Name</mat-label>
        <input type="text" matInput formControlName="newListName"  />
      </mat-form-field>
      <mat-error *ngIf="form.get('newListName').errors?.maxlength">List names must be 60 characters or less.</mat-error>
      <mat-error *ngIf="form.get('newListName').errors?.duplicateListName">List name already exists. Enter a different name.</mat-error>

      <div class="action-buttons">
        <button class="cancel-btn" (click)="onClose()" mat-button>Cancel</button>
        <button class="md-button" type="submit"
          [disabled]="form.invalid">Submit</button>
      </div>
    </form>
  
</div>