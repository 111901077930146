import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-correspondence',
    styleUrls: ['./correspondence.component.scss'],
    templateUrl: './correspondence.component.html'
})
export class CorrespondenceComponent {
    @Input('student') student;
    @Input('message') message;
    @Input('corMessage') corMessage;

    @Output('sendCorrespondence') sendCorrespondence = new EventEmitter();

    sendMessage(student) {
        this.sendCorrespondence.next(student)
    }


}