<div class="studentRecordsExchange">
  <div class="breadcrumb layout-row">
    <span><a routerLink="/reports">Reports</a></span>
    <span>></span>
    <span><p>Student Records Exchange</p></span>
  </div>

  <div class="header layout-row">
    <h1 class="header-padding flex-90">Student Records Exchange</h1>
    <export-report class="flex-10" tabindex="0"
      [exportLink$]="exportLink$"
      [exportfilter]="filterParameters"
      [showcsv]="true" 
      [blankres]="blankRes"
      [showpdf]="true">
    </export-report>
  </div>

  <hr layout-padding class="thickHr">

  <div class="layout-align-center-center bodyPadding">
    <div layout="column" flex="90">
      <div class="report-summary layout-row">
        <p>
          Counts of consolidated student records from one state viewed by users in other states
        </p>
      </div>
      <filter-button (activateFilter)="filterActivate()"></filter-button>
      <reports-table *ngIf="!blankRes" 
        [responsedata]="response"
        [isreportlevelspecialsortinit]="isInit"
        [currentsortcolumn]="currentSortColumn" 
        [sortascending]="false" 
        [shownavigation]="false" 
        (sortbycolumn)="sortByColumn($event)" >
      </reports-table>
      <no-results *ngIf="blankRes"></no-results>
    </div>
  </div>
</div>
