import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastService } from 'src/app/services/toast.service';
import * as angular from "angular";
import { AppConstants } from 'src/app/config/index.constants';
import { Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  private router: string;
  isMobile: boolean;
  private roleClasses: string[];
  authRoleClasses: any ={};
  isBetaUser: boolean;
  constructor(
    private authService: AuthService,
    private reportInfoService: ReportInfoService,
    private reportsService: ReportsService,
    private toastService: ToastService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
      this.router = _router.url;
      this.isBetaUser = false;
      this.roleClasses = [
        'dashboard',
        'userAccount',
        'userAccount_with_oua',
        'userAccount_with_ga',
        'studentInfo',
        'studentInfo_with_da_ga',
        'studentInfo_with_da_ga_mpu_msu',
        'studentMobility',
        'mergeAndSplitWorklist_with_da',
        'mergeAndSplitWorklist_with_ga',
        'mergeAndSplitWorklist_all',
        'dataQuality',
        'dataQuality_all',
        'dataQuality_sbs',
        'changeOfResidency',
        'usageReport',
        'studentMobility_all',
        'dataDashboards',
        'dataDashboard_studentAchievement',
        'dataDashboard_studentAchievement_with_sua',
        'dataDashboard_studentAchievement_with_sda',
        'dataRequest',
        'moveNotice',
        'msixUsage',
        'childCountReconciliation',
        'accountMetrics'
      ];
    } 

  ngOnInit() {
    this.checkRoleClasses();
  }

  goToReport(reportName: string) {
    // clear previous filter dialog value , if any
    this.reportsService.filterDialogValues = null;

    this._router.navigate([reportName]);
  }

  checkRoleClasses() {
    this.isAuthorizedRole();
    this.isBetaUser = this.authService.isBetaUser();
  }

  isAuthorizedRole() {
    this.authService.isRoleClassAuthorized(this.roleClasses).then(data => {
      this.authRoleClasses = data;
      console.log("Reports component - authRolesClasses object: ", this.authRoleClasses);
    });
  }
}
